import { useEffect, useState } from "react";
import { useUserProfile } from "./useUserProfile";
import {
  getAllFindPackSaleDetails,
  getFindPackSaleDetailsWithUser,
} from "./flow-interactions/scripts";

const imageBase =
  "https://images.find.xyz/tr:fo-auto/https://find.mypinata.cloud/ipfs";

const formatSocials = (socials) => {
  if (!socials) {
    return {};
  }
  return Object.keys(socials).reduce((acc, key) => {
    acc[key.toLowerCase()] = socials[key].url;
    return acc;
  }, {});
};

const getPrice = (packDetails) => {
  const info = packDetails.saleInfos[0];
  const { price } = info;

  return parseFloat(price);
};

const getThumbnail = (packDetails) => {
  const hash = packDetails.thumbnailHash;
  return `${imageBase}/${hash}`;
};

const getBanner = (packDetails) => {
  const hash = packDetails.collectionDisplay.bannerImage.file.cid;
  return `${imageBase}/${hash}`;
};

const getArtwork = (packDetails) => {
  const hash = packDetails.collectionDisplay.squareImage.file.cid;
  return `${imageBase}/${hash}`;
};

const formatDetails = (packDetails) => {
  console.log({packDetails})
  // We can use this to test collections that are not live yet
  const daysToShift = 0;
  const shift = 24 * 60 * 60 * 1000 * daysToShift;

  const name = packDetails.name;
  const packsLeft = parseInt(packDetails.packsLeft);
  const description = packDetails.description;
  const startsAt = parseInt(packDetails.openTime) * 1000 - shift;
  const links = formatSocials(packDetails?.collectionDisplay.socials);
  const finished = packDetails.saleEnded;
  const price = getPrice(packDetails);
  const thumbnail = getThumbnail(packDetails);
  const banner = getBanner(packDetails);
  const artwork = getArtwork(packDetails)

  return {
    name,
    packsLeft,
    description,
    startsAt,
    links,
    finished,
    price,
    thumbnail,
    banner,
    artwork
  };
};

export default function (collectionName, packID) {
  const [packDetails, setPackDetails] = useState();
  const user = useUserProfile();

  useEffect(() => {
    const packTypeName = collectionName.toLowerCase();
    if (user && user.loggedIn) {
      getFindPackSaleDetailsWithUser(packTypeName, packID, user).then(
        (details) => {
          const formattedDetails = formatDetails(details);
          setPackDetails(formattedDetails);
        }
      );
    } else {
      getAllFindPackSaleDetails(packTypeName).then((details) => {
        const formattedDetails = formatDetails(details[packID]);
        setPackDetails(formattedDetails);
      });
    }
  }, [user, collectionName]);

  return packDetails;
}
