import parseImgUrl from "./parseImgUrl";

const useListingStatusCollection = (
  nftDetails,
  user,
  profileData,
  nftData,
  searchedAddress
) => {
  let aCatalogItem = false;
  let aDapperItem = false;
  let listingDetailsFetched = false;
  let ownItem = false;
  let userHighestBidder = false;
  let userHasOffer = false;
  let userOfferAmount = null;
  let offerListing = null;
  let forSale = false;
  let dapperForSale = false;
  let saleListing = null;
  let dapperSaleListing = null;
  let offered = false;
  let forAuction = false;
  let auctionListing = null;
  let auctionStarted = false;
  let auctionEnded = false;
  let auctionEndedBelowReserve = false;
  let auctionEndedAboveReserve = false;
  let itemMediaType = "image";
  let videoMediaLink;
  let allowedListingActions;
  let dapperAllowedListingActions;
  let itemRoyalties = {};
  let ownerProfile;
  let ownerStatus;
  let source;
  let itemMetaData;
  let addressSearchedOwner;
  let listingDetails = nftDetails;
  let itemMedia;
  let itemCollection;
  // let itemCollectionDescription;
  let itemDescription;
  let itemName;
  let itemMediaDisplay;
  let itemEdition = null;
  let itemEditionMax = null;
  let itemTraits = null;
  let itemThumbnail = null;
  let itemRarity = null;
  let itemId = null;
  let itemUuid = null;
  let itemType = null;
  let linkedForMarket = null;
  let flowty = null;
  let flowtyRental = null;
  let storefront = null;
  let storefrontV2 = null;

  if (searchedAddress) {
    addressSearchedOwner = searchedAddress;
  }

  if (profileData) {
    ownerProfile = profileData.profile;
    ownerStatus = profileData;
    if (user && profileData.profile.address === user.addr) {
      ownItem = true;
    }
  }
  if (nftDetails) {
    aCatalogItem = true;

    //catalog allowed listing actions
    if (listingDetails) {
      listingDetailsFetched = true;
      itemMetaData = listingDetails.nftDetail;
      itemName = listingDetails.nftDetail.name;

      //catalogId + uuid
      if (listingDetails.nftDetail.id) {
        itemId = listingDetails.nftDetail.id;
      }
      if (listingDetails.nftDetail.uuid) {
        itemUuid = listingDetails.nftDetail.uuid;
      }

      //itemtype
      if (listingDetails.nftDetail.type) {
        itemType = listingDetails.nftDetail.type;
      }
      //catalog media/thumbnail
      //set catalog thumbnail
      if (listingDetails?.nftDetail?.thumbnail) {
        itemThumbnail = listingDetails?.nftDetail?.thumbnail;
      }
      //catalog media for multis
      if (
        listingDetails?.nftDetail?.media &&
        Object.keys(listingDetails?.nftDetail?.media).length > 0
      ) {
        itemMedia = listingDetails.nftDetail.media;
      } else {
        itemMedia = "thumbnail";
      }

      //catalog edition
      if (
        listingDetails.nftDetail.editions &&
        listingDetails.nftDetail.editions[0]
      ) {
        if (listingDetails.nftDetail.editions[0]?.number) {
          itemEdition = listingDetails.nftDetail.editions[0]?.number;
        }
        if (listingDetails.nftDetail.editions[0]?.max) {
          itemEditionMax = listingDetails.nftDetail.editions[0].max;
        }
      }

      //catalog display
      if (listingDetails?.nftDetail?.description) {
        if (listingDetails?.nftDetail?.description) {
          itemDescription = listingDetails?.nftDetail?.description;
        }
      } else if (
        !listingDetails?.nftDetail?.description &&
        listingDetails?.nftDetail?.collection.description
      ) {
        itemDescription = listingDetails?.nftDetail?.collection.description;
      } else itemDescription = "No description";

      //catalog collection
      if (listingDetails?.nftDetail?.collection.name) {
        if (listingDetails?.nftDetail?.collection.name) {
          itemCollection = listingDetails?.nftDetail?.collection.name;
        }
      }

      //catalog edition views
      if (
        listingDetails.nftDetail.editions &&
        listingDetails.nftDetail.editions[0]
      ) {
        if (listingDetails.nftDetail.editions[0]?.number) {
          itemEdition = listingDetails.nftDetail.editions[0]?.number;
        }
        if (listingDetails.nftDetail.editions[0]?.max) {
          itemEditionMax = listingDetails.nftDetail.editions[0].max;
        }
      }

      //catalog item traits
      if (
        listingDetails.nftDetail.traits &&
        Object.keys(listingDetails.nftDetail.traits).length > 0
      ) {
        itemTraits = listingDetails.nftDetail.traits;
      }

      // if (listingDetails.nftDetail.rarity.description){
      //   itemRarity = listingDetails.nftDetail.rarity.description
      // }

      //linked for market
      if (
        listingDetails.linkedForMarket &&
        listingDetails.linkedForMarket != undefined
      ) {
        linkedForMarket = true;
      } else {
        linkedForMarket = false;
      }

      //catalog allowed listings
      if (
        listingDetails.allowedListingActions &&
        listingDetails.allowedListingActions != undefined
      ) {
        allowedListingActions = listingDetails.allowedListingActions;

        //catalog royalties
        if (
          listingDetails.allowedListingActions.FindMarketSale &&
          listingDetails.allowedListingActions.FindMarketSale.ListingDetails[0]
            .royalties
        ) {
          itemRoyalties = {
            ...itemRoyalties,
            Sale: {
              ...listingDetails.allowedListingActions.FindMarketSale
                .ListingDetails[0].royalties,
            },
          };
        }

        //allowed market items (note ListingDetails is case correct)
        if (
          listingDetails.allowedListingActions.FindMarketAuctionEscrow &&
          listingDetails.allowedListingActions.FindMarketAuctionEscrow
            .ListingDetails[0].royalties
        ) {
          itemRoyalties = {
            ...itemRoyalties,
            Auction: {
              ...listingDetails.allowedListingActions.FindMarketSale
                .ListingDetails[0].royalties,
            },
          };
        }
        if (
          listingDetails.allowedListingActions.FindMarketDirectOfferEscrow &&
          listingDetails.allowedListingActions.FindMarketDirectOfferEscrow
            .ListingDetails[0].royalties
        ) {
          itemRoyalties = {
            ...itemRoyalties,
            Offer: {
              ...listingDetails.allowedListingActions.FindMarketSale
                .ListingDetails[0].royalties,
            },
          };
        }
      }

      //catalog nft current listing status
      if (listingDetails.findMarket) {
        //catalog nft direct sale status
        if (listingDetails.findMarket.FindMarketSale) {
          forSale = true;
          saleListing = listingDetails.findMarket.FindMarketSale;
        }
        //catalog nft auction status
        if (listingDetails.findMarket.FindMarketAuctionEscrow) {
          forAuction = true;
          auctionListing = listingDetails.findMarket.FindMarketAuctionEscrow;
          if (
            user &&
            user.addr ===
              listingDetails.findMarket.FindMarketAuctionEscrow.bidder
          ) {
            userHighestBidder = true;
          }
          if (
            listingDetails.findMarket.FindMarketAuctionEscrow.auction
              .auctionEndsAt
          ) {
            auctionStarted = true;
            if (
              listingDetails.findMarket.FindMarketAuctionEscrow.auction
                .auctionEndsAt &&
              listingDetails.findMarket.FindMarketAuctionEscrow.auction
                .auctionEndsAt <
                listingDetails.findMarket.FindMarketAuctionEscrow.auction
                  .timestamp
            ) {
              auctionEnded = true;
              if (
                listingDetails.findMarket.FindMarketAuctionEscrow.auction
                  .currentPrice <
                listingDetails.findMarket.FindMarketAuctionEscrow.auction
                  .reservePrice
              ) {
                auctionEndedBelowReserve = true;
              } else if (
                listingDetails.findMarket.FindMarketAuctionEscrow.auction
                  .currentPrice >=
                listingDetails.findMarket.FindMarketAuctionEscrow.auction
                  .reservePrice
              ) {
                auctionEndedAboveReserve = true;
              }
            }
          }
        }

        //catalog nft offered status
        if (listingDetails.findMarket.FindMarketDirectOfferEscrow) {
          offered = true;
          offerListing = listingDetails.findMarket.FindMarketDirectOfferEscrow;
          if (
            user &&
            user.addr ===
              listingDetails.findMarket.FindMarketDirectOfferEscrow.bidder
          ) {
            userHasOffer = true;
            userOfferAmount =
              listingDetails.findMarket.FindMarketDirectOfferEscrow.amount * 1;
          }
        }
      }

      // * dapper allowed listing items
      if (
        listingDetails.dapperAllowedListingActions &&
        listingDetails.dapperAllowedListingActions != undefined
      ) {
        dapperAllowedListingActions =
          listingDetails.dapperAllowedListingActions;

        //catalog royalties
        if (
          listingDetails.dapperAllowedListingActions.FindMarketSale &&
          listingDetails.dapperAllowedListingActions.FindMarketSale
            .ListingDetails[0].royalties
        ) {
          aDapperItem = true;
          itemRoyalties = {
            ...itemRoyalties,
            Sale: {
              ...listingDetails.dapperAllowedListingActions.FindMarketSale
                .ListingDetails[0].royalties,
            },
          };
        }

        //allowed market items (note ListingDetails is case correct)
        if (
          listingDetails.dapperAllowedListingActions.FindMarketAuctionEscrow &&
          listingDetails.dapperAllowedListingActions.FindMarketAuctionEscrow
            .ListingDetails[0].royalties
        ) {
          itemRoyalties = {
            ...itemRoyalties,
            Auction: {
              ...listingDetails.dapperAllowedListingActions.FindMarketSale
                .ListingDetails[0].royalties,
            },
          };
        }
        if (
          listingDetails.dapperAllowedListingActions
            .FindMarketDirectOfferEscrow &&
          listingDetails.dapperAllowedListingActions.FindMarketDirectOfferEscrow
            .ListingDetails[0].royalties
        ) {
          itemRoyalties = {
            ...itemRoyalties,
            Offer: {
              ...listingDetails.dapperAllowedListingActions.FindMarketSale
                .ListingDetails[0].royalties,
            },
          };
        }
      }

      // * Dapper current listings
      if (listingDetails.dapperMarket) {
        //catalog nft direct sale status
        if (listingDetails.dapperMarket.FindMarketSale) {
          forSale = true;
          saleListing = listingDetails.dapperMarket.FindMarketSale;
        }
      }

      //catalog flowty / flowtyloan / storefront / storefront v2 status
      if (listingDetails.flowty) {
        flowty = listingDetails.flowty;
      }
      if (listingDetails.flowtyRental) {
        flowtyRental = listingDetails.flowtyRental;
      }
      if (listingDetails.storefront) {
        storefront = listingDetails.storefront;
      }
      if (listingDetails.storefrontV2) {
        storefrontV2 = listingDetails.storefrontV2;
      }
    }
  }

  return {
    aCatalogItem: aCatalogItem,
    aDapperItem: aDapperItem,
    itemCollection,
    itemRoyalties,
    itemDescription,
    itemName,
    allowedListingActions,
    dapperAllowedListingActions,
    listingDetails,
    listingDetailsFetched,
    itemMetaData: itemMetaData,
    user: user,
    owner: { addressSearchedOwner, ownerProfile, ownerStatus, ownItem },
    itemMedia,
    itemMediaType,
    itemTraits,
    itemThumbnail,
    itemRarity,
    itemId,
    itemUuid,
    itemType,
    itemEdition: itemEdition,
    itemEditionMax: itemEditionMax,
    allCurrentListings: {
      offered: { offered, userHasOffer, userOfferAmount, offerListing },
      forSale: { forSale, saleListing },
      forAuction: {
        forAuction,
        auctionListing,
        auctionInfo: {
          auctionStarted,
          auctionEnded,
          auctionEndedAboveReserve,
          auctionEndedBelowReserve,
          userHighestBidder,
        },
      },
      linkedForMarket,
      flowty: flowty,
      flowtyRental: flowtyRental,
      storefront: storefront,
      storefrontV2: storefrontV2,
    },
    dapperAllCurrentListings: {
      forSale: {},
    },
  };
};
export default useListingStatusCollection;
