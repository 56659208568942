import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import TimeAgo from "react-timeago";
import ReactGA from "react-ga4";

import MarketplaceItemBoughtModal from "../../marketplace/marketplaceitemboughtmodal/marketplaceitemboughtmodal";
import FlowSticker from "../../forms/ftstickers/FlowSticker";
import FUSDSticker from "../../forms/ftstickers/FUSDSticker";
import DapperSticker from "../../forms/ftstickers/DapperSticker";

import {
  handleBuy,
  handleBuyItemDirectSale,
} from "../../../functions/flow-interactions/transactions";
import { UseThemeStatus } from "../../../functions/themeMode";
import useToastTxStatus from "../../../functions/useToastTxStatus";
import { UseRefineQuery } from "../../../functions/refineQuery";
import parseImgUrl from "../../../functions/parseImgUrl";
import truncateItemName from "../../../functions/truncateItemName";
import { handleNFTFavClicked, hasUserFavNFT } from "../../../firebaseConfig";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../functions/useWalletType";
import { toLocalNumber } from "../../../functions/format";

import "./mp-single-hit.css";
import USDCSticker from "../../forms/ftstickers/USDCSticker";

const dt = (new Date().getTime() / 1000).toFixed(0);

function getSticker(alias){
  if (alias === "FlowToken") return <FlowSticker />
  if (alias === "FUSD") return <FUSDSticker />
  if (alias === "FlowUtilityToken") return <DapperSticker coin="FLOW" />
  if (alias === "DapperUtilityCoin") return <DapperSticker coin="USD" />
  if (alias === "FiatToken") return <USDCSticker/>

  return null
}

export default function MarketplaceSingleHit(props) {
  const { hit, moreFromCollection, detail, user } = props;

  const [showBuy, setShowBuy] = useState(false);
  const [buyNowClicked, setBuyNowClicked] = useState(false);
  const [isNFTFavByUser, setIsNFTFavByUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  let isName;
  if (hit.collection_name === "FIND") {
    isName = true;
  }
  const themeStatus = UseThemeStatus();
  const toastStatus = useToastTxStatus();
  const query = UseRefineQuery();
  const wallet = useWalletType();
  let compId = null;
  let compIdMax = null;
  let navigate = useNavigate();

  async function getFavs(collectionName, nftId, user) {
    const isFav = await hasUserFavNFT(collectionName, nftId, user);
    setIsNFTFavByUser(isFav);
  }

  //flov # data
  if (hit?.["number.edition_flovatar component_number"]) {
    compId = hit?.["number.edition_flovatar component_number"];
    if (hit?.["number.edition_flovatar component_max"]) {
      compIdMax = hit?.["number.edition_flovatar component_max"];
    }
  }

  useEffect(() => {
    if (hit) {
      if (isNFTFavByUser === null) {
        getFavs(hit.collection_name, hit.nft_id.toString(), user);
      }
    }
  }, [hit, user]);

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <div
        id="mp-card-tooltip"
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    )
  );

  const renderTooltipFavs = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Add to fav list</span>
    </Tooltip>
  );

  if (!hit) {
    return null;
  }

  const { amount_alias } = hit
  const isUSDEquivalent =
    amount_alias === "DapperUtilityCoin" ||
    amount_alias === "FUSD" ||
    amount_alias === "FiatToken";
  const prefix = isUSDEquivalent ? "$" : "";
  const formattedAmount = toLocalNumber(hit);
  const amount = `${prefix}${formattedAmount}`;
  const sticker = getSticker(hit.amount_alias)

  const callbacks = {
    onSuccess: () => setShowPopup(true),
  };

  const onClick = isName
    ? () => {
        setBuyNowClicked(true);
        handleBuy(hit.nft_name, hit.amount, hit.seller, wallet, callbacks);
      }
    : () => {
        setBuyNowClicked(true);
        handleBuyItemDirectSale(
          hit.seller,
          hit.amount,
          hit.uuid,
          wallet,
          callbacks
        );
      };

  return (
    <Card
      onMouseEnter={() => setShowBuy(true)}
      onMouseLeave={() => setShowBuy(false)}
      className={
        !moreFromCollection
          ? "market-nft mx-0 mb-0 pb-0"
          : moreFromCollection && detail
          ? "market-nft-more-detail mx-1 mb-0 pb-0"
          : "market-nft-more mx-1 mb-0 pb-0"
      }
    >
      {/* <Link
          className={isName ? "card-link px-2 py-2 mb-2 " : ' card-link px-0 mb-2'}
      
          to={
            hit.collection_name === "FIND"
              ? "/mp/" + hit.collection_alias + "/" + hit.nft_name
              : "/mp/" + hit.collection_alias + "/" + hit.uuid
          }
        > */}
      {hit.nft_thumbnail &&
      (hit.nft_thumbnail.includes(".mp4") ||
        hit.collection_alias === "THiNG.FUND Membership Badge") ? (
        <video
          key={hit.nft_thumbnail}
          muted
          alt={"Picture of " + hit.nft_name.name}
          loop=""
          playsInline=""
          style={{ cursor: "pointer" }}
          onMouseOver={(event) => event.target.play()}
          onMouseOut={(event) => event.target.pause()}
          onClick={() =>
            hit.collection_name === "FIND"
              ? navigate("/mp/" + hit.collection_alias + "/" + hit.nft_name)
              : navigate("/mp/" + hit.collection_alias + "/" + hit.uuid)
          }
        >
          <source
            src={parseImgUrl(hit.nft_thumbnail) + "#t=1.0"}
            type="video/mp4"
          ></source>
          Sorry this video is not supported by your browser
        </video>
      ) : (
        <>
          <img
            crossOrigin="anonymous"
            className={
              hit.collection_alias === "FIND" ? "img-wrap" : "img-wrap"
            }
            fluid={+true}
            src={
              hit.collection_alias === "FIND"
                ? parseImgUrl(
                    "/assets/img/marketplace/find_name_with_find.png",
                    "",
                    hit.nft_name
                  )
                : parseImgUrl(hit.nft_thumbnail, "thumbnail")
            }
            color="var(--background)"
            style={{
              borderRadius: "8px",
              cursor: "pointer",
              objectFit: "cover",
            }}
            onClick={() =>
              hit.collection_name === "FIND"
                ? navigate("/mp/" + hit.collection_alias + "/" + hit.nft_name)
                : navigate("/mp/" + hit.collection_alias + "/" + hit.uuid)
            }
          />
          {/* {hit.collection_alias === "FIND" && (
                <div id="valid-til" className="flex-row">
                  &nbsp;
                  <span>
                    {new Date(
                      hit.number_valid_until * 1000
                    ).toLocaleDateString()}
                  </span>
                  &nbsp;
                  <img
                    src="/assets/img/profile/namesowned/tick-circle.png"
                    alt="green tick"
                  />
                </div>
              )} */}
        </>
      )}
      <div className=" mpCardCollectionRow  py-0 px-2">
        <Link className="card-top-link" to={"/mp/" + hit.collection_name}>
          <Col xs="auto">
            <Card.Img
              style={{ height: "1rem", width: "1rem" }}
              fluid={+true}
              src="/assets/img/marketplace/verify1.png"
            />
            &nbsp;&nbsp;
          </Col>
          <Col className=" align-items-center justify-content-center mp-collection-alias">
            <span className="mp-collection-alias smallTextSpan">
              {hit.collection_alias === "FIND"
                ? ".find"
                : truncateItemName(hit.collection_alias, 15)}
            </span>
          </Col>
        </Link>
        <OverlayTrigger placement="top" overlay={renderTooltipFavs}>
          {/* favs */}
          {user && user.loggedIn ? (
            <div className="flex-row " id="fav-icon-box">
              <img
                src={
                  themeStatus === "light" && !isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light.svg"
                    : themeStatus === "light" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light-fill.svg"
                    : themeStatus === "dark" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-dark-fill.svg"
                    : "/assets/img/marketplace/heart-dark.svg"
                }
                onClick={async () => {
                  await handleNFTFavClicked(
                    hit.collection_name,
                    hit.nft_type,
                    hit.nft_id.toString(),
                    hit.nft_name,
                    hit.nft_thumbnail
                      ? hit.nft_thumbnail
                      : parseImgUrl(
                          "/assets/img/marketplace/find_name_with_find.png",
                          "",
                          hit.nft_name
                        ),
                    hit.seller,
                    user
                  );

                  getFavs(hit.collection_name, hit.nft_id.toString(), user);
                }}
                alt=""
                style={{ width: "25px", cursor: "pointer" }}
              />
            </div>
          ) : (
            <div className="flex-row " id="fav-icon-box">
              <img
                src={
                  themeStatus === "light" && !isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light.svg"
                    : themeStatus === "light" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light-fill.svg"
                    : themeStatus === "dark" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-dark-fill.svg"
                    : "/assets/img/marketplace/heart-dark.svg"
                }
                alt=""
                style={{ width: "1.4rem", cursor: "pointer" }}
                onClick={() => {
                  fcl.logIn();
                  ReactGA.event("login", {
                    method: "wallet",
                  });
                }}
              />
            </div>
          )}
        </OverlayTrigger>
      </div>
      <Link
        className="card-link"
        to={
          hit.collection_name === "FIND"
            ? "/mp/" + hit.collection_alias + "/" + hit.nft_name
            : "/mp/" + hit.collection_alias + "/" + hit.uuid
        }
      >
        <Row className="py-0 px-2">
          <Col align="left" className="mpCardNameRow">
            <span className="nftName fw-bold">
              {hit.collection_alias === "FIND" ? (
                <span>
                  <span className="fw-bold">
                    <span className="fw-bold themeColor">{hit.nft_name}</span>
                    .find
                  </span>
                </span>
              ) : (
                <span>
                  {truncateItemName(hit.nft_name, 30)}
                  {compId && " #" + compId}
                  {compIdMax && " / #" + compIdMax}
                </span>
              )}
            </span>
          </Col>
        </Row>
        <Row className="pt-3 px-2">
          <Col>
            <small className="smallText">
              {hit.nft_rarity ? (
                <>
                  {hit.nft_rarity[0].toUpperCase() +
                    hit.nft_rarity.substring(1)}
                </>
              ) : (
                <>&nbsp;</>
              )}
            </small>
          </Col>
        </Row>
        {/* <Row className="pb-1 cardRow">
          <Col>
            {hit.listing_alias === "EnglishAuction" ? (
              <small className="smallText">
                {hit.ends_at ? (
                  hit.ends_at > dt ? (
                    <TimeAgo
                      date={new Date(hit.ends_at * 1000)}
                      timeStyle="twitter"
                      suffix="none"
                    />
                  ) : (
                    "Ended"
                  )
                ) : (
                  "Timer begins on bid"
                )}
              </small>
            ) : (
              <small>&nbsp;</small>
            )}
          </Col>
        </Row> */}
        {/* <Row className="pt-2 pb-3 align-items-center">
          <Col>
            {hit.listing_alias === "EnglishAuction" && (
              <p className="salesTypeAuction">Auction</p>
            )}
            {hit.listing_alias === "Sale" && (
              <p className="salesTypeSale">Sale</p>
            )}
            {hit.listing_alias === "DirectOffer" && (
              <p className="salesTypeSale">Offer</p>
            )}

            {/* {hit.listing_alias === "EnglishAuction"
                ? "Auction"
                : hit.listing_alias} */}
        {/* </Col> */}
        {/* <Col
            align="right"
            className="justify-content-end align-items-end"
          ></Col> */}
        {/* </Row> */}
        <Row className="align-items-center pb-1 px-2  ">
          <Col className="d-flex justify-content-center ">
            <span className="fw-bold price-span" style={{}}>
              {amount}
            </span>
          </Col>
          <Col>
            {sticker}
          </Col>
        </Row>
      </Link>
      {hit.listing_alias === "Sale" && (
        <Row onClick={onClick} className="salesTypeSaleRow p-0 m-0 mt-2 py-0">
          {showBuy ? "Buy Now" : "Listing"}
        </Row>
      )}
      {hit.listing_alias === "DirectOffer" && (
        <Row className="salesTypeOfferRow p-0 m-0 mt-2">Offer</Row>
      )}
      {hit.listing_alias === "EnglishAuction" && (
        <Row className="salesTypeAuctionRow p-0 m-0 mt-2">
          {hit.ends_at ? (
            hit.ends_at > dt ? (
              <TimeAgo
                className="d-flex align-items-center justify-content-center"
                date={new Date(hit.ends_at * 1000)}
                suffix="none"
              />
            ) : (
              "Ended"
            )
          ) : (
            "Auction"
          )}
        </Row>
      )}

      {/* Modal to show results of the transaction*/}
      <Modal
        data-theme={themeStatus}
        show={showPopup}
        onHide={() => setShowPopup(false)}
        centered
      >
        <MarketplaceItemBoughtModal
          nftDetails={hit}
          isName={isName}
          query={query}
        />
      </Modal>
    </Card>
  );
}
