import React from 'react'
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router';
import MoreFromCollection from '../../marketplace/morefromcollection/morefromcollection';

const MpDetailPageDelisted = () => {    
    const { colName, nft } = useParams();
let isName = false
if (colName === 'FIND'){
    isName = true
}

  return (
    <>
    <Container className="content-container my-4">
    <h3 className='themeColor'>Uh-oh - {colName}&nbsp;{nft} is no longer indexed!</h3>
    <p>If you know the current owner of this item you can look it up via their profile.</p>
    <p>In the mean time - here are some listed items from this collection:</p>
    <MoreFromCollection isName={isName} colName={colName} detail={false}/>
    </Container></>
  )
}

export default MpDetailPageDelisted