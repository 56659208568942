import React from "react";
import { Col, Row } from "react-bootstrap";
import CreateProfileInput from "./CreateProfileInput";
import CreateProfileStageBar from "./CreateProfileStageBar";
import "./createprofile.scss";
import CreateProfileFormInfo from "./CreateProfileFormInfo";
import CreateProfileFormHeader from "./CreateProfileFormHeader";
import CreateProfileFormDesc from "./CreateProfileFormDesc";

const CreateProfileForm = ({
  formValues,
  setFormValues,
  validation,
  currentStage,
  walletType,
}) => {
  return (
    <div id="create-profile-form-wrapper">
      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormHeader currentStage={currentStage} />
        </Col>
      </Row>
      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormDesc currentStage={currentStage} />
        </Col>
      </Row>
      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormInfo currentStage={currentStage} />
        </Col>
      </Row>
      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileInput
            formValues={formValues}
            setFormValues={setFormValues}
            validation={validation}
            walletType={walletType}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateProfileForm;
