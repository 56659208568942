import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import DetailsItemHistory from "../../profile/profilecollection/nftdetailpage/detailsitemhistory/detailsitemhistory";
import CatalogTraitsCardMarket from "../../profile/profilecollection/nftdetailpage/detailspropertycard/CatalogTraitsCardMarket";
import DetailsPropertyCard from "../../profile/profilecollection/nftdetailpage/detailspropertycard/detailspropertycard";

const MpDetailAccordion = ({
  hit,
  listingDetails,
  isName,
  user,
  isOffered,
  listingStatus,
}) => {
  // console.log(listingDetails)
  return (
    <Row className="mb-3 ">
      <Col className="">
        <Accordion id="market-detail-acc" defaultActiveKey="1">
          {/* <Accordion.Item eventKey="0">
            <Accordion.Header className="pb-2">
              <span className="fw-bold">Description</span>
            </Accordion.Header>
            <Accordion.Body className="px-0 py-0">
              <Row className="pb-4">
                {listingDetails && (
                  <MpDetailDescription
                    isName={isName}
                    listingDetails={listingDetails}
                    listingStatus={listingStatus}
                  />
                )}
              </Row>
            </Accordion.Body>
          </Accordion.Item> */}

          {!listingStatus.isName &&
          (
           ((listingStatus.itemTraits || listingStatus.itemEdition || listingStatus.itemRarity) && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <span className="fw-bold">Properties</span>
                </Accordion.Header>
                <Accordion.Body className="px-0 py-0">
                  {listingStatus.listingDetails &&
                    hit &&
                    listingStatus &&
                    listingDetails && (
                      <CatalogTraitsCardMarket listingStatus={listingStatus} />
                    )}
                </Accordion.Body>
              </Accordion.Item>
            )))}
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="fw-bold">Details</span>
            </Accordion.Header>
            <Accordion.Body className="py-0 px-0">
              {listingStatus.listingDetails && hit && listingStatus && (
                <DetailsPropertyCard
                  listingDetails={listingDetails}
                  nftDetails={hit}
                  listingStatus={listingStatus}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>

          {/* {!listingStatus.isName && (
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span className="fw-bold">Royalties</span>
              </Accordion.Header>
              <Accordion.Body className="px-0 py-0">
                {listingStatus.listingDetails && hit && listingStatus && (
                  <CatalogRoyaltiesCardMarket listingStatus={listingStatus} />
                )}
              </Accordion.Body>
            </Accordion.Item>
          )} */}

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <span className="fw-bold">History</span>
            </Accordion.Header>
            <Accordion.Body className="px-0 py-0">
              {listingDetails && (
                <DetailsItemHistory
                  user={user}
                  isName={isName}
                  nftDetails={hit}
                  listingDetails={listingDetails}
                  listingStatus={listingStatus}
                  hit={hit}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span>Properties</span>
          </Accordion.Header>
          <Accordion.Body>
            {listingStatus.listingDetails &&  hit && listingStatus && (
              <DetailsPropertyCard
                listingDetails={listingDetails}
                nftDetails={hit}
                listingStatus={listingStatus}
              />
            )}
          </Accordion.Body>
          <div className="seperator100 my-3"></div>
        </Accordion.Item>

  

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <h4>History</h4>
          </Accordion.Header>
          <Accordion.Body>
            {listingDetails && (
              <DetailsItemHistory
                user={user}
                isName={isName}
                nftDetails={hit}
                listingDetails={listingDetails}
                listingStatus={listingStatus}
                hit={hit}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
        <div className="seperator100 my-3"></div>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <h4>Offers</h4>
          </Accordion.Header>
          <Accordion.Body>
            {listingDetails &&  (
              <DetailsOffersMade
                user={user}
                isName={isName}
                nftDetails={hit}
                listingDetails={listingDetails}
                isOffered={listingStatus.offered}
                listingStatus={listingStatus}
                hit={hit}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
        <div className="seperator100 my-3"></div> */}
        </Accordion>
      </Col>
    </Row>
  );
};

export default MpDetailAccordion;
