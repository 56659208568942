import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Col, Row, Button } from "react-bootstrap";

import Loading from "../../../loading/loading";
import { CollectionNft } from "../collectionnft/collectionnft";
import { getExtraItems } from "../../../../functions/flow-interactions/scripts";

import "./collectiontabpage.css";

export function CollectionTabPage({
  collectionData,
  name,
  address,
  profileData,
  activeTabKey,
}) {
  const [hasMore, setHasMore] = useState(false);
  const loadLength = 25;
  const [collection, setCollection] = useState([]);
  const [loadMoreIDs, setLoadMoreIDs] = useState();

  let { colName } = useParams();
  const resolver = collectionData?.resolver;


  useEffect(() => {
    if (collectionData) {
      loadCollection().then();
    }
  }, [collectionData]);

  // TODO: Refactor code into single function
  async function loadCollection() {
    const { extraIDs, extraIDsIdentifier } = collectionData
    let idArray = extraIDs.map(Number);
    const slice = idArray.slice(0, loadLength);
    const dictionary = { [extraIDsIdentifier]: slice };


    const response = await getExtraItems(address, dictionary, resolver);
    const items = response[extraIDsIdentifier]
    setCollection(items);

    const rest = idArray.slice(loadLength);
    if (rest.length > 0) {
      setHasMore(true);
      setLoadMoreIDs(rest);
    }
  }
  async function loadMoreItems() {
    const { key } = collectionData
    if (loadMoreIDs.length > 0) {

      const slice = loadMoreIDs.slice(0, loadLength);
      const dictionary = {[key]: slice}
      const response = await getExtraItems(address, dictionary, resolver);
      const moreItems = response[key]

      // Update collection
      const updatedCollection = [...collection, ...moreItems];
      setCollection(updatedCollection);

      // Update loadMoreIDs
      const restIDs = loadMoreIDs.slice(loadLength);
      if (restIDs.length === 0) {
        setHasMore(false);
      }
      setLoadMoreIDs(restIDs);
    }
  }

  return (
    <>
      {collection && collection.length > 0 ? (
        collection.map((nft, i) => (
          <Col
            key={i}
            className={`collection-nft-col pb-2 collection-${colName}`}
            xs="6"
            md="4"
            lg="4"
            xl="3"
            xxl="3"
          >
            <CollectionNft
              nftData={nft}
              idkey={i}
              name={name}
              object={nft}
              collection={colName}
              link="enabled"
              onSaleNFT={
                profileData?.itemsForSale &&
                profileData.itemsForSale["FindMarketSale"]?.items
                  ? profileData.itemsForSale["FindMarketSale"]?.items.find(
                      (item) => item.nftId === nft.id
                    )
                  : null
              }
              onAuctionNFT={
                profileData?.itemsForSale &&
                profileData.itemsForSale["FindMarketAuctionEscrow"]?.items
                  ? profileData.itemsForSale[
                      "FindMarketAuctionEscrow"
                    ]?.items.find((item) => item.nftId === nft.id)
                  : null
              }
              activeTabKey={activeTabKey}
            />
          </Col>
        ))
      ) : (
        <Loading />
      )}
      {hasMore && (
        <Row className="my-3">
          <Col align="center">
            <Button
              onClick={loadMoreItems}
              variant="find-outline-commerce"
              className="w-100"
            >
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}
