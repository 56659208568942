import { useEffect, useState } from "react";
import { useStateChanged } from "./DisabledState";
import {
  getAddressArrayCollections,
  getUserCollections,
} from "./flow-interactions/scripts";

const network = process.env.REACT_APP_NETWORK;

const useGetFactoryCollectionsMulti = (addressArray) => {
  const [collectionItems, setCollectionItems] = useState({});
  const [getFinished, setGetFinished] = useState(false);

  useEffect(() => {
    if (addressArray.length > 0) {
      try {
        getAddressArrayCollections(addressArray).then((collectionItems) => {
          setCollectionItems(collectionItems);
          setGetFinished(true);
        });
      } catch (error) {
        throw error;
      }
    }
  }, [addressArray, useStateChanged()]);

  return {
    collectionItems,
    getFinished,
  };
};

export default useGetFactoryCollectionsMulti;
