import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import FlowSticker from "../../../../forms/ftstickers/FlowSticker";
import "./ColDetailAvailableOn.scss";

const ColDetailAvailableOnFindSale = (props) => {
  const { listingStatus } = props
  const onDetails = listingStatus.allCurrentListings.forSale.saleListing;
  const { colName, nft } = useParams();

  return (
    <div className="available-on-wrapper-find p-3 my-4">
      <div className="available-on-row">
        <div className="available-on-icon-col">
          <img
            id="icon-img"
            src="/assets/img/profile/collection/nft-detail/on-find.png"
          />
          <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
        </div>
        <div className="available-on-info-col">
          <div className="available-on-info-row-1">.find market</div>
          <div className="available-on-info-row-2">For sale</div>
        </div>
        <div className="available-on-price-col">
          <div className="available-on-price-row-1">
            <div>{onDetails.ftAlias === "DUC" && "$"}{onDetails.amount * 1}</div>&nbsp;
            <div>
              {onDetails.ftAlias === "Flow" ? (
                <FlowSticker noBg={"true"} />
              ) : (
                onDetails.ftAlias.replace("DUC", "")
              )}
            </div>
            &nbsp;
            <Link to={`/mp/${colName}/${listingStatus.itemUuid}`}>
              <div>
                <img
                  style={{ marginBottom: "3px" }}
                  src="/assets/img/profile/collection/nft-detail/chevron-right.svg"
                />
              </div>
            </Link>
          </div>
          <div className="available-on-price-row-2">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default ColDetailAvailableOnFindSale;
