import * as tx from "../../const";
import pluginProcessor from "../../plugins";

export default function classify(context, transfer) {
  // console.log("=========== Start classification ===========");
  const { collection, id } = context;
  const { events, signers } = transfer;

  const idRelated = events.list.reduce(
    (acc, key) => {
      const event = events.byId[key.id][key.index];
      const argId = event.args.id || event.args.tokenId;

      const fromCollection = event.id.includes(collection);
      const exactId = argId === id;
      const found = fromCollection && exactId;

      if (found) {
        acc.list.push(key);

        const keyLowerCase = key.id.toLowerCase().split(".")[3];
        if (keyLowerCase.includes("withdraw")) {
          acc.traits.withdraw = event;
        }

        if (keyLowerCase.includes("burn")) {
          acc.traits.burn = event;
        }

        if (keyLowerCase.includes("mint") || keyLowerCase.includes("create")) {
          acc.traits.mint = event;
        }

        if (keyLowerCase.includes("deposit")) {
          acc.traits.deposit = event;
        }

        if (keyLowerCase.includes("withdraw")) {
          acc.traits.withdraw = event;
        }
      }

      const keyLowerCase = key.id.toLowerCase();
      if (keyLowerCase.includes("marketplace")) {
        if (keyLowerCase.includes("forsale")) {
          const price = event.args.price;
          acc.other.marketplace = {
            type: tx.MARKETPLACE_SALE_CREATED,
            price,
          };
        }
      }

      return acc;
    },
    { list: [], traits: {}, other: {} }
  );

  const resolvePlugins = pluginProcessor(context,transfer, idRelated)
  if(resolvePlugins){
    return resolvePlugins
  }

  if (idRelated.list.length === 0) {
    return {
      type: tx.EMPTY,
    };
  }

  // Let's see if we have "burn" trait
  if (idRelated.traits.burn) {
    return {
      type: tx.BURN,
    };
  }

  if (idRelated.traits.withdraw) {
    const { marketplace } = idRelated.other;
    if (marketplace) {
      return marketplace;
    }
  }

  // How about "mint"?
  if (idRelated.traits.mint) {
    const { args } = idRelated.traits.mint;
    let recipient = args.to || args.recipient;
    // This is big assumption that most of the transactions are using single signers to create accounts

    if (!recipient) {
      if (idRelated.traits.deposit) {
        recipient = idRelated.traits.deposit.args.to || "Private Collection 🤷‍♂️";
      }
    }
    return {
      type: tx.MINT,
      minters: signers,
      recipient,
    };
  }

  if (idRelated.traits.deposit) {
    const recipient = idRelated.traits.deposit.args?.to;
    const sender = idRelated.traits.withdraw?.args?.from || signers[0];

    return {
      type: tx.TRANSFER,
      recipient,
      sender,
    };
  }


  return {
    type: tx.UNKNOWN,
  };

  /*
  // EXPERIMENTAL
  const eventTypes = Object.keys(events.byId).reduce(
    (acc, id) => {
      const [, address, contract, eventName] = id.split(".");
      if (!acc.types[contract]) {
        acc.types[contract] = {};
      }
      acc.types[contract][eventName] = true;
      acc.unique[id] = true;
      return acc;
    },
    { types: {}, unique: {} }
  );

  console.log({ eventTypes });
  if (Object.keys(eventTypes.types).length === 1) {
    // These are interactions within the same contract
    // Let's try to identify them
    console.log({ unique: eventTypes.unique });
    const mintSignature = Object.keys(eventTypes.unique).find(
      (item) =>
        item.toLowerCase().includes("mint") ||
        item.toLowerCase().includes("create")
    );
    const mintEvents = events.byId[mintSignature];
    console.log({ mintEvents });

    const preciseMintEvent = mintEvents.find((event) => {
      console.log(event.id, context.id);
      return (event.id = context.id);
    });
    console.log({ preciseMintEvent });

    const recipient =
      preciseMintEvent.args.to || preciseMintEvent.args.recipient;

    if (mintSignature) {
      return {
        type: MINT,
        recipient,
      };
    }
  }
   */
}
