import React, { useEffect, useState } from "react";
import { Col, Dropdown, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import EventsSwitch from "../../functions/EventsSwitch";
import "./LiveFeedWrapper.scss";
import TimeAgo from "react-timeago";
import { Script } from "../../functions/script";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import parseImgUrl from "../../functions/parseImgUrl";
import {
  handleHideFindThoughts,
  handleReactToThought,
} from "../../functions/flow-interactions/transactions";
import { useStateChanged } from "../../functions/DisabledState";

const LiveFeedEventItemThought = ({ event, i, user }) => {
  const [thought, setThought] = useState(null);
  const [showReactionInput, setShowReactionInput] = useState(false);
  const reactionArray = [
    "🔥",
    "❤️",
    "👍",
    "🤣",
    "🙏",
    "🚀",
    "💯",
    "👀",
    "😐",
    "🤯",
  ];

  useEffect(() => {
    setShowReactionInput(false);
    if (event) {
      async function getFindThoughts(addresses, ids) {
        const getThoughts = await Script(scripts.getFindThoughts, {
          addresses: addresses,
          ids: ids,
        });
        setThought(getThoughts[0]);
      }
      try {
        // console.log("this");
        getFindThoughts(
          [event.blockEventData.creator],
          [event.blockEventData.id]
        );
        // console.log(thought);
      } catch (error) {
        console.log(error);
      }
    }
  }, [event, useStateChanged()]);

  // console.log(event)
  // console.log(switchedEvent);
  // console.log(event);

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    )
  );

  const handleReactClicked = (creator, id, reactIcon) => {
    console.log(reactIcon);
    handleReactToThought(creator, id, reactIcon);
  };

  const handleReactionInputOpen = () => {
    setShowReactionInput(!showReactionInput);
    if (showReactionInput) {
      document.getElementById("reaction-icon-row").focus();
    }
  };

  useEffect(() => {
    if (showReactionInput) {
      document.getElementById("reaction-icon-row").focus();
    }
  }, [showReactionInput]);

  const profileImg = (
    <img
      crossOrigin="anonymous"
      style={{ width: "48px", height: "48px", borderRadius: "50%" }}
      src={thought ? parseImgUrl(thought.creatorAvatar, "icon") : null}
    />
  );

  const renderTooltipReactions = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Add reaction</span>
    </Tooltip>
  );

  // console.log(switchedEvent)

  if (thought && thought.hidden) {
    const creatorLink = thought.creatorName
      ? `/${thought.creatorName}`
      : `/${thought.creator}`;
    return (
      <div key={thought.id} id="live-feed-event-item-wrapper" className="py-3">
        <div id="live-feed-event-item-row">
          <div id="lf-item-icon-box">
            <Link to={creatorLink}>
              {profileImg}
              {thought.creatorName && (
                <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
              )}{" "}
            </Link>
          </div>

          <div id="lf-item-info-box">
            <div id="live-feed-event-item-thought-profile-name-row">
              <Link to={creatorLink}>
                <span>{thought.creatorProfileName}</span>
              </Link>
            </div>
            {thought.creatorName && (
              <div id="live-feed-event-item-thought-find-name-row">
                <Link to={creatorLink}>
                  <span>
                    <span className="themeColor">{thought.creatorName}</span>
                    .find
                  </span>
                </Link>
              </div>
            )}

            <div id="live-feed-event-item-thought-content">
              This thought post was hidden
            </div>
          </div>
          <div id="lf-item-end-box">
            <div id="live-feed-event-item-price-row">
              <div id="lf-item-price-box">
                {/* {event.blockEventData.amount}&nbsp;{switchedEvent.txFtType} */}
              </div>
              <div id="lf-item-kebab-box">
                {user && user.profile.address === thought.creator && (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomDropdownToggle}
                      id="kebab-dropdown-toggle"
                    >
                      <img src="/assets/img/lf/lf-kebab.svg" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="secColor small"
                        onClick={() => {
                          handleHideFindThoughts([Number(thought.id)], [false]);
                        }}
                      >
                        Show this thought post
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
            <div id="lf-item-time-box">
              <TimeAgo
                date={new Date(thought.created * 1000)}
                timestyle="twitter"
                suffix="none"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (thought) {
    // console.log(thought);
    const creatorLink = thought.creatorName
      ? `/${thought.creatorName}`
      : `/${thought.creator}`;
    return (
      <div id="live-feed-event-item-wrapper" className="py-3">
        <div id="live-feed-event-item-row">
          <div id="lf-item-icon-box">
            <Link to={creatorLink}>
              {profileImg}
              {thought.creatorName && (
                <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
              )}{" "}
            </Link>
          </div>
          <div id="lf-item-info-box">
            <div id="live-feed-event-item-thought-profile-name-row">
              <Link to={creatorLink}>
                <span>{thought.creatorProfileName}</span>
              </Link>
            </div>
            {thought.creatorName && (
              <div id="live-feed-event-item-thought-find-name-row">
                <Link to={creatorLink}>
                  <span>
                    <span className="themeColor">{thought.creatorName}</span>
                    .find
                  </span>
                </Link>
              </div>
            )}
            <div id="live-feed-event-item-thought-header">{thought.header}</div>
            <div id="live-feed-event-item-thought-content">{thought.body}</div>
            {thought.tags[0] !== "" && thought.tags.length > 0 && (
              <div id="live-feed-event-item-thought-tag-row" style={{}}>
                {/* <i className="fa-solid fa-tags"></i> &nbsp; */}
                {thought.tags.map((tag, i) => (
                  <div key={i} className="tag-item">
                    <span className="tag-text">{tag}</span>
                  </div>
                ))}
              </div>
            )}
            <div id="live-feed-event-item-thought-reactions-row">
              <OverlayTrigger placement="top" overlay={renderTooltipReactions}>
                <div
                  onClick={() => {
                    handleReactionInputOpen();
                  }}
                  id="thought-reaction-toggle"
                >
                  {" "}
                  <div id="thought-reaction-box">
                    {!showReactionInput ? "➕" : "➖"}
                  </div>
                </div>
              </OverlayTrigger>
              {thought.reacted &&
                Object.keys(thought.reacted) &&
                Object.keys(thought.reacted).map((icon, i) => {
                  // console.log(thought.reacted[icon]);
                  const renderTooltipReacted = (props) => (
                    <Tooltip key={i} {...props}>
                      <div id="reacted-tooltip">
                        {thought.reacted[icon].map((reactor, i) => {
                          return (
                            <React.Fragment key={i}>
                              <span style={{ fontSize: "16px" }}>
                                {reactor.findName ? (
                                  <span>
                                    <span className="themeColor">
                                      {reactor.findName}
                                    </span>
                                    .find
                                  </span>
                                ) : (
                                  reactor.address
                                )}
                              </span>
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Tooltip>
                  );
                  return (
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltipReacted}
                    >
                      <div
                        key={i}
                        onClick={() =>
                          handleReactClicked(thought.creator, thought.id, icon)
                        }
                        id="thought-reaction-box"
                      >
                        {icon}&nbsp;
                        {Object.keys(thought.reacted[icon]).length}
                      </div>
                    </OverlayTrigger>
                  );
                })}

              {showReactionInput && (
                <div
                  tabindex={"-1"}
                  onBlur={() => setShowReactionInput(false)}
                  id="reaction-icon-row"
                >
                  {reactionArray.map((reactIcon, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() =>
                          handleReactClicked(
                            thought.creator,
                            thought.id,
                            reactIcon
                          )
                        }
                        id="thought-reaction-box"
                      >
                        {reactIcon}&nbsp;
                        {thought.reactions &&
                        Object.keys(thought.reactions).includes(reactIcon) ? (
                          <>{thought.reactions[reactIcon]}</>
                        ) : (
                          <>0</>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div id="lf-item-end-box">
            <div id="live-feed-event-item-price-row">
              <div id="lf-item-price-box">
                {/* {event.blockEventData.amount}&nbsp;{switchedEvent.txFtType} */}
              </div>
              <div id="lf-item-kebab-box">
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomDropdownToggle}
                    id="kebab-dropdown-toggle"
                  >
                    <img src="/assets/img/lf/lf-kebab.svg" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      target="_blank"
                      className=" "
                      rel="noreferrer"
                      href={
                        process.env.REACT_APP_BLOCK_VIEWER_URL +
                        event.flowTransactionId
                      }
                    >
                      View transaction
                    </Dropdown.Item>
                    {user && user.profile.address === thought.creator && (
                      <Dropdown.Item
                        className="secColor small"
                        onClick={() => {
                          handleHideFindThoughts([Number(thought.id)], [true]);
                        }}
                      >
                        Hide this thought post
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div id="lf-item-time-box">
              <TimeAgo
                date={new Date(Number(thought.created * 1000))}
                timestyle="twitter"
                suffix="none"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="live-feed-event-item-wrapper" className="py-3">
        <div id="live-feed-event-item-row">
          <div id="lf-item-icon-box"></div>

          <div id="lf-item-info-box">
            <div id="live-feed-event-item-thought-profile-name-row">
              <span>Fetching thought...</span>
            </div>
          </div>
          <div id="live-feed-event-item-price-row">
            <div id="lf-item-price-box">
              {/* {event.blockEventData.amount}&nbsp;{switchedEvent.txFtType} */}
            </div>
            <div id="lf-item-kebab-box">
              <img src="/assets/img/lf/lf-kebab.svg" />
            </div>
          </div>
          <div id="lf-item-time-box"></div>
        </div>
      </div>
    );
  }
};

export default LiveFeedEventItemThought;
