import React, { useState } from "react";
import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { Tx } from "../../../../../functions/script";
import { scripts, transactions } from "@findonflow/find-flow-contracts-1.0";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import "./ColDetailOwnItemSendItem.scss";
import { useFormStatus } from "../../../../../functions/DisabledState";
import ColDetailOwnItemSendItemInfo from "./ColDetailOwnItemSendItemInfo";
import ColDetailOwnItemSendItemConfirm from "./ColDetailOwnItemSendItemConfirm";
import { useSwipeable } from "react-swipeable";

const itemBlacklisted = listingStatus => {
  if(listingStatus.aDapperItem){
    // Enable only for specific collections
    if(listingStatus.itemCollection === "Wearables"){
      return false
    }
    return true
  }
  return false
}

const ColDetailOwnItemSendItem = (props) => {
  const { listingStatus } = props;
  const [showSendNFTModal, setShowSendNFTModal] = useState(false);
  const [sendNFTAddress, setSendNFTAddress] = useState("");
  const [sendNFTMemo, setSendNFTMemo] = useState("");
  const [currentStage, setCurrentStage] = useState(1);
  const [royaltyDonation, setRoyaltyDonation] = useState({
    amount: null,
    type: "A.1654653399040a61.FlowToken.Vault",
  });
  // const [findRoyaltyDonation, setFindRoyaltyDonation] = useState({
  //   amount: null,
  //   type: "A.1654653399040a61.FlowToken.Vault",
  // });
  const navigate = useNavigate();
  const themeStatus = UseThemeStatus();
  // console.log(royaltyDonation);
  const sendNFT = async (e) => {
    e.preventDefault();
    const address = sendNFTAddress.trim().toLowerCase();
    if (
      (address.length === 18 && address.includes("0x")) ||
      (address.length > 2 && address.length <= 16)
    ) {
      if (listingStatus.itemCollection === "Wearables"){
        await Tx({
          tx: transactions.sendWearables,
          args: {
            allReceivers: [address],
            ids: [listingStatus.itemId],
            memos: [sendNFTMemo]
          },
          callbacks: {
            async onSuccess(status){
              console.log("success")
              navigate("/me/collection")
            }
          }
        })
      }else{
        try {
          // console.log([listingStatus.itemType]);
          // console.log([address]);
          // console.log([parseInt(listingStatus.itemMetaData.id)]);
          // console.log([sendNFTMemo]);
          // console.log([royaltyDonation.type]);
          // console.log([royaltyDonation.amount]);
          // console.log(findRoyaltyDonation.type);
          // console.log(findRoyaltyDonation.amount);

          await Tx({
            tx: transactions.sendNFTs,
            args: {
              nftIdentifiers: [listingStatus.itemType],
              allReceivers: [address],
              ids: [parseInt(listingStatus.itemMetaData.id)],
              memos: [sendNFTMemo],
              donationTypes: royaltyDonation.amount
                ? [royaltyDonation.type]
                : [null],
              donationAmounts: royaltyDonation.amount
                ? [parseFloat(royaltyDonation.amount) / 2]
                : [null],
              findDonationType: royaltyDonation.amount
                ? royaltyDonation.type
                : null,
              findDonationAmount: royaltyDonation.amount
                ? parseFloat(royaltyDonation.amount) / 2
                : null,
            },
            callbacks: {
              async onSuccess(status) {
                console.log("success");
                navigate("/me/collection");
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      toast.error("Please enter a valid wallet address or .find name");
    }
  };

  const handleSendNFTModal = () => {
    setShowSendNFTModal(!showSendNFTModal);
    setCurrentStage(1);
  };

  //mobile swipedown close
  const config = {
    delta: 150, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,
    swipeDuration: 250,
  };
  const handlers = useSwipeable({
    onSwipedDown: () => {
      // console.log('this')
      handleSendNFTModal();
    },
    ...config,
  });

  if(itemBlacklisted(listingStatus)){
    return null
  }

  return (
    <div id="send-modal-wrapper">
      <Button
        onClick={() => {
          setShowSendNFTModal(!showSendNFTModal);
        }}
        variant="find-collection-outline"
      >
        Send NFT
      </Button>
      <Modal
        id="send-item-modal"
        show={showSendNFTModal}
        onHide={handleSendNFTModal}
        centered
        data-theme={themeStatus}
      >
        <div {...handlers}>
          <Modal.Header
            style={{ border: "none" }}
            closeButton
            closeLabel="close window"
          ></Modal.Header>
          <Modal.Body>
            {currentStage === 1 ? (
              <ColDetailOwnItemSendItemInfo
                sendNFTAddress={sendNFTAddress}
                setSendNFTAddress={setSendNFTAddress}
                sendNFTMemo={sendNFTMemo}
                setSendNFTMemo={setSendNFTMemo}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
                listingStatus={listingStatus}
                handleSendNFTModal={handleSendNFTModal}
              />
            ) : (
              <ColDetailOwnItemSendItemConfirm
                sendNFTAddress={sendNFTAddress}
                setSendNFTAddress={setSendNFTAddress}
                sendNFTMemo={sendNFTMemo}
                setSendNFTMemo={setSendNFTMemo}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
                listingStatus={listingStatus}
                sendNFT={sendNFT}
                royaltyDonation={royaltyDonation}
                setRoyaltyDonation={setRoyaltyDonation}
                // findRoyaltyDonation={findRoyaltyDonation}
                // setFindRoyaltyDonation={setFindRoyaltyDonation}
              />
            )}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ColDetailOwnItemSendItem;
