import React from "react";
import { Container } from "react-bootstrap";

const HelpHubHeading = () => {
  return (
    <Container fluid={+true} className="pt-3">
      <h3>The HelpHub</h3>
      <p>
        Welcome to the .find HelpHub. <br /> Below you will find a guide to help
        you to get started on the platform. <br /> Need more info? You can catch
        us on <a href="https://discord.gg/tCSFSpFcRv">discord</a>.{" "}
      </p>
    </Container>
  );
};

export default HelpHubHeading;
