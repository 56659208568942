import React, { useState, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import FindPackModal from "../FindPackModalMk2";
import Bl0xModal from "../bl0xmodal";

import parseImgUrl from "../../../../../functions/parseImgUrl";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import useToastTxStatus from "../../../../../functions/useToastTxStatus";
import {changeState} from "../../../../../functions/DisabledState";

export default function ColDetailPackOpen(props){
  const { listingStatus, colName } = props;
  const { openPackClicked, setOpenPackClicked } = props;

  const [bl0xModalOpen, setBl0xModalOpen] = useState(false);
  const themeStatus = UseThemeStatus();
  const toastStatus = useToastTxStatus();

  const navigate = useNavigate()
  const location = useLocation();

  const isBloxPack = colName.toLowerCase().includes("Bl0xPack".toLowerCase());

  const showBloxModal = bl0xModalOpen && isBloxPack;
  const showFindModal = bl0xModalOpen && !isBloxPack;

  const packId = parseInt(listingStatus?.listingDetails?.nftDetail?.id || "0")
  const thumbnail = parseImgUrl(listingStatus?.itemThumbnail || "")

  const onHide = useCallback((packOpened ) => {
    setBl0xModalOpen(!bl0xModalOpen)

    // We need to navigate out of this view if the pack has been opened
    if(packOpened){
      changeState()
      const to = location.pathname.split("/").slice(0,-1).join("/")
      navigate(to)
    }
  },[packId,bl0xModalOpen]);

  return (
    <>
      <Button
        onClick={() => setBl0xModalOpen(!bl0xModalOpen)}
        variant="find-collection-pack-outline"
        className="mb-3"
      >
        Open Pack
      </Button>

      {showBloxModal && (
        <Modal
          onHide={onHide}
          centered
          data-theme={themeStatus}
        >
          <Bl0xModal
            imgUrl={parseImgUrl(listingStatus.itemThumbnail)}
            bl0xModalOpen={bl0xModalOpen}
            setBl0xModalOpen={setBl0xModalOpen}
            listingStatus={listingStatus}
            toastStatus={toastStatus}
            openPackClicked={openPackClicked}
            setOpenPackClicked={setOpenPackClicked}
          />
        </Modal>
      )}

      {showFindModal && (
        <FindPackModal onHide={onHide} packId={packId} thumbnail={thumbnail} key={packId}/>
      )}
    </>
  );
};
