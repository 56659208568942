import React, { useState } from "react";
import { Button, Col, Form, Image, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { transactions } from "@findonflow/find-flow-contracts-1.0";
import { Tx } from "../../../../../functions/script";
import ReactGA from "react-ga4";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import { useFormStatus } from "../../../../../functions/DisabledState";
import "./ColDetailBurnItem.scss";
import { useSwipeable } from "react-swipeable";

const ColDetailBurnItem = ({ listingStatus }) => {
  const [burnModalOpen, setBurnModalOpen] = useState(false);
  const [burnMemo, setBurnMemo] = useState("");
  const [confirmBurn, setConfirmBurn] = useState("");

  const navigate = useNavigate();

  const handleBurnNFTs = async (e) => {
    e.preventDefault();
    try {
      await Tx({
        tx: transactions.burnNFTs,
        args: {
          types: [listingStatus.itemType],
          ids: [Number(listingStatus.itemId)],
          messages: [burnMemo],
        },
        callbacks: {
          async onSuccess(status) {
            console.log("success");
            navigate("/me/collection/");

            ReactGA.event({
              category: "Burn NFT",
              action: "User Burned NFT",
              label: "Furnace - Burned",
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

//mobile swipedown close
const config = {
  delta: 150, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: true,
  swipeDuration: 250,
};
const handlers = useSwipeable({
  onSwipedDown: () => {
    // console.log('this')
    setBurnModalOpen(false);
  },
  ...config,
});

  // console.log(listingStatus);
  return (
    <div id="burn-NFTs-wrapper">
      <Button
        // onClick={() =>
        //   handleBurnNFTs(
        //     [listingStatus.itemType],
        //     [Number(listingStatus.itemId)],
        //     [""]
        //   )
        // }
        onClick={() => setBurnModalOpen(!burnModalOpen)}
        variant="find-collection-burn-outline"
        className="mb-3 mt-3"
      >
        Burn NFT
      </Button>
      <Modal
        id="burn-item-modal"
        show={burnModalOpen}
        onHide={() => setBurnModalOpen(!burnModalOpen)}
        centered
        data-theme={UseThemeStatus()}
      >
                <div {...handlers}>

        <Modal.Header
          style={{ border: "none" }}
          closeButton
          closeLabel="close window"
        >
          <Modal.Title className="text-center w-100">
            <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
              Burn {listingStatus.itemCollection} -&nbsp;
              {listingStatus.itemName}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-center mb-3">
            <Image crossOrigin="anonymous" src={parseImgUrl(listingStatus.itemThumbnail, 'thumbnail')} />
          </div>
          <Form onSubmit={(e) => handleBurnNFTs(e)}>
            <fieldset disabled={useFormStatus()}>
              <Form.Group className="mt-3">
                <Form.Label htmlFor="burn-memo-message">
                  Message (Optional)
                </Form.Label>
                <Form.Control
                  id="burn-memo-message"
                  name="memo"
                  placeholder="Enter a message"
                  type="memo"
                  onChange={(e) => setBurnMemo(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label htmlFor="burn-confirm-input" id="burn-warning">
                  <i className="fa-solid fa-circle-info" /> Warning: Burning an
                  NFT is permanent and NFTs burnt through the .find furnace are
                  unrecoverable.
                </Form.Label>
                <Form.Label htmlFor="burn-confirm-input" id="burn-warning">
                  If you are sure you want to burn{" "}
                  <b>{listingStatus.itemName}</b>, please enter 'Confirm' in the
                  box below to continue.
                </Form.Label>

                <Form.Control
                  id="burn-confirm-input"
                  name="burnConfirm"
                  placeholder="Enter 'Confirm' to continue"
                  type="memo"
                  onChange={(e) => setConfirmBurn(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Col align="center" className="mt-4 burn-item-col">
                <Button
                  type="submit"
                  variant="find-collection-burn-outline"
                  disabled={confirmBurn.toLowerCase() !== "confirm"}
                >
                  Burn
                </Button>
              </Col>
            </fieldset>
          </Form>
        </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ColDetailBurnItem;
