import React, { useEffect, useState } from "react";
import { Accordion, Dropdown, Form } from "react-bootstrap";
import { useRefinementList } from "react-instantsearch-hooks-web";
// import { createRoutesFromChildren } from "react-router";
import "./marketplace.css";
import "./CustomSearchBox.css";

export default function CustomRefinementList(props) {
  
  const {
    items,
    refine,
    // hasExhaustiveItems,
    // createURL,
    // sendEvent,
    // searchForItems,
    // isFromSearch,
    // canRefine,
    // canToggleShowMore,
    // isShowingMore,
    // toggleShowMore,
  } = useRefinementList({
    attribute: props.attribute,
  });
// console.log(props)


  const [sortedItems, setSortedItems] = useState(null);
  useEffect(() => {
    if (items && Object.keys(items).length > 0) {
      setSortedItems(
        Object.values(items).sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [items]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Accordion.Header ref={ref} className="">
      <span className="smallTextSpan">
        {children[0].toUpperCase() + children.substring(1).replace("_", " ")}
      </span>
    </Accordion.Header>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div className="px-0 ">
          <ul className="list-unstyled ">{children}</ul>
        </div>
      );
    }
  );

  const handleFilterSearch = (event) => {
    // console.log(event.target.value)
    // searchForItems(event.currentTarget.value);
  };

  const handleCheckedRefinement = (value) => {
    // let isChecked = e.target.checked;
    //console.log(e.target.value);
    refine(value);
  };

  // console.log(items)
  // console.log(sortedItems)
  return (
    <div className="">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {props.attribute.replace("string.", "")}
      </Dropdown.Toggle>

      <Accordion.Body as={CustomMenu}>
        <Form>
          <form action="" role="search" className="main-search mb-4">
            <div className="search-wrapper-box">
              <i className="fa-solid fa-magnifying-glass search-icon mx-2" />
              <input
            placeholder="Search filters"
            type="text"
            className="input search-input smallTextSpan"
            onChange={handleFilterSearch}
            
          />
            </div>
          </form>
          {sortedItems &&
            sortedItems.map((item, i) => {
              // console.log({item})
              return (
                <div
                  className="check-box py-2"
                  key={i}
                  onClick={() => handleCheckedRefinement(item.value)}
                >
                  <Form.Check
                    id={item.value}
                    value={item.value}
                    checked={item.isRefined}
                  />
                  <Form.Label
                    className="mb-0 smallTextSpan"
                    style={{marginLeft: "10px"}}
                    htmlFor={item.value}
                  >
                    {item.label}
                  </Form.Label>
                </div>
              )
              // <div
              //   key={i}
              //   eventKey={i}
              //   onClick={(event) => addRefinement(event, item.value)}
              // >
              //   {item.label.replaceAll('_', ' ')}
              // </div>
            })}
        </Form>
      </Accordion.Body>
    </div>
  );
}
