import { useCallback, useState } from "react";
import {
  usePagination,
  useSearchBox,
} from "react-instantsearch-hooks-web";

const messages = [
  "What you are looking for?",
  "Pants maybe",
  "Type: a",
  "Type: b",
  "Type: flower",
  "Need socks?",
  "Universe is great, they say",
  "It's cold grab a sweater",
];

const getRandomMessage = () => {
  const { length } = messages;
  const randomIndex = Math.min(Math.round(Math.random() * length), length - 1);
  return messages[randomIndex];
};

export default function Search() {
  const { nbHits } = usePagination();

  const { query, refine } = useSearchBox();
  const onChange = useCallback((e) => {
    refine(e.target.value);
  }, []);

  const [placeholder, setPlaceholder] = useState(getRandomMessage());

  const reset = () => {
    setPlaceholder(getRandomMessage());
    refine("");
  };

  return (
    <div className="filter__content__group">
      <div className="filter__search-title">
        <h3>Show me:</h3>
        <span>{nbHits} items</span>
      </div>
      <div className="filter__search-wrapper">
        <input
          className="my-0"
          type="text"
          value={query}
          onChange={onChange}
          placeholder={placeholder}
        />
        <button className={"filter__round-button"} onClick={reset}>
          x
        </button>
      </div>
    </div>
  );
}
