import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import * as fcl from "@onflow/fcl";
import axios from "axios";
import EventsSwitch from "../../../functions/EventsSwitch";
import "../userstore.css";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../functions/parseImgUrl";

const StoreSold = ({ filterTerm }) => {
  const [eventsData, setEventsData] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState("");
  const [user, setUser] = useState({ loggedIn: null });
  const [runOnce, setRunOnce] = useState(false);

  let soldActivity = {};
  useEffect(() => fcl.currentUser().subscribe(setUser), []);
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const graffleLogo = (
    <Image
      fluid={+true}
      src="/assets/img/home/graffle.webp"
      alt="Graffle Logo"
    />
  );
  const findLogo = (
    <Image
      className="store-thumb-image"
      src="/assets/img/store/fnamestore.png"
    />
  );
  useEffect(() => {
    const getEvents = async () => {
      // //console.log("getSales fired")
      let data;
      let res = await axios.get(
        "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?eventType=A." +
          contractAddress +
          ".FindMarketAuctionEscrow.EnglishAuction,A." +
          contractAddress +
          ".FindMarketSale.Sale,A." +
          contractAddress +
          ".FindMarketDirectOfferEscrow.DirectOffer,A." +
          contractAddress +
          ".FIND.Sale,A." +
          contractAddress +
          ".FIND.EnglishAuction,A." +
          contractAddress +
          ".FIND.DirectOffer&seller=" +
          user.addr
      );
      data = res.data;
      setEventsData(
        data.filter((event) => event.blockEventData.status === "sold")
      );
      setFilteredEvents(
        data
          .filter((event) => event.blockEventData.status === "sold")
          .filter(
            (event) =>
              event?.blockEventData?.name
                ?.toLowerCase()
                .includes(filterTerm.toLowerCase()) ||
              event.blockEventData?.nft?.name
                .toLowerCase()
                .includes(filterTerm.toLowerCase()) ||
              event.blockEventData?.nft?.collectionName
                .toLowerCase()
                .includes(filterTerm.toLowerCase())
          )
      );
    };
    getEvents();
  }, [user]);

  useEffect(() => {
    setFilteredEvents(
      eventsData.filter(
        (event) =>
          event?.blockEventData?.name
            ?.toLowerCase()
            .includes(filterTerm.toLowerCase()) ||
          event.blockEventData?.nft?.name
            .toLowerCase()
            .includes(filterTerm.toLowerCase()) ||
          event.blockEventData?.nft?.collectionName
            .toLowerCase()
            .includes(filterTerm.toLowerCase())
      )
    );
  }, [filterTerm]);

  // //console.log(eventsData)

  return (
    <Container fluid={+true} id="store-names-listings" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row className="pb-2">
            <Col align="left">
              <h4>Items you have sold</h4>
            </Col>
          </Row>

          <table className="store-table" id="store-sold-table">
            <tbody>
              {eventsData && filteredEvents.length > 0 ? (
                <>
                  {filteredEvents.map((activity, i) => {
                    // console.log(activity);
                    soldActivity = EventsSwitch(activity);
                    // //console.log(soldActivity);

                    return (
                      <React.Fragment key={i + "store-sold-row"}>
                        <tr id={i + "store-sold-row"} className="store-row">
                          <td className="store-thumb-col">
                            {soldActivity.itemType !== "nft" ? (
                              <div className="store-thumb-image">
                                {findLogo}
                              </div>
                            ) : (
                              <Image
                              crossOrigin="anonymous"

                                className="store-thumb-image"
                                fluid={+true}
                                src={parseImgUrl(soldActivity.imgUrl, 'icon')}
                              />
                            )}
                          </td>
                          <td className="store-name-col">
                            {soldActivity.itemType !== "nft" ? (
                              <Link to={"/mp/FIND/" + soldActivity.itemName}>
                                <span className="fw-bold">
                                  <span className="themeColor">
                                    {soldActivity.itemName}
                                  </span>
                                  .find{" "}
                                </span>
                              </Link>
                            ) : (
                              <Link
                                to={
                                  "/mp/" +
                                  activity.blockEventData.nft.collectionName +
                                  "/" +
                                  activity.blockEventData.id
                                }
                              >
                                <span className="fw-bold">
                                  {soldActivity.itemName}
                                </span>
                              </Link>
                            )}
                          </td>
                          <td className="store-sticker-col">
                            <small style={{ color: "var(--text-secondary" }}>
                              {new Date(
                                activity.eventDate
                              ).toLocaleTimeString()}{" "}
                              <br />
                              {new Date(
                                activity.eventDate
                              ).toLocaleDateString()}{" "}
                            </small>
                          </td>
                          <td className="store-price-col">
                            <span className="d-none d-lg-block">
                              {" "}
                              {soldActivity.whatHappened} for:{" "}
                            </span>
                            <b>
                              {parseFloat(soldActivity.txAmount).toFixed(2)}{" "}
                              {soldActivity.txFtType}{" "}
                            </b>
                          </td>
                          <td className="store-time-left-col">
                            <a
                              className="store-sticker"
                              target="_blank"
                              rel="noreferrer"
                              href={
                                process.env.REACT_APP_BLOCK_VIEWER_URL +
                                activity.flowTransactionId
                              }
                            >
                              View Transaction
                            </a>
                          </td>
                          <td className="store-buttons-col secColor">
                            <small>
                              to{" "}
                              <b>
                                {soldActivity.toIsName ? (
                                  <Link to={"/" + soldActivity.toName}>
                                    <span>
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  activity.blockEventData.buyer
                                )}
                              </b>
                            </small>
                          </td>
                        </tr>
                        <tr className="mobile-row">
                          <td className="mobile-store-sticker-col">
                            <a
                              className="store-sticker"
                              target="_blank"
                              rel="noreferrer"
                              href={
                                process.env.REACT_APP_BLOCK_VIEWER_URL +
                                activity.flowTransactionId
                              }
                            >
                              View TX
                            </a>
                          </td>
                          <td className="mobile-store-time-left-col secColor">
                            <small style={{ color: "var(--text-secondary" }}>
                              {new Date(
                                activity.eventDate
                              ).toLocaleTimeString()}{" "}
                              <br />
                              {new Date(
                                activity.eventDate
                              ).toLocaleDateString()}{" "}
                            </small>
                          </td>
                          <td align="end">
                            <small>
                              to{" "}
                              <b>
                                {soldActivity.toIsName ? (
                                  <Link to={"/" + soldActivity.toName}>
                                    <span>
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  activity.blockEventData.buyer
                                )}
                              </b>
                            </small>
                          </td>
                        </tr>
                      </React.Fragment>

                      // <Row key={i} className="my-4 align-items-center">
                      //   <Col className="col-1" xs="2" md="1">
                      //     {soldActivity.itemType === "nft" ? (
                      //       <div style={{ borderRadius: "10px" }}>
                      //         {soldActivity.eventIcon}
                      //       </div>
                      //     ) : (
                      //       <div>{findLogo} </div>
                      //     )}
                      //   </Col>

                      //   <Col xs="10" md="auto">
                      //     <b>{soldActivity.itemName}</b>
                      //   </Col>
                      //   <Col
                      //     style={{ color: "var(--text-secondary" }}
                      //     xs="12"
                      //     md="auto"
                      //   >
                      //     {soldActivity.whatHappened} for:{" "}
                      //     <b>
                      //       {parseFloat(soldActivity.txAmount).toFixed(2)}{" "}
                      //       {soldActivity.txFtType}{" "}
                      //     </b>
                      //   </Col>
                      //   {/* <Col>
                      //       <small style={{ color: "var(--text-secondary" }}>
                      //       {new Date(activity.eventDate).toLocaleDateString()} -{" "}
                      //       {new Date(activity.eventDate).toLocaleTimeString()}{" "}
                      //     </small>
                      //       </Col> */}
                      //   <Col className="d-none d-md-block" align="right">
                      //     <small style={{ color: "var(--text-secondary" }}>
                      //       to <b>{soldActivity.buyerName}</b>
                      //     </small>
                      //   </Col>
                      //   <Col className="d-md-none">
                      //     <small style={{ color: "var(--text-secondary" }}>
                      //       to <b>{soldActivity.buyerName}</b>
                      //     </small>
                      //   </Col>
                      // </Row>
                    );
                  })}
                </>
              ) : (
                <tr className="py-2 store-row">
                  <td>
                    <small>No Recent Activity</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Row>
            <Col align="center">
              {" "}
              <a
                className="graffleLink"
                href="https://graffle.io"
                rel="noreferrer"
                target="_blank"
              >
                {graffleLogo} Powered by Graffle
              </a>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StoreSold;
