import * as Sentry from "@sentry/react";
import * as fcl from "@onflow/fcl";
import { Col } from "react-bootstrap";
import toast from "react-hot-toast";

import { changeState, disableForm, enableForm } from "./DisabledState";
import { mapValuesToCode } from "./cadut";
import TransactionError from "../sentry/TransactionError";

import "../styles/toast.scss";

export const Script = async (script, inputArgs) => {
  const args = [];

  try {
    if (script.spec !== null) {
      for (var key in script.spec.order) {
        const value = script.spec.order[key];

        if (!(value in inputArgs)) {
          throw new Error("'" + value + "' is not present in arguments");
        }
        args.push(inputArgs[value]);
      }
    }
  } catch (e) {
    console.error(e)
  }

  const cadutArgs = await mapValuesToCode(script.code, args);

  try {
    const response = await fcl.send([
      fcl.script(script.code),
      fcl.args(cadutArgs),
    ]);
    return await fcl.decode(response);
  } catch (e) {
    console.log({ Error: e }, { Script: script }, { Args: args });
  }
};

const call = async (method, ...args) => {
  if (typeof method === "function") {
    return method(...args);
  }
};

function makeLink(txId) {
  return process.env.REACT_APP_BLOCK_VIEWER_URL + txId;
}

async function prepareArguments(tx, args) {
  const { code, spec } = tx;
  const checkedArgs = [];
  for (let key in spec.order) {
    const value = spec.order[key];
    if (!(value in args)) {
      throw new Error("'" + value + "' is not present in arguments");
    }
    checkedArgs.push(args[value]);
  }
  return mapValuesToCode(code, checkedArgs);
}

function applyReplacements(code, replacements) {
  let updatedCode = code;
  if (replacements) {
    Object.keys(replacements).forEach((element) => {
      updatedCode = updatedCode.replaceAll(element, replacements[element]);
    });
  }
  return updatedCode;
}

function Toast(props) {
  const { title, txId } = props;
  const link = makeLink(txId);
  console.log({ link });
  return (
    <Col className={"custom-toast"}>
      <p className={"text-center"}>{title}</p>
      <p>
        Click
        <a href={link} target="_blank" rel="noreferrer">
          HERE
        </a>
        to view this transaction.
      </p>
    </Col>
  );
}

function showStatus(type, { txId, toastId }) {
  let toastMethod;
  const compare = type.toLowerCase();
  switch (compare) {
    case "submitted": {
      toastMethod = toast.loading;
      break;
    }
    case "successful": {
      toastMethod = toast.success;
      break;
    }
    case "failed": {
      toastMethod = toast.error;
      break;
    }
    default: {
      toastMethod = () => {};
    }
  }

  logTransactionLink(type, txId);

  return toastMethod(<Toast title={`Transaction ${type}`} txId={txId} />, {
    id: toastId,
  });
}

function logTransactionLink(type, txId) {
  const txLink = makeLink(txId);
  const message = `Transaction ${txId} ${type}: ${txLink}`;
  console.debug(message, type);
}

function processErrorMessage(message, options) {
  const { txId, toastId, hasSubmitted } = options;
  const errorMessages = {
    txCanceledByUser: "Transaction Cancelled by user",
    loginCanceledByUser: "Login cancelled by user",
    notValidFindUser: "User doesn't exist, please create a profile on .find",
    notEnoughBalance:
      "You do not have enough tokens in your wallet for this transaction",
    notEnoughFunds:
      "Your wallet does not have enough funds to pay for this item",

    generic: `Transaction Error: ${message}`,
  };

  // Init toast message with generic message
  let toastMessage = errorMessages.generic;

  // Not enough tokens
  if (
    message.includes(
      "Amount withdrawn must be less than or equal than the balance of the Vault"
    )
  ) {
    toastMessage = errorMessages.notEnoughBalance;
  }

  if (
    message.includes(
      "Your wallet does not have enough funds to pay for this item"
    )
  ) {
    toastMessage = errorMessages.notEnoughFunds;
  }

  // Not a valid FIND user
  if (message.includes("Not a valid FIND user")) {
    toastMessage = errorMessages.notValidFindUser;
  }

  // User rejected transaction
  if (
    message.includes("User rejected signature") ||
    message.includes("Declined by user") ||
    message.includes("Externally Halted")
  ) {
    toastMessage = errorMessages.txCanceledByUser;
  }

  // User rejected login
  if (message.includes("Cannot read properties of undefined")) {
    toastMessage = errorMessages.loginCanceledByUser;
  }

  // Show toast
  if (hasSubmitted) {
    showStatus("Failed", { txId, toastId });
  } else {
    toast.error(toastMessage, { id: toastId });
  }
}

export async function Tx(props = {}) {
  const { tx, args, callbacks } = props;
  const { skip = {} } = props;
  const { code, replacements } = tx;

  const mappedArgs = await prepareArguments(tx, args);
  const cadence = applyReplacements(code, replacements);

  // Init variables we will update later
  let hasSubmitted = false;
  let txId = null;
  let txLink = null;
  let txDetails = null;
  let toastId = 0;
  if (!skip["loading"]) {
    toastId = toast.loading("Preparing transaction");
  }

  try {
    const { onStart, onSubmission, onSuccess } = callbacks;

    // Start Process
    call(onStart).then();
    const currentUser = fcl.currentUser().authorization;

    // Submit transaction to network
    if (!skip["start"]) {
      toast.loading("Transaction Started", { id: toastId });
    }

    // Disabled interactions on site
    disableForm();

    txId = await fcl
      .send([
        fcl.transaction(cadence),
        fcl.args(mappedArgs),
        fcl.payer(currentUser),
        fcl.proposer(currentUser),
        fcl.authorizations([currentUser]),
        fcl.limit(9999),
      ])
      .then(fcl.decode);

    // Call "onSubmission" callback
    if (!skip["submit"]) {
      showStatus("Submitted", { txId, toastId });
    }
    call(onSubmission, txId).then();
    hasSubmitted = true;

    // Wait for it to be sealed
    txDetails = await fcl.tx(txId).onceSealed();

    await call(onSuccess, txDetails);
    if (!skip["success"]) {
      showStatus("Successful", { txId, toastId });
    }

    if (!skip["state-change"]) {
      // This will pull update from the chain for user items.
      // We need to refactor this as it breaks multiple flows...
      changeState();
    }

    // Return transaction details for further processing
    return txDetails;
  } catch (error) {
    const { onError } = callbacks;
    call(onError, error).then();

    // Extract error message
    const message = error.message || error;
    console.error("Transaction failed:", message, "Link:", txLink);

    const txError = new TransactionError("Flow: Transaction Error");

    Sentry.withScope((scope) => {
      scope.setTag("tx-error");
      Sentry.captureException(txError);
    });

    processErrorMessage(message, { txId, toastId, hasSubmitted });
  } finally {
    console.log("Done!");
    const { onComplete } = callbacks;

    // Enable button interactions
    enableForm();

    // Call "onComplete" callback
    call(onComplete, txDetails).then();
  }
}
