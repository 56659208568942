import { useParams } from "react-router-dom";

export default function ItemDetails(props) {
	const { findUser } = props;
	const { id } = useParams();

	return (
		<>
			<h1>Doodles #{id} Details Page</h1>
			<p>Show some fancy info here</p>
		</>
	);
}
