import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DetailsItemHistoryCard from "../detailsitemhistory/detailsitemhistorycard";
import NFTDetails from "../../../../NFTHistoryMk2";

const ColDetailHistory = ({ listingStatus }) => {
  const collection = listingStatus?.itemType.split(".").slice(0, 3).join(".");
  const id = listingStatus?.itemId

  return (
    <NFTDetails collection={collection} id={id} />
  );
};

export default ColDetailHistory;
