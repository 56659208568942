import React, {  useEffect } from "react";
import { Col } from "react-bootstrap";
import { InstantSearch } from "react-instantsearch-hooks-web";
import { Autocomplete } from "../search/autocomplete/Autocomplete";
import searchClient from "../../functions/searchClient";
import "./navheadsearchbar.css";

const NavheadSearchBar = () => {

  useEffect(() => {
    document.body.classList.add("main-nav");
  }, []);

  return (
    <>
      <Col className="d-none d-lg-block" xs="1"></Col>
      <Col align="center" className="d-none d-lg-block">
        <InstantSearch indexName="market" searchClient={searchClient}>
          <Autocomplete
            searchClient={searchClient}
            placeholder="Search for names, profiles, or NFTs"
            detachedMediaQuery="none"
            openOnFocus
          />
        </InstantSearch>
        {/* <AutocompleteNew searchClient={searchClient} /> */}
      </Col>{" "}
    </>
  );
};

export default NavheadSearchBar;
