import React from 'react'
import { Container, Row } from 'react-bootstrap'

const SearchedMoreItems = () => {
  return (
    <Container className="d-flex flex-column align-items-center justify-content-center p-3 p-lg-0">


    <Row>
      Image cards and such here 
    </Row>
    <Row>
    <p>&lt;&nbsp; Page 1 &nbsp;&gt;</p>

    </Row>
    </Container>
  )
}

export default SearchedMoreItems