import './searchtabs.css'
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";

const SearchTabs = () => {
  return (
    <Container id="search-tabs" className="mb-3 px-0">
      <fieldset>
        <Row>
          <Col className="">
            <Tabs id="search-tabs" className="searchtabs">
              <Tab
                title="All items"
                eventKey="All Items"
                mountOnEnter
                unmountOnExit
              ></Tab>
              <Tab
                title="Names"
                eventKey="Names"
                mountOnEnter
                unmountOnExit
              ></Tab>
              <Tab
                title="NFTs"
                eventKey="NFTs"
                mountOnEnter
                unmountOnExit
              ></Tab>
              <Tab
                title="Profiles"
                eventKey="Profiles"
                mountOnEnter
                unmountOnExit
              ></Tab>
            </Tabs>
          </Col>
        </Row>
      </fieldset>
    </Container>
  );
};

export default SearchTabs;
