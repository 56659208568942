import { useEffect } from "react";
import { useFormStatus } from "../../../../functions/DisabledState";
import { Button, Form, Row, Col } from "react-bootstrap";
import { handleListNFTDirectSale } from "../../../../functions/flow-interactions/transactions";

// import { useNavigate } from "react-router";
import { useImmer } from "use-immer";
import toast from "react-hot-toast";
import "./listnft.scss";
import { useWalletType } from "../../../../functions/useWalletType";

export default function ListNftForSale(props){
  const { listingStatus, showSalesModal, setShowSalesModal } = props
  const walletType = useWalletType();
  const ftDapperArray = walletType === "Dapper Wallet"
    ? listingStatus.dapperAllowedListingActions.FindMarketSale.ftAlias
    : listingStatus.allowedListingActions.FindMarketSale.ftAlias

  const ftNonDapperArray = listingStatus?.allowedListingActions?.FindMarketSale?.ftAlias

  // let navigate = useNavigate();
  const [formValues, setFormValues] = useImmer([
    {
      id: "amount",
      value: "0",
    },
    {
      id: "ftAlias",
      value: ftDapperArray[0],
    },
    {
      id: "id",
      value: listingStatus.itemId,
    },
    {
      id: "nftAlias",
      value: listingStatus.itemType,
    },
    {
      id: "validUntil",
      value: null,
    },
  ]);
  // useEffect(() => {
  //   if (nftDetails) {
  //     setFormValues((draft) => {
  //       const varVal = draft.find((varVal) => varVal.id === "id");
  //       varVal.value = nftDetails.id;
  //     });
  //   }
  // }, [nftDetails, setFormValues]);

  // useEffect(() => {
  //   if (listingDetails) {
  //     setFormValues((draft) => {
  //       const varVal = draft.find((varVal) => varVal.id === "nftAlias");
  //       varVal.value = listingDetails.nftDetail.type;
  //     });
  //   }
  // }, [listingDetails, setFormValues]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(formValues)
    if (formValues[0].value >= 1) {
      // console.log(formValues)
      handleListNFTDirectSale(formValues, walletType);
    } else {
      toast.error("Please enter an amount of 1 or higher");
    }
  };

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value;
      //now validate
      if (varVal.value < 1) {
        e.target.classList.add("is-invalid");
        e.target.classList.remove("is-valid");
      } else {
        e.target.classList.add("is-valid");
        e.target.classList.remove("is-invalid");
      }
    });
    
  }

  return (
    <div id="list-sale-wrapper">
      <form noValidate onSubmit={handleSubmit}>
        <fieldset disabled={useFormStatus()}>
          <div id="list-currency-select-wrapper">
            <label htmlFor="list-amount-ftType">Select Currency</label>
            <select className="list-sale-select-wrapper" id="list-amount-ftType" name="ftAlias" onChange={updateField}>
              {!walletType === "Dapper Wallet" ?
              ftNonDapperArray.map((ft, i) => {
                return (
                  <option key={i} value={ft}>{ft}</option>
                )
              })
            :
            ftDapperArray.map((ft, i) => {
              let ftFriendlyName = ft.replace("FUT", "Flow").replace("DUC", "USD $")
              return (
                <option key={i} value={ft}>{ftFriendlyName}</option>
              )
            })
            }
            </select>
          </div>
          <div id="list-amount-input-wrapper">
            <label htmlFor="list-amount-input">List price</label>

            <input
              id="list-amount-input"
              name="amount"
              placeholder="Enter an amount"
              type="number"
              onChange={updateField}
              required
              min="0"
            />
          </div>
          <div id="list-button-row">
            <Button
              onClick={() => setShowSalesModal(!showSalesModal)}
              variant="find-outline-commerce-light"
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              disabled={useFormStatus() || formValues[0].value < 1}
              type="submit"
              variant="find-outline-commerce-dark"
            >
              List
            </Button>{" "}
          </div>
        </fieldset>
      </form>
    </div>
  );
};
