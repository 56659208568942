import { useEffect, useState } from "react";
import * as fcl from "@onflow/fcl";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useImmer } from "use-immer";
import { handleOffer } from "../../../../functions/flow-interactions/transactions";
import { useWalletCurrency } from "../../../../functions/useWalletType";


export function MakeOfferName({ lease }) {
    const [user, setUser] = useState({ loggedIn: null })
    useEffect(() => fcl.currentUser().subscribe(setUser), [])
    let validated = false

    const currency = useWalletCurrency()

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        // //console.log(event.target.bidAmt.value)
        if (form.bidAmt.value < 1) {
            event.preventDefault();
            event.stopPropagation();
            return
        } else
            event.preventDefault();
        //setValidated(true)
        handleOffer(formValues)
    }

    const [formValues, setFormValues] = useImmer([
        {
            id: "bidAmt",
            value: "1"
        },
        {
            id: "name",
            value: lease.name
        }
    ])

    function updateField(e) {
        setFormValues((draft) => {
            const varVal = draft.find((varVal) => varVal.id === e.target.name);
            varVal.value = e.target.value;
            //now validate
            if (varVal.value < 1) {
                e.target.classList.add("is-invalid")
                e.target.classList.remove("is-valid")
            }
            else {
                e.target.classList.add("is-valid")
                e.target.classList.remove("is-invalid")
            }
        })
    }
    return (
        <Container className="commerceinfo p-3">
            {lease.latestBidBy === user.addr && !lease.auctionEnds && lease.latestBidBy ?
                <Row>
                    <Col>
                    <p className="fw-bold">Congrats! You just made an offer of {parseFloat(lease.latestBid).toFixed(2)} {currency} for <span className="themeColor">{lease.name}</span>.find</p>
                    <div><small>Check your dashboard regularly for the status of your offer, and whether it has been accepted.</small></div>
                    </Col>
                    </Row>
                :
                <>
<Row>
                <p>How much would you like to offer for <b><span className="themeColor">{lease.name}</span>.find</b></p>
            </Row>
            <Row>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="form-inputs">
                    <Row>
                        <Col>
                            <Form.Control type="number" placeholder={`5 {currency}`} onChange={updateField} name="bidAmt" min="0" required/>
                        </Col>
                        <Col xs="auto"><Button variant="find-outline-commerce-dark" type="submit">Offer</Button></Col>
                    </Row>
                </Form>
            </Row></>
            }

        </Container>
    )
}