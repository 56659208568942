import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UseThemeStatus } from "../../functions/themeMode";

const NavheadThemeToggle = ({ switchTheme }) => {
  let currentTheme = UseThemeStatus();

  const renderTooltipTheme = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>
        Switch to {currentTheme === "light" ? "dark" : "light"} mode
      </span>
    </Tooltip>
  );

  return (
    <div className="">
      <OverlayTrigger placement="bottom" overlay={renderTooltipTheme}>
        <div id="mode-switch-label" onClick={() => switchTheme()} />
      </OverlayTrigger>
    </div>
  );
};

export default NavheadThemeToggle;
