import React from "react";
import { Col, Row } from "react-bootstrap";
import CreateProfileExploreOptions from "./CreateProfileExploreOptions";
import CreateProfileFormDesc from "./CreateProfileFormDesc";
import CreateProfileFormHeader from "./CreateProfileFormHeader";
import CreateProfileFormInfo from "./CreateProfileFormInfo";
import CreateProfileInput from "./CreateProfileInput";
import "./createprofile.scss";

const CreateProfileExploreFind = ({
  formValues,
  setFormValues,
  validation,
  currentStage,
  user,
  findUser
}) => {
  return (
    <div id="create-profile-form-wrapper">
      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormHeader currentStage={currentStage} user={user} findUser={findUser} />
        </Col>
      </Row>
      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormDesc currentStage={currentStage} />
        </Col>
      </Row>
      <div id='create-form-divider'className="seperator100 my-4 d-none d-lg-block"></div>

      <Row className="py-2 py-lg-2">
        <Col>
          <CreateProfileExploreOptions user={user} />
        </Col>
      </Row>
    </div>
  );
};

export default CreateProfileExploreFind;
