import React from "react";
import { useNavigate } from "react-router";

import Loading from "../../../components/loading/loading";

import DropBox from "../../../components/DropPageMk2/layout/DropBox";
import ProjectTitle from "../../../components/DropPageMk2/ProjectTitle";
import ProjectStatus from "../../../components/DropPageMk2/ProjectStatus";
import PriceBlock from "../../../components/DropPageMk2/PriceBlock";
import { Divider } from "../../../components/DropPageMk2/components";
import { Paragraph } from "../../../components/DropPageMk2/Text";

import usePackDetails from "../../../functions/usePackDetails";
import { purchasePack } from "../../../functions/flow-interactions/transactions";

// Extra info Defined per project
const project = {
  title: "Afterfuture Generated Experiences",
  totalPacks: 500,
  verified: true,
};

export default function MegaDrop() {
  const collectionName = "afterfuture";
  const packId = 1;
  const packDetails = usePackDetails(collectionName, packId);
  const navigate = useNavigate();

  const onSuccess = async () => {
    console.log("success");
    navigate("/me/collection/main/FindPackCollection");
  };

  if (!packDetails) {
    return <Loading />;
  }

  const { title, verified} = project;

  const { name, packsLeft, price, links } = packDetails;
  const { artwork, description } = packDetails;
  const { startsAt, finished } = packDetails;

  const progress = {
    current: project.totalPacks - parseInt(packsLeft),
    total: project.totalPacks,
  };

  const image = <img src={artwork} alt={`${name} - ${title}`} />;
  const current = new Date().getTime();
  const started = startsAt < current;

  const mint = (numOfPacks) => {
    const totalAmount = price * numOfPacks;
    const params = {
      numOfPacks,
      collectionName,
      packId,
      totalAmount,
      onSuccess,
    };

    purchasePack(params).then();
  };

  const Description = () => {
    const rewards = (
      <>
        <b>Pack Contents:</b> 1x Artwork, 1x Flovatar Background, and a chance
        for a .find voucher or bonus Artwork
      </>
    );
    const rarities = (
      <>
        <b>Rarities:</b> Common, Rare, Legendary, Mythical
      </>
    );
    return (
      <div className={"mb-4"}>
        {description.split(/\n/).map((content) => (
          <Paragraph content={content} className={"secColor"} />
        ))}
        <Paragraph content={rewards} className={"mb-1"} />
        <Paragraph content={rarities} className={"mb-1"}/>
      </div>
    );
  };

  const content = (
    <>
      <ProjectTitle
        name={name}
        title={title}
        verified={verified}
        links={links}
      />
      <ProjectStatus
        startsAt={startsAt}
        finished={finished}
        className={"mb-2"}
      />
      <Description />
      <PriceBlock
        price={price}
        started={started}
        progress={progress}
        mint={mint}
      />
    </>
  );
  return (
    <div className={"container mt-3"}>
      <DropBox content={content} image={image} />
      <Divider className={"dp__mb-2"} />
    </div>
  );
}
