import React, { useEffect, useState } from "react";
import Bl0xLoading from "../../../../loading/bl0xloading";
import "./ColDetailMediaContent.css";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import Lightbox from "react-image-lightbox";

//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
//swiper css imports
import "swiper/swiper.min.css";
import ColDetailMediaSliderCatalog from "./ColDetailMediaSliderCatalog";
import ColDetailMediaSliderShard from "./ColDetailMediaSliderShard";

const ColDetailMediaContent = ({ listingStatus }) => {


  if (listingStatus.aCatalogItem){
    return (
      <ColDetailMediaSliderCatalog listingStatus={listingStatus}/>
    )} else {
      return (
        <ColDetailMediaSliderShard listingStatus={listingStatus}/>
      )
    }
  }
  


  // if (listingStatus && itemMedia) {
  //   // console.log(Object.entries(itemMedia));

  //   return (
  //     <Swiper
  //     pagination={true}

  //       modules={[Pagination]}
  //       className="mySwiper"
  //       spaceBetween={1500}
  //       slidesPerView={1}
  //       // onSlideChange={() => console.log("slide change")}
  //       // onSwiper={(swiper) => console.log(swiper)}
  //     >

  //      listingStatus && itemMedia && <SwiperSlide key={"thumbnail"}>
  //         <img
  //           src={parseImgUrl(listingStatus.itemThumbnail, 'maxWidth')}
  //           onClick={() => setLightBoxOpen("thumbnail")}
  //         />
  //         {lightBoxOpen === "thumbnail" && (
  //           <Lightbox
  //             mainSrc={parseImgUrl(listingStatus.itemThumbnail, "", "", true)}
  //             onCloseRequest={() => setLightBoxOpen(null)}
  //           />
  //         )}
  //       </SwiperSlide>
  //       {itemMedia !== "thumbnail" &&
  //         Object.keys(itemMedia).map((mediaItem, i) => {
  //           // console.log(Object.keys(itemMedia));
  //           // console.log(itemMedia[mediaItem]);
  //           return (
  //             <SwiperSlide key={mediaItem}>
  //               {itemMedia[mediaItem].includes("video") ? (
  //                 <video
  //                   muted
  //                   loop=""
  //                   playsInline=""
  //                   controls
  //                   onMouseOver={(event) => event.target.play()}
  //                   onMouseOut={(event) => event.target.pause()}
  //                 >
  //                   <source
  //                     src={parseImgUrl(mediaItem, "", "", true)}
  //                     type={itemMedia[mediaItem]}
  //                   />
  //                   Sorry this video is not supported by your browser
  //                 </video>
  //               ) : (
  //                 <>
  //                   <img
  //                     src={parseImgUrl(mediaItem, "maxWidth")}
  //                     onClick={() => setLightBoxOpen(mediaItem)}
  //                   />
  //                   {lightBoxOpen === mediaItem && (
  //                     <Lightbox
  //                       mainSrc={parseImgUrl(mediaItem, "", "", true)}
  //                       onCloseRequest={() => setLightBoxOpen(null)}
  //                     />
  //                   )}
  //                 </>
  //               )}
  //             </SwiperSlide>
  //           );
  //         })}
  //     </Swiper>
  //   );
  // } else {
  //   return (
  //     <Swiper>
  //       {" "}
  //       <SwiperSlide>
  //         <Bl0xLoading />
  //       </SwiperSlide>
  //     </Swiper>
  //   );
  // }

export default ColDetailMediaContent;
