import * as fcl from "@onflow/fcl";
import React from "react";
import { Tab } from "bootstrap";
import { scripts, transactions } from "@findonflow/find-flow-contracts-1.0";
import { Script, Tx } from "../../../functions/script";
import { useEffect, useState, useImmer } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
  Spinner,
  Tabs,
  Button,
  Modal,
  InputGroup,
  DropdownButton,
  Dropdown,
  ModalFooter,
} from "react-bootstrap";
import { useStateChanged } from "../../../functions/DisabledState";
import { CollectionTabPage } from "./collectiontabpage/collectiontabpage";
import "./profilecollection.css";
import { useNavigate, useParams } from "react-router";
import { CreateAlbum } from "./createalbum/createalbum";
import { NftDetailPage } from "./nftdetailpage/nftdetailpage";
import useGetFactoryCollections from "../../../functions/useGetFactoryCollections";
import Loading from "../../loading/loading";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import EstimatedCollectionValue from "./estimatedcollectionvalue";
import CSVReader from "./ProfileCollectionSendBatch/csvreader";
import getShardScriptName from "../../../functions/getShardScriptName";
import parseImgUrl from "../../../functions/parseImgUrl";
import toast from "react-hot-toast";
import { useUserWalletStatus } from "../../../functions/useWalletType";
import useGetFactoryCollectionsMulti from "../../../functions/useGetFactoryCollectionsMulti";
import ProfileCollectionWalletTabs from "./ProfileCollectionWalletTabs/ProfileCollectionWalletTabs";
import { makeSearchClient } from "../../../functions/searchClient";

const searchClient = makeSearchClient("nft_name, collection_name")

export function ProfileCollection(props) {
  const { profile, profileData, addressNoProfile } = props
  const { type, display, searchedAddress } = props
  const user = useUserWalletStatus();
  const [profileAddressArray, setProfileAddressArray] = useState([]);
  const [profileMainAddress, setProfileMainAddress] = useState(null);
  const [nftData, setNftData] = useState(null);
  const { name, walletProvider, colName, albumName, nft } = useParams();

  //run shard/catalog collections for main profile wallet + emID wallets

  //Ben : This Function fetches all the collections for the profile address
 

  useEffect(() => {
    //set the lookup address for a searched address with no profile
    if (searchedAddress && !profile) {
      setProfileAddressArray([searchedAddress]);
      setProfileMainAddress(searchedAddress);
    }
    //set the user address array based on emeraldID linkage or lack thereof
    if (profileData){
      const mainAddress = profileData.profile.address
      setProfileMainAddress(mainAddress);
      if (profileData?.accounts?.length > 0) {
        const profileAddressArray = profileData.accounts.map(({address}) => address)
        // Add main address to a list of accounts
        profileAddressArray.push(mainAddress)
        // we can eliminate duplicates by creating a set and then converting it back to array
        const uniqueAddresses = Array.from(new Set(profileAddressArray))
        setProfileAddressArray(uniqueAddresses)
      } else {
        setProfileAddressArray([mainAddress]);
      }
    }
  }, [profile,profileData, searchedAddress]);

  const userCollections = useGetFactoryCollectionsMulti(profileAddressArray);

  //get nft data such as type for detail page
  useEffect(() => {
    // Ref: https://yugioh.fandom.com/wiki/Exodia 🙈
    const exodia = walletProvider
      && userCollections.getFinished
      && profileAddressArray.length > 0
      && nft
      && colName
    
    if (exodia) {
      if (walletProvider === "main") {
        setNftData(
          userCollections?.collectionItems[profileMainAddress][colName]
        );
      } else {
        // const byProvider = profile.accounts.reduce((result, account)=>{
        //   const { name, address } = account
        //   result[name] = address

        //   return result
        // },{})
        console.log(profile, profileData)
        const walletAddress = profileData.accounts.find(item => item.name === walletProvider).address
        console.log(walletAddress)
        setNftData(
          userCollections.collectionItems[walletAddress][colName]
        );
      }
    }
  }, [walletProvider, userCollections, nft, colName]);

  if (userCollections.getFinished) {
  return (
    <Container fluid={+true} className="p-0" id="collections">
      <Card>
        <ProfileCollectionWalletTabs
          userCollections={userCollections}
          profileData={profileData}
          profileMainAddress={profileMainAddress}
          type={type}
          display={display}
          nftData={nftData}
          searchedAddress={searchedAddress}
          nft={nft}
          profile={profile}
        />
      </Card>
    </Container>
  );
}
else{
  return <Loading />
}
}

