import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import FlowSticker from "../../../../forms/ftstickers/FlowSticker";
import FUSDSticker from "../../../../forms/ftstickers/FUSDSticker";
import ColDetailMakeOffer from "../ColDetailMakeOffer/ColDetailMakeOffer";
import ColDetailRemoveListing from "../ColDetailRemoveListing/ColDetailRemoveListing";
import ColDetailBuyNow from "./ColDetailBuyNow";
import { toLocalNumber } from "../../../../../functions/format";

const ColDetailForSale = ({
  listingStatus,
  buyNowClicked,
  setBuyNowClicked,
}) => {
  const [saleDetails, setSaleDetails] = useState(null);
  const themeStatus = UseThemeStatus();

  useEffect(() => {
    if (listingStatus.listingDetails.findMarket.FindMarketSale) {
      setSaleDetails(listingStatus.listingDetails.findMarket.FindMarketSale);
    }
  }, [listingStatus.listingDetails.findMarket.FindMarketSale]);

  const prefix = saleDetails?.ftAlias?.includes("DUC") ? "$" : ""
  const formattedAmount = toLocalNumber(saleDetails?.amount)
  const amount = `${prefix}${formattedAmount}`
  
  if (saleDetails)
    return (
      <Row className="py-2 justify-content-center">
        <Col xs="12">
          <Card
            id="mp-price-box-card-sale"
            className="mp-price-box-card px-4 pb-4 pt-4 mb-2"
          >
            <Row className="py-2">
              <Col>
                <span className="fw-bold">Sale price:</span>
              </Col>
            </Row>
            <Row className="pb-4">
              <Col>
                <div className="mp-price-row">
                  <h3 className="mb-0">{amount}</h3> &nbsp;
                  {saleDetails.ftAlias.includes("Flow") && (
                    <FlowSticker noBg={"true"} />
                  )}  
                  {saleDetails.ftAlias.includes("FUSD") &&
                  (
                    <FUSDSticker noBg={"true"} />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              {saleDetails &&
              listingStatus.allCurrentListings.linkedForMarket &&
              (!listingStatus.user.loggedIn || !listingStatus.owner.ownItem) ? (
                <Col
                  align="center"
                  xs="12"
                  lg="6"
                  className="pb-xs-2 mb-3 mb-lg-0"
                >
                  <ColDetailMakeOffer
                    listingStatus={listingStatus}
                    saleDetails={saleDetails}
                    themeStatus={themeStatus}
                  />
                </Col>
              ) : listingStatus.owner.ownItem && saleDetails ? (
                <>
                  <Col xs="0" md="6"></Col>
                  <Col align="center" xs="12" lg="6" className="pb-0">
                    <ColDetailRemoveListing saleDetails={saleDetails} />
                  </Col>
                </>
              ) : (
                <Col
                  align="center"
                  xs="12"
                  lg="6"
                  className="pb-xs-2 mb-3 mb-lg-0"
                >
                  {"  "}
                </Col>
              )}
              {!listingStatus.owner.ownItem && (
                <Col align="center" xs="12" lg="6" className="pb-xs-2">
                  <ColDetailBuyNow
                    listingStatus={listingStatus}
                    buyNowClicked={buyNowClicked}
                    setBuyNowClicked={setBuyNowClicked}
                    saleDetails={saleDetails}
                    themeStatus={themeStatus}
                  />{" "}
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    );
};

export default ColDetailForSale;
