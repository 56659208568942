
const friendlicateCollection = (collection) => {
  switch (collection) {
    case "Lease":
      return ".find Names";

    case "NFGv3":
      return "NonFunGerbils";

    case "HaikuNFT":
      return "Bitku";

    case "Art":
      return "Versus";

    case "CharityNFT":
      return "Neo Charity 2021";

    case "Beam":
      return "Frightclub";

    case "StarlyCard":
      return "Starly";

    case "CaaPass":
      return "THiNG.FUND Membership Badge";

    case "Momentables":
      return "Crypto Pharaohs";

      case "FlovatarComponent":
        return "FloBits";

    default:
      return collection;
  }
};

export default friendlicateCollection;
