import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useMenu } from "react-instantsearch-hooks-web";
import { itemLogo, itemName } from "../../../../utils";

export default function DoodlesCurrencyRefinement() {
  const { items, refine, currentRefinement } = useMenu({
    attribute: "amount_alias",
    sortBy: ["name:asc"],
  });

  const sortCurrency = "Currency";

  return (
    <DropdownButton
      title={sortCurrency}
      as={ButtonGroup}
      id={`sorting-dropdown`}
      bsPrefix="filter-selection"
      onSelect={(key) => {
        refine(key);
      }}
    >
      {items.map((item) => {
        return (
          <Dropdown.Item eventKey={item.value} active={currentRefinement}>
            {itemLogo(item.value)} {itemName(item.value)}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
}
