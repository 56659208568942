import React from "react";
import { Link } from "react-router-dom";

const NavheadLogo = ({setExpanded, expanded}) => {
  return (
    <Link onClick={() => setExpanded(false)} to="/">
      <img
        src="/assets/img/find_logo.png"
        alt="Find Logo"
        style={{ maxHeight: "34px" }}
      />
    </Link>
  );
};

export default NavheadLogo;
