import React from "react";
import CharityAuctionHeader from "./CharityAuctionHeader";
import CharityAuctionInfo from "./CharityAuctionInfo";
import CharityAuctionItems from "./CharityAuctionItems";
import CharityAuctionWall from "./CharityAuctionWall";
import "./CharityAuction.scss";
import { Container } from "react-bootstrap";
import CharityBannerBio from "./CharityBannerBio";
import CharityAuctionGiftWidget from "./CharityAuctionGiftWidget";

const CharityAuctionWrapper = () => {
  return (
    <div id="charity-auction-outer-wrapper">
      <div id="charity-auction-wrapper" className="content-container">
        {/* <CharityAuctionHeader /> */}
        <CharityBannerBio />
        <CharityAuctionItems />
        <CharityAuctionGiftWidget />
        <CharityAuctionWall />
        <CharityAuctionInfo />
      </div>
    </div>
  );
};

export default CharityAuctionWrapper;
