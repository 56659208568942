import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import { UseThemeStatus } from "../../../functions/themeMode";
import "./createprofile.scss";

const CreateProfileFormInfo = ({ currentStage }) => {
  const [showInfoModal, setShowInfoModal] = useState(false);

  const infoTitle =
    currentStage === 2
      ? "What’s the difference between a .find profile and .find name?"
      : currentStage === 3
      ? "Find out more about .find names"
      : currentStage === 4
      ? "Woohoo you registered a .find name"
      : null;

  const infoContent =
    currentStage === 2
      ? [
          "A profile name gives you a way to identify your profile on .find and other platforms, while a .find name provides an identity for your 18 digit '0x...' wallet address.",
          "Find out more about .find names on the next page after creating your profile.",
        ]
      : currentStage === 3
      ? [
          "A .find name is a lease on the blockchain that gives a memorable name to your wallet address - this makes it easier for you and your friends to find and interact with your wallets and collections simply by going to find.xyz/<yourname>.",
          "A .find name also integrates with services such as Flovatar, Flowscan, FLOAT to show your easy to remember .find name instead of an 18 digit '0x...' wallet address.",
          "Multiple wallets can be linked to a .find name via emerald ID.",
        ]
      : currentStage === 4
      ? [
        //no modal on stage 4
          "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
        ]
      : null;

  //mobile swipedown close
  const config = {
    delta: 150, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,
    swipeDuration: 250,
  };
  const handlers = useSwipeable({
    onSwipedDown: () => {
      setShowInfoModal(!showInfoModal);
    },
    ...config,
  });

  const themeStatus = UseThemeStatus();
  return (
    <div>
      <span
        onClick={() => setShowInfoModal(!showInfoModal)}
        id="create-profile-form-info"
      >
        {infoTitle}
      </span>
      <Modal
        show={showInfoModal}
        onHide={() => setShowInfoModal(!showInfoModal)}
        centered
        data-theme={themeStatus}
      >
        <div {...handlers}>
          <Modal.Header
            style={{ border: "none" }}
            closeButton
            closeLabel="close window"
          ></Modal.Header>
          {/* <Modal.Header
          style={{ border: "none" }}
          closeButton
          closeLabel="close window"
        ></Modal.Header> */}

          {/* <Modal.Title className="text-center w-100">{infoTitle}</Modal.Title> */}
          <Modal.Body className="p-4">
            {infoContent.map((clause, i) => {
              return (
                <>
                  <span id="create-profile-form-info-clause" key={i}>
                    {clause}
                  </span>
                  <br />
                  <br />
                </>
              );
            })}
            <div id="info-button-row">
              <Button
                onClick={() => setShowInfoModal(!showInfoModal)}
                variant="find"
              >
                Got it
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>{" "}
    </div>
  );
};

export default CreateProfileFormInfo;
