// Imports
import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

// Project Components
import { Crumb, IconMagnify, SocialButtons } from "./components";
import { getCurrentPage } from "./utils";

// Styles
import "./breadcrumbs.scss";

// Implementation
export default function Breadcrumbs(props) {
  const { page } = props;
  const { pathname } = useLocation();
  const { title, crumbs, link, url } = getCurrentPage(pathname);

  return (
    <Container fluid={true} id="breadcrumbs-container" className="py-3">
      <IconMagnify />

      <Link className="breadcrumbs-link" to="/">
        Home
      </Link>

      <Link className="breadcrumbs-link" to={link}>
        {title}
      </Link>

      {crumbs.map((label, i) => {
        const isLast = i === crumbs.length - 1;
        return <Crumb last={isLast} label={label} key={i} />;
      })}

      <SocialButtons page={page} url={url} />
    </Container>
  );
}
