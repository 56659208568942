import { Row } from "../components";
import { ReactComponent as Icons } from "./social-icons.svg";
import "./index.scss";

const icons = {
  url: 0,
  instagram: 24,
  discord: 48,
  twitter: 72,
};

const getIconOffset = (name) => {
  const offset = icons[name.toLowerCase()];

  if (!name || !offset) {
    return 0;
  }

  return offset;
};

export function Icon(props) {
  const { name } = props;
  const offset = getIconOffset(name);

  return (
    <div className={"dp__icon-container"}>
      <Icons style={{ transform: `translate(-${offset}px)` }} />
    </div>
  );
}

export function IconLink(props) {
  const { name, url } = props;
  const title = `Go to ${url}`;
  return (
    <a className={"dp__icon-link"} href={url} title={title}>
      <Icon name={name} />
    </a>
  );
}

export function IconStack(props) {
  const { links = {} } = props;
  if (!links) {
    return null;
  }

  return (
    <Row className={"dp__align-end dp__gap"}>
      {Object.keys(links).map((name) => {
        const url = links[name];
        return <IconLink name={name} url={url} />;
      })}
    </Row>
  );
}

export function Verified(props) {
  const { show } = props;
  if (!show) {
    return null;
  }

  const src = "/assets/img/stickers/verify-sticker-small.png";
  const alt = "Verified";
  return (
    <div className={"dp__icon-container"}>
      <img alt={alt} src={src} title={alt} />
    </div>
  );
}
