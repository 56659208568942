import parseImgUrl from "../../../functions/parseImgUrl";
import { toLocalNumber } from "../../../functions/format";

function MarketplaceItem(props) {
  const { hit } = props;

  const amount = toLocalNumber(hit.amount, { min: 2 });
  let ticker = hit.amount_alias
    .replace("FlowToken", "FLOW")
    .replace("FlowUtilityToken", "FLOW")
    .replace("DapperUtilityCoin", "USD")
    .replace("FUSD", "USD");

  let price = ticker === "USD" ? `$${amount}` : `${amount} ${ticker}`;

  return (
    <div>
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
          <img
            crossOrigin="anonymous"
            src={
              hit.collection_name === "FIND"
                ? "/assets/img/marketplace/find_name_with_find.png"
                : parseImgUrl(hit.nft_thumbnail, "thumbnail")
            }
            alt={hit.nft_name}
            width="40"
            height="40"
          />
        </div>
        <div className="aa-ItemContentBody">
          <span>
            <img
              src={"/assets/img/marketplace/verify1.png"}
              alt="verify icon"
              width="16px"
              height="16px"
            />{" "}
            <small style={{ color: "var(--text-secondary)" }}>
              {hit.collection_alias.replace("FIND", ".find")}
            </small>
          </span>
          <div className="aa-ItemContentTitle" style={{ marginTop: "-5px" }}>
            <div className="fw-bold" style={{ display: "flex" }}>
              <small>
                <div
                  dangerouslySetInnerHTML={{
                    __html: hit._highlightResult.nft_name.value,
                  }}
                  style={
                    hit.collection_alias === "FIND"
                      ? { color: "var(--theme-main)" }
                      : { color: "var(--text-primary)" }
                  }
                />
              </small>
              {hit.collection_alias === "FIND" ? (
                <small>
                  <b>.find</b>
                </small>
              ) : null}
            </div>
          </div>
          <div
            className="aa-ItemContentDescription"
            style={{ marginBottom: "-5px" }}
          >
            {hit.nft_rarity ? (
              <small style={{ color: "var(--text-secondary)" }}>
                {hit.nft_rarity?.[0].toUpperCase() +
                  hit.nft_rarity?.substring(1) || ""}
              </small>
            ) : (
              <small>&nbsp;</small>
            )}
          </div>
          <div>
            <small>
              <b>{price}</b>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketplaceItem;
