import React from "react";
import { Image } from "react-bootstrap";
import "./ftstickers.css";

export default function USDCSticker(props){
	const { noBg } = props;
	return (
		<div className="sticker-box">
      <span
				className={
					noBg === "true"
						? "sticker-span-no-bg"
						: noBg === "tertiary"
							? "sticker-span-tertiary"
							: "sticker-span"
				}
			>
        <div>
          <Image
						className="sticker-image"
						src="/assets/img/stickerlogos/usdc-sticker-logo.png"
					/>
        </div>
        <span className="ft-span secColor">USDC</span>
      </span>
		</div>
	);
};

