import React from "react";
import { Configure, InstantSearch } from "react-instantsearch-hooks-web";
import CustomHitsCarousel from "../../search/CustomHitsCarousel/CustomHitsCarousel";
import { makeSearchClient } from "../../../functions/searchClient";

const MoreFromCollection = ({ isName, colName, detail }) => {

  const searchClient = makeSearchClient("nft_name, nft_alias, seller_name, seller");

  return (
    <>
      <InstantSearch indexName="market" searchClient={searchClient}>
        <Configure
          hitsPerPage={10}
          facetFilters={["nft_alias:" + colName]}
        />
        <CustomHitsCarousel detail={detail} moreFromCollection={true}/>

        {/* {eventsData && eventsData.length > 0 ? (
          eventsData.map((activity, i) => {
            // console.log(activity);

            return (
              <MoreFromCollectionCard isName={isName} nftDetail={activity} />
            );
          })
        ) : (
          <Row className="align-items-center justify-content-center ">
            <Col>
              <Card
                className="align-items-start justify-content-center "
                style={{
                  backgroundColor: "var(--background-profile)",
                  width: "100%",
                }}
              >
                <Card.Body>
                  <Col>No items from this collection found</Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )} */}
        {/* <div className="d-flex align-items-center justify-content-center pb-2">
          <Link to={/mp/ + nftCollection}>
            <Button variant="find-dark">View Collection</Button>
          </Link>
        </div> */}
      </InstantSearch>
    </>
  );
};

export default MoreFromCollection;
