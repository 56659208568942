

import { Card, Col, Modal, Row, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TimeAgo from "react-timeago";
import "./CharityAuctionHitsCard.scss";
import { useState } from "react";

// import MarketplaceItemBoughtModal from "../../marketplace/marketplaceitemboughtmodal/marketplaceitemboughtmodal";

import FlowSticker from "../forms/ftstickers/FlowSticker";
import FUSDSticker from "../forms/ftstickers/FUSDSticker";
import { UseThemeStatus } from "../../functions/themeMode";
import useToastTxStatus from "../../functions/useToastTxStatus";
import parseImgUrl from "../../functions/parseImgUrl";
import truncateItemName from "../../functions/truncateItemName";
const dt = (new Date().getTime() / 1000).toFixed(0);

const CharityAuctionHitsCard = ({ hit, moreFromCollection, detail }) => {
  const [showBuy, setShowBuy] = useState(false);
  const [buyNowClicked, setBuyNowClicked] = useState(false);
  let isName;
  if (hit.collection_name === "FIND") {
    isName = true;
  }
  const themeStatus = UseThemeStatus();
  const toastStatus = useToastTxStatus();
  let compId = null;
  let compIdMax = null;
  let navigate = useNavigate();

  // console.log(buyNowClicked)
  // console.log(toastStatus)

  // console.log(hit);
  // console.log(hit.ends_at);
  // console.log(dt)

  //flov # data
  if (hit?.["number.edition_flovatar component_number"]) {
    compId = hit?.["number.edition_flovatar component_number"];
    if (hit?.["number.edition_flovatar component_max"]) {
      compIdMax = hit?.["number.edition_flovatar component_max"];
    }
  }

  if (hit) {
    // console.log(hit)
    return (
      <>
        <Card
          onMouseEnter={() => setShowBuy(true)}
          onMouseLeave={() => setShowBuy(false)}
          className={
            !moreFromCollection
              ? "market-nft mx-0 mb-0 pb-0"
              : moreFromCollection && detail
              ? "market-nft-more-detail mx-1 mb-0 pb-0"
              : "market-nft-more mx-1 mb-0 pb-0"
          }
        >
          {/* <Link
          className={isName ? "card-link px-2 py-2 mb-2 " : ' card-link px-0 mb-2'}
      
          to={
            hit.collection_name === "FIND"
              ? "/mp/" + hit.collection_alias + "/" + hit.nft_name
              : "/mp/" + hit.collection_alias + "/" + hit.uuid
          }
        > */}
          {hit.nft_thumbnail &&
          (hit.nft_thumbnail.includes(".mp4") ||
            hit.collection_alias === "THiNG.FUND Membership Badge") ? (
            <video
              key={hit.nft_thumbnail}
              muted
              alt={"Picture of " + hit.nft_name.name}
              loop=""
              playsInline=""
              style={{ cursor: "pointer" }}
              onMouseOver={(event) => event.target.play()}
              onMouseOut={(event) => event.target.pause()}
              onClick={() =>
                hit.collection_name === "FIND"
                  ? navigate("/mp/" + hit.collection_alias + "/" + hit.nft_name)
                  : navigate("/mp/" + hit.collection_alias + "/" + hit.uuid)
              }
            >
              <source
                src={parseImgUrl(hit.nft_thumbnail) + "#t=1.0"}
                type="video/mp4"
              ></source>
              Sorry this video is not supported by your browser
            </video>
          ) : (
            <>
              <Image
              crossOrigin="anonymous"
                className={
                  hit.collection_alias === "FIND" ? "img-wrap" : "img-wrap"
                }
                fluid={+true}
                src={
                  hit.collection_alias === "FIND"
                    ? parseImgUrl(
                        "/assets/img/marketplace/find_name_with_find.png",
                        "",
                        hit.nft_name
                      )
                    : parseImgUrl(hit.nft_thumbnail)
                }
                color="var(--background)"
                style={{
                  borderRadius: "8px",
                  cursor: "pointer",
                  objectFit: "cover",
                }}
                onClick={() =>
                  hit.collection_name === "FIND"
                    ? navigate(
                        "/mp/" + hit.collection_alias + "/" + hit.nft_name
                      )
                    : navigate("/mp/" + hit.collection_alias + "/" + hit.uuid)
                }
              />
              {/* {hit.collection_alias === "FIND" && (
                <div id="valid-til" className="flex-row">
                  &nbsp;
                  <span>
                    {new Date(
                      hit.number_valid_until * 1000
                    ).toLocaleDateString()}
                  </span>
                  &nbsp;
                  <img
                    src="/assets/img/profile/namesowned/tick-circle.png"
                    alt="green tick"
                  />
                </div>
              )} */}
            </>
          )}
          <Link className="card-top-link" to={"/mp/" + hit.collection_name}>
            <div className="d-flex mpCardCollectionRow align-items-center justify-content-center py-0 px-2">
              <Col xs="auto">
                <Card.Img
                  style={{ height: "1rem", width: "1rem" }}
                  fluid={+true}
                  src="/assets/img/marketplace/verify1.png"
                />
                &nbsp;&nbsp;
              </Col>
              <Col className=" align-items-center justify-content-center mp-collection-alias">
                <span className="mp-collection-alias smallTextSpan">
                  {hit.collection_alias === "FIND"
                    ? ".find"
                    : truncateItemName(hit.collection_alias, 15)}
                </span>
              </Col>
            </div>
          </Link>
          <Link
            className="card-link"
            to={
              hit.collection_name === "FIND"
                ? "/mp/" + hit.collection_alias + "/" + hit.nft_name
                : "/mp/" + hit.collection_alias + "/" + hit.uuid
            }
          >
            <Row className="py-0 px-2">
              <Col align="left" className="mpCardNameRow">
                <span className="nftName fw-bold">
                  {hit.collection_alias === "FIND" ? (
                    <span>
                      <span className="fw-bold">
                        <span className="fw-bold themeColor">
                          {hit.nft_name}
                        </span>
                        .find
                      </span>
                    </span>
                  ) : (
                    <span>
                      {truncateItemName(hit.nft_name, 30)}
                      {compId && " #" + compId}
                      {compIdMax && " / #" + compIdMax}
                    </span>
                  )}
                </span>
              </Col>
            </Row>

            
            <Row className="pt-3 px-2">
              <Col>
                <small className="smallText">
                  {hit.nft_rarity ? (
                    <>
                      {hit.nft_rarity[0].toUpperCase() +
                        hit.nft_rarity.substring(1)}
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </small>
              </Col>
            </Row>
            {/* <Row className="pb-1 cardRow">
          <Col>
            {hit.listing_alias === "EnglishAuction" ? (
              <small className="smallText">
                {hit.ends_at ? (
                  hit.ends_at > dt ? (
                    <TimeAgo
                      date={new Date(hit.ends_at * 1000)}
                      timeStyle="twitter"
                      suffix="none"
                    />
                  ) : (
                    "Ended"
                  )
                ) : (
                  "Timer begins on bid"
                )}
              </small>
            ) : (
              <small>&nbsp;</small>
            )}
          </Col>
        </Row> */}
            {/* <Row className="pt-2 pb-3 align-items-center">
          <Col>
            {hit.listing_alias === "EnglishAuction" && (
              <p className="salesTypeAuction">Auction</p>
            )}
            {hit.listing_alias === "Sale" && (
              <p className="salesTypeSale">Sale</p>
            )}
            {hit.listing_alias === "DirectOffer" && (
              <p className="salesTypeSale">Offer</p>
            )}

            {/* {hit.listing_alias === "EnglishAuction"
                ? "Auction"
                : hit.listing_alias} */}
            {/* </Col> */}
            {/* <Col
            align="right"
            className="justify-content-end align-items-end"
          ></Col> */}
            {/* </Row> */}
            <Row className="align-items-center pb-1 px-2  ">
              <Col className="d-flex justify-content-center ">
                <span className="fw-bold price-span" style={{}}>
                  {hit.amount}
                </span>
              </Col>
              <Col>
                {hit.amount_alias === "FlowToken" && <FlowSticker />}{" "}
                {hit.amount_alias === "FUSD" && <FUSDSticker />}
              </Col>
            </Row>
          </Link>
      

          {hit.listing_alias === "EnglishAuction" && (
            <Row className="salesTypeAuctionRow p-0 m-0 mt-2">
              {hit.ends_at ? (
                hit.ends_at > dt ? (
                  <TimeAgo
                    className="d-flex align-items-center justify-content-center"
                    date={new Date(hit.ends_at * 1000)}
                    suffix="none"
                  />
                ) : (
                  "Ended"
                )
              ) : (
                "Auction"
              )}
            </Row>
          )}
          {/* {buyNowClicked && toastStatus.status === "success" && (
            <Modal
              data-theme={themeStatus}
              show={buyNowClicked && toastStatus.status === "success"}
              centered
            >
              <MarketplaceItemBoughtModal
                nftDetails={hit}
                isName={isName}
                query={query}
              />
            </Modal>
          )} */}
        </Card>
      </>
    );
  } else {
    return null;
  }
};

export default CharityAuctionHitsCard;
