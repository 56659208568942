import React from "react";
import { Form } from "react-bootstrap";
import "./ThoughtBox.scss";

const ThoughtBoxHeaderInput = ({ thoughtInput, setThoughtInput }) => {
  return (
    <div>
      <input
        id="lf-thought-header-box"
        value={thoughtInput.header}
        placeholder="Enter a heading for your thought"
        onChange={(e) =>
          setThoughtInput({ ...thoughtInput, header: e.target.value })
        }
      ></input>
    </div>
  );
};

export default ThoughtBoxHeaderInput;
