import { useEffect, useState } from "react";
import { getLostAndFoundNFTs } from "./flow-interactions/scripts";
import { useStateChanged } from "./DisabledState";

export default function useLostAndFound(user) {
	const [isLoading, setIsLoading] = useState(false)
  const [lostNFTs, setLostNFTs] = useState({});

  useEffect(() => {
    if (user.loggedIn) {
			setIsLoading(true)
      getLostAndFoundNFTs(user.addr).then(items => {
				setIsLoading(false)
				setLostNFTs(items)
			});
    }
  }, [user, useStateChanged()]);

	let numberOfItems = 0;
	if(lostNFTs){
		for (const lostNFTsKey in lostNFTs.ticketIds) {
			numberOfItems += lostNFTs.ticketIds[lostNFTsKey].length
		}
	}

  return {lostNFTs, isLoading, numberOfItems};
}
