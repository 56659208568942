import React, { useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import MakeOfferNftModalMp from "../../forms/buying/makeoffernft/makeoffernftmodalmp";

const renderTooltipComingSoon = (props) => (
  <Tooltip {...props}>
    <span style={{ fontSize: "16px" }}>Coming soon</span>
  </Tooltip>
);
const disabledStyle = { opacity: "0.65" };

const config = {
  delta: 150, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: true,
  swipeDuration: 250,
};

export default function MpDetailMakeOffer(props){
  const { hit, isName, listingDetails } = props
  const { findUser, profileDetails, userHasOffer} = props
  const { themeStatus} = props

  const [showOffer, setShowOffer] = useState(false);

  const handleCloseOffer = () => {
    setShowOffer(false);
  };

  const handleShowOffer = () => {
    setShowOffer(true);
  };

  const handlers = useSwipeable({
    onSwipedDown: () => {
      // console.log('this')
      handleCloseOffer();
    },
    ...config,
  });


  return (
    <>
      {hit && (hit.amount_alias === 'DapperUtilityCoin' || hit.amount_alias === 'FlowUtilityToken') ? (
        <OverlayTrigger placement="top" overlay={renderTooltipComingSoon}>
          <Button
            // disabled={true}
            style={disabledStyle}
            // onClick={handleShowOffer}
            className="w-100"
            variant="find-dark"
          >
            <small>Offer</small>
          </Button>
        </OverlayTrigger>
      ) : (
        <Button onClick={handleShowOffer} className="w-100" variant="find-dark">
          <small>Offer</small>
        </Button>
      )}

      <Modal
        data-theme={themeStatus}
        show={showOffer}
        onHide={handleCloseOffer}
        centered
      >
        <div {...handlers}>
          <Modal.Header
            style={{ border: "none" }}
            closeButton
            closeLabel="close window"
            //theme causing rerenders
            // data-theme={() => UseThemeStatus()}
          >
            <Modal.Title className="text-center w-100">
              <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
                Make an offer for{" "}
                {!isName ? (
                  hit.nft_name
                ) : (
                  <span>
                    <span className="themeColor">{hit.nft_name}</span>.find
                  </span>
                )}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MakeOfferNftModalMp
              hit={hit}
              findUser={findUser}
              profileDetails={profileDetails}
              isName={isName}
              listingDetails={
                isName ? listingDetails.leaseStatus : listingDetails
              }
              userHasOffer={userHasOffer}
            />
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};