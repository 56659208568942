import { formatTime, hashCode, isFeeEvent } from "../utils";
import { extractSigners } from "../../../functions/cadut/util/parser";

export const eventMapper = (item) => {
  const event = item.node;
  const { type, fields } = event;
  const { id } = type;
  const argNames = type.fields.map((field) => field.identifier);
  const argValues = fields.map((field) => {
    if (typeof field.value === "object") {
      if (field.type === "Optional") {
        return field.value?.value || null;
      }
      return field;
    }
    return field.value;
  });
  const args = {};
  for (let i = 0; i < argNames.length; i++) {
    const name = argNames[i];
    const value = argValues[i];

    args[name] = value;
  }
  return {
    id,
    argNames,
    args,
  };
};

export const transferMapper = (currentOwner) => (item) => {
  const transfer = item.node;
  const { transaction } = item.node;

  const from = transfer.from?.address;
  const to = transfer.to?.address;
  const { script, time, authorizers } = transaction;
  const hash = hashCode(script);
  const signers = authorizers.map((i) => i.address);
  const extractedSigners = extractSigners(script);

  const events = transaction.events.edges.map(eventMapper).reduce(
    (acc, event) => {
      if (!isFeeEvent(event)) {
        const { id } = event;
        if (!acc.byId[id]) {
          acc.byId[id] = [];
        }
        const index = acc.byId[id].push(event);
        acc.list.push({
          id,
          index: index - 1,
        });
      }

      return acc;
    },
    { byId: {}, list: [] }
  );

  return {
    from,
    to,
    script,
    hash,
    events,
    signers,
    extractedSigners,
    txHash: transaction.hash,
    meta: {
      currentOwner,
      timestamp: formatTime(time),
    },
  };
};
