import classify from "./classifier";
import * as render from "./components";

export default function (props) {
  const { context, transfer } = props;
  const { hash } = transfer;

  // We can identify some transfers by their hash
  // prettier-ignore
  const switchboard = {
		"____________________NTkwNjE1NzA2": render.GaiaClaimAirdrop,
		"________________MjE0NDQ1MjcyMg==": render.GaiaClaimAirdrop,
		"________________LTEyNDY2Nzk2Ng==": render.BatchMove,
    "____________________MTQ0NzM2MzA1": render.BatchMinted
	};

  const Render = switchboard[hash];
  if (Render) {
    return <Render context={context} transfer={transfer} />;
  }

  // identify transfer type
  const transferType = classify(context, transfer);

  // If we don't know what to do with this transfer, we shall fall back to generic rendering
  return null
}
