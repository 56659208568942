import React from "react";
import "./nftsforsalecard.scss";

const NftForSaleLower = ({
  itemName,
  onSaleNFT,
  onAuctionNFT,
  itemAmount,
  itemFT,
  itemCollection,
}) => {
  return (
    <div id="sale-nft-lower-wrapper">
      <div id="sale-nft-lower-name">{itemName}</div>
      <div id="sale-nft-lower-amount">{itemCollection}</div>
      {itemFT === "DapperUtilityCoin" ? (
        <div id="sale-nft-lower-amount">
          ${parseFloat(itemAmount).toLocaleString()}
        </div>
      ) : (
        <div id="sale-nft-lower-amount">
          {onAuctionNFT ? "Bid:" : ""} {parseFloat(itemAmount).toLocaleString()} {itemFT}
        </div>
      )}
    </div>
  );
};

export default NftForSaleLower;
