import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import parseNameLink from "../../../../../functions/parseNameLink";
import "./detailspropertycard.css";
const CatalogDetailsPropertyCardMarket = ({ listingStatus }) => {
  let listingDetails;
  let itemRoyalties = null;
  let itemTags = null;
  let itemScalars = null;
  let itemMedia = null;
  let itemThumbnail = null;
  let itemAllowedListings = null;
  let itemCurrentListings = {
    forAuction: false,
    forSale: false,
    offered: false,
  };
  let itemCollectionDescription = null;
  let itemEdition = null;
  let itemCollectionContract = null;
  let itemOwner = null;
  let itemOwnerAddress = null;
  let itemType = null;
  let itemIsListed = null;
  let itemRarity = null;
  let itemTraits = null;
  let itemCollectionName = null;

  // console.log(itemMedia);

  if (
    listingStatus.listingDetails.nftDetail.data[
      "A.1d7e57aa55817448.MetadataViews.Traits"
    ]
  ) {
    itemTraits =
      listingStatus.listingDetails.nftDetail.data[
        "A.1d7e57aa55817448.MetadataViews.Traits"
      ];
  }

  // console.log(itemTraits)

  if (
    listingStatus.sellerProfile &&
    Object.keys(listingStatus.sellerProfile).length !== 0 &&
    Object.getPrototypeOf(listingStatus.sellerProfile) === Object.prototype
  ) {
    itemOwner = listingStatus.sellerProfile;
  }

  if (listingStatus.indexHit && listingStatus.indexHit.nft_rarity) {
    itemRarity = listingStatus.indexHit.nft_rarity;
  }

  //market
  // console.log(listingStatus);
  //royalties
  if (
    listingStatus.itemRoyalties.Sale &&
    Object.keys(listingStatus.itemRoyalties.Sale).length !== 0 &&
    Object.getPrototypeOf(listingStatus.itemRoyalties.Sale) === Object.prototype
  ) {
    itemRoyalties = listingStatus.itemRoyalties.Sale;
  }

  //allowedlistings
  if (
    listingStatus.listingDetails.allowedListingActions &&
    Object.keys(listingStatus.listingDetails.allowedListingActions).length !==
      0 &&
    Object.getPrototypeOf(
      listingStatus.listingDetails.allowedListingActions
    ) === Object.prototype
  ) {
    itemAllowedListings = listingStatus.listingDetails.allowedListingActions;
  }

  //currentListings
  if (
    listingStatus.forAuction === true ||
    listingStatus.forSale === true ||
    listingStatus.offered === true
  ) {
    itemIsListed = true;
    if (listingStatus.forAuction === true) {
      itemCurrentListings.forAuction = true;
    }
    if (listingStatus.forSale === true) {
      itemCurrentListings.forSale = true;
    }
    if (listingStatus.offered === true) {
      itemCurrentListings.offered = true;
    }
  }

  //details
  if (listingStatus.listingDetails.nftDetail) {
    listingDetails = listingStatus.listingDetails.nftDetail;
    if (listingStatus.listingDetails.nftDetail.type) {
      itemType = listingStatus.listingDetails.nftDetail.type;
    }

    if (listingStatus.listingDetails.nftDetail.thumbnail) {
      itemThumbnail = listingStatus.listingDetails.nftDetail.thumbnail;
    }

    if (listingStatus.listingDetails.nftDetail.collection.description) {
      itemCollectionDescription =
        listingStatus.listingDetails.nftDetail.collection.description;
    }

    if (listingStatus.listingDetails.nftDetail.collection.name) {
      itemCollectionName =
        listingStatus.listingDetails.nftDetail.collection.name;
    }

    if (
      listingStatus.listingDetails.nftDetail.tags &&
      Object.keys(listingStatus.listingDetails.nftDetail.tags).length !== 0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.nftDetail.tags) ===
        Object.prototype
    ) {
      itemTags = listingStatus.listingDetails.nftDetail.tags;
    }
    if (
      listingStatus.listingDetails.nftDetail.scalars &&
      Object.keys(listingStatus.listingDetails.nftDetail.scalars).length !==
        0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.nftDetail.scalars) ===
        Object.prototype
    ) {
      itemScalars = listingStatus.listingDetails.nftDetail.scalars;
    }

    if (listingStatus.itemMedia) {
      itemMedia = listingStatus.itemMedia;
    }

    if (listingStatus.listingDetails.editionNumber) {
      itemEdition = listingStatus.listingDetails.editionNumber;
      if (listingStatus.listingDetails.totalInEdition) {
        itemEdition =
          itemEdition + " / " + listingStatus.listingDetails.totalInEdition;
      }
    }
  }

  // console.log(listingDetails)
  // console.log(listingStatus);
  if (listingDetails) {
    return (
      <Card id="nft-detail-details-card" className="nft-details-card">
        <Card.Body className="px-0 px-sm-4 py-0 my-0">
          <Row>
            <Col className="py-0 px-0" xs="12">
              {/* {itemOwner && (
                <Row className="pb-4">
                  <Col xs="auto" className="px-0">
                    <span className="fw-bold small px-0"> Owner:&nbsp;&nbsp;</span>
                  </Col>
                  <Col className="px-0">
                    <span>{parseNameLink(itemOwner)}</span>
                  </Col>
                </Row>
              )} */}

              {itemThumbnail && (
                <Row className="pb-4">
                  <Col xs="12" className="px-0">
                    <span className="fw-bold small px-0">
                      Thumbnail:&nbsp;&nbsp;
                    </span>
                  </Col>
                  <Col className="px-0 propertiesWrap">
                    <a
                      target="_blank"
                      className="propLink"
                      rel="noreferrer"
                      href={parseImgUrl(itemThumbnail)}
                    >
                      {itemThumbnail}
                    </a>
                  </Col>
                </Row>
              )}

              {/* <Row>
                  <span className="fw-bold small px-0 pb-4"> Rarity:</span>
                </Row> */}

              {itemMedia && itemMedia !== "thumbnail" && (
                <Row className="pb-5">
                  <Col xs="12" className="px-0">
                    <span className="fw-bold px-0"> Media:&nbsp;&nbsp;</span>
                  </Col>
                  {Object.keys(itemMedia).map((mediaItem, i) => {
                    return (
                      <div className="px-0 propertiesWrap mb-2" key={i}>
                        <span className="fw-bold">{itemMedia[mediaItem]}:</span>{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className=""
                          href={mediaItem}
                        >
                          <span>{mediaItem}</span>
                        </a>
                      </div>
                    );
                  })}
                </Row>
              )}

              <Row className="pb-4">
                <Col xs="12" md="6" className="px-0">
                  <span className="fw-bold small px-0">ID:&nbsp;&nbsp;</span>{" "}
                  <span className="propertiesWrap">
                    {listingDetails.id ? listingDetails.id : ""}
                  </span>
                </Col>
                <Col xs="12" md="6" className="px-0">
                  <span className="fw-bold small px-0">UUID:&nbsp;&nbsp;</span>
                  <span className="propertiesWrap">
                    {listingDetails.uuid ? listingDetails.uuid : ""}
                  </span>
                </Col>
                {/* <Col className="propertiesWrap">{listingDetails.id}</Col> */}
              </Row>

              {/* {listingDetails.uuid && (
               <Col>
                 <Row className="align-items-center">
                   <Col xs="12" md="6">
                     <h3 className="themeColor">UUID:</h3>
                   </Col>
                   <Col className="propertiesWrap">{listingDetails.uuid}</Col>
                 </Row>
               </Col>
             )} */}

              <Row className="pb-4">
                <span className="fw-bold small px-0">
                  {" "}
                  Current market listings:
                </span>
                {itemIsListed ? (
                  <Row className="propertiesWrap">
                    {itemCurrentListings.forAuction && (
                      <Link
                        className="px-0"
                        to={
                          "/mp/" +
                          listingDetails.collectionName +
                          "/" +
                          listingDetails.id
                        }
                      >
                        <span>Auction</span>
                      </Link>
                    )}
                    {itemCurrentListings.forSale && (
                      <Link
                        className="px-0"
                        to={
                          "/mp/" +
                          listingDetails.collectionName +
                          "/" +
                          listingDetails.id
                        }
                      >
                        <span>Sale</span>
                      </Link>
                    )}
                    {itemCurrentListings.offered && (
                      <Link
                        className="px-0"
                        to={
                          "/mp/" +
                          listingDetails.collectionName +
                          "/" +
                          listingDetails.id
                        }
                      >
                        <span>Offered</span>
                      </Link>
                    )}
                  </Row>
                ) : (
                  <Row>
                    <span>None</span>
                  </Row>
                )}
              </Row>

              {itemCollectionDescription && (
                <div className="pb-4">
                  <Row>
                    <span className="fw-bold small px-0 pb-0">
                      {" "}
                      Collection:
                    </span>
                  </Row>
                  <Row>
                    <Col className=" px-0">
                      <span className="propertiesWrap">
                        {itemCollectionName}
                      </span>
                      <br />
                      <span className="propertiesWrap">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            "https://flowscan.org/contract/" +
                            itemType.replaceAll(".NFT", "")
                          }
                        >
                          {itemType.replaceAll(".NFT", "")}
                        </a>
                      </span>
                      <br />
                      <span className="propertiesWrap">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            "https://nft-catalog.vercel.app/catalog/" +
                            listingDetails.collectionName
                          }
                        >
                          {listingDetails.collectionName} @ Flow NFT Catalog
                        </a>
                      </span>{" "}
                      <br />
                      {itemCollectionDescription && (
                        <>
                          {" "}
                          <br />
                          <span className="propertiesWrap">
                            {itemCollectionDescription}
                          </span>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              )}

              <div className="pb-4">
                <Row>
                  <span className="fw-bold small px-0">
                    {" "}
                    Allocated market actions:
                  </span>
                </Row>
                {itemAllowedListings && (
                  <Row className="align-items-center">
                    <Col className="propertiesWrap px-0">
                      <Row>
                        {Object.keys(itemAllowedListings).map(
                          (properties, i) => {
                            return (
                              <span className="propertiesWrap" key={i}>
                                {properties}
                              </span>
                            );
                          }
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>

            {/* <Col xs="12" lg="6">
            {itemTraits && (
                <>
                  <Row>
                    <span className="fw-bold small px-0 pb-4"> Properties:</span>
                  </Row>

                  {itemCollectionName !== "Crypto Pharaohs" ? (
                    <Row className="pb-3 propRow">
                      {Object.keys(itemTraits.traits).map((properties, i) => {
                        return (
                          <Col
                            key={i}
                            xs="8"
                            sm="6"
                            lg="6"
                            xl="6"
                            xxl="6"
                            className="propertiesTraits mb-3"
                          >
                            <Card>
                              <Card.Body className="traitsWrap">
                                <div>
                                  {itemTraits.traits[properties].name && (
                                    <Row>
                                      <span className="fw-bold small">
                                        {itemTraits.traits[properties].name}
                                      </span>
                                    </Row>
                                  )}
                                  {itemTraits.traits[properties].value &&
                                    (itemTraits.traits[properties]
                                      .displayType === "Number" ? (
                                      <Row>
                                        <span className="themeColor">
                                          {itemTraits.traits[properties].value *
                                            1}
                                        </span>
                                      </Row>
                                    ) : itemTraits.traits[properties]
                                        .displayType === "date" ? (
                                      <Row>
                                        <span className="themeColor">
                                          {new Date(
                                            itemTraits.traits[properties]
                                              .value * 1000
                                          ).toLocaleDateString()}
                                        </span>
                                      </Row>
                                    ) : (
                                      <Row>
                                        <span className="themeColor">
                                          {itemTraits.traits[properties].value}
                                        </span>
                                      </Row>
                                    ))}
                                  {itemTraits?.traits[properties]?.rarity
                                    ?.description ? (
                                    <Row>
                                      <span className="secColor">
                                        {
                                          itemTraits.traits[properties].rarity
                                            .description
                                        }
                                      </span>
                                    </Row>
                                  ) : itemTraits?.traits[properties]?.rarity
                                      ?.score ? (
                                    <Row>
                                      <span className="secColor">
                                        {itemTraits?.traits[properties]?.rarity
                                          ?.score * 1}
                                        {itemTraits?.traits[properties]?.rarity
                                          ?.max &&
                                          " / " +
                                            itemTraits?.traits[properties]
                                              ?.rarity?.max *
                                              1}
                                      </span>
                                    </Row>
                                  ) : (
                                    <Row>
                                      <span className="secColor">
                                        {/* {
     itemTraits.traits[properties].rarity
       .description
   } */}
            {/* </span>
                                    </Row>
                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <Row className="pb-3 propRow">
                      {Object.keys(itemTraits.traits).map((properties, i) => {
                        return (
                          <Col
                            key={i}
                            xs="8"
                            sm="6"
                            lg="6"
                            xl="6"
                            xxl="6"
                            className="propertiesTraits mb-3"
                          >
                            <Card>
                              <Card.Body className="traitsWrap">
                                {itemTraits.traits[properties].name && (
                                  <Row>
                                    <span className="fw-bold small">
                                      {itemTraits.traits[properties].name}
                                    </span>
                                  </Row>
                                )}

                                {itemTraits.traits[properties].value &&
                                  Object.keys(
                                    itemTraits.traits[properties].value
                                  ).length > 0 && <>
                                  {itemTraits.traits[properties].value.name &&
                                        <span className="themeColor">
                                        {
                                          itemTraits.traits[properties].value.name
                                        }
                                      </span>
                                  }
                                   
                                        {itemTraits.traits[properties].value.additionalData &&
                                        <span className="secColor fw-bold small">
                                        {
                                          itemTraits.traits[properties].value.additionalData
                                        }
                                      </span>
                                  }
                                     
                                        {itemTraits.traits[properties].value.primaryPower &&
                                        <span className="secColor">
                                        {
                                          itemTraits.traits[properties].value.primaryPower
                                        }
                                      </span>
                                  }      {itemTraits.traits[properties].value.secondaryPower &&
                                        <span className="secColor">
                                        {
                                          itemTraits.traits[properties].value.secondaryPower
                                        }
                                      </span>
                                  }
                                  {itemTraits.traits[properties].value.rarity &&
                                        <span className="secColor">
                                        {
                                          itemTraits.traits[properties].value.rarity
                                        }
                                      </span>
                                  }
                                  </>}
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </>
              )} */}

            {/* {itemRoyalties && (
                <>
                  <Row className="pb-4">
                    <span className="fw-bold small px-0 pb-1"> Royalties:</span>
                  </Row>
                  <Row className="royaltyRow">
                    {Object.keys(itemRoyalties).map((properties, i) => {
                      return (
                        <Col
                          key={i}
                          xs="8"
                          sm="6"
                          lg="6"
                          xl="6"
                          xxl="5"
                          className="propertiesRoyalty mb-3"
                        >
                          <Card>
                            <Card.Body className="royaltyWrap">
                              <div>
                                <span className="fw-bold small">Royalty Name:</span>{" "}
                                {itemRoyalties[properties].royaltyName}
                              </div>
                              <div>
                                <span className="fw-bold small">Paid to:</span>{" "}
                                {itemRoyalties[properties].findName ? (
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={
                                      "/" + itemRoyalties[properties].findName
                                    }
                                  >
                                    <span className="fw-bold small">
                                      <span className="themeColor">
                                        {itemRoyalties[properties].findName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  itemRoyalties[properties].address
                                )}
                              </div>
                              <div>
                                <span className="fw-bold small">Cut percentage:</span>{" "}
                                {parseFloat(
                                  itemRoyalties[properties].cut
                                ).toFixed(4) * 100}
                                %
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
    );
    // return (
    //   <Card id="nft-detail-details-card" className="nft-details-card">
    //     <Card.Body>
    //       <Row className="py-3 pb-4">
    //         <h1 style={{ textAlign: "start" }}>
    //           {listingDetails.name ? (
    //             listingDetails.name
    //           ) : (
    //             <span>
    //               {listingDetails.collectionName} #{listingDetails.id}
    //             </span>
    //           )}
    //         </h1>
    //         <Col className="propertiesWrap"></Col>
    //       </Row>

    //       {itemOwner && (
    //         <Row className="align-items-center py-3 ">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Owned By:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">{parseNameLink(itemOwner)}</Col>
    //         </Row>
    //       )}

    //       {itemOwnerAddress && (
    //         <Row className="align-items-center py-3 ">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Owned By:</h3>
    //           </Col>
    //           <Col className="propertiesWrap"><Link to={'/' + itemOwnerAddress}><span className="fw-bold small">{itemOwnerAddress}</span></Link></Col>
    //         </Row>
    //       )}

    //       {itemEdition && (
    //         <Row className="align-items-center y-2 ">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Edition:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             {listingDetails.name ? (
    //               listingDetails.name
    //             ) : (
    //               <span>
    //                 {listingDetails.collectionName}
    //                 {listingDetails.id}
    //               </span>
    //             )}
    //           </Col>
    //         </Row>
    //       )}

    //       {itemThumbnail && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Thumbnail:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             <a
    //               target="_blank"
    //               rel="noreferrer"
    //               href={parseImgUrl(itemThumbnail)}
    //             >
    //               {itemThumbnail}
    //             </a>
    //           </Col>
    //         </Row>
    //       )}

    //       <Row className="py-3 align-items-center">
    //         {listingDetails.id && (
    //           <Col>
    //             <Row className="align-items-center">
    //               <Col xs="12" md="6">
    //                 <h3 className="themeColor">ID:</h3>
    //               </Col>
    //               <Col className="propertiesWrap">{listingDetails.id}</Col>
    //             </Row>
    //           </Col>
    //         )}
    //         {listingDetails.uuid && (
    //           <Col>
    //             <Row className="align-items-center">
    //               <Col xs="12" md="6">
    //                 <h3 className="themeColor">UUID:</h3>
    //               </Col>
    //               <Col className="propertiesWrap">{listingDetails.uuid}</Col>
    //             </Row>
    //           </Col>
    //         )}
    //       </Row>

    //       <Row className="py-3 align-items-center">
    //         <Col xs="12" md="3">
    //           <h3 className="themeColor">Collection:</h3>
    //         </Col>
    // <Col className="propertiesWrap">
    //   <span>
    //     {listingDetails.collectionName}
    //   </span>
    //   <br />
    //   <span>
    //     <a
    //       target="_blank"
    //       rel="noreferrer"
    //       href={
    //         "https://flowscan.org/contract/" +
    //         itemType.replaceAll(".NFT", "")
    //       }
    //     >
    //       {itemType.replaceAll(".NFT", "")}
    //     </a>
    //   </span>
    //   <br />
    //   <span>
    //     <a
    //       target="_blank"
    //       rel="noreferrer"
    //       href={
    //         "https://nft-catalog.vercel.app/catalog/" +
    //         listingDetails.collectionName
    //       }
    //     >
    //     {listingDetails.collectionName} @ Flow NFT Catalog
    //     </a>
    //   </span>{" "}
    //   <br />
    //   {itemCollectionDescription && (
    //     <span>{itemCollectionDescription}</span>
    //   )}
    // </Col>
    //       </Row>

    //       {listingDetails.rarity && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Rarity:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">{listingDetails.rarity}</Col>
    //         </Row>
    //       )}

    //       {itemTags && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Tags:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             {Object.keys(listingDetails.tags).map((properties, i) => {
    //               // console.log(listingDetails);
    //               return (
    //                 <div key={i}>
    //                   <span className="fw-bold small">
    //                     {properties.replaceAll("_", " ")}:{" "}
    //                   </span>
    //                   {listingDetails.tags[properties]}
    //                 </div>
    //               );
    //             })}
    //           </Col>
    //         </Row>
    //       )}

    //       {itemScalars && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Scalars:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    //             {Object.keys(itemScalars).map((properties, i) => {
    //               // console.log(listingDetails);
    //               return (
    //                 <div key={i}>
    //                   <span className="fw-bold small">
    //                     {properties.replaceAll("_", " ")}:{" "}
    //                   </span>

    //                   {itemScalars[properties] * 1}
    //                 </div>
    //               );
    //             })}
    //           </Col>
    //         </Row>
    //       )}

    // {itemAllowedListings && (
    //   <Row className="py-3 align-items-center">
    //     <Col xs="12" md="3">
    //       <h3 className="themeColor">Allowed market actions:</h3>
    //     </Col>
    //     <Col className="propertiesWrap">
    //       <Row>
    //         {Object.keys(itemAllowedListings).map((properties, i) => {
    //           return <div key={i}>{properties}</div>;
    //         })}
    //       </Row>
    //     </Col>
    //   </Row>
    // )}

    //       <Row className="py-3 align-items-center">
    //         <Col xs="12" md="3">
    //           <h3 className="themeColor">Current Market Listings:</h3>
    //         </Col>
    //         <Col className="propertiesWrap">
    // {itemIsListed ? (
    //   <Row>
    //     {itemCurrentListings.forAuction && (
    //       <Link
    //         to={
    //           "/mp/" +
    //           listingDetails.collectionName +
    //           "/" +
    //           listingDetails.id
    //         }
    //       >
    //         <span>Auction</span>
    //       </Link>
    //     )}
    //     {itemCurrentListings.forSale && (
    //       <Link
    //         to={
    //           "/mp/" +
    //           listingDetails.collectionName +
    //           "/" +
    //           listingDetails.id
    //         }
    //       >
    //         <span>Sale</span>
    //       </Link>
    //     )}
    //     {itemCurrentListings.offered && (
    //       <Link
    //         to={
    //           "/mp/" +
    //           listingDetails.collectionName +
    //           "/" +
    //           listingDetails.id
    //         }
    //       >
    //         <span>Offered</span>
    //       </Link>
    //     )}
    //   </Row>
    // ) : (
    //   <Row>
    //     <span>None</span>
    //   </Row>
    // )}
    //         </Col>
    //       </Row>

    //       {itemRoyalties && (
    //         <Row className="py-3 align-items-center">
    //           <Col xs="12" md="3">
    //             <h3 className="themeColor">Royalties:</h3>
    //           </Col>
    //           <Col className="propertiesWrap">
    // <Row>
    // {Object.keys(itemRoyalties).map((properties, i) => {
    //   return (
    //     <Col key={i} xs="auto" className="propertiesRoyalty m-1">
    //       <div>
    //         <span className="fw-bold small">Royalty Name:</span>{" "}
    //         {itemRoyalties[properties].royaltyName}
    //       </div>
    //       <div>
    //         <span className="fw-bold small">Paid to:</span>{" "}
    //         {itemRoyalties[properties].findName ? (
    //           <Link
    //             style={{ textDecoration: "none" }}
    //             to={"/" + itemRoyalties[properties].findName}
    //           >
    //             <span className="fw-bold small">
    //               <span className="themeColor">
    //                 {itemRoyalties[properties].findName}
    //               </span>
    //               .find
    //             </span>
    //           </Link>
    //         ) : (
    //           itemRoyalties[properties].address
    //         )}
    //       </div>
    //       <div>
    //         <span className="fw-bold small">Cut percentage:</span>{" "}
    //         {parseFloat(itemRoyalties[properties].cut).toFixed(
    //           2
    //         ) * 100}
    //         %
    //       </div>
    //     </Col>
    //   );
    // })}
    // </Row>
    //           </Col>
    //         </Row>
    //       )}
    //     </Card.Body>
    //   </Card>
    // );
  } else {
    return <p>No properties</p>;
  }
};

// const itemRoyalties = () =>{
//   return (

//   )
// }

// export default itemRoyalties;
export default CatalogDetailsPropertyCardMarket;
