import React from "react";
import { Image } from "react-bootstrap";
import parseNameLink from "../../../functions/parseNameLink";
import CustomImageIcon from "../../CustomImage/CustomImgIcon";

const MpDetailOwnedByTop = ({ profileDetails }) => {
  // console.log(profileDetails);

  return (
    <div className="flex-row">
      <div className="profileMenuPic" style={{marginRight: '10px'}}>
        {profileDetails && (
          <CustomImageIcon imgUrl={profileDetails.avatar}/>
        )}
      </div>
      <div>
        <div>
          <span className="small fw-bold">Owned By:</span>
        </div>
        <div>{parseNameLink(profileDetails)}</div>
      </div>
    </div>
    // <Row className="align-items-center">
    //   <Col className="profileMenuPic">
    //     {profileDetails && <Image src={profileDetails.avatar} style={{height: '40px'}} />}
    //   </Col>
    //   <Col align="left">
    //     <Row>
    //       <small>Owned By:</small>
    //     </Row>
    //     <Row className="find-text">
    //   {parseNameLink(profileDetails)}
    //     </Row>
    //   </Col>
    // </Row>
  );
};

export default MpDetailOwnedByTop;
