import React from "react";
import { Col, Row } from "react-bootstrap";
import MpDetailOwnedByTop from "../../../../search/mpdetailpage/mpdetailownedbytop";
import ColDetailLikesViews from "./ColDetailLikesViews";

const ColDetailBelowName = ({ listingStatus }) => {
  return (
    <>
      <Row className="align-items-center my-2">
        <Col xs="12" xxl="6" className="">
          <Row className="flex-row-between">
            <Col xs="auto">
              {listingStatus.owner.ownerProfile && (
                <MpDetailOwnedByTop
                  profileDetails={listingStatus.owner.ownerProfile}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col xs="auto" className="mt-4 mt-xxl-0">
          <ColDetailLikesViews listingStatus={listingStatus} />
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <span className="secColor">{listingStatus.itemDescription}</span>
        </Col>
      </Row>
    </>
  );
};

export default ColDetailBelowName;
