import React from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import "./bestcollections.css";

const BestCollections = () => {
  return (
    <Container className="py-5 my-5">
      <Row className="pt-5">
        <Col align="center">
          <h2>The best collections available within .find</h2>
        </Col>
      </Row>
      <Row className="pb-2" >
        <Col className="px-4 secondary-text" align="center">
          <p style={{ color: "var(--text-secondary)" }}>
            {" "}
            We are working hard to ensure all of your favourite NFT collections
            are available in .find, and so far, we have some pretty big names:
          </p>
        </Col>
      </Row>

      <Row
        align="center"
        className="py-2 pb-5 align-items-center justify-content-center"
      >
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center best-collections-card mt-2">
            <Image
              className="collections-img"
              width="64px"
              src="/assets/img/home/homecollectionsbanner/collections1.png"
            />
          </Card>
        </Col>
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center best-collections-card mt-2">
            <Image
              className="collections-img"
              width="64px"
              src="/assets/img/home/homecollectionsbanner/collections2.png"
            />
          </Card>{" "}
        </Col>
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center best-collections-card mt-2">
            <Image
              className="collections-img"
              width="64px"
              src="/assets/img/home/homecollectionsbanner/collections3.png"
            />
          </Card>
        </Col>
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center best-collections-card mt-2">
            <Image
              className="collections-img"
              width="64px"
              src="/assets/img/home/homecollectionsbanner/collections4.png"
            />
          </Card>
        </Col>
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center best-collections-card mt-2">
            <Image
              className="collections-img"
              width="64px"
              src="/assets/img/home/homecollectionsbanner/collections5.png"
            />
          </Card>
        </Col>
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center best-collections-card mt-2">
            <Image
              className="collections-img"
              width="64px"
              src="/assets/img/home/homecollectionsbanner/collections6.png"
            />
          </Card>
        </Col>
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center best-collections-card mt-2">
            <Image
              className="collections-img"
              width="64px"
              src="/assets/img/home/homecollectionsbanner/collections7.png"
            />
          </Card>
        </Col>
        <Col xs="auto">
          <Card className="p-2 align-items-center justify-content-center secondary-text best-collections-card mt-2">
            Plus more coming...
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BestCollections;
