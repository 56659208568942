import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactHeader from "../components/contact/contactheader/contactheader";
import { Helmet } from "react-helmet";

const Contact = ({ findUser, metaTitle, metaDescription }) => {
  if (findUser) {
    return (
      <Container
        fluid={+true}
        style={{
          backgroundColor: "var(--background-profile)",
        }}
      >
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <Container className="content-container">
          <Row className="pt-2 justify-content-center">
            <Col
              className="px-0 logged-in-main-col-width py-3 pt-4"
              xs="12"
              lg="auto"
            >
              <h3>Get in contact</h3>
              <Image
                className="py-3"
                fluid={+true}
                src="/assets/img/contact/contact1.png"
                alt=".find contact banner"
              />
              <Image
                className="py-3"
                fluid={+true}
                src="/assets/img/contact/contact2.png"
                alt=".find contact banner"
              />
              <Image
                className="py-3"
                fluid={+true}
                src="/assets/img/contact/contact3.png"
                alt=".find contact banner"
              />
              <Image
                className="py-3"
                fluid={+true}
                src="/assets/img/contact/contact4.png"
                alt=".find contact banner"
              />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  } else {
    return (
      <>
        <Breadcrumbs />
        <Container
          fluid={+true}
          style={{
            backgroundColor: "var(--background-profile)",
            minHeight: "calc(100vh - 218px",
          }}
        >
          <Container className="content-container">
            <ContactHeader />
            <Row className="justify-content-center pb-5">
              <Col
                className="px-0 logged-in-main-col-width py-3 "
                xs="12"
                lg="auto"
              >
                <Image
                  fluid={+true}
                  className="py-3"
                  src="/assets/img/contact/contact1.png"
                  alt=".find contact banner"
                />
                <Image
                  fluid={+true}
                  className="py-3"
                  src="/assets/img/contact/contact2.png"
                  alt=".find contact banner"
                />
                <Image
                  className="py-3"
                  fluid={+true}
                  src="/assets/img/contact/contact3.png"
                  alt=".find contact banner"
                />
                <Image
                  className="py-3"
                  fluid={+true}
                  src="/assets/img/contact/contact4.png"
                  alt=".find contact banner"
                />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
};

export default Contact;
