import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UseThemeStatus } from "../../../../functions/themeMode";

export default function AvailableOnFindCardName(props){
  const { lease, listingDetails, nftDetails, isName } = props;
  const { forSale, forAuction } = props

  const mpLink = isName
    ? "/mp/FIND/" + lease?.name
    : "/mp/" + nftDetails?.collection + "/" + listingDetails?.listingId

  return (
    <Row style={{height: "100px"}} className="mx-0 px-0">
      <Card
        className="align-items-between justify-content-center"
        style={{backgroundColor: "var(--background-profile)"}}
      >
        <Row className="align-items-center justify-content-center p-2">
          <Col xs="7" md="8">
            <Row className="align-items-center justify-content-center py-2">
              <Image
                className="d-none d-md-block"
                style={{
                  height: "40px",
                  width: "65px",
                  borderRadius: "12px",
                }}
                src="/assets/img/store/fnamestore.png"
              />

              <Col className="px-2">
                <Col>
                  <h4>.find Market</h4>
                </Col>

                <Col style={{ color: "var(--text-secondary" }}>
                  {forAuction && !forSale && "Auction"}
                  {!forAuction && forSale && "Sale"}
                  {forAuction && forSale && "Sale and Auction"}
                </Col>
              </Col>
            </Row>
          </Col>
          <Col align="right">
            {isName ? (
                <p className="mb-0 fw-bold">
                  {forSale
                    ? parseFloat(lease.salePrice).toFixed(2)
                    : forAuction && lease.latestBid
                      ? parseFloat(lease.latestBid).toFixed(2)
                      : parseFloat(lease.auctionStartPrice).toFixed(2)}{" "}
                  FUSD
                </p>
            ) : (
              <h3 className="mb-0">
                {listingDetails.amount * 1} {listingDetails.ftAlias}
              </h3>
            )}
          </Col>
          <Col xs="2" md="1">
            <Link to={mpLink}>
              {UseThemeStatus() === "light" ? (
                <Image
                  style={{
                    borderRadius: "0px",
                    height: "25px",
                    width: "25px",
                  }}
                  src="/assets/img/profile/linkToLight.svg"
                />
              ) : (
                <Image
                  style={{
                    borderRadius: "0px",
                    height: "25px",
                    width: "25px",
                  }}
                  src="/assets/img/profile/linkToDark.svg"
                />
              )}
            </Link>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};