import Filter from "../Generic/Filter";
import groups from "./groups";

export default function TemplateFilter() {
  const title = "Template";
  const attribute = "string.template";
  const options = { attribute, title, groups };

  return <Filter {...options} />;
}
