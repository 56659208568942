import React from "react";
import { Link } from "react-router-dom";
import FlowSticker from "../../../../forms/ftstickers/FlowSticker";
import "./ColDetailAvailableOn.scss";

const ColDetailAvailableOnStorefront = (props) => {
  const { listingStatus } = props
  const ftType = onDetails.ftTypeIdentifier.split('.')[2]
  const onDetails = listingStatus.allCurrentListings.storefront;
  return (
    <div className="available-on-wrapper-storefront p-3 my-4">
      <div className="available-on-row">
        <div className="available-on-icon-col">
          <img
            id="icon-img"
            src="/assets/img/profile/collection/nft-detail/on-storefront.png"
          />
          <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
        </div>
        <div className="available-on-info-col">
          <div className="available-on-info-row-1">Storefront</div>
          <div className="available-on-info-row-2">2</div>
        </div>
        <div className="available-on-price-col">
          <div className="available-on-price-row-1">
            <div>{onDetails.amount * 1}</div>&nbsp;
            <div>
              {ftType === "Flow" ? (
                <FlowSticker noBg={"true"} />
              ) : (
                ftType
              )}
            </div>
            &nbsp;
            <Link to={``}>
              <div>
                <img
                  style={{ marginBottom: "3px" }}
                  src="/assets/img/profile/collection/nft-detail/chevron-right.svg"
                />
              </div>
            </Link>
          </div>{" "}
          <div className="available-on-price-row-2">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default ColDetailAvailableOnStorefront;
