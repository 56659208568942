import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Star, StarFill } from "react-bootstrap-icons";
import {
  getMarketplaceNFTLikes,
  getNFTViewCount,
  handleNFTFavClicked,
  handleNFTLikeClicked,
  handleNFTViewIncrement,
  hasUserFavNFT,
  hasUserLikedNFT,
} from "../../../firebaseConfig";
import parseImgUrl from "../../../functions/parseImgUrl";
import { UseThemeStatus } from "../../../functions/themeMode";
import "./mpdetailpage.css";
import * as fcl from "@onflow/fcl";
import ReactGA from "react-ga4";

const MpDetailLikesViews = ({ hit, user, listingStatus }) => {
  const [likes, setLikes] = useState(null);
  const [isNFTLikedByUser, setIsNFTLikedByUser] = useState(null);
  const [isNFTFavByUser, setIsNFTFavByUser] = useState(null);
  const [viewCount, setViewCount] = useState(null);
  const [itemThumbnail, setItemThumbnail] = useState(null);

  const themeStatus = UseThemeStatus();

  async function getLikes(collectionName, nftType, nftId, user) {
    const likes = await getMarketplaceNFTLikes(collectionName, nftType, nftId);
    const isLiked = await hasUserLikedNFT(collectionName, nftType, nftId, user);
    setLikes(likes);
    setIsNFTLikedByUser(isLiked);
  }
  async function getViews(collectionName, nftType, nftId) {
    const views = await getNFTViewCount(collectionName, nftType, nftId);
    setViewCount(views);
  }

  async function getFavs(collectionName, nftId, user) {
    const isFav = await hasUserFavNFT(collectionName, nftId, user);
    setIsNFTFavByUser(isFav);
  }

  useEffect(() => {
    if (hit) {
      // console.log('this')
      handleNFTViewIncrement(
        hit.collection_name,
        hit.nft_type,
        hit.nft_id.toString(),
        hit.nft_name
      );
      if (hit.collection_name !== "FIND") {
        setItemThumbnail(hit.nft_thumbnail);
      } else {
        setItemThumbnail(
          parseImgUrl(
            "/assets/img/marketplace/find_name_with_find.png",
            "",
            hit.nft_name
          )
        );
      }
      if (viewCount === null) {
        getViews(
          hit.collection_name,
          hit.nft_type,
          hit.nft_id.toString(),
          user
        );
      }
      if (likes === null) {
        getLikes(
          hit.collection_name,
          hit.nft_type,
          hit.nft_id.toString(),
          user
        );
      }
      if (isNFTFavByUser === null) {
        // console.log('bing')
        getFavs(hit.collection_name, hit.nft_id.toString(), user);
      }
    }
    // console.log("this");

    // const views = await getMarketplaceNFTLikes(hit)
  }, [hit, user]);

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <div
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    )
  );

  const renderTooltipLikes = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Likes</span>
    </Tooltip>
  );
  const renderTooltipFavs = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Add to fav list</span>
    </Tooltip>
  );
  const renderTooltipViews = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Total views</span>
    </Tooltip>
  );

  return (
    <Row className="align-items-center">
      <OverlayTrigger placement="top" overlay={renderTooltipFavs}>
        <Col xs="auto">
          {/* favs */}
          {user && user.loggedIn ? (
            <div className="flex-row likes-icon-box">
              <Image
                src={
                  themeStatus === "light" && !isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light.svg"
                    : themeStatus === "light" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light-fill.svg"
                    : themeStatus === "dark" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-dark-fill.svg"
                    : "/assets/img/marketplace/heart-dark.svg"
                }
                onClick={async () => {
                  await handleNFTFavClicked(
                    hit.collection_name,
                    hit.nft_type,
                    hit.nft_id.toString(),
                    hit.nft_name,
                    itemThumbnail,
                    hit.seller,
                    user
                  );
                  getFavs(hit.collection_name, hit.nft_id.toString(), user);
                }}
                alt=""
                style={{ width: "25px", cursor: "pointer" }}
              />
              &nbsp;&nbsp;
            </div>
          ) : (
            <div className="flex-row likes-icon-box">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  id="favs-dropdown-toggle"
                >
                  <Image
                    src={
                      themeStatus === "light" && !isNFTFavByUser
                        ? "/assets/img/marketplace/heart-light.svg"
                        : themeStatus === "light" && isNFTFavByUser
                        ? "/assets/img/marketplace/heart-light-fill.svg"
                        : themeStatus === "dark" && isNFTFavByUser
                        ? "/assets/img/marketplace/heart-dark-fill.svg"
                        : "/assets/img/marketplace/heart-dark.svg"
                    }
                    alt=""
                    style={{ width: "25px", cursor: "pointer" }}
                  />
                </Dropdown.Toggle>{" "}
                <Dropdown.Menu>
                  <Dropdown.Item
                    target="_blank"
                    className="secColor small"
                    rel="noreferrer"
                    onClick={() => {
                      fcl.logIn();
                      ReactGA.event("login", {
                        method: "wallet",
                      });
                    }}
                  >
                    Please log in to fav
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              &nbsp;&nbsp;
            </div>
          )}
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={renderTooltipLikes}>
        <Col xs="auto">
          {/* //likes */}
          {user && user.loggedIn ? (
            <div className="flex-row likes-icon-box">
              <Image
                src={
                  themeStatus === "light" && !isNFTLikedByUser
                    ? "/assets/img/marketplace/thumbs-up-light.svg"
                    : themeStatus === "light" && isNFTLikedByUser
                    ? "/assets/img/marketplace/thumbs-up-light-fill.svg"
                    : themeStatus === "dark" && isNFTLikedByUser
                    ? "/assets/img/marketplace/thumbs-up-dark-fill.svg"
                    : "/assets/img/marketplace/thumbs-up-dark.svg"
                }
                onClick={async () => {
                  await handleNFTLikeClicked(
                    hit.collection_name,
                    hit.nft_type,
                    hit.nft_id.toString(),
                    hit.nft_name,
                    user
                  );
                  getLikes(
                    hit.collection_name,
                    hit.nft_type,
                    hit.nft_id.toString(),
                    user
                  );
                }}
                alt=""
                style={{ width: "25px", cursor: "pointer" }}
              />
              &nbsp;&nbsp;
              <span className="propertiesWrap">{likes}</span>
            </div>
          ) : (
            <div className="flex-row likes-icon-box">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  id="likes-dropdown-toggle"
                >
                  <Image
                    src={
                      themeStatus === "light"
                        ? "/assets/img/marketplace/thumbs-up-light.svg"
                        : "/assets/img/marketplace/thumbs-up-dark.svg"
                    }
                    alt=""
                    style={{ width: "25px", cursor: "pointer" }}
                  />{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    target="_blank"
                    className="secColor small"
                    rel="noreferrer"
                    onClick={() => {
                      fcl.logIn();
                      ReactGA.event("login", {
                        method: "wallet",
                      });
                    }}
                  >
                    Please log in to like
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              &nbsp;&nbsp;
              <span className="propertiesWrap">{likes}</span>
            </div>
          )}
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={renderTooltipViews}>
        <Col xs="auto">
          <div className="flex-row likes-icon-box">
            <Image
              src={
                themeStatus === "light"
                  ? "/assets/img/marketplace/views-icon-light.svg"
                  : "/assets/img/marketplace/views-icon-dark.svg"
              }
              alt=""
              style={{ width: "25px" }}
            />
            &nbsp;&nbsp;
            <span className="propertiesWrap">{viewCount}</span>
          </div>
        </Col>
      </OverlayTrigger>

      {/* <Col xs="auto">
        <div>
          {isNFTFavByUser ? (
            <StarFill
              style={{ width: "30px", marginRight: "10px", cursor: "pointer" }}
              onClick={async () => {
                await handleNFTFavClicked(hit, user);
                getFavs(hit);
              }}
            />
          ) : (
            <Star
              style={{ width: "30px", marginRight: "10px", cursor: "pointer" }}
              onClick={async () => {
                await handleNFTFavClicked(hit, user);
                getFavs(hit);
              }}
            />
          )}
          Add to favorites
        </div>
      </Col> */}
    </Row>
  );
};

export default MpDetailLikesViews;
