import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Card,
  Form,
} from "react-bootstrap";
import { scripts, transactions } from "@findonflow/find-flow-contracts-1.0";
import { Script, Tx } from "../functions/script";
import DropPage from "../components/DropPage/DropPage";
import { Helmet } from "react-helmet";

const PurchasePack = () => {

  return (
    <div>
       <Helmet>
        <title>Party Favorz x Artist DRE / .find</title>
        <meta name="description" content="The PartyFavorz X Artist DRE drop" />
      </Helmet>
      <DropPage />
    </div>
  );
};

export default PurchasePack;
// <Container
//   fluid={+true}
//   className="pb-5 pt-4"
//   style={{
//     backgroundColor: "var(--background-profile)",
//     minHeight: "calc(100vh - 218px)",
//   }}
// >
//   {packDeatils && (
//     <Container className="content-container">
//       <Container fluid={+true} id="collection-header" className="mb-3 px-0">
//         <Row>
//           <Col className="">
//             <Card className="outer-wrapper">
//               <div
//                 className="header-card-bg"
//                 style={{
//                   backgroundImage:
//                     "url('" +
//                     `https://find.mypinata.cloud/ipfs/${packDeatils.collectionDisplay.bannerImage.file.cid}` +
//                     "')",
//                   backgroundPosition: "center",
//                   backgroundSize: "cover",
//                   cursor: "pointer",
//                 }}
//               ></div>
//               <Card.Body>
//                 <Row className="justify-content-sm-center justify-content-lg-start">
//                   <Col
//                     className="header-square-col ms-lg-2 d-flex justify-content-center"
//                     xs="12"
//                     lg="auto"
//                   >
//                     <div
//                       className="profile-pic"
//                       // onClick={(e) => setSquareOpen(true)}
//                       style={{ cursor: "pointer" }}
//                     >
//                       <Image
//                         src={`https://find.mypinata.cloud/ipfs/${packDeatils.collectionDisplay.squareImage.file.cid}`}
//                       />
//                     </div>
//                   </Col>
//                   <Col>
//                     <div className="header-name-padding d-none d-lg-block"></div>
//                     <Row className="align-items-center">
//                       <Col className="d-flex justify-content-center justify-content-lg-start mt-3 mt-lg-0">
//                         <h3>{packDeatils.name}</h3>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col className="justify-content-center text-center text-lg-start justify-content-lg-start px-3">
//                         <span className="collection-description">
//                           {packDeatils.collectionDisplay.description}
//                         </span>
//                       </Col>
//                     </Row>
//                     <Row className="d-flex align-items-end justify-content-end">
//                       <Col className="mt-3 icons-col" xs="4" sm="4" md="2">
//                         <Row>
//                           {packDeatils.collectionDisplay.externalURL && (
//                             <Col>
//                               <span className="icon-span">
//                                 <a
//                                   href={
//                                     packDeatils.collectionDisplay
//                                       .externalURL.url
//                                   }
//                                   target="_blank"
//                                   rel="noreferrer"
//                                   style={{ textDecoration: "none" }}
//                                 >
//                                   <i className={"fa-solid fa-globe"} />
//                                 </a>
//                               </span>
//                             </Col>
//                           )}

//                           {packDeatils.collectionDisplay.socials &&
//                             Object.keys(
//                               packDeatils.collectionDisplay.socials
//                             ).map((link, index) => (
//                               <Col key={index}>
//                                 <span className="icon-span">
//                                   <a
//                                     href={
//                                       packDeatils.collectionDisplay.socials[
//                                         link
//                                       ].url
//                                     }
//                                     target="_blank"
//                                     rel="noreferrer"
//                                     style={{ textDecoration: "none" }}
//                                   >
//                                     <i className={"fa-brands fa-" + link} />
//                                   </a>
//                                 </span>
//                               </Col>
//                             ))}
//                         </Row>
//                       </Col>
//                     </Row>
//                   </Col>
//                 </Row>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//       <Card className="p-3 detail-nft">
//         <Row className="mb-3">
//           <Col><span className="themeColor">Featured Drop</span></Col>
//         </Row>
//         <Row className="mb-2">
//           <Col><h3>{packDeatils.name} Pack</h3></Col>
//         </Row>
//         <Row>
//           <Col xs="12" className="mb-3">
//             Packs Left: {packDeatils.packsLeft}
//           </Col>
//         </Row>
//         {/* {!user.addr ?
//           <Row>
//             <Col xs="12" className="mb-4 themeColor">
//               Connect your wallet to see if you qualify for any of the
//               below sales
//             </Col>
//           </Row>
//           :
//           <Row>
//             <Col xs="12" className="mb-4 themeColor">
//               You are qualified for{" "}
//               <span className="fw-bold">
//                 {packDeatils.userQualifiedSale?.name}
//               </span>
//             </Col></Row>} */}
//         <Row className="mb-3">
//           <Col className="d-none d-md-block">
//             <Card.Img
//               src={`https://find.mypinata.cloud/ipfs/${packDeatils.thumbnailHash}`}
//               alt={"Picture of " + packDeatils.name}
//             />
//           </Col>
//           <Col>
//             <Row className="h-100 align-content-start">
//               <Row className=" my-2">
//                 <Col className="align-items-center justify-content-center">
//                   <Image
//                     style={{
//                       height: "2rem",
//                       width: "2rem",
//                       marginRight: "0.5rem",
//                     }}
//                     fluid={+true}
//                     src="/assets/img/marketplace/verify1.png"
//                   />
//                   <span className="collection-name">
//                     {packDeatils.collectionDisplay.name}
//                   </span>
//                 </Col>
//               </Row>

//               {packDeatils.saleInfos.map((info, i) => {
//                 const startDate = new Date(parseInt(info.startTime) * 1000);
//                 const endDate =
//                   info.endTime === null
//                     ? null
//                     : new Date(parseInt(info.endTime) * 1000);
//                 return (
//                   <Col xs="12" key={i} className="mb-4">
//                     <div style={{fontWeight: "600"}}>{info.name}</div>
//                     <div className="text-secondary mb-2">
//                       Price: {parseFloat(info.price)} FLOW
//                     </div>
//                     <Row>
//                       <Col xs="auto" className="mt-2">
//                       <span style={{ padding: "4px 12px", background: "#F2F4F7", borderRadius: "16px", fontSize: "14px", color: "black"}}>
//                       Start Date:{" "}
//                       {startDate.getMonth() +
//                         1 +
//                         "/" +
//                         startDate.getDate() +
//                         "/" +
//                         startDate.getFullYear() +
//                         " " +
//                         startDate.getHours() +
//                         ":" +
//                         startDate.getMinutes() +
//                         ":" +
//                         startDate.getSeconds()}</span>
//                       </Col>
//                       <Col xs="auto" className="mt-2">
//                       {endDate && (
//                         <span style={{ padding: "4px 12px", background: "#FEF3F2", borderRadius: "16px", fontSize: "14px", color: "red"}}>
//                         End Date:{" "}
//                         {endDate.getMonth() +
//                           1 +
//                           "/" +
//                           endDate.getDate() +
//                           "/" +
//                           endDate.getFullYear() +
//                           " " +
//                           endDate.getHours() +
//                           ":" +
//                           endDate.getMinutes() +
//                           ":" +
//                           endDate.getSeconds()}
//                       </span>
//                     )}
//                       </Col>
//                     </Row>
//                   </Col>
//                 );
//               })}

//               {
//               //(user?.addr || findUser?.address) &&
//                 packDeatils.userQualifiedSale?.canBuyNow || packDeatils.saleInfos[2].startTime < Date() &&
//                 parseInt(packDeatils.packsLeft) !== 0 && (
//                   <Col xs="6" md="6" className="mt-3">
//                     <Form.Label>Number of packs to purchase</Form.Label>
//                     <Form.Control
//                       type="number"
//                       min="1"
//                       name="numOfPacks"
//                       required
//                       placeholder="1"
//                       value={numOfPacks}
//                       onChange={(e) => setNumOfPacks(e.target.value)}
//                     />
//                     {packDeatils.userQualifiedSale?.purchaseLimit !== null &&
//                       numOfPacks >
//                       parseInt(
//                         packDeatils.userQualifiedSale?.purchaseLimit
//                       ) -
//                       parseInt(
//                         packDeatils.userQualifiedSale?.userPurchaseRecord
//                       ) && (
//                         <p className="text-danger">
//                           You are only allowed to purchase a total of{" "}
//                           {packDeatils.userQualifiedSale.purchaseLimit}{" "}
//                           packs
//                         </p>
//                       )}
//                   </Col>
//                 )}
//               {
//               //(user?.addr || findUser?.address) &&
//                 packDeatils.userQualifiedSale?.canBuyNow || packDeatils.saleInfos[2].startTime < Date() &&
//                 parseInt(packDeatils.packsLeft) !== 0 && (
//                   <Row className="px-3 mt-3">
//                     <Button
//                       variant="find-outline-dark"
//                       onClick={purchasePack}
//                       disabled={
//                         numOfPacks <= 0 ||
//                         (packDeatils.userQualifiedSale?.purchaseLimit !==
//                           null &&
//                           numOfPacks >
//                           parseInt(
//                             packDeatils.userQualifiedSale?.purchaseLimit
//                           ) -
//                           parseInt(
//                             packDeatils.userQualifiedSale?.userPurchaseRecord
//                           ))
//                       }
//                     >
//                       Buy Now
//                     </Button>
//                   </Row>
//                 )}
//             </Row>
//           </Col>
//         </Row>
//         <Row className="mt-3">
//           <h3>The Art</h3>
//         </Row>
//         <Row>
//           <p>
//             Flomies is a collection of 3333 homies on flow (Flow-me // Flow
//             Homies). These characters are unique and were carefully
//             hand-drawn over an 8 month time period - we have come so far
//             since our original design. Our goal for our art has always been
//             to be in consideration for the most unique art in this
//             ecosystem.
//           </p>
//         </Row>
//         <Image
//           src="https://4138204603-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FbLyKO2rxaW8sXrWPBnF6%2Fuploads%2F5a0SPhAR3pAC0PJZw99B%2FMy%20project-1%20(48).png?alt=media&token=a50ad67b-106e-45cc-8094-12ed97ca7b3a"
//           height="400px"
//         />
//         <Row className="mt-3">
//           <h3>Mental Health</h3>
//         </Row>
//         <Row>
//           <p>
//             The Flomies are dedicated to bring awareness to mental health &
//             illness among several other initiatives that deserve more
//             attention. This will be an adventure that will continue to
//             reward Flomies holders in every business venture we join under
//             the Flomies project - we are working on a program involving
//             schools and universities in the United States. Our team has
//             added and will continue to add clues to what we're up to.
//           </p>
//         </Row>
//         <Row>
//           <p>
//             Our mission is to build a unique adventure, foster a calm and
//             welcoming community and ultimately our goal is to save lives.
//           </p>
//         </Row>
//         <Row className="mt-3">
//           <h3>Why do we consider ourselves an experimental project?</h3>
//         </Row>
//         <Row>
//           <p>
//             Because we see the same things over and over again in this
//             space. Mint + airdrop and that's it for a while - don't get us
//             wrong; some projects are innovating, we just want to be one of
//             them. WE ARE GOING TO TRY NEW THINGS. WE ARE GOING TO
//             EXPERIMENT.
//           </p>
//         </Row>
//         <Row>
//           <p>
//             Some of the things that we try could be duds and not work out,
//             but we truly believe some of the things we have planned will
//             pave the way for the future development on Flow.
//           </p>
//         </Row>
//         <Row className="mt-5">
//           <p className="themeColor">
//             Learn more about Flomies{" "}
//             <a
//               target="_blank"
//               rel="noreferrer"
//               href="https://flomies.gitbook.io/puzl-pages/"
//               style={{ color: "var(--color-theme)" }}
//             >
//               here
//             </a>
//             .
//           </p>
//         </Row>
//       </Card>
//     </Container>
//   )}
// </Container>
