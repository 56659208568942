import { Button, Col, Container, Row } from "react-bootstrap";
import './joindiscord.css'

export function JoinDiscord() {
    return (
        <Container id="join-discord" fluid={+true} className="g-0 discordBG">
            <Container className="discordText">
                <Row style={{height: "200px"}}>
                    <Col className="my-auto px-5" align="center">
                    <div className="discordTitle" >.find us on <span style={{}}>Discord</span></div>
                    </Col>
                    <Col className="my-auto px-5" align="center"><a style={{textDecoration: 'none'}} href="https://discord.gg/tCSFSpFcRv" target="_blank" rel="noreferrer"><Button className="d-flex align-items-center" variant="find-nochange-dark"><i className="fab fa-discord m-1"/>&nbsp;Join&nbsp;<span className="d-none d-lg-block"> the community</span></Button></a></Col>
                </Row>
                
            </Container>
        </Container>
    )
}