import { Link } from "react-router-dom";
import { useState, useEffect } from "react"
import * as fcl from "@onflow/fcl";
import useSound from "use-sound";
import { itemLogo, splitAndCap, walletCheck } from "../../../utils";

import bubblePop from "./sound/bubble-pop.aac";
import parseImgUrl from "../../../../../../../functions/parseImgUrl";
import { handleBuyItemDirectSale } from "../../../../../../../functions/flow-interactions/transactions";
import { useWalletType } from "../../../../../../../functions/useWalletType";

// Styles
import "./doodle.scss";
import "./doodle.media.scss";

const sounds = [bubblePop];
const withSound = true;

const { format } = Intl.NumberFormat(navigator.language);

export default function SingleDoodle(props) {
  const { uuid, seller, amount, amount_alias } = props;
  const { nft_id, nft_name, nft_thumbnail, id } = props;

  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser().subscribe(setUser), []);

  const soundIndex = nft_id % sounds.length;
  const sound = sounds[soundIndex];
  const options = { volume: 0.1, interrupt: true };
  const [play, { stop }] = useSound(sound, options);

  const src = parseImgUrl(nft_thumbnail);
  const title = splitAndCap(nft_name, " ");
  const ownItem = user.addr === seller ? true : false
  const wallet = useWalletType();
  const currentWallet = wallet === "none" ? "none" : wallet === "Dapper Wallet" ? wallet : "Flow Wallet"
  const walletNeedsChange = walletCheck(amount_alias) === currentWallet ? false : true

  const itemButtonText = () => {
    if (wallet !== "none") {
      if (ownItem) {
        return ("You own this")
      } else {
        if (walletNeedsChange) {
          return ("Wrong wallet")
        } else {
          return ("Buy")
        }
      }
    } else {
      return (<button className="doodle__button__nobg" onClick={() => fcl.authenticate()}>Login</button>)
    }
  }

  return (
    <div
      className={"doodle"}
      key={id}
      onMouseEnter={() => {
        if (withSound) {
          play();
        }
      }}
      onMouseLeave={stop}
    >
      <div className={"doodle__preview-container"}>
        <div className="doodle__preview-container__preview">
          <div className="doodle__preview-container__crop">
            <img src={src} alt={title} crossOrigin="anonymous" className="doodle__image" />
          </div>
        </div>

        <div className="doodle__shadow-container" />
      </div>

      <div className="doodle__details-container">
        <Link to={`${nft_id}`}>
          <h2>{title}</h2>
        </Link>
      </div>

      <div className="doodle__price-container">
        <span className="doodle__price-container--dim">Price:</span>
        <span>{amount_alias === "DapperUtilityCoin" ? "$" + format(amount) : format(amount)} {itemLogo(amount_alias)}</span>
      </div>

      <div className="doodle__action-container">
        <button
          disabled={ownItem || walletNeedsChange}
          className="doodle__button"
          onClick={() => {
            handleBuyItemDirectSale(seller, amount, uuid, wallet);
          }}
        >
          
          {itemButtonText()}
        </button>
      </div>
    </div>
  );
}
