import React from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import "./helphubsidenav.css";

const HelpHubSideNavMobile = () => {
  function CustomToggle({ children, eventKey, lease, manage }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <Button
        onClick={decoratedOnClick}
        style={{
          backgroundImage: "var(--accordion-chevron)",
          width: "26px",
          height: "26px",
          backgroundColor: "var(--background)",
          borderColor: "var(--background)",
          boxShadow: "none",
        }}
      ></Button>
    );
  }

  return (
    <Container
      id="helphub-side-nav-mobile-container"
      className="mb-3 px-0 align-items-center justify-content-center"
    >
      <Card id="helphub-side-nav-mobile-card" className="my-2">
        <Card.Body>
          <Accordion defaultActiveKey={0}>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#getting-started"><h5>Getting Started</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={0} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={0}>
                  <ul>
                    <li>
                      <a href="#help-blocto">Creating a Blocto Wallet</a>
                    </li>
                    <li>
                      <a href="#help-register-email">Registering Email</a>
                    </li>
                    <li>
                      <a href="#help-create-profile">
                        Creating a .find Profile
                      </a>
                    </li>
                    <li>
                      <a href="#help-create-profile-success">
                        Successful Profile Creation
                      </a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#help-new-profile"><h5>Your .find Profile</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={1} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={1}>
                  <ul>
                    <li>
                      <a href="#help-new-profile">Your New .find Profile</a>
                    </li>
                    <li>
                      <a href="#help-profile-looking">Looking Around Profile</a>
                    </li>
                    <li>
                      <a href="#help-profile-custom">Customising Profile</a>
                    </li>
                    <li>
                      <a href="#help-profile-funding">Funding Profile</a>
                    </li>
                    <li>
                      <a href="#help-profile-name">A Name for Your Profile</a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#help-name-reg"><h5>.find Names</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={2} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={2}>
                  <ul>
                    <li>
                      <a href="#help-name-reg">Registering a Name</a>
                    </li>
                    <li>
                      <a href="#help-name-lease">Names Lease and Cost</a>
                    </li>
                    <li>
                      <a href="#help-name-chars">What's in a Name?</a>
                    </li>
                    <li>
                      <a href="#help-name-manage">Managing Names</a>
                    </li>{" "}
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#help-profile-collections"><h5>Profile Collections</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={3} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={3}>
                  <ul>
                    <li>
                      <a href="#help-profile-collections">
                        Profile Collections
                      </a>
                    </li>
                    <li>
                      <a href="#help-profile-collections-supported">
                        Supported Collections
                      </a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#help-buying-market"> <h5>Buying and Selling</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={4} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={4}>
                  <ul>
                    <li>
                      <a href="#help-buying-market">The Market Tab</a>
                    </li>
                    <li>
                      <a href="#help-buying-listing">Listing Items</a>
                    </li>
                    <li>
                      <a href="#help-buying-listing-names">Listing Names</a>
                    </li>
                    <li>
                      <a href="#help-buying-listing-nfts">Listing NFTs</a>
                    </li>
                    <li>
                      <a href="#help-buying-direct-listing">Direct Listings</a>
                    </li>
                    <li>
                      <a href="#help-buying-auction-rules">Auction Rules</a>
                    </li>
                    <li>
                      <a href="#help-buying-offering">Buying and Offering</a>
                    </li>
                    <li>
                      <a href="#help-buying-user-store">The User Store</a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#help-mp"><h5>The Marketplace</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={5} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={5}>
                  <ul>
                    <li>
                      <a href="#help-mp">The Marketplace</a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#help-lf"><h5>.find Features</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={6} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={6}>
                  <ul>
                    <li>
                      <a href="#help-lf">The Live Feed</a>
                    </li>
                    <li>
                      <a href="#help-forge">The Forge</a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                  <a href="#help-terms"><h5>Terms of Use</h5></a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={7} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={7}>
                  <ul>
                    <li>
                      <a href="#help-terms">Terms of Use</a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HelpHubSideNavMobile;
