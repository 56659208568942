import React, { useEffect, useRef, useState } from "react";
import { useInfiniteHits } from "react-instantsearch-hooks-web";
import { Col, Row } from "react-bootstrap";

import Loading from "../../../../../../components/loading/bl0xloading";

import cache from "./cache"
import SingleDoodle from "./SingleItem";

const options = { showPrevious: true, cache};

export default function Hits() {
  const { hits, isLastPage, showMore } = useInfiniteHits(options);
  const [loading, setLoading] = useState(false);
  const sentinelRef = useRef(null);
  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
            setLoading(true);
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <div className={"doodles__results"}>
      {hits.map((item) => {
        return <SingleDoodle {...item} />;
      })}

      <div ref={sentinelRef} />
      {!isLastPage && loading && (
        <Row className="my-5">
          <Col align="center">
            <Loading />
          </Col>
        </Row>
      )}
    </div>
  );
}
