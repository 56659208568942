import React from "react";
import TimeAgo from "react-timeago";
import "./CharityAuction.scss";
const CharityAuctionWallItem = ({ donation }) => {
  console.log(donation);
  return (
    <div id="charity-auction-wall-item">
      <div id="charity-auction-wall-item-desktop">
        <div id="wall-item-from-col">
          {donation.blockEventData.fromName ? (
            <span>
              <span className="themeColor">
                {donation.blockEventData.fromName}
              </span>
              .find
            </span>
          ) : (
            <span>{donation.blockEventData.from}</span>
          )}
        </div>
        <div id="wall-item-message-col">{donation.blockEventData.message}</div>
        <div id="wall-item-amount-col">
          {donation.blockEventData.amount}&nbsp;
          {donation.blockEventData.type.split(".")[2].replace("Token", "")}
        </div>
        <div id="wall-item-date-col">
          <TimeAgo
            date={new Date(donation.eventDate)}
            timestyle="twitter"
            suffix="none"
          />
        </div>
      </div>
      <div id="charity-auction-wall-item-mobile">
        <div id="wall-item-from-col">
          <div className="pb-1">
            {donation.blockEventData.fromName ? (
              <span>
                <span className="themeColor">
                  {donation.blockEventData.fromName}
                </span>
                .find
              </span>
            ) : (
              <span>{donation.blockEventData.from}</span>
            )}
          </div>
          <div id='wall-item-amount-col'>
            {" "}
            {donation.blockEventData.amount}&nbsp;
            {donation.blockEventData.type.split(".")[2].replace("Token", "")}
          </div>
        </div>
        <div id="wall-item-message-col">
          <div className="pb-1">{donation.blockEventData.message} </div>
          <div id="wall-item-date-col">
            {" "}
            <TimeAgo
              date={new Date(donation.eventDate)}
              timestyle="twitter"
              suffix="none"
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default CharityAuctionWallItem;
