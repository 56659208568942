import { Accordion, Button, Col, Row } from "react-bootstrap";
import { setShowFilters } from "../../../functions/filtersState";
import { useWalletType } from "../../../functions/useWalletType";
// import friendlicateCollection from "../../../functions/friendlicateCollection";
import CustomAttributes from "../CustomAtrributesMenu";
import { RangeSlider } from "../CustomRangeSlider";
import CustomRefinementList from "../CustomRefinementList";
import CustomSearchBox from "../CustomSearchBox";
import "./mpsidebar.css";
import MpSideBarFloat from "./mpsidebarfloat";
import MpSideBarWallet from "./mpsidebarwallet";

const MpSideBar = ({ floatState, setFloatState, showAll, setShowAll }) => {
  const handleShowFilters = () => {
    setShowFilters(false);
  };

  const wallet = useWalletType()
  console.log(wallet)

  // const transformItems = (items) => {
  //   // console.log(items)
  //   return items.map((item) => ({
  //     ...item,
  //     label: friendlicateCollection(item.label),
  //   }));
  // };

  function CustomToggle({ children, eventKey, lease, manage }) {
    // const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <Button
        onClick={handleShowFilters}
        className="flex-center fw-bold filterButton px-1"
        style={{
          backgroundColor: "var(--background-profile)",
          boxShadow: "none",
          border: "none",
          cursor: "pointer",
          color: "var(--text-primary)",
        }}
      >
        {" "}
        <i className="fa-solid fa-angle-left" />
        &nbsp;&nbsp;&nbsp; Filters{" "}
      </Button>
    );
  }

  return (
    <div id="mp-side-bar" className="px-1">
      <div className="filters-title pt-4 mb-4 px-0 flex-row">
        <CustomToggle />
      </div>

      <Row>
        <CustomSearchBox />
      </Row>
      {wallet !== "none" &&
      <Row>
        <Col className="pt-3">
        <MpSideBarWallet
            showAll={showAll}
            setShowAll={setShowAll} />
        </Col>
      </Row>}
      <Row className="">
        <Col className="pt-3">
          <MpSideBarFloat
            floatState={floatState}
            setFloatState={setFloatState}
          />
          {/* <Image
              className={!floatState ? 'floatClickedActive' : 'floatClicked'}
                onClick={() => setFloatState(!floatState)}
                src="/assets/img/marketplace/float_banner.webp"
                style={{ maxHeight: "1.5rem", cursor: "pointer" }}
              /> */}
        </Col>
      </Row>
      
      <Accordion className="px-0" alwaysOpen>
        <Accordion.Item className="mt-1" eventKey="collection">
          <Accordion.Header>
            <span className="smallTextSpan">Collection</span>
          </Accordion.Header>
          <Accordion.Body className="px-0">
            {/* <CustomCollectionRefinementList
              attribute={"collection_name"}
              sortBy={["collection_name:asc"]}
              limit={3}
              showMore
              showMoreLimit={50}
              searchable
              transformItems={transformItems}
              translations={{
                showMore(expanded) {
                  return expanded ? "Show less" : "Show more";
                },
              }}
            /> */}

            <CustomRefinementList
              attribute="collection_name"
              sortBy={["collection_name:asc"]}
              limit={3}
              showMore
              showMoreLimit={50}
            />

            {/* <CustomRefinementList

            /> */}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="sale-type">
          <Accordion.Header>
            <span className="smallTextSpan">Sale Type</span>
          </Accordion.Header>
          <Accordion.Body className="px-0">
            <CustomRefinementList
              attribute="listing_alias"
              sortBy={["listing_alias:asc"]}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="price">
          <Accordion.Header>
            <span className="smallTextSpan">Price</span>
          </Accordion.Header>
          <Accordion.Body className="px-0">
            <RangeSlider attribute="amount" />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="rarity">
          <Accordion.Header>
            <span className="smallTextSpan">Rarity</span>
          </Accordion.Header>
          <Accordion.Body className="px-0">
            <CustomRefinementList
              attribute={"nft_rarity"}
              sortBy={["nft_rarity:asc"]}
            />{" "}
          </Accordion.Body>
        </Accordion.Item>
        <CustomAttributes />
      </Accordion>
    </div>
  );
};

export default MpSideBar;
