import { Card, Col, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./collectionnftpfpselect.css";
import parseImgUrl from "../../functions/parseImgUrl";
import truncateItemName from "../../functions/truncateItemName";


export function CollectionNftPfpSelect({
  nftData,
  idkey,
  name,
  object,
  collection,
  link,
  pfp
}) {
  if (nftData) {
    let imgUrl = parseImgUrl(nftData.media, 'thumbnail');

    let url = "/";
    if (name) {
      url = url + name + "/collection/";
    } else {
      url = url + "me/collection/";
    }
    url = url + '/main/' + nftData.collection + "/";
    url = url + object.id;

    // console.log(nftData)

    return link !== "disabled" ? (
      <Card id={"card" + object.id} className={"profile-nft-pfp ps-2 pe-2 pt-2"}>
        <Link
          className="profile-nft-link"
          to={{
            pathname: url,
            state: { nftData: { nftData } },
          }}
        >
          {(nftData.mediaType.includes("video") || nftData.media.includes('.mp4')) ? (
            <video
              key={imgUrl}
              muted
              alt={"Picture of " + nftData.name}
              loop=""
              playsInline=""
              controls
              onMouseOver={(event) => event.target.play()}
              onMouseOut={(event) => event.target.pause()}
            >
              <source src={imgUrl} type="video/mp4"></source>
              Sorry this video is not supported by your browser
            </video>
          ) : (
            (nftData.mediaType === "" ||
              nftData.mediaType.includes("image")) && (
              <Image
              crossOrigin="anonymous"
                src={parseImgUrl(imgUrl, 'thumbnail') }
                key={imgUrl}
                alt={"Picture of " + nftData.name}
                color='var(--background-profile)'
                style={{objectFit: 'cover'}}
              />
            )
          )}
          {nftData.mediaType.includes("text") && (
            <div className="titletxt text-nft-wrapper">
              <div className="text-nft m-auto">{nftData.image}</div>
            </div>
          )}

          <Card.Text className="py-2">
            <Row className="fw-bold">
              <Col>
                {" "}
                {nftData.name
                  ? nftData.name
                  : nftData.collection + " #" + nftData.id}
              </Col>
            </Row>
            {nftData.listPrice &&
              "For sale: " + nftData.listPrice * 1 + " " + nftData?.listToken}

            <small style={{ color: "var(--text-secondary)" }}>
              {nftData.subCollection
                ? nftData.subCollection
                : nftData.collection}
            </small>
          </Card.Text>
        </Link>
      </Card>
    ) : (
      <Card id={"card" + object} className={"profile-nft-pfp ps-2 pe-2 pt-2"}>
        {nftData.mediaType.includes("video") ? (
          <video
          crossOrigin="anonymous"
            key={imgUrl}
            muted
            alt={"Picture of " + nftData.name}
            loop=""
            playsInline=""
            onMouseOver={(event) => event.target.play()}
            onMouseOut={(event) => event.target.pause()}
          >
            <source src={imgUrl + "#t=1.0"} type="video/mp4"></source>
            Sorry this video is not supported by your browser
          </video>
        ) : (
          (nftData.contentType === "" ||
            nftData.mediaType.includes("image")) && (
            <Card.Img
            crossOrigin="anonymous"
              src={imgUrl}
              key={imgUrl}
              alt={"Picture of " + nftData.name}
            />
          )
        )}
        {nftData.mediaType.includes("text") && (
          <div className="titletxt text-nft-wrapper">
            <div className="text-nft m-auto">{nftData.image}</div>
          </div>
        )}

        <Card.Text className="fw-bold py-2">
          {nftData.name && truncateItemName(nftData.name,20)}
          {!nftData.name && truncateItemName(collection,20)}
          {nftData.listPrice &&
            "For sale: " + nftData.listPrice * 1 + " " + nftData?.listToken}
        </Card.Text>
      </Card>
    );
  } else return null;
}
