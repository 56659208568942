import React, { useEffect, useState } from "react";
import DropPageBannerBio from "./DropPageBannerBio";
import DropPageHeader from "./DropPageHeader";
import DropPageMintBox from "./DropPageMintBox";
import DropPageInfo from "./DropPageInfo";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import { Script, Tx } from "../../functions/script";
import { useNavigate, useParams } from "react-router";
import { useUserProfile } from "../../functions/useUserProfile";

import "./drop-page.scss";

export default function DropPage(){
  const [packDetails, setPackDetails] = useState();
  const [numOfPacks, setNumOfPacks] = useState(1);
  let { colName, packId } = useParams();
  const user = useUserProfile();

  useEffect(() => {
    const packTypeName = colName.toLowerCase()
    if (user && user.loggedIn) {
      async function getFindPackSaleDetailsWithUser() {
        const res = await Script(scripts.getFindPackSaleDetailsWithUser, {
          packTypeName,
          packTypeId: parseInt(packId),
          user: user.addr,
        });
        setPackDetails(res);
      }
      try {
        getFindPackSaleDetailsWithUser();
      } catch (error) {
        console.log(error);
      }
    } else {
      async function getAllFindPackSaleDetails() {
        const res = await Script(scripts.getAllFindPackSaleDetails, {
          packTypeName
        });
        setPackDetails(res[packId]);
      }
      try {
        getAllFindPackSaleDetails();
      } catch (error) {
        console.log(error);
      }
    }
  }, [user, colName]);




  return (
    <div className="drop-page-wrapper">
      <DropPageHeader />
      <DropPageBannerBio packDetails={packDetails} />
      <DropPageMintBox
        packDetails={packDetails}
        numOfPacks={numOfPacks}
        setNumOfPacks={setNumOfPacks}
      />
      <DropPageInfo />
    </div>
  );
};
