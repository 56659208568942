import React from "react";
import { usePagination } from "react-instantsearch-hooks-web";
import Sort from "./Sort";

import "./action-bar.scss"

export default function Sorting(props) {
  const { toggle } = props;
  const { nbHits } = usePagination();

  return (
    <div className="doodles__sorting">
      <button className="doodles__sorting__toggle" onClick={toggle}>Toggle Filters</button>
      <p>Found: {nbHits} items </p>
      <Sort />
    </div>
  );
}
