import React from "react";
import { Link } from "react-router-dom";
import truncateAddress from "./truncateAddress";

const parseNameLink = (findUser) => {
  if (findUser) {
    // console.log(findUser);
    let findName;
    let address;
    let profileName;

    if (findUser.address){
        address = findUser.address
    }

    if (findUser.findName) {
      findName = findUser.findName;
    }

    if (findUser.name){
        profileName = findUser.profileName
    }

    if (findName) {
      return (
        <Link style={{ textDecoration: "none" }} to={"/" + findName}>
          <span className="fw-bold">
            <span className="themeColor">{findName}</span>.find
          </span>
        </Link>
      );
    } else if (profileName) {
      return (
        <Link to={"/" + address}>
          <span className="fw-bold">{profileName}</span>
        </Link>
      );
    } else {
      return (
        <Link to={"/" + address}>
          <span className="fw-bold">{truncateAddress(address)}</span>
        </Link>
      );
    }
  }
};

export default parseNameLink;
