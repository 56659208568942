import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import './profileheader.css'

const ProfileHeaderNoProfile = ({searchedAddress}) => {
  let headerBackgroundUrl = "/findbg.webp";
  let profilePic = "/assets/img/store/fnamestore.png"
  let profileName = searchedAddress
  // let defaultName = searchedAddress
  let profileDescription = 'No profile .found at this address.'

  // console.log(searchedAddress)

  return (
    <Container fluid={+true} id="profile-header" className="mb-3 px-0">
    <Row>
      <Col className="">
        <Card className="outer-wrapper">
          <div
            className="header-card-bg"
            style={{ backgroundImage: "url('" + headerBackgroundUrl + "')" }}
          ></div>
          <Card.Body>
            <Row className="justify-content-sm-center justify-content-lg-start">
              <Col
                className="ms-lg-2 mt-5 d-flex justify-content-center"
                xs="12"
                lg="auto"
              >
                <div className="profile-pic">
                  <Image src={profilePic} />
                </div>
              </Col>
              <Col lg='9'>
                <div className="header-name-padding d-none d-lg-block"></div>
                <Row className="align-items-center">
                  <Col className="d-flex justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                    <h3>{profileName}</h3>
                  </Col>
                  <Col align="right" className="d-none d-lg-block">
                    <i className="fas fa-share"></i>
                  </Col>
                </Row>  
                {/* {defaultName && (
                  <Row>
                    <Col className="d-flex justify-content-center justify-content-lg-start">
                      <p className="fw-bold">
                        <span className="themeColor">{defaultName}</span>.find
                      </p>
                    </Col>
                  </Row>
                )} */}
                <Row>
                  <Col className="justify-content-center text-center text-lg-start justify-content-lg-start">
                    <p>{profileDescription}</p>
                  </Col>
                </Row>
             
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  ) 
}

export default ProfileHeaderNoProfile