import { Card, Col, Container, Row } from "react-bootstrap";

export function ConnectWidget({ links }) {
  // console.log(links);
  return (
    <Container fluid={+true} id="connect-widget" className="mb-3 px-0">
      <Row>
        <Col>
          <Card className="pb-4 pt-2 px-2">
            <Card.Body>
              <Row className="mb-2">
                <Col>
                  <h4>Connect</h4>
                </Col>
              </Row>

              {Object.keys(links).length > 0 ? (
                <>
                  {Object.keys(links).map((e, index) =>
                    links[e].type === "globe" ? (
                      <Row key={index} className="mb-1">
                        <Col>
                          <span>
                            <a
                              href={links[e].url}
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <i className={"me-2 fa-solid fa-globe"}></i> {e}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    ) : (
                      <Row key={index} className="mb-1">
                        <Col>
                          <span>
                            <a
                              href={links[e].url}
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <i
                                className={
                                  "me-2 fa-brands fa-" +
                                  links[e].type.toLowerCase()
                                }
                              ></i>{" "}
                              {e}
                            </a>
                          </span>
                        </Col>
                      </Row>
                    )
                  )}{" "}
                </>
              ) : (
                <>
                  <Row>
                    <Col>
                      <small>No links... yet!</small>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
