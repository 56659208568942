import React from "react";
import { Card } from "react-bootstrap";
import LiveFeedEventItemThoughtTabThought from "../../livefeed/LiveFeedEventItemThoughtTabThought";

const ProfileThoughtsDataBox = ({ thoughtsData, profile }) => {
  return (
    <Card className="px-0 px-md-3">
      <>
        {thoughtsData && thoughtsData.length > 0 ? (
          thoughtsData.map((thought, i) => {
            return (
              <LiveFeedEventItemThoughtTabThought
                key={thought.id}
                thought={thought}
              />
            );
          })
        ) : thoughtsData && thoughtsData.length === 0 ? (
          <div id="live-feed-data-box-loading-wrapper">No thoughts posted</div>
        ) : (
          <div id="live-feed-data-box-loading-wrapper">
            Fetching data from chain...
          </div>
        )}
      </>
    </Card>
  );
};

export default ProfileThoughtsDataBox;
