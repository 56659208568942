import axios from "axios";
import React, { useEffect, useState } from "react";
import "./CharityAuction.scss";
import CharityAuctionWallItem from "./CharityAuctionWallItem";

const CharityAuctionWall = () => {
  const [donations, setDonations] = useState(null);
  const graffleUrl = process.env.REACT_APP_GRAFFLE_SEARCH_URL;

  console.log(donations);
  useEffect(() => {
    const getDonations = async () => {
      // console.log("getSales fired")
      let data;
      let res = await axios.get(
        graffleUrl +
          "search?eventType=A.097bafa4e0b48eef.FIND.FungibleTokenSent&tag=may4thcharity"
      );
      data = res.data;
      setDonations(data);
    };
    getDonations();
  }, []);
  return (
    <div id="charity-auction-wall-wrapper">
      <div id="charity-auction-wall-upper">
        <div id="charity-auction-wall-title">The Wall of Fame</div>
        <div id="charity-auction-wall-desc">
          Your name and message will appear here whenever you donate
        </div>
      </div>
      <div id="charity-auction-wall-inner">
        {donations ? (
          <>
            {donations.map((donation, i) => {
              return <CharityAuctionWallItem donation={donation} />;
            })}
          </>
        ) : (
          <>Fetching info from chain...</>
        )}
      </div>
      <div id="powered-by-graffle-row">
        Powered by Graffle
        <img src="/assets/img/home/graffle.webp" alt="Graffle Logo" />
      </div>
    </div>
  );
};

export default CharityAuctionWall;
