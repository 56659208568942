import {Image} from "react-bootstrap";
import React from "react";

export default function DapperSticker(props) {
	let { coin = "USD" } = props;
	return (
		<div className="sticker-box">
      <span className="sticker-span">
        <Image
					className="sticker-image"
					src="/assets/img/walletProviders/wallet-provider-dapper.svg"
				/>
        <span className="ft-span secColor">{coin}</span>
      </span>
		</div>
	);
}
