// Use prefixed number to sort groups during rendering
export const LIMITED = "0-Limited";
export const MASCOT = "1-Mascot";
export const SPORT = "3-Sport";
export const FOOTWARE = "4-Footware";
export const FACE = "5-Face";
export const CLOTHES = "6-Clothes";
export const ACCESSORY = "7-Accessory";
export const COMMON = "8-Common";

// prettier-ignore
const groups = {
	// Limited Event Items
	"beta pass": LIMITED,

	"satchel": ACCESSORY,

	// Sport Traits
	"athletic joggers": SPORT,

	// Footware Traits
	"crew socks": FOOTWARE,
	"show": FOOTWARE,
	"slides": FOOTWARE,

	// Mascot Traits
	"mascot's flower": MASCOT,
	"mascot's sweater": MASCOT,

	// The rest items are Common
}

export default {
  organizer: (item) => {
    const { label } = item;
    const groupValue = groups[label];

    switch (true) {
      // items inside groups
			case groupValue !== undefined:
        return groupValue;

      case label.toLowerCase().includes("athlet"):
      case label.toLowerCase().includes("sport"):
        return SPORT;

      case label.toLowerCase().includes("mascot"):
        return MASCOT;

      case label.toLowerCase().includes("shoe"):
      case label.toLowerCase().includes("slides"):
      case label.toLowerCase().includes("socks"):
      case label.toLowerCase().includes("loaf"):
        return FOOTWARE;

      case label.toLowerCase().includes("sweat"):
      case label.toLowerCase().includes("hoodie"):
      case label.toLowerCase().includes("trouser"):
      case label.toLowerCase().includes("puffer"):
        return CLOTHES;

			case label.toLowerCase().includes("satchel"):
				return CLOTHES;

      case label.toLowerCase().includes("head"):
      case label.toLowerCase().includes("mask"):
      case label.toLowerCase().includes("glasses"):
      case label.toLowerCase().includes("ear"):
        return FACE;

      default:
        return COMMON;
    }
  },
};
