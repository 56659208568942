import React from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { AuthCluster } from "../auth-cluster";
import { ConnectWidget } from "../components/profile/connectwidget/connectwidget";
import { ProfileHeader } from "../components/profile/profileheader/profileheader";
import EditProfileSettings from "../components/settings/editprofilesettings";
import { Helmet } from "react-helmet";

const Settings = ({ findUser, metaTitle, metaDescription }) => {
  //console.log(findUser)

  if (findUser === "first_init") {
    return (
      <Container className="py-5 my-5" align="center">
        <h2>Loading</h2>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        {/* <Row>{JSON.stringify(profileData, null, 2)}</Row> */}
      </Container>
    );
  }

  if (findUser) {
    return (
      <>
        <Container
          fluid={+true}
          style={{
            backgroundColor: "var(--background-profile)",
            minHeight: "calc(100vh - 218px)",
          }}
        >
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <Container className="content-container">
            <Row className="pt-4 justify-content-center">
              <Col xs="12" lg="9" xxl="10" className="px-0">
                <ProfileHeader profileData={findUser.profile} />
                <div className="d-block d-lg-none">
                  <ConnectWidget links={findUser?.profile?.links} />
                </div>
                <EditProfileSettings findUser={findUser.profile} />
              </Col>

              <Col className="d-none d-lg-block" xs="12" lg="3" xxl="2">
                <ConnectWidget links={findUser?.profile?.links} />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  } else if (findUser === "") {
    return (
      <Container fluid={+true} className="py-5 my-5" align="center">
        <h2 className="mb-5">Please Log in</h2>
        <AuthCluster user={findUser} />
      </Container>
    );
  }
};

export default Settings;
