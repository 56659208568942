import { Container, Spinner } from "react-bootstrap";

export default function Loading() {
  return (
    <Container
      style={{ minHeight: "200px" }}
      id="loading"
      className="py-5 my-5"
      align="center"
    >
      {/* <h2>Loading</h2> */}
      <Spinner
        animation="grow"
        role="status"
        style={{ color: "var(--theme-main)" }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      {/* <Row>{JSON.stringify(profileData, null, 2)}</Row> */}
    </Container>
  );
}
