import React, {  useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { InstantSearch } from "react-instantsearch-hooks-web";
import { Autocomplete } from "../../search/autocomplete/Autocomplete";
import searchClient from "../../../functions/searchClient";

import "./search-home.css";

// Class Names
const classContainer = "search-home d-flex align-items-center justify-content-center p-3 p-lg-0"
const classCopyBig = "mt-2 mt-md-2 m-auto fw-bold d-none d-md-block"
const classCopyMobile = "mt-3 mt-md-3 mb-4 m-auto fw-bold d-block d-md-none"
const classArrow = "fa-solid fa-angle-down p-3 pt-5 display-5 themeColor"

export default function SearchHome() {
  useEffect(() => {
    document.body.classList.remove("main-nav");
  }, []);

  return (
    <Container
      id="search-home"
      className={classContainer}
    >
      <div className="search-wrapper form-inputs">
        <Row>
          <Col>
            <Row>
              <Col align="center">
                <h1>
                  .find - a place for your <br /> collections on Flow
                </h1>
                <p
                  className={classCopyBig}
                  style={{ color: "var(--text-secondary)" }}
                >
                  Buy, sell, mint and showcase NFTs on the Flow blockchain with
                  <br />a named wallet and profile that brings an identity to
                  your crypto life.
                </p>
                <p
                  className={classCopyMobile}
                  style={{ color: "var(--text-secondary)" }}
                >
                  Buy, sell, mint and showcase NFTs <br /> on the Flow blockchain with 
                  a <br /> named wallet and profile that <br />brings an identity to
                  your crypto life.
                </p>
              </Col>
            </Row>
            <Row
              className="mb-md-4 mt-md-4 mb-3 justify-content-center"
              xs={1}
              md={1}
            >
              <Col className="mb-2">
                <InstantSearch
                  indexName="market"
                  searchClient={searchClient}
                >
                  <Autocomplete
                    searchClient={searchClient}
                    placeholder=".find people and things"
                    openOnFocus
                  />
                </InstantSearch>
              </Col>
            </Row>
            <Row>
              <Col align="center"></Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="search-scroll-more">
        <a href="#info-cards">
          <i className={classArrow}></i>
        </a>
      </div>

    </Container>
  );
}
