import { Form } from "react-bootstrap";
import useSound from "use-sound";
import { splitAndCap } from "../../../../../utils";
import click from "./click.mp3";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";

export default function RefineItem(props) {
  const currentRefinements = useCurrentRefinements();
  const playbackRate = 0.9 + currentRefinements.items.length * 0.25;
  const options = { volume: 0.35, playbackRate };
  const [play] = useSound(click, options);
  const { item, onClick } = props;
  const { label, value, count, isRefined: checked } = item;
  const key = `template-${label}`;
  const tagName = splitAndCap(label, /[\s-]/);

  if (count === 0) {
    return null;
  }

  let title = `Total: ${count} ${tagName} Wearables`;

  return (
    <div className="filter__checkbox-row">
      <Form.Check bsPrefix={"filter-checkbox"}>
        <Form.Check.Input
          key={key}
          id={key}
          type="checkbox"
          value={value}
          checked={checked}
          onClick={(e) => {
            play();
            onClick(e);
          }}
        />
        <Form.Check.Label for={key}>{tagName}</Form.Check.Label>
      </Form.Check>
      <span title={title}>{count > 99 ? "99+" : count}</span>
    </div>
  );
}
