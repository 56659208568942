import { Link } from "react-router-dom";

export default function Crumb(props) {
  const { key, last, label } = props;
  const linkLabel = label.replace("FIND", ".find").replaceAll("%20", " ");

  let link = (
    <Link className="breadcrumbs-link" to={"/"}>
      {linkLabel}
    </Link>
  );

  return (
    <span key={key}>
      {last ? label : link}
    </span>
  );
}
