import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Configure, InstantSearch } from "react-instantsearch-hooks-web";
import CharityAuctionWrapper from "../components/CharityAuction/CharityAuctionWrapper";
import { Helmet } from "react-helmet";
import searchClient from "../functions/searchClient";

const CharityAuction = () => {
  const [indexName, setIndexName] = useState("market");
  // const { colName } = useParams();

  const sellerAddress = "0xa3127d4e2030b125";

  return (
    <Container
      fluid={+true}
      className="pb-5"
      style={{
        backgroundColor: "var(--background-profile)",
        minHeight: "calc(100vh - 218px)",
      }}
    >
      <Helmet>
        <title>
          Flow and Friends Fantasy Football League for Charity - Auction Event /
          .find
        </title>
        <meta
          name="description"
          content="Flow and Friends Fantasy Football League for Charity - Auction Event"
        />
      </Helmet>
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure
          hitsPerPage={18}
          facetFilters={[
            "seller:" + sellerAddress,
            // "listing_alias:-DirectOffer",
          ]}
        />

        <CharityAuctionWrapper />
      </InstantSearch>
    </Container>
  );
};

export default CharityAuction;
