import { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useFormStatus } from "../../../functions/DisabledState";
import StoreBidsOnNames from "../storebids/storebidsonnames/storebidsonnames";
import StoreBidsOnNfts from "../storebids/storebidsonnfts/storebidsonnfts";
import StoreBuyingNames from "../storebuying/storebuyingnames/storebuyingnames";
import StoreBuyingNfts from "../storebuying/storebuyingnfts/storebuyingnfts";
import StoreBuyingWon from "../storebuying/storebuyingwon/storebuyingwon";
// import StoreBidHistory from "../storehistory/storebidhistory";
import StoreNamesListings from "../storelistings/storenameslistings/storenameslistings";
import StoreNftListings from "../storelistings/storenftlistings/storenftlistings";
import StoreSold from "../storesold/storesold";
import StoreWatchlist from "../storewatchlist/storewatchlist";
import "./storetabs.css";

const StoreTabs = ({ findUser, type, display, tab, filterTerm }) => {
  // //console.log(findUser);
  const [key, setKey] = useState("store");
  const navigate = useNavigate();
  let { tabs } = useParams();

  // //console.log(findUser.leases)

  function handleChangeTab(k) {
    navigate("/me/store/" + k);
  }

  useEffect(() => {
    if (tab) {
      setKey(tab);
    }
    if (tabs) {
      setKey(tabs);
    }
    if (!tab && !tabs) {
      setKey("store");
    }
  }, [tab, tabs]);
  // //console.log(findUser)

  return (
    <>
      <Container fluid={+true} id="store-tabs" className="mb-3 px-0">
        <Row className="pb-4 mb-4">
          <Col className="">
            <Tabs
              className="storetabs mt-4 mb-4"
              id="store-tabs"
              activeKey={key}
              onSelect={(k) => handleChangeTab(k)}
            >
              <Tab
                title="Listings"
                eventKey="listings"
                mountOnEnter
                unmountOnExit
              >
                <fieldset disabled={useFormStatus()}>
                  <StoreNamesListings leases={findUser.leases} filterTerm={filterTerm}/>
                  <StoreNftListings items={findUser.itemsForSale} filterTerm={filterTerm} />
                </fieldset>
              </Tab>
              <Tab
                title="Bids/offers"
                eventKey="bids"
                mountOnEnter
                unmountOnExit
              >
                <fieldset disabled={useFormStatus()}>
                  <StoreBidsOnNames leases={findUser.leases} filterTerm={filterTerm}/>
                  <StoreBidsOnNfts bids={findUser.itemsForSale} filterTerm={filterTerm}/>
                </fieldset>
              </Tab>
              <Tab title="Sold" eventKey="sold" mountOnEnter unmountOnExit>
                <fieldset disabled={useFormStatus()}>
                  <StoreSold filterTerm={filterTerm}/>
                </fieldset>
              </Tab>
              <Tab title="Buying" eventKey="buying" mountOnEnter unmountOnExit>
                <fieldset disabled={useFormStatus()}>
                  <StoreBuyingWon findUser={findUser} filterTerm={filterTerm}/>
                  <StoreBuyingNames bids={findUser.bids} filterTerm={filterTerm}/>
                  <StoreBuyingNfts bids={findUser.marketBids} filterTerm={filterTerm}/>
                </fieldset>
              </Tab>
              <Tab
                title="Watchlist"
                eventKey="watchlist"
                mountOnEnter
                unmountOnExit
              >
                <fieldset disabled={useFormStatus()}>
                  <StoreWatchlist findUser={findUser} filterTerm={filterTerm}/>
                </fieldset>
              </Tab>
              {/* <Tab
                title="History"
                eventKey="history"
                mountOnEnter
                unmountOnExit
              >
                <StoreBidHistory filterTerm={filterTerm}/>
              </Tab> */}
            </Tabs>
          </Col>
        </Row>
      </Container>
      {/* {JSON.stringify(findUser.leases)} */}
    </>
  );
};

export default StoreTabs;
