import React from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import "./mpsidebar.css";

const MpSideBarFloat = ({ floatState, setFloatState, mobile }) => {
  return (
    <Row>
      <Col>
        <div id={mobile ? 'mp-side-bar-float-mobile' : 'mp-side-bar-float'}>
          <Image
          className="mx-2"
            style={{    height: '25px',
              width: '25px' }}
            src="/assets/img/marketplace/float-square.webp"
          />
          <span className="include-float-span" style={{}}>
            {" "}
            Include FLOATs{" "}
          </span>
          <Form className="float-switch">
            <Form.Check
            className="form-switch-toggle"
              onChange={() => setFloatState(!floatState)}
              checked={!floatState}
              type="switch"
              id="custom-switch"
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default MpSideBarFloat;
