import React, { useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import { useWalletType } from "../../../../../functions/useWalletType";
import ColDetailMakeOfferModal from "./ColDetailMakeOfferModal";

const ColDetailMakeOffer = ({ listingStatus, saleDetails, themeStatus }) => {
  const [showOffer, setShowOffer] = useState(false);
  const handleCloseOffer = () => {
    setShowOffer(false);
  };
  const handleShowOffer = () => {
    setShowOffer(true);
  };
  const walletType = useWalletType();

  // const renderTooltipComingSoon = (props) => (
  //   <Tooltip {...props}>
  //     <span style={{ fontSize: "16px" }}>Coming soon</span>
  //   </Tooltip>
  // );
  const disabledStyle = { opacity: "0.65" };
  // console.log(saleDetails);
  const renderTooltipComingSoon = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Coming soon</span>
    </Tooltip>
  );

  //mobile swipedown close

  const config = {
    delta: 150, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true,
    swipeDuration: 250,
  };
  const handlers = useSwipeable({
    onSwipedDown: () => {
      // console.log('this')
      handleCloseOffer();
    },
    ...config,
  });

  return (
    <>
      {saleDetails &&
      (saleDetails.ftAlias === "DUC" || saleDetails.ftAlias === "FUT") ? (
        <OverlayTrigger placement="top" overlay={renderTooltipComingSoon}>
          <Button
            // disabled={true}
            style={disabledStyle}
            // onClick={handleShowOffer}
            className="w-100"
            variant="find-dark"
          >
            <small>Offer</small>
          </Button>
        </OverlayTrigger>
      ) : (
        <Button onClick={handleShowOffer} className="w-100" variant="find-dark">
          <small>Offer</small>
        </Button>
      )}

      <Modal
        data-theme={themeStatus}
        show={showOffer}
        onHide={handleCloseOffer}
        centered
      >
        <div {...handlers}>
          <Modal.Header
            style={{ border: "none" }}
            closeButton
            closeLabel="close window"
            //theme causing rerenders
            // data-theme={() => UseThemeStatus()}
          >
            <Modal.Title className="text-center w-100">
              <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
                Make an offer for {listingStatus.itemName}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ColDetailMakeOfferModal
              listingStatus={listingStatus}
              themeStatus={themeStatus}
              saleDetails={saleDetails}
            />
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ColDetailMakeOffer;
