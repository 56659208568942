import { useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useFormStatus } from "../../../functions/DisabledState";
import "./fundwidgetownprofile.css";
import { handleSendFungibleTo } from "../../../functions/flow-interactions/transactions";

export function FundWidgetOwnProfile({ profile }) {
  //   //console.log(fundForm.fundReceiver);

  const [fundForm, setFundForm] = useState({
    name: "",
    amount: "",
    ftAlias: "Flow",
    tag: "gift",
    message: "",
  });

  const updateForm = (e) => {
    setFundForm({
      ...fundForm,
      [e.target.name]: e.target.value,
    });
    // //console.log(e.target);
    // //console.log(fundForm);
  };

  const parsedFundReceiver = fundForm.name
    .replace(/\b(.find)\b/i, "")
    .replace(/[^a-z0-9-]/g, "")
    .toLowerCase();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendFungibleTo(parsedFundReceiver, fundForm);
  };

  const renderTooltipFundWarning = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>
        Please double check the fund receiver as funds sent to wrong address are
        non-recoverable!
      </span>
    </Tooltip>
  );

  return (
    <Container fluid={+true} id="fund-widget" className="mb-3 px-0 mx-0">
      <fieldset onSubmit={handleSubmit} disabled={useFormStatus()}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="px-3">
              <h4 style={{ marginBottom: "0px" }}>.fund somebody</h4>
            </Accordion.Header>
            <Accordion.Body>
              <div id="fund-widget-warning-icon-row">
                <OverlayTrigger
                  placement="left"
                  overlay={renderTooltipFundWarning}
                >
                  <i className="fa-solid fa-circle-info" />
                </OverlayTrigger>
              </div>
              <Form className="form-inputs">
                <Row xs="1">
                  <Col className="mb-3">
                    <span className="propertiesWrap">
                      Choose somebody to fund, a token type and an amount. Leave
                      a message if you'd like and press send. The fundee will
                      receive the funds shortly after.
                    </span>
                  </Col>
                  <Col className="mb-3">
                    <Form.Control
                      onChange={updateForm}
                      name="name"
                      type="text"
                      placeholder="Who would you like to fund?"
                      autoComplete="off"
                      required
                    />
                  </Col>
                  <Col className="mb-3">
                    <Form.Select name="ftAlias" onChange={updateForm}>
                      <option>Flow</option>
                      <option>FUSD</option>
                    </Form.Select>
                  </Col>
                  <Col className="mb-3">
                    <Form.Control
                      onChange={updateForm}
                      min="0.01"
                      name="amount"
                      type="number"
                      placeholder="Amount e.g. 100"
                      step=".01"
                      required
                    />
                  </Col>
                  <Col className="mb-3">
                    <Form.Control
                      name="message"
                      onChange={updateForm}
                      as="textarea"
                      placeholder="Optionally enter a message (255 chars max)"
                      maxLength="255"
                      rows={3}
                    />
                  </Col>
                  <Col>
                    <Button type="submit" variant="find" className="w-100">
                      Send
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </fieldset>
    </Container>
  );
}
