import React, { useEffect, useState } from "react";
import parseImgUrl, { parseIPFSLink } from "../../functions/parseImgUrl";
import Loading from "../loading/loading";
import LoadingThumb from "../loading/LoadingThumb";

const CustomImageIcon = ({ imgUrl, isName }) => {
  const [iconImgUrl, setIconImgUrl] = useState(null);

  useEffect(() => {
    if (imgUrl) {
      if (isName) {
        setIconImgUrl(imgUrl);
      } else {
        setIconImgUrl(parseImgUrl(imgUrl, "icon"));
      }
    }
  }, [imgUrl]);

  return (
      <img
        crossOrigin="anonymous"
        // alt={`Picture of ${imgUrl}`}
        src={iconImgUrl}
      />
  );
};

export default CustomImageIcon;
