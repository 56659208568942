import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import {
  handleCancelAuction,
  handleFullfillAuction,
} from "../../../../../functions/flow-interactions/transactions";

const ReviewOwnAuction = ({ filteredLease }) => {
  // //console.log(filteredLease);
  let ended;
  if (filteredLease.auctionEnds && filteredLease.auctionEnds < filteredLease.currentTime){
    ended = true;
  } else {
    ended = false;
  }

  return (
    <Container className="commerceinfo p-3">
      <Row>
        <Col>
          <p className="fw-bold">
            Auction Details for {filteredLease.name}.find
          </p>
          <div>
            <small>
              Starting Price: &nbsp;
              <b>
                {parseFloat(filteredLease.auctionStartPrice).toFixed(2)} FUSD
              </b>
            </small>
          </div>
          <div>
            <small>
              {ended && filteredLease.latestBid >= filteredLease.auctionReservePrice
                ? "Winning Bid"
                : "Current Bid"}
              : &nbsp;

              {!filteredLease.latestBid ? (
                <b>
                  {parseFloat(filteredLease.auctionStartPrice).toFixed(2)} FUSD
                </b>
              ) : (
                <b>{parseFloat(filteredLease.latestBid).toFixed(2)} FUSD</b>
              )}
            </small>
          </div>
          <div>
            <small>
              Reserve Price: &nbsp;
              <b>
                {" "}
                {parseFloat(filteredLease.auctionReservePrice).toFixed(2)} FUSD
              </b>
            </small>
          </div>
          <div>
            <small>
              Auction{" "}
              {!ended
                ? "Ends"
                : "Ended"}
              : &nbsp;
              {filteredLease.auctionEnds ? (
                <b>
                  <ReactTimeago
                    date={new Date(filteredLease.auctionEnds * 1000)}
                    timeStyle="twitter"
                  />
                </b>
              ) : (
                <b>Timer begins on first bid</b>
              )}
            </small>
          </div>

          <div>
            <small>
              Highest Bidder: &nbsp;
              {filteredLease.auctionEnds ? (
                <b>{filteredLease.latestBidBy}</b>
              ) : (
                <b>No bids yet</b>
              )}
            </small>
          </div>
        </Col>
      </Row>
      <Row>
        {filteredLease.currentTime > filteredLease.auctionEnds &&
        filteredLease.latestBidBy ? (
          <Col align="right">
            {" "}
            <Button
              onClick={() =>
                handleFullfillAuction(filteredLease.name, filteredLease.address)
              }
              variant="find-outline-commerce"
            >
              Fulfill Auction
            </Button>
          </Col>
        ) : (
          <Col className="mt-2" align="right">
            {" "}
            <Button
              variant="find-outline-commerce-red"
              onClick={() => handleCancelAuction(filteredLease.name)}
              size="sm"
            >
              Remove listing
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ReviewOwnAuction;
