import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  getMarketplaceNFTLikes,
  getNFTViewCount,
  handleNFTFavClicked,
  handleNFTLikeClicked,
  handleNFTViewIncrement,
  hasUserFavNFT,
  hasUserLikedNFT,
} from "../../../../../firebaseConfig";
import { UseThemeStatus } from "../../../../../functions/themeMode";

const ColDetailLikesViews = ({ listingStatus }) => {
  const [likes, setLikes] = useState(0);
  const [isNFTLikedByUser, setIsNFTLikedByUser] = useState(false);
  const [isNFTFavByUser, setIsNFTFavByUser] = useState(false);
  const [viewCount, setViewCount] = useState(null);

  const user = listingStatus.user;

  const themeStatus = UseThemeStatus();

  async function getLikes(collectionName, nftType, nftId, user) {
    const likes = await getMarketplaceNFTLikes(collectionName, nftType, nftId);
    const isLiked = await hasUserLikedNFT(collectionName, nftType, nftId, user);
    setLikes(likes);
    setIsNFTLikedByUser(isLiked);
  }
  async function getViews(collectionName, nftType, nftId) {
    const views = await getNFTViewCount(collectionName, nftType, nftId);
    setViewCount(views);
  }

  async function getFavs(collectionName, nftId, user) {
    const isFav = await hasUserFavNFT(collectionName, nftId, user);
    setIsNFTFavByUser(isFav);
  }

  useEffect(() => {
    if (listingStatus.itemMetaData) {
      handleNFTViewIncrement(
        listingStatus.itemCollection,
        listingStatus.itemType,
        listingStatus.itemId,
        listingStatus.itemName
      );
    }
    getViews(
      listingStatus.itemCollection,
      listingStatus.itemType,
      listingStatus.itemId,
      user
    );
    getLikes(
      listingStatus.itemCollection,
      listingStatus.itemType,
      listingStatus.itemId,
      user
    );
    getFavs(listingStatus.itemCollection, listingStatus.itemId, user);
  }, [user, listingStatus.itemMetaData]);

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    )
  );

  const renderTooltipLikes = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Likes</span>
    </Tooltip>
  );
  const renderTooltipFavs = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Add to fav list</span>
    </Tooltip>
  );
  const renderTooltipViews = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Total views</span>
    </Tooltip>
  );

  return (
    <Row className="align-items-center">
      <OverlayTrigger placement="top" overlay={renderTooltipFavs}>
        <Col xs="auto">
          {/* favs */}

          {user && user.loggedIn ? (
            <div className="flex-row likes-icon-box">
              <Image
                src={
                  themeStatus === "light" && !isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light.svg"
                    : themeStatus === "light" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-light-fill.svg"
                    : themeStatus === "dark" && isNFTFavByUser
                    ? "/assets/img/marketplace/heart-dark-fill.svg"
                    : "/assets/img/marketplace/heart-dark.svg"
                }
                onClick={async () => {
                  await handleNFTFavClicked(
                    listingStatus.itemCollection,
                    listingStatus.itemType,
                    listingStatus.itemId,
                    listingStatus.itemName,
                    listingStatus.itemThumbnail,
                    listingStatus.owner.ownerProfile.address,
                    user
                  );
                  getFavs(
                    listingStatus.itemCollection,
                    listingStatus.itemId,
                    user
                  );
                }}
                alt=""
                style={{ width: "25px", cursor: "pointer" }}
              />
              &nbsp;&nbsp;
            </div>
          ) : (
            <div className="flex-row likes-icon-box">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  id="favs-dropdown-toggle"
                >
                  <Image
                    src={
                      themeStatus === "light"
                        ? "/assets/img/marketplace/heart-light.svg"
                        : "/assets/img/marketplace/heart-dark.svg"
                    }
                    alt=""
                    style={{ width: "25px", cursor: "pointer" }}
                  />
                </Dropdown.Toggle>{" "}
                <Dropdown.Menu>
                  <Dropdown.Item
                    target="_blank"
                    className="secColor small"
                    rel="noreferrer"
                  >
                    Please log in to fav
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              &nbsp;&nbsp;
            </div>
          )}
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={renderTooltipLikes}>
        <Col xs="auto">
          {/* likes */}
          {user && user.loggedIn ? (
            <div className="flex-row likes-icon-box">
              <Image
                src={
                  themeStatus === "light" && !isNFTLikedByUser
                    ? "/assets/img/marketplace/thumbs-up-light.svg"
                    : themeStatus === "light" && isNFTLikedByUser
                    ? "/assets/img/marketplace/thumbs-up-light-fill.svg"
                    : themeStatus === "dark" && isNFTLikedByUser
                    ? "/assets/img/marketplace/thumbs-up-dark-fill.svg"
                    : "/assets/img/marketplace/thumbs-up-dark.svg"
                }
                onClick={async () => {
                  await handleNFTLikeClicked(
                    listingStatus.itemCollection,
                    listingStatus.itemType,
                    Number(listingStatus.itemId),
                    listingStatus.itemName,
                    user
                  );
                  getLikes(
                    listingStatus.itemCollection,
                    listingStatus.itemType,
                    listingStatus.itemId,
                    user
                  );
                }}
                alt=""
                style={{ width: "25px", cursor: "pointer" }}
              />
              &nbsp;&nbsp;
              <span className="propertiesWrap">{likes}</span>
            </div>
          ) : (
            <div className="flex-row likes-icon-box">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  id="likes-dropdown-toggle"
                >
                  <Image
                    src={
                      themeStatus === "light"
                        ? "/assets/img/marketplace/thumbs-up-light.svg"
                        : "/assets/img/marketplace/thumbs-up-dark.svg"
                    }
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    target="_blank"
                    className="secColor small"
                    rel="noreferrer"
                  >
                    Please log in to like
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              &nbsp;&nbsp;
              <span className="propertiesWrap">{likes}</span>
            </div>
          )}
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={renderTooltipViews}>
        <Col xs="auto">
          <div
            className="flex-row likes-icon-box"
            style={{ marginLeft: "10px" }}
          >
            <Image
              src={
                themeStatus === "light"
                  ? "/assets/img/marketplace/views-icon-light.svg"
                  : "/assets/img/marketplace/views-icon-dark.svg"
              }
              alt=""
              style={{ width: "30px" }}
            />
            &nbsp;&nbsp;
            <span className="propertiesWrap">{viewCount}</span>
          </div>
        </Col>
      </OverlayTrigger>

      {/* <Col xs="auto">
        <div>
          {isNFTFavByUser ? (
            <StarFill
              style={{ width: "30px", marginRight: "10px", cursor: "pointer" }}
              onClick={async () => {
                await handleNFTFavClicked(hit, user);
                getFavs(hit);
              }}
            />
          ) : (
            <Star
              style={{ width: "30px", marginRight: "10px", cursor: "pointer" }}
              onClick={async () => {
                await handleNFTFavClicked(hit, user);
                getFavs(hit);
              }}
            />
          )}
          Add to favorites
        </div>
      </Col> */}
    </Row>
  );
};

export default ColDetailLikesViews;
