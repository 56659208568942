export const isObject = val => {
  const isArray = Array.isArray(val)
  return val instanceof Object && !isArray
}

export const hashCode = (code) => {
  // We need to remove empty strings to prevent handling of pretty much duplicate
  // Cadence code
  const str = code
    .split("\n")
    .filter((item) => !item.match(/^\s*$/g))
    .map((item) => (item[0] === "\t" ? item.slice(1) : item))
    .join("\n");

  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  const padded = hash.toString();
  return btoa(padded).padStart(32, "_");
};

export const isFeeEvent = (event) => {
  // Fees doesn't contribute any valuable information about transfer to the user, so we can omit them
  const feeDeducted = event.id === "A.f919ee77447b7497.FlowFees.FeesDeducted";

  const smallTransfer = parseFloat(event.args.amount) < 0.0001;

  const feeDeposited =
    event.id === "A.1654653399040a61.FlowToken.TokensDeposited" &&
    event.args.to === "0xf919ee77447b7497";

  const tempStorageFee =
    event.id === "A.1654653399040a61.FlowToken.TokensDeposited" &&
    event.args.to === "0xa3db411f3801ae75";

  const feeWithdrawn =
    event.id === "A.1654653399040a61.FlowToken.TokensWithdrawn" &&
    event.args.amount < 0.0001;

  return (
    feeDeducted ||
    feeDeposited ||
    feeWithdrawn ||
    tempStorageFee ||
    smallTransfer
  );
};

export const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

export const formatTime = (timestamp) => {
  const t = new Date(timestamp);

  const time = t.toLocaleTimeString();
  const date = t.toLocaleDateString();
  const asString = `${date} - ${time}`;

  return {
    asString,
    time,
    date,
  };
};

// We assume that maximum royalty is around 15%, so we make it 16% to exclude rounding errors.
// You can still pass the value to fine tune to specific needs
export const splitPayments = (events, fullPrice, royaltySize = 0.16) => {
  let price = fullPrice === "string" ? parseFloat(fullPrice) : fullPrice;

  return events.reduce(
    (acc, event) => {
      const { to, amount } = event.args;
      const eventPrice = parseFloat(amount);

      // This one is probably royalty
      if (eventPrice <= price * royaltySize) {
        acc.royalties.push({
          to,
          amount: eventPrice,
        });
      }

      // This one is main purchase transfer
      if (eventPrice >= price * (1 - royaltySize)) {
        acc.purchase = {
          to,
          amount: eventPrice,
        };
      }

      return acc;
    },
    { royalties: [], purchase: -1 }
  );
};

export const shortAddress = (address) => {
  if (!address) {
    return "???";
  }
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const getCollectionFromEventId = (id) =>
  id.split(".").slice(0, -1).join(".");

export const getEvents = (props, eventName) => {
  const { transfer, context } = props;
  const { collection } = context;
  return transfer.events.byId[`${collection}.${eventName}`];
};
export const getEvent = (props, eventName) => {
  return getEvents(props, eventName)[0];
};
