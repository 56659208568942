// This is a list of known paths we can resolve right away
const pathMap = {
	"/lf": "Livefeed",
	"/p/explore": "Explore",
	"/p/helphub": "HelpHub",
	"/p/contact": "Contact",
	"/p/testnethub": "Testnet Hub",
	"/p/privacy": "Privacy",
	"/p/terms": "Terms",
};

export function getCurrentPage(pathname = "") {
	const url = `https://find.xyz/${pathname}`;
	let link = ""
	let crumbs = [];

	let title = pathMap[pathname];

	if (pathname.includes("/mp")) {
		title = "Marketplace";
		link = "/mp"

		if (pathname.length > 3) {
			crumbs = pathname
				.slice(3) // slice "/mp" at the start
				.split("/")
				.filter((item) => item !== "");
		}
	} else {
		// TODO: Change the logic here
		title = title?.replace("/", "");
	}

	return { title, url, crumbs, link };
}
