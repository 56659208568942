import * as tx from "../../const";

const platform = "Find";
const eventTypes = {
  findAirdropped: "A.097bafa4e0b48eef.FindAirdropper.Airdropped",
};

export default function FindPlugin(context, transfer, idRelated) {
  const { events } = transfer;
  const airdropEvent = events.byId[eventTypes.findAirdropped];

  if (airdropEvent) {
    const { args } = airdropEvent[0];
    const { to } = args;
    return {
      type: tx.AIRDROPPED,
      catcher: to,
      platform,
    };
  }

  return null;
}
