import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import parseImgUrl from "../../../../functions/parseImgUrl";
import { UseThemeStatus } from "../../../../functions/themeMode";
import { useLatestEvent } from "../../../../functions/LatestEvent";
import { openFindPack } from "../../../../functions/flow-interactions/transactions";
import { changeState } from "../../../../functions/DisabledState";

import "./findpackmodal.scss";

const Title = (props) => {
  const { text } = props;
  const className = "themeColor text-center mb-1";

  return (
    <h3 id="pack-open-title" className={className}>
      {text}
    </h3>
  );
};
const Subtitle = (props) => {
  const { show = true, text } = props;
  if (!show) return null;
  const className = "themeColor text-center w-100 d-block";
  return (
    <span id="pack-open-id" className={className}>
      {text}
    </span>
  );
};

const PackOpening = React.memo((props) => {
  const { show = true } = props;
  if (!show) {
    return null;
  }

  // const clipURL = parseImgUrl("ipfs://QmdcRhoRvAUcbbDd2wnjJoVSeqrwJKNHiFiwRHy2iApjEv")
  const clipURL = `/assets/clips/genexp-pack-open.mp4`;

  return (
    <Col className="flex-center m-2">
      <video
        preload="auto"
        loop={true}
        autoPlay={true}
        id="pack-img-loading"
        muted={true}
        crossOrigin="anonymous"
      >
        <source src={clipURL} type="video/mp4"></source>
      </video>
    </Col>
  );
});

const PackThumbnail = (props) => {
  const { show = true, thumbnail } = props;
  if (!show) {
    return null;
  }

  return <img src={thumbnail} alt={"Pack Image"} className="pack-thumbnail" />;
};

const Controls = (props) => {
  const { open, goBack } = props;
  return (
    <Col>
      <Button
        onClick={open}
        className="w-100 mb-2"
        variant="find-nochange-dark"
      >
        Open pack
      </Button>

      <Button onClick={goBack} className="w-100" variant="find-dark">
        Go back
      </Button>
    </Col>
  );
};
function FindPackModal(props) {
  // Props
  const { packId, thumbnail } = props;
  const { onHide } = props;

  // Hooks
  const themeStatus = UseThemeStatus();
  const latestEvent = useLatestEvent();
  const [currentStep, setCurrentStep] = useState("preview");

  const eventFrame = useRef({
    inProgress: false,
    packOpened: false,
    rewards: [],
    processed: {},
  });
  useEffect(() => {
    if (latestEvent) {
      const { blockEventData, flowEventId } = latestEvent;
      const { rewardFields, rewardId } = blockEventData;
      const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

      if (blockEventData.packId !== packId) {
        return;
      }

      if (flowEventId === `A.${contractAddress}.FindPack.Opened`) {
        setCurrentStep("progress");
        eventFrame.current.inProgress = true;
      }

      // read rewards
      if (flowEventId === `A.${contractAddress}.FindPack.PackReveal`) {
        const image = rewardFields.nftImage;
        const name = rewardFields.nftName;
        const collectionName = rewardFields?.path.slice(1).split("/")[1] || "";

        if (!eventFrame.current.processed[rewardId]) {
          eventFrame.current.rewards.push({
            image,
            name,
            collectionName,
          });

          eventFrame.current.processed[rewardId] = true;
        }
      }

      if (flowEventId === `A.${contractAddress}.FindPack.Fulfilled`) {
        // set frame end, show rewards!
        if (eventFrame.current.inProgress) {
          eventFrame.current.inProgress = false;
          eventFrame.current.packOpened = true;
          setCurrentStep("rewards");
        }
      }
    }
  }, [latestEvent]);

  // Utilities
  const reset = useCallback(() => {
    const rewardsReceived = eventFrame.current.packOpened;
    setCurrentStep("preview");
    onHide(rewardsReceived);
  }, []);

  const Preview = () => {
    const open = () => {
      setCurrentStep("progress");
      openFindPack(packId, {
        onError: () => {
          setCurrentStep("preview");
        },
      });
    };

    return (
      <>
        <Title text={"Open Pack"} />
        <PackThumbnail thumbnail={thumbnail} />
        <Controls open={open} goBack={reset} />
      </>
    );
  };

  const Progress = React.memo(() => {
    return (
      <>
        <Title text={"Opening pack..."} />
        <Subtitle text={"Nearly there, hold on!"} />
        <PackOpening />
      </>
    );
  });

  const Rewards = () => {
    const { rewards } = eventFrame.current;
    return (
      <>
        <Title text={"Pack Rewards"} />
        <Row className={"pack-reward-grid"}>
          {rewards.map((item, i) => {
            const { name, image, collectionName } = item;
            const src = parseImgUrl(image, "thumbnail");
            const to = `/me/collection/main/${collectionName}`;
            const key = `${name}-${i}`;

            return (
              <Link
                className={"pack-reward-block"}
                to={to}
                key={key}
                onClick={changeState}
              >
                <img src={src} alt={name} />
                <p>{name}</p>
              </Link>
            );
          })}
        </Row>
      </>
    );
  };

  const steps = {
    preview: Preview,
    progress: Progress,
    rewards: Rewards,
  };

  const Content = steps[currentStep];

  return (
    <Modal show={true} onHide={reset} centered data-theme={themeStatus}>
      <Modal.Body id="find-pack-modal-wrapper">
        <Content />
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(FindPackModal);
