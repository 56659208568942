import React from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./alphatestinginfo.css";

const AlphaTestingInfo = () => {
  return (
    <Container id='testnet-info' fluid={+true} className="">
      <section id="testnet-getting-started">
        <h4>Getting started on .find Testnet</h4>
        <p>
          Follow the guide on the <Link to={"/p/helphub"}>HelpHub</Link> to set
          up your Blocto wallet and .find profile.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-funding">
        <h4>Funding Your Testnet Account</h4>
        <p>
          Once your Blocto wallet and .find wallet are set up you are ready to
          fund your testnet account and start minting some testnet NFTs.
          <br /> To do this you will need to fund your .find account with some
          testnet Flow.
        </p>
        {/* <div className='image-div'>
        <Image fluid={+true} src="/assets/img/helphub/helphub1.png" />
    </div>
    <p className='caption' align="center">Begin by finding the Connect Wallet button.</p> */}
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-faucet">
        <h4>The Flow Testnet Faucet</h4>
        <p>
          To fund your .find testnet account, head to the{" "}
          <a href="https://testnet-faucet.onflow.org/fund-account">
            Flow testnet faucet.
          </a>
          <br />
          Here you can enter your Blocto wallet address (found on your profile
          dashboard) and receive testnet Flow.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet2.png" />
        </div>
        <p className="caption" align="center">
          Find your Blocto wallet address on your .find profile dashboard
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet1.png" />
        </div>
        <p className="caption" align="center">
          Enter your Blocto wallet address to receive testnet funds
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-swap">
        <h4>Testnet FUSD and Bloctoswap</h4>
        <p>
          Once you have received your testnet Flow you may also want some
          testnet FUSD to register and purchase names.
          <br /> Head to{" "}
          <a href="https://swap-testnet.blocto.app/#/swap">Bloctoswap</a> and
          connect your Blocto wallet via email verification.
          <br /> Once connected you can swap some of your testnet Flow for
          testnet FUSD.
          <br />
          (Note: The testnet exchange rate for Flow to FUSD does not reflect the
          actual rate.)
          <br /> Make sure you are using the testnet version of Bloctoswap (the
          URL should begin with 'swap-testnet.blocto.app').{" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet3.png" />
        </div>
        <p className="caption" align="center">
          Swapping testnet Flow for testnet FUSD
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-mint-bl0x">
        <h4>Minting a Testnet Bl0x NFT</h4>
        <p>
          Now your testnet Blocto wallet is funded with testnet Flow and FUSD
          you will be able to register .find names and interact with the
          Marketplace.
          <br /> Let's begin by minting some of our very own testnet Bl0x NFTs.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet4.png" />
        </div>
        <p className="caption" align="center">
          A newly minted testnet Bl0x NFT viewed on a .find profile
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-whitelist-bl0x">
        <h4>Whitelisting Your Blocto Wallet to Mint Testnet Bl0x NFTs</h4>
        <p>
          To mint a testnet Bl0x NFT you will first have to whitelist your
          wallet address. <br /> You can do this by heading to the{" "}
          <a href="https://testnet.floats.city/bjarte.find/event/90492897">
            FLOAT testnet site
          </a>{" "}
          and connecting your Blocto wallet via email verification.
          <br />
          Next click the 'Claim this FLOAT' button to begin the whitelist
          transaction. <br />
          Once you have claimed this FLOAT your wallet will be whitelisted for
          minting testnet Bl0x NFTs.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet5.png" />
        </div>
        <p className="caption" align="center">
          Claim this FLOAT to whitelist your Blocto wallet to mint testnet Bl0x
          NFTs
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-init-bl0x">
        <h4>Initialising Bl0x Testnet Collection</h4>
        <p>
          Once your Blocto wallet has been whitelisted to mint testnet Bl0x NFTs
          you can head to the{" "}
          <a href="https://bl0x-blond.vercel.app/">Bl0x testnet site</a> and
          initialise the collection.
          <br /> Head to the Bl0x site and connect your Blocto wallet via the
          Connect Wallet button.
          <br /> Once connected you will see the Initialise Collection button -
          click this to run the transaction and you will be ready to mint some
          testnet Bl0x NFTs.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet6.png" />
        </div>
        <p className="caption" align="center">
          Head to the Bl0x testnet site then connect your wallet and initialise
          the collection to begin minting
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-packs-bl0x">
        <h4>Minting Testnet Bl0x Packs</h4>
        <p>
          Now that your Blocto wallet is whitelisted and the Bl0x collection has
          been initialised you are ready to start minting some testnet Bl0x
          NFTs.
          <br /> On the{" "}
          <a href="https://bl0x-blond.vercel.app/">Bl0x testnet site</a> head to
          the 'Mint' tab and select how many testnet Bl0x NFTs you would like to
          mint.
          <br /> You can mint as many as you want.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet7.png" />
        </div>
        <p className="caption" align="center">
          Choose how many Bl0x testnet NFTs you would like to mint
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-open-bl0x">
        <h4>Opening Testnet Bl0x Packs</h4>
        <p>
          Once your packs have been minted you will be able to open them and see
          what's inside.
          <br /> Once opened they will be automatically added to your .find
          profile and you can interact with them in ways such as listing them on
          the Marketplace and setting them as your display picture - check out
          the <Link to={"/p/helphub"}>HelpHub</Link> for more info. <br /> How
          cool is that?
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet8.png" />
        </div>
        <p className="caption" align="center">
          Once your Bl0x testnet NFTs are minted, you can open the packs to
          automatically add them to your .find profile
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-mint-flovatar">
        <h4>Minting a Testnet Flovatar NFT</h4>
        <p>
          .find have teamed up with Flovatar to allow testnet users to mint
          special Flovatar NFTs to use on the .find testnet marketplace.
          <br /> To mint your own testnet Flovatar NFT, first make sure your
          Blocto wallet has testnet Flow by following the process outlined
          above.
          <br /> You can then head to the{" "}
          <a href="https://test2.flovatar.com/">Flovatar testnet site</a> to
          begin the minting process.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet9.png" />
        </div>
        <p className="caption" align="center">
          Head to the Flovatar testnet site to begin minting your testnet
          Flovatar NFT
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-init-flovatar">
        <h4>Initialising Flovatar Testnet Collection</h4>
        <p>
          After connecting your Blocto wallet to the Flovatar testnet site via
          the Connect Wallet button you will be asked to initialise the
          collection. <br /> Do so by pressing the 'Initialize Account' button
          and running the testnet transaction. <br /> Once the transaction is
          complete, you will be able to mint your very own testnet Flovatar NFT.{" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet10.png" />
        </div>
        <p className="caption" align="center">
          After connecting your wallet to the Flovatar testnet site you can
          initialise the collection to begin the minting process
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-packs-flovatar">
        <h4>Purchasing a Flovatar Testnet Pack</h4>
        <p>
          Before building your very own testnet Flovatar NFT you will need to
          purchase a testnet pack to get the spark to bring it to life.
          <br /> Head to the{" "}
          <a href="https://test2.flovatar.com/pack">pack minting page</a> and
          make sure your Blocto wallet is connected.
          <br />
          Next, follow the pack minting instructions - start by getting your
          ticket for the queue by clicking the second option.
          <br />
          Now you can purchase your pack! You will only need a standard pack to
          get the spark to build your testnet Flovatar but feel free to try out
          the deluxe packs.
          <br /> Once you have minted your pack you will be able to open it - it
          will contain the necessary spark as well as some Flobit accessories.
          <br />
          Now you have the spark you are ready to begin building your very own
          testnet Flovatar NFT.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet11.png" />
        </div>
        <p style={{ marginBottom: "2rem" }} className="caption" align="center">
          After connecting and initialising your Blocto wallet, head to the pack
          page and follow the instructions to purchase a testnet Flovatar pack
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet12.png" />
        </div>
        <p style={{ marginBottom: "2rem" }} className="caption" align="center">
          Choose a pack to buy - you will need a standard for the spark but feel
          free to try out some others
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet13.png" />
        </div>
        <p style={{ marginBottom: "2rem" }} className="caption" align="center">
          Once your pack has been purchased you will see it in your collection
          and be able to open it
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet14.png" />
        </div>
        <p className="caption" align="center">
          Inside the pack will be the spark we need along with some Flobit
          accessories
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-build-flovatar">
        <h4>Building Your Testnet Flovatar NFT</h4>
        <p>
          With your account initialised and your pack purchased, you are ready
          to build your very own testnet Flovatar NFT.
          <br /> Head to the testnet{" "}
          <a href="https://test2.flovatar.com/builder">
            Flovatar build page
          </a>{" "}
          and begin building your Flovatar.
          <br />
          With the spark from the pack before we are able to build any standard
          Flovatar - to add rare traits and accessories open more packs to get
          the necessary boosters.
          <br /> Once you have built your Flovatar press the 'Check
          Availability' button - if it is available you will be able to mint it
          via the 'Mint' button in the same location.
          <br /> Once your testnet Flovatar is minted you will be able to see it
          in your .find profile and interact with it in ways such as listing it
          on the Marketplace and setting it as your profile picture.
          <br /> (Note: The images for Flovatars are currently taken from the
          mainnet versions so the image on your .find profile may not reflect
          the one you have built).
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet15.png" />
        </div>
        <p style={{ marginBottom: "2rem" }} className="caption" align="center">
          Build your testnet Flovatar NFT via the builder, you can get more
          boosters and Flobits from packs
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet16.png" />
        </div>
        <p style={{ marginBottom: "2rem" }} className="caption" align="center">
          After a successful Flovatar build you will receive this confirmation
          message
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet17.png" />
        </div>
        <p className="caption" align="center">
          Your testnet Flovatar will be automatically added to your .find
          collection where you can set it as your profile picture or list it on
          the Marketplace
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="testnet-dandies">
        <h4>Need Some Variety?</h4>
        <p>
          Post your wallet address in the{" "}
          <a href="https://discord.gg/tCSFSpFcRv">discord</a> sneak-peek channel
          and we'll load you up with a dandy variety of NFT types including
          images and video.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/alphatesting/testnet18.png" />
        </div>
        <p className="caption" align="center">
          A variety of fine and dandy testnet NFTs
        </p>

        <div className="seperator100 my-4"></div>
      </section>
    </Container>
  );
};

export default AlphaTestingInfo;
