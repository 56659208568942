import { Button, Form, Row } from "react-bootstrap";
import { useRefinementList } from "react-instantsearch-hooks-web";
import "./CustomRefinementList.css";
import "./CustomSearchBox.css";


export default function CustomRefinementList(props) {
  const {
    items,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
    refine,
    searchForItems,
    // hasExhaustiveItems,
    // createURL,
    // sendEvent,
    // isFromSearch,
    // canRefine,
    
  } = useRefinementList(props);

  // console.log(canToggleShowMore);

  // console.log(items)

  const handleCheckedRefinement = (value) => {
    // let isChecked = e.target.checked;
    //console.log(e.target.value);
    refine(value);
  };

  const handleFilterSearch = (event) => {
    searchForItems(event.currentTarget.value);
  };

  const handleToggleShowMore = () => {
    toggleShowMore();
  };

  const doNothing = (e) => {
    e.preventDefault()
  }

  return (
    <>


<div action="" role="search" className="main-search mb-4">
      <div className="search-wrapper-box">
        <i className="fa-solid fa-magnifying-glass search-icon mx-2" />
        <input
        placeholder="Search filters"
        type="text"
        className="input search-input smallTextSpan"
        onChange={handleFilterSearch}
      />
        {/* <input
          className="input search-input smallTextSpan"
          id="algolia_search"
          type="search"
          placeholder="Search marketplace"
          defaultValue={query}
          onChange={(e) => refine(e.currentTarget.value)}
        /> */}
      </div>
    </div>

      <Form>
        {items.map((item, i) => {
          let filterName =
            item.value[0].toUpperCase() + item.value.substring(1);
          // console.log(filterName);
          return (
            <div className="check-box py-2" key={i} onClick={() => handleCheckedRefinement(item.value)}>
              <Form.Check>
              <Form.Check.Input
              id={item.value}
              value={item.value}
              checked={item.isRefined}
              
              />
              <Form.Check.Label
                className="mb-0 smallTextSpan"
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => handleCheckedRefinement(item.value)}
                htmlFor={item.value}
              >
                {filterName
                  .replaceAll("Sale", "Buy Now")
                  .replaceAll("EnglishAuction", "Auction")
                  .replaceAll("DirectOffer", "Offer")
                  .replaceAll("FIND", ".find")}
              </Form.Check.Label>
              </Form.Check>
            </div>
          );
        })}
      </Form>
      {canToggleShowMore && (
        <Button
          className="mt-2 mx-2"
          variant="find-text"
          onClick={handleToggleShowMore}
        >
          {isShowingMore ? "See less" : "See all items"}
        </Button>
      )}
    </>
  );
}
