import React from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import {
  handleFulfillSale,
  handleRejectBlindBid,
} from "../../../../functions/flow-interactions/transactions";
import ReviewOwnAuctionStore from "../../../forms/selling/listname/reviewownauctionstore/reviewownauctionstore";
import "../../userstore.css";

const StoreBidsOnNames = ({ leases, filterTerm }) => {
  // //console.log(leases)
  let filteredOffersLeases = leases
    .filter((lease) => lease.latestBid > 0 && !lease.auctionEnds)
    .filter((lease) =>
      lease.name.toLowerCase().includes(filterTerm.toLowerCase())
    );

  let filteredBidsLeases = leases
    .filter((lease) => lease.auctionStartPrice > 0 && lease.auctionEnds)
    .filter((lease) =>
      lease.name.toLowerCase().includes(filterTerm.toLowerCase())
    );

  //now filter these via search term

  // //console.log(filteredOffersLeases)

  const findLogo = (
    <Image
      className="store-thumb-image"
      src="/assets/img/store/fnamestore.png"
    />
  );

  function CustomToggle({ children, eventKey, lease }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <>
        <div className="d-none d-lg-block">
          <Button
            variant="find-outline-commerce"
            size="sm"
            onClick={decoratedOnClick}
          >
            {children}
          </Button>
        </div>
        <div className="d-lg-none">
          <Button
            onClick={decoratedOnClick}
            style={{
              backgroundImage: "var(--accordion-chevron)",
              width: "26px",
              height: "26px",
              backgroundColor: "var(--background)",
              borderColor: "var(--background)",
              boxShadow: "none",
            }}
          ></Button>
        </div>
      </>
    );
  }

  return (
    <Container fluid={+true} id="store-names-bids" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row>
            <Col align="left">
              <h4>Bids/offers on your names</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <p>
              <b>Offers:</b>
            </p>
          </Row>
          <table className="store-table" id="store-offered-names-table">
            <tbody>
              {filteredOffersLeases.length > 0 ? (
                filteredOffersLeases.map((bid, i) => {
                  // //console.log(bid)
                  return (
                    <React.Fragment key={i + "store-offered-names-row"}>
                      <tr
                        id={i + "store-offered-names-row"}
                        className="store-row"
                      >
                        <td className="store-thumb-col">{findLogo}</td>
                        <td className="store-name-col">
                          <Link to={"/mp/FIND/" + bid.name}>
                            <span className="fw-bold">
                              <span className="themeColor">{bid.name}</span>
                              .find{" "}
                            </span>
                          </Link>
                        </td>
                        <td className="store-sticker-col">
                          {!bid.auctionReservePrice && bid.latestBid && (
                            <span className="store-sticker">
                              From: {bid.latestBidBy}
                            </span>
                          )}
                        </td>
                        <td className="store-price-col">
                          <div className="d-none d-lg-block">
                            {" "}
                            Offer:{" "}
                            <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>
                          </div>
                          <div className="d-block d-lg-none">
                            <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>{" "}
                          </div>
                        </td>
                        <td className="store-time-left-col"></td>

                        <td className="store-buttons-col">
                          <Button
                            onClick={() => handleRejectBlindBid(bid.name)}
                            variant="find-outline-commerce-red"
                          >
                            Reject
                          </Button>
                          <Button
                            onClick={() => handleFulfillSale(bid.name)}
                            variant="find-outline-commerce"
                          >
                            Accept
                          </Button>
                        </td>
                      </tr>
                      <tr className="mobile-row">
                        <td className="mobile-store-sticker-col">
                          {bid.latestBid && (
                            <span className="store-sticker">Offer </span>
                          )}
                        </td>
                        <td className="mobile-store-time-left-col"></td>
                        <td align="end">
                          <Button
                            onClick={() => handleRejectBlindBid(bid.name)}
                            variant="find-outline-commerce-red"
                          >
                            Reject
                          </Button>
                          <Button
                            onClick={() => handleFulfillSale(bid.name)}
                            variant="find-outline-commerce"
                          >
                            Accept
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>

                    // <Row key={i} className="my-4 align-items-center">
                    //   <Col className="col-1">{findLogo}</Col>
                    //   <Col>
                    //     <b>{bid.name}.find </b>
                    //   </Col>
                    //   <Col>
                    //     Offer: <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>
                    //   </Col>
                    //   <Col>
                    //     From:&nbsp;<b>{bid.latestBidBy}</b>
                    //   </Col>
                    //   <Col xs="auto" className="ms-auto">
                    //     <Button
                    //       onClick={() => handleRejectBlindBid(bid.name)}
                    //       variant="find-outline-commerce-red"
                    //     >
                    //       Reject
                    //     </Button>
                    //   </Col>
                    //   <Col xs="auto">
                    //     <Button
                    //       onClick={() => handleFulfillSale(bid.name)}
                    //       variant="find-outline-commerce"
                    //     >
                    //       Accept
                    //     </Button>
                    //   </Col>
                    // </Row>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <small>No current offers on your names</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="seperator100 my-4"></div>

          <Row>
            <p>
              <b>For auction:</b>
            </p>
          </Row>
          <Accordion>
            <table className="store-table" id="store-bidded-names-table">
              <tbody>
                {filteredBidsLeases.length > 0 ? (
                  filteredBidsLeases.map((bid, i) => {
                    let ended;
                    if (bid.auctionEnds < bid.currentTime) {
                      ended = true;
                    } else {
                      ended = false;
                    }
                    // //console.log(bid);

                    return (
                      <React.Fragment key={i + "store-bidded-names-row"}>
                        <tr
                          id={i + "store-bidded-names-row"}
                          className="store-row"
                        >
                          <td className="store-thumb-col">{findLogo}</td>
                          <td className="store-name-col">
                            <Link to={"/mp/FIND/" + bid.name}>
                              <span className="fw-bold">
                                <span className="themeColor">{bid.name}</span>
                                .find{" "}
                              </span>
                            </Link>
                          </td>
                          <td className="store-sticker-col">
                            <span className="store-sticker">
                              From: {bid.latestBidBy}
                            </span>
                          </td>
                          <td className="store-price-col">
                            <div className="d-none d-lg-block">
                              {/* //       {bid.latestBid && ( */}
                              {/* //         <>
                  //           Bid:&nbsp;
                  //           <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>
                  //         </>
                  //       )} */}
                              Bid:{" "}
                              <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>
                            </div>
                            <div className="d-block d-lg-none">
                              <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>{" "}
                            </div>
                          </td>
                          <td className="store-time-left-col">
                            <span className="store-sticker">
                              {!ended ? "Ends: " : "Ended: "}
                              <ReactTimeago
                                date={new Date(bid.auctionEnds * 1000)}
                                timestyle="twitter"
                              />
                            </span>
                          </td>

                          <td className="store-buttons-col">
                            <CustomToggle eventKey={i}>
                              {" "}
                              View Auction
                            </CustomToggle>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-none d-md-table-cell" colSpan={6}>
                            <Accordion.Collapse eventKey={i}>
                              <Card.Body>
                                <div
                                  className="collapse show"
                                  id="collapseExample"
                                >
                                  <ReviewOwnAuctionStore
                                    ended={ended}
                                    filteredLease={bid}
                                  />
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </td>
                        </tr>

                        <tr className="mobile-row">
                          <td className="mobile-store-sticker-col">
                            {bid.latestBid && (
                              <span className="store-sticker">Bids </span>
                            )}
                          </td>
                          <td className="mobile-store-time-left-col">
                            <span className="store-sticker">
                              {!ended ? "Ends: " : "Ended: "}
                              <ReactTimeago
                                date={new Date(bid.auctionEnds * 1000)}
                                timestyle="twitter"
                              />
                            </span>
                          </td>
                          <td align="end">
                            <CustomToggle eventKey={i}>
                              {" "}
                              View Auction
                            </CustomToggle>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-table-cell d-md-none" colSpan={3}>
                            <Accordion.Collapse eventKey={i}>
                              <Card.Body>
                                <div
                                  className="collapse show"
                                  id="collapseExample"
                                >
                                  <ReviewOwnAuctionStore
                                    ended={ended}
                                    filteredLease={bid}
                                  />
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </td>
                        </tr>
                      </React.Fragment>

                      // <React.Fragment key={i}>
                      //   <Row className="my-4 align-items-center">
                      //     <Col className="col-1">{findLogo}</Col>
                      //     <Col>
                      //       <b>{bid.name}.find </b>
                      //     </Col>
                      //     <Col>
                      //       {" "}
                      //       {bid.latestBid && (
                      //         <>
                      //           Start:&nbsp;
                      //           <b>
                      //             {parseFloat(bid.auctionStartPrice).toFixed(2)}{" "}
                      //             FUSD
                      //           </b>
                      //         </>
                      //       )}
                      //     </Col>
                      //     <Col>
                      //       {bid.latestBid && (
                      //         <>
                      //           Bid:&nbsp;
                      //           <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>
                      //         </>
                      //       )}
                      //     </Col>
                      //     <Col>
                      //     {bid.auctionEnds > bid.currentTime ? "Ends: " : "Ended: "}
                      //       <b>
                      //         <ReactTimeago
                      //           date={new Date(bid.auctionEnds * 1000)}
                      //           timestyle="twitter"
                      //         />{" "}
                      //       </b>

                      //     </Col>
                      //     <Col align="right">
                      //       <CustomToggle eventKey={i}> View Auction</CustomToggle>
                      //     </Col>
                      //   </Row>
                      //   <Accordion.Collapse eventKey={i}>
                      //     <Card.Body>
                      //       <div className="collapse show" id="collapseExample">
                      //         <ReviewOwnAuctionStore filteredLease={bid} />
                      //       </div>
                      //     </Card.Body>
                      //   </Accordion.Collapse>
                      // </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td>
                      <small>No current bids on your names</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StoreBidsOnNames;
