import React from 'react'
import './ColDetailAvailableOn.scss'

const ColDetailAvailableOnFlowtyRental = ({listingStatus}) => {
  const onDetails = listingStatus.allCurrentListings.flowtyRental
  return (
    <div className="available-on-wrapper-flowty p-3 my-4">
      <div className="available-on-row">
      <div className="available-on-icon-col">
          <img id='icon-img' src="/assets/img/profile/collection/nft-detail/on-flowty.jpg" />
          <img id='verify-img' src="/assets/img/find-verified-tick.svg"/>
        </div>
        <div className="available-on-info-col">
          <div className="available-on-info-row-1">This item is available for loan on the Flowty website</div>
          <div className="available-on-info-row-2">2</div>
        </div>
        <div className="available-on-price-col">
          <div className="available-on-price-row-1">1</div>
          <div className="available-on-price-row-2">2</div>
        </div>
      </div>
    </div>
  )
}

export default ColDetailAvailableOnFlowtyRental