import React from "react";
import { useEffect } from "react";
import { Form, Row, Col, Image, Button, Container } from "react-bootstrap";
import { useImmer } from "use-immer";
import { useFormStatus } from "../../../../functions/DisabledState";
import { UseThemeStatus } from "../../../../functions/themeMode";
import {
  handleMakeOfferNFT,
  handleOffer,
} from "../../../../functions/flow-interactions/transactions";
import "./makeoffernftmodalmp.css";
import toast from "react-hot-toast";
import FUSDSticker from "../../ftstickers/FUSDSticker";
import FlowSticker from "../../ftstickers/FlowSticker";
import MpDetailOwnedBy from "../../../search/mpdetailpage/mpdetailownedby";
import parseImgUrl from "../../../../functions/parseImgUrl";

const MakeOfferNftModalMp = ({
  
  hit,
  profileDetails,
  isName,
  listingDetails,
  userHasOffer,
}) => {
  // const [salesType, setSalesType] = useState("sale");
  // const [forSale, setIsForSale] = useState(false);
  // const [forAuction, setIsForAuction] = useState(false);
  // console.log(hit);

  // console.log(listingDetails);
  // const [collectionName, setCollectionName] = useState();
  let validated = false;

  const [formValues, setFormValues] = useImmer([
    {
      id: "address",
      value: hit.seller,
    },
    {
      id: "amount",
      value: "0",
    },
    {
      id: "ftAlias",
      value: "Flow",
    },
    {
      id: "id",
      value: null,
    },
    {
      id: "nftAlias",
      value: hit.nft_type,
    },
  ]);

  //edgecases (for reference - solved by using type)
  //Dandy items need dandy not the mp index collection name
  //blox index  collection name comes through with 'b' not 'B
  // useEffect(() => {
  //   if (hit.nft_type.includes("Dandy")) {
  //     setFormValues([
  //       {
  //         id: "address",
  //         value: hit.seller,
  //       },
  //       {
  //         id: "amount",
  //         value: "0",
  //       },
  //       {
  //         id: "ftAlias",
  //         value: "Flow",
  //       },
  //       {
  //         id: "id",
  //         value: null,
  //       },
  //       {
  //         id: "nftAlias",
  //         value: "Dandy",
  //       },
  //     ]);
  //   } else if (hit.collection_name.includes("bl0x")) {
  //     setFormValues([
  //       {
  //         id: "address",
  //         value: hit.seller,
  //       },
  //       {
  //         id: "amount",
  //         value: "0",
  //       },
  //       {
  //         id: "ftAlias",
  //         value: "Flow",
  //       },
  //       {
  //         id: "id",
  //         value: null,
  //       },
  //       {
  //         id: "nftAlias",
  //         value: "Bl0x",
  //       },
  //     ]);
  //   }
  // }, [hit]);

  // console.log(formValues)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValues[1].value < 1) {
      document.getElementById("amount").classList.add("is-invalid");
      document.getElementById("amount").classList.add("is-valid");
      toast.error("Please enter an amount of 1 or higher");
    } else {
      if (isName) {
        handleOffer([
          {
            id: "bidAmt",
            value: formValues[1].value,
          },
          {
            id: "name",
            value: hit.nft_name,
          },
        ]);
      } else {
        handleMakeOfferNFT(formValues);
      }
    }
  };

  useEffect(() => {
    if (hit) {
      setFormValues((draft) => {
        const varVal = draft.find((varVal) => varVal.id === "id");
        varVal.value = hit.nft_id;
      });
    }
  }, [hit, setFormValues]);

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value;
      //now validate
      if (varVal.value < 1) {
        e.target.classList.add("is-invalid");
        e.target.classList.remove("is-valid");
      } else {
        e.target.classList.add("is-valid");
        e.target.classList.remove("is-invalid");
      }
    });
    // //console.log(formValues);
  }

  // useEffect(() => {
  //     if (lease.salePrice > 0) {
  //         setIsForSale(true)
  //         setSalesType("auction")
  //     }
  //     if (lease.auctionStartPrice > 0) {
  //         setIsForAuction(true)
  //     }
  //     if (lease.salePrice > 0 && lease.auctionStartPrice > 0) {
  //         setSalesType("none")
  //     }
  // }, [lease])

  return (
    <div
      id="list-name"
      data-theme={UseThemeStatus()}
      className="form-inputs mp-offer-modal"
    >
      <Image
        fluid={+true}
        className="modal-img"
        src={
          isName
          ? parseImgUrl("/assets/img/marketplace/find_name_with_find.png", '', hit.nft_name)
          : parseImgUrl(hit.nft_thumbnail)
        }
      />

      {hit.listing_alias === "Sale" && (
        <Row className="pt-3 fw-bold">
          <Col align="left" className="">
            Buy price:{" "}
          </Col>
          <Col align="right">
            {" "}
            {hit.amount * 1} {hit.amount_alias.replace("FlowToken", "Flow")}
          </Col>
        </Row>
      )}

 <MpDetailOwnedBy profileDetails={profileDetails}/>
      <fieldset disabled={useFormStatus()}>
        {userHasOffer ? (
          <Container className="commerceinfo py-3">
            <Row>
              <Col>
                <p className="fw-bold">
                  Congrats! You have made an offer of{" "}
                  {isName
                    ? listingDetails.latestBid * 1
                    : listingDetails.findMarket.FindMarketDirectOfferEscrow
                        .amount * 1}{" "}
                  {hit.amount_alias.replace("FlowToken", "Flow")} for{" "}
                  {isName ? (
                    <span>
                      <span className="themeColor">{hit.nft_name}</span>.find
                    </span>
                  ) : (
                    hit.nft_name
                  )}
                </p>
                <div>
                  <small>
                    Check your dashboard regularly for the status of your offer,
                    and whether it has been accepted.
                  </small>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Row className="pt-3">
            <Col align="left" className="fw-bold">
              How much would you like to offer?
            </Col>
            <Col>
              {hit.amount_alias === "FlowToken" && <FlowSticker />}
              {hit.amount_alias === "FUSD" && <FUSDSticker />}
            </Col>
            <Row>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="form-inputs pt-3"
              >
                <Row>
                  <Form.Label>Offer amount:</Form.Label>
                </Row>
                <Row className="p-3">
                  <Col align="center" xs="12" md="6">
                    <Form.Control
                      id="amount"
                      className="w-100"
                      type="number"
                      placeholder="Input offer"
                      onChange={updateField}
                      name="amount"
                      required
                      min="0"
                    />
                  </Col>
                  <Col align="center" className="mt-2 mt-md-0" xs="12" md="6">
                    <Button
                      className="w-100"
                      variant="find-outline-commerce-dark"
                      type="submit"
                    >
                      Make Offer
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Row>
        )}
      </fieldset>
    </div>
  );
};

export default MakeOfferNftModalMp;
