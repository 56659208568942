import { useState } from "react";
import Lightbox from "react-image-lightbox";
import { Card, Col, Container, Row } from "react-bootstrap";
import bl0x_banner from "../../../../../components/marketplace/collectionpage/bl0x_banner.webp";
import nfg_banner from "../../../../../components/marketplace/collectionpage/nfg_banner.webp";
import nfg_square from "../../../../../components/marketplace/collectionpage/nfg_square.webp";
import CustomImageThumb from "../../../../../components/CustomImage/CustomImageThumb";
import CollectionStatsBox from "../../../../../components/marketplace/collectionpage/CollectionStatsBox";
import catalogData from "./data";
import "./header.scss";
import "./header.media.scss";

export default function Header() {
  const [bannerOpen, setBannerOpen] = useState();
  const [squareOpen, setSquareOpen] = useState();

  const colName = "Wearables";

  let catalogDataDisplay;
  let creatorName;
  let creatorAddress;
  let creatorLink;
  let creatorType;
  let headerName;
  if (catalogData) {
    catalogDataDisplay = catalogData.collectionDisplay;
    creatorName = catalogData?.contractName
      ? catalogData?.contractName
      : catalogData?.contractAddress;
    creatorAddress = catalogData?.contractAddress;
    creatorType = catalogData?.nftType.replace(".NFT", "");
    creatorLink = "https://flowscan.org/contract/" + creatorType;
  }

  //hacky fix for items with no banner from catalog response
  let headerBackgroundUrl =
    catalogDataDisplay?.name === "bl0x"
      ? bl0x_banner
      : catalogDataDisplay?.name === "NonFunGerbils"
      ? nfg_banner
      : catalogDataDisplay?.bannerImage?.file.cid
      ? "https://find.mypinata.cloud/ipfs/" +
        catalogDataDisplay?.bannerImage?.file.cid
      : catalogDataDisplay?.bannerImage?.file?.url
      ? catalogDataDisplay.bannerImage.file.url
      : null;

  //hacky fix for items with no square from catalog response
  let squareImage =
    catalogDataDisplay?.name === "bl0x"
      ? " https://pbs.twimg.com/media/Fa2fh0sXoAIIGTq?format=png&name=small"
      : catalogDataDisplay?.name === "NonFunGerbils"
      ? nfg_square
      : catalogDataDisplay?.bannerImage?.file.cid
      ? "https://find.mypinata.cloud/ipfs/" +
        catalogDataDisplay?.squareImage?.file.cid
      : catalogDataDisplay?.squareImage?.file?.url
      ? catalogDataDisplay?.squareImage?.file?.url
      : null;
  let headerDescription = catalogDataDisplay?.description;
  let headerSite = catalogDataDisplay?.externalURL?.url;
  let headerSocials = catalogDataDisplay?.socials;

  headerName = catalogData.collectionDisplay.name;

  return (
    <div className={"header__container"}>
      <img
        src={headerBackgroundUrl}
        alt={colName}
        className="header__container--hero"
      />
      <div className="header__content">
        <div className="header__socials">
          {headerSite && (
            <a
              href={headerSite}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <i className={"me-2 fa-solid fa-globe"} />
            </a>
          )}
          {headerSocials &&
            Object.keys(headerSocials).map((link, index) => (
              <a
                key={index}
                href={headerSocials[link].url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <i className={"me-2 fa-brands fa-" + link} />
              </a>
            ))}
        </div>
        <div className="header__avatar">
          <img src={squareImage} alt="Wearables Collection" />
        </div>
        <div className="header__text">
          <h3 className={"header__title"}>{headerName}</h3>
          <div className="header__subtitle">
            Created by:{" "}
            <a target="_blank" rel="noreferrer" href={creatorLink}>
              {creatorName}
            </a>
          </div>
          <p className={"header__description"}>{headerDescription}</p>
        </div>
        <div className="header__stats">
          <CollectionStatsBox />
        </div>
      </div>
    </div>
  );
}
