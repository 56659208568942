export const Mint = (props) => {
	const { context, transfer } = props;
	return <p>This is Flovatar Minting</p>;
};

export const Burn = (props) => {
	const { context, transfer } = props;
	return <p>This is Flovatar Burn</p>;
};

export const MarketplaceSale = (props) => {
	const { context, transfer } = props;
	return <p>This is Flovatar Marketplace Sale</p>;
};

export const MarketplacePurchases = (props) => {
	const { context, transfer } = props;
	return <p>Flovatar Purchased</p>;
};