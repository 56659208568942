import React from "react";
import { useLocation } from "react-router";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import "./mpdetailpage.css";

const MpDetailShareIcons = () => {
  const location = useLocation();

  let url = "https://find.xyz/" + location.pathname;
  let title = `Check this out on the .find marketplace`;

  return (
    <div>
      <span style={{ float: "left" }}>
        <TwitterShareButton
          className="m-1"
          title={title}
          url={url}
          hashtags={["findonflow", "onFlow"]}
        >
          <i className="fab fa-twitter mr-1 shareIcons" />
        </TwitterShareButton>
        <FacebookShareButton className="m-1" quote={title} url={url}>
          <i className="fab fa-facebook m-1 shareIcons" />
        </FacebookShareButton>
        <LinkedinShareButton className="m-1" url={url}>
          <i className="fab fa-linkedin m-1 shareIcons" />
        </LinkedinShareButton>
      </span>
    </div>
  );
};

export default MpDetailShareIcons;
