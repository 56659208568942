import React from "react";
import Filters from "./Filters";
import ActionBar from "./ActionBar";
import "./selection.scss";
import "./selection.media.scss";

export default function FilterSelection(props) {
  const { toggle } = props;
  return (
    <div className="doodles__filter-selection">
      <ActionBar toggle={toggle} />
      <Filters />
    </div>
  );
}
