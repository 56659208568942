import React from "react";
import EventsSwitch from "../../functions/EventsSwitch";
import "./LiveFeedWrapper.scss";
import TimeAgo from "react-timeago";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const LiveFeedEventItem = ({ event, i }) => {
  // console.log(event)
  const switchedEvent = EventsSwitch(event);
  // console.log(switchedEvent);
  // console.log(event);

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    )
  );

  // console.log(switchedEvent)
  return (
    <div id="live-feed-event-item-wrapper" className="py-3">
      <div id="live-feed-event-item-row">
        <div id="lf-item-icon-box">
          {switchedEvent && switchedEvent.viewItem ? (
            <Link to={switchedEvent.viewItem}>{switchedEvent.eventIcon}</Link>
          ) : (
            <>{switchedEvent.eventIcon}</>
          )}
        </div>
        <div id="lf-item-info-box">
          <div>
            {switchedEvent && switchedEvent.viewItem ? (
              <>
                <Link to={switchedEvent.viewItem}>
                  <span id="lf-item-name-span">
                    {switchedEvent.itemType === "name" ||
                    switchedEvent.isName ? (
                      <>
                        <span className="fw-bold">
                          <span className="themeColor">
                            {switchedEvent.itemName}
                          </span>
                          .find
                        </span>
                        &nbsp;
                      </>
                    ) : (
                      <>
                        {switchedEvent.itemName && (
                          <span id="lf-item-name-span">
                            {switchedEvent.itemName}&nbsp;
                          </span>
                        )}
                      </>
                    )}
                  </span>
                </Link>
                {switchedEvent.whatHappened}
                {switchedEvent.viewThoughtLink}
              </>
            ) : (
              <>
                {switchedEvent.itemName && (
                  <span id="lf-item-name-span">
                    {switchedEvent.itemName}&nbsp;
                  </span>
                )}
                {switchedEvent.whatHappened}
                {switchedEvent.viewThoughtLink}
              </>
            )}
          </div>

          <div>
            <span id="lf-item-from-to-span">
              From{" "}
              <span className="fw-bold">
                {/* {soldActivity.fromName
                          ? soldActivity.fromName
                          : soldActivity.seller} */}
                {switchedEvent.fromIsName && switchedEvent.fromName ? (
                  <Link to={"/" + switchedEvent.fromName}>
                    <span className="secondaryText">
                      <span className="themeColor">
                        {switchedEvent.fromName}
                      </span>
                      .find
                    </span>
                  </Link>
                ) : switchedEvent.fromName ? (
                  <Link to={"/" + switchedEvent.fromName}>
                    <span className="secondaryText">
                      {switchedEvent.fromName}
                    </span>
                  </Link>
                ) : (
                  switchedEvent?.seller
                )}
              </span>
              {switchedEvent.toName && switchedEvent.toName.length > 0 && (
                <span>
                  {" "}
                  to{" "}
                  <span className="fw-bold">
                    {switchedEvent.toName && switchedEvent.toIsName ? (
                      <Link to={"/" + switchedEvent.toName}>
                        <span className="secondaryText">
                          <span className="themeColor">
                            {switchedEvent.toName}
                          </span>
                          .find
                        </span>
                      </Link>
                    ) : switchedEvent.toName ? (
                      <Link to={"/" + switchedEvent.toName}>
                        <span className="secondaryText">
                          {switchedEvent.toName}
                        </span>
                      </Link>
                    ) : (
                      switchedEvent?.buyer
                    )}
                  </span>
                </span>
              )}
              {/* <span id="lf-item-from-to-span">
              from {switchedEvent.fromName}
              {switchedEvent.toName && ` to ${switchedEvent.toName}`}
            </span> */}
            </span>
          </div>
        </div>
        <div id="lf-item-end-box">
          <div id="live-feed-event-item-price-row">
            <div id="lf-item-price-box">
              {event.blockEventData.amount}&nbsp;{switchedEvent.txFtType}
            </div>

           {!event.flowEventId.includes('Edited') && <div id="lf-item-kebab-box">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  id="kebab-dropdown-toggle"
                >
                  <img src="/assets/img/lf/lf-kebab.svg" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    target="_blank"
                    className=" "
                    rel="noreferrer"
                    href={
                      process.env.REACT_APP_BLOCK_VIEWER_URL +
                      event.flowTransactionId
                    }
                  >
                    View transaction
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>}


          </div>
          <div id="lf-item-time-box">
            <TimeAgo
              date={new Date(event.eventDate)}
              timestyle="twitter"
              suffix="none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveFeedEventItem;
