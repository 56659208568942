import React from "react";
import "./LiveFeedWrapper.scss";

const ThoughtBoxNFTSelectOthers = () => {
  return (
    <div>
      Enter user address or findname
      <form action="">
        <input type="text" />
        <input type="submit" />
      </form>
    </div>
  );
};

export default ThoughtBoxNFTSelectOthers;
