import React from "react";
import "./createprofile.scss";

const CreateProfileFormHeader = ({ currentStage, findUser }) => {
  const headerContent =
    currentStage === 2
      ? "Choose a free profile name"
      : currentStage === 3
      ? "Register a .find name"
      : currentStage === 4
      ? "Woohoo! You're ready to go"
      : null;
  return (
    <>
      <span id="create-profile-form-header">
        {headerContent}
        {findUser && findUser.profile && findUser.profile.findName ? (
          <span>
            &nbsp;
            <span className="themeColor">{findUser.profile.findName}</span>.find
          </span>
        ) : null}
        !
      </span>
    </>
  );
};

export default CreateProfileFormHeader;
