import "./index.scss";
export default function DropBox(props) {
  const { image, content } = props;
  return (
    <div className={"dp__drop-info--container dp__mb-3"}>
      <div className="dp__drop-info--content">{content}</div>
      <div className="dp__drop-info--image">{image}</div>
    </div>
  );
}
