import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./ColDetailOwnItemSendItem.scss";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import { Script } from "../../../../../functions/script";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import { useFormStatus } from "../../../../../functions/DisabledState";
import SendItemRoyaltiesForm from "./SendItemRoyaltiesForm";

const ColDetailOwnItemSendItemConfirm = ({
  setCurrentStage,
  sendNFTAddress,
  listingStatus,
  sendNFTMemo,
  sendNFT,
  royaltyDonation,
  setRoyaltyDonation,
}) => {
  const [receiverInfo, setReceiverInfo] = useState(null);
  const [confirmSummary, setConfirmSummary] = useState(null);
  const [receiverHasLease, setRecieverHasLease] = useState(null);
  const [receiverPfp, setReceiverPfp] = useState(
    "/assets/img/profile/send-item/avatar-blank.svg"
  );
  //look up status of receiver wallet
  useEffect(() => {
    async function getReceiverWallet(addr) {
      const receiver = addr.toLowerCase()

      const receiverWalletResponse = await Script(scripts.sendNFTs, {
        sender: listingStatus.user.addr,
        nftIdentifiers: [listingStatus.itemType],
        allReceivers: [receiver],
        ids: [Number(listingStatus.itemId)],
        memos: [sendNFTMemo],
      });

      const receiverInfo = receiverWalletResponse ? receiverWalletResponse[0] : {}
      setReceiverInfo(receiverInfo);
    }
    try {
      getReceiverWallet(sendNFTAddress.replaceAll(".find", ""));
    } catch (error) {
      console.log(error);
    }
  }, [sendNFTAddress, listingStatus]);

  useEffect(() => {
    if (receiverInfo) {
      if (receiverInfo.ok) {
        setConfirmSummary(
          "This item will be sent straight to the receiver's collection"
        );
      }
      if (receiverInfo.err) {
        setConfirmSummary(
          `This item can not be sent due to error: ${receiverInfo.err} `
        );
      }
      if (!receiverInfo.err) {
        if (sendNFTAddress.length === 18 && sendNFTAddress.includes("0x")) {
          setRecieverHasLease(false);
        } else if (
          (sendNFTAddress.length > 2 && sendNFTAddress.length <= 16) ||
          sendNFTAddress.includes(".find")
        ) {
          setRecieverHasLease(true);
        }
      }
      if (receiverInfo.isDapper) {
        setConfirmSummary(
          `You are sending an item to a Dapper wallet - known issues`
        );
      }
      if (!receiverInfo.ok && !receiverInfo.isDapper && !receiverInfo.err) {
        setConfirmSummary(
          `This item will be sent to the receiver's inbox where they will be able to link their collection`
        );
      }
      if (receiverInfo.avatar) {
        setReceiverPfp(parseImgUrl(receiverInfo.avatar));
      }
    }
  }, [receiverInfo]);

  const formStatus = useFormStatus();

  if(receiverInfo){
    return (
      <div id="send-item-confirm-wrapper">
        <div id="send-item-icon-row">
          <img src={parseImgUrl(listingStatus.itemThumbnail)} />{" "}
          <img
            id="send-arrows-img"
            src="/assets/img/profile/send-item/arrows-right.svg"
          />
          <img src={receiverPfp} />
        </div>
        <div id="send-item-name-row">
          Sending {listingStatus.itemName}
          {/* {receiverInfo.type.split(".")[2]} {receiverInfo.id} */}
        </div>
        <div id="send-item-receiver-info">
          <div id="receiver-title"> Receiver:</div>
          {receiverHasLease ? (
            <span>
            <span>
              <span className="fw-bold themeColor">
                {" "}
                <span style={{ color: "var(--text-primary)" }}>
                  {sendNFTAddress.replaceAll(".find", "")}
                </span>
                .find{" "}
              </span>
              <small className="secColor">@ {receiverInfo.address}</small>
            </span>
          </span>
          ) : (
            <span>{sendNFTAddress}</span>
          )}
        </div>{" "}
        <div id="send-item-memo-info">
          <div id="message-title">Message: </div>
          {receiverInfo.message ? receiverInfo.message : "None"}
        </div>
        {receiverInfo.err && (
          <div id="send-item-error-info">
            {receiverInfo.err && <>Error: {receiverInfo.err}</>}
          </div>
        )}
        <div id="send-item-confirm-summary">
          <div id="send-item-confirm-summary-title">Summary:</div>
          <div id="send-item-confirm-summary-info">{confirmSummary}</div>
        </div>
        {receiverInfo.royalties && (
          <SendItemRoyaltiesForm
            royalties={receiverInfo.royalties.royalties}
            royaltyDonation={royaltyDonation}
            setRoyaltyDonation={setRoyaltyDonation}
            // findRoyaltyDonation={findRoyaltyDonation}
            // setFindRoyaltyDonation={setFindRoyaltyDonation}
          />
        )}
        <div id="send-item-form-wrapper">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendNFT(e);
            }}
            disabled={formStatus}
            id="send-item-form"
          >
            <div id="send-item-button-row">
              <Button
                variant="find-outline-commerce-light"
                // // disabled={sendNFTAddress.length <= 3}
                onClick={() => setCurrentStage(1)}
              >
                Go back
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="find-outline-commerce-dark"
                disabled={receiverInfo.err || formStatus}
              >
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  return (
    <div id="send-item-confirm-wrapper">
      <div id="send-item-icon-row">
        <img alt="item thumbnail" src={parseImgUrl(listingStatus.itemThumbnail)} />{" "}
        <img alt="arrow right"
          id="send-arrows-img"
          src="/assets/img/profile/send-item/arrows-right.svg"
        />
        <img alt="blank avatar" src="/assets/img/profile/send-item/avatar-blank.svg" />
      </div>
      Getting receiver info...
    </div>
  );
};

export default ColDetailOwnItemSendItemConfirm;
