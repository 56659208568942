import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import parseNameLink from "../../../../../functions/parseNameLink";
import UppercaseFirstLetter from "../../../../../functions/UppercaseFirstLetter";
import "./ColDetailDetails.css";
const ColDetailDetailsCatalog = ({ listingStatus }) => {
  let listingDetails;
  let itemMedia = null;
  let itemThumbnail = null;
  let itemAllowedListings = null;
  let itemCurrentListings = {
    forAuction: false,
    forSale: false,
    offered: false,
  };
  let itemCollectionDescription = null;
  let itemCatalogName = null;
  let itemOwner = null;
  let itemType = null;
  let itemIsListed = null;
  let itemRarity = null;


  if (Object.keys(listingStatus.listingDetails).length > 0) {
  
    if (listingStatus?.listingDetails?.nftDetail?.type)
      itemCatalogName =
        listingStatus?.listingDetails?.nftDetail?.type.split(".")[2];
  }

  if (
    listingStatus.owner.ownerProfile &&
    Object.keys(listingStatus.owner.ownerProfile).length !== 0 &&
    Object.getPrototypeOf(listingStatus.owner.ownerProfile) === Object.prototype
  ) {
    itemOwner = listingStatus.owner.ownerProfile;
  }

  if (listingStatus.indexHit && listingStatus.indexHit.nft_rarity) {
    itemRarity = listingStatus.indexHit.nft_rarity;
  }

  //market

  //allowedlistings
  if (
    listingStatus.listingDetails.allowedListingActions &&
    Object.keys(listingStatus.listingDetails.allowedListingActions).length !==
      0 &&
    Object.getPrototypeOf(
      listingStatus.listingDetails.allowedListingActions
    ) === Object.prototype
  ) {
    itemAllowedListings = listingStatus.listingDetails.allowedListingActions;
  }

  //currentListings
  if (listingStatus.forAuction === true) {
    itemCurrentListings.forAuction = true;
  }
  if (listingStatus.forSale === true) {
    itemCurrentListings.forSale = true;
  }
  if (listingStatus.offered === true) {
    itemCurrentListings.offered = true;
  }

  //details
  if (listingStatus.listingDetails.nftDetail) {
    listingDetails = listingStatus.listingDetails.nftDetail;
    if (listingStatus.listingDetails.nftDetail.type) {
      itemType = listingStatus.listingDetails.nftDetail.type;
    }

    if (listingStatus.listingDetails.nftDetail.thumbnail) {
      itemThumbnail = listingStatus.listingDetails.nftDetail.thumbnail;
    }

    if (listingStatus.listingDetails.nftDetail.collection.description) {
      itemCollectionDescription =
        listingStatus.listingDetails.nftDetail.collection.description;
    }

    if (
      listingStatus.listingDetails.nftDetail.media &&
      Object.keys(listingStatus.listingDetails.nftDetail.media).length !== 0
    ) {
      itemMedia = listingStatus.listingDetails.nftDetail.media;
    }
  }

  if (listingDetails) {
    return (
      <Card style={{backgroundColor: 'var(--background)'}} id="nft-detail-details-card" className="nft-details-card px-0">
        <Card.Body>
          <Row>
            <Col xs="12">
              {itemOwner && (
                <Row className="pb-5">
                  <Col xs="auto" className="px-0">
                    <span className="fw-bold px-0"> Owner:&nbsp;&nbsp;</span>
                  </Col>
                  <Col className="px-0">
                    <span>{parseNameLink(itemOwner)}</span>
                  </Col>
                </Row>
              )}

              {itemRarity && (
                <Row className="pb-5">
                  <Col xs="auto" className="px-0">
                    <span className="fw-bold px-0"> Rarity:&nbsp;&nbsp; </span>
                  </Col>
                  <Col className="px-0">
                    <span>{itemRarity}</span>
                  </Col>
                </Row>
              )}

              {itemThumbnail && (
                <Row className="pb-5">
                  <Col xs="12" className="px-0">
                    <span className="fw-bold px-0">Thumbnail:&nbsp;&nbsp;</span>
                  </Col>
                  <Col className="px-0 propertiesWrap">
                    <a href={itemThumbnail}>
                      <span>{itemThumbnail}</span>
                    </a>
                  </Col>
                </Row>
              )}

              {itemMedia && (
                <Row className="pb-5">
                  <Col xs="12" className="px-0">
                    <span className="fw-bold px-0"> Media:&nbsp;&nbsp;</span>
                  </Col>
                  {Object.keys(itemMedia).map((mediaItem, i) => {
                    return (
                      <div className="px-0 propertiesWrap mb-2" key={i}>
                        <span className="fw-bold">{itemMedia[mediaItem]}:</span>{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className=""
                          href={mediaItem}
                        >
                          <span>{mediaItem}</span>
                        </a>
                      </div>
                    );
                  })}
                </Row>
              )}

              <Row className="pb-5">
                <Col xs="12" md="6" className="px-0">
                  <span className="fw-bold px-0">ID:&nbsp;&nbsp;</span>{" "}
                  <span className="propertiesWrap">
                    {listingDetails.id ? listingDetails.id : ""}
                  </span>
                </Col>
                <Col xs="12" md="6" className="px-0">
                  <span className="fw-bold px-0">UUID:&nbsp;&nbsp;</span>
                  <span className="propertiesWrap">
                    {listingDetails.uuid ? listingDetails.uuid : ""}
                  </span>
                </Col>
              </Row>
              {itemAllowedListings && (
                <Row className="pb-5">
                  <span className="fw-bold px-0">
                    {" "}
                    Current market listings:
                  </span>
                  {itemIsListed ? (
                    <Row className="propertiesWrap">
                      {itemCurrentListings.forAuction && (
                        <Link
                          className="px-0"
                          to={
                            "/mp/" + itemCatalogName + "/" + listingDetails.id
                          }
                        >
                          <span>Auction</span>
                        </Link>
                      )}
                      {itemCurrentListings.forSale && (
                        <Link
                          className="px-0"
                          to={
                            "/mp/" + itemCatalogName + "/" + listingDetails.id
                          }
                        >
                          <span>Sale</span>
                        </Link>
                      )}
                      {itemCurrentListings.offered && (
                        <Link
                          className="px-0"
                          to={
                            "/mp/" + itemCatalogName + "/" + listingDetails.id
                          }
                        >
                          <span>Offered</span>
                        </Link>
                      )}
                    </Row>
                  ) : (
                    <Row>
                      <span className="propertiesWrap px-0">None</span>
                    </Row>
                  )}
                </Row>
              )}

              <div className="pb-5">
                <Row>
                  <span className="fw-bold px-0">
                    Allocated market actions:
                  </span>
                </Row>
                {itemAllowedListings ? (
                  <Row className="align-items-center">
                    <Col className="propertiesWrap px-0">
                      <Row>
                        {Object.keys(itemAllowedListings).map(
                          (properties, i) => {
                            return <div key={i}>{properties}</div>;
                          }
                        )}
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row className="align-items-center">
                    <Col className="propertiesWrap px-0">
                      <Row>
                        <span>None</span>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>

              {itemCollectionDescription && (
                <div className="pb-5">
                  <Row>
                    <span className="fw-bold px-0 pb-1"> Collection:</span>
                  </Row>
                  <Row>
                    <Col className="propertiesWrap px-0">
                      <span>{listingStatus.itemCollection}</span>
                      <br />
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            "https://flowscan.org/contract/" +
                            itemType.replaceAll(".NFT", "")
                          }
                        >
                          {itemType.replaceAll(".NFT", "")}
                        </a>
                      </span>
                      <br />
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            "https://nft-catalog.vercel.app/catalog/" +
                            listingStatus.itemCollection
                          }
                        >
                          {listingStatus.itemCollection} @ Flow NFT Catalog
                        </a>
                      </span>
                      <br />
                      {itemCollectionDescription && (
                        <span>{itemCollectionDescription}</span>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } else {
    return <p>No properties</p>;
  }
};

export default ColDetailDetailsCatalog;
