import React from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import "./alphatestingsidenav.css";

const AlphaTestingSideNavMobile = () => {
  function CustomToggle({ children, eventKey, lease, manage }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <Button
        onClick={decoratedOnClick}
        style={{
          backgroundImage: "var(--accordion-chevron)",
          width: "26px",
          height: "26px",
          backgroundColor: "var(--background)",
          borderColor: "var(--background)",
          boxShadow: "none",
        }}
      ></Button>
    );
  }

  return (
    <Container
      id="testing-side-nav-mobile-container"
      className="mb-3 px-0 align-items-center justify-content-center"
    >
      <Card id="testing-side-nav-mobile-card" className="my-2">
        <Card.Body>
          <Accordion defaultActiveKey={0}>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                    <a href="#testnet-getting-started">
                      <h5>Getting Started</h5>
                    </a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={0} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={0}>
                  <ul>
                    <li>
                      <a href="#testnet-funding">Funding Testnet Wallet</a>
                    </li>
                    <li>
                      <a href="#testnet-faucet">Flow Testnet Faucet</a>
                    </li>
                    <li>
                      <a href="#testnet-swap">Testnet FUSD and Bloctoswap</a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                    <a href="#testnet-mint-bl0x">
                      <h5>Testnet Bl0x NFTs</h5>
                    </a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={1} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={1}>
                  <ul>
                    <li>
                      <a href="#testnet-whitelist-bl0x">
                        Bl0x Wallet Whitelisting
                      </a>
                    </li>
                    <li>
                      <a href="#testnet-init-bl0x">
                        Initialising Bl0x Collection
                      </a>
                    </li>
                    <li>
                      <a href="#testnet-packs-bl0x">Minting Bl0x Packs</a>
                    </li>
                    <li>
                      <a href="#testnet-open-bl0x">Opening Bl0x Packs</a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                    <a href="#testnet-mint-flovatar">
                      <h5>Testnet Flovatar NFTs</h5>
                    </a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={2} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={2}>
                  <ul>
                    <li>
                      <a href="#testnet-init-flovatar">
                        Initialising Flovatar Collection
                      </a>
                    </li>
                    <li>
                      <a href="#testnet-packs-flovatar">
                        Purchasing Flovatar Packs
                      </a>
                    </li>
                    <li>
                      <a href="#testnet-build-flovatar">
                        Building Your Flovatar NFT
                      </a>
                    </li>
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <Row>
                  <Col xs="10">
                    <a href="#testnet-dandies">
                      <h5>More Testnet NFTs</h5>
                    </a>{" "}
                  </Col>
                  <Col xs="2">
                    <CustomToggle eventKey={3} />
                  </Col>
                </Row>
                <Accordion.Collapse eventKey={3}>
                  <ul>
                    <li>
                      <a href="#testnet-dandies">
                      Dandy Variety NFTs
                      </a>
                    </li>
                 
                  </ul>
                </Accordion.Collapse>
              </Col>
            </Row>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AlphaTestingSideNavMobile;
