import React from "react";
import { Card} from "react-bootstrap";
import CatalogDetailsPropertyCard from "./CatalogDetailsPropertyCard";
import CatalogDetailsPropertyCardMarket from "./CatalogDetailsPropertyCardMarket";
import "./detailspropertycard.css";
import NameDetailsPropertyCard from "./NameDetailsPropertyCard";
import ShardDetailsPropertyCard from "./ShardDetailsPropertyCard";

const DetailsPropertyCard = ({ listingDetails, nftDetails, listingStatus }) => {
  // console.log(listingDetails);
  // console.log(listingStatus);
  // console.log(nftDetails);

  return (
    <div>
        {listingStatus.aCatalogItem && listingStatus.aMarketplaceItem ? (
          <CatalogDetailsPropertyCardMarket listingStatus={listingStatus} />
        ) : listingStatus.isName ? (
          <NameDetailsPropertyCard listingStatus={listingStatus} />
        ) : listingStatus.aCatalogItem ? (
          <CatalogDetailsPropertyCard listingStatus={listingStatus} />
        ) : !listingStatus.aCatalogItem ? (
          <ShardDetailsPropertyCard
            listingDetails={listingDetails}
            listingStatus={listingStatus}
            nftDetails={nftDetails}
          />
        ) : (
          <div>lolwat</div>
        )}
   </div>
  );

  // //catalog item
  // if (
  //   listingDetails &&
  //   listingDetails.nftDetail &&
  //   listingStatus.aCatalogItem
  // ) {
  //   listingDetails = listingDetails.nftDetail;
  //   console.log(listingDetails);
  //   console.log(listingDetails.tags);
  //   return (
  //     <Card id="nft-detail-details-card" className="nft-details-card">
  //       <Card.Body>
  //         {/* {Object.keys(listingDetails).map((properties, i) => {
  //           if (listingDetails[properties]) {
  //             return (DaysOnFlow/83
  //         })} */}
  //         <Row>
  //           <Col>
  //             <Row className="py-2 ">
  //               <Col xs="12" md="3">
  //                 <h5>Name:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">
  //                 {listingDetails.name ? (
  //                   listingDetails.name
  //                 ) : (
  //                   <span>
  //                     {listingDetails.collectionName}
  //                     {listingDetails.id}
  //                   </span>
  //                 )}
  //               </Col>
  //             </Row>
  //             <Row className="py-2">
  //               <Col xs="12" md="3">
  //                 <h5>Collection:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">
  //                 {listingDetails.collectionName}
  //               </Col>
  //             </Row>
  //             {listingDetails.rarity && (
  //               <Row className="py-2">
  //                 <Col xs="12" md="3">
  //                   <h5>Rarity:</h5>
  //                 </Col>
  //                 <Col className="propertiesWrap">{listingDetails.rarity}</Col>
  //               </Row>
  //             )}
  //             <Row className="py-2">
  //               <Col xs="12" md="3">
  //                 <h5>Media:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">
  //                 <b>Thumbnail:</b>{" "}
  //                 <a
  //                   target="_blank"
  //                   rel="noreferrer"
  //                   href={listingDetails.thumbnail.replace(
  //                     "ipfs://",
  //                     "https://find.mypinata.cloud/ipfs/"
  //                   )}
  //                 >
  //                   {listingDetails.thumbnail.replace(
  //                     "ipfs://",
  //                     "https://find.mypinata.cloud/ipfs/"
  //                   )}
  //                 </a>
  //                 {Object.keys(listingDetails.media).map((properties, i) => {
  //                   if (listingDetails?.media[properties]) {
  //                     return (
  //                       <div key={i}>
  //                         <b>{listingDetails.media[properties] + ": "}</b>
  //                         <a
  //                           target="_blank"
  //                           rel="noreferrer"
  //                           href={properties.replace(
  //                             "ipfs://",
  //                             "https://find.mypinata.cloud/ipfs/"
  //                           )}
  //                         >
  //                           {properties.replace(
  //                             "ipfs://",
  //                             "https://find.mypinata.cloud/ipfs/"
  //                           )}
  //                         </a>
  //                       </div>
  //                     );
  //                   }
  //                 })}
  //               </Col>
  //             </Row>
  //             {Object.keys(listingDetails.scalars) && Object.keys(listingDetails.scalars).length > 0 && (
  //               <Row className="py-2">
  //                 <Col xs="12" md="3">
  //                   <h5>Scalars:</h5>
  //                 </Col>
  //                 <Col className="propertiesWrap">
  //                   {Object.keys(listingDetails.scalars).map(
  //                     (properties, i) => {
  //                       console.log(listingDetails);
  //                       return (
  //                         <div key={i}>
  //                           <span className="fw-bold">
  //                             {properties.replaceAll("_", " ")}:{" "}
  //                           </span>

  //                           {listingDetails.scalars[properties] * 1}
  //                         </div>
  //                       );
  //                     }
  //                   )}
  //                 </Col>
  //               </Row>
  //             )}

  //             {Object.keys(listingDetails.tags) && Object.keys(listingDetails.tags).length > 0 && (
  //               <Row className="py-2">
  //                 <Col xs="12" md="3">
  //                   <h5>Tags:</h5>
  //                 </Col>
  //                 <Col className="propertiesWrap">
  //                   {Object.keys(listingDetails.tags).map((properties, i) => {
  //                     // console.log(listingDetails);
  //                     return (
  //                       <div key={i}>
  //                         <span className="fw-bold">
  //                           {properties.replaceAll("_", " ")}:{" "}
  //                         </span>
  //                         {listingDetails.tags[properties]}
  //                       </div>
  //                     );
  //                   })}
  //                 </Col>
  //               </Row>
  //             )}
  //             {listingStatus.itemRoyalties.Sale[0] && (
  //               <Row className="py-2">
  //                 <Col xs="12" md="3">
  //                   <h5>Royalties:</h5>
  //                 </Col>
  //                 <Col className="propertiesWrap">
  //                   <Row>
  //                     {Object.keys(listingStatus.itemRoyalties.Sale).map(
  //                       (properties, i) => {
  //                         return (
  //                           <Col
  //                             key={i}
  //                             xs="auto"
  //                             className="propertiesRoyalty m-1"
  //                           >
  //                             <div>
  //                               <span className="fw-bold">Royalty Name:</span>{" "}
  //                               {
  //                                 listingStatus.itemRoyalties.Sale[properties]
  //                                   .royaltyName
  //                               }
  //                             </div>
  //                             <div>
  //                               <span className="fw-bold">Paid to:</span>{" "}
  //                               {listingStatus.itemRoyalties.Sale[properties]
  //                                 .findName ? (
  //                                 <Link
  //                                   style={{ textDecoration: "none" }}
  //                                   to={
  //                                     "/" +
  //                                     listingStatus.itemRoyalties.Sale[
  //                                       properties
  //                                     ].findName
  //                                   }
  //                                 >
  //                                   <span className="fw-bold">
  //                                     <span className="themeColor">
  //                                       {
  //                                         listingStatus.itemRoyalties.Sale[
  //                                           properties
  //                                         ].findName
  //                                       }
  //                                     </span>
  //                                     .find
  //                                   </span>
  //                                 </Link>
  //                               ) : (
  //                                 listingStatus.itemRoyalties.Sale[properties]
  //                                   .address
  //                               )}
  //                             </div>
  //                             <div>
  //                               <span className="fw-bold">Cut percentage:</span>{" "}
  //                               {parseFloat(
  //                                 listingStatus.itemRoyalties.Sale[properties]
  //                                   .cut
  //                               ).toFixed(2) * 100}
  //                               %
  //                             </div>
  //                           </Col>
  //                         );
  //                       }
  //                     )}
  //                   </Row>
  //                 </Col>
  //               </Row>
  //             )}
  //             {/* {listingDetails.nftDetail.rarity && (
  //           <Row className="py-2">
  //             <Col xs="12" md="3">
  //               <h5>Traits:</h5>
  //             </Col>
  //             <Col className="propertiesWrap">
  //               {" "}
  //               {Object.keys(listingDetails.nftDetail.tags).map(
  //                 (properties, i) => {
  //                   if (listingDetails.nftDetail?.tags[properties]) {
  //                     return (
  //                       <div key={i}>
  //                         <b>{properties.replaceAll("-", " ") + ": "}</b>

  //                         {listingDetails.nftDetail.tags[properties]}
  //                       </div>
  //                     );
  //                   }
  //                 }
  //               )}
  //             </Col>
  //           </Row>
  //         )} */}
  //             <Row className="py-2">
  //               <Col xs="12" md="3">
  //                 <h5>UUID:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">{listingDetails.uuid}</Col>
  //             </Row>
  //           </Col>
  //           {/* <Col md="4"></Col> */}
  //         </Row>
  //       </Card.Body>
  //     </Card>
  //   );
  // }
  // //not catalog item
  // else if (listingDetails && !listingStatus.aCatalogItem) {
  //   console.log(listingDetails);
  //   return (
  //     <Card id="nft-detail-details-card" className="nft-details-card">
  //       <Card.Body>
  //       <Row className="py-2 ">
  //               <Col xs="12" md="3">
  //                 <h5>Name:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">
  //                 {listingDetails.title ? (
  //                   listingDetails.title
  //                 ) : (
  //                   <span>
  //                     {listingDetails.collectionName}
  //                     {listingDetails.id}
  //                   </span>
  //                 )}
  //               </Col>
  //             </Row>
  //             <Row className="py-2">
  //               <Col xs="12" md="3">
  //                 <h5>Media:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">

  //                 {/* {Object.keys(listingDetails?.media).map((properties, i) => {
  //                   if (listingDetails?.media[properties]) {
  //                     return (
  //                       <div key={i}>
  //                         <b>{listingDetails.media[properties] + ": "}</b>

  //                       </div>
  //                     );
  //                   }
  //                 })} */}
  //               </Col>
  //             </Row>
  //             <Row className="py-2 ">
  //               <Col xs="12" md="3">
  //                 <h5>Contract:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">
  //                 {listingDetails.title ? (
  //                   listingDetails.title
  //                 ) : (
  //                   <span>
  //                     {listingDetails.collectionName}
  //                     {listingDetails.id}
  //                   </span>
  //                 )}
  //               </Col>
  //             </Row>
  //             <Row className="py-2 ">
  //               <Col xs="12" md="3">
  //                 <h5>Metadata:</h5>
  //               </Col>
  //               <Col className="propertiesWrap">
  //                 {listingDetails.title ? (
  //                   listingDetails.title
  //                 ) : (
  //                   <span>
  //                     {listingDetails.collectionName}
  //                     {listingDetails.id}
  //                   </span>
  //                 )}
  //               </Col>
  //             </Row>
  //         {" "}
  //         {Object.keys(listingDetails).map((properties, i) => {
  //           if (listingDetails[properties]) {
  //             return (
  //               <div key={i}>
  //                 {properties + ": " + listingDetails[properties]}
  //               </div>
  //             );
  //           }
  //         })}

  //       </Card.Body>
  //     </Card>
  //   );
  // } else {
  //   return (
  //     <Card id="nft-detail-details-card" className="nft-details-card">
  //       <Card.Body>No properties available</Card.Body>
  //     </Card>
  //   );
  // }
};
//     return (
//   <Card id="nft-detail-details-card" className="nft-details-card">
//     <Card.Body>
//       {/* {Object.keys(listingDetails.nftDetail).map((properties, i) => {
//   if (listingDetails.nftDetail[properties]) {
//     return (
//       <div key={i}>
//         {properties + ": " + listingDetails.nftDetail[properties]}
//       </div>
//     );
//   }
// })} */}
//       <Row>
//         <Col>
//           <Row className="py-2 ">
//             <Col xs="12" md="3">
//               <h5>Name:</h5>
//             </Col>
//             <Col className="propertiesWrap">
//               {" "}
//               {listingDetails.nftDetail.name ? (
//                 listingDetails.nftDetail.name
//               ) : (
//                 <span>
//                   {listingDetails.nftDetail.collectionName}{" "}
//                   {listingDetails.nftDetail.id}{" "}
//                 </span>
//               )}
//             </Col>
//           </Row>
//           <Row className="py-2">
//             <Col xs="12" md="3">
//               <h5>Collection:</h5>
//             </Col>
//             <Col className="propertiesWrap">
//               {listingDetails.nftDetail.collectionName}
//             </Col>
//           </Row>
//           {listingDetails.nftDetail.rarity && (
//             <Row className="py-2">
//               <Col xs="12" md="3">
//                 <h5>Rarity:</h5>
//               </Col>
//               <Col className="propertiesWrap">
//                 {listingDetails.nftDetail.rarity}
//               </Col>
//             </Row>
//           )}
//           <Row className="py-2">
//             <Col xs="12" md="3">
//               <h5>Media:</h5>
//             </Col>
//             <Col className="propertiesWrap">
//               <b>Thumbnail:</b>{" "}
//               <a
//                 target="_blank"
//                 rel="noreferrer"
//                 href={listingDetails.nftDetail.thumbnail.replace(
//                   "ipfs://",
//                   "https://find.mypinata.cloud/ipfs/"
//                 )}
//               >
//                 {listingDetails.nftDetail.thumbnail.replace(
//                   "ipfs://",
//                   "https://find.mypinata.cloud/ipfs/"
//                 )}
//               </a>
//               {Object.keys(listingDetails.nftDetail.media).map(
//                 (properties, i) => {
//                   if (listingDetails.nftDetail?.media[properties]) {
//                     return (
//                       <div key={i}>
//                         <b>
//                           {listingDetails.nftDetail.media[properties] +
//                             ": "}
//                         </b>
//                         <a
//                           target="_blank"
//                           rel="noreferrer"
//                           href={properties.replace(
//                             "ipfs://",
//                             "https://find.mypinata.cloud/ipfs/"
//                           )}
//                         >
//                           {properties.replace(
//                             "ipfs://",
//                             "https://find.mypinata.cloud/ipfs/"
//                           )}
//                         </a>
//                       </div>
//                     );
//                   }
//                 }
//               )}
//             </Col>
//           </Row>
//           {/* {listingDetails.nftDetail.scalars.length > 0 && (
//             <Row className="py-2">
//               <Col xs="12" md="3">
//                 <h5>Numbers:</h5>
//               </Col>
//               <Col className="propertiesWrap">
//                 {Object.keys(listingDetails.nftDetail.scalars).map(
//                   (properties, i) => {
//                     if (listingDetails.nftDetail?.scalars[properties]) {
//                       return (
//                         <div key={i}>
//                           {properties.replaceAll("_", " ") +
//                             ": " +
//                             parseFloat(
//                               listingDetails.nftDetail.scalars[properties]
//                             ).toFixed(0)}
//                         </div>
//                       );
//                     }
//                   }
//                 )}
//               </Col>
//             </Row>
//           )} */}
//           {/* {listingDetails.nftDetail.royalties.length > 0 && (
//             <Row className="py-2">
//               <Col xs="12" md="3">
//                 <h5>Royalties:</h5>
//               </Col>
//               <Col className="propertiesWrap">
//                 <Row>
//                   {listingDetails.nftDetail.royalties.map(
//                     (properties, i) => {
//                       return (
//                         <Col
//                           key={i}
//                           xs="auto"
//                           className="propertiesRoyalty"
//                         >
//                           <div>
//                             <span className="fw-bold">Royalty Name:</span>{" "}
//                             {properties.royaltyName}
//                           </div>
//                           <div>
//                             <span className="fw-bold">Paid to:</span>{" "}
//                             {properties.findName ? (
//                               <Link
//                                 style={{ textDecoration: "none" }}
//                                 to={"/" + properties.findName}
//                               >
//                                 <span className="fw-bold">
//                                   <span className="themeColor">
//                                     {properties.findName}
//                                   </span>
//                                   .find
//                                 </span>
//                               </Link>
//                             ) : (
//                               properties.address
//                             )}
//                           </div>
//                           <div>
//                             <span className="fw-bold">Cut percentage:</span>{" "}
//                             {parseFloat(properties.cut).toFixed(2) * 100}%
//                           </div>
//                         </Col>
//                       );
//                     }
//                   )}
//                 </Row>
//               </Col>
//             </Row>
//           )} */}
//           {/* {listingDetails.nftDetail.rarity && (
//             <Row className="py-2">
//               <Col xs="12" md="3">
//                 <h5>Traits:</h5>
//               </Col>
//               <Col className="propertiesWrap">
//                 {" "}
//                 {Object.keys(listingDetails.nftDetail.tags).map(
//                   (properties, i) => {
//                     if (listingDetails.nftDetail?.tags[properties]) {
//                       return (
//                         <div key={i}>
//                           <b>{properties.replaceAll("-", " ") + ": "}</b>

//                           {listingDetails.nftDetail.tags[properties]}
//                         </div>
//                       );
//                     }
//                   }
//                 )}
//               </Col>
//             </Row>
//           )} */}
//           <Row className="py-2">
//             <Col xs="12" md="3">
//               <h5>UUID:</h5>
//             </Col>
//             <Col className="propertiesWrap">
//               {listingDetails.nftDetail.uuid}
//             </Col>
//           </Row>
//         </Col>
//         {/* <Col md="4"></Col> */}
//       </Row>
//     </Card.Body>
//   </Card>
//     );
//   } else if (listingDetails && !listingDetails.nftDetail && !listingStatus.aCatalogItem) {
//     return (
//       <Card id="nft-detail-details-card" className="nft-details-card">
//         <Card.Body>No properties available</Card.Body>
//       </Card>
//     );
//   } else return
//     (
//       <Card id="nft-detail-details-card" className="nft-details-card">
//       <Card.Body>No properties available</Card.Body>
//     </Card>
//     )

// };

export default DetailsPropertyCard;
