import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import {
  handleCancelNFTAuction,
  handleCancelNFTSale,
  handleFulfillMarketAuctionEscrowedNFT,
} from "../../../../functions/flow-interactions/transactions";
import ReactTimeago from "react-timeago";

import "../../userstore.css";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../../functions/parseImgUrl";
import { useWalletType } from "../../../../functions/useWalletType";

const StoreNftListings = ({ items, filterTerm }) => {
  const [filteredSaleNfts, setFilteredSaleNfts] = useState();
  const [filteredAuctionNfts, setFilteredAuctionNfts] = useState();
  const wallet = useWalletType();

  useEffect(() => {
    if (items.FindMarketSale) {
      setFilteredSaleNfts(
        items.FindMarketSale.items.filter(
          (item) =>
            item.nft.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
            item.nft.collectionName
              .toLowerCase()
              .includes(filterTerm.toLowerCase())
        )
      );
    } else {
      setFilteredSaleNfts();
    }
  }, [items, filterTerm]);

  useEffect(() => {
    if (items.FindMarketAuctionEscrow) {
      setFilteredAuctionNfts(
        items.FindMarketAuctionEscrow.items.filter(
          (item) =>
            item.nft.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
            item.nft.collectionName
              .toLowerCase()
              .includes(filterTerm.toLowerCase())
        )
      );
    } else {
      setFilteredAuctionNfts();
    }
  }, [items, filterTerm]);

  return (
    <Container fluid={+true} id="store-nft-listings" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row>
            <Col align="left">
              <h4>NFTs you have listed</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <p>
              <b>For sale:</b>
            </p>
          </Row>
          <table className="store-table" id="store-nfts-for-auction-table">
            <tbody>
              {filteredSaleNfts && filteredSaleNfts.length > 0 ? (
                filteredSaleNfts.map((item, i) => {
                  // console.log(item);
                  let imgUrl = "";
                  let nftName;
                  let ft = item.ftAlias.replace("DUC", "$").replace("FUT", "Flow") 
                  item.nft.name
                    ? (nftName = item.nft.name)
                    : (nftName = item.nft.collectionName + " #" + item.nft.id);
                  if (item.nft.thumbnail) {
                    if (item.nft.thumbnail.includes("ipfs://")) {
                      imgUrl = item.nft.thumbnail.replace(
                        "ipfs://",
                        "https://find.mypinata.cloud/ipfs/"
                      );
                    } else {
                      imgUrl = item.nft.thumbnail;
                    }
                  }
                  const NftThumb = (
                    <Image
                    crossOrigin="anonymous"
                      className="store-thumb-image"
                      fluid={+true}
                      src={parseImgUrl(imgUrl, 'icon')}
                    />
                  );

                  return (
                    <React.Fragment key={i + "store-nfts-for-sale-row"}>
                      <tr
                        id={i + "store-nfts-for-sale-row"}
                        className="store-row"
                      >
                        <td width="50" className="store-thumb-col">
                          {NftThumb}
                        </td>
                        <td className="store-name-col">
                          <Link
                            to={
                              "/mp/" +
                              item.nft.collectionName +
                              "/" +
                              item.listingId
                            }
                          >
                            <span className="fw-bold">
                              <span>{nftName}</span>
                            </span>
                          </Link>
                        </td>
                        <td className="store-sticker-col">
                          {item.bidder && (
                            <span className="store-sticker">
                              Offer received
                            </span>
                          )}
                        </td>
                        <td className="store-price-col">
                          <div className="d-none d-lg-block">
                            List price:{" "}
                            <b>
                              {parseFloat(item.amount).toFixed(2)}{" "}
                              {ft}
                            </b>
                          </div>
                          <div className="d-block d-lg-none">
                            <b>
                              {parseFloat(item.amount).toFixed(2)}{" "}
                              {ft}
                            </b>{" "}
                          </div>
                        </td>
                        <td className="store-time-left-col"></td>

                        <td className="store-buttons-col">
                          <Button
                            onClick={() => handleCancelNFTSale(item.listingId, wallet)}
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                      <tr className="mobile-row">
                        <td className="mobile-store-sticker-col">
                          {item.latestBid && (
                            <span className="store-sticker">Offer </span>
                          )}
                        </td>
                        <td className="mobile-store-time-left-col"></td>
                        <td align="end">
                          <Button
                            onClick={() => handleCancelNFTSale(item.listingId)}
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <small>No NFTs currently listed for sale</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="seperator100 my-4"></div>

          <Row>
            <p>
              <b>For auction:</b>
            </p>
          </Row>
          <table className="store-table" id="store-nfts-for-auction-table">
            <tbody>
              {filteredAuctionNfts && filteredAuctionNfts.length > 0 ? (
                filteredAuctionNfts.map((item, i) => {
                  //console.log(item)

                  let imgUrl = "";
                  let nftName;
                  item.nft.name
                    ? (nftName = item.nft.name)
                    : (nftName = item.nft.collectionName + " #" + item.nft.id);
                  let ended;
                  if (
                    item.auction.auctionEndsAt &&
                    item.auction.auctionEndsAt < item.auction.timestamp
                  ) {
                    ended = true;
                  } else {
                    ended = false;
                  }

                  if (item.nft.thumbnail) {
                    if (item.nft.thumbnail.includes("ipfs://")) {
                      imgUrl = item.nft.thumbnail.replace(
                        "ipfs://",
                        "https://find.mypinata.cloud/ipfs/"
                      );
                    } else {
                      imgUrl = item.nft.thumbnail;
                    }
                  }
                  const NftThumb = (
                    <Image
                    crossOrigin="anonymous"

                      className="store-thumb-image"
                      fluid={+true}
                      src={parseImgUrl(imgUrl, 'icon')}
                    />
                  );

                  return (
                    <React.Fragment key={i + "store-nfts-for-sale-row"}>
                      <tr
                        id={i + "store-nfts-for-sale-row"}
                        className="store-row"
                      >
                        <td className="store-thumb-col">{NftThumb}</td>
                        <td className="store-name-col">
                          <Link
                            to={
                              "/mp/" +
                              item.nft.collectionName +
                              "/" +
                              item.nft.id
                            }
                          >
                            <span className="fw-bold">
                              <span>{nftName}</span>
                            </span>
                          </Link>
                        </td>
                        <td className="store-sticker-col">
                          {item.bidder && (
                            <span className="store-sticker">New bids</span>
                          )}
                        </td>
                        <td className="store-price-col">
                          <div className="d-none d-lg-block">
                            {item.bidder
                              ? "Current bid:"
                              : ended &&
                                item.auction.currentPrice >=
                                  item.auction.reservePrice
                              ? "Winning bid:"
                              : "Starting bid:"}{" "}
                            <b>
                              {parseFloat(item.auction.currentPrice).toFixed(2)}{" "}
                              {item.ftAlias}
                            </b>
                          </div>
                          <div className="d-block d-lg-none">
                            <b>
                              {parseFloat(item.auction.currentPrice).toFixed(2)}{" "}
                              {item.ftAlias}
                            </b>
                          </div>
                        </td>
                        <td className="store-time-left-col">
                          {item.auction.auctionEndsAt ? (
                            <span className="store-sticker">
                              {!ended ? "Ends: " : "Ended: "}
                              <ReactTimeago
                                date={
                                  new Date(item.auction.auctionEndsAt * 1000)
                                }
                                timestyle="twitter"
                              />
                            </span>
                          ) : (
                            <span className="store-sticker">Begins on bid</span>
                          )}
                        </td>

                        <td className="store-buttons-col">
                          {!ended ? (
                            <Button
                              onClick={() =>
                                handleCancelNFTAuction(item.listingId)
                              }
                              variant="find-outline-commerce-red"
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              variant="find-outline-commerce"
                              onClick={() =>
                                handleFulfillMarketAuctionEscrowedNFT(
                                  item.nftId,
                                  item.seller
                                )
                              }
                            >
                              Fulfill
                            </Button>
                          )}
                        </td>
                      </tr>
                      <tr className="mobile-row">
                        <td className="mobile-store-sticker-col">
                          {item.bidder && (
                            <span className="store-sticker">Bids</span>
                          )}
                        </td>
                        <td className="mobile-store-time-left-col">
                          {item.auction.auctionEndsAt ? (
                            <span className="store-sticker">
                              {!ended ? "Ends: " : "Ended: "}
                              <ReactTimeago
                                date={
                                  new Date(item.auction.auctionEndsAt * 1000)
                                }
                                timestyle="twitter"
                              />
                            </span>
                          ) : (
                            <span className="store-sticker">Begins on bid</span>
                          )}
                        </td>
                        <td align="end">
                          {!ended ? (
                            <Button
                              onClick={() =>
                                handleCancelNFTAuction(item.listingId)
                              }
                              variant="find-outline-commerce-red"
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              className="w-100"
                              variant="find-outline-commerce"
                              onClick={() =>
                                handleFulfillMarketAuctionEscrowedNFT(
                                  item.nftId,
                                  item.seller
                                )
                              }
                            >
                              Fulfill
                            </Button>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <small>No NFTs currently listed for auction</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StoreNftListings;
