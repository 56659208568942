import * as Sentry from "@sentry/react";
import GeneralSolutionFallbackError from "./GeneralSolutionFallback";

export function reportGeneralSolution(context, data) {
  const { collection } = context;
  const { transfer, transferType } = data;
  const { hash } = transfer;

  const message = `General solution applied`;
  const extras = { context, hash, transferType };
  const error = new GeneralSolutionFallbackError(message, extras);

  Sentry.withScope((scope) => {
    scope.setExtras(extras);
    scope.setTag("nft-history", collection);
    scope.setFingerprint([collection, hash]);
    Sentry.captureException(error);
  });
}
