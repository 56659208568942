import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Script } from "../../../functions/script";
import CreateProfileContinue from "./CreateProfileContinue";
import CreateProfileFindNameInput from "./CreateProfileFindNameInput";
import CreateProfileFormDesc from "./CreateProfileFormDesc";
import CreateProfileFormHeader from "./CreateProfileFormHeader";
import CreateProfileFormInfo from "./CreateProfileFormInfo";
import "./createprofile.scss";

const CreateProfileRegisterFindName = ({
  formValues,
  setFormValues,
  validation,
  currentStage,
  setCurrentStage,
  walletType,
  user,
}) => {
  return (
    <div id="create-profile-form-wrapper">
      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormHeader currentStage={currentStage} />
        </Col>
      </Row>

      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormDesc currentStage={currentStage} />
        </Col>
      </Row>

      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFormInfo currentStage={currentStage} />
        </Col>
      </Row>

      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileFindNameInput
            formValues={formValues}
            setFormValues={setFormValues}
            validation={validation}
            walletType={walletType}
          />
        </Col>
      </Row>

      <div id='create-form-divider' className="seperator100 my-4 d-none d-lg-block"></div>

      <Row className="py-1 py-lg-2">
        <Col>
          <CreateProfileContinue
            currentStage={currentStage}
            setCurrentStage={setCurrentStage}
            user={user}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateProfileRegisterFindName;
