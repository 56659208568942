import React from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import {
  handleFulfillSale,
  handleRejectBlindBid,
} from "../../../functions/flow-interactions/transactions";
import { Link } from "react-router-dom";
// ****THIS COMPONENT WAS MADE BY EVAN MILES 28/01/2022****

export function BidsOffersReceived({ leases }) {
  let filteredLeases = leases.filter((lease) => lease.latestBid > 0);
  return (
    <Container
      fluid={+true}
      id="names-for-auction"
      className="px-0 fade-in-right mb-3"
    >
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <h4>Bids/offers received</h4>
            </Col>
          </Row>
          <table
            className="profile-table"
            id="profile-names-bids-received-table"
          >
            <tbody>
              {filteredLeases.length > 0 ? (
                filteredLeases.map((filteredLease, i) => {
                  // //console.log(filteredLease)
                  let offerType = "Bid";
                  if (!filteredLease.auctionStartPrice) {
                    offerType = "Offer";
                  }
                  return (
                    <React.Fragment>
                      <tr
                        key={i}
                        className="align-items-center mt-3 profile-names-table-names-row"
                      >
                        <td className="profile-names-table-names-col">
                          <Link to={"/mp/FIND/" + filteredLease.name}>
                            <span className="fw-bold">
                              <span className="themeColor">
                                {filteredLease.name}
                              </span>
                              .find
                            </span>
                          </Link>{" "}
                        </td>

                        <td className="profile-names-table-price-col">
                          {offerType === "Bid" ? "Latest Bid" : "Offer"}:{" "}
                          <b>
                            {parseFloat(filteredLease.latestBid).toFixed(2)}{" "}
                            FUSD
                          </b>
                        </td>
                        <td className="profile-names-table-sticker-col">
                          <span className="profile-sticker">
                            {offerType} from: {filteredLease.latestBidBy}
                          </span>
                        </td>

                        <td className="profile-names-table-button-col">
                          {offerType === "Offer" && (
                            <>
                              <Button
                                onClick={() =>
                                  handleRejectBlindBid(filteredLease.name)
                                }
                                variant="find-outline-commerce-red"
                              >
                                Reject
                              </Button>
                              <Button
                                onClick={() =>
                                  handleFulfillSale(filteredLease.name)
                                }
                                variant="find-outline-commerce"
                              >
                                Accept
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <tr className="profile-mobile-row">
                          <td className="mobile-store-sticker-col">
                            <span className="store-sticker">
                              {offerType} from: {filteredLease.latestBidBy}
                            </span>
                          </td>
                          <td align="end">
                            {offerType === "Offer" && (
                              <>
                                <Button
                                  onClick={() =>
                                    handleRejectBlindBid(filteredLease.name)
                                  }
                                  variant="find-outline-commerce-red"
                                >
                                  Reject
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleFulfillSale(filteredLease.name)
                                  }
                                  variant="find-outline-commerce"
                                >
                                  Accept
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <small>You haven't received any bids or offers</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </Container>
  );
}
