import * as tx from "../../const";

const platform = "Starly";
const eventTypes = {
  cardWithdraw: "A.5b82f21c0edf76e3.StarlyCard.Withdraw",
  stakedCardStaked: "A.5b82f21c0edf76e3.StakedStarlyCard.CardStaked",
  stakedCardDeposit: "A.5b82f21c0edf76e3.StakedStarlyCard.Deposit",
};
export default function StarlyPlugin(context, transfer, idRelated) {
  const { id } = context;
  const { events } = transfer;

  // First we check if basic StarlyCard was created and staked
  const withdrawEvent = events.byId[eventTypes.cardWithdraw];
  if (withdrawEvent) {
    if (withdrawEvent[0]?.args?.id === id) {
      const staked = events.byId[eventTypes.stakedCardStaked];
      const deposit = events.byId[eventTypes.stakedCardDeposit];
      const staker = transfer.signers[0];

      if (staked?.args?.id === deposit?.args?.id) {
        return {
          type: tx.STAKED,
          platform,
          staker,
        };
      }
    }
  }

  return null;
}
