import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import {
  handleCancelAuction,
  handleCancelNFTAuction,
  handleCancelNFTSale,
  handleWithdrawSale,
} from "../../../functions/flow-interactions/transactions";
import { useWalletType } from "../../../functions/useWalletType";

//mobile swipedown close
const config = {
  delta: 150, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: true,
  swipeDuration: 250,
};

export default function MpDetailRemoveListing(props) {
  const { isName, hit, listingDetails, themeStatus, listingStatus } =props;
  const { label = "Remove Listing" } = props

  const walletType = useWalletType();
  const [showRemoveListing, setShowRemoveListing] = useState(false);

  const handleRemoveListing = () => {
    if (listingStatus.isName) {
      if (listingStatus.forSale && !listingStatus.forAuction) {
        handleWithdrawSale(hit.nft_name, walletType);
      } else if (!listingStatus.forSale && listingStatus.forAuction) {
        handleCancelAuction(hit.nft_name);
      } else if (listingStatus.forSale && listingStatus.forAuction) {
        setShowRemoveListing(true);
      }
    } else if (!listingStatus.isName) {
      if (listingStatus.forSale && !listingStatus.forAuction) {
        handleCancelNFTSale(
          listingDetails.findMarket.FindMarketSale.listingId,
          walletType
        );
      } else if (!listingStatus.forSale && listingStatus.forAuction) {
        handleCancelNFTAuction(
          listingDetails.findMarket.FindMarketAuctionEscrow.listingId
        );
      } else if (listingStatus.forSale && listingStatus.forAuction) {
        setShowRemoveListing(true);
      }
    }
  };
  const handleCloseRemoveListing = () => {
    setShowRemoveListing(false);
  };

  const handlers = useSwipeable({
    ...config,
    onSwipedDown: handleCloseRemoveListing,
  });

  if (listingDetails) {
    return (
      <>
        {!listingStatus.auctionEnded && (
          <Button
            onClick={() => handleRemoveListing()}
            className="w-100"
            variant="find-outline-commerce-red"
            style={{ height: "52px" }}
          >
            {label}
          </Button>
        )}
        <Modal
          data-theme={themeStatus}
          show={showRemoveListing}
          onHide={handleCloseRemoveListing}
          centered
        >
          <div {...handlers}>
            <Modal.Header style={{ border: "none", paddingBottom: "0" }}>
              <Modal.Title>
                <h3 align="center" className="px-3">
                  Would you like to remove{" "}
                  {isName ? (
                    <span>
                      <span className="themeColor">{hit.nft_name}</span>
                      .find
                    </span>
                  ) : (
                    hit.nft_name
                  )}{" "}
                  from Sale or Auction?
                </h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="py-3 px-3">
                <Col align="center">
                  On selecting the sales type to delist from it will delisted
                  from that sales type only.
                  <br />
                  <br /> To also delist it from the other sales type click
                  'Remove Listing' again after the transaction has completed.
                </Col>
              </Row>
              <Row className="px-3 pt-4">
                <Col xs="12" md="6">
                  <Button
                    className="w-100"
                    variant="find-outline-commerce-red"
                    onClick={
                      isName
                        ? () => handleWithdrawSale(hit.nft_name, walletType)
                        : () =>
                            handleCancelNFTSale(
                              listingDetails.findMarket.FindMarketSale.listingId
                            )
                    }
                  >
                    <small> Sale</small>
                  </Button>
                </Col>
                <Col xs="12" md="6" className="mt-3 mt-md-0">
                  <Button
                    className="w-100"
                    variant="find-outline-commerce-red"
                    onClick={
                      isName
                        ? () => handleCancelAuction(hit.nft_name)
                        : () =>
                            handleCancelNFTAuction(
                              listingDetails.findMarket.FindMarketAuctionEscrow
                                .listingId
                            )
                    }
                  >
                    <small> Auction</small>
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  }

  return null
}
