import parseImgUrl from "./parseImgUrl";

const useOnboardStatus = (findUser, userPreferences) => {
  // console.log(findUser);
  // console.log(userPreferences);
  const currentTime = Math.floor(new Date().getTime() / 1000);
  let profileCreated = null;
  let findName = null;
  let findNameOptOut = null;
  let onboarded = null;
  let currentStage = null;
  let timePassedNamePrompt = null;
  let timePassedExplorePrompt = null;
  let onboardStatusRun = null;

  //profile status
  if (findUser) {
    if (findUser.profile) {
      profileCreated = true;
      if (findUser.leases.length > 0) {
        findName = true
      }
    }
  }
  //user pref status
  if (userPreferences) {
    if (userPreferences.findNameOptOut) {
      findNameOptOut = userPreferences.findNameOptOut;
      timePassedNamePrompt = true;
      // if (currentTime - findNameOptOut >= 2419200) {
      //   timePassedNamePrompt = true;
      // }
    }
    if (userPreferences.onboardComplete) {
      onboarded = userPreferences.onboardComplete;
      timePassedExplorePrompt = true;

      // if (currentTime - onboarded >= 2419200) {
      //   timePassedExplorePrompt = true;

      // }
    }
  
  }

  //current stage based on userpref + profile
  if (!profileCreated) {
    currentStage = 2;
  }
  if (
    profileCreated &&
    ((!findName && !findNameOptOut))
  ) {
    currentStage = 3;
  }
  if (
    profileCreated &&
    (findName || (findNameOptOut)) &&
    (!onboarded )
  ) {
    currentStage = 4;
  }

  if (userPreferences && findUser){
  onboardStatusRun = true;}

  return {
    profileCreated: profileCreated,
    findName: findName,
    findNameOptOut: findNameOptOut,
    onboarded: onboarded,
    currentStage: currentStage,
    timePassedNamePrompt: timePassedNamePrompt,
    timePassedExplorePrompt: timePassedExplorePrompt,
    onboardStatusRun: onboardStatusRun,
  };
};
export default useOnboardStatus;
