import RenderTrait from "./RenderTrait";
import { capitalize } from "./utils";

const sortByName = (a,b)=>{
  const aTag = a.shortName
  const bTag = b.shortName
  if(aTag > bTag){
    return 1
  }

  if(aTag < bTag){
    return -1
  }
  return 0
}

export default function RenderTree(props) {
  const { treeData } = props;
  const { singles, groups, group, level } = treeData;

  return (
    <div className={`group-container level-${level} group-${group}`}>
      {group && (
        <div className={`group-head group-head--level-${level}`}>
          {capitalize(group)}
        </div>
      )}
      {singles && (
        <div className={`singles-grid ${singles.length}-items`}>
          {singles.sort(sortByName).map((trait) => (
            <RenderTrait trait={trait} />
          ))}
        </div>
      )}
      {groups && groups.map((data) => <RenderTree treeData={data} />)}
    </div>
  );
}
