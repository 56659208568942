import React from "react";
import UpdateProfileWrapper from "../components/profile/UpdateProfile/UpdateProfileWrapper";
import { Helmet } from "react-helmet";
import { Container, Spinner } from "react-bootstrap";
import { AuthCluster } from "../auth-cluster";

const UpdateProfile = ({ findUser, metaTitle, metaDescription }) => {
  return (
    <div className="content-container-v3">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      {findUser ? (
        <UpdateProfileWrapper findUser={findUser} />
      ) : (
        <Container fluid={+true} className="py-5 my-5" align="center">
        <h2 className="mb-5">Please Log in</h2>
        <AuthCluster user={findUser} />
      </Container>
      )}
    </div>
  );
};

export default UpdateProfile;
