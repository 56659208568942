import React from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./helphubinfo.css";

const HelphubInfo1 = () => {
  return (
    <Container id="help-hub-info" fluid={+true} className="">
      <section id="getting-started">
        <h4>Getting started on .find</h4>
        <p>
          Logged out users can explore the .find marketplace and live feed - but
          to get the most out of .find you can create a profile and assign a
          .find name to it.
          <br /> This will give you somewhere to showcase your Flow NFTs and a
          way to interact with the platform. <br /> .find uses the Blocto Flow
          wallet to allow users to securely access and control their resources.{" "}
          <br /> If you have an existing Blocto wallet then you can use that
          with no issues, if not the next section will outline the simple
          process of setting one up.{" "}
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-blocto">
        <h4>Creating a Blocto wallet</h4>
        <p>
          Creating or using an existing Blocto wallet is a simple process that
          can be done right here on the .find site. <br /> All you will need is
          an email address you can securely access for verification processes.
          <br /> To begin the process, click the 'Connect Wallet' button in the
          .find navigation bar.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub1.png" />
        </div>
        <p className="caption" align="center">
          Begin by finding the Connect Wallet button.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-register-email">
        <h4>Registering Email</h4>
        <p>
          Next you will be asked for the email you wish to use. This email will
          be used for verification process so make sure you have secure access
          to it. <br /> If you have a Blocto wallet associated with that email
          address then you will be signed into that account, otherwise the email
          you enter will be used to register a new wallet.
          <br /> After registering and signing into your wallet via email
          verification a new '0x' wallet address will be generated for you, the
          address of which will be shown in the Connect Wallet button. <br />{" "}
          You are now able to send assets such as Flow, FUSD and NFTs to this
          address and if they are supported display and use them on the .find
          platform.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub2.png" />
        </div>
        <p className="caption" align="center">
          Blocto wallet register/sign in
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-create-profile">
        <h4>Creating a .find Profile</h4>
        <p>
          Congratulations! Your Blocto wallet should be registered and verified
          and now you are ready to create a brand new .find profile to showcase
          your assets and interact with the platform. <br /> When clicking the
          Connect Wallet button that now shows your wallet address you will be
          prompted to choose a name for your profile - go ahead and choose a
          name to create a profile. <br /> This profile and the information
          associated with it will be stored on the blockchain. <br /> NOTE: The
          name you choose here will be your profile nickname and not your main
          .find name - after creation you can assign as many .find names with it
          as you like and choose one of those as your main .find name.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub3.png" />
        </div>
        <p className="caption" align="center">
          Create a .find profile
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-create-profile-success">
        <h4>Successful Profile Creation</h4>
        <p>
          After your new profile has been registered on the Flow blockchain you
          will receive a notification telling you it has been successful. <br />{" "}
          This notification will also allow you to view the blockchain and
          smartcontract interaction that makes the transaction possible - a
          feature of every transaction on the platform.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub4.png" />
        </div>
        <p className="caption" align="center">
          Notification and blockchain viewer
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-new-profile">
        <h4>Your New .find Profile</h4>
        <p>
          Following a successful profile creation, next to the Connect Wallet
          button in the navigation bar you should see a new randomly assigned
          profile picture. <br /> Don't worry if it is not an accurate likeness,
          you will now be able to change it (to an avatar of your choosing or
          one of your NFTs) along with any other parts of your profile.
          <br />
          Click on this profile picture and then head to the dashboard where you
          will be taken to your shiny new .find profile.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub5.png" />
        </div>
        <p className="caption" align="center">
          Access your profile dashboard
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-profile-looking">
        <h4>Looking Around Your .find Profile</h4>
        <p>
          Congratulations on your shiny new profile! <br /> This is where you
          will be able to view your name and NFT collections, create and
          showcase albums and list items for sale or auction. <br /> From the
          Profile User Bar (PUB) to the left of the profile (note on mobile this
          dropdown from the top of the page) you will be able to access the many
          exciting features of the .find platform such as the Live Feed,
          Marketplace, your User Store where you can view the status and history
          of all the items you are buying and selling and The Forge where you
          can mint your own NFT collections.
          <br /> Now you have the opportunity to really make your profile your
          own - let's do so by following the 'Settings' tab in the PUB.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub6.png" />
        </div>
        <p className="caption" align="center">
          PUB tabs will allow you to view parts of the platform and customise
          your profile.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-profile-custom">
        <h4>Customising Your .find Profile</h4>
        <p>
          The .find platform gives users the opportunity to create a blockchain
          identity that represents them through a variety of methods including
          profile customisation. <br /> After following the 'Settings' link in
          the PUB you can customise your profile as you see fit with options
          such as your profile nickname, your bio/description, profile tags,
          avatars selected from your NFT collection or elsewhere via URL and the
          ability to add ways to connect with you such as your twitter handle or
          personal website.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub7.png" />
        </div>
        <p className="caption" align="center">
          Customise your new profile via the settings tab in the PUB
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-profile-funding">
        <h4>Funding Your .find Profile</h4>
        <p>
          If you are using a pre-existing Blocto wallet, any funds associated
          with that account will automatically be available for use upon .find
          profile creation. <br /> If you are using a newly registered Blocto
          wallet there are a few options for funding it. <br /> You can send
          Flow blockchain tokens and assets such as FUSD to your 0x wallet
          address. <br /> You can also use fiat currency on-ramp services such
          as <a href="https://www.moonpay.com/">Moonpay.</a>
          <br />
          (Note: We are currently exploring a number of other options in terms
          of on-ramp providers.)
          <br />
          You can seamlessly transfer any assets held by your wallet to other
          users by using their .find name or wallet address in conjunction with
          the profile Fund Widget.{" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub12.png" />
        </div>
        <p className="caption" align="center">
          Fund other .find profiles with ease via the profile Fund Widget
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-profile-name">
        <h4>A Name For Your .find Profile</h4>
        <p>
          One of the most useful features of the .find platform is the ability
          to register .find names and have these represent the user's profile
          and wallet address allowing for convenient transfer and exchange
          between wallets without having to remember an 18 digit 0x hex string.
          <br /> With a .find name you can easily share your customised profile
          and collections - if a user has a find name you can easily look up
          their collections, albums and names/NFTs they have for sale.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub8.png" />
        </div>
        <p className="caption" align="center">
          Manage your names and set a main name via your profile
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-name-reg">
        <h4>Registering a .find Name</h4>
        <p>
          Registering a .find name is a simple process that adds another layer
          of customisation and convenience to your profile. <br /> To do so, you
          can search for the name you wish to assign to your profile via the
          navigation bar search feature. If it is available you will see the
          pricing and be given an option to register that name via a blockchain
          transaction. <br /> Alternately, heading to
          find.xyz/&lt;yournewfindname&gt; will take you to a register name page
          if it is available (if not it will take you to the profile of the user
          who has that name where you can see if it is for sale or make an offer
          for it.){" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub9.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          Register a new .find name via the search box
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub10.png" />
        </div>
        <p className="caption" align="center">
          Alternatively, head to find.xyz/&lt;yournewfindname&gt; to see if your
          chosen name is available to register.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-name-lease">
        <h4>.find Name Lease and Cost</h4>
        <p>
          .find names are registered on a 365-day lease basis with the costs
          covered by stablecoin funds. <br /> Currently names can only be
          registered with the FUSD stablecoin but there are plans to implement
          more types of stablecoins in the future. <br /> Stablecoins are used
          to maintain a price that is not subject to potential fluctuations in
          Flow value.
        </p>
        <p>
          Lease costs are based on name length and the following price
          structure:
        </p>
        <ul>
          <li>
            5+ Characters: <b>5 FUSD</b>
          </li>
          <li>
            4 Characters: <b>100 FUSD</b>
          </li>
          <li>
            3 Characters: <b>500 FUSD</b>
          </li>
        </ul>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub11.png" />
        </div>
        <p className="caption" align="center">
          An example 365-day lease 4 character .find name available for
          registration.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-name-chars">
        <h4>What's in a .find Name?</h4>
        <p>
          A .find name is comprised of a minimum of 3 and a maximum of 15
          characters.
          <br /> The characters that can be used are A-Z and 0-9.
          <br /> It is not possible to use a Flow address as a .find name - so
          0xAdresses cannot be used.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub15.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          An example of a shortest possible .find name
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub14.png" />
        </div>
        <p className="caption" align="center">
          An example of a longest possible .find name
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-name-manage">
        <h4>Managing .find Names</h4>
        <p>
          Once a new name is registered you will be redirected to your profile
          where you can manage all of your .find names in a number of ways.
        </p>
        <p>
          You can interact with your .find names via your profile in the
          following ways:
        </p>
        <ul>
          <li>
            Make a .find name your main/default name (it will be associated with
            your activity and show up in places such as the Live Feed and the
            Marketplace)
          </li>
          <li>Extend the lease on a .find name for another year</li>
          <li>Transfer your .find name to another user free of charge</li>
          <li>
            List a .find name for sale or auction whereupon it will show up in
            the .find Marketplace and you will be able to track its listing
            status via the User Store (please see the Marketplace section for
            more details on this)
          </li>
        </ul>
        <p>
          Viewing the names tab on your profile will give you these management
          options as well as a way to view the names you have for sale, accept
          or reject offers and cancel or fulfill listings or auctions. It will
          also give details of names you are offering and bidding on. All of
          this information is available in the User Store (please see the User
          Store section for more details on this).
        </p>
        <p>
          Viewing .find names on another user's profile will give you a number
          of other options such as making a purchase offer, buying it for a
          direct listing price or viewing auction details.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub16.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          The names management panel
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub17.png" />
        </div>
        <p className="caption" align="center">
          Viewing your own profile names with listings and offers{" "}
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-profile-collections">
        <h4>Profile Collections</h4>
        <p>
          The profile collections tab is where you are able to view and interact
          with all of the supported NFT items associated with your .find profile
          or the profile you are viewing.{" "}
        </p>
        <p>From here you can:</p>
        <ul>
          <li>View all of your supported NFT items</li>
          <li>View items from specific NFT collections</li>
          <li>Create, view and showcase albums of multiple NFTs</li>
          <li>View an item's details and metadata and change your PFP</li>
          <li>List items for sale or auction on the .find marketplace</li>
          <li>
            Make offers, bids and direct purchases for items other users have
            listed
          </li>
        </ul>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub18.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          View collections and albums via the collections tab
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub19.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          Get NFT information, make PFP, list on .find market and make offers
          via the collection detail page (dark mode)
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub20.png" />
        </div>
        <p className="caption" align="center">
          Create and showcase albums of multiple NFTs (dark mode)
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-profile-collections-supported">
        <h4>Supported Collections</h4>
        {/* need a list/icon grid here really  */}
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-market">
        <h4>The Market Tab</h4>
        <p>
          The profile market tab gives a succinct overview of all of the items
          (names and NFTs) that a user currently has listed for sale. <br />{" "}
          Like the names and collections tabs, you can interact with these items
          with different options depending on if you are viewing your own or
          somebody else's profile. <br /> These options include making offers,
          bids, cancelling and fulfilling direct sales and viewing more
          information about the NFTs for sale via the .find marketplace.
          <br /> This tab is useful to get a brief summary of your own or
          other's listed items, though much more rich information regarding
          bids, offers, sales and history is available via the User Store.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub21.png" />
        </div>
        <p className="caption" align="center">
          View and interact with the items the user is selling via the profile
          market tab
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-listing">
        <h4>Listing Items</h4>
        <p>
          Names and items on your profile can be listed for direct sale or
          auction.
          <br /> Once listed they will be indexed to the .find Marketplace where
          they can be purchased or bid on depending on the type of sale.
          <br /> They can also be bid on or purchased by users viewing your
          profile. <br /> You can view the current status of the listing via the
          'Your Store' tab of the PUB.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-listing-names">
        <h4>Listing Names</h4>
        <p>
          To list a name, find the name you would like to list in the names tab
          of your dashboard and clicked the 'Sell Name' button. <br /> You can
          choose whether you wish to list the name for a direct sale price or
          for an auction where you choose the starting bid, the reserve price
          and the duration of the auction (see the auction rules section for the
          rules of the auction).
          <br />
          Once listed, you can view the status of the listing via the 'Your
          Store' tab of the PUB. <br /> Other users can then interact with the
          listing via the names tab of your profile or the marketplace.
          <br /> Currently names can only be listed for FUSD.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub22.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          To list a name, find the 'Sell Name' button next to the name you want
          to list
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub23.png" />
        </div>
        <p className="caption" align="center">
          You can then to choose to list the name for direct sale or auction
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-listing-nfts">
        <h4>Listing NFTs</h4>
        <p>
          To list an NFT for sale, find the NFT you would like to sell in the
          collections tab of your profile. <br /> Click through to the NFT
          details page and you will be given the option to list the NFT for a
          direct sale price or for an auction where you choose the start price,
          the reserve price and the auction duration. <br /> After listing an
          NFT, it will be indexed to the Marketplace where users can buy or bid
          on it. The listing can also be interacted with by a user viewing the
          NFT details on your profile collection tab.
          <br /> Currently NFTs can only be listed for Flow.{" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub24.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          To list a name, find the 'Sell Name' button next to the NFT you want
          to list
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub25.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          You will be given the option to list for a direct sale or auction
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-direct-listing">
        <h4>Direct Listings</h4>
        <p>
          When an item is listed for direct sale, a user viewing the item will
          be able to click the 'Buy Now' button next to the item and if they
          have sufficient funds in their wallet, start the transaction. On
          success the funds will be transferred to your profile and the item to
          theirs.
          <br /> While an item is listed for direct sale you can still receive
          and accept/reject offers on it, though accepting an offer will remove
          the item from direct sale and transfer it to the buyer.{" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub27.png" />
        </div>
        <p className="caption" align="center" style={{ marginBottom: "2rem" }}>
          Names listed for direct sale in the profile names tab
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub26.png" />
        </div>
        <p className="caption" align="center">
          Viewing details of a Marketplace NFT available to purchase via direct
          sale
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-auction-rules">
        <h4>Auction Rules</h4>
        <p>
          When an item is listed for auction the seller decides the auction
          start price, the reserve price and the auction duration. <br /> The
          listing will then be visible in the Marketplace and can be interacted
          with via both this listing and the user's profile. <br /> The auction
          timer begins on the first bid and if it finishes with the latest bid
          higher than the reserve price it can be fulfilled by either the buyer
          or the seller - this can be done via the 'Your Store' tab.
          <br /> Auctions are escrowed so that a buyer can make a bid only if
          they have sufficient funds to do so.
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub28.png" />
        </div>
        <p className="caption" style={{ marginBottom: "2rem" }} align="center">
          Viewing details of name auction listing via your own profile
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub29.png" />
        </div>
        <p className="caption" align="center">
          Viewing an auction listing with bids via the Marketplace
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-offering">
        <h4>Buying and Offering on Items </h4>
        <p>
          If an item is listed for direct sale or auction you can buy or bid on
          it via the Marketplace listing or the user's profile.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-buying-user-store">
        <h4>The User Store</h4>
        <p>
          The user store is where you can see a detailed summary of all of the
          items you are interacting with.
          <br /> From here you can view:{" "}
        </p>
        <ul>
          <li>Names and NFTs you have listed for sale</li>
          <li>
            Current offers, bids and auction details on the names and NFTs you
            have listed
          </li>
          <li>A summary of items that you have sold</li>
          <li>
            Current offers and bids that you are making on items and their
            status{" "}
          </li>
          <li>Items you have recently won </li>
        </ul>
        <p>
          From here you can interact with your activity in a number of ways such
          as cancelling listings, viewing auction details and fulfilling
          finished auctions and increasing your bids and offers. <br /> You can
          use the search filter to narrow down the items you are viewing.{" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub30.png" />
        </div>
        <p className="caption" align="center">
          The listings section of the user store
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-mp">
        <h4>The Marketplace</h4>
        <p>
          The Marketplace is where you can view all NFTs and names that have
          been listed for sale or auction. <br /> You can search and sort
          results via a variety of parameters
          <br /> You can interact with each listing via the details page in ways
          such as making bids on auction items and purchasing direct sale items.{" "}
          <br /> When you list a name of NFT for sale it will automatically be
          indexed to the Marketplace.{" "}
        </p>
        <div className="image-div">
          <Image fluid={+true} src="/assets/img/helphub/helphub31.png" />
        </div>
        <p className="caption" align="center">
          A variety of names and items listed for sale on the Marketplace
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-lf">
        <h4>The Live Feed</h4>
        <p>
          From the Live Feed you can view all of the activity happening on the
          .find platform. <br /> It will automatically update when new events
          occur. <br /> From here you can link through to the profiles involved
          with each activity and view listed auctions, sales and profile
          updates.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-forge">
        <h4>The Forge</h4>
        <p>
          Coming soon: The Forge allows you to mint your own NFT collections
          right here on the .find platform.
        </p>
        <div className="seperator100 my-4"></div>
      </section>

      <section id="help-terms">
        <h4>Terms of Use</h4>
        <p>
          You can .find our terms of usage at{" "}
          <Link to={"/p/terms"}>find.xyz/p/terms</Link> and the privacy policy
          at <Link to={"/p/privacy"}>find.xyz/p/privacy</Link>.
        </p>
        {/* Need some terms of use here */}
        <div className="seperator100 my-4"></div>
      </section>
    </Container>
  );
};

export default HelphubInfo1;
