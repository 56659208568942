import React from "react";
import "./bl0xloading.css";

const Bl0xLoading = () => {
  return (
    <div className="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Bl0xLoading;
