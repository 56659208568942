import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ListedBoth = () => {
  return (
    <div>
      <Card className="listedCard">
        <Card.Body>
          <Row>
            <Col>This NFT is listed for sale and auction.</Col>
          </Row>
          <Row>
            <Col>View it on the .find market:</Col>
          </Row>
          <Row className="mt-3">
            <Col align="center">
              <Link to={"/"}>
                <Button variant="find">.find market</Button>
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ListedBoth;
