import React from 'react'
import ColDetailDetailsCatalog from './ColDetailDetailsCatalog'
import ColDetailDetailsShard from './ColDetailDetailsShard'

const ColDetailDetails = ({listingStatus}) => {

    if (listingStatus.aCatalogItem){
  return (
    <ColDetailDetailsCatalog listingStatus={listingStatus}/>
  )} else return (
    <ColDetailDetailsShard listingStatus={listingStatus}/>
  )
}

export default ColDetailDetails