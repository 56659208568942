import { Button, CloseButton, Col, Row } from "react-bootstrap";
import {
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch-hooks-web";
import friendlicateCollection from "../../functions/friendlicateCollection";
import "./marketplace.css";

export default function CustomCurrentRefinements(props) {
  const {
    items,
    // canRefine,
    refine,
  } = useCurrentRefinements(props);

  function CustomClearRefinements(props) {
    const {
      //canRefine,
      refine,
    } = useClearRefinements(props);
    return (
      <Button variant="find-clear" onClick={() => refine(items)}>
        <CloseButton className="refinementsButtonClear" />
      </Button>
    );
  }

  return (
    <>
      {items && (
        <Row className="align-content-middle align-items-center px-2 mt-0">
          <Col className="mt-3" xs="auto">
            {items.length > 0 && <CustomClearRefinements />}
          </Col>
          {items.map((item, i) => {
            let itemAttribute = item.attribute
              .replace("string.", "")
              .replace("collection_name", "Collection")
              .replace("nft_alias", "Collection")
              .replace("listing_alias", "Listing Type")
              .replace("nft_rarity", "Rarity")
              .replace("collection_alias", "Collection")
              .replace("amount_alias", "Currency");
            return item.refinements.map((itemRefinements, i) => {
              let itemLabel = friendlicateCollection(itemRefinements.label);
              return (
                <Col
                  key={i}
                  xs="auto"
                  className="mt-3 mx-1 px-2 refinementsList"
                >
                  {itemAttribute[0].toUpperCase() +
                    itemAttribute.substring(1) +
                    " - " +
                    itemLabel
                      .replace("FlowToken", "Flow")
                      .replace("FIND", ".find")
                      .replace("EnglishAuction", "Auction")
                      .replace("DapperUtilityCoin", "$")
                      .replace("FlowUtilityToken", "Flow")}
                  <CloseButton
                    className="refinementsButton"
                    onClick={() => refine(itemRefinements)}
                  />
                </Col>
              );
            });
          })}
        </Row>
      )}
    </>
  );
}
