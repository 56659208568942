import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";


// Be sure to use an API key that only allows search operations
export const apiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
export const host = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;

export const port = 443;
export const protocol = "https";

// Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
const cacheTimeout = 2 * 60;

const adapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: apiKey,
    nodes: [{ host, port, protocol }],
    cacheSearchResultsForSeconds: cacheTimeout,
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "nft_name, nft_alias",
  },
});

export default adapter.searchClient;