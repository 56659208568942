import React from "react";
import { Link } from "react-router-dom";
import { handleUserHasSetPreferences } from "../../../firebaseConfig";
import "./createprofile.scss";

const CreateProfileExploreOptions = ({user}) => {

  const handleUserOnboardComplete = () => {
    handleUserHasSetPreferences(user.addr)
  }

  return (
    <div id="create-profile-expore-options-wrapper">
      {/* //card 1 */}
      <Link onClick={handleUserOnboardComplete} className="explore-link" to={"/me/settings"}>
        <div className="create-profile-explore-card">
          <div className="create-card-img-wrapper">
            <img src="assets/img/profile/createprofile/profile-setup-icon.svg" />
          </div>
          <div className="create-card-text-wrapper">
            <div className="create-card-upper-text">
              Complete the set up of your profile
            </div>
            <div className="create-card-lower-text">Finish profile set up</div>
          </div>
        </div>
      </Link>
      {/* card 2 */}
      {/* <Link onClick={handleUserOnboardComplete} className="explore-link" to={"/p/explore"}>
        <div className="create-profile-explore-card">
          <div className="create-card-img-wrapper">
            <img src="assets/img/profile/createprofile/explore-find-icon.svg" />
          </div>
          <div className="create-card-text-wrapper">
            <div className="create-card-upper-text">Explore .find </div>
            <div className="create-card-lower-text">
              Take our site tour and explore all of our features
            </div>
          </div>
        </div>
      </Link> */}
      {/* card 3 */}
      <Link onClick={handleUserOnboardComplete} className="explore-link" to={"/mp"}>
        <div className="create-profile-explore-card">
          <div className="create-card-img-wrapper">
            <img src="assets/img/profile/createprofile/explore-mp-icon.svg" />
          </div>
          <div className="create-card-text-wrapper">
            <div className="create-card-upper-text">
              Explore our marketplace
            </div>
            <div className="create-card-lower-text">
              View whats up for sale right now
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CreateProfileExploreOptions;
