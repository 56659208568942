import Generic from "../../Generic";
import * as render from "./components";
import classify from "./classifier";

export default function ({ context, transfer }) {
  const { collection, id: nftId } = context;
  const { events, script, hash } = transfer;

  // We can identify some transfers by their hash
  // prettier-ignore
  const switchboard = {
		"____________________NDIzNzM3MDM3": render.GenesisMint,
		"____________________NTkwNjE1NzA2": render.MarketplaceSale,
		"________________LTEwMjM3MDM5NTM=": render.MarketplaceSale,
		"________________MTI5MTY0NDY1NA==": render.MarketplaceSalePurchased,
		"________________MTExOTMwMTkxOQ==": render.MarketplaceSaleCanceled,
		"____________________MTM2NDM5MDEx": render.BackgroundAdded,
		"________________LTI0NTI4OTQwNg==": render.BackgroundAdded,
		"____________________ODY2NzM5NTU2": render.Removed,
		"________________MTI4NjkxNDgxMg==": render.Equipped,
		"________________MjA1MzEyODYzMg==": render.DirectTransfer,
		"________________LTIwMDkxNDg3ODU=": render.DirectTransfer,
		"________________LTEzNDE2MTI1Mjc=": render.FlovatarCreation,
		"____________________ODg1NDQ2NjIy": render.FlovatarPackOpened,
		"________________MTUxODM3ODIzMQ==": render.FlovatarPackOpened,
		"________________LTY4ODY2NDk5MQ==": render.FlovatarPackCreated,
	};

	const Render = switchboard[hash];
	if (Render) {
		return <Render context={context} transfer={transfer} />;
	}

	// identify transfer type
	const transferType = classify(context, transfer);

	// If we don't know what to do with this transfer, we shall fall back to generic rendering
	return null
}
