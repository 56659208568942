import React from "react";
import DropPageInfoSection from "./DropPageInfoSection";
import parseImgUrl from "../../functions/parseImgUrl";

const membershipTitle = "Your Membership!";
const membershipDescription = [
  `Owning just 1 of the current series will grant you VIP access to that party.`,
  `Owning all 3 of the Party Favorz in any series will grant you 1 airdropped Party Favorz from the next series (snapshot dates announced in our discord)`,
];
const membershipArtwork =
  "/assets/img/DropPage/drop-page-membership-artwork.jpg";

const partyKeyTitle = (
  <span>
    Party Vibes
    <br />
    .find the party!
  </span>
);
const partyKeyDescription = [
  "Party Favorz- Where we get down and party no matter the time, We love ripping bongs, shot gunning beers and lighting up. Dont worry, it’s not a crime. Party Favorz involve DJs/great music to chill to and celebrate. So many different flavors within the Favorz and the community of rock stars partying into the night. Let's get out there and party, instead of fight because Party Favors are the light. -Posiden",
];
const partyKeyArtwork = "/assets/img/DropPage/drop-page-key.jpg";

const lastPartyTitle = (
  <>
    Last Party
    <br />
    Collection!
  </>
);
const lastPartyDescription = [
  `The last party was with Dre to create an awesome collection & Party event!`,
];
const lastPartyArtwork = "https://find.mypinata.cloud/ipfs/QmSbEzsJzHftP9jYtwMkPU7qu9Jkv9aSvgPnLhmNFbfpmJ"

export default function DropPageInfo() {
  return (
    <div className="drop-page-info-wrapper">
      <div className="drop-page-info-container">
        <DropPageInfoSection
          sectionTitle={membershipTitle}
          sectionDescription={membershipDescription}
          sectionArtwork={membershipArtwork}
        />
        <DropPageInfoSection
          sectionTitle={partyKeyTitle}
          sectionDescription={partyKeyDescription}
          sectionArtwork={partyKeyArtwork}
        />
        <DropPageInfoSection
          sectionTitle={lastPartyTitle}
          sectionDescription={lastPartyDescription}
          sectionArtwork={lastPartyArtwork}
          noGlow
        />
      </div>
    </div>
  );
}
