import React, { useEffect, useState } from "react";
import "./LiveFeedWrapper.scss";

const ThoughtBoxUploadHosted = ({thoughtInput, setThoughtInput}) => {
  const [hostedMediaLink, setHostedMediaLink] = useState(null);
  const [hostedMediaType, setHostedMediaType] = useState(null);
  useEffect(() => {
    if (hostedMediaLink) {
      if (hostedMediaLink.includes("png")) {
        setHostedMediaType("image/png");
      } else if (hostedMediaLink.includes(".mp4")) {
        setHostedMediaType("video/mp4");
      }
    }
  }, [hostedMediaLink]);

  console.log(hostedMediaType);
  return (
    <div>
      Upload hosted media via HTML link
      {hostedMediaLink && (
        <>
          {hostedMediaType && hostedMediaType.includes("image") ? (
            <img
              style={{ width: "100%" }}
              src={hostedMediaLink}
              alt="The uploaded file"
            />
          ) : hostedMediaType && hostedMediaType.includes("video") ? (
            <video style={{ width: "100%" }} autoplay controls muted>
              <source src={hostedMediaLink} type={hostedMediaType} />
            </video>
          ) : null}
        </>
      )}
      <form action="">
        <input
          onChange={(e) => setHostedMediaLink(e.target.value)}
          type="text"
        />
        <input type="submit" />
      </form>
    </div>
  );
};

export default ThoughtBoxUploadHosted;
