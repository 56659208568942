import { Accordion } from "react-bootstrap";
import {
  TagFilter,
  TemplateFilter,
  PositionFilter,
  EditionFilter,
} from "./Filters";
import Search from "./Search";

import "./sidebar.scss";
import "./sidebar.media.scss";

export default function Sidebar(props) {
  const { toggle } = props;

  return (
    <div className="filter">
      <section className="filter__container">
        <header onClick={toggle}>
          Filter Dooperties
          <button className="filter__round-button filter__round-button--violet">
            X
          </button>
        </header>
        <div className="filter__content">
          <Search />
          <Accordion alwaysOpen>
            <TemplateFilter />
            <TagFilter />
            <PositionFilter />
            <EditionFilter />
          </Accordion>
        </div>
      </section>
    </div>
  );
}
