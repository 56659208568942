import { useEffect, useState } from "react";
import { Row } from "../components";
import "./index.scss";
export default function Increment(props) {
  const { onChange = () => {} } = props;
  const { initial = 1, max = 10 } = props;

  const [number, setNumber] = useState(initial);
  useEffect(() => onChange(number), [number]);
  function add() {
    if (number < max) {
      setNumber(number + 1);
    }
  }
  function reduce() {
    if (number > 1) {
      setNumber(number - 1);
    }
  }

  // keep it as strings, which can be later updated with internationalization
  const titleAdd = "add";
  const titleReduce = "reduce";

  return (
    <Row className={"dp__increment"}>
      <button onClick={reduce} title={titleReduce}>
        <img src={"/assets/icons/btn-icon-minus.png"} alt={"minus"} />
      </button>
      <span>{number}</span>
      <button onClick={add} title={titleAdd}>
        <img src={"/assets/icons/btn-icon-plus.png"} alt={"plus"} />
      </button>
    </Row>
  );
}
