import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./ColDetailOwnItemSendItem.scss";

const SendItemRoyaltiesForm = ({
  royalties,
  royaltyDonation,
  setRoyaltyDonation,
  findRoyaltyDonation,
  setFindRoyaltyDonation,
}) => {
  const [customValueRoy, setCustomValueRoy] = useState(null);
  const [customValueFind, setCustomValueFind] = useState(null);

  useEffect(() => {
    if (customValueRoy !== null && customValueRoy !== royaltyDonation.amount) {
      setRoyaltyDonation({ ...royaltyDonation, amount: customValueRoy });
    }
  }, [customValueRoy]);

  // console.log(royaltyDonation)

  // useEffect(() => {
  //   if (
  //     customValueFind !== null &&
  //     customValueFind !== findRoyaltyDonation.amount
  //   ) {
  //     setFindRoyaltyDonation({
  //       ...findRoyaltyDonation,
  //       amount: customValueFind,
  //     });
  //   }
  // }, [customValueFind]);
  return (
    <div id="send-item-confirm-royalties">
      <div id="send-item-confirm-royalties-title">Tip jar:</div>
      <div id="royalty-desc">
        This item has the following royalties associated with it.
        <br />
        Would you like to support the artist/platform?
      </div>
      <ul id="royalty-names">
        {Object.keys(royalties).map((royalty, i) => {
          return (
            <li className="royalty-name" key={i}>
              {royalties[royalty].description}
            </li>
          );
        })}{" "}
        <li className="royalty-name">Platform</li>
      </ul>
      <div>
        <form id="royalty-select-form">
          <div className="check-wrapper">
            <Form.Check
              type="radio"
              id="donation-none"
              // label="None (0 Flow)"
              name="donation"
              checked={royaltyDonation.amount === null}
              onChange={(e) =>
                setRoyaltyDonation({ ...royaltyDonation, amount: null })
              }
            />
            <Form.Label htmlFor="donation-none">None (0 Flow) </Form.Label>
          </div>
          <div className="check-wrapper">
            <Form.Check
              type="radio"
              id="donation-1"
              name="donation"
              value={Number(1.0)}
              onChange={(e) => {
                setRoyaltyDonation({
                  ...royaltyDonation,
                  amount: e.target.value,
                });
              }}
            />{" "}
            <Form.Label htmlFor="donation-1">1 Flow</Form.Label>
          </div>
          <div className="check-wrapper">
            <Form.Check
              type="radio"
              id="donation-5"
              name="donation"
              value={Number(5.0)}
              onChange={(e) => {
                setRoyaltyDonation({
                  ...royaltyDonation,
                  amount: e.target.value,
                });
              }}
            />{" "}
            <Form.Label htmlFor="donation-5">5 Flow</Form.Label>
          </div>
          <div className="check-wrapper" id="other-amount">
            <div id="other-inner">
              <Form.Check
                type="radio"
                id="donation-other"
                // label="Other"
                name="donation"
                checked={customValueRoy && (royaltyDonation.amount === customValueRoy)}
                onChange={(e) =>
                  setRoyaltyDonation({
                    ...royaltyDonation,
                    amount: customValueRoy,
                  })
                }
              />
              <Form.Label htmlFor="donation-other">Other</Form.Label>
            </div>
            <input
              value={customValueRoy}
              id="amount-input"
              onChange={(e) => setCustomValueRoy(e.target.value)}
            />{" "}
          </div>
        </form>
      </div>
      {/* <div>Platform</div>
      <div>
        <form>
          <Form.Check
            type="radio"
            id="find-donation-none"
            label="None"
            name="find-donation"
            value=""
            onChange={(e) =>
              setFindRoyaltyDonation({
                ...findRoyaltyDonation,
                amount: e.target.value,
              })
            }
          />
          <Form.Check
            type="radio"
            id="find-donation-1"
            label="1"
            name="find-donation"
            value={Number(1.0)}
            onChange={(e) =>
              setFindRoyaltyDonation({
                ...findRoyaltyDonation,
                amount: e.target.value,
              })
            }
          />
          <Form.Check
            type="radio"
            id="find-donation-5"
            label="5"
            name="find-donation"
            value={Number(5.0)}
            onChange={(e) =>
              setFindRoyaltyDonation({
                ...findRoyaltyDonation,
                amount: e.target.value,
              })
            }

            //    onChange={() => setSalesType("auction")}
          />
          <Form.Check
            type="radio"
            id="find-donation-other"
            label="Other"
            name="find-donation"
            onChange={(e) =>
              setFindRoyaltyDonation({
                ...findRoyaltyDonation,
                amount: customValueFind,
              })
            }
          />
          <input onChange={(e) => setCustomValueFind(e.target.value)} />
        </form>
      </div> */}
    </div>
  );
};

export default SendItemRoyaltiesForm;
