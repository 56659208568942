import React, { useEffect, useState } from "react";
import { Card, Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import {
  getAllUserFavsList,
  handleNFTFavClicked,
} from "../../../firebaseConfig";
import parseImgUrl from "../../../functions/parseImgUrl";
import "./storewatchlist.css";
import "../userstore.css";
import { Link } from "react-router-dom";
import { useUserWalletStatus } from "../../../functions/useWalletType";

const StoreWatchlist = ({ filterTerm, findUser }) => {
  const [favList, setFavList] = useState(null);
  const [filteredFavList, setFilteredFavList] = useState(null);
  const user = useUserWalletStatus();

  async function getFavs(address) {
    const favs = await getAllUserFavsList(address);
    setFavList(favs);
  }

  useEffect(() => {
    if (findUser && findUser.profile.address) {
      getFavs(findUser.profile.address);
    }
  }, [findUser]);

  useEffect(() => {
    if (favList) {
      setFilteredFavList(favList);
    }
  }, [favList]);

  useEffect(() => {
    if (filterTerm && favList) {
      setFilteredFavList(
        favList.filter(
          (favItem) =>
            favItem.nftName.toLowerCase().includes(filterTerm.toLowerCase()) ||
            favItem.collectionName
              .toLowerCase()
              .includes(filterTerm.toLowerCase()) ||
            favItem.nftId.toLowerCase().includes(filterTerm.toLowerCase())
        )
      );
    } else {
      setFilteredFavList(favList);
    }
  }, [filterTerm, favList]);

  //   console.log(favList);
  //   console.log(findUser);

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    )
  );

  return (
    <Container fluid={+true} id="store-names-listings" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row className="pb-2">
            <Col align="left">
              <h4>Your watchlist</h4>
            </Col>
          </Row>
          <table className="store-table" id="store-sold-table">
            <tbody>
              {(favList && favList === "none") ||
              (filteredFavList && filteredFavList.length === 0) ? (
                <tr>
                  <td>
                    <small>No watched items</small>
                  </td>
                </tr>
              ) : filteredFavList ? (
                filteredFavList.map((fav, i) => {
                  const colName = fav.nftType
                    .split(".")[2]
                    .replace("NFGv3", "NonFunGerbils");
                  const itemLink =
                    fav.collectionName === "FIND"
                      ? `/${fav.nftName}/names`
                      : `/${fav.nftCurrentOwner}/collection/main/${colName}/${fav.nftId}`;
                  return (
                    <tr key={i} className="store-row">
                      <td className="store-thumb-col">
                        <Link to={itemLink}>
                          <img
                            className="store-thumb-image"
                            fluid={+true}
                            src={parseImgUrl(fav.nftThumbnail)}
                          />
                        </Link>
                      </td>
                      <td className="store-name-col">
                        <Link to={itemLink}>
                          <span
                            className={
                              fav.collectionName === "FIND"
                                ? "themeColor fw-bold"
                                : "fw-bold"
                            }
                          >
                            {fav.nftName}
                            {fav.collectionName === "FIND" && (
                              <span style={{ color: "var(--text-primary)" }}>
                                .find
                              </span>
                            )}{" "}
                          </span>{" "}
                        </Link>
                      </td>

                      {/* <td>{JSON.stringify(fav)}</td> */}
                      <td>
                        <span className="store-sticker">#{fav.nftId}</span>
                      </td>
                      <td className="store-price-col">
                        <span className="">{fav.collectionName}</span>
                      </td>
                      <td className="store-time-left-col"></td>
                      <td className="store-buttons-col">
                        <Dropdown>
                          <Dropdown.Toggle
                            as={CustomDropdownToggle}
                            id="kebab-dropdown-toggle"
                          >
                            <img src="/assets/img/lf/lf-kebab.svg" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                            // href={itemLink}
                            >
                              <Link to={itemLink}> View item</Link>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={async () => {
                                await handleNFTFavClicked(
                                  fav.collectionName,
                                  fav.nftType,
                                  fav.nftId,
                                  fav.nftName,
                                  fav.nftThumbnail,
                                  fav.nftCurrentOwner,
                                  user
                                );
                                getFavs(findUser.profile.address);
                              }}
                            >
                              Remove from favs
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <small>Fetching watchlist...</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StoreWatchlist;
