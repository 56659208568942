import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'

const ForgeCreation = () => {
  return (
    <Container
    fluid={+true}
    className="pb-5"
    style={{
      backgroundColor: "var(--background-profile)",
      minHeight: "calc(100vh - 218px",
    }}
  >
    <Row className="pt-2 justify-content-center">

      <Col className="px-0 logged-in-main-col-width py-3" xs="12" lg="auto">
        <Image
              className="py-3"
              fluid={+true}
              src="/assets/img/forge/forge2.png"
              alt=".find contact banner"
            />
            <Row><Col align="center">
            <Button variant="find">The Forge</Button>
            </Col></Row>
      </Col>
    </Row>
  </Container>
  )
}

export default ForgeCreation