import React from "react";
import "./nftsforsale.scss";
const NftForSaleUpper = ({
  nftData,
  itemName,
  mediaUrl,
  mediaType,
  onSaleNFT,
  onAuctionNFT,
}) => {
  if (mediaUrl)
    return (
      <div id="sale-nft-upper-wrapper">
        {mediaType === "video" ? (
          <video
            key={mediaUrl}
            src={mediaUrl}
            type="video/mp4"
            muted
            alt={"Video of " + mediaUrl}
            loop=""
            playsInline=""
            controls
            onMouseOver={(event) => event.target.play()}
            onMouseOut={(event) => event.target.pause()}
          >
            Sorry this video is not supported by your browser
          </video>
        ) : (
          <img
            crossOrigin="anonymous"
            alt={`Picture of ${itemName}`}
            src={mediaUrl}
            style={{
              borderTopLeftRadius: "13px",
              borderTopRightRadius: "13px",
              backgroundColor: "var(--background)",
              width: "100%",
            }}
          />
        )}
        <div
          style={{
            position: "absolute",
            bottom: "0",
            margin: "5px",
            display: "flex",
          }}
        >
          {onSaleNFT && <div className="tag for-sale">For Sale</div>}
          {onAuctionNFT && <div className="tag auction">Auction</div>}
        </div>
      </div>
    );
};

export default NftForSaleUpper;
