import React from "react";
import DropPageMintBoxImage from "./DropPageMintBoxImage";
import DropPageMintBoxMint from "./DropPageMintBoxMint";
import DropPageMintBoxSplash from "./DropPageMintBoxSplash";

export default function DropPageMintBox(props){
  const {
    packDetails,
    purchasePack,
    numOfPacks,
    setNumOfPacks,
  } = props
  return (
    <div className="drop-page-mint-box-wrapper">
      <div className="drop-page-mint-box-content-container">
        <DropPageMintBoxImage packDetails={packDetails} />
        <DropPageMintBoxMint
          packDetails={packDetails}
          numOfPacks={numOfPacks}
          purchasePack={purchasePack}
          setNumOfPacks={setNumOfPacks}
        />
        <DropPageMintBoxSplash />
      </div>
    </div>
  );
};