import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Modal } from "react-bootstrap";
import { ConnectWidget } from "../../components/profile/connectwidget/connectwidget";
import { ProfileHeader } from "../../components/profile/profileheader/profileheader";
import { ProfileTabs } from "../../components/profile/profiletabs/profiletabs";
import * as fcl from "@onflow/fcl";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import { Script } from "../../functions/script";
import { FundWidget } from "../../components/profile/fundwidget/fundwidget";
import "../../components/profile/profile.css";
import { useNavigate, useParams } from "react-router";
import { useStateChanged } from "../../functions/DisabledState";
import { AuthCluster } from "../../auth-cluster";
import RegisterName from "../../components/profile/registername/registername";
import { FundWidgetOwnProfile } from "../../components/profile/fundwidgetownprofile/fundwidgetownprofile";
import CreateProfile from "../../components/profile/createprofile/createprofile";
import { Helmet } from "react-helmet";
import ProfileTabsNoProfile from "../../components/profile/profiletabs/profiletabsnoprofile";
import ProfileHeaderNoProfile from "../../components/profile/profileheader/profileheadernoprofile";
import { getUserProfilePreferences } from "../../firebaseConfig";
import useOnboardStatus from "../../functions/useOnboardStatus";
import LockedName from "../../components/profile/LockedName/LockedName";
import Loading from "../../components/loading/loading";
import { useWalletType } from "../../functions/useWalletType";
import { getStatus } from "../../functions/flow-interactions/scripts";

export default function Profile(props) {
  const { findUser, type, tab, display, metaDescription } = props
  const [profileData, setProfileData] = useState(findUser);
  const [profileSearch, setProfileSearch] = useState(null);
  const [addressSearch, setAddressSearch] = useState(null);
  const [user, setUser] = useState({ loggedIn: null });
  const [searchedName, setSearchedName] = useState(null);
  const [searchedAddress, setSearchedAddress] = useState(null);
  useEffect(() => fcl.currentUser().subscribe(setUser), []);
  const [findName, setFindName] = useState(null);
  // const [collectionItems, setCollectionItems] = useState("first_init");
  const [addressNoProfile, setAddressNoProfile] = useState(null);
  const [show, setShow] = useState(false);
  const [userPreferences, setUserPreferences] = useState(null);
  let navigate = useNavigate();
  let { name } = useParams();
  let searchName;
  const walletType = useWalletType();
  
  //get user pref from firebase
  async function getUserPref() {
    const userPref = await getUserProfilePreferences(user.addr);
    setUserPreferences(userPref);
  }

  useEffect(() => {
    if (user.addr) {
      getUserPref(user.addr);
    }
    //if default name not set
    if (
      user.loggedIn &&
      profileData &&
      profileData.profile &&
      profileData.profile.address === user.addr
    ) {
      if (
        profileData.leases.length > 0 &&
        profileData.profile.findName === ""
      ) {
        setShow(true);
      }
    }
  }, [profileData, user]);

  //set onboarded status based on getstatus profile + firebase response
  const onboardStatus = useOnboardStatus(findUser, userPreferences);
  // console.log(onboardStatus);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(
    () => {
      if (name) {
        searchName = name.toLowerCase();
        searchName = searchName.replace(/[^a-z0-9-]/g, "");
        // //console.log(searchName);

        if (!searchName) {
          //console.log("No name entered. Redirect to homepage");
          navigate("/");
          return;
        }
        if (searchName.length < 3 || searchName.length > 16) {
          if (searchName.length === 18 && searchName.includes("0x")) {
            setAddressSearch(true);
            setSearchedAddress(name);
          } else {
            //console.log("Name too short or too long. Enter a valid name");
            navigate("/");
            return;
          }
        }
      } else {
        setProfileSearch(true);
        setProfileData(findUser);
      }

      setSearchedName(searchName);

      if (type === "search" || addressSearch) {
        try {
          // Ben : If this is where the search bar function is, this should only call "getNameInfo" and it should do the work
          // reduce the amount of queries to the chain
          getStatus(searchName).then(setFindName)
        } catch (error) {
          //console.log(error);
        }
      }
    },
    // eslint-disable-next-line
    [useStateChanged(), searchName, name, profileSearch]
  );

  useEffect(() => {
    // console.log(findName);
    if (addressSearch && searchedAddress && !findName.FINDReport.profile) {
      // console.log("No profile at address");
    // console.log(searchedAddress)
      setAddressNoProfile(true);
    } else {
      let newProfileData = findName?.FINDReport || findName?.NameReport
      setProfileData(newProfileData);
    }
  }, [findName]);

  useEffect(() => setProfileData(findUser), [findUser]);
  // //console.log(profileData)
  if (profileSearch && !user.loggedIn) {
    return (
      <Container className="py-5 my-5" align="center">
        <h2 className="mb-5">Please Log in</h2>
        <AuthCluster user={user} />
      </Container>
    );
  }

  if (
    ((profileData === "first_init" || !profileData) && !addressNoProfile) ||
    (findUser && !onboardStatus.onboardStatusRun)
  ) {
    return <Loading />;
  }
  //onboarding - if no profile show create profile stage (2)
  //if profile and no findname and findname opt more than 4 weeks ago show find name stage (3)
  //if profile and findname or findname optout and explore profile more than 4 weeks ago show stage (4)
  //if profile and findname or findname optout and onboard less than 4 weeks ago show regular dashboard
  if (
    findUser &&
    onboardStatus.currentStage &&
    onboardStatus.onboardStatusRun
  ) {
    return (
      <CreateProfile
        profileCurrentStage={onboardStatus.currentStage}
        user={user}
        findUser={findUser}
      />
    );
  }

  if (findName?.NameReport?.status === "FREE" && searchedName) {
    // //console.log(searchedName);
    return (
      <RegisterName
        profileData={findName.NameReport}
        searchedName={searchedName}
      />
    );
  }

  // console.log(findName)

  if (findName?.NameReport?.status === "LOCKED" && searchedName) {
    // //console.log(searchedName);
    return (
      <LockedName searchedName={searchedName} leaseData={findName.NameReport} />
      // <RegisterName
      //
      //
      // />
    );
  }

  //and if findname/optout and onboarded
  if (
    profileData &&
    profileData.profile &&
    ((findUser && !onboardStatus.currentStage) || !findUser)
  ) {
    return (
      <Container
        fluid={+true}
        id="profile-wrapper"
        style={{
          backgroundColor: "var(--background-profile)",
          minHeight: "calc(100vh - 218px",
        }}
      >
        <Helmet>
          <title>
            {profileData.profile.findName || profileData.profile.name} / .find
          </title>
          <meta name="description" content={metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://find.xyz" />
          <meta
            property="og:title"
            content=".find - find people and things #onFlow"
          />
          <meta
            property="og:description"
            content="With .find you can buy/sell/offer and research collections, names and NFTs on the flow blockchain"
          />
          <meta property="og:image" content="https://find.xyz/logo512.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://find.xyz" />
          {/* <meta name="twitter:site" content="@user" />
          <meta name="twitter:creator" content="@user" />  */}
          <meta
            name="twitter:title"
            content=".find - Livefeed of the flow blockchain"
          />
          <meta
            name="twitter:description"
            content="The .find livefeed, see everything that is happening at .find on the flow blockchain"
          />
          <meta name="twitter:image" content="https://find.xyz/logo512.png" />
        </Helmet>
        <Container className="content-container">
          <Row className="pt-4 justify-content-center">
            <Col xs="12" lg="9" xxl="10" className="px-0">
              <Row>
                <Col>
                  {profileData.profile && (
                    <ProfileHeader profileData={profileData.profile} />
                  )}
                </Col>
              </Row>
              <Row className="d-lg-none">
                {profileData.profile &&
                  Object.keys(profileData.profile.links).length > 0 && (
                    <Col xs="12" lg="3" xxl="2">
                      <ConnectWidget links={profileData.profile.links} />
                    </Col>
                  )}

                <Col xs="12" lg="3" xxl="2">
                  {walletType !== "Dapper Wallet" &&
                    profileData &&
                    !profileData.isDapper && (
                      <>
                        {type === "owner" ? (
                          <FundWidgetOwnProfile />
                        ) : (
                          <FundWidget profile={profileData.profile} />
                        )}
                      </>
                    )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProfileTabs
                    profileData={profileData}
                    type={type}
                    tab={tab}
                    display={display}
                  />
                </Col>
              </Row>
            </Col>
            {/* show the connect widget to the right on a large screen */}

            <Col xs="12" lg="3" xxl="2" className="d-none d-lg-block">
              {profileData.profile &&
                Object.keys(profileData.profile.links).length > 0 && (
                  <ConnectWidget links={profileData.profile.links} />
                )}

              {walletType !== "Dapper Wallet" &&
                profileData &&
                !profileData.isDapper && (
                  <>
                    {type === "owner" ? (
                      <FundWidgetOwnProfile />
                    ) : (
                      <FundWidget profile={profileData.profile} />
                    )}
                  </>
                )}
            </Col>
          </Row>
          {/* <Row>{JSON.stringify(findUser, null, 2)}</Row> */}
        </Container>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            style={{ border: "none" }}
            closeButton
            closeLabel="close window"
          >
            <Modal.Title className="text-center w-100">
              <h4 className="fw-bold" style={{ marginBottom: "0px" }}>
                Update Your Profile
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You have not set a default .find name. Please go{" "}
              <a
                onClick={() => {
                  handleClose();
                  navigate("/me/names");
                }}
                style={{ color: "var(--theme-main)", cursor: "pointer" }}
              >
                here
              </a>{" "}
              and click on "Manage" to update your profile.
            </p>
          </Modal.Body>
        </Modal>
      </Container>
    );
  } else if (searchedAddress && addressNoProfile) {
    return (
      <Container
        fluid={+true}
        id="profile-wrapper"
        style={{
          backgroundColor: "var(--background-profile)",
          minHeight: "calc(100vh - 218px",
        }}
      >
        <Helmet>
          <title>{searchedAddress} / .find</title>
          <meta name="description" content={metaDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://find.xyz" />
          <meta
            property="og:title"
            content=".find - find people and things #onFlow"
          />
          <meta
            property="og:description"
            content="With .find you can buy/sell/offer and research collections, names and NFTs on the flow blockchain"
          />
          <meta property="og:image" content="https://find.xyz/logo512.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://find.xyz" />
          {/* <meta name="twitter:site" content="@user" />
          <meta name="twitter:creator" content="@user" />  */}
          <meta
            name="twitter:title"
            content=".find - Livefeed of the flow blockchain"
          />
          <meta
            name="twitter:description"
            content="The .find livefeed, see everything that is happening at .find on the flow blockchain"
          />
          <meta name="twitter:image" content="https://find.xyz/logo512.png" />
        </Helmet>
        <Container className="content-container">
          <Row className="pt-4 justify-content-center">
            <Col xs="12" lg="9" xxl="10" className="px-0">
              <Row>
                <Col>
                  <ProfileHeaderNoProfile searchedAddress={searchedAddress} />
                </Col>
              </Row>
              <Row className="d-lg-none">
                {/* {profileData.profile &&
                  Object.keys(profileData.profile.links).length > 0 && (
                    <Col xs="12" lg="3" xxl="2">
                      <ConnectWidget links={profileData.profile.links} />
                    </Col>
                  )} */}

                <Col xs="12" lg="3" xxl="2">
                  {/* {type === "owner" ? (
                    <FundWidgetOwnProfile />
                  ) : (
                    <FundWidget profile={profileData.profile} />
                  )} */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProfileTabsNoProfile
                    searchedAddress={searchedAddress}
                    type={type}
                    tab={tab}
                    display={display}
                  />
                </Col>
              </Row>
            </Col>
            {/* show the connect widget to the right on a large screen */}
          </Row>
          {/* <Row>{JSON.stringify(findUser, null, 2)}</Row> */}
        </Container>
      </Container>
    );
  } else {
    return null;
  }
}
