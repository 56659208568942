import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footerlinks.css";

const FooterLinks = () => {
  return (
    <Container className="footer-container">
      <div className="seperator100 mb-4 mb-md-5 mt-5"></div>
      <Container>
        <Row className="flex-center mb-2 mb-md-4 pt-md-4">
          <Image
            alt="footer-flow-logo"
            fluid={+true}
            src="/assets/img/footer/find-logo-footer.svg"
            className="footerlogo"
          />
        </Row>
        <Row className="flex-center pb-md-3">
          <Col></Col>
          <Col xs="12" md="auto" align="center" className="my-2 my-md-0">
            <Link className="footer-link" to="/p/helphub">
              HelpHub
            </Link>
          </Col>
          <Col xs="12" md="auto" align="center" className="my-2 my-md-0">
            <Link className="footer-link" to="/p/testnethub">
              TestnetHub
            </Link>
          </Col>
          <Col xs="12" md="auto" align="center" className="my-2 my-md-0">
            <a
              className="footer-link"
              href="https://discord.gg/tCSFSpFcRv"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
          </Col>
          <Col xs="12" md="auto" align="center" className="my-2 my-md-0">
            <a
              className="footer-link"
              href="https://roadmap.findlabs.io/roadmap/b/findxyz"
              target="_blank"
              rel="noreferrer"
            >
              Roadmap
            </a>
          </Col>
          <Col xs="12" md="auto" align="center" className="my-2 my-md-0">
            <a
              className="footer-link"
              href="https://findonflow.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              Medium
            </a>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <div className="seperator100 mt-3 mt-md-5"></div>
    </Container>
    // <Container
    //   className="d-flex align-items-center justify-content-around"
    //   id="footer-links-wrapper"
    // >
    //   <Row>
    //     <Col>
    // <Image
    //   alt="footer-flow-logo"
    //   fluid={+true}
    //   src="/assets/img/footer/find-logo-footer.svg"
    //   className="footerlogo"
    // />
    //   </Col></Row>
    //   <Row>
    //     <Col>
    // <Link className="footer-link" to="/p/helphub">
    //   HelpHub
    // </Link>
    //     </Col>
    //     <Col>
    // <Link className="footer-link" to="/p/testnethub">
    //   TestnetHub
    // </Link>
    //     </Col>
    //     {/* <Col>
    //       <Link className="footer-link" to="/">
    //         Educate
    //       </Link>
    //     </Col> */}
    //     {/* <Col>
    //       <a
    //         className="footer-link"
    //         href="https://twitter.com/findonflow"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         Twitter
    //       </a>
    //     </Col> */}
    //     <Col>
    //       <a
    //         className="footer-link"
    //         href="https://discord.gg/tCSFSpFcRv"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         Discord
    //       </a>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default FooterLinks;
