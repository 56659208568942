import { atom, useAtom } from "react-atomic-state";

const showFilters = atom(window.innerWidth < 576 ? false : true);

export const setShowFilters = (value) => {
  // console.log(message);
  showFilters.set(value);
};

export const useShowFilters = () => useAtom(showFilters);
