import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormStatus } from "../../../functions/DisabledState";
import {handleFullfillAuction, handleRegisterName} from "../../../functions/flow-interactions/transactions";
import {getNameInfo, getNamePriceInFlow, getPriceInFlow} from "../../../functions/flow-interactions/scripts";
import "./createprofile.scss";
import {ActionButton} from "../../search/autocomplete/NameItem";
import {toLocalNumber} from "../../../functions/format";

const CreateProfileFindNameInput = ({
  formValues,
  setFormValues,
  validation,
  walletType,
}) => {
  const [findNameInfo, setFindNameInfo] = useState(null);
  const [findNameAvailable, setFindNameAvailable] = useState(null);
  const [findNameCost, setFindNameCost] = useState(null);
  const [flowPrice, setFlowPrice] = useState(null);

  function updateField(e) {
    setFormValues(e.target.value);
  }

  useEffect(() => {
    // console.log(findNameInfo);
    if (findNameInfo) {
      setFindNameAvailable(
        findNameInfo.status === "FREE"
          ? "available"
          : "unavailable"
      );
      setFindNameCost(findNameInfo.cost * 1);
      //const price = toLocalNumber(findNameInfo.cost, { min: 2 });

      getNamePriceInFlow(formValues.toLowerCase()).then((result) => {
        console.log({result});
        if (result) {
          setFlowPrice(result);
        }
      });
    }
  }, [findNameInfo]);

  useEffect(() => {
    // console.log(validation)
    setFindNameInfo(null);
    setFindNameAvailable(null);
    setFindNameCost(null);
    if (validation && validation.status === "valid" && formValues.length > 2) {
      getNameInfo(formValues).then(setFindNameInfo)
    }
  }, [formValues && validation]);

  const registerName = async (token) => {
    // handleRegisterName(formValues.toLowerCase(), findNameCost, walletType);
    const name = formValues.toLowerCase()
    const amount = findNameInfo.cost;
    const tokenAmount = flowPrice || 0
    const args = { name, amount, token, tokenAmount }

    await handleRegisterName(args, walletType)
  };

  const flowPriceLabel = flowPrice ? `${(parseFloat(flowPrice)*1.05).toFixed(2)} FLOW` : ""
  const usdcPriceLabel = findNameInfo ? `$${parseFloat(findNameInfo.cost).toFixed(2)}` : "";
  const showFlowRegister = walletType !== "Dapper Wallet" && flowPrice

  return (
    <div id="create-profile-input-wrapper">
      <Form>
        <fieldset disabled={useFormStatus()}>
          <Row>
            <Col id="validation-error-wrapper">
              {validation.status === "invalid" ? (
                <span className="validation-error-invalid">
                  {validation.error}
                </span>
              ) : validation.error === "none" ? (
                <span className="validation-error-valid">
                  All good <i className="fa-solid fa-check" />
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </Col>
          </Row>
          <Row className="py-2">
            <Col>
              <Form.Control
                onChange={updateField}
                type="text"
                placeholder="Your .find name"
                maxLength={13}
                className={
                  validation.status === null
                    ? ""
                    : validation.status === "valid"
                    ? "valid"
                    : "invalid"
                }
              ></Form.Control>
            </Col>
          </Row>

          <Row className="py-2">
            {findNameInfo && <Col>
              {validation && validation.status === "valid" ? (
                <div id="find-name-status-wrapper">
                  <span>
                    {findNameAvailable && findNameAvailable === "available" && (
                      <img src="assets/img/profile/createprofile/name-valid-icon.svg" />
                    )}
                    <span id="find-name-status-status">
                      <span id="find-name-status-name">
                        <span className="themeColor">{formValues.toLowerCase()}</span>.find
                      </span>
                      {findNameAvailable && " is " + findNameAvailable}
                    </span>
                  </span>
                  <div id="find-name-status-cost" class={"flex-row gap-1"}>
                    <span>
                      {findNameCost &&
                        findNameAvailable === "available" &&
                        `$${findNameCost}`}
                    </span>
                    or
                    {flowPrice &&
                      <span>
                        {flowPriceLabel}
                      </span>
                    }
                  </div>
                </div>
              ) : (
                <div id="find-name-status-wrapper">&nbsp;</div>
              )}
            </Col>}
          </Row>

          <Row className="aa-ItemActions py-2 flex-row gap-2">
            {showFlowRegister && <button
                type="button"
                className={`register flow col-4 flex-grow-1`}
                disabled={validation.status !== "valid"}
                onClick={()=>registerName("flow")}
            >
              Register with FLOW
              <span>{flowPriceLabel}</span>
            </button>
            }
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default CreateProfileFindNameInput;
