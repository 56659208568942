import React from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./LiveFeedWrapper.scss";

const LiveFeedHeader = () => {
  const navigate = useNavigate();
  return (
    <div id="live-feed-header-wrapper">
      <div
        id="lf-back-wrapper"
        onClick={() => navigate(-1)}
        className="d-md-none mb-3"
      >
        <span id="lf-back-span">
          <img src="/assets/img/lf/lf-back.svg" />
          &nbsp; Back
        </span>
      </div>
      <div>
        <span id="lf-title-span">Livefeed</span>
      </div>
      <div>
        <span id="lf-sub-title-span">All the latest action on .find</span>
      </div>
    </div>
  );
};

export default LiveFeedHeader;
