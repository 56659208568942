import { Card, Col, Container, Image, Row } from "react-bootstrap";
import CollectionStatsBox from "./CollectionStatsBox";
import "./CollectionPage.css";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import bl0x_banner from "./bl0x_banner.webp";
import nfg_banner from "./nfg_banner.webp";
import nfg_square from "./nfg_square.webp";

import { SortBy } from "react-instantsearch-hooks-web";
import parseImgUrl from "../../../functions/parseImgUrl";
import CustomImageThumb from "../../CustomImage/CustomImageThumb";

export default function CollectionHeader(colName) {
  const [bannerOpen, setBannerOpen] = useState();
  const [squareOpen, setSquareOpen] = useState();

  let catalogDataDisplay;
  let creatorName;
  let creatorAddress;
  let creatorLink;
  let creatorType;
  let headerName;
  if (colName.catalogData) {
    catalogDataDisplay = colName.catalogData.collectionDisplay;
    creatorName = colName.catalogData?.contractName
      ? colName.catalogData?.contractName
      : colName.catalogData?.contractAddress;
    creatorAddress = colName.catalogData?.contractAddress;
    creatorType = colName.catalogData?.nftType.replace(".NFT", "");
    creatorLink = "https://flowscan.org/contract/" + creatorType;
  }

  //hacky fix for items with no banner from catalog response
  let headerBackgroundUrl =
    catalogDataDisplay?.name === "bl0x"
      ? bl0x_banner
      : catalogDataDisplay?.name === "NonFunGerbils"
      ? nfg_banner
      : catalogDataDisplay?.bannerImage?.file.cid
      ? "https://find.mypinata.cloud/ipfs/" +
        catalogDataDisplay?.bannerImage?.file.cid
      : catalogDataDisplay?.bannerImage?.file?.url
      ? catalogDataDisplay.bannerImage.file.url
      : null;

  //hacky fix for items with no square from catalog response
  let squareImage =
    catalogDataDisplay?.name === "bl0x"
      ? " https://pbs.twimg.com/media/Fa2fh0sXoAIIGTq?format=png&name=small"
      : catalogDataDisplay?.name === "NonFunGerbils"
      ? nfg_square
      : catalogDataDisplay?.bannerImage?.file.cid
      ? "https://find.mypinata.cloud/ipfs/" +
        catalogDataDisplay?.squareImage?.file.cid
      : catalogDataDisplay?.squareImage?.file?.url
      ? catalogDataDisplay?.squareImage?.file?.url
      : null;
  let headerDescription = catalogDataDisplay?.description;

  if (!colName) {
    headerName = catalogDataDisplay?.name
      .replace("FIND", ".find")
      .replace("bl0x", "Bl0x");
  } else {
    headerName = colName.colName.colName
      .replace("FIND", ".find")
      .replace("bl0x", "Bl0x");
  }

  let headerSite = catalogDataDisplay?.externalURL?.url;
  let headerSocials = catalogDataDisplay?.socials;

  return (
    <Container fluid={+true} id="collection-header" className="mb-3 px-0">
      <Row>
        <Col className="">
          <Card className="outer-wrapper">
            <div
              onClick={(e) => setBannerOpen(true)}
              className="header-card-bg"
              style={{
                backgroundImage: "url('" + headerBackgroundUrl + "')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                cursor: "pointer",
              }}
            ></div>
            {bannerOpen && (
              <Lightbox
                mainSrc={headerBackgroundUrl}
                onCloseRequest={() => setBannerOpen(false)}
              />
            )}
            {squareOpen && (
              <Lightbox
                mainSrc={squareImage}
                onCloseRequest={() => setSquareOpen(false)}
              />
            )}
            <Card.Body>
              <Row className="justify-content-sm-center justify-content-lg-start">
                <Col
                  className="header-square-col ms-lg-2 d-flex justify-content-center"
                  xs="12"
                  lg="auto"
                >
                  <div
                    className="profile-pic"
                    onClick={(e) => setSquareOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <CustomImageThumb imgUrl={squareImage}/>
                  </div>
                </Col>
                <Col>
                  <div className="header-name-padding d-none d-lg-block"></div>
                  <Row className="align-items-center">
                    <Col className="d-flex justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                      <h3>{headerName}</h3>
                    </Col>
                    <Col xs="auto" className="d-none d-lg-block">
                      <Row>
                        {headerSite && (
                          <Col>
                            <span className="icon-span">
                              <a
                                href={headerSite}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                <i className={"me-2 fa-solid fa-globe"} />
                              </a>
                            </span>
                          </Col>
                        )}
                        {headerSocials &&
                          Object.keys(headerSocials).map((link, index) => (
                            <Col key={index}>
                              <span className="icon-span">
                                <a
                                  href={headerSocials[link].url}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ textDecoration: "none" }}
                                >
                                  <i className={"me-2 fa-brands fa-" + link} />
                                </a>
                              </span>
                            </Col>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center justify-content-lg-start mb-3">
                      <span className="fw-bold">
                        Created by:{" "}
                        <a target="_blank" rel="noreferrer" href={creatorLink}>
                          {creatorName}
                        </a>
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="justify-content-center text-center text-lg-start justify-content-lg-start px-3">
                      <span className="collection-description">
                        {headerDescription}
                      </span>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center justify-content-center d-lg-none">
                    <Col className="mt-3 icons-col" xs="6" sm="8" md="6">
                      <Row>
                        {headerSite && (
                          <Col>
                            <span className="icon-span">
                              <a
                                href={headerSite}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                <i className={"me-2 fa-solid fa-globe"} />
                              </a>
                            </span>
                          </Col>
                        )}
                        {headerSocials &&
                          Object.keys(headerSocials).map((link, index) => (
                            <Col key={index}>
                              <span className="icon-span">
                                <a
                                  href={headerSocials[link].url}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ textDecoration: "none" }}
                                >
                                  <i className={"me-2 fa-brands fa-" + link} />
                                </a>
                              </span>
                            </Col>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Row className="d-flex justify-content-center align-items-center mt-3 mt-lg-5 mb-2">
                  <CollectionStatsBox />
                </Row>
                <Row>
                  <Row>
                    <Col className="d-none d-md-block" md="10"></Col>
                    <Col className="py-3" xs="12" md="2">
                      <SortBy
                        items={[
                          { label: "Sort By", value: "market" },
                          { label: "Price ▲", value: "market/sort/amount:asc" },
                          {
                            label: "Price ▼",
                            value: "market/sort/amount:desc",
                          },
                          {
                            label: "Ends soonest",
                            value: "market/sort/ends_at:asc",
                          },
                          {
                            label: "Activity",
                            value: "market/sort/updated_at:desc",
                          },
                        ]}
                        classNames={{ select: "mpSortBy form-select" }}
                      />
                    </Col>
                  </Row>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
