import { Button, CloseButton, Col, Row } from "react-bootstrap";
import {
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch-hooks-web";
import "./marketplace.css";

export default function CustomCurrentRefinementsMobile(props) {
  const {
    items,
    // canRefine,
    refine,
  } = useCurrentRefinements(props);

  function CustomClearRefinements(props) {
    const {
      // canRefine,
      refine,
    } = useClearRefinements(props);
    return (
      <Button variant="find-clear" onClick={() => refine(items)}>
        <CloseButton className="refinementsButtonClear" />
      </Button>
    );
  }
  // console.log(items)

  return (
    <>
      {items && (
        <Row className="align-content-middle align-items-center px-0 mt-1">
          <Col className="mt-3 mx-1" xs="auto">
            {items.length > 0 && <CustomClearRefinements />}
          </Col>

          {items.map((item, i) => {
            // console.log(item)
            let itemAttribute = item.attribute
              .replace("string.", "")
              .replace("collection_name", "Collection")
              .replace("listing_alias", "Listing Type")
              .replace("nft_rarity", "Rarity")
              .replace("collection_alias", "Collection")
              .replace("amount_alias", "Currency")
              .replace("nft_alias", "Collection");
            return item.refinements.map((itemRefinements, i) => {
              // console.log(item.attribute)
              return (
                <Col
                  key={i}
                  xs="auto"
                  className="mt-3 mx-1 px-2 refinementsListMobile"
                >
                  {itemAttribute[0].toUpperCase() +
                    itemAttribute.substring(1) +
                    " - " +
                    itemRefinements.value
                      .toString()
                      .replace("FlowToken", "Flow")
                      .replace("FIND", ".find")
                      .replace("EnglishAuction", "Auction")}
                  <CloseButton
                    className="refinementsButton"
                    onClick={() => refine(itemRefinements)}
                  />
                </Col>
              );
            });
          })}
        </Row>
      )}
    </>
  );
}
