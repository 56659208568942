import React, { useState, useRef } from "react";
import { Image } from "react-bootstrap";
import { shortAddress, isObject } from "../utils";
import { useFindResolver } from "../service/find-resolver";
import { useNFTResolver } from "../service/nft-resolver";
import { toLocalNumber } from "../../../functions/format";

export const NFTRender = (props) => {
  const { collection, id } = props;
  const nftData = useNFTResolver(collection, id);
  return <div>We will show NFT here</div>;
};

export const FindAddress = (props) => {
  const { address, forceAddress = false, className = "", prefix = "" } = props;
  const name = useFindResolver(address);

  const linkPart = forceAddress ? address : name;
  const href = `/${linkPart}`;
  const title = `Go to ${name || address} profile page`;

  const content = forceAddress ? shortAddress(address) : name;
  const linkClass = "find-address " + className;

  let link = (
    <a href={href} target={"_blank"} className={linkClass} title={title}>
      {content}
    </a>
  );

  if (name === "???") {
    link = <span className={className} title="Most likely, some NFT collection">{name}</span>;
  }


  if (prefix) {
    return (
      <p>
        {prefix} {link}
      </p>
    );
  }

  return link;
};

export const TransferTitle = (props) => {
  const { children } = props;
  return <h3 className="transfer-container__title">{children}</h3>;
};

export const Amount = (props) => {
  const { value } = props;
  const trimmedZeroes = value.toString();
  return (
    <p className={"transfer-container__single__amount"}>{trimmedZeroes}</p>
  );
};

export const Ticker = (props) => {
  const { children } = props;
  return <span className={"transfer-container__ticker"}>{children}</span>;
};

export const FlowSticker = () => (
  <img
    className="sticker-image"
    src="/assets/img/stickerlogos/flow-sticker-logo.jpg"
  />
);

export const USDCSticker = () => (
  <img
    className="sticker-image"
    src="/assets/img/stickerlogos/usdc-sticker-logo.png"
  />
);

export const FUSDSticker = () => (
  <img
    className="sticker-image"
    src="/assets/img/stickerlogos/fusd-sticker-logo.jpeg"
  />
);

export const DapperSticker = () => (
  <img
    className="sticker-image"
    src="/assets/img/walletProviders/wallet-provider-dapper.svg"
  />
);

export const TokenSticker = (props) => {
  const { token } = props;
  if (token.includes("Flow")) {
    return <FlowSticker />;
  }
  if (token.includes("DUC") || token.includes("DapperUtilityCoin")) {
    return <DapperSticker />;
  }
  if (token.includes("FUSD")) {
    return <FUSDSticker />;
  }
  if (token.includes("USDC")) {
    return <USDCSticker />;
  }
};

export const Price = (props) => {
  const { price } = props;
  if (!price || !price.amount) {
    return null;
  }
  const { amount, token } = props.price;

  let ticker = "";
  if (token.includes("DUC") || token.includes("DapperUtilityCoin")) {
    ticker = "$";
  } else {
    if (token.includes("Flow")) {
      return "FLOW";
    }
    if (token.includes("FUSD")) {
      return "FUSD";
    }
    if (token.includes("USDC")) {
      return "USDC";
    }
  }

  const amountLocale = toLocalNumber(amount);
  const fullPrice =
    ticker === "$" ? `$${amountLocale}` : `${amountLocale} ${ticker}`;
  return (
    <div className="transfer-container__single">
      <p>Price:</p>
      <TokenSticker token={token}/>
      <span className={"transfer-container__ticker"}>{fullPrice}</span>
    </div>
  );
};

export const SingleFlowTransfer = (props) => {
  const { transfer } = props;
  return (
    <div className="transfer-container__single">
      <Amount value={transfer.amount} />
      <FlowSticker />
      <Ticker className="ft-span secColor">Flow</Ticker>
      <i className="fa-solid fa-arrow-right linkIcon"></i>
      <FindAddress address={transfer.to} forceAddress={true} />
    </div>
  );
};

export const Timestamp = (props) => {
  const { timestamp } = props;
  return (
    <div className="transfer-container__timestamp">
      <p>{timestamp}</p>
    </div>
  );
};

export const FlowTransfers = (props) => {
  const { data } = props;
  const { purchase, royalties } = data.payments;
  return (
    <div>
      <p>Purchase:</p>
      <SingleFlowTransfer transfer={purchase} />
      <p>Royalties:</p>
      {royalties.map((item) => {
        return <SingleFlowTransfer transfer={item} />;
      })}
    </div>
  );
};

export const FlowSale = (props) => {
  const { price } = props;
  const numPrice = parseFloat(price);
  return (
    <div>
      <p>Price:</p>
      <div className={"transfer-container__single"}>
        <Amount value={numPrice} />
        <FlowSticker />
        <Ticker className="ft-span secColor">Flow</Ticker>
      </div>
    </div>
  );
};

export const USDSale = (props) => {
  const { price } = props;
  const numPrice = parseFloat(price);
  return (
    <div>
      <p>Price:</p>
      <div className={"transfer-container__single"}>
        <Amount value={numPrice} />
        <FlowSticker />
        <Ticker className="ft-span secColor">Flow</Ticker>
      </div>
    </div>
  );
};

export function HistoryBlock(props) {
  const [debug, setDebug] = useState(false);
  const ref = useRef();

  const { children, transfer, context } = props;
  const { collection } = context;
  const { events, script, hash } = transfer;
  const timestamp = transfer.meta.timestamp.asString;
  return (
    <div
      ref={ref}
      className="transfer-container p-3 mb-3"
      onClick={(e) => {
        // We only want to enable debugging information if "robot" badge is clicked
        const debugNode = ref.current?.querySelector(".transfer__debug");
        if (e.target === debugNode) {
          setDebug(!debug);
        }
      }}
    >
      {children}
      {debug && (
        <>
          <Events events={events} collection={collection} />
          <Script script={script} />
          <Hash hash={hash} />
          <Context context={context} />
        </>
      )}
      <Timestamp timestamp={timestamp} />
      <WildGuess />
    </div>
  );
}

export function Events(props) {
  const { events, collection } = props;
  return events.list.map((eventId) => {
    const { id, index } = eventId;
    const event = events.byId[id][index];

    const { args, argNames } = event;
    const related = event.id.includes(collection);
    const color = related ? "related" : "";

    return (
      <section className={"event-block"}>
        <header className={`event-id ${color}`}>{id}</header>
        {argNames.map((argName) => {
          let value = args[argName];
          if (isObject(value)) {
            value = JSON.stringify(value);
          }
          return (
            <p>
              {argName}: {value}
            </p>
          );
        })}
      </section>
    );
  });
}

export function Script(props) {
  const { script } = props;
  return <textarea value={script} />;
}

export function Hash(props) {
  const { hash } = props;
  return (
    <p>
      Hash: <b>{hash}</b>
    </p>
  );
}

export function Context(props) {
  const { context } = props;
  const { collection, id } = context;
  return (
    <>
      <p>
        Collection: <b>{collection}</b>
      </p>
      <p>
        Id: <b>{id}</b>
      </p>
    </>
  );
}

export function WildGuess() {
  const title = "Our wild guess for this type of transfer";
  return (
    <div className={"transfer__debug"} title={title}>
      🤖
    </div>
  );
}
