import React from "react";
import { Col } from "react-bootstrap";
import parseImgUrl from "../../../functions/parseImgUrl";
import Loading from "../../loading/loading";
import MpDetailLightBox from "./mpdetailightbox";
import MpDetailMediaSlider from "./MpDetailMediaSlider/MpDetailMediaSlider";

const MpDetailMediaContent = ({ hit, hits, listingStatus, isName }) => {


     
       

    return (
      <MpDetailMediaSlider
        listingStatus={listingStatus}
        isName={isName}
        hit={hit}
      />
    );
  
};

export default MpDetailMediaContent;
