import { useEffect, useState } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import "./nftsforsale.scss";
import NftsForSaleCard from "./nftsforsalecard";

const NftsForSale = ({ type, itemsForSale }) => {
  const [nftsForDirectSale, setNftsForDirectSale] = useState();
  const [nftsForAuction, setNftsForAuction] = useState();
  const [allNftsForSale, setAllNftsForSale] = useState();

  useEffect(() => {
    if (itemsForSale.FindMarketSale) {
      setNftsForDirectSale(itemsForSale.FindMarketSale.items);
    }
  }, [itemsForSale]);

  useEffect(() => {
    if (itemsForSale.FindMarketAuctionEscrow) {
      setNftsForAuction(itemsForSale.FindMarketAuctionEscrow.items);
    }
  }, [itemsForSale]);

  useEffect(() => {
    if (nftsForDirectSale && nftsForAuction) {
      setAllNftsForSale(nftsForDirectSale.concat(nftsForAuction));
    } else if (nftsForDirectSale && !nftsForAuction) {
      setAllNftsForSale(nftsForDirectSale);
    } else if (!nftsForDirectSale && nftsForAuction) {
      setAllNftsForSale(nftsForAuction);
    }
  }, [nftsForDirectSale, nftsForAuction]);

  return (
    <Container fluid={+true} id="nfts-for-sale" className="p-0">
      <Card className=" pt-2 mt-2">
        <Card.Body className="p-0">
          <Row className=" mt-4 mb-0 px-3" style={{ padding: "1rem 1 rem" }}>
            <Col>
              <h4>NFTs for sale/auction</h4>
            </Col>
          </Row>

          {allNftsForSale ? (
            <Row className="mx-0 px-2 mt-3 justify-content-start g-0 mb-3">
              {allNftsForSale.map((nft, i) => {
                // //console.log(nft);
                return (
                  <Col
                    key={i}
                    className="collection-nft-col pb-2  "
                    xs="6"
                    md="4"
                    lg="4"
                    xl="3"
                    xxl="3"
                  >
                    <NftsForSaleCard nftData={nft} type={type} />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Row className="mx-0 px-2 mt-1 justify-content-start g-0 mb-4">
              <Col className="px-2">
                <small>No NFTs currently listed for sale/auction</small>
              </Col>
            </Row>
          )}

          {/* {JSON.stringify(eventsData)} */}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NftsForSale;
