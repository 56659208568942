import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { transferMapper } from "../mapper";

const queryNFTTransfers = gql`
  query ($nftId: ID!, $contractId: ID!) {
    nft(contractId: $contractId, nftId: $nftId) {
      owner {
        address
      }
      transfers {
        edges {
          cursor
          node {
            from {
              address
            }
            to {
              address
            }
            transaction {
              time
              hash
              script
              arguments
              authorizers {
                address
              }
              events {
                edges {
                  node {
                    type {
                      id
                      fields {
                        type
                        identifier
                      }
                    }
                    fields
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const useFetchTransfers = (props) => {
  const { collection, id } = props;
  const [loadTransfers, { called, loading, data }] = useLazyQuery(
    queryNFTTransfers,
    {
      variables: {
        contractId: collection,
        nftId: id,
      },
    }
  );

  let [nftTransfers, setProcessedData] = useState([]);
  let [owner, setOwner] = useState(null);

  useEffect(() => {
    if (data) {
      const owner = data.nft?.owner.address || null;
      setOwner(owner);

      const nftTransfers = data.nft?.transfers.edges.map(transferMapper(owner));
      setProcessedData(nftTransfers);
    }
  }, [data]);

  return {
    load: loadTransfers,
    transfers: nftTransfers,
    called,
    loading,
    owner,
  };
};
