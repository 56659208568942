import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import ColDetailDetails from "../ColDetailDetails/ColDetailDetails";
import ColDetailHistory from "../ColDetailHistory/ColDetailHistory";
import DetailsPropertyCard from "../detailspropertycard/detailspropertycard";
import "./ColDetailAccordion.css";

const ColDetailAccordion = ({ listingStatus }) => {
  return (
    <Row className="mb-3 ">
      <Col className="">
        <Accordion id="col-detail-acc" defaultActiveKey="1">

         {(listingStatus.itemTraits || listingStatus.itemEdition) && <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span className="fw-bold">Properties</span>
            </Accordion.Header>
            <Accordion.Body className="px-0 py-0">
              <DetailsPropertyCard listingStatus={listingStatus} />
            </Accordion.Body>
          </Accordion.Item>}


          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="fw-bold">Details</span>
            </Accordion.Header>
            <Accordion.Body className="py-0 px-0">
              <ColDetailDetails listingStatus={listingStatus} />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <span className="fw-bold">History</span>
            </Accordion.Header>
            <Accordion.Body className="px-0 py-0">
              <ColDetailHistory listingStatus={listingStatus}/>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

export default ColDetailAccordion;
