import React from "react";
import { Col, Row } from "react-bootstrap";
import "./LiveFeedWrapper.scss";

const LiveFeedTabs = ({ currentTab, setCurrentTab }) => {
  const tabArray = [
    "All",
    "Latest sales",
    "Latest listings",
    "Mints",
    "Burns",
    "Thoughts",
    "Trending"
  ];
  return (
    <div id="live-feed-tabs-wrapper">
      <Row className="d-flex align-items-center ">
        <Col className="px-0">
          {/* tabs display desktop */}
          <div id="lf-tabs-col">
            <div className="d-none d-md-flex" id="tabs-row-desktop">
              {tabArray.map((tab, i) => {
                return (
                  <div
                    onClick={() => {
                      setCurrentTab(tab);
                    }}
                    key={tab + "-tab-item"}
                    className={
                      currentTab === tab
                        ? "tab-item tab-item-active"
                        : "tab-item"
                    }
                  >
                    {tab}
                  </div>
                );
              })}
            </div>
          </div>
          {/* tabs display mobile */}

          <div className="d-block d-md-none" id="tabs-row-mobile">
            <select
              onChange={(e) => {
                setCurrentTab(e.target.value);
              }}
            >
              {tabArray.map((tab, i) => {
                return (
                  <option
                    value={tab}
                    key={tab + "-tab-item"}
                    className="tab-item"
                    selected={tab === currentTab}
                  >
                    {tab}
                  </option>
                );
              })}
            </select>
          </div>
        </Col>
        <Col className="d-none d-lg-block" xs="3">
          <div id="powered-by-wrapper">
            <span id="powered-by-span">
              <span>Powered by Graffle</span>&nbsp;&nbsp;
              <img
                id="graffle-logo"
                fluid={+true}
                src="/assets/img/home/graffle.webp"
                alt="Graffle Logo"
              />
            </span>
          </div>
        </Col>

        <Col className="d-flex d-lg-none mt-3 mb-2" xs="12">
          <div id="powered-by-wrapper">
            <span id="powered-by-span">
              <span>Powered by Graffle</span>&nbsp;&nbsp;
              <img
                id="graffle-logo"
                fluid={+true}
                src="/assets/img/home/graffle.webp"
                alt="Graffle Logo"
              />
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LiveFeedTabs;
