import React from "react";
import {
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch-hooks-web";
import useSound from "use-sound";

import names from "./names";
import { capitalize, itemLogo, itemName } from "../../../utils";

import remove from "./sounds/simple-click.aac";
import "./filter-selection.scss"
export default function Filters() {
  const options = { volume: 0.25, playbackRate: 0.8 };
  const [play] = useSound(remove, options);

  const clearRefinements = useClearRefinements();
  const currentRefinements = useCurrentRefinements();

  const numberOfItems = currentRefinements.items.length

  return (
    <div className={`doodles__filters-list ${numberOfItems === 0 && "empty"}`}>
      {numberOfItems > 0 && (
        <>
          <button
            className={"top-filter"}
            onClick={() => {
              play();
              clearRefinements.refine();
            }}
          >
            Clear All
          </button>
          <div className={"divider"}/>
          {currentRefinements.items.map((item) => {
            const prefix = names[item.attribute] || "--";
            return item.refinements.map((ref) => {
              return (
                <button
                  className={"top-filter"}
                  onClick={() => {
                    play();
                    currentRefinements.refine(ref);
                  }}
                >
                  {prefix} - {prefix === "Currency" ? itemName(ref.label) : capitalize(ref.label)}
                </button>
              );
            });
          })}
        </>
      )}
    </div>
  );
}
