export default {
	"contractName": "Wearables",
	"contractAddress": "0xe81193c424cfd3fb",
	"nftType": "A.e81193c424cfd3fb.Wearables.NFT",
	"collectionDisplay": {
		"name": "Wearables",
		"description": "Doodles 2 lets anyone create a uniquely personalized and endlessly customizable character in a one-of-a-kind style. Wearables and other collectibles can easily be bought, traded, or sold. Doodles 2 will also incorporate collaborative releases with top brands in fashion, music, sports, gaming, and more.\n\nDoodles 2 Private Beta, which will offer first access to the Doodles character creator tools, will launch later in 2022. Doodles 2 Private Beta will only be available to Beta Pass holders.",
		"externalURL": {
			"url": "https://doodles.app"
		},
		"squareImage": {
			"file": {
				"url": "https://gateway.pinata.cloud/ipfs/QmVpAiutpnzp3zR4q2cUedMxsZd8h5HDeyxs9x3HibsnJb/"
			},
			"mediaType": "image/png"
		},
		"bannerImage": {
			"file": {
				"url": "https://gateway.pinata.cloud/ipfs/QmWEsThoSdJHNVcwexYuSucR4MEGhkJEH6NCzdTV71y6GN/"
			},
			"mediaType": "image/png"
		},
		"socials": {
			"twitter": {
				"url": "https://twitter.com/doodles"
			},
			"discord": {
				"url": "https://discord.gg/doodles"
			}
		}
	}
}