import React, { useEffect, useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { CollectionTabPage } from "../collectiontabpage/collectiontabpage";
import EmptyWalletPlaceholder from "./EmptyWalletPlaceholder";
import "./ProfileCollectionWalletTabs.scss";
import Loading from "../../../loading/loading";

const MainWalletTab = ({
  walletCollection,
  walletAddress,
  profileData,
  type,
  activeTabKey,
}) => {
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionData, setSelectedCollectionData] = useState(null);
  const { name, walletProvider, colName } = useParams();
  const navigate = useNavigate();

  function handleChangeCollection(e) {
    if (e) {
      const collectionName = e;
      setSelectedCollection(collectionName);
      setSelectedCollectionData(walletCollection[collectionName]);
      if (type === "owner") {
        navigate("/me/collection/" + activeTabKey + "/" + collectionName);
      } else
        navigate(
          "/" + name + "/collection/" + activeTabKey + "/" + collectionName
        );
    }
  }

  useEffect(() => {
    setSelectedCollection(colName);
    setSelectedCollectionData(walletCollection[colName]);
  }, [colName]);

  //useeffect sets selected collection to colName url param if there and first if none
  useEffect(() => {
    if (walletCollection && !selectedCollection) {
      if (colName && !selectedCollection) {
        setSelectedCollection(colName);
        setSelectedCollectionData(walletCollection[colName]);
      } else {
        setSelectedCollection(Object.keys(walletCollection)[0]);
        setSelectedCollectionData(
          walletCollection[Object.keys(walletCollection)[0]]
        );
      }
    }
  }, [walletCollection, colName]);

  if (!walletCollection) {
    return <Loading />;
  }

  let selectItems = [];
  if (walletCollection) {
    const sortedKeys = Object.keys(walletCollection).sort((f, s) => {
      const a = f.toLowerCase();
      const b = s.toLowerCase();

      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }

      return 0;
    });

    selectItems = sortedKeys.map((collectionName) => {
      const collection = walletCollection[collectionName];
      const numOfItems = collection.extraIDs.length;
      const value = collection.extraIDsIdentifier;
      const selected = selectedCollection === value;
      const name = collection.collectionName; //extractCollectionName(collection.key)
      const label = `${name} (${numOfItems})`;

      return (
        <option selected={selected} value={value} key={label}>
          {label}
        </option>
      );
    });
  }

  return (
    <div>
      {Object.keys(walletCollection) &&
      Object.keys(walletCollection).length > 0 ? (
        <div>
          <Row className="my-4 px-3 align-items-center justify-content-between">
            <Col xs="12" md="auto" className="align-items-start">
              <span className="viewing-span">
                Viewing <span>{type === "owner" ? "your" : `${name}'s`}</span>{" "}
                <span className="fw-bold themeColor">
                  {walletCollection[selectedCollection]?.collectionName ||
                    selectedCollection}
                </span>{" "}
                collection
              </span>
            </Col>
            <Col xs="12" md="auto" className="align-items-start">
              <Form.Select
                onChange={(e) => handleChangeCollection(e.currentTarget.value)}
                className="mb-2"
                //   onChange={(e) => setSelectedDropdown(e.target.value)}
              >
                {selectItems}
              </Form.Select>
            </Col>
          </Row>
          {selectedCollection && selectedCollectionData && (
            <Tabs className="collection-tabs" activeKey={selectedCollection}>
              {Object.keys(walletCollection) &&
                Object.keys(walletCollection).map((collectionName, i) => {
                  return (
                    <Tab
                      title={collectionName}
                      key={collectionName}
                      mountOnEnter
                      eventKey={collectionName}
                    >
                      <Row className="hits-row px-2">
                        <CollectionTabPage
                          collectionData={walletCollection[collectionName]}
                          name={name}
                          address={walletAddress}
                          profileData={profileData}
                          activeTabKey={activeTabKey}
                        />
                      </Row>
                    </Tab>
                  );
                })}
            </Tabs>
          )}
        </div>
      ) : (
        <EmptyWalletPlaceholder />
      )}
    </div>
  );
};

export default MainWalletTab;

{
  /* collection-tabs name tag title - eventually for v3 desgin we can have these as the collection square image if we have a good way to fetch
					can use catalog info for catalog items but shard ones I'm not sure how we would gety the square image
					for now - set to display none and collection change is handled by routing / dropdown select
					*/
}
