import {
  Col,
  Container,
  Image,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { UseThemeStatus } from "../../../functions/themeMode";
import "./builton.css";

export function BuiltOn() {
  const renderTooltipFlow = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Built on Flow</span>
    </Tooltip>
  );
  const renderTooltipGraffle = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Graffle</span>
    </Tooltip>
  );

  const renderTooltipBlocto = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Blocto</span>
    </Tooltip>
  );
  const renderTooltipLilico = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Lilico</span>
    </Tooltip>
  );
  const renderTooltipTypesense = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Typesense</span>
    </Tooltip>
  );

  return (
    <Container id="builton" className="py-md-5 ">
      <Row className="justify-content-center pt-md-5  mt-0 align-items-center d-none d-md-flex">
        <Col xs="auto">
          <span>
            <h3 className="mb-0">Proudly powered by:</h3>
          </span>
        </Col>
        <OverlayTrigger placement="top" overlay={renderTooltipFlow}>
          <Col xs="auto">
            <a href="https://www.onflow.org" target="_blank" rel="noreferrer">
              <Image
                src={
                  UseThemeStatus() === "light"
                    ? "/assets/img/home/logo-flow-light.svg"
                    : "/assets/img/home/logo-flow-dark.svg"
                }
                alt="flow-logo"
                height="64"
              />
            </a>
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={renderTooltipBlocto}>
          <Col xs="auto">
            <a href="https://portto.com/" target="_blank" rel="noreferrer">
              <Image
                src={
                  UseThemeStatus() === "light"
                    ? "/assets/img/home/logo-blocto-light.svg"
                    : "/assets/img/home/logo-blocto-dark.svg"
                }
                alt="blocto-logo"
                height="50"
              />
            </a>
          </Col>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={renderTooltipLilico}>
          <Col xs="auto">
            <a
              alt="lilico-logo"
              href="https://lilico.app/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                id=""
                src="/assets/img/home/logo-lilico-text.svg"
                alt="lilico-logo"
                height="64"
              />
            </a>
          </Col>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={renderTooltipTypesense}>
          <Col xs="auto">
            <a
              alt="typesense-logo"
              href="https://typesense.org/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                id=""
                src={
                  UseThemeStatus() === "light"
                    ? "/assets/img/home/logo-typesense.svg"
                    : "/assets/img/home/logo-typesense-dark.svg"
                }
                alt="typesense-logo"
                height="64"
              />
            </a>
          </Col>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={renderTooltipGraffle}>
          <Col xs="auto">
            <a
              alt="footer-graffle-logo"
              href="https://www.graffle.io"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/assets/img/home/graffle.webp"
                alt="graffle-logo"
                height="64"
              />
            </a>
          </Col>
        </OverlayTrigger>
      </Row>
      <Container className="px-2">
        <Row className="justify-content-center pt-5 pt-md-0 mb-0 align-items-center d-flex d-md-none">
          <Col align="center" xs="12" md="auto">
            <span>
              <h3>Proudly powered by:</h3>
            </span>
          </Col>
          <OverlayTrigger placement="top" overlay={renderTooltipFlow}>
            <Col className="py-3   mt-3" align="center" xs="12" md="auto">
              <a href="https://www.onflow.org" target="_blank" rel="noreferrer">
                <Image
                  src={
                    UseThemeStatus() === "light"
                      ? "/assets/img/home/logo-flow-light.svg"
                      : "/assets/img/home/logo-flow-dark.svg"
                  }
                  alt="flow-logo"
                  height="64"
                />
              </a>
            </Col>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={renderTooltipLilico}>
            <Col className="py-3  " align="center" xs="12" md="auto">
              <a
                alt="footer-lilico-logo"
                href="https://lilico.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  id=""
                  src="/assets/img/home/logo-lilico-text.svg"
                  alt="lilico-logo"
                  height="64"
                />
              </a>
            </Col>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={renderTooltipBlocto}>
            <Col className="py-3  " align="center" xs="12" md="auto">
              <a href="https://portto.com/" target="_blank" rel="noreferrer">
                <Image
                  src={
                    UseThemeStatus() === "light"
                      ? "/assets/img/home/logo-blocto-light.svg"
                      : "/assets/img/home/logo-blocto-dark.svg"
                  }
                  alt="blocto-logo"
                  height="50"
                />
              </a>
            </Col>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={renderTooltipTypesense}>
            <Col className="py-3  " align="center" xs="12" md="auto">
              <a href="https://portto.com/" target="_blank" rel="noreferrer">
                <Image
                  src={
                    UseThemeStatus() === "light"
                      ? "/assets/img/home/logo-typesense.svg"
                      : "/assets/img/home/logo-typesense-dark.svg"
                  }
                  alt="typesense-logo"
                  height="50"
                />
              </a>
            </Col>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={renderTooltipGraffle}>
            <Col className="py-3 pb-4 pb-mb-3  " align="center" xs="12" md="auto">
              <a
                alt="footer-graffle-logo"
                href="https://www.graffle.io"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src="/assets/img/home/graffle.webp"
                  alt="graffle-logo"
                  height="64"
                />
              </a>
            </Col>
          </OverlayTrigger>
        </Row>
      </Container>
    </Container>
  );
}
