import React from 'react'
import { Image, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SearchOffCanvas from './searchoffcanvas'
import './navhead.css'
import parseImgUrl from '../../functions/parseImgUrl'

const NavheadMobileTop = ({profile, setExpanded, user, expanded}) => {
  return (
    <>
    <div className="d-block d-lg-none ms-auto"></div>
          <div className="profileMenuPic px-2 d-block d-lg-none">
            {user.loggedIn && profile?.avatar && (
              <Link to="/me">
                <Image crossOrigin='anonymous' src={parseImgUrl(profile.avatar, 'icon')} />
              </Link>
            )}
          </div>
          <div className=" px-2 d-block d-lg-none">
            <SearchOffCanvas key="1" placement={"top"} name={"top"} />
          </div>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="responsive-navbar-nav"
          />
    </>
  )
}

export default NavheadMobileTop