import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../functions/parseImgUrl";
import useListingStatus from "../../../functions/useListingStatus";
import MpDetailLightBox from "./mpdetailightbox";

const MpDetailNameAndCol = ({
  hits,
  hit,
  isName,
  itemMediaType,
  videoMediaLink,
  listingStatus,
}) => {
  let link 
  if (hit && hit.nft_thumbnail){
    link = parseImgUrl(hit.nft_thumbnail)
  }

  return (
    <div className="">
            {/* <Col xs="12" align='center' className="d-block d-sm-none mb-4 ">
            {link && (link.includes('.mp4')|| hit.collection_alias === 'THiNG.FUND Membership Badge')  ? (
          <video
          key={hit.nft_thumbnail}
          muted
          alt={"Video of " + hit.nft_name}
          loop=""
          playsInline=""
          controls
          autoPlay
          onMouseOver={(event) => event.target.play()}
          onMouseOut={(event) => event.target.pause()}
          style={{ cursor: "pointer", width: '90vw' }}
        >
          <source src={link + "#t=1.0"} type="video/mp4"></source>
          Sorry this video is not supported by your browser
        </video>
        ) : (
          (itemMediaType === "" || itemMediaType == "image") && (
            // <Card.Img src={imgUrl} key={imgUrl} alt={"Picture of " + nftDetails.name} />
            <MpDetailLightBox hits={hits} isName={isName} />
          )
        )}
      </Col> */}
      <>
      <Link style={{ textDecoration: "none" }} to={/mp/ + hit.collection_name}>
        <Row className=" my-2 align-items-center ">
          <Col className="">
            <Image
              style={{
                height: "1.2rem",
                width: "1.2rem",
                marginRight: "0.5rem",
              }}
              fluid={+true}
              src="/assets/img/marketplace/verify1.png"
            />
            <span>{hit.collection_alias.replace("FIND", ".find").replace('bl0x', 'Bl0x')}</span>
          </Col>
          {hit.nft_rarity && (
            <Col className="" align="right" xs="auto">
              {hit.nft_edition && (
                <span className="small fw-bold">
                  {hit.nft_edition} / {hit.nft_max_edition}{" "}
                </span>
              )}

              <span className="small fw-bold">
                {hit.nft_rarity[0].toUpperCase() + hit.nft_rarity.substring(1)}
              </span>
            </Col>
          )}
          <Col className="" align="right" xs="auto">
            {listingStatus.itemEdition && (
              <span className="small fw-bold secColor">
                {listingStatus.itemEdition}{" "}
                {listingStatus.itemEditionMax && (
                  <> / {listingStatus.itemEditionMax}&nbsp;&nbsp;</>
                )}
              </span>
            )}
          </Col>
        </Row>
      </Link>
      <Col xs="12" className="">
        <h3 className="">
          {isName ? (
            <>
              <span className="themeColor">{hit.nft_name}</span>.find
            </>
          ) : (
            hit.nft_name
          )}
        </h3>
      </Col>
      </>

    </div>
  );
};

export default MpDetailNameAndCol;
