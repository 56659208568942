import { useSearchBox } from "react-instantsearch-hooks-web";
import "./CustomSearchBox.css";

function CustomSearchBox({props, mobile}) {
  const { query, refine } = useSearchBox(props);
  // console.log(query);
  return (
    <form action="" role="search" className="main-search">
      <div className="search-wrapper-box">
        <i className="fa-solid fa-magnifying-glass search-icon mx-2" />
        <input
          className="input search-input smallTextSpan"
          id="algolia_search"
          type="search"
          placeholder="Search marketplace"
          defaultValue={query}
          onChange={(e) => refine(e.currentTarget.value)}
        />
      </div>
    </form>
  );
}

export default CustomSearchBox;
