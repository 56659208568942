import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useSortBy } from "react-instantsearch-hooks-web";
import DoodlesCurrencyRefinement from "../Currency";

const refToLabel = {
  "market/sort/amount:asc": "Price (Asc.)",
  "market/sort/amount:desc": "Price (Desc.)",
  "market/sort/nft_id:asc": "ID (Asc.)",
  "market/sort/nft_id:desc": "ID (Desc.)",
  "market/sort/nft_name:asc": "Name (Asc.)",
  "market/sort/nft_name:desc": "Name (Desc.)",
  "market/sort/updated_at:asc": "Updated (Asc.)",
  "market/sort/updated_at:desc": "Updated (Desc.)",
};

const items = [
  { label: "Price (Asc.)", value: "market/sort/amount:asc" },
  { label: "Price (Desc.)", value: "market/sort/amount:desc" },
  { label: "ID (Asc.)", value: `market/sort/nft_id:asc` },
  { label: "ID (Desc.)", value: `market/sort/nft_id:desc` },
  { label: "Name (Asc.)", value: `market/sort/nft_name:asc` },
  { label: "Name (Desc.)", value: `market/sort/nft_name:desc` },
  { label: "Updated (Asc.)", value: `market/sort/updated_at:asc` },
  { label: "Updated (Desc.)", value: `market/sort/updated_at:desc` },
];

export default function Sort() {
  const { refine, currentRefinement } = useSortBy({ items });
  const sortTitle = refToLabel[currentRefinement] || "Sort"

  return (
    <div className="doodles__sorting__buttons">
    <DoodlesCurrencyRefinement />
    <DropdownButton
    title={sortTitle}
    as={ButtonGroup}
    id={`sorting-dropdown`}
    bsPrefix="filter-selection"
    onSelect={(key) => {
      refine(key);
    }}
  >
    {items.map((item, i) => {
      const { value, label } = item;
      const active = value === currentRefinement;
      return (
        <Dropdown.Item eventKey={value} active={active}>
          {label}
        </Dropdown.Item>
      );
    })}
  </DropdownButton>
  </div>);
}
