import React, {useState, useEffect} from "react";
import { Button } from "react-bootstrap";

import parseImgUrl from "../../../../../functions/parseImgUrl";
import { getFindStatus } from "../../../../../functions/flow-interactions/scripts";

import "./ColDetailOwnItemSendItem.scss";

const ColDetailOwnItemSendItemInfo = ({
  setCurrentStage,
  listingStatus,
  sendNFTAddress,
  setSendNFTAddress,
  sendNFTMemo,
  setSendNFTMemo,
  handleSendNFTModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)

  const disabled = loading || sendNFTAddress.length < 3 || error !== null

  function checkCollection(info) {
    if (listingStatus.itemCollection === "Wearables") {
      if (!info.readyForWearables) {
        return "Specified account is not configured to receive this item"
      }
    }
    return null
  }

  async function checkAddress(){
    const notEmpty = sendNFTAddress !== ""
    const validName = notEmpty && sendNFTAddress.length >=3 && sendNFTAddress.length <= 18
    const validAddress = notEmpty && sendNFTAddress.startsWith("0x") && sendNFTAddress.length === 18
    if(validName || validAddress){
      const user = sendNFTAddress.toLowerCase()
      setLoading(true);
      setError(null)
      const info = await getFindStatus(user)

      setLoading(false)

      if (info) {
        setError(checkCollection(info))
      } else {
        setError("Account with specified name or address doesn't exist")
      }
    }
  }

  // Check that provided name is OK
  useEffect(()=>{
    if(!sendNFTAddress){
      setError(null)
    }
    if(sendNFTAddress && sendNFTAddress.length < 3){
      setError("Not a valid name or address")
    } else{
      checkAddress()
    }
  },[sendNFTAddress])

  return (
    <div id="send-item-info-wrapper">
      <div id="send-item-icon-row">
        <img src={parseImgUrl(listingStatus.itemThumbnail)} />{" "}
        <img
          id="send-arrows-img"
          src="/assets/img/profile/send-item/arrows-right.svg"
        />
        <img src="/assets/img/profile/send-item/avatar-blank.svg" />
      </div>
      <div id="send-item-name-row">Send {listingStatus.itemName}</div>
      <div id="send-item-desc-row">
        Enter the .find name or the wallet address of the person you would like
        to send this item to
      </div>
      <div id="send-item-form-wrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setCurrentStage(2);
          }}
          id="send-item-form"
        >
          <label htmlFor="send-to-input">Send to</label>

          <input
            className={"my-0 mb-1"}
            value={sendNFTAddress}
            id="send-to-input"
            label="Send to"
            maxLength="18"
            type='text'
            pattern="[a-zA-Z0-9-.]+"
            onChange={(e) => setSendNFTAddress(e.target.value.toLowerCase())}
            placeholder="Enter a wallet or .find name"
          ></input>
          {error && !loading && <p className="smallTextSpan red my-0">{error}</p>}
          {loading && <p className="smallTextSpan my-0 green">Checking address...</p>}

          <label htmlFor="send-memo-input" className={"mt-3"}>Message (Optional)</label>
          <textarea
            value={sendNFTMemo}
            id="send-memo-input"
            onChange={(e) => setSendNFTMemo(e.target.value)}
            placeholder="Enter a message..."
          ></textarea>

          <div id="send-item-button-row">
            <Button
              variant="find-outline-commerce-light"
              onClick={() => handleSendNFTModal()}
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              className={disabled ? "opacity-25" : "opacity-100"}
              variant="find-outline-commerce-dark"
              type="submit"
              disabled={disabled}
            >
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ColDetailOwnItemSendItemInfo;

{
  /* <div className="flex-center mb-3">
    <Image src={parseImgUrl(listingStatus.itemThumbnail)} />
  </div>
  <Form onSubmit={(e) => sendNFT(e)}>
    <fieldset disabled={useFormStatus()}>
      <Form.Group>
        <Form.Label>Receiver Address</Form.Label>
        <Form.Control
          name="receiver"
          placeholder="Enter a wallet address or .find name"
          type="receiver"
          onChange={(e) => setSendNFTAddress(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Form.Group className="mt-3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          name="memo"
          placeholder="Enter a message"
          type="memo"
          onChange={(e) => setSendNFTMemo(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <Col align="center" className="mt-4">
        <Button
          type="submit"
          variant="find-outline-commerce-dark"
          disabled={sendNFTAddress.length <= 1}
          // onClick={sendNFT}
        >
          Send
        </Button>
      </Col>
    </fieldset>
  </Form> */
}
