import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  handleBuy,
  handleBuyItemDirectSale,
} from "../../../functions/flow-interactions/transactions";
import { useWalletType } from "../../../functions/useWalletType";
import MarketplaceItemBoughtModal from "../../marketplace/marketplaceitemboughtmodal/marketplaceitemboughtmodal";

const MpDetailBuyNow = (props) => {
  const {
    hit,
    isName,
    listingDetails,
    toastStatus,
    themeStatus,
    buyNowClicked,
    setBuyNowClicked,
    query,
    findUser,
    itemAmount,
    listingStatus,
  } = props;

  const walletType = useWalletType();
  const [showPopup, setShowPopup] = useState(false);

  const callbacks = {
    onSuccess: () => setShowPopup(true),
  };

  const onClick = isName
    ? () => {
        setBuyNowClicked(true);
        handleBuy(
          hit.nft_name,
          listingDetails.leaseStatus.salePrice,
          hit.seller,
          walletType,
          callbacks
        );
      }
    : () => {
        setBuyNowClicked(true);
        handleBuyItemDirectSale(
          hit.seller,
          itemAmount,
          hit.uuid,
          walletType,
          callbacks
        );
      };

  return (
    <>
      <Button className="w-100" variant="find-nochange-dark" onClick={onClick}>
        <small>Buy Now</small>
      </Button>

      <Modal
        data-theme={themeStatus}
        show={showPopup}
        onHide={() => setShowPopup(false)}
        centered
      >
        <MarketplaceItemBoughtModal
          nftDetails={hit}
          isName={isName}
          query={query}
        />
      </Modal>
    </>
  );
};

export default MpDetailBuyNow;
