import React from "react";
import { Button } from "react-bootstrap";

const DropPageHeader = ({ packDetails }) => {
  return (
    <div className="drop-page-header-wrapper">
      <a href="#drop-info">
        <div className="new-drop-span-wrapper">
          <span className="new-drop-span">New drop!</span>&nbsp; Learn more&nbsp;
          <i className="fa-solid fa-arrow-right me-1 ms-1" />
        </div>
      </a>
      <div className="header-title-span">Party Favors X Afterfuture</div>
      <div className="header-claim-button-wrap">
        <a href="#claim-block">
          <Button variant="find-claim-pack-yellow">Claim or purchase</Button>
        </a>
      </div>
    </div>
  );
};

export default DropPageHeader;
