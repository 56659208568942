import { usePagination } from "react-instantsearch-hooks-web";

export default function CustomCount(props) {
  const { nbHits } = usePagination(props);

  return (
    <span className=""
      style={{
        color: "var(--text-secondary)",
        overflow: "auto",
        whiteSpace: "nowrap",
        fontSize: 'small',
      }}
    >
      &nbsp;{nbHits} available NFTs and names
    </span>
  );
}
