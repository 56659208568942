import { Column, Row } from "../components";
import { ProgressLine } from "./components";

export default function Progress(props) {
  const { label, progress } = props;
  const { precision = 1 } = props;
  const { current, total } = progress;
  const percent = 100 * current / total;
  const percentLabel = percent.toFixed(precision);
  return (
    <Column>
      <Row className={"dp__space-between dp__mb dp__roboto dp__small-text"}>
        <div>{label}</div>
        <div>
          ({percentLabel}%) {current} out of {total}
        </div>
      </Row>
      <ProgressLine fill={percent} />
    </Column>
  );
}
