import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import parseImgUrl from "../../functions/parseImgUrl";
import "./LiveFeedWrapper.scss";
import TimeAgo from "react-timeago";
import {
  handleHideFindThoughts,
  handleReactToThought,
} from "../../functions/flow-interactions/transactions";
import { useStateChanged } from "../../functions/DisabledState";
import { Script } from "../../functions/script";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import { Link } from "react-router-dom";
import { useUserProfile } from "../../functions/useUserProfile";
import axios from "axios";

const LiveFeedEventItemThoughtTabThought = ({ thought }) => {
  const [showReactionInput, setShowReactionInput] = useState(false);
  const [mutableThought, setMutableThought] = useState(null);
  const [thoughtHidden, setThoughtHidden] = useState(false);
  const [thoughtEvent, setThoughtEvent] = useState(null);
  const graffleBaseUrl = process.env.REACT_APP_GRAFFLE_SEARCH_URL + "search?";
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  useEffect(() => {
    if (!mutableThought && thought) {
      setMutableThought(thought);
      if (thought.hidden) {
        setThoughtHidden(true);
      }
    }
    const getEvents = async () => {
      // //console.log("getSales fired")
      let data;
      let res = await axios.get(
        graffleBaseUrl +
          `eventType=A.${contractAddress}.FindThoughts.Published&id=${thought.id}`
      );
      data = res.data;

      setThoughtEvent(data);
    };

    getEvents();
  }, [thought]);
  // console.log(thoughtEvent)
  const user = useUserProfile();

  useEffect(() => {
    setShowReactionInput(false);
    if (mutableThought) {
      async function getFindThoughts(addresses, ids) {
        const getThoughts = await Script(scripts.getFindThoughts, {
          addresses: addresses,
          ids: ids,
        });
        setMutableThought(getThoughts[0]);
      }
      try {
        // console.log("this");
        getFindThoughts([thought.creator], [Number(thought.id)]);
        // console.log(thought);
      } catch (error) {
        console.log(error);
      }
    }
  }, [useStateChanged()]);

  const reactionArray = [
    "🔥",
    "❤️",
    "👍",
    "🤣",
    "🙏",
    "🚀",
    "💯",
    "👀",
    "😐",
    "🤯",
  ];

  useEffect(() => {
    if (showReactionInput) {
      document.getElementById("reaction-icon-row").focus();
    }
  }, [showReactionInput]);

  const profileImg = (
    <img
      crossOrigin="anonymous"
      style={{ width: "48px", height: "48px", borderRadius: "50%" }}
      src={
        mutableThought
          ? parseImgUrl(mutableThought.creatorAvatar, "icon")
          : null
      }
    />
  );
  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    )
  );

  const handleReactClicked = (creator, id, reactIcon) => {
    console.log(reactIcon);
    handleReactToThought(creator, id, reactIcon);
  };

  const renderTooltipReactions = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>Add reaction</span>
    </Tooltip>
  );

  if (mutableThought && mutableThought.hidden) {
    const creatorLink = mutableThought.creatorName
      ? `/${mutableThought.creatorName}`
      : `/${mutableThought.creator}`;
    return (
      <div
        key={mutableThought.id}
        id="live-feed-event-item-wrapper"
        className="py-3"
      >
        <div id="live-feed-event-item-row">
          <div id="lf-item-icon-box">
            <Link to={creatorLink}>
              {profileImg}
              {mutableThought.creatorName && (
                <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
              )}{" "}
            </Link>
          </div>

          <div id="lf-item-info-box">
            <div id="live-feed-event-item-thought-profile-name-row">
              <Link to={creatorLink}>
                <span>{mutableThought.creatorProfileName}</span>
              </Link>
            </div>
            {mutableThought.creatorName && (
              <div id="live-feed-event-item-thought-find-name-row">
                <Link to={creatorLink}>
                  <span>
                    <span className="themeColor">
                      {mutableThought.creatorName}
                    </span>
                    .find
                  </span>
                </Link>
              </div>
            )}

            <div id="live-feed-event-item-thought-content">
              This thought post was hidden
            </div>
          </div>
          <div id="lf-item-end-box">
            <div id="live-feed-event-item-price-row">
              <div id="lf-item-price-box">
                {/* {event.blockEventData.amount}&nbsp;{switchedEvent.txFtType} */}
              </div>
              <div id="lf-item-kebab-box">
                {user && user.profile.address === mutableThought.creator && (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomDropdownToggle}
                      id="kebab-dropdown-toggle"
                    >
                      <img src="/assets/img/lf/lf-kebab.svg" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="secColor small"
                        onClick={() => {
                          handleHideFindThoughts(
                            [Number(mutableThought.id)],
                            [false]
                          );
                        }}
                      >
                        Show this thought post
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
            <div id="lf-item-time-box">
              <TimeAgo
                date={new Date(mutableThought.created * 1000)}
                timestyle="twitter"
                suffix="none"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mutableThought && !mutableThought.hidden) {
    const creatorLink = mutableThought.creatorName
      ? `/${mutableThought.creatorName}`
      : `/${mutableThought.creator}`;
    // console.log(mutableThought);
    return (
      <div
        key={mutableThought.id}
        id="live-feed-event-item-wrapper"
        className="py-3"
      >
        <div id="live-feed-event-item-row">
          <div id="lf-item-icon-box">
            <Link to={creatorLink}>
              {profileImg}
              {mutableThought.creatorName && (
                <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
              )}{" "}
            </Link>
          </div>

          <div id="lf-item-info-box">
            <div id="live-feed-event-item-thought-profile-name-row">
              <Link to={creatorLink}>
                <span>{mutableThought.creatorProfileName}</span>
              </Link>
            </div>
            {mutableThought.creatorName && (
              <div id="live-feed-event-item-thought-find-name-row">
                <Link to={creatorLink}>
                  <span>
                    <span className="themeColor">
                      {mutableThought.creatorName}
                    </span>
                    .find
                  </span>
                </Link>
              </div>
            )}
            <div id="live-feed-event-item-thought-header">
              {mutableThought.header}
            </div>

            <div id="live-feed-event-item-thought-content">
              {mutableThought.body}
            </div>
            {thought.tags[0] !== "" && thought.tags.length > 0 && (
              <div id="live-feed-event-item-thought-tag-row" style={{}}>
                {/* <i className="fa-solid fa-tags"></i> &nbsp; */}
                {thought.tags.map((tag, index) => (
                  <div key={index} className="tag-item">
                    <span className="tag-text">{tag}</span>
                  </div>
                ))}
              </div>
            )}
            <div id="live-feed-event-item-thought-reactions-row">
              <OverlayTrigger placement="top" overlay={renderTooltipReactions}>
                <div
                  onClick={() => {
                    setShowReactionInput(!showReactionInput);
                  }}
                  id="thought-reaction-toggle"
                >
                  {" "}
                  <div id="thought-reaction-box">
                    {!showReactionInput ? "➕" : "➖"}
                  </div>
                </div>
              </OverlayTrigger>

              {mutableThought.reacted &&
                Object.keys(mutableThought.reacted) &&
                Object.keys(mutableThought.reacted).map((icon, i) => {
                  // console.log(mutableThought.reacted[icon]);
                  const renderTooltipReacted = (props) => (
                    <Tooltip {...props}>
                      <div id="reacted-tooltip">
                        {mutableThought.reacted[icon].map((reactor, i) => {
                          return (
                            <React.Fragment key={i}>
                              <span style={{ fontSize: "16px" }}>
                                {reactor.findName ? (
                                  <span>
                                    <span className="themeColor">
                                      {reactor.findName}
                                    </span>
                                    .find
                                  </span>
                                ) : (
                                  reactor.address
                                )}
                              </span>
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </Tooltip>
                  );
                  return (
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltipReacted}
                      key={i}
                    >
                      <div
                        onClick={() =>
                          handleReactClicked(
                            mutableThought.creator,
                            mutableThought.id,
                            icon
                          )
                        }
                        id="thought-reaction-box"
                      >
                        {icon}&nbsp;
                        {Object.keys(mutableThought.reacted[icon]).length}
                      </div>
                    </OverlayTrigger>
                  );
                })}

              {showReactionInput && (
                <div
                  tabindex={"-1"}
                  onBlur={() => setShowReactionInput(!showReactionInput)}
                  id="reaction-icon-row"
                >
                  {reactionArray.map((reactIcon, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() =>
                          handleReactClicked(
                            mutableThought.creator,
                            mutableThought.id,
                            reactIcon
                          )
                        }
                        id="thought-reaction-box"
                      >
                        {reactIcon}&nbsp;
                        {mutableThought.reactions &&
                        Object.keys(mutableThought.reactions).includes(
                          reactIcon
                        ) ? (
                          <>{mutableThought.reactions[reactIcon]}</>
                        ) : (
                          <>0</>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div id="lf-item-end-box">
            <div id="live-feed-event-item-price-row">
              <div id="lf-item-price-box">
                {/* {event.blockEventData.amount}&nbsp;{switchedEvent.txFtType} */}
              </div>
              <div id="lf-item-kebab-box">
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomDropdownToggle}
                    id="kebab-dropdown-toggle"
                  >
                    <img src="/assets/img/lf/lf-kebab.svg" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {thoughtEvent && thoughtEvent[0]?.flowTransactionId ? (
                      <Dropdown.Item
                        className="secColor small"
                        target="_blank"
                        rel="noreferrer"
                        href={
                          process.env.REACT_APP_BLOCK_VIEWER_URL +
                          thoughtEvent[0].flowTransactionId
                        }
                      >
                        View transaction
                      </Dropdown.Item>
                    ) : null}

                    {user && user.profile.address === mutableThought.creator && (
                      <Dropdown.Item
                        className="secColor small"
                        onClick={() => {
                          handleHideFindThoughts(
                            [Number(mutableThought.id)],
                            [true]
                          );
                        }}
                      >
                        Hide this thought post
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div id="lf-item-time-box">
              <TimeAgo
                date={new Date(mutableThought.created * 1000)}
                timestyle="twitter"
                suffix="none"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div id="live-feed-event-item-wrapper" className="py-3">
        <div id="live-feed-event-item-row">
          <div id="lf-item-icon-box"></div>

          <div id="lf-item-info-box">
            <div id="live-feed-event-item-thought-profile-name-row">
              <span>Fetching thought...</span>
            </div>
          </div>
          <div id="live-feed-event-item-price-row">
            <div id="lf-item-price-box">
              {/* {event.blockEventData.amount}&nbsp;{switchedEvent.txFtType} */}
            </div>
            <div id="lf-item-kebab-box">
              <img src="/assets/img/lf/lf-kebab.svg" />
            </div>
          </div>
          <div id="lf-item-time-box"> </div>
        </div>
      </div>
    );
};

export default LiveFeedEventItemThoughtTabThought;
