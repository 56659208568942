import React from "react";
import { Route, Routes } from "react-router";
import Collection from "../collection";
import MarketplaceDetailPage from "../../components/search/MarketplaceDetailPage";
import MarketPlace from "../marketplace";

// Doodles
import {DoodlesMarketplace, DoodleDetails} from "./Doodles/index";

const Info = () => {
  return <p>This is info about collection</p>;
}

export default function ImprovedMarketplace(props) {
  const { findUser, user } = props;
  return (
    <Routes>
      <Route
        index
        element={
          <MarketPlace
            findUser={findUser}
            metaTitle="Marketplace / .find"
            metaDescription="Want to buy, bid, or give an offer for an NFT? Use our filters on the marketplace to .find exactly what you are looking for"
          />
        }
      />

      {/* ========================= DOODLES ========================= */}
      {/* TODO: Define proper meta strings */}
      <Route path={"Wearables"}>
        <Route
          index
          element={
            <DoodlesMarketplace
              findUser={findUser}
              metaTitle="Doodles | Marketplace"
              metaDescription="Best buying experience for Doodles avatars and components"
            />
          }
        />
        <Route
          path={":id"}
          element={<DoodleDetails findUser={findUser} user={user} />}
        />
      </Route>

      {/* ========================= GENERIC ========================= */}
      <Route path=":colName">
        <Route index element={<Collection findUser={findUser} />} />
        <Route
          path=":nft"
          element={<MarketplaceDetailPage findUser={findUser} user={user} />}
        />
      </Route>
    </Routes>
  );
}
