import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./detailspropertycard.css";

const CatalogRoyaltiesCardMarket = ({ listingStatus }) => {
  let itemRoyalties;

  if (
    listingStatus.itemRoyalties.Sale &&
    Object.keys(listingStatus.itemRoyalties.Sale).length !== 0 &&
    Object.getPrototypeOf(listingStatus.itemRoyalties.Sale) === Object.prototype
  ) {
    itemRoyalties = listingStatus.itemRoyalties.Sale;
  }

  return (
    <Card id="nft-detail-details-card" className="nft-royalties-card">
      <Card.Body className="px-0">
        {itemRoyalties && (
          <Row className="pb-0 align-items-center">
            <Col className="propertiesWrap">
              {Object.keys(itemRoyalties).map((properties, i) => {
                return (
                  <Col
                    key={i}
                    xs="auto"
                    className="propertiesRoyalty py-2 mb-4 px-4"
                  >
                    <div className="my-3">
                      <span className="fw-bold ">Royalty Name:</span>{" "}
                      <span className=""> {itemRoyalties[properties].royaltyName}</span>
                    </div>
                    <div className="my-3">
                      <span className="fw-bold ">Paid to:</span>{" "}
                      {itemRoyalties[properties].findName ? (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"/" + itemRoyalties[properties].findName}
                        >
                          <span className="fw-bold ">
                            <span className="themeColor">
                              {itemRoyalties[properties].findName}
                            </span>
                            .find
                          </span>
                        </Link>
                      ) : (
                        itemRoyalties[properties].address
                      )}
                    </div>
                    <div className="my-3">
                      <span className="fw-bold ">Cut percentage:</span>{" "}
                      <span className=""> {(parseFloat(itemRoyalties[properties].cut) *
                        100).toFixed(2)}
                      %</span>
                    </div>
                  </Col>
                );
              })}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default CatalogRoyaltiesCardMarket;
