import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useImmer } from "use-immer";
import { handleEditProfile } from "../../functions/flow-interactions/transactions";
import "./editprofilesettings.css";
import { useFormStatus } from "../../functions/DisabledState";
import PfpSelectSettings from "./pfpselectsettings";
import TagsInput from "./tagsinput";
import toast from "react-hot-toast";
import { useWalletType } from "../../functions/useWalletType";

const EditProfileSettings = ({ findUser }) => {
  // console.log(findUser);
  const [newLinksArray, setNewLinksArray] = useState([]);
  const [tagsArray, setTagsArray] = useState(findUser.tags);
  const [removeLinksArray, setRemoveLinksArray] = useState([]);
  const [newTagsArray, setNewTagsArray] = useState([]);
  const walletType = useWalletType()

  const initialSettingsForm = {
    name: findUser.name,
    description: findUser.description,
    avatar: findUser.avatar,
    links: findUser.links,
    allowStoringFollowers: findUser.allowStoringFollowers,
  };

  let linksArray = {
    linkTitles: {},
    linkTypes: {},
    linkUrls: {},
    removeLinks: {
      removeLinks: removeLinksArray,
    },
  };

  //set object copy of user's current links and tags
  useEffect(() => {
    setNewLinksArray(findUser.links);
    setNewTagsArray(findUser.tags);
  }, [findUser]);

  const handleTagKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim() || newTagsArray.length === 10) return;
    setNewTagsArray([...newTagsArray, value]);
    e.target.value = "";
  };

  const removeTag = (index) => {
    setNewTagsArray(newTagsArray.filter((el, i) => i !== index));
  };

  const [settingsForm, setSettingsForm] = useImmer({
    name: findUser.name,
    description: findUser.description,
    avatar: findUser.avatar,
    links: linksArray,
    allowStoringFollowers: findUser.allowStoringFollowers,
  });

  const updateSettingsForm = (e) => {
    if (e.target.name === "name") {
      if (e.target.value.length > 64 || e.target.value === "") {
        document.getElementById("name").classList.add("is-invalid");
        document.getElementById("name").classList.remove("is-valid");
      } else {
        document.getElementById("name").classList.add("is-valid");
        document.getElementById("name").classList.remove("is-invalid");
      }
    }

    setSettingsForm({
      ...settingsForm,
      [e.target.name]: e.target.value,
    });
  };

  const removeLink = (e, link, index) => {
    //only do this if current findUser links object keys includes link
    if (Object.keys(findUser.links).includes(link)) {
      setRemoveLinksArray((removeLinksArray) => [...removeLinksArray, link]);
    }
    setNewLinksArray((links) => {
      const newLinks = { ...links };
      delete newLinks[link];
      return newLinks;
    });
  };

  useEffect(() => {
    if (linksArray.removeLinks.removeLinks !== removeLinksArray) {
      linksArray.removeLinks.removeLinks = removeLinksArray;
    }
  }, [newLinksArray, removeLinksArray]);

  // useEffect(() => {
  //   if (newLinksArray.length > 0) {
  //     Object.keys(newLinksArray).map((key, index) => {
  //       if (key === "") {
  //         console.log(newLinksArray);
  //         console.log(index);
  //         document.getElementById(`linkName-${index-1}`).classList.add("is-invalid");
  //         document.getElementById(`linkName-${index-1}`).classList.remove("is-valid");
  //       } else {
  //         document.getElementById(`linkName-${index-1}`).classList.add("is-valid");
  //         document.getElementById(`linkName-${index-1}`).classList.remove("is-invalid");
  //       }
        
  //       // else if (newLinksArray[key].type === "") {
  //       //   toast.error('Please select a type for your link');
  //       //   error = true;
  //       //   return;
  //       // } else if (newLinksArray[key].type.toLowerCase() != "image" && newLinksArray[key].type.toLowerCase() != "globe" && !newLinksArray[key].url.toLowerCase().includes(newLinksArray[key].type.toLowerCase())) {
  //       //   toast.error(`Please make sure you have a valid url for ${newLinksArray[key].type}`);
  //       //   error = true;
  //       //   return;
  //       // }
        
  //       return;
  //     })
  //   }
  // }, [newLinksArray])

  const updateNewLinksArrayTitle = (e, index, link) => {
    let prevUrl = newLinksArray[link].url;
    let prevTitle = newLinksArray[link].title;
    let prevType = newLinksArray[link].type;
    delete newLinksArray[Object.keys(newLinksArray)[index]];
    const { name, value } = e.target;
    if (value === "") {
      document.getElementById(`linkName-${index}`).classList.add("is-invalid");
      document.getElementById(`linkName-${index}`).classList.remove("is-valid");
    } else {
      document.getElementById(`linkName-${index}`).classList.add("is-valid");
      document.getElementById(`linkName-${index}`).classList.remove("is-invalid");
    }
    setNewLinksArray({
      ...newLinksArray,
      [value]: { url: prevUrl, title: prevTitle, type: prevType },
    });
  };

  const updateNewLinksArrayProps = (e, index, link) => {
    const { name, value } = e.target;
    newLinksArray[link][name] = value;
    if (value === "") {
      document.getElementById(`link-${name}-${index}`).classList.add("is-invalid");
      document.getElementById(`link-${name}-${index}`).classList.remove("is-valid");
    } else {
      if ((name === "type" || name === "url")) {
        if ((!value.toLowerCase().includes(newLinksArray[link].url.toLowerCase()) || !value.toLowerCase().includes(newLinksArray[link].type.toLowerCase())) && newLinksArray[link].type != "image" && newLinksArray[link].type != "globe") {
          // console.log("poop")
          document.getElementById(`link-url-${index}`).classList.add("is-invalid");
          document.getElementById(`link-url-${index}`).classList.remove("is-valid");
        } else if (newLinksArray[link].url === "") {
          document.getElementById(`link-url-${index}`).classList.add("is-invalid");
          document.getElementById(`link-url-${index}`).classList.remove("is-valid");
        } else {
          document.getElementById(`link-url-${index}`).classList.add("is-valid");
          document.getElementById(`link-url-${index}`).classList.remove("is-invalid");
        }
      } else {
        document.getElementById(`link-${name}-${index}`).classList.add("is-valid");
        document.getElementById(`link-${name}-${index}`).classList.remove("is-invalid");
      }
    }
    
  };

  const clearForm = () => {
    setSettingsForm(initialSettingsForm);
    setNewLinksArray(findUser.links);
  };

  const addNewLink = () => {
    setNewLinksArray({
      ...newLinksArray,
      "": { url: "", title: "", type: "" },
    });
  };

  const submitSettingsForm = () => {
    let error = false;

    Object.keys(newLinksArray).map((key, index) => {
      if (key === "") {
        document.getElementById(`linkName-${index}`).classList.add("is-invalid");
        document.getElementById(`linkName-${index}`).classList.remove("is-valid");
        toast.error('Link name is required');
        error = true;
        return;
      } else if (newLinksArray[key].title === "") {
        document.getElementById(`link-title-${index}`).classList.add("is-invalid");
        document.getElementById(`link-title-${index}`).classList.remove("is-valid");
        toast.error('Link title is required');
        error = true;
        return;
      } else if (newLinksArray[key].url === "") {
        document.getElementById(`link-url-${index}`).classList.add("is-invalid");
        document.getElementById(`link-url-${index}`).classList.remove("is-valid");
        toast.error('Link url is required');
        error = true;
        return;
      } else if (newLinksArray[key].type === "") {
        document.getElementById(`link-type-${index}`).classList.add("is-invalid");
        document.getElementById(`link-type-${index}`).classList.remove("is-valid");
        toast.error('Please select a type for your link');
        error = true;
        return;
      } else if (newLinksArray[key].type.toLowerCase() != "image" && newLinksArray[key].type.toLowerCase() != "globe" && !newLinksArray[key].url.toLowerCase().includes(newLinksArray[key].type.toLowerCase())) {
        toast.error(`Please make sure you have a valid url for ${newLinksArray[key].type}`);
        error = true;
        return;
      }
      
      return;
    })

    if (settingsForm.name.length > 64) {
      toast.error("Name is too long");
      error = true;
      return;
    }

    if (settingsForm.name === "") {
      toast.error("Name is required");
      error = true;
      return;
    }

    if (!error) {
      Object.keys(newLinksArray).map((key, index) => {
        // console.log(key)
            // return {[key]: newLinksArray[key].title} 
            return linksArray.linkTitles[key] = newLinksArray[key].title
      }
      )
      Object.keys(newLinksArray).map((key, index) => {
        // console.log(key)
        return linksArray.linkTypes[key] = newLinksArray[key].type
      }
      )
      Object.keys(newLinksArray).map((key, index) => {
        // console.log(key)
        return linksArray.linkUrls[key] = newLinksArray[key].url
      }
      )
    
      linksArray.removeLinks.removeLinks = removeLinksArray;
  
      
      error || handleEditProfile(settingsForm, newTagsArray, linksArray, walletType);
      // console.log(linksArray)
    }
  };

  return (
    <Container id='settings-container' fluid={+true} className="px-0 mb-5 pb-5">
      <Card>
        <Card.Body>
          <fieldset disabled={useFormStatus()}>
            <Row className="pb-3">
              {" "}
              <h4>Edit your profile</h4>
            </Row>
            <Form autoComplete="off">
              <Form.Label className="fw-bold">Name</Form.Label>
              <Form.Control
                className="mb-3 settings-input"
                name="name"
                placeholder="Name"
                id="name"
                onChange={updateSettingsForm}
                value={settingsForm.name}
              />
              <Form.Label className="fw-bold">Bio/Description</Form.Label>
              <Form.Control
                id="description"
                maxLength="255"
                className="mb-3 ms-0 settings-input"
                name="description"
                as="textarea"
                rows={3}
                placeholder="Description (max 255 characters)"
                onChange={updateSettingsForm}
                value={settingsForm.description}
              />

              <Form.Label className="fw-bold">
                Tags (Max 10)
              </Form.Label>
              {/* <Form.Control
                className="mb-3 settings-input"
                name="tags"
                placeholder={
                  findUser.tags && findUser.tags.length > 0
                    ? tagsArray.join(" ").replaceAll(" ", ", ")
                    : "Tags (Separate with a comma)"
                }
                onChange={handleChangeTags}
              />
                            <Form.Label className="fw-bold">
                Tags (Separate with a comma)
              </Form.Label> */}
              <TagsInput
                handleTagKeyDown={handleTagKeyDown}
                newTagsArray={newTagsArray}
                removeTag={removeTag}
              />

              

              <Form.Label className="fw-bold">Avatar URL</Form.Label>
              <Form.Control
                className="mb-3 settings-input"
                name="avatar"
                placeholder="URL to your avatar"
                onChange={updateSettingsForm}
                value={settingsForm.avatar}
              />
              <Row className="pb-3">
                <PfpSelectSettings findUser={findUser} />
              </Row>
              <fieldset>
                <Form.Label className="fw-bold">Links</Form.Label>

                {/* //here map links and give option to add/remove */}

                {Object.keys(newLinksArray).map((link, index) => (
                  <React.Fragment key={index}>
                    <Row className="d-flex align-items-center py-2">
                      <Col className="" xs="12" md="3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          id={`linkName-${index}`}
                          className="settings-input"
                          type="text"
                          placeholder="Add name"
                          name="name"
                          defaultValue={link}
                          onChange={(e) =>
                            updateNewLinksArrayTitle(e, index, link)
                          }
                        />
                      </Col>
                      <Col className="" xs="12" md="3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          id={`link-title-${index}`}
                          className="settings-input"
                          type="text"
                          placeholder="Add title"
                          name="title"
                          defaultValue={newLinksArray[link].title}
                          onChange={(e) =>
                            updateNewLinksArrayProps(e, index, link)
                          }
                        />
                      </Col>
                      <Col className="" xs="12" md="3">
                        <Form.Label>Url</Form.Label>
                        <Form.Control
                          id={`link-url-${index}`}
                          className="settings-input"
                          type="text"
                          placeholder="Add link"
                          name="url"
                          defaultValue={newLinksArray[link].url}
                          onChange={(e) =>
                            updateNewLinksArrayProps(e, index, link)
                          }
                        />
                      </Col>
                      <Col className="" xs="12" md="3">
                        <Form.Label>Type</Form.Label>
                        <Form.Select
                          id={`link-type-${index}`}
                          name="type"
                          defaultValue={newLinksArray[link].type.toLowerCase()}
                          onChange={(e) =>
                            updateNewLinksArrayProps(e, index, link)
                          }
                        >
                          <option value="">Choose a type</option>
                          <option value="instagram">Instagram</option>
                          <option value="twitter">Twitter</option>
                          <option value="youtube">YouTube</option>
                          <option value="discord">Discord</option>
                          <option value="globe">Website</option>
                          <option value="image">Image</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col align="right">
                        <Button
                          type="button"
                          variant="find-outline-commerce-red"
                          className=""
                          size="sm"
                          onClick={(e) => removeLink(e, link, index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}

                <Button variant="find-text" onClick={addNewLink}>
                  <small>Add a new link</small>
                </Button>
              </fieldset>

              <Row className="mt-5" align="center">
                <Col>
                  <Button
                    className="m-3"
                    variant="find-outline-dark"
                    type="button"
                    onClick={clearForm}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="m-3"
                    variant="find-nochange-dark"
                    onClick={submitSettingsForm}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </fieldset>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditProfileSettings;
