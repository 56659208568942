// Transfer Types
export const EMPTY = "empty";
export const UNKNOWN = "unknown";
export const BURN = "burn";
export const MINT = "mint";
export const MINT_AND_DEPOSIT = "minted-and-deposited";
export const TRANSFER = "transfer";
export const STAKED = "staked";
export const SWAPPED = "swapped";
export const AIRDROPPED = "airdropped";

export const MARKETPLACE_ITEM_PURCHASED = "purchased-from-marketplace";
export const MARKETPLACE_SALE_CANCELED = "marketplace-sale-canceled";
export const MARKETPLACE_SALE_CREATED = "marketplace-sale-created";

// Event Signatures
export const FLOW_DEPOSITED_EVENT =
  "A.1654653399040a61.FlowToken.TokensDeposited";
