import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useInfiniteHits } from "react-instantsearch-hooks-web";

import SingleHit from "./MarketplaceSingleHit";
import { useUserWalletStatus } from "../../functions/useWalletType";
import Bl0xLoading from "../loading/bl0xloading";

export default function CustomHitsInfinite(props) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const [loading, setLoading] = useState(false);
  const user = useUserWalletStatus();

  //the sentinel ref for tracking scroll position and loading more
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
            setLoading(true);
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <>
      {hits.map((hit, i) => (
        <Col
          key={i}
          className="my-2 d-flex align-items-center justify-content-center"
          xs="6"
          sm="6"
          lg="4"
          xl="3"
          xxl={2}
        >
          <SingleHit hit={hit} user={user} />
        </Col>
      ))}
      <div ref={sentinelRef} />
      {!isLastPage && loading && (
        <Row className="my-5">
          <Col align="center">
            <Bl0xLoading />
          </Col>
        </Row>
      )}
    </>
  );
}
