import React, { useEffect, useState } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { useCurrentRefinements, useHits } from "react-instantsearch-hooks-web";
import CustomRefinementList from "./CustomRefinements";
import CustomRarityMenu from "./CustomRarityMenu";
import { RangeSlider } from "./CustomRangeSlider";

export default function CustomAttributes(props) {
  const { hits } = useHits(props);
  const { items } = useCurrentRefinements(props);
  const [showAdvanced, setShowAdvanced] = useState(false);

  //only show advanced if 1 collection selected
  useEffect(() => {
    // console.log(items);
    if (items && items.length > 0) {
      let collectionFilters = items.filter(
        (item) => item.attribute === "collection_name"
      );
      // console.log(collectionFilters);
      if (collectionFilters[0] && collectionFilters[0].refinements) {
        if (
          collectionFilters[0].refinements.length === 1 &&
          collectionFilters[0].refinements[0].value !== "Lease"
        ) {
          setShowAdvanced(true);
        } else {
          setShowAdvanced(false);
        }
      }
      // let filteredItems = items.filter((item) => item.attribute = 'nft_alias')
    } else {
      setShowAdvanced(false);
    }
  }, [items]);
  // console.log(showAdvanced);

  // console.log(hits)

  ////console.log(JSON.stringify(hits))
  //const filters = hits.map((hit))Object.keys(hits)
  //.filter(k => hits[k].includes("string"))
  const filters = hits.map((hit) =>
    Object.keys(hit).filter(
      (k) => k.startsWith("string.") || k.startsWith("number.")
    )
  );

  // console.log(filters)
  const mergedFilters = filters.reduce((r, c) => Object.assign(r, c), {});
  // console.log(mergedFilters)

  if (Object.keys(mergedFilters).length < 1 || !showAdvanced) {
    return null;
  } else
    return (
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <span className="smallTextSpan">Advanced filters</span>
          </Accordion.Header>

          <Accordion.Body className="px-0 py-0">
            <Accordion alwaysOpen>
              {Object.keys(mergedFilters).map((key, i) => {
                // console.log(mergedFilters[key]);
                const attribute = mergedFilters[key];
                if (
                  attribute !== "string.external_url" &&
                  attribute !== "number.uuid"
                ) {
                  if (attribute.startsWith("number")) {
                    let attributeTitle = attribute
                      .replace("number.", "")
                      .split("_")
                      .map((item) => {
                        if (item === "uuid") return "Id";
                        if (item === "number") return "";
                        return item[0].toUpperCase() + item.slice(1);
                      })
                      .join(" ");

                    return (
                      <React.Fragment key={i}>
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header>{attributeTitle}</Accordion.Header>
                          <Accordion.Body className={"accordion-body--wide"}>
                            <RangeSlider attribute={attribute} />
                          </Accordion.Body>
                        </Accordion.Item>
                      </React.Fragment>
                    );
                  }

                  return (
                    <React.Fragment key={i}>
                      <Accordion.Item eventKey={i}>
                        <CustomRefinementList
                          attribute={attribute}
                          searchable
                        />
                      </Accordion.Item>
                    </React.Fragment>
                    //    <Col  xs="6" lg="3" key={i} className="mt-1 p-2 g-0">
                    //    <CustomRefinementList
                    //      attribute={mergedFilters[attribute]}
                    //    />
                    //  </Col>
                  );
                }
              })}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      // hits.filter(k => k.startsWith("string")).map((hit, i) => {
      // Object.keys.hit.filter(hit.contains("string")).map((stringhit))
      // const res = Object.keys(hit)
      //   .filter(k => k.startsWith('string'))
      //   //console.log(res)
      //   //console.log(Object.keys(hit).filter(att => att.startsWith("string")))
      //return null
      //.map(([,v]) => v)
      // return(<Col key={i}>
      //     <Card style={{borderRadius: "10px"}}>
      //         <Card.Img src={hit.nft_thumbnail.replace("ipfs://", "https://find.mypinata.cloud/ipfs/")} />
      //         <Card.Body>
      //             <p><Highlight attribute="nft_name" hit={hit}/></p>
      //             <p>{hit.seller}</p>
      //             <p>{hit.amount} - status: {hit.status}</p>
      //             <p>{hit.listing_type}</p>
      //         </Card.Body>
      //     </Card>
      // </Col>)}

      // }))
    );
}
