import React from 'react'
import truncateItemName from '../../../../functions/truncateItemName'
import './collectionnft.scss'

const CollectionNFTLower = ({itemName, itemCollection}) => {
  return (
    <div className='collection-nft-lower-wrapper'>

      <div className='collection-nft-lower-name'>{itemName}</div>
      <div className='collection-nft-lower-col-name'>{truncateItemName(itemCollection, 15)}</div>

    </div>
  )
}

export default CollectionNFTLower