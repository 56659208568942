import React from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import { useFormStatus } from "../../../../../functions/DisabledState";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import { handleMakeOfferNFT } from "../../../../../functions/flow-interactions/transactions";
import FlowSticker from "../../../../forms/ftstickers/FlowSticker";
import FUSDSticker from "../../../../forms/ftstickers/FUSDSticker";

const ColDetailMakeOfferModal = ({
  listingStatus,
  themeStatus,
  saleDetails,
}) => {
  let validated = false;

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value;
      //now validate
      if (varVal.value < 1) {
        e.target.classList.add("is-invalid");
        e.target.classList.remove("is-valid");
      } else {
        e.target.classList.add("is-valid");
        e.target.classList.remove("is-invalid");
      }
    });
    // //console.log(formValues);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues)
    if (formValues[1].value < 1) {
      document.getElementById("amount").classList.add("is-invalid");
      document.getElementById("amount").classList.add("is-valid");
      toast.error("Please enter an amount of 1 or higher");
    } else {
      handleMakeOfferNFT(formValues);
    }
  };

  // console.log(saleDetails);

  const [formValues, setFormValues] = useImmer([
    {
      id: "address",
      value: listingStatus.owner.ownerProfile.address,
    },
    {
      id: "amount",
      value: "0",
    },
    {
      id: "ftAlias",
      value: listingStatus.allowedListingActions.FindMarketSale.ftAlias[0],
    },
    {
      id: "id",
      value: listingStatus.itemId,
    },
    {
      id: "nftAlias",
      value: listingStatus.itemType,
    },
  ]);
  return (
    <div
      id="list-name"
      data-theme={themeStatus}
      className="form-inputs mp-offer-modal"
    >
      <Image
      crossOrigin="anonymous"
        fluid={+true}
        className="modal-img"
        src={parseImgUrl(listingStatus.itemThumbnail, 'thumbnail')}
      />

      {saleDetails && listingStatus.allCurrentListings.forSale.forSale && <Row className="py-3 fw-bold pb-2">
        <Col align="left" className="">
          Buy price:{" "}
        </Col>
        <Col align="right">
          {" "}
          {saleDetails.amount * 1} {saleDetails.ftAlias}
        </Col>
      </Row>}

      {/* <MpDetailOwnedBy profileDetails={profileDetails} /> */}
      <fieldset disabled={useFormStatus()}>
        {saleDetails && listingStatus.allCurrentListings.offered.userHasOffer ? (
          <Container className="commerceinfo py-3">
            <Row>
              <Col>
                <p className="fw-bold mb-0">
                  Congrats! You have made an offer of {listingStatus.allCurrentListings.offered.userOfferAmount}{" "}
                  {saleDetails.ftAlias} for {listingStatus.itemName}.
                </p>
                <div>
                  <small>
                    Check your store to see if your offer has been accepted. You can retract your offer at any time.
                  </small>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Row className="pt-3">
            <Col align="left" className="fw-bold">
              How much would you like to offer?
            </Col>
            <Col>
              {listingStatus.allowedListingActions.FindMarketSale.ftAlias[0] === "Flow" ? (
                <FlowSticker />
              ) : saleDetails.ftAlias === "FUSD" ? (
                <FUSDSticker />
              ) : (
                saleDetails.ftAlias
              )}
            </Col>
            <Row>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="form-inputs pt-3"
              >
                <Row>
                  <Form.Label>Offer amount:</Form.Label>
                </Row>
                <Row className="p-3">
                  <Col align="center" xs="12" md="6">
                    <Form.Control
                      id="amount"
                      className="w-100"
                      type="number"
                      placeholder="Input offer"
                      onChange={updateField}
                      name="amount"
                      required
                      min="0"
                    />
                  </Col>
                  <Col align="center" className="mt-2 mt-md-0" xs="12" md="6">
                    <Button
                      className="w-100"
                      variant="find-outline-commerce-dark"
                      type="submit"
                    >
                      Make Offer
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Row>
        )}
      </fieldset>
    </div>
  );
};

export default ColDetailMakeOfferModal;
