
const UppercaseFirstLetter = (string) => {
    if (isNaN(string)){
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
    }
    else return string
}

export default UppercaseFirstLetter

