import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import parseNameLink from "../../../../../functions/parseNameLink";
import UppercaseFirstLetter from "../../../../../functions/UppercaseFirstLetter";
import "./ColDetailDetails.css";

const ColDetailDetailsShard = ({ listingStatus }) => {
  // console.log(listingStatus);
  let listingDetails;
  let itemRoyalties = null;
  let itemTags = null;
  let itemTagsSorted = null;
  let itemScalars = null;
  let itemMedia = null;
  let itemThumbnail = null;
  let itemAllowedListings = null;
  let itemCurrentListings = null;
  let itemCollectionDescription = null;
  let itemEdition = null;
  let itemCollectionContract = null;
  let itemCollectionContractLink = null;
  let itemOwner = null;
  let itemType = null;
  let itemIsListed = null;

  // console.log(listingStatus);

  //owner
  if (
    listingStatus.owner &&
    listingStatus.owner.ownerProfile &&
    Object.keys(listingStatus.owner.ownerProfile).length !== 0
  ) {
    itemOwner = listingStatus.owner.ownerProfile;
  }

  // console.log(itemOwner);

  //market

  //royalties
  if (
    listingStatus.itemRoyalties.Sale &&
    Object.keys(listingStatus.itemRoyalties.Sale).length !== 0 &&
    Object.getPrototypeOf(listingStatus.itemRoyalties.Sale) === Object.prototype
  ) {
    itemRoyalties = listingStatus.itemRoyalties.Sale;
  }

  if (
    listingStatus.allowedListingActions &&
    Object.keys(listingStatus.allowedListingActions).length !== 0 &&
    Object.getPrototypeOf(listingStatus.allowedListingActions) ===
      Object.prototype
  ) {
    itemAllowedListings = listingStatus.allowedListingActions;
  }


  //details
  if (listingStatus.listingDetails) {
    listingDetails = listingStatus.listingDetails;
    //media
    if (
      listingStatus.listingDetails.media &&
      Object.keys(listingStatus.listingDetails.media).length !== 0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.media) !==
        Object.prototype
    ) {
      itemMedia = listingStatus.listingDetails.media;
    }
    //contract
    if (
      listingStatus.listingDetails.contract &&
      Object.keys(listingStatus.listingDetails.contract).length !== 0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.contract) ===
        Object.prototype
    ) {
      itemCollectionContract = listingStatus.listingDetails.contract;
      if (
        listingStatus.listingDetails.contract.name &&
        listingStatus.listingDetails.contract.address &&
        listingStatus.listingDetails.contract.public_path
      ) {
        itemCollectionContractLink =
          "A." +
          listingStatus.listingDetails.contract.address.replace("0x", "") +
          "." +
          listingStatus.listingDetails.contract.public_path.split(".")[0];
      }
    }

    if (listingStatus.listingDetails.description) {
      itemCollectionDescription = listingStatus.listingDetails.description;
    }

    if (
      listingStatus.listingDetails.metadata &&
      Object.keys(listingStatus.listingDetails.metadata).length !== 0
    ) {
      itemTags = listingStatus.listingDetails.metadata;

      if (Object.keys(itemTags)) {



         itemTagsSorted = Object.keys(itemTags).sort().reduce(
          (obj, key) => { 
            obj[key] = itemTags[key]; 
            return obj;
          }, 
          {}
        );


      }

    }

  }

  if (listingDetails) {
    return (
      <Card style={{backgroundColor: 'var(--background)'}} id="nft-detail-details-card" className="nft-details-card">
        <Card.Body>
          <Row>
            <Col xs="12">
              {itemOwner && (
                <Row className="pb-5">
                  <Col xs="auto" className="px-0">
                    <span className="fw-bold px-0"> Owner:&nbsp;&nbsp;</span>
                  </Col>
                  <Col className="px-0">
                    <span>{parseNameLink(itemOwner)}</span>
                  </Col>
                </Row>
              )}

              {/* {itemRarity && (
                <Row className="pb-5">
                  <Col xs="auto" className="px-0">
                    <span className="fw-bold px-0"> Rarity:&nbsp;&nbsp; </span>
                  </Col>
                  <Col className="px-0">
                    <span>{itemRarity}</span>
                  </Col>
                </Row>
              )} */}

              {itemThumbnail && (
                <Row className="pb-5">
                  <Col xs="12" className="px-0">
                    <span className="fw-bold px-0">
                      {" "}
                      Thumbnail:&nbsp;&nbsp;
                    </span>
                  </Col>
                  <Col className="px-0 propertiesWrap">
                    <a href={parseImgUrl(itemThumbnail)}>
                      <span>{itemThumbnail}</span>
                    </a>
                  </Col>
                </Row>
              )}

              {/* <Row>
                  <span className="fw-bold px-0 pb-5"> Rarity:</span>
                </Row> */}

              {itemMedia && (
                <Row className="pb-5 align-items-center">
                  <Col xs="12" className="px-0">
                    <span className="fw-bold px-0"> Media:&nbsp;&nbsp;</span>
                  </Col>
                  <Col className="propertiesWrap px-0">
                    {Object.keys(itemMedia).map((properties, i) => {
                      return (
                        <div key={i}>
                          {Object.keys(itemMedia[properties]).map(
                            (media, i) => {
                              return (
                                <>
                                  <span className="fw-bold">{media}: </span>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={parseImgUrl(
                                      itemMedia[properties][media], '', '', true
                                    )}
                                  >
                                    <span>{itemMedia[properties][media]}</span>
                                  </a>{" "}
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                      );
                    })}

                    {/* <a    target="_blank"
                  rel="noreferrer" href={parseImgUrl(itemThumbnail)}>{itemThumbnail}</a> */}
                  </Col>
                </Row>
              )}

              <Row className="pb-5">
                <Col xs="12" md="6" className="px-0">
                  <span className="fw-bold px-0">ID:&nbsp;&nbsp;</span>{" "}
                  <span className="propertiesWrap">
                    {listingDetails.id ? listingDetails.id : ""}
                  </span>
                </Col>
                <Col xs="12" md="6" className="px-0">
                  <span className="fw-bold px-0">UUID:&nbsp;&nbsp;</span>
                  <span className="propertiesWrap">
                    {listingDetails.uuid ? listingDetails.uuid : ""}
                  </span>
                </Col>
                {/* <Col className="propertiesWrap">{listingDetails.id}</Col> */}
              </Row>

              <div className="pb-5">
                <Row>
                  <span className="fw-bold px-0">
                    {" "}
                    Allocated market actions:
                  </span>
                </Row>
                {itemAllowedListings ? (
                  <Row className="align-items-center">
                    <Col className="propertiesWrap px-0">
                      <Row>
                        {Object.keys(itemAllowedListings).map(
                          (properties, i) => {
                            return <div key={i}>{properties}</div>;
                          }
                        )}
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row className="align-items-center">
                    <Col className="propertiesWrap px-0">
                      <Row>
                        <span>None</span>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>

              {itemCollectionDescription && (
                <div className="pb-5">
                  <Row>
                    <span className="fw-bold px-0 pb-1"> Collection:</span>
                  </Row>
                  <Row>
                    <Col className="propertiesWrap px-0">
                      <span> {itemCollectionContract.name}</span>
                      <br />
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          // href={
                          //   // "https://flowscan.org/contract/" +
                          //   // itemType.replaceAll(".NFT", "")
                          // }
                        >
                          {/* {itemType.replaceAll(".NFT", "")} */}
                        </a>
                      </span>
                      <span>
                        {" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            "https://flowscan.org/contract/" +
                            itemCollectionContractLink
                          }
                        >
                          {itemCollectionContractLink}
                        </a>
                      </span>
                      <br />
                      {itemCollectionContract.external_domain && (
                        <span>
                          <a href={itemCollectionContract.external_domain}>
                            {itemCollectionContract.external_domain}
                          </a>
                        </span>
                      )}{" "}
                      <br />
                      {itemCollectionDescription && (
                        <span>{itemCollectionDescription}</span>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Col>

          </Row>
        </Card.Body>
      </Card>
    );
  } else {
    return <p>No properties</p>;
  }
};

export default ColDetailDetailsShard;
