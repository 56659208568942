import React from "react";
import { Image } from "react-bootstrap";
import "./ftstickers.css";

export default function FlowFtSticker(props){
  const { noBg } = props;
  return (
    <div className="sticker-box">
      <span className={noBg === 'true' ? 'sticker-span-no-bg' : noBg === 'tertiary' ? 'sticker-span-tertiary' : 'sticker-span'}>
        <div>
          <Image
            className="sticker-image"
            src="/assets/img/stickerlogos/flow-sticker-logo.jpg"
          />
        </div>
        <span className="ft-span secColor">Flow</span>
      </span>
    </div>
  );
};
