import { atom, useAtom } from "react-atomic-state";

const latestEvent = atom(null);
export const setLatestEvent = (message) => {
  // console.log(message);
  latestEvent.set(message);
};

export const useLatestEvent = () => useAtom(latestEvent);

// export default LatestEvent

