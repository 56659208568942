import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { ProfileCollection } from "../profilecollection/profilecollection";

const ProfileTabsNoProfile = ({ type, tab, display, searchedAddress }) => {
  // //console.log(profileData)
  const [key, setKey] = useState("profile");
  const navigate = useNavigate();
  let { name, tabs, colName, nft, walletProvider } = useParams();

  useEffect(() => {
    if (!colName) {
      navigate("/" + searchedAddress + "/collection");
    } else if (colName && nft) {
      navigate(
        "/" +
          searchedAddress +
          "/collection/" +
          walletProvider +
          "/" +
          colName +
          "/" +
          nft
      );
    } else {
      navigate(
        "/" + searchedAddress + "/collection/" + walletProvider + "/" + colName
      );
    }
  }, [searchedAddress]);

  function handleChangeTab(k) {
    navigate("/" + name + "/" + k);
  }

  useEffect(() => {
    if (tab) {
      setKey(tab);
    }
    if (tabs) {
      setKey(tabs);
    }
    if (!tab && !tabs) {
      setKey("profile");
    }
  }, [tab, tabs]);

  return (
    <Container fluid={+true} id="profile-tabs" className="mb-3 px-0">
      <Row>
        <Col className="">
          <Tabs
            id="profile-tabs"
            className="profiletabs"
            activeKey={key}
            onSelect={(k) => handleChangeTab(k)}
          >
            <Tab
              eventKey="collection"
              title="Collection"
              mountOnEnter
              unmountOnExit
            >
              <ProfileCollection
                type={type}
                display={display}
                searchedAddress={searchedAddress}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileTabsNoProfile;
