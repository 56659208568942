import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { AuthCluster } from "../auth-cluster";
import StoreTabs from "../components/userstore/storetabs/storetabs";
import "../components/userstore/userstore.css";
import { Helmet } from "react-helmet";

const UserStore = ({ findUser, metaTitle, metaDescription }) => {
  const [filterTerm, setFilterTerm] = useState("");

  const updateForm = (e) => {
    setFilterTerm(e.target.value);
  };
  if (findUser) {
    return (
      <>
        <Container
          fluid={+true}
          id="store-wrapper"
          style={{
            backgroundColor: "var(--background-profile)",
          }}
        >
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <Container className="content-container">
            <Row className="pt-2 justify-content-center">
              <Col className="px-0 pt-4" xs="auto">
                <Row className="align-items-center justify-content-center">
                  <Col>
                    <h3 id="store-title" className="mb-0 d-none d-md-block">
                      Your Personal Store
                    </h3>
                    <h3
                      id="store-title"
                      className="mb-0 px-0 d-block d-md-none"
                    >
                      Your Personal Store
                    </h3>
                  </Col>
                  <Col className="d-none d-md-block">
                    <fieldset>
                      <Form.Control
                        onChange={updateForm}
                        value={filterTerm}
                        id="store-filter-form"
                        placeholder="Type to filter results"
                      ></Form.Control>
                    </fieldset>
                  </Col>
                </Row>
                <Row className="d-block d-md-none mt-3 px-0">
                  <Col>
                    <fieldset>
                      <Form.Control
                        onChange={updateForm}
                        value={filterTerm}
                        id="store-filter-form"
                        placeholder="Type to filter results"
                      ></Form.Control>
                    </fieldset>
                  </Col>
                </Row>

                <StoreTabs findUser={findUser} filterTerm={filterTerm} />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  } else {
    return (
      <Container className="py-5 my-5" align="center">
        <h2 className="mb-5">Please Log in</h2>
        <AuthCluster user={findUser} />
      </Container>
    );
  }
};

export default UserStore;
