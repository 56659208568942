import React from "react";
import {
  Accordion,
  Button,
  Col,
  Row,
} from "react-bootstrap";
import { setShowFilters } from "../../../functions/filtersState";
// import friendlicateCollection from "../../../functions/friendlicateCollection";
import CustomAttributes from "../CustomAtrributesMenu";
import CustomCurrentRefinementsMobile from "../CustomCurrentRefinementsMobile";
import { RangeSlider } from "../CustomRangeSlider";
import CustomRefinementList from "../CustomRefinementList";
import CustomSearchBox from "../CustomSearchBox";
import MpSideBarFloat from "../mpsidebar/mpsidebarfloat";
import "./mpmobilesidebar.css";
import { useSwipeable } from "react-swipeable";
import MpSideBarWallet from "../mpsidebar/mpsidebarwallet";
import { useWalletType } from "../../../functions/useWalletType";

const MpMobileSideBar = ({ showFilters, floatState, setFloatState, showAll, setShowAll }) => {
  // const transformItems = (items) => {
  //   // console.log(items)
  //   return items.map((item) => ({
  //     ...item,
  //     label: friendlicateCollection(item.label),
  //   }));
  // };

  const config = {
    delta: 100, // min distance(px) before a swipe starts. *See Notes*
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setShowFilters(false),
    ...config,
  });

  const wallet = useWalletType()

  return (
    <div
      {...handlers}
      id="mp-mobile-side-bar"
      className={
        showFilters ? "mp-mobile-side-bar" : "mp-mobile-side-bar-hidden"
      }
    >
      <Accordion id="scrollable-accordion" className="pt-5 px-3">
        <div className="filters-title px-2 pb-1">
          <Button
            onClick={() => setShowFilters(false)}
            className="flex-center fw-bold filterButton px-1"
            style={{
              backgroundColor: "var(--background)",
              boxShadow: "none",
              border: "none",
              cursor: "pointer",
              color: "var(--text-primary)",
            }}
          >
            {" "}
            <i
              style={{ fontSize: "27px" }}
              className="fa-solid fa-angle-left fw-bold mobile-i"
            />
            &nbsp;&nbsp;&nbsp; <h2 className="mb-0 fw-bold">Filters</h2>{" "}
          </Button>
        </div>

        <Row className="mt-4">
          <CustomSearchBox mobile={true} />
        </Row>
        {wallet !== "none" &&
        <Row className="">
          <Col className=" py-3">
            <MpSideBarWallet
              showAll={showAll}
              setShowAll={setShowAll}
              mobile={true}
            />
          </Col>
        </Row>}
        <Row className="">
          <Col className=" py-3">
            <MpSideBarFloat
              floatState={floatState}
              setFloatState={setFloatState}
              mobile={true}
            />
          </Col>
        </Row>
        <Row className="py-2 px-0 px-sm-2">
          <Col>
            <CustomCurrentRefinementsMobile />
          </Col>
        </Row>

        <Accordion.Item className="mt-1" eventKey="1">
          <Accordion.Header>
            <span className="smallTextSpan">Collection</span>
          </Accordion.Header>
          <Accordion.Body className="px-0">
            <CustomRefinementList
              attribute="collection_name"
              sortBy={["collection_name:asc"]}
              limit={3}
              showMore
              showMoreLimit={50}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <span className="smallTextSpan">Sale Type</span>
          </Accordion.Header>
          <Accordion.Body className="px-0">
            <CustomRefinementList
              attribute={"listing_alias"}
              sortBy={["listing_alias:asc"]}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <span className="smallTextSpan">Price</span>
          </Accordion.Header>
          <Accordion.Body>
            <RangeSlider attribute="amount" />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <span className="smallTextSpan">Rarity</span>
          </Accordion.Header>
          <Accordion.Body className="px-0">
            <CustomRefinementList
              attribute={"nft_rarity"}
              sortBy={["nft_rarity:asc"]}
            />{" "}
          </Accordion.Body>
        </Accordion.Item>
        <CustomAttributes />
      </Accordion>
    </div>
  );
};

export default MpMobileSideBar;
