import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton
} from "react-share";

export default function SocialButtons(props) {
	const { page, url } = props;
	const quote = `Check this out on the .find marketplace`;

	// We can skip render if we are on wrong page
	if (page !== "mpdetail") {
		return null;
	}

	return (
		<span style={{ float: "right" }}>
      <TwitterShareButton
				className="m-1"
				title={quote}
				url={url}
				hashtags={["findonflow", "onFlow"]}
			>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>

      <FacebookShareButton className="m-1" quote={quote} url={url}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <LinkedinShareButton className="m-1" url={url}>
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
    </span>
	);
}
