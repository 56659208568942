import React from "react";
import useGetFactoryCollections from "../../functions/useGetFactoryCollections";
import "./LiveFeedWrapper.scss";

const ThoughtBoxNFTSelectOwn = ({ user }) => {
  console.log(user);
  const userCollections = useGetFactoryCollections(user.profile.address);
  return (
    <div>
      select own
      {userCollections && JSON.stringify(userCollections)}
    </div>
  );
};

export default ThoughtBoxNFTSelectOwn;
