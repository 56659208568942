import { scripts } from "@findonflow/find-flow-contracts-1.0";
import { Script } from "../script";

// Profile Details

export async function getLostAndFoundNFTs(user) {
  return Script(scripts.getLostAndFoundNFTs, { user });
}

export async function getNameInfo(query) {
  const name = query.toLowerCase();
  return Script(scripts.getNameSearchbar, { name });
}

export async function getFindStatus(user) {
  return Script(scripts.getFindStatus, { user });
}

export async function getFindMarket(user) {
  return Script(scripts.getFindMarket, { user });
}

export async function getProfileReport(user) {
  // TODO: split this into consequent calls if errors are too frequent, since Access Nodes can have limits
  const fullReport = await Promise.all([
    getFindStatus(user),
    getFindMarket(user),
    Script(scripts.getFindLeaseMarket, { user }),
  ]);

  const [status, market] = fullReport;
  return {
    ...status,
    ...market,
  };
}
export async function getOwnedThoughts(address) {
  if (!address) {
    return [];
  }
  const thoughtsResponse = await Script(scripts.getOwnedFindThoughts, {
    address,
  });

  return thoughtsResponse.sort((a, b) => b.created - a.created);
}

export async function getStatus(user) {
  const FINDReport = await getProfileReport(user);
  const NameReport = await getNameInfo(user);

  return {
    FINDReport,
    NameReport,
  };
}

// Collection Details

export async function getAddressArrayCollections(addressArray) {
  let result = {};
  for (let i = 0; i < addressArray.length; i++) {
    const user = addressArray[i];
    const collection = await getUserCollections(user);

    result = {
      ...result,
      [user]: {
        ...result[user],
        ...collection,
      },
    };
  }

  return result;
}

// Some paths store the items with unresolvable types (contracts are outdated)
const blacklistedPaths = [
  "FLOATCollectionStoragePath006",
  "DappyCollection",
  "KibbleVault000",
  "KittyItemsMarketCollection000",
  "KittyItemsCollection000",
].reduce((acc, item) => {
  acc[item] = true;
  return acc;
}, {});

const isBlacklisted = (path) => !blacklistedPaths[path];

export async function getUserCollections(user) {
  const { getNFTCatalogIDs, getNFTCatalogItems } = scripts;
  return  Script(getNFTCatalogIDs, { collections: [], user });
}

export async function getExtraItems(user, collectionIDs, resolver) {
  const code = scripts["getNFTCatalogItems"];
  const raw = await Script(code, { user, collectionIDs });

  // TODO: Maybe update "collection" value inside items
  console.log({raw});
  return raw
}

// NFT Details
// This is to get more information about listing. Not sure why we are fetching profile here...
export async function getListingDetails(user, listingArgs) {
  const allDetails = new Promise.all([
    Script(scripts.getNFTDetailsNFTCatalog, listingArgs),
    Script(scripts.getNFTDetailsNFTCatalogCommunity, listingArgs),
    Script(scripts.getProfile, { user }),
  ]);

  const [catalogDetails, communityDetails] = allDetails;

  return {
    ...catalogDetails,
    ...communityDetails,
  };
}
export async function getNFTDetails(user, scriptName, id, projectName) {
  // const project = nftData.extraIDsIdentifier.replace("Bl0x", "bl0x")
  const project = projectName.replace("Bl0x", "bl0x");
  return Script(scripts[scriptName], {
    user,
    project,
    id,
    views: [],
  });
  // console.log(nftDetailResponse);
}

export async function getFindPackSaleDetailsWithUser(
  packTypeName,
  packId,
  user
) {
  return Script(scripts.getFindPackSaleDetailsWithUser, {
    packTypeName,
    packTypeId: parseInt(packId),
    user: user.addr,
  });
}

export async function getAllFindPackSaleDetails(packTypeName) {
  return Script(scripts.getAllFindPackSaleDetails, {
    packTypeName,
  });
}

export async function getPriceInFlow(usdcAmount) {
  return Script(scripts.getFlowToUsdc, {
    usdcAmount,
  });
}

export async function getNamePriceInFlow(name){
  let price;
  try{
    price = await Script(scripts.getNamePriceInFlow, {
    name
  }) } catch (e) {
    console.log(e)
  }
  return price;
}