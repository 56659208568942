import { Builder } from "../components";
import "./index.scss";

export function PriceLabel(props) {
  return Builder("price--label")(props);
}

export function PriceValue(props) {
  return Builder("price--value")(props);
}

export function FlowSticker() {
  return (
    <div className={"dp__token"}>
      <img alt="Flow Token" src="/assets/img/stickers/flow-sticker-small.png" />
      <span>FLOW</span>
    </div>
  );
}
