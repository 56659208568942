import React from "react";
import { Button } from "react-bootstrap";
import ReactGA from "react-ga4";
import { Tx } from "../../../functions/script";
import { transactions } from "@findonflow/find-flow-contracts-1.0";
import { useNavigate } from "react-router";
import "./UpdateProfileWrapper.scss";
import { useFormStatus } from "../../../functions/DisabledState";

const UpdateProfileWrapper = ({ findUser }) => {
  // console.log(findUser);
  const navigate = useNavigate();
  const formStatus = useFormStatus();
  const handleFixAccount = async (value) => {
    try {
      await Tx({
        tx: transactions.fixAccount,
        args: {},
        callbacks: {
          async onSuccess(status) {
            navigate("/me");
            window.scrollTo(0, 0);

            console.log("success");
            ReactGA.event({
              category: "Fixed Account",
              action: "User Fixed Account",
              label: "Account Fix",
            });
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (findUser.needsUpdate) {
    return (
      <div id="update-profile-wrapper">
        <span id="update-profile-title-span">Your profile needs updating!</span>
        {/* <span id="update-profile-title-span">
          Please press the button below to continue
        </span> */}

        <span className="pb-3" id="update-profile-info-span">
          An issue found with contracts by Matrix Market has left some wallets
          vulnerable to having their FUSD/FLOW tokens drained from their wallet.
        </span>

        <span className="pb-3" id="update-profile-info-span">
          You can read more about this issue{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/0xMatrixMarket/status/1595340742297354243?cxt=HHwWhoC8hfz85KMsAAAA"
          >
            on Matrix Market's twitter
          </a>{" "}
          (external link).
        </span>
        <span className="pb-3" id="update-profile-info-span">
          If you are seeing this warning, you are susceptible to the attack and
          should take immediate action.
        </span>
        <span className="pb-3" id="update-profile-info-span">
          Please run the profile fix transaction by pressing the button below.
        </span>
        <Button
          disabled={formStatus}
          variant="find"
          onClick={() => handleFixAccount()}
        >
          Fix profile
        </Button>
      </div>
    );
  } else if (!findUser.needsUpdate) {
    return (
      <div id="update-profile-wrapper">
        <span id="update-profile-title-span">
          Your profile is all up to date!
        </span>
      </div>
    );
  }
};

export default UpdateProfileWrapper;
