import React from "react";
import "./createprofile.scss";

const CreateProfileFormDesc = ({ currentStage }) => {
  const descContent =
    currentStage === 2
      ? [
          "Assign a profile name to create your .find profile and view your Flow NFTs.",
          "You can change this and link it to a .find name at any time.",
        ]
      : currentStage === 3
      ? [
          "Search for a .find name that you want to register.",
          "This will link your 18 digit '0x...' wallet address to a name that can be used across the Flow ecosystem.",
        ]
      : currentStage === 4
      ? [
          "Now you can complete your profile set up, explore .find or checkout our marketplace.",
          "Select any of the options below to continue.",
        ]
      : null;

  return (
    <div>
      <div id="create-profile-form-desc">
        {descContent.map((clause, i) => {
          return <><span>{clause}</span><br/></>;
        })}
      </div>
    </div>
  );
};

export default CreateProfileFormDesc;
