import { Col } from "react-bootstrap";
import { useHits } from "react-instantsearch-hooks-web";
import SingleHit from "./MarketplaceSingleHit";
import './marketplace.css'

export default function CustomHits({props, showSideBarFilters}) {
  const { hits} = useHits(props);

  return hits.map((hit, i) => (
    <Col key={i} className="mt-3 d-flex align-items-center justify-content-center" xs="6" sm='6' lg='4' xl='3' xxl={showSideBarFilters ? 2 : 2}>
      <SingleHit hit={hit} />
    </Col>
  ));
}
