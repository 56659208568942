import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import EventsSwitch from "../../../../functions/EventsSwitch";
import parseImgUrl from "../../../../functions/parseImgUrl";
const StoreBuyingWon = ({ findUser, filterTerm }) => {
  const [allStoreEvents, setAllStoreEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState("");
  const [runOnce, setRunOnce] = useState(false);
  const [dt, setDt] = useState(new Date().getTime());

  // //console.log(dt)

  let boughtActivity = {};
  const graffleLogo = (
    <Image
      fluid={+true}
      src="/assets/img/home/graffle.webp"
      alt="Graffle Logo"
    />
  );
  const findLogo = (
    <Image fluid={+true} src="/assets/img/store/fnamestore.png" />
  );
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  // add view button
  // winning bid/offer depending on salestype

  useEffect(() => {
    const getEvents = async () => {
      // //console.log("getSales fired")
      let data;
      let res = await axios.get(
        "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?eventType=A." +
          contractAddress +
          ".FindMarketAuctionEscrow.EnglishAuction,A." +
          contractAddress +
          ".FindMarketSale.Sale,A." +
          contractAddress +
          ".FindMarketDirectOfferEscrow.DirectOffer,A." +
          contractAddress +
          ".FIND.Sale,A." +
          contractAddress +
          ".FIND.EnglishAuction,A." +
          contractAddress +
          ".FIND.DirectOffer&buyer=" +
          findUser.profile.address
      );
      data = res.data;
      setAllStoreEvents(
        data.filter(
          (event) =>
            event.blockEventData.status === "sold" &&
            dt - new Date(event.eventDate).getTime() < 604800000
        )
      );
      setFilteredEvents(
        data
          .filter(
            (event) =>
              event.blockEventData.status === "sold" &&
              dt - new Date(event.eventDate).getTime() < 604800000
          )
          .filter(
            (event) =>
              event?.blockEventData?.name
                ?.toLowerCase()
                .includes(filterTerm.toLowerCase()) ||
              event?.blockEventData?.nft?.name
                ?.toLowerCase()
                .includes(filterTerm.toLowerCase()) ||
              event.blockEventData?.nft?.collectionName
                .toLowerCase()
                .includes(filterTerm.toLowerCase())
          )
      );
    };
    getEvents();
  }, [findUser]);

  //console.log(filteredEvents.length);

  useEffect(() => {
    setFilteredEvents(
      allStoreEvents.filter(
        (event) =>
          event?.blockEventData?.name
            ?.toLowerCase()
            .includes(filterTerm.toLowerCase()) ||
          event.blockEventData?.nft?.name
            .toLowerCase()
            .includes(filterTerm.toLowerCase()) ||
          event.blockEventData?.nft?.collectionName
            .toLowerCase()
            .includes(filterTerm.toLowerCase())
      )
    );
  }, [filterTerm]);

  return (
    filteredEvents &&
    filteredEvents.length > 0 && (
      <Container fluid={+true} id="store-names-listings" className="px-0 mb-3">
        <Card>
          <Card.Body>
            <Row className="pb-2">
              {" "}
              <Col align="left">
                <h4>🚀 Congratulations! You just won...</h4>
              </Col>
            </Row>

            <table className="store-table" id="store-bought-table">
              <tbody>
                {allStoreEvents && filteredEvents.length > 0 ? (
                  <>
                    {filteredEvents.map((activity, i) => {
                      // console.log(activity);
                      boughtActivity = EventsSwitch(activity);
                      // console.log(boughtActivity);

                      // console.log(((dt - new Date(
                      //   activity.eventDate
                      // ).getTime() )))

                      return (
                        <React.Fragment key={i + "store-bought-row"}>
                          <tr id={i + "store-bought-row"} className="store-row">
                            <td className="store-thumb-col">
                              {boughtActivity.itemType !== "nft" ? (
                                <div className="store-thumb-image">
                                  {findLogo}
                                </div>
                              ) : (
                                <Image
                                crossOrigin="anonymous"
                                  className="store-thumb-image"
                                  fluid={+true}
                                  src={parseImgUrl(boughtActivity.imgUrl, 'icon')}
                                />
                              )}
                            </td>
                            <td className="store-name-col">
                              {boughtActivity.itemType !== "nft" ? (
                                <Link
                                  to={"/mp/FIND/" + boughtActivity.itemName}
                                >
                                  <span className="fw-bold">
                                    <span className="themeColor">
                                      {boughtActivity.itemName}
                                    </span>
                                    .find{" "}
                                  </span>
                                </Link>
                              ) : (
                                <Link
                                  to={
                                    "/mp/" +
                                    activity.blockEventData.nft.collectionName +
                                    "/" +
                                    activity.blockEventData.id
                                  }
                                >
                                  <span className="fw-bold">
                                    {boughtActivity.itemName}
                                  </span>
                                </Link>
                              )}
                            </td>
                            <td className="store-sticker-col">
                              {" "}
                              <small style={{ color: "var(--text-secondary" }}>
                                {new Date(
                                  activity.eventDate
                                ).toLocaleTimeString()}{" "}
                                <br />
                                {new Date(
                                  activity.eventDate
                                ).toLocaleDateString()}{" "}
                              </small>
                            </td>
                            <td className="store-price-col">
                              <span className="d-none d-lg-block">
                                Your bid/offer:
                              </span>
                              <b>
                                {parseFloat(boughtActivity.txAmount).toFixed(2)}{" "}
                                {boughtActivity.txFtType}{" "}
                              </b>
                            </td>
                            <td className="store-time-left-col">
                              <a
                                className="store-sticker"
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  process.env.REACT_APP_BLOCK_VIEWER_URL +
                                  activity.flowTransactionId
                                }
                              >
                                View Transaction
                              </a>
                            </td>
                            <td className="store-buttons-col secColor">
                              <Button variant="find-text">
                                <small>View</small>
                              </Button>
                            </td>
                          </tr>
                          <tr className="mobile-row">
                            <td className="mobile-store-sticker-col">
                              {" "}
                              <a
                                className="store-sticker"
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  process.env.REACT_APP_BLOCK_VIEWER_URL +
                                  activity.flowTransactionId
                                }
                              >
                                View TX
                              </a>
                            </td>
                            <td className="mobile-store-time-left-col secColor">
                              <small style={{ color: "var(--text-secondary" }}>
                                {new Date(
                                  activity.eventDate
                                ).toLocaleTimeString()}{" "}
                                <br />
                                {new Date(
                                  activity.eventDate
                                ).toLocaleDateString()}{" "}
                              </small>
                            </td>
                            <td align="end">
                              <Button variant="find-text">
                                <small>View</small>
                              </Button>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <tr className="store-row">
                    <td>
                      <small>No Recent Activity</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card.Body>
        </Card>
        <div className="seperator100 my-4"></div>
      </Container>
    )
  );
};

export default StoreBuyingWon;
