const SPORT = "Sport";
const COMMON = "Common";

const groups = {

};

export default {
  base: COMMON,
  organizer: (item) => {
    const { label } = item;
    const groupValue = groups[item.label];

    switch (true) {
      // items inside groups
      case groupValue !== undefined:
        return groupValue;

      case label.toLowerCase().includes("athlet"):
        return SPORT;

      default:
        return COMMON;
    }
  },
};
