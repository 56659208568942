import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import parseImgUrl from "../../../functions/parseImgUrl";
import truncateItemName from "../../../functions/truncateItemName";
import NftForSaleLower from "./NftForSaleLower";
import NftForSaleUpper from "./NftForSaleUpper";
import "./nftsforsalecard.scss";

const NftsForSaleCard = ({ nftData, type }) => {
  const [detailsUrl, setDetailsUrl] = useState("/");
  const [mediaType, setMediaType] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [itemCollection, setItemCollection] = useState(null);

  const [marketUrl, setMarketUrl] = useState(null);
  const [onSaleNFT, setOnSaleNFT] = useState(null);
  const [onAuctionNFT, setOnAuctionNFT] = useState(null);
  const [itemAmount, setItemAmount] = useState(null);
  const [itemFT, setItemFT] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (nftData) {
      // console.log(nftData);
      //set item name
      setItemName(
        truncateItemName(
          nftData.nft.name
            ? nftData.nft.name
            : nftData.nft.collectionName + " #" + nftData.nft.id,
          30
        )
      );
      //set media and type
      if (nftData.nft.thumbnail) {
        setMediaUrl(parseImgUrl(nftData.nft.thumbnail, 'thumbnail'));
      }
      //set sale type
      if (nftData.auction && Object.keys(nftData.auction)) {
        setOnAuctionNFT(true);
      }
      if (!nftData.auction && nftData.amount) {
        setOnSaleNFT(true);
      }
      setItemAmount(nftData.amount * 1);
      setItemFT(nftData.ftTypeIdentifier.split(".")[2].replace("Token", ""));
      //set market url
      let nftMpName = nftData.nft.type
        .split(".")[2]
        .replace("NFGv3", "NonFunGerbils");
      setItemCollection(nftMpName);
      setMarketUrl(`/mp/${nftMpName}/${nftData.listingId}`);
    }
  }, [nftData]);

  const handleCollectionNavigate = () => {
    navigate(marketUrl);
  };

  return (
    <div onClick={handleCollectionNavigate} id="sale-nft-wrapper">
      <NftForSaleUpper
        nftData={nftData}
        itemName={itemName}
        mediaUrl={mediaUrl}
        mediaType={mediaType}
        onSaleNFT={onSaleNFT}
        onAuctionNFT={onAuctionNFT}
      />
      <NftForSaleLower
        itemName={itemName}
        onSaleNFT={onSaleNFT}
        onAuctionNFT={onAuctionNFT}
        itemAmount={itemAmount}
        itemFT={itemFT}
        itemCollection={itemCollection}
      />
      {/* <a className="nfts-for-sale-card-link" href={detailsUrl}>
        <Card.Img src={imgUrl} />

        <Row
          className="align-items-center justify-content-center"
          style={{ height: "50px" }}
        >
          <Col>
            <b>
              {truncateItemName(
                nftData.nft.name
                  ? nftData.nft.name
                  : nftData.nft.collectionName + " #" + nftData.nft.id,
                25
              )}
            </b>
          </Col>
        </Row>
        <Row>
          <Card.Text>
            <Col className="pb-3" style={{ color: "var(--text-secondary)" }}>
              <small>
                {nftData.auction && !nftData.bidder && "Starting Bid"}
                {nftData.auction && nftData.bidder && "Top Bid"}
                {nftData.amount && !nftData.auction && "Price"}:{" "}
                <b>
                  {nftData.amount * 1} {nftData.ftAlias}
                </b>
              </small>
            </Col>
          </Card.Text>
        </Row>
      </a> */}
    </div>
  );
};

export default NftsForSaleCard;
