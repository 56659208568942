import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useImmer } from "use-immer";
import { AuthCluster } from "../../auth-cluster";
import { useFormStatus } from "../../functions/DisabledState";
import { handleProfile } from "../../functions/flow-interactions/transactions";

const NavheadRegister = ({ user }) => {
  const [formValues, setFormValues] = useImmer("");

  let formStatus = useFormStatus();

  function updateField(e) {
    setFormValues(e.target.value);
  }

  return (
    <Container>
      <Form onSubmit={() => formValues && handleProfile(formValues)}>
        <Row className="p-2">
          <Col className="d-flex justify-content-center text-center titletxt fw-bold">
            You've connected your wallet but don't have a profile yet.
          </Col>
        </Row>
        <Row className="m-1 p-2 mb-3">
          <Col className="d-flex justify-content-center text-center">
            No problem, just click below to create your profile. It's FREE.
          </Col>
        </Row>
        <Row>
          <fieldset id="a" disabled={formStatus}>
            <Col
              xs="12"
              md="12"
              className="mb-3 px-3 mb-md-0 formInputs mx-auto"
            >
              <Form.Group align="center">
                <Form.Label>Enter a name</Form.Label>
                <Form.Control
                  required
                  placeholder="Your name"
                  onChange={updateField}
                  maxlength="16"
                />
              </Form.Group>
            </Col>
            <Col xs="12" className="mx-auto mt-3 px-4" align="center">
              <Button
                onClick={() => formValues && handleProfile(formValues)}
                variant="find"
                className="w-100"
                type="submit"
              >
                Create
              </Button>
            </Col>
            <Col xs="12" className="mx-auto mt-3" align="center">
              <div align="center">
                <AuthCluster user={user} />
              </div>
            </Col>
          </fieldset>
        </Row>
      </Form>
    </Container>
  );
};

export default NavheadRegister;
