import { useEffect, useState } from "react";
import Rheostat from "rheostat";
import { useRange } from "react-instantsearch-hooks-web";

import "rheostat/initialize";
import "rheostat/css/rheostat.css";
import "./slider.scss"

export default function Slider(props) {
  const { attribute = "amount", title } = props;
  const { start, range, canRefine, refine } = useRange({
    attribute,
  });
  const [stateMin, setStateMin] = useState(range[0]);
  const [stateMax, setStateMax] = useState(range[1]);

  useEffect(() => {
    if (canRefine) {
      if (start[0] === -Infinity) {
        setStateMin(range.min);
      }
      if (start[1] === Infinity) setStateMax(range.max);
    }
  }, [start]);

  const onChange = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
    if (start[0] !== min || start[1] !== max) {
      refine([min, max]);
    }
  };

  const changeInputMin = (e) => {
    if (e.target.value <= stateMax - 1 && e.target.value >= 0) {
      setStateMin(e.target.value);
      refine([e.target.value, stateMax]);
    }
  };

  const changeInputMax = (e) => {
    if (e.target.value >= stateMin + 1 && e.target.value <= range.max) {
      setStateMax(e.target.value);
      refine([stateMin, e.target.value]);
    }
  };

  const onValuesUpdated = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
  };

  return (
    <div className="slider-container">
      {title && <h3>{title}</h3>}
      <Rheostat
        min={range.min}
        max={range.max}
        values={[start[0], start[1]]}
        onChange={onChange}
        onValuesUpdated={onValuesUpdated}
      />
      <div className="slider-container__input-group">
        <input
          className="slider-input"
          value={stateMin}
          min={range.min}
          max={stateMax - 1}
          type="number"
          onChange={changeInputMin}
        />
        <input
          className="slider-input"
          value={stateMax}
          min={stateMin + 1}
          max={range.max}
          type="number"
          onChange={changeInputMax}
        />
      </div>
    </div>
  );
}
