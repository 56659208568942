import {
  capitalize,
  getCollectionFromEventId,
  getEvent,
  getEvents,
} from "../../../../utils";
import { FindAddress, FlowSale, TransferTitle } from "../../../components";
import { extractor } from "./extractor";
import { FlowTransfers } from "../../../components";
import { useNFTResolver } from "../../../../service/nft-resolver";

import { MARKETPLACE_ITEM_PURCHASED } from "../../../const";
import { FLOVATAR_CREATED, FLOVATAR_UPDATED } from "../Flovatar/const";
import {
  FLOVATAR_MP_FOR_SALE,
  FLOVATAR_MP_SALE_WITHDRAW,
} from "../Flovatarmarketplace/const";
import {
  FLOVATAR_COMPONENT_WITHDRAW,
  FLOVATAR_COMPONENT_DEPOSIT,
} from "./const";

export const GenesisMint = (props) => {
  const { transfer } = props;
  const numOfItems = getEvents(props, "Created").length;
  const storage = transfer.signers[0];
  return (
    <section>
      <header>
        <TransferTitle>
          🍀 Minted in a batch of {numOfItems} items
        </TransferTitle>
        <FindAddress address={storage} prefix={"Minted by:"} />
      </header>
    </section>
  );
};

export const Burn = () => {
  return (
    <section>
      <header>
        <TransferTitle>Item was burned 🔥</TransferTitle>
      </header>
    </section>
  );
};

export const DirectTransfer = (props) => {
  const { transfer } = props;
  const fromEvent = transfer.events.byId[FLOVATAR_COMPONENT_WITHDRAW][0];
  const toEvent = transfer.events.byId[FLOVATAR_COMPONENT_DEPOSIT][0];
  const { from } = fromEvent.args;
  const { to } = toEvent.args;
  return (
    <section>
      <header>
        <TransferTitle>Direct Transfer</TransferTitle>
        <p>
          From: <FindAddress address={from} />
        </p>
        <p>
          To: <FindAddress address={to} />
        </p>
      </header>
    </section>
  );
};

export const FlovatarCreation = (props) => {
  const { context, transfer } = props;
  const { from, to, events } = transfer;
  // TODO: Sometimes there will be no event with this signature
  const creationEvent = events.byId[FLOVATAR_CREATED][0];
  const collection = getCollectionFromEventId(creationEvent.id);
  const { id } = creationEvent.args;
  const nftData = useNFTResolver(collection, id);
  return (
    <section>
      <header>
        <TransferTitle>🐣 Flovatar Created</TransferTitle>
      </header>
    </section>
  );
};

export const FlovatarPackCreated = (props) => {
  return (
    <section>
      <header>
        <TransferTitle>📩 Flovatar Pack Created</TransferTitle>
      </header>
    </section>
  );
};

export const FlovatarPackOpened = (props) => {
  const { args } = getEvent(props, "Deposit");
  const { to } = args;
  return (
    <section>
      <header>
        <TransferTitle>🎉 Pulled from Flovatar Pack</TransferTitle>
        <FindAddress address={to} prefix={"Keeper:"} />
      </header>
    </section>
  );
};

export const MarketplaceSale = (props) => {
  const { transfer } = props;
  const saleEvent = transfer.events.byId[FLOVATAR_MP_FOR_SALE][0];
  const { price, address } = saleEvent.args;
  return (
    <section>
      <header>
        <TransferTitle>
          📃 Listed for Sale by <FindAddress address={address} />
        </TransferTitle>
        <FlowSale price={price} by={address} />
      </header>
    </section>
  );
};

export const MarketplaceSaleCanceled = (props) => {
  const { context, transfer } = props;
  const event = transfer.events.byId[FLOVATAR_MP_SALE_WITHDRAW][0];
  const { address } = event.args;
  return (
    <section>
      <header>
        <TransferTitle>MarketPlace Sale Canceled</TransferTitle>
        <p>
          Item returned to <FindAddress address={address} />
        </p>
      </header>
    </section>
  );
};

export const MarketplaceSalePurchased = (props) => {
  const { context, transfer } = props;
  // TODO: Refactor this so we don't need to do it twice 😅
  const data = extractor(transfer, MARKETPLACE_ITEM_PURCHASED);
  const { from, to } = data;
  return (
    <section>
      <header>
        <TransferTitle>
          💸 Purchased by <FindAddress address={to} /> from {""}
          <FindAddress address={from} /> via Marketplace
        </TransferTitle>
      </header>
      <FlowTransfers data={data} />
    </section>
  );
};

export const Equipped = (props) => {
  const { context, transfer } = props;
  const updateEvent = transfer.events.byId[FLOVATAR_UPDATED][0];
  const collection = getCollectionFromEventId(updateEvent.id);
  const { id } = updateEvent.args;
  const nftData = useNFTResolver(collection, id);
  const href = `/t/A.921ea449dffec68a.Flovatar/${id}`;
  return (
    <section>
      <header>
        <TransferTitle>
          Equipped to{" "}
          <a href={href} target={"_blank"}>
            Flovatar #{id}
          </a>
        </TransferTitle>
      </header>
    </section>
  );
};

export const Removed = (props) => {
  const { context, transfer } = props;
  const updateEvent = transfer.events.byId[FLOVATAR_UPDATED][0];
  const collection = getCollectionFromEventId(updateEvent.id);
  const { id } = updateEvent.args;
  const nftData = useNFTResolver(collection, id);
  const href = `/t/A.921ea449dffec68a.Flovatar/${id}`;
  return (
    <section>
      <header>
        <TransferTitle>
          Removed from{" "}
          <a href={href} target={"_blank"}>
            Flovatar #{id}
          </a>
        </TransferTitle>
      </header>
    </section>
  );
};

export const BackgroundAdded = (props) => {
  const { context, transfer } = props;
  const updateEvent = transfer.events.byId[FLOVATAR_UPDATED][0];
  const collection = getCollectionFromEventId(updateEvent.id);
  const { id } = updateEvent.args;
  const nftData = useNFTResolver(collection, id);
  return (
    <section>
      <header>
        <TransferTitle>Background Added to Flovatar</TransferTitle>
      </header>
    </section>
  );
};

export const BackgroundRemoved = (props) => {
  /*  const { context, transfer } = props;
  console.log("Action", transfer);
  const updateEvent = transfer.events.byId[FLOVATAR_UPDATED][0];
  console.log({ updateEvent });
  const collection = updateEvent.id;
  const { id } = updateEvent.args.id;
  const nftData = useNFTResolver(collection, id);
  console.log("Removed", nftData);*/
  return (
    <section>
      <header>
        <TransferTitle>Background Removed from Flovatar</TransferTitle>
      </header>
    </section>
  );
};
