import * as tx from "../../const";
import * as render from "./components";
import { WildGuess } from "../../components";

const switchBoard = {
  [tx.MINT]: render.Mint,
  [tx.BURN]: render.Burn,
  [tx.TRANSFER]: render.Transfer,
  [tx.MARKETPLACE_ITEM_PURCHASED]: render.MarketplaceItemPurchased,
  [tx.MARKETPLACE_SALE_CANCELED]: render.MarketplaceSaleCanceled,
  [tx.STAKED]: render.Staked,
  [tx.SWAPPED]: render.Swapped,
  [tx.AIRDROPPED]: render.Airdropped,
  [tx.UNKNOWN]: render.Unknown,
  [tx.EMPTY]: render.Empty,
};

export default function Generic(props) {
  const { transferType } = props;

  if (transferType.type === tx.EMPTY) {
    return null;
  }

  let Render = switchBoard[transferType.type] || switchBoard[tx.UNKNOWN];

  return <Render {...props} />;
}
