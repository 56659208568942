import React from "react";
import { Col, Row } from "react-bootstrap";
import { AuthCluster } from "../../auth-cluster";
import NavheadMainTopEnd from "./navheadmaintopend";
import NavheadSearchBar from "./navheadsearchbar";
import NavheadThemeToggle from "./navheadthemetoggle";

const NavheadMainTop = ({
  user,
  expanded,
  setExpanded,
  switchTheme,
  profileStatus,
  profile,
  lostAndFoundItems
}) => {
  return user && user.loggedIn ? (
    <>
      <NavheadSearchBar />
      <div style={{ width: "15px" }}>&nbsp;</div>
      <NavheadMainTopEnd
        user={user}
        profileStatus={profileStatus}
        switchTheme={switchTheme}
        profile={profile}
        lostAndFoundItems={lostAndFoundItems}
      />
    </>
  ) : (
    <Row className="align-items-center d-none d-lg-flex">
      <Col>
        <NavheadThemeToggle switchTheme={switchTheme} />
      </Col>

      <Col>
        <AuthCluster user={user} />
      </Col>
    </Row>
  );
};

export default NavheadMainTop;
