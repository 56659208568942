import { getItemAmount, getSticker } from "../utils";
import { toLocalNumber } from "../../../../functions/format";
import React from "react";

export default function PriceTag(props) {
  const { isName, listingDetails, type = "sale" } = props;

  const { sticker, prefix } = getSticker(listingDetails, isName, type);
  const itemAmount = getItemAmount(listingDetails, isName, type);
  const amount = toLocalNumber(itemAmount);

  return (
    <div className="mp-price-row">
      <h3 className="mb-0">
        <span>{prefix}</span>
        {amount}
      </h3>
      {sticker}
    </div>
  );
}
