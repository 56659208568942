import React from "react";

const useListingStatus = (
  hits,
  listingDetails,
  profileDetails,
  isName,
  user
) => {
  let listingDetailsFetched = false;
  let ownItem = false;
  let userHighestBidder = false;
  let userHasOffer = false;
  let forSale = false;
  let offered = false;
  let forAuction = false;
  let auctionStarted = false;
  let auctionEndsAt;
  let auctionEnded = false;
  let auctionEndedBelowReserve = false;
  let auctionEndedAboveReserve = false;
  let itemMediaType = "image";
  let videoMediaLink;
  let aCatalogItem;
  let itemRoyalties = {};
  let itemDescription;
  let itemEdition = null;
  let itemEditionMax = null;
  let itemTraits = null;
  let itemRarity = null
  let itemMedia = null
  let itemThumbnail = null
  let aDapperItem = false

  //  console.log(listingDetails)

  // console.log(itemEdition)

  let sellerProfile = profileDetails;

if (hits && listingDetails){

  if (listingDetails) {
    listingDetailsFetched = true;
  }

  if (user && hits) {
    if (hits.seller === user.addr) {
      ownItem = true;
    }
  }

  //nft sale criteria
  if (listingDetails.findMarket) {
    aCatalogItem = true;

    //catalog royalties
    if (
      listingDetails?.allowedListingActions?.FindMarketSale &&
      listingDetails?.allowedListingActions?.FindMarketSale.ListingDetails[0]
        .royalties
    ) {
      itemRoyalties = {
        ...itemRoyalties,
        Sale: {
          ...listingDetails.allowedListingActions.FindMarketSale
            .ListingDetails[0].royalties,
        },
      };
    }

    //dapper royalties
    if (
      listingDetails?.dapperAllowedListingActions?.FindMarketSale &&
      listingDetails?.dapperAllowedListingActions?.FindMarketSale?.ListingDetails[0]
        .royalties
    ) {
      itemRoyalties = {
        ...itemRoyalties,
        Sale: {
          ...listingDetails?.dapperAllowedListingActions?.FindMarketSale
            .ListingDetails[0].royalties,
        },
      };
    }

    //display views
    if (listingDetails?.nftDetail?.description) {
      if (listingDetails?.nftDetail?.description) {
        itemDescription = listingDetails?.nftDetail?.description;
      }
    }

    //edition views
    if (
      listingDetails?.nftDetail?.editions &&
      listingDetails?.nftDetail?.editions[0]
    ) {
      // console.log(listingDetails.nftDetail.editions[0]);

      if (listingDetails.nftDetail.editions[0]?.number) {
        itemEdition = listingDetails.nftDetail.editions[0]?.number;
      }
      if (listingDetails.nftDetail.editions[0]?.max) {
        itemEditionMax = listingDetails.nftDetail.editions[0].max;
      }
    }

    //item traits
    if (
      listingDetails?.nftDetail.traits &&
      Object.keys(listingDetails.nftDetail.traits).length > 0
    ) {
      itemTraits = listingDetails.nftDetail.traits
    }

    //thumbnail
    if (
      listingDetails?.nftDetail.thumbnail
    ) {
      itemThumbnail = listingDetails.nftDetail.thumbnail 
    }

    //media
    if (
      listingDetails?.nftDetail?.media &&
      Object.keys(listingDetails?.nftDetail?.media).length > 0
    ) {
      itemMedia = listingDetails.nftDetail.media;
    
    } else {
      itemMedia = 'thumbnail'      
    }

    if (hits){
      if (hits.nft_rarity) {
        itemRarity = hits.nft_rarity
      }
    }
    // if (listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition']){
    //   if (listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition']?.number){
    //     itemEdition = listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition'].number
    //   }
    //   if (listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition']?.max){
    //     itemEditionMax = listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition'].max
    //   }
    // }

    // if (listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition']){
    //   if (listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition']?.number){
    //     itemEdition = listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition'].number
    //   }
    //   if (listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition']?.max){
    //     itemEditionMax = listingDetails?.nftDetail?.data?.['A.1d7e57aa55817448.MetadataViews.Edition'].max
    //   }
    // }

    //nft direct sale status
    if (listingDetails?.findMarket?.FindMarketSale) {
      forSale = true;
    }
    if (listingDetails?.findMarket?.FindMarketSale) {
      forSale = true;
      if(listingDetails?.findMarket?.FindMarketSale.ftAlias === "FUT" || listingDetails?.findMarket?.FindMarketSale.ftAlias === "DUC") {
        aDapperItem = true;
      }
    }


    // if (listingDetails.dapperMarket.FindMarketSale) {
    //   forSale = true;
    //   aDapperItem = true
    // }

    

    //nft auction status
    if (listingDetails.findMarket.FindMarketAuctionEscrow) {
      forAuction = true;
      if (
        user &&
        user.addr === listingDetails.findMarket.FindMarketAuctionEscrow.bidder
      ) {
        userHighestBidder = true;
      }
      if (
        listingDetails.findMarket.FindMarketAuctionEscrow.auction.auctionEndsAt
      ) {
        auctionStarted = true;
        auctionEndsAt =
          listingDetails.findMarket.FindMarketAuctionEscrow.auction
            .auctionEndsAt * 1;
        if (
          listingDetails.findMarket.FindMarketAuctionEscrow.auction
            .auctionEndsAt &&
          listingDetails.findMarket.FindMarketAuctionEscrow.auction
            .auctionEndsAt <
            listingDetails.findMarket.FindMarketAuctionEscrow.auction.timestamp
        ) {
          auctionEnded = true;
          if (
            listingDetails.findMarket.FindMarketAuctionEscrow.auction
              .currentPrice <
            listingDetails.findMarket.FindMarketAuctionEscrow.auction
              .reservePrice
          ) {
            auctionEndedBelowReserve = true;
          } else if (
            listingDetails.findMarket.FindMarketAuctionEscrow.auction
              .currentPrice >=
            listingDetails.findMarket.FindMarketAuctionEscrow.auction
              .reservePrice
          ) {
            auctionEndedAboveReserve = true;
          }
        }
      }
    }

    //nft offered status
    if (listingDetails.findMarket.FindMarketDirectOfferEscrow) {
      offered = true;
      if (
        user &&
        user.addr ===
          listingDetails.findMarket.FindMarketDirectOfferEscrow.bidder
      ) {
        userHasOffer = true;
      }
    }
  }

  //name sale criteria
  if (listingDetails.leaseStatus) {
    // console.log("name");

    //name auction status
    if (listingDetails.leaseStatus.auctionStartPrice) {
      forAuction = true;
      if (user && user.addr === listingDetails.leaseStatus.latestBidBy) {
        userHighestBidder = true;
      }
      if (listingDetails.leaseStatus.auctionEnds) {
        auctionStarted = true;
        auctionEndsAt = listingDetails.leaseStatus.auctionEnds * 1;
        if (
          listingDetails.leaseStatus.auctionEnds <
          listingDetails.leaseStatus.currentTime
        ) {
          auctionEnded = true;
          if (
            listingDetails.leaseStatus.latestBid >=
            listingDetails.leaseStatus.auctionReservePrice
          ) {
            auctionEndedAboveReserve = true;
          } else if (
            listingDetails.leaseStatus.latestBid <
            listingDetails.leaseStatus.auctionReservePrice
          ) {
            auctionEndedBelowReserve = true;
          }
        }
      }
    }
    //name listed sale status
    if (listingDetails.leaseStatus.salePrice) {
      forSale = true;
    }
    //name offered status
    if (
      listingDetails.leaseStatus.latestBid &&
      !listingDetails.leaseStatus.auctionStartPrice
    ) {
      offered = true;
      if (user && user.addr === listingDetails.leaseStatus.latestBidBy) {
        userHasOffer = true;
      }
    }
  }

  //set mediatype and link if video
  if (listingDetails && listingDetails?.nftDetail?.media) {
    Object.keys(listingDetails && listingDetails?.nftDetail?.media).map(
      (mediaItem, i) => {
        if (listingDetails?.nftDetail?.media[mediaItem].includes("video")) {
          itemMediaType = "video";

          videoMediaLink = mediaItem
            .replace("ipfs://", "https://find.mypinata.cloud/ipfs/")
            .replace(
              "https://ipfs.io/ipfs/",
              "https://find.mypinata.cloud/ipfs/"
            );
        }
      }
    );
  }

  return {
    aMarketplaceItem: true,
    aCatalogItem,
    aDapperItem,
    listingDetailsFetched,
    indexHit: hits,
    itemRoyalties,
    itemDescription,
    listingDetails: listingDetails,
    isName: isName,
    user: user,
    sellerProfile: sellerProfile,
    ownItem: ownItem,
    userHighestBidder: userHighestBidder,
    userHasOffer: userHasOffer,
    forSale: forSale,
    offered: offered,
    forAuction: forAuction,
    auctionStarted: auctionStarted,
    auctionEnded: auctionEnded,
    auctionEndedBelowReserve: auctionEndedBelowReserve,
    auctionEndedAboveReserve: auctionEndedAboveReserve,
    itemMediaType: itemMediaType,
    videoMediaLink: videoMediaLink,
    auctionEndsAt: auctionEndsAt,
    itemEdition: itemEdition,
    itemEditionMax: itemEditionMax,
    itemTraits: itemTraits,
    itemRarity: itemRarity,
    itemThumbnail,
    itemMedia
  }};

  //   //set listing status
  //   if (isName) {
  //     if (listingDetails?.leaseStatus?.salePrice) {
  //       setForSale(true);
  //     }
  //     if (listingDetails?.leaseStatus?.auctionStartPrice) {
  //       setForAuction(true);
  //       if (user && user.addr === listingDetails?.leaseStatus?.latestBidBy) {
  //         setUserHighestBidder(true);
  //       }
  //       if (
  //         listingDetails?.leaseStatus?.auctionEnds &&
  //         listingDetails?.leaseStatus?.auctionEnds >
  //           listingDetails?.leaseStatus?.currentTime
  //       ) {
  //         setAuctionStarted(true);
  //       } else if (
  //         listingDetails?.leaseStatus?.auctionEnds &&
  //         listingDetails?.leaseStatus?.auctionEnds <
  //           listingDetails?.leaseStatus?.currentTime
  //       ) {
  //         setAuctionEnded(true);
  //       }
  //     }
  //     if (
  //       listingDetails?.leaseStatus?.latestBidBy &&
  //       !listingDetails?.leaseStatus?.auctionStartPrice
  //     ) {
  //       setIsOffered(true);
  //       if (user && user.addr === listingDetails?.leaseStatus?.latestBidBy) {
  //         setUserHasOffer(true);
  //       }
  //     }
  //   } else {
  //     console.log("this hit");
  //     if (listingDetails?.findMarket?.FindMarketSale) {
  //       setForSale(true);
  //     }
  //     if (
  //       listingDetails?.findMarket &&
  //       listingDetails?.findMarket?.FindMarketAuctionEscrow?.auction
  //     ) {
  //       setForAuction(true);
  //       if (
  //         user &&
  //         user.addr ===
  //           listingDetails?.findMarket?.FindMarketAuctionEscrow?.bidder
  //       ) {
  //         setUserHighestBidder(true);
  //       }
  //       if (
  //         listingDetails?.findMarket?.FindMarketAuctionEscrow?.auction
  //           ?.auctionEndsAt &&
  //         listingDetails?.findMarket?.FindMarketAuctionEscrow?.auction
  //           ?.auctionEndsAt >
  //           listingDetails?.findMarket?.FindMarketAuctionEscrow?.auction
  //             ?.timestamp
  //       ) {
  //         setAuctionStarted(true);
  //       } else if (
  //         listingDetails?.findMarket?.FindMarketAuctionEscrow?.auction
  //           ?.auctionEndsAt &&
  //         listingDetails?.findMarket?.FindMarketAuctionEscrow?.auction
  //           ?.auctionEndsAt <
  //           listingDetails?.findMarket?.FindMarketAuctionEscrow?.auction
  //             ?.timestamp
  //       ) {
  //         setAuctionEnded(true);
  //       }
  //     }
  //     if (
  //       listingDetails.findMarket &&
  //       listingDetails.findMarket.FindMarketDirectOfferEscrow
  //     ) {
  //       setIsOffered(true);
  //       if (
  //         user &&
  //         user.addr ===
  //           listingDetails?.findMarket?.FindMarketDirectOfferEscrow?.bidder
  //       ) {
  //         setUserHasOffer(true);
  //       }
  //     } else {
  //       setIsOffered(false)
  //     }}
};
export default useListingStatus;
