import { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {  useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import CollectionPage from "../components/marketplace/collectionpage/CollectionPage";
import searchClient from "../functions/searchClient";

function Collection({ user, findUser }) {
  const [indexName, setIndexName] = useState("market");

  const { colName } = useParams();

  return (
    <Container
      fluid={+true}
      className="pb-5"
      style={{
        backgroundColor: "var(--background-profile)",
        minHeight: "calc(100vh - 218px)",
      }}
      id="collection-page"
    >
      <Helmet>
          <title>
            {colName} / .find
          </title>
          <meta
            name="description"
            content={"The" + {colName} + " collection page on the .find marketplace" }
          />
        </Helmet>
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure
          hitsPerPage={18}
          facetFilters={["collection_alias:" + colName, "listing_alias:-DirectOffer"]}

        />

        <Container id='collection-container' className="content-container">
          <Col className="py-2" xs="12" lg="auto">
            <Breadcrumbs />
            <CollectionPage colName={colName} />
          </Col>
        </Container>
      </InstantSearch>
    </Container>
  );
}

export default Collection;
