import * as tx from "../../const";

const platform = "NFTStoreFrontV2";
const eventTypes = {
  listingCompleted: "A.3cdbb3d569211ff3.NFTStorefrontV2.ListingCompleted",
  listingCompletedGaia: "A.4eb8a10cb9f87357.NFTStorefrontV2.ListingCompleted",
};

export default function NFTStorefrontV2Plugin(context, transfer, idRelated) {
  const { events } = transfer;

  const listingEvent = events.byId[eventTypes.listingCompleted];
  if (listingEvent) {
    const { args } = listingEvent[0];
    const { purchased, buyer } = args;
    const { salePrice, salePaymentVaultType } = args;
    const commission = {
      amount: args.commissionAmount,
      receiver: args.commissionReceiver,
    };
    const price = {
      amount: salePrice,
      token: salePaymentVaultType,
    };

    if (purchased) {
      return {
        type: tx.MARKETPLACE_ITEM_PURCHASED,
        platform,
        commission,
        buyer,
        price,
      };
    } else {
      return {
        type: tx.MARKETPLACE_SALE_CANCELED,
        platform,
      };
    }
  }

  const listingEventGaia = events.byId[eventTypes.listingCompletedGaia];
  if (listingEventGaia) {
    const event = listingEventGaia[0];
    const { args } = event;
    const { purchased, buyer } = args;
    const { salePrice, salePaymentVaultType } = args;
    const commission = {
      amount: args.commissionAmount,
      receiver: args.commissionReceiver,
    };
    const price = {
      amount: salePrice,
      token: "DUC",
    };

    if (purchased) {
      return {
        type: tx.MARKETPLACE_ITEM_PURCHASED,
        platform,
        commission,
        buyer,
        price,
      };
    } else {
      return {
        type: tx.MARKETPLACE_SALE_CANCELED,
        platform: "Gaia Marketplace",
        price,
      };
    }
  }

  return null;
}
