import "./index.scss";

export function Builder(extraClass) {
  return (props) => {
    const { children, className } = props;
    return (
      <div
        className={`${extraClass ? `dp__${extraClass}` : ""}${
          className ? ` ${className}` : ""
        }`}
      >
        {children}
      </div>
    );
  };
}

export function GreyContainer(props) {
  return Builder("grey-container dp__row")(props);
}

export function Column(props) {
  return Builder("column")(props);
}
export function Row(props) {
  return Builder("row")(props);
}

export function Button(props) {
  const { label, onClick } = props;
  const { className } = props;
  return (
    <button className={`dp__button ${className || ""}`} onClick={onClick}>
      {label}
    </button>
  );
}

export function Divider(props) {
  return Builder("divider")(props);
}
