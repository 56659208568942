import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export function MintAndMarket() {
  return (
    <Container id="MintAndMarket">
      <Row>
        <Col className="my-auto px-5">
          <Image src="/assets/img/home/new-feature.webp" height="27" />
          <div className="my-3">
            <h2>Mint your own NFTs and create a marketplace</h2>
          </div>
          <p>
            The forge is your gateway to minting. With it you can mint into your
            own collection and sell your own NFTs using the .find marketplace or
            any other supported marketplace.
          </p>
          <Link  to={"/mp"}>
            <Button className="px-0"
             
              variant="find-text"
            >
              View full marketplace
            </Button>
          </Link>{" "}
        </Col>
        <Col
          sm="12"
          lg="6"
          xs={{ order: "first" }}
          md={{ order: "last" }}
          align="center"
        >
          <Image
            src="/assets/img/home/mint-market.webp"
            alt="FUSD FLOW Sending and Name Gifting"
            width="500"
            fluid={+true}
          />
        </Col>
      </Row>
    </Container>
  );
}
