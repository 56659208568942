import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MpDetailCollectionBy from "./mpdetailpage/mpdetailcollectionby";
import MpDetailLikesViews from "./mpdetailpage/mpdetaillikesviews";
// import MpDetailLikesViews from "./mpdetailpage/mpdetaillikesviews";
import MpDetailOwnedByTop from "./mpdetailpage/mpdetailownedbytop";

const MpDetailBelowName = ({ listingStatus, hit, user }) => {
  // let itemDescription = 'Fetching onchain data...'
  const [itemDescription, setItemDescription] = useState(
    "Fetching onchain data..."
  );

  useEffect(() => {
    if (listingStatus) {
      if (listingStatus.isName) {
        setItemDescription("A name for your wallet and .find profile.");
      }
      if (listingStatus && listingStatus.listingDetails.nftDetail) {
        if (listingStatus.listingDetails.nftDetail.description) {
          setItemDescription(
            listingStatus.listingDetails.nftDetail.description
          );
        } else if (
          listingStatus.listingDetails.nftDetail.collection.description
        ) {
          setItemDescription(
            listingStatus.listingDetails.nftDetail.collection.description
          );
        } else {
          setItemDescription("No description found.");
        }
      }
    }
  }, [listingStatus]);

  return (
    <>
      {/* <Row className="my-5">
      <Col>
      <span className="secColor"> or  item description here</span></Col>
    </Row> */}
      <Row className="align-items-center my-2">
        <Col xs="12" xxl="8" className="">
          <Row className="flex-row-between">
            <Col xs="auto" className="">
              <MpDetailCollectionBy
                hit={hit}
                profileDetails={listingStatus.sellerProfile}
                listingStatus={listingStatus}
              />
            </Col>
            <Col>
              {listingStatus.sellerProfile && (
                <MpDetailOwnedByTop
                  profileDetails={listingStatus.sellerProfile}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col xs="auto" className="mt-4 mt-xxl-0">
          <MpDetailLikesViews hit={hit} user={user} />
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <span className="secColor">{itemDescription}</span>
        </Col>
      </Row>
    </>
  );
};

export default MpDetailBelowName;
