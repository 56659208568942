import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { handleExtend } from "../../../functions/flow-interactions/transactions";
import { useUserProfile } from "../../../functions/useUserProfile";
import "./LockedName.scss";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import {useWalletCurrency} from "../../../functions/useWalletType";
import {getPriceInFlow} from "../../../functions/flow-interactions/scripts";

const LockedName = ({ searchedName, leaseData }) => {
  const [userOwnsName, setUserOwnsName] = useState(false);
  const [price, setPrice] = useState(0);

  const user = useUserProfile();
  const currentDate = (new Date().getTime() / 1000).toFixed(0);

  useEffect(() => {
    if (user && user.profile) {
      if (user.profile.address === leaseData.owner) {
        setUserOwnsName(true);
      }
    }
  }, [leaseData && user]);

  useEffect(()=>{
    getPriceInFlow(leaseData.cost).then(setPrice);
  },[leaseData.name])

  return (
    <div id="locked-name-wrapper">
      <Helmet>
        <title>Locked name / .find</title>
        <meta name="description" content="The .find locked name page" />
      </Helmet>
      <div id="locked-name-inner" className="content-container">
        <div id="locked-name-title">This .find name is currently locked</div>
        <div id="locked-name-desc">
          {" "}
          It was previously owned by{" "}
          <Link id="owner-link" to={"/" + leaseData.owner}>
            <span>{leaseData.owner}</span>
          </Link>{" "}
          and they have not yet renewed the lease.
          <br />
          They have another{" "}
          <span id="date-span">
            <TimeAgo
              date={new Date(leaseData.lockedUntil * 1000)}
              timestyle="twitter"
              suffix="none"
            />{" "}
          </span>
          to renew otherwise it will be released back to the public and
          available for anyone to purchase.
        </div>

        {userOwnsName && (
          <div id="owned-name-wrapper">
            <div id="owned-name-title">This is your name!</div>
            <div id="owned-name-renew-until">
              {" "}
              Click below to renew your name lease until&nbsp;
              <span id="date-span">
                {new Date(
                  Number(currentDate * 1000 + 31536000000)
                ).toLocaleString()}
              </span>
            </div>
            <div id="owned-name-cost">Cost: {(price * 1.05).toFixed(2)} FLOW</div>
            <div id="owned-name-button-row">
              <Button
                variant="find"
                onClick={() => handleExtend(searchedName, {cost: leaseData.cost, costFLOW: price})}
              >
                Extend lease
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LockedName;
