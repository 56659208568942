import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore/lite";

const firebaseAPIKey = process.env.REACT_APP_FIREBASE_API_KEY;

const firebaseConfig = {
  apiKey: firebaseAPIKey,
  authDomain: "find-website.firebaseapp.com",
  projectId: "find-website",
  storageBucket: "find-website.appspot.com",
  messagingSenderId: "1090950328417",
  appId: "1:1090950328417:web:7552c5ce7b6c48a9e3fa20",
  measurementId: "G-145JLB6TEF",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

//getter functions

//get user preferences
export async function getUserProfilePreferences(address) {
  const q = query(collection(db, "users"), where("address", "==", address));
  const querySnapshot = await getDocs(q);
  const collectionList = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      data: doc.data(),
    };
  });
  const userData = collectionList[0];
  if (userData && userData.data.userPref) {
    return userData.data.userPref;
  } else {
    return "none";
  }
}

//get all user favs
export async function getAllUserFavsList(address) {
  const q = query(collection(db, "users"), where("address", "==", address));
  const querySnapshot = await getDocs(q);
  const collectionList = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      data: doc.data(),
    };
  });
  const userData = collectionList[0];
  if (userData && userData.data.favs) {
    return userData.data.favs;
  } else {
    return "none";
  }

  // const collectionCol = collection(db, "users");
  // const collectionSnapshot = await getDocs(collectionCol);
  // const collectionList = collectionSnapshot.docs.map((doc) => {
  //   return {
  //     id: doc.id,
  //     data: doc.data(),
  //   };
  // });
  // const userData = collectionList[0]

  // if (userData && userData.data.favs) {
  //   return userData.data.favs;
  // } else {
  //   return "none";
  // }
}

//get likes for an item
export async function getMarketplaceNFTLikes(collectionName, nftType, nftId) {
  if (nftType) {
    const q = query(
      collection(db, "collections"),
      where("collectionType", "==", nftType)
    );
    const querySnapshot = await getDocs(q);
    const collectionList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    const nftCollection = collectionList[0];
    if (nftCollection) {
      const nft = nftCollection.data.nfts[nftId];
      if (nft) {
        return nft.likes.length;
      } else {
        return 0;
      }
    } else {
      return 0;
    }

    // const userData = collectionList[0]
    // if (userData && userData.data.favs) {
    //   return userData.data.favs;
    // } else {
    //   return "none";
    // }

    // const collectionCol = collection(db, "collections");
    // const collectionSnapshot = await getDocs(collectionCol);
    // const collectionList = collectionSnapshot.docs.map((doc) => {
    //   return {
    //     id: doc.id,
    //     data: doc.data(),
    //   };
    // });
    // const nftCollection = collectionList.find(
    //   (collection) =>
    //     collection.data.collectionName === collectionName &&
    //     collection.data.collectionType === nftType
    // );

    // if (nftCollection) {
    //   const nft = nftCollection.data.nfts[nftId];
    //   if (nft) {
    //     return nft.likes.length;
    //   } else {
    //     return 0;
    //   }
    // } else {
    //   return 0;
    // }
  }
}

//get view count for an item
export async function getNFTViewCount(collectionName, nftType, nftId) {
  if (nftType) {
    const q = query(
      collection(db, "collections"),
      where("collectionType", "==", nftType)
    );
    const querySnapshot = await getDocs(q);
    const collectionList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    const nftCollection = collectionList[0];
    // console.log(nftCollection)
    if (nftCollection) {
      const nft = nftCollection.data.nfts[nftId];
      // console.log(nft)
      if (nft && nft.viewCount) {
        return nft.viewCount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  // if (nftType) {
  //   const collectionCol = collection(db, "collections");
  //   const collectionSnapshot = await getDocs(collectionCol);
  //   const collectionList = collectionSnapshot.docs.map((doc) => {
  //     return {
  //       id: doc.id,
  //       data: doc.data(),
  //     };
  //   });
  //   const nftCollection = collectionList.find(
  //     (collection) =>
  //       collection.data.collectionName === collectionName &&
  //       collection.data.collectionType === nftType
  //   );

  //   if (nftCollection) {
  //     const nft = nftCollection.data.nfts[nftId];
  //     if (nft) {
  //       return nft.viewCount;
  //     } else {
  //       return 0;
  //     }
  //   } else {
  //     return 0;
  //   }
  // }
}

//check if user has liked nft
export async function hasUserLikedNFT(collectionName, nftType, nftId, user) {
  if (user && user.addr) {
    const q = query(
      collection(db, "collections"),
      where("collectionType", "==", nftType)
    );
    const querySnapshot = await getDocs(q);
    const collectionList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    const nftCollection = collectionList[0];
    // console.log(nftCollection)
    if (nftCollection) {
      const nft = nftCollection.data.nfts[nftId];
      // console.log(nft)
      if (nft && nft.likes) {
        return nft.likes.includes(user.addr);
      } else {
        return false;
      }
    } else {
      return false;
    }

    // const collectionCol = collection(db, "collections");
    // const collectionSnapshot = await getDocs(collectionCol);
    // const collectionList = collectionSnapshot.docs.map((doc) => {
    //   return {
    //     id: doc.id,
    //     data: doc.data(),
    //   };
    // });
    // const nftCollection = collectionList.find(
    //   (collection) =>
    //     collection.data.collectionName === collectionName &&
    //     collection.data.collectionType === nftType
    // );

    // if (nftCollection) {
    //   const nft = nftCollection.data.nfts[nftId];
    //   if (nft) {
    //     return nft.likes.includes(user.addr);
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
  }
}

//check if user has fav nft
export async function hasUserFavNFT(collectionName, nftId, user) {
  if (user && user.addr) {
    // console.log("this");
    const q = query(collection(db, "users"), where("address", "==", user.addr));
    const querySnapshot = await getDocs(q);
    const collectionList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    if(!collectionList) return false;
    const userData = collectionList[0].data;
    // console.log(userData);
    if (!userData || !userData.favs) return false;
    const nft = userData.favs.find(
      (fav) => fav.nftId === nftId && fav.collectionName === collectionName
    );
    if (!nft) return false;
    return true;

    // const collectionCol = collection(db, "users");
    // const collectionSnapshot = await getDocs(collectionCol);
    // const collectionList = collectionSnapshot.docs.map((doc) => doc.data());
    // const userData = collectionList.find((item) => item.address === user.addr);
    // if (!userData || !userData.favs) return false;

    // const nft = userData.favs.find(
    //   (fav) => fav.nftId === nftId && fav.collectionName === collectionName
    // );
    // if (!nft) return false;
    // return true;
  }
}

//setter functions

export async function handleNFTLikeClicked(
  collectionName,
  nftType,
  nftId,
  nftName,
  user
) {
  if (nftType && user.addr) {
    const collectionCol = collection(db, "collections");
    const q = query(
      collection(db, "collections"),
      where("collectionType", "==", nftType)
    );
    const querySnapshot = await getDocs(q);
    const collectionList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    const nftCollection = collectionList[0];

    if (nftCollection) {
      const nft = nftCollection.data.nfts[nftId];
      if (nft) {
        if (nft.likes.includes(user.addr)) {
          nft.likes = nft.likes.filter((addr) => addr !== user.addr);
        } else {
          nft.likes.push(user.addr);
        }
        const nftRef = doc(db, "collections", nftCollection.id);
        await updateDoc(nftRef, {
          nfts: {
            ...nftCollection.data.nfts,
            [nftId]: nft,
          },
        });
      } else {
        const nftRef = doc(db, "collections", nftCollection.id);
        await updateDoc(nftRef, {
          nfts: {
            ...nftCollection.data.nfts,
            [nftId]: {
              nftId: nftId,
              nftName: nftName,
              likes: [user.addr],
            },
          },
        });
      }
    } else {
      await addDoc(collectionCol, {
        collectionName: collectionName,
        collectionType: nftType,
        nfts: {
          [nftId]: {
            nftId: nftId,
            nftName: nftName,
            likes: [user.addr],
          },
        },
      });
    }
  }
}

export async function handleNFTFavClicked(
  collectionName,
  nftType,
  nftId,
  nftName,
  nftThumbnail,
  nftCurrentOwner,
  user
) {
  if (collectionName && user && user.addr) {
    const collectionCol = collection(db, "users");
    const q = query(collection(db, "users"), where("address", "==", user.addr));
    const querySnapshot = await getDocs(q);
    const collectionList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    const userData = collectionList[0];

    if (userData) {
      if (
        userData.data.favs &&
        userData.data.favs.find(
          (fav) => fav.nftId === nftId && fav.collectionName === collectionName
        )
      ) {
        const updatedUser = {
          ...userData.data,
          favs: userData.data.favs.filter((fav) => fav.nftId !== nftId),
        };

        await updateDoc(doc(db, "users", userData.id), updatedUser);
      } else if (!userData.data.favs) {
        const docRef = doc(db, "users", userData.id);
        await updateDoc(docRef, {
          favs: [
            {
              nftId: nftId,
              nftName: nftName,
              collectionName: collectionName,
              nftType: nftType,
              nftThumbnail: nftThumbnail,
              nftCurrentOwner: nftCurrentOwner,
            },
          ],
        });
      } else {
        const docRef = doc(db, "users", userData.id);
        await updateDoc(docRef, {
          favs: [
            ...userData.data.favs,
            {
              nftId: nftId,
              nftName: nftName,
              collectionName: collectionName,
              nftType: nftType,
              nftThumbnail: nftThumbnail,
              nftCurrentOwner: nftCurrentOwner,
            },
          ],
        });
      }
    } else {
      console.log("bong bong");

      await addDoc(collectionCol, {
        address: user.addr,
        favs: [
          {
            nftId: nftId,
            nftName: nftName,
            collectionName: collectionName,
            nftType: nftType,
            nftThumbnail: nftThumbnail,
            nftCurrentOwner: nftCurrentOwner,
          },
        ],
      });
    }
  }
}

export async function handleNFTViewIncrement(
  collectionName,
  nftType,
  nftId,
  nftName
) {
  if (nftType) {
    const collectionCol = collection(db, "collections");
    const q = query(
      collection(db, "collections"),
      where("collectionType", "==", nftType)
    );
    const querySnapshot = await getDocs(q);
    const collectionList = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        data: doc.data(),
      };
    });
    const nftCollection = collectionList[0];

    if (nftCollection) {
      const nft = nftCollection.data.nfts[nftId];
      if (nft) {
        await updateDoc(doc(db, "collections", nftCollection.id), {
          nfts: {
            ...nftCollection.data.nfts,
            [nftId]: {
              ...nft,
              viewCount: nft.viewCount ? nft.viewCount + 1 : 1,
            },
          },
        });
      } else {
        await updateDoc(doc(db, "collections", nftCollection.id), {
          nfts: {
            ...nftCollection.data.nfts,
            [nftId]: {
              nftId: nftId,
              nftName: nftName,
              likes: [],
              viewCount: 1,
            },
          },
        });
      }
    } else {
      await addDoc(collectionCol, {
        collectionName: collectionName,
        collectionType: nftType,
        nfts: {
          [nftId]: {
            nftId: nftId,
            nftName: nftName,
            likes: [],
            viewCount: 1,
          },
        },
      });
    }
  }
}

//function to set user has opted out of find name with time stamp
export async function handleUserFindNameOptOut(address) {
  const currentTime = Math.floor(new Date().getTime() / 1000);
  const collectionCol = collection(db, "users");
  const q = query(collection(db, "users"), where("address", "==", address));
  const querySnapshot = await getDocs(q);
  const collectionList = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      data: doc.data(),
    };
  });
  const userData = collectionList[0];

  if (userData) {
    //if user in FB and has no preferences create that field
    const updatedUser = {
      ...userData.data,
      userPref: {
        ...userData.data.userPref,
        findNameOptOut: currentTime,
      },
    };
    await updateDoc(doc(db, "users", userData.id), updatedUser);
  } else {
    //if no user data yet add to FB with optout timestamp
    await addDoc(collectionCol, {
      address: address,
      userPref: {
        findNameOptOut: currentTime,
      },
    });
  }
}

//function indicating user has completed onBoard preferences with timestamp
export async function handleUserHasSetPreferences(address) {
  const currentTime = Math.floor(new Date().getTime() / 1000);
  const collectionCol = collection(db, "users");
  const q = query(collection(db, "users"), where("address", "==", address));
  const querySnapshot = await getDocs(q);
  const collectionList = querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      data: doc.data(),
    };
  });
  const userData = collectionList[0];
  if (userData) {
    //if user in FB and has no preferences create that field
    const updatedUser = {
      ...userData.data,
      userPref: {
        ...userData.data.userPref,
        onboardComplete: currentTime,
      },
    };
    await updateDoc(doc(db, "users", userData.id), updatedUser);
  } else {
    //if no user data yet add to FB with onboard complete timestamp
    await addDoc(collectionCol, {
      address: address,
      userPref: {
        findNameOptOut: null,
        onboardComplete: currentTime,
      },
    });
  }
}
