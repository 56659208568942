import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useImmer } from "use-immer";
import { handleBid, handleFullfillAuction } from "../../../../functions/flow-interactions/transactions";
import * as fcl from "@onflow/fcl";
import ReactTimeago from "react-timeago";
import IncreaseBidName from "../increasebidname/increasebidname";
import {useWalletCurrency} from "../../../../functions/useWalletType";

export default function MakeBidName({ lease }) {
  const [user, setUser] = useState({ loggedIn: null });
  useEffect(() => fcl.currentUser().subscribe(setUser), []);
  let validated = false;
  const currency = useWalletCurrency()

  // //console.log(lease);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    // //console.log(event.target.bidAmt.value)
    // //console.log(lease.auctionStartPrice * 1)
    // //console.log(form.bidAmt.value)

    if (
      (!lease.latestBid && form.bidAmt.value < (lease.auctionStartPrice *1)) ||
     ( form.bidAmt.value < 1) ||
      (lease.latestBid && form.bidAmt.value < lease.auctionStartPrice) ||
      (lease.latestBid && form.bidAmt.value < lease.latestBid)
    ) {
      event.preventDefault();
      event.stopPropagation();
      return;
    } else event.preventDefault();
    //setValidated(true)
    handleBid(formValues);
  };

  const [formValues, setFormValues] = useImmer([
    {
      id: "bidAmt",
      value: "1",
    },
    {
      id: "name",
      value: lease.name,
    },
  ]);

  function updateField(e) {
    setFormValues((draft) => {
      const varVal = draft.find((varVal) => varVal.id === e.target.name);
      varVal.value = e.target.value;
      //now validate
      if (
       ( varVal.value < 1) ||
       (!lease.latestBid && varVal.value < (lease.auctionStartPrice * 1)) ||
        (varVal.value < lease.latestBid)
      ) {
        e.target.classList.add("is-invalid");
        e.target.classList.remove("is-valid");
      } else {
        e.target.classList.add("is-valid");
        e.target.classList.remove("is-invalid");
      }
    });

  }
  return (
    <Container className="commerceinfo p-3">
      {lease.latestBidBy === user.addr && lease.latestBidBy ? (
        <Row>
          <Col>
            {/* <p className="fw-bold">Congrats! You just made a bid of {parseFloat(lease.latestBid).toFixed(2)} FUSD to {lease.name}.find</p> */}
            <p className="fw-bold">
              Congrats! You are the highest bidder for {lease.name}.find
            </p>
            <div>
              <small>
                Starting Price:{" "}
                <b>{parseFloat(lease.auctionStartPrice).toFixed(2)} {currency}</b>
              </small>
            </div>
            <div>
              <small>
                Your Current Bid:{" "}
                <b>{parseFloat(lease.latestBid).toFixed(2)} {currency}</b>
              </small>
            </div>
            <div>
              <small>
                {(lease.currentTime < lease.auctionEnds) ?
                <>
                Auction ends in:{" "}
                </>
                :
                <>
                Auction ended:{" "}
                </>
              }
                <b>
                  <ReactTimeago
                    date={new Date(lease.auctionEnds * 1000)}
                    timestyle="twitter"
                  />
                </b>{" "}
                {}
              </small>
            </div>
            <br />
            <div>
              <small>
              </small>
            </div>
            
            {lease.auctionEnds && (lease.currentTime > lease.auctionEnds) ?
              <>
                <Row>
                  <Col>
                    <small>
                      This auction has ended with you as the highest bidder.
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col align="right">
                    <Button
                      onClick={() =>
                        handleFullfillAuction(
                          lease.name,
                          lease.address
                        )
                      }
                      variant="find-outline-commerce"
                    >
                      Fulfill Auction
                    </Button>
                  </Col>
                </Row>
              </>
              :
              <>
              <Row>
              <Col>
                <small>
                Check your dashboard regularly for the status of your bid.
                </small>
              </Col>
            </Row>
            <Row>
              <Col>
              <IncreaseBidName lease={lease}/>
              </Col>

            </Row>
            </>
            }


          </Col>
        </Row>


      ) : (
        <>
          <Row>
            <p>How much would you like to bid for <b><span className="themeColor">{lease.name}</span>.find</b></p>
          </Row>
          <Row>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="form-inputs"
            >
              <Row>
                <Col>
                  <Form.Control
                    type="number"
                    min="0"
                    required
                    placeholder="Bids must be at least 1FUSD above the current bid"
                    onChange={updateField}
                    name="bidAmt"
                  />
                </Col>
                <Col xs="auto">
                  <Button variant="find-outline-commerce-dark" type="submit">
                    Bid
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </>
      )}
    </Container>
  );
}
