import { Container, Row, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./profileuserbar.css";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { UseThemeStatus } from "../../../functions/themeMode";

export function ProfileUserBar() {
  const [currentPage, setCurrentPage] = useState("");
  const [show, setShow] = useState(false);
  const location = useLocation();
  const currentLocation = location.pathname;
  ////console.log(currentLocation)

  useEffect(() => {
    // if(location.pathname === "/lf"){
    //   setCurrentPage("livefeed")
    // }
    // if(location.pathname === "/mp"){
    //   setCurrentPage("marketplace")
    // }
    switch (true) {
      case currentLocation.startsWith("/mp"):
        return setCurrentPage("marketplace");
      case currentLocation.startsWith("/partyfavorz/pack"):
        return setCurrentPage("drop");
      case currentLocation.startsWith("/lf"):
        return setCurrentPage("livefeed");
      case currentLocation === "/me":
        return setCurrentPage("me");
      case currentLocation.startsWith("/me/store"):
        return setCurrentPage("yourStore");
      case currentLocation === "/me/settings":
        return setCurrentPage("settings");
      case currentLocation === "/me/forge":
        return setCurrentPage("forge");
      case currentLocation === "/p/contact":
        return setCurrentPage("contact");
      case currentLocation === "/p/explore":
        return setCurrentPage("explore");
      default:
        return setCurrentPage("me");
    }
  }, [location.pathname]);

  return (
    <div className="pub-sidebar-wrapper">
      <Container
        fluid
        id="profile-user-bar"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {show ? (
          <Row className="icons-row">
            {UseThemeStatus() === "light" ? (
              <Nav activeKey={currentPage} className="flex-column p-0">
                {/* <Nav.Link as={Link} to="/partyfavorz/pack/3" eventKey="drop">
                  <img
                    id="drop-img"
                    src="/assets/img/DropPage/drop-info-1-min.jpeg"
                  />
                  <span className="pub-text">Drop</span>
                </Nav.Link> */}
                  {/* Explore gone til redesign/build - Linear 789 */}
                {/* <Nav.Link as={Link} to="/p/explore" eventKey="explore">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/search-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Explore</span>
                </Nav.Link> */}
                <Nav.Link as={Link} to="/lf" eventKey="livefeed">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/livefeed-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Live feed</span>
                </Nav.Link>
                <Nav.Link as={Link} to="/mp" eventKey="marketplace">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/marketplace-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Marketplace</span>
                </Nav.Link>
                <Nav.Link as={Link} to="/me" eventKey="me">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/dashboard-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Dashboard</span>
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/me/store/listings"
                  eventKey="yourStore"
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/yourstore-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Your Store</span>
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/afterfuture/pack/1"
                  eventKey="pfxaf"
                >
                  <span className="pub-text">🚀 Current Drops</span>
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/me/forge" eventKey="forge">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/forge-light.svg"
                    alt=""
                  />
                  <span className="pub-text">The Forge</span>
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/me/settings" eventKey="settings">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/settings-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Settings</span>
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/p/contact" eventKey="contact">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/contact-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Contact</span>
                </Nav.Link> */}
                {/* <Nav.Link
                  as={Link}
                  to="/"
                  eventKey="logout"
                  onClick={fcl.unauthenticate}
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/logout-light.svg"
                    alt=""
                  />
                  <span className="pub-text">Log out</span>
                </Nav.Link> */}
              </Nav>
            ) : (
              <Nav activeKey={currentPage} className="flex-column p-0">
                {/* <Nav.Link as={Link} to="/partyfavorz/pack/3" eventKey="drop">
                  <img
                    id="drop-img"
                    src="/assets/img/DropPage/drop-info-1-min.jpeg"
                  />
                  <span className="pub-text">Drop</span>
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/p/explore" eventKey="explore">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/search-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">Explore</span>
                </Nav.Link> */}

                <Nav.Link as={Link} to="/lf" eventKey="livefeed">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/livefeed-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">Live feed</span>
                </Nav.Link>
                <Nav.Link as={Link} to="/mp" eventKey="marketplace">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/marketplace-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">Marketplace</span>
                </Nav.Link>
                <Nav.Link as={Link} to="/me" eventKey="me">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/dashboard-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">Dashboard</span>
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/me/store/listings"
                  eventKey="yourStore"
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/yourstore-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">Your Store</span>
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/afterfuture/pack/1"
                  eventKey="pfxaf"
                >
                  <span className="pub-text">🚀 Current Drops</span>
                </Nav.Link>
                
                {/* <Nav.Link as={Link} to="/me/forge" eventKey="forge">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/forge-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">
                  The Forge</span>
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/me/settings" eventKey="settings">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/settings-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">
                  Settings</span>
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/p/contact" eventKey="contact">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/contact-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">
                  Contact</span>
                </Nav.Link> */}
                {/* <Nav.Link
                  as={Link}
                  to="/"
                  eventKey="logout"
                  onClick={fcl.unauthenticate}
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/logout-dark.svg"
                    alt=""
                  />
                  <span className="pub-text">
                  Log out</span>
                </Nav.Link> */}
              </Nav>
            )}
          </Row>
        ) : (
          <Row className="icons-row">
            {UseThemeStatus() === "light" ? (
              <Nav activeKey={currentPage} className="flex-column p-0">
                {/* <Nav.Link as={Link} to="/partyfavorz/pack/3" eventKey="drop">
                  <img
                    id="drop-img"
                    src="/assets/img/DropPage/drop-info-1-min.jpeg"
                  />
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/p/explore" eventKey="explore">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/search-light.svg"
                    alt=""
                  />
                </Nav.Link> */}
                <Nav.Link as={Link} to="/lf" eventKey="livefeed">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/livefeed-light.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link as={Link} to="/mp" eventKey="marketplace">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/marketplace-light.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link as={Link} to="/me" eventKey="me">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/dashboard-light.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/me/store/listings"
                  eventKey="yourStore"
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/yourstore-light.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/afterfuture/pack/1"
                  eventKey="pfxaf"
                >
                  <span>🚀</span>
                </Nav.Link>
                
                {/* <Nav.Link as={Link} to="/me/forge" eventKey="forge">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/forge-light.svg"
                    alt=""
                  />
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/me/settings" eventKey="settings">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/settings-light.svg"
                    alt=""
                  />
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/p/contact" eventKey="contact">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/contact-light.svg"
                    alt=""
                  />
                </Nav.Link> */}
                {/* <Nav.Link
                  as={Link}
                  to="/"
                  eventKey="logout"
                  onClick={fcl.unauthenticate}
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/logout-light.svg"
                    alt=""
                  />
                </Nav.Link> */}
              </Nav>
            ) : (
              <Nav activeKey={currentPage} className="flex-column p-0">
                {/* <Nav.Link as={Link} to="/partyfavorz/pack/3" eventKey="drop">
                  <img
                    id="drop-img"
                    src="/assets/img/DropPage/drop-info-1-min.jpeg"
                  />
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/p/explore" eventKey="explore">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/search-dark.svg"
                    alt=""
                  />
                </Nav.Link> */}

                <Nav.Link as={Link} to="/lf" eventKey="livefeed">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/livefeed-dark.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link as={Link} to="/mp" eventKey="marketplace">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/marketplace-dark.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link as={Link} to="/me" eventKey="me">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/dashboard-dark.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/me/store/listings"
                  eventKey="yourStore"
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/yourstore-dark.svg"
                    alt=""
                  />
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/afterfuture/pack/1"
                  eventKey="pfxaf"
                >
                  <span>🚀</span>
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/me/forge" eventKey="forge">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/forge-dark.svg"
                    alt=""
                  />
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/me/settings" eventKey="settings">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/settings-dark.svg"
                    alt=""
                  />
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/p/contact" eventKey="contact">
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/contact-dark.svg"
                    alt=""
                  />
                </Nav.Link> */}
                {/* <Nav.Link
                  as={Link}
                  to="/"
                  eventKey="logout"
                  onClick={fcl.unauthenticate}
                >
                  <Image
                    fluid={+true}
                    src="/assets/img/profile/pubicons/logout-dark.svg"
                    alt=""
                  />
                </Nav.Link> */}
              </Nav>
            )}
          </Row>
        )}
      </Container>
    </div>
  );
}
