import * as render from "./components";
import * as tx from "../../../const";
import classify from "./classifier";

export default function ({ context, transfer }) {
  const { collection, id: nftId } = context;
  const { events, script, hash } = transfer;

  // We can identify some transfers by their hash
  // prettier-ignore
  const switchboard = {
		"________________MjE0NDQ1MjcyMg==": render.MarketplaceSale,
	};

  const Render = switchboard[hash];
  if (Render) {
    return <Render context={context} transfer={transfer} />;
  }

  // identify transfer type
  const transferType = classify(context, transfer);

  // If we don't know what to do with this transfer, we shall fall back to generic rendering
  return null
}
