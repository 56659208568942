export const update = (path, target, value) => {
  const key = path[0];
  if (path.length === 0) {
    return 0;
  }
  if (path.length === 1) {
    target[key] = { node: value };
  } else {
    if (!target[key]) {
      target[key] = {};
    }
    update(path.slice(1), target[key], value);
  }
};

export const groupItems = (list) => {
  return list.reduce((acc, item) => {
    const path = item.name.split("_");
    update(path, acc, item);
    return acc;
  }, {});
};

const push = (key, acc, item) => {
  if (!acc[key]) {
    acc[key] = [item];
  } else {
    acc[key].push(item);
  }
};

export const process = (groupped, options) => {
  const { group = "", level = 0 } = options;
  const t = Object.keys(groupped).reduce(
    (acc, key) => {
      const item = groupped[key];
      const keys = Object.keys(item);
      if (item.displayType) {
        item.shortName = item.name.split("_")[level];
        push("singles", acc, item);
      } else {
        if (!item.node) {
          const processed = process(item, { group: key, level: level + 1 });
          push("groups", acc, processed);
        } else {
          const updated = item.node;
          updated.shortName = updated.name.split("_")[level];
          push("singles", acc, updated);
        }
      }
      return acc;
    },
    { group, level }
  );

  return t;
};

export const capitalizeTrait = (str) => {
  if (!str) {
    return "";
  }
  return str
    .split(/(?=[A-Z])/)
    .map(capitalize)
    .join(" ");
};

export const elipsiss = (str, limit = 20) => {
  if (str.length < limit) {
    return str;
  } else {
    return str.slice(0, 5) + "..." + str.slice(-5);
  }
};

export const capitalize = (str) => {
  if (!str) {
    return "";
  }
  return str[0].toUpperCase() + str.slice(1);
};
