import React, { useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { SortBy } from "react-instantsearch-hooks-web";
import CollectionStatsBox from "../marketplace/collectionpage/CollectionStatsBox";
import "./CharityAuction.scss";

const CharityBannerBio = () => {
  const [bannerOpen, setBannerOpen] = useState();
  const [squareOpen, setSquareOpen] = useState();

  //here we need background / square from response script, here is dummy for now
  const headerName =
    "The Inaugural Flow and Friends Fantasy Football League for Charity - Auction Event";
  const headerDescription = [
    `This year 11 Flow projects (and our good friends from Pieland) got together to compete in a Fantasy Football League while raising money for charity.`,
    `Each project chose a charity to represent at the beginning of the season. Through the fantasy league and other various events, the projects have worked together to help raise funds for these charities.`,
    `100% of funds raised will be given to charity at the end of the fantasy football season.`,

    `This will be a week-long event starting on December 11th.`,

    `How can YOU help: Bid on cool stuff! NFTs have been donated for this event, so find a few you like and place your best bids!`,

    `FLOW/USD donations will also be accepted.`,

    `You can read more about the charities each project has chosen to support here.`,
  ];
  const headerBackgroundUrl =
    "/assets/img/CharityAuction/charity-football-header.png";
  const squareImage =
    "https://uc0e785f7e42ee0004c72e7abb65.previews.dropboxusercontent.com/p/thumb/ABta1O1nW7NYri_9_7mIr6995syAS9ClR9ahkl-CdoQK3_mKR0KBvUKVFyjQGt1Gh4x27TtFfaVMHFekAkHUa_3zd4U26iG3_MVDM6jou48N0tsSZhZPrJy5TgaOrYZfpYDcZl08tDTQN2x7V6fz_NBzNKEhp-aZNE9PWGmgCXc7e361wsRZ9vr_048q1rM3K7zBqrt6mfT-pWVUplKXATd4V-RJHDk6N_l4hPEuGC72O38eoYOQHbRed_aaj5D-BVnZCjGzgfXkUlDc0uTEiyqE6sz_c-tjrJtJule4XDltjlG32ri55KAHKXGlM8Vx8eQzUuODH91PLd6HClgeMC3-xrGjC8UKFN3wG2mTgis-cCa640CUChhY0GZxgYWg-E4/p.png";

  return (
    <div
      fluid={+true}
      id="charity-auction-banner-bio-wrapper"
      className="mb-3 px-0"
    >
      <Row>
        <Col className="">
          <Card className="outer-wrapper">
            <div
              onClick={(e) => setBannerOpen(true)}
              className="header-card-bg"
              style={{
                backgroundImage: "url('" + headerBackgroundUrl + "')",
                backgroundPosition: "center",
                backgroundSize: "cover",
                cursor: "pointer",
              }}
            ></div>
            {bannerOpen && (
              <Lightbox
                mainSrc={headerBackgroundUrl}
                onCloseRequest={() => setBannerOpen(false)}
              />
            )}
            {squareOpen && (
              <Lightbox
                mainSrc={squareImage}
                onCloseRequest={() => setSquareOpen(false)}
              />
            )}
            <Card.Body>
              <Row className="justify-content-sm-center justify-content-lg-start">
                <Col
                  className="header-square-col ms-lg-2 d-flex justify-content-center"
                  xs="12"
                  lg="auto"
                >
                  <div
                    className="profile-pic"
                    onClick={(e) => setSquareOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <Image crossOrigin="anonymous" src={squareImage} />
                  </div>
                </Col>
                <Col>
                  <div className="header-name-padding d-none d-lg-block"></div>
                  <Row id="header-row">
                    <Col className="d-flex justify-content-center justify-content-lg-start mt-3 mt-lg-0">
                      <h3>{headerName}</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      id="info-col"
                      className="justify-content-center text-center text-lg-start justify-content-lg-start px-3"
                    >
                      {headerDescription &&
                        headerDescription.map((sectionSpan, i) => {
                          return (
                            <React.Fragment key={i}>
                              <span
                                // className="collection-description"
                                id="charity-page-info-section-desc-span"
                              >
                                {sectionSpan}
                              </span>
                            </React.Fragment>
                          );
                        })}
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center align-items-center mt-3 mt-lg-5 mb-2">
                    <CollectionStatsBox />
                  </Row>
                  <Row>
                    <Row>
                      <Col className="d-none d-md-block" md="10"></Col>
                      <Col className="py-3" xs="12" md="2">
                        <SortBy
                          items={[
                            { label: "Sort By", value: "market" },
                            {
                              label: "Price ▲",
                              value: "market/sort/amount:asc",
                            },
                            {
                              label: "Price ▼",
                              value: "market/sort/amount:desc",
                            },
                            {
                              label: "Ends soonest",
                              value: "market/sort/ends_at:asc",
                            },
                            {
                              label: "Activity",
                              value: "market/sort/updated_at:desc",
                            },
                          ]}
                          classNames={{ select: "mpSortBy form-select" }}
                        />
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CharityBannerBio;
