import { Button, Col, Container, Row } from "react-bootstrap";
import { useHits } from "react-instantsearch-hooks-web";

import SingleHit from "../MarketplaceSingleHit";

import AliceCarousel from "react-alice-carousel";
import { useEffect, useState } from "react";
import { UseThemeStatus } from "../../../functions/themeMode";
import { useUserWalletStatus } from "../../../functions/useWalletType";

export default function CustomHitsCarousel({
  props,
  moreFromCollection,
  detail,
}) {
  const { hits } = useHits(props);
  const [items, setItems] = useState([]);
  // const [hitCount, setHitCount] = useState(0)
  const user = useUserWalletStatus();

  // console.log(hitCount)

  // useEffect(() => {
  //   setHitCount((hitCount) => hitCount + 1);
  // }, [hits]);

  // console.log(hits);

  const responsive = {
    0: { items: 2 },
    768: { items: 3 },
    992: { items: 4 },
    1420: { items: 5 },
  };

  useEffect(() => {
    if (hits && hits.length > 0 && items.length === 0) {
      hits.map((hit, i) =>
        setItems((items) => [
          ...items,
          <SingleHit
            detail={detail}
            moreFromCollection={true}
            hit={hit}
            user={user}
          />,
        ])
      );
    }
  }, [hits]);

  // console.log(items)

  return (
    <Container className={!detail && "content-container"}>
      <Row className="align-items-center justify-content-center g-0">
        {items.length > 0 ? (
          <AliceCarousel
            mouseTracking
            responsive={responsive}
            items={items}
            infinite={true}
            renderPrevButton={() => {
              return (
                <Button
                  className="m-2 carousel-button-left-explore"
                  variant={UseThemeStatus() === "light" ? "dark" : "light"}
                >
                  &lt;
                </Button>
              );
            }}
            renderNextButton={() => {
              return (
                <Button
                  className="m-2 carousel-button-right-explore"
                  variant={UseThemeStatus() === "light" ? "dark" : "light"}
                >
                  &gt;
                </Button>
              );
            }}
          />
        ) : (
          <Col className="px-2">No other collection items found</Col>
        )}
      </Row>
    </Container>
  );
}
