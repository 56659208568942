export function toLocalNumber(value = 0, options) {
  const decimals  = options?.decimals || 5
  const min  = options?.min || 0

  let amount =
    typeof value === "object" && value !== null ? value.amount : value;
  if (typeof amount === "string") {
    amount = parseFloat(amount);
  }
  return amount.toLocaleString(navigator.language, {
    maximumFractionDigits: decimals,
    minimumFractionDigits: min,
  });
}

export function extractCollectionName(name){
  let result = name
    .replace(/collection|storage|path/gi,"")
    .replace(/NFTs?/, "")
    .match(/[A-Z][a-z]+/g)

  const extractedName = result ? result.join(" ") : name[0].toUpperCase() + name.slice(1)
  return extractedName
}
