import Filter from "../Generic/Filter";
import groups from "./groups";

export default function TagFilter() {
  const title = "Tag";
  const attribute = "string.tag";
  const options = { title, attribute, groups };

  return <Filter {...options} />;
}
