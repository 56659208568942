import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import parseImgUrl from "../../../functions/parseImgUrl";
import "./mpdetailpage.css";
import CustomImageIcon from "../../CustomImage/CustomImgIcon";

const MpDetailCollectionBy = ({ hit, listingStatus }) => {
  // console.log(hit);
  const [catalogName, setCatalogName] = useState(null);
  const [squareImg, setSqareImg] = useState(null);

  useEffect(() => {
    if (listingStatus.isName) {
      setSqareImg("https://find.xyz/find.png");
      setCatalogName("FIND");
    } else if (listingStatus && listingStatus.listingDetails.nftDetail) {
      if (listingStatus.listingDetails.nftDetail.collection) {
        if (listingStatus.listingDetails.nftDetail.collection.name) {
          setCatalogName(
            listingStatus.listingDetails.nftDetail.collection.name
          );
        }
        if (listingStatus.listingDetails.nftDetail.collection.squareImage) {
          // setSqareImg(Object.keys(listingStatus.listingDetails.nftDetail.collection.squareImage))
          if (
            Object.keys(
              listingStatus.listingDetails.nftDetail.collection.squareImage
            )
          )
            if (
              listingStatus.listingDetails.nftDetail.collection.name ===
                "bl0x" ||
              listingStatus.listingDetails.nftDetail.collection.name ===
                "bl0x Pack"
            ) {
              setSqareImg(
                "https://pbs.twimg.com/media/Fa2fh0sXoAIIGTq?format=png&name=small"
              );
            } else {
              setSqareImg(
                Object.keys(
                  listingStatus.listingDetails.nftDetail.collection.squareImage
                )[0]
              );
            }
        }
      }
    }
  }, [listingStatus, hit]);

  return (
    <div className="flex-row">
      <div className="profileMenuPic" style={{ marginRight: "10px" }}>
     <CustomImageIcon imgUrl={squareImg}/>
      </div>
      <div>
        <div>
          <span className="small fw-bold">Collection:</span>
        </div>
        <Link to={"/mp/" + catalogName}>
          <small>
            {hit.collection_name
              .replace("FIND", ".find")
              .replace("bl0x", "Bl0x")}{" "}
          </small>{" "}
        </Link>{" "}
      </div>
    </div>
    // <Row className="align-items-center">
    //   <Col xs='auto' className="profileMenuPic">
    // {profileDetails && <Image src="/assets/img/store/fnamestore.png" style={{height: '40px'}} />}
    //   </Col>
    //   <Col align="left">
    //     <Row>
    //       <small>Collection:</small>
    //     </Row>
    //     <Row className="find-text">
    //       <Link to={'/mp/' + hit.collection_name}>
    //     <small>
    // {hit.collection_name}  </small>  </Link>
    //     </Row>
    //   </Col>
    // </Row>
  );
};

export default MpDetailCollectionBy;
