import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./ThoughtBox.scss";

const ThoughtBoxTagInput = ({
  handleTagKeyDown,
  removeTag,
  thoughtTagsArray,
}) => {
  return (
    <div id="livefeed-thought-tags-box" className="tags-wrapper mb-3">
      <Row className="tags-input-container justify-content-center align-items-center mx-0 px-0">
        {thoughtTagsArray.map((tag, index) => (
          <Col key={index} xs="auto" className="tag-item">
            <span className="tag-text">{tag}</span>
            <span onClick={() => removeTag(index)} className="tag-close">
              &times;
            </span>
          </Col>
        ))}

        <Col className="px-0 mx-0">
          <Form.Control
            onKeyDown={handleTagKeyDown}
            type="text settings-input"
            className="tags-input"
            id="livefeed-thought-tags-box-inner"
            placeholder="Add a tag and press Enter..."
            maxLength="15"
          ></Form.Control>
        </Col>
      </Row>
    </div>
  );
};

export default ThoughtBoxTagInput;
