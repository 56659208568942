import React, { useEffect, useRef, useState } from "react";

import Header from "../Header";

import FilterSelection from "./FilterSelection";
import Hits from "./Hits";
import Sidebar from "./Sidebar";

import "./doodles.scss";
import "./doodles.media.scss";

export default function Results() {
  const bodyRef = useRef();

  const [showFilters, setShowFilters] = useState(
    window.innerWidth > 960 ? true : false
  );

  const gridClassName = showFilters
    ? "doodles__grid show-filters"
    : "doodles__grid";

  const toggle = () => {
    const newValue = !showFilters;
    setShowFilters(newValue);
    if (newValue) {
      bodyRef.current.classList.add("no-scroll");
    } else {
      bodyRef.current.classList.remove("no-scroll");
    }
  };

  useEffect(() => {
    bodyRef.current = document.getElementsByTagName("body")[0];
  }, []);

  return (
    <div className="doodles__root">
      <Header />
      <div className={gridClassName}>
        <FilterSelection toggle={toggle} />
        <Hits />

        <Sidebar toggle={toggle} />
      </div>
    </div>
  );
}
