import { HistoryBlock } from "./components";
import projects from "./projects";
import Generic from "./projects/Generic";
import classify from "./projects/Generic/classifier";
import { EMPTY } from "./const";
import {reportGeneralSolution} from "../../../sentry";

const mapTransfer = (context) => (transfer) => {
  const { collection } = context;
  const [address, contractName] = collection.split(".").slice(1);
  const projectKey = `A${address}`;
  const Mapped =
    projects[projectKey] && projects[projectKey][contractName]
      ? projects[projectKey][contractName].default
      : null;
  let content = Mapped && <Mapped context={context} transfer={transfer} />;

  // We need fall back if Mapped component doesn't know how to handle it
  if (!content) {
    let transferType = classify(context, transfer);
    if (transferType.type === EMPTY) {
      return null;
    }
    content = <Generic context={context} transferType={transferType} transfer={transfer} />;

    reportGeneralSolution(context, {transfer,transferType})
  }

  return (
    <HistoryBlock context={context} transfer={transfer}>
      {content}
    </HistoryBlock>
  );
};

export default mapTransfer;
