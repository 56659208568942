import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./ThoughtBox.scss";
import EmojiPicker from "emoji-picker-react";
import { useUserProfile } from "../../functions/useUserProfile";
import parseImgUrl from "../../functions/parseImgUrl";
import { handlePublishThought } from "../../functions/flow-interactions/transactions";
import ThoughtBoxTagInput from "./ThoughtBoxTagInput";
import ThoughtBoxHeaderInput from "./ThoughtBoxHeaderInput";
import ThoughtBoxMediaModal from "./ThoughtBoxMediaModal";
import { useFormStatus, useStateChanged } from "../../functions/DisabledState";
import { UseThemeStatus } from "../../functions/themeMode";
import { useLocation } from "react-router";

const LiveFeedThoughtBox = ({ user, setPostedClicked }) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const [showHeaderInput, setShowHeaderInput] = useState(false);
  const [showTagsInput, setShowTagsInput] = useState(false);
  const [showMediaInput, setShowMediaInput] = useState(false);
  const [profileThoughtBox, setProfileThoughtBox] = useState(false);
  const location = useLocation();
  const [thoughtInput, setThoughtInput] = useState({
    header: "",
    body: "",
    mediaHash: "",
    mediaType: "",
  });
  const [thoughtTagsArray, setThoughtTagsArray] = useState([]);

  useEffect(() => {
    if (user && profileThoughtBox !== true) {
      // console.log(location.pathname);
      if (
        location.pathname.includes("/me") ||
        location.pathname === "/" ||
        (user.profile.findName &&
          location.pathname.includes(user.profile.findName)) ||
        location.pathname.includes(user.profile.address)
      ) {
        setProfileThoughtBox(true);
      } else {
        setProfileThoughtBox(false);
      }
    }
  }, [location, user]);

  const themeStatus = UseThemeStatus();

  const onEmojiClick = (emojiObject) => {
    // console.log(emojiObject);
    setThoughtInput({
      ...thoughtInput,
      body: thoughtInput.body + emojiObject.emoji,
    });
    setShowEmojis(false);
  };

  const handleSubmitThought = () => {
    handlePublishThought(thoughtInput);
  };

  const handleTagKeyDown = (e) => {
    console.log(thoughtTagsArray);
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim() || thoughtTagsArray.length === 10) return;
    setThoughtTagsArray([...thoughtTagsArray, value]);
    e.target.value = "";
  };

  const removeTag = (index) => {
    setThoughtTagsArray(thoughtTagsArray.filter((el, i) => i !== index));
  };

  const CustomDropdownToggle = React.forwardRef(
    ({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    )
  );

  //reset thought input on successful tx
  useEffect(() => {
    setThoughtInput({
      header: "",
      body: "",
      mediaHash: "",
      mediaType: "",
    });
    setThoughtTagsArray([]);
    setShowTagsInput(false);
    setShowHeaderInput(false);
  }, [useStateChanged()]);

  const renderTooltipDisclaimer = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>
        Posting a thought is a permanent onchain post. <br /> Be mindful and
        considerate when posting and consider the impact that this may have on
        you and others around you. <br /> While you can hide a thought on the
        .find website, there is no way to permanently delete a thought. <br />{" "}
        Stay safe and have fun!!
      </span>
    </Tooltip>
  );

  return (
    <div id="thought-box-outer">
      {/* desktop thought box */}

      <div
        className={
          profileThoughtBox ? "d-none d-md-flex no-border" : "d-none d-md-flex"
        }
        id="live-feed-thought-box-wrapper"
      >
        <div id="live-feed-thought-box-inner-row">
          <div id="lf-thought-img-box">
            <img
              style={{ width: "48px", height: "48px", borderRadius: "50%" }}
              src={
                user && user.profile
                  ? parseImgUrl(user.profile.avatar)
                  : "/assets/img/marketplace/find_name_with_find.png"
              }
            />
            {user && user.profile && user.profile.findName && (
              <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
            )}
          </div>{" "}
          <div id="thought-input-col">
            {showHeaderInput && (
              <ThoughtBoxHeaderInput
                setThoughtInput={setThoughtInput}
                thoughtInput={thoughtInput}
              />
            )}
            <div id="lf-thought-text-box">
              <textarea
                placeholder="Post a thought"
                value={thoughtInput.body}
                maxLength="280"
                onChange={(e) =>
                  setThoughtInput({ ...thoughtInput, body: e.target.value })
                }
              />
              <div id="lf-thought-icon-row">
                <div className="lf-thought-icon-box">
                  <img
                    onClick={() => setShowEmojis(!showEmojis)}
                    src="/assets/img/lf/lf-emoji.svg"
                  />
                  {showEmojis && (
                    <EmojiPicker
                      theme={themeStatus}
                      onEmojiClick={(emojiObject) => onEmojiClick(emojiObject)}
                    />
                  )}
                </div>
                <Dropdown>
                  <Dropdown.Toggle as={CustomDropdownToggle}>
                    <div className="lf-thought-icon-box">
                      <img src="/assets/img/lf/lf-kebab-side.svg" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="secColor"
                      onClick={() => setShowHeaderInput(!showHeaderInput)}
                    >
                      Add header
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="secColor"
                      onClick={() => setShowTagsInput(!showTagsInput)}
                    >
                      Add tags
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      className="secColor"
                      onClick={() => setShowMediaInput(!showMediaInput)}
                    >
                      Add media
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
                <div
                  className="lf-thought-icon-box"
                  id="thought-box-disclaimer"
                >
                  <OverlayTrigger
                    placement="left"
                    overlay={renderTooltipDisclaimer}
                  >
                    <i className="fa-solid fa-circle-info" />
                  </OverlayTrigger>
                </div>
                <div>
                  <Button
                    disabled={useFormStatus()}
                    onClick={() => {
                      if (setPostedClicked) {
                        setPostedClicked(true);
                      }
                      handlePublishThought(thoughtInput, thoughtTagsArray);
                    }}
                    variant="find-thoughts-post"
                  >
                    Post
                  </Button>
                </div>
              </div>
            </div>
            {showTagsInput && (
              <ThoughtBoxTagInput
                handleTagKeyDown={handleTagKeyDown}
                removeTag={removeTag}
                thoughtTagsArray={thoughtTagsArray}
              />
            )}
          </div>
        </div>
      </div>
      {/* mobile thought box */}
      <div
        id="live-feed-thought-box-wrapper-mobile"
        className={
          profileThoughtBox ? "d-flex d-md-none no-border" : "d-flex d-md-none"
        }
      >
        {" "}
        {showHeaderInput && (
          <ThoughtBoxHeaderInput
            setThoughtInput={setThoughtInput}
            thoughtInput={thoughtInput}
          />
        )}
        <textarea
          placeholder="Post a thought"
          value={thoughtInput.body}
          maxLength="280"
          onChange={(e) =>
            setThoughtInput({ ...thoughtInput, body: e.target.value })
          }
        />
        {showTagsInput && (
          <ThoughtBoxTagInput
            handleTagKeyDown={handleTagKeyDown}
            removeTag={removeTag}
            thoughtTagsArray={thoughtTagsArray}
          />
        )}
        <div id="lf-thought-icon-row">
          {/* <div className="lf-thought-icon-box">
            <img
              onClick={() => setShowEmojis(!showEmojis)}
              src="/assets/img/lf/lf-emoji.svg"
            />
            {showEmojis && (
              <EmojiPicker
                onEmojiClick={(emojiObject) => onEmojiClick(emojiObject)}
              />
            )}
          </div> */}
          <div className="lf-thought-icon-box" id="thought-box-disclaimer">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltipDisclaimer}
            >
              <i className="fa-solid fa-circle-info" />
            </OverlayTrigger>
          </div>
          <Dropdown>
            <Dropdown.Toggle as={CustomDropdownToggle}>
              <div className="lf-thought-icon-box">
                <img src="/assets/img/lf/lf-kebab-side.svg" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="secColor"
                onClick={() => setShowHeaderInput(!showHeaderInput)}
              >
                Add header
              </Dropdown.Item>
              <Dropdown.Item
                className="secColor"
                onClick={() => setShowTagsInput(!showTagsInput)}
              >
                Add tags
              </Dropdown.Item>
              {/* <Dropdown.Item
                      className="secColor"
                      onClick={() => setShowMediaInput(!showMediaInput)}
                    >
                      Add media
                    </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>

          <div>
            <Button
              disabled={useFormStatus()}
              onClick={() => {
                if (setPostedClicked) {
                  setPostedClicked(true);
                }
                handlePublishThought(thoughtInput, thoughtTagsArray);
              }}
              variant="find-thoughts-post"
            >
              Post
            </Button>
          </div>
        </div>
      </div>
      {showMediaInput && (
        <ThoughtBoxMediaModal
          showMediaInput={showMediaInput}
          setShowMediaInput={setShowMediaInput}
          user={user}
          thoughtInput={thoughtInput}
          setThoughtInput={setThoughtInput}
        />
      )}
    </div>
  );
};

export default LiveFeedThoughtBox;
