
const truncateItemName = (itemName, trunctLength) => {
  // console.log(address);
  let trunctitemNameStart;
  // let trunctAddressEnd;
  let trunctItemNameMiddle = "...";
  let trunctItemNameTotal;

  itemName = itemName.replace('bl0x', 'Bl0x')

  if (itemName && itemName.length) {
    trunctitemNameStart = itemName.substring(0, trunctLength);
    // trunctAddressEnd = address.substring(address.length - 3);

    if (itemName.length > trunctLength){
    trunctItemNameTotal =
    trunctitemNameStart + trunctItemNameMiddle;} else {
        trunctItemNameTotal = trunctitemNameStart
    }

    return trunctItemNameTotal;
  }
};

export default truncateItemName;
