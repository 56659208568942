import { Accordion} from "react-bootstrap";
import CatalogRoyaltiesCardMarket from "../../profile/profilecollection/nftdetailpage/detailspropertycard/CatalogRoyaltiesCardMarket";

import "./mpdetailpage.css";

const MpDetailRoyalties = ({
  listingStatus,
  hit,
}) => {
//   console.log(listingStatus);
  // console.log(isName)

  // console.log(offersEventsData);

  return (
    <Accordion className="mb-3 mt-2">
      <Accordion.Item id="offers-accordion-header" eventKey="0">
        <Accordion.Header className=" px-3">
          <i
            style={{ fontSize: "30px" }}
            className="fa-regular fa-star mx-2 secColor"
          />
          <span className="fw-bold">Royalties</span>{" "}
        </Accordion.Header>
        <Accordion.Body className="px-3 py-0">
          {listingStatus.listingDetails && hit && listingStatus && (
            <CatalogRoyaltiesCardMarket listingStatus={listingStatus} />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default MpDetailRoyalties;
