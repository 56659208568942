import parseImgUrl from "../../../functions/parseImgUrl";
import { handleExtend } from "../../../functions/flow-interactions/transactions";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../functions/useWalletType";
import Loading from "../../loading/loading";
import { toLocalNumber } from "../../../functions/format";
import { useEffect, useState } from "react";
import {getNamePriceInFlow} from "../../../functions/flow-interactions/scripts";

export function ActionButton(props) {
  const { type, register } = props;
  const { label, price } = props;

  return (
    <button
      type="button"
      className={`register ${type}`}
      onClick={(e) => register(type)}
    >
      {label}
      <span>{price}</span>
    </button>
  );
}

function NameItem(props) {
  const { hit, handleRegister } = props;

  const [flowPrice, setFlowPrice] = useState(null);

  const walletType = useWalletType();
  const user = useUserWalletStatus();
  const { status, cost, owner, avatar } = hit.findName;
  const nameStatus = status.toLowerCase();
  const price = toLocalNumber(cost, { min: 2 });

  useEffect(() => {
    getNamePriceInFlow(hit.name).then(setFlowPrice)
  });

  const flowPriceLabel = flowPrice
    ? `≈${(parseFloat(flowPrice) * 1.05).toFixed(2)} FLOW`
    : "";
  const usdcPriceLabel = `$${price}`;

  const args = {
    name: hit.name,
    amount: hit.findName?.NameReport?.cost || hit.findName?.cost,
    tokenAmount: flowPrice,
  };
  const register = (token) => handleRegister({ ...args, token }, walletType);
  const showFlowRegister = walletType !== "Dapper Wallet" && flowPrice;

  //name available
  return nameStatus === "free" ? (
    <div className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <strong>
              <span className="themeColor">{hit.name}</span>.find
            </strong>
          </div>
          <div
            className="aa-ItemContentDescription"
            style={{ color: "var(--text-secondary)" }}
          >
            {usdcPriceLabel} {showFlowRegister && `or ${flowPriceLabel}`}
          </div>
        </div>
      </div>
      <div className="aa-ItemActions flex-row gap-2">
        {showFlowRegister && (
          <ActionButton
            type={"flow"}
            register={register}
            label={"Register (FLOW)"}
            price={flowPriceLabel}
          />
        )}
      </div>
    </div>
  ) : //name locked
  nameStatus === "locked" ? (
    <a href={`/${hit.name}`} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <strong>
              <span className="themeColor">{hit.name}</span>.find
            </strong>
          </div>
          <div
            className="aa-ItemContentDescription"
            style={{ color: "var(--text-secondary)" }}
          >
            {price} USDC
          </div>
        </div>
      </div>
      {/* //locked - renew if owner disabled if not */}
      <div className="aa-ItemActions">
        {user && user.addr === owner ? (
          <button
            onClick={() =>
              handleExtend(hit.name, parseFloat(price).toFixed(2), walletType)
            }
            type="button"
            className="register"
          >
            Renew
          </button>
        ) : (
          <button disabled={true} type="button" className="register">
            Locked
          </button>
        )}
      </div>
    </a>
  ) : //name has profile
  avatar ? (
    <a href={`/${hit.name}`} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <span>
              <img
                crossOrigin="anonymous"
                src={parseImgUrl(avatar, "thumbnail")}
                alt="avatar"
                width="40"
                height="40"
              />
              <strong className={"ms-2"}>
                <span className="themeColor">{hit.name}</span>.find
              </strong>
            </span>
          </div>
        </div>
      </div>
    </a>
  ) : (
    <Loading />
  );
}

export default NameItem;
