import { Row, Col, Button, Container } from "react-bootstrap";
import { useFormStatus } from "../../../functions/DisabledState";
import { handleFullfillAuction } from "../../../functions/flow-interactions/transactions";

export function FulfillEndedNameOffer({ bid }) {
  // //console.log(bid);

  return (
    <Container className="commerceinfo p-3">
      <Row className="my-3 align-items-center">
        <Col xs="12" md="auto">
          You won the auction on <b><span className="themeColor">{bid.name}</span>.find</b> with a bid of{" "}
          <b>{bid.amount * 1} FUSD</b>
        </Col>
      </Row>
      <Row>
        <fieldset disabled={useFormStatus()}>
          <Row>
            <Col xs="12" md="auto"></Col>
            <Col className="mt-3 mt-md-auto" align="right">
              <Button
                onClick={() =>
                  handleFullfillAuction(bid.name, bid.lease.address)
                }
                variant="find-outline-commerce"
              >
                Fulfill
              </Button>
            </Col>
          </Row>
        </fieldset>
      </Row>
    </Container>
  );
}
