import { Container } from "react-bootstrap";

const AlphaTestingHeading = () => {
  return (
    <Container fluid={+true} className="pt-3">
      <h3>.find Testnet Hub</h3>
      <p>
        Welcome to the .find testnet guide. <br /> Below you will find a guide
        to some of the things you can do on the testnet platform including
        minting Flovatar and Bl0x NFTs. <br /> Need more info? You can catch us
        on <a href="https://discord.gg/tCSFSpFcRv">discord</a>.{" "}
      </p>
    </Container>
  );
};

export default AlphaTestingHeading;
