// ----------------------------------------------------
import { COMMON, traitGroups } from "./Results/Sidebar/Filters/TagFilter/groups";

export function capitalize(str) {
  if (!str) {
    return "";
  }
  return str[0].toUpperCase() + str.slice(1);
}

// ----------------------------------------------------
export function splitAndCap(str, splitter = " ") {
  if (!str) {
    return "";
  }
  return str.split(splitter).map(capitalize).join(" ");
}

// ----------------------------------------------------
export function sortBy(field) {
  const getter = typeof field === "function" ? field : (x) => x[field];
  return function (a, b) {
    const aValue = getter(a);
    const bValue = getter(b);
    if (aValue >= bValue) {
      return 1;
    }
    if (aValue < bValue) {
      return -1;
    }

    return 0;
  };
}

// ----------------------------------------------------
export function groupItems(groups) {
  const { organizer } = groups;

  return function (items) {
    return items.reduce((acc, item) => {
      let key = organizer(item);
      if (!acc[key]) {
        acc[key] = [item];
      } else {
        acc[key].push(item);
      }
      return acc;
    }, {});
  };
}

// ----------------------------------------------------
export function singleCollection(collectionName) {
  return [`collection_name:=["${collectionName}"]`];
}

// ----------------------------------------------------
export function itemLogo(param) {
  switch (param) {
      case "FlowToken": return <img src="/assets/img/walletProviders/flow-logo-device-transparent.png" className="imageAutoHeight" alt="flow logo" />;
      case "FUSD": return <img src="/assets/img/walletProviders/Flow-FUSD-logo.png" className="imageAutoHeight" alt="flow logo" />;
      case "DapperUtilityCoin": return <img src="/assets/img/walletProviders/dapper-logo-device-transparent.png" className="imageAutoHeight" alt="dapper logo" />;
      case "FlowUtilityToken": return <img src="/assets/img/walletProviders/dapper-flow-logo.png" className="imageAutoHeight" alt="dapper and flow logo" />;

      default: return ""
  }
}

//----------------------------------------------------
export function itemName(param) {
  switch (param) {
      case "FlowToken": return "Flow";
      case "FUSD": return "FUSD"
      case "DapperUtilityCoin": return "USD $";
      case "FlowUtilityToken": return "Dapper Flow";

      default: return ""
  }
}

//---------------------------------------------------
export function walletCheck(param) {
  switch (param) {
    case "FlowToken": return "Flow Wallet";
    case "FUSD": return "Flow Wallet"
    case "DapperUtilityCoin": return "Dapper Wallet";
    case "FlowUtilityToken": return "Dapper Wallet";

    default: return console.error("This token is not recognised");
}
}