import React from "react";
import { Button } from "react-bootstrap";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import { handleSetPfp } from "../../../../../functions/flow-interactions/transactions";

const ColDetailOwnItemMakePfp = ({ listingStatus }) => {
  return (
    <>
      <Button variant='find-collection-pfp-outline' className="mb-2" onClick={() => handleSetPfp(parseImgUrl(listingStatus.itemThumbnail))}>Make profile picture</Button>
    </>
  );
};

export default ColDetailOwnItemMakePfp;
