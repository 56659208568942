import {
  Col,
  Container,
  Image,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./footer.css";
import FooterLinks from "./footerlinks";
import { animateScroll as scroll } from "react-scroll";
import FooterLinksMobile from "./footerlinksmobile";
import { Link } from "react-router-dom";
import { UseThemeStatus } from "../../functions/themeMode";
import FooterLower from "./FooterLower";

function Footer(props) {
  const {className} = props;
  // const renderTooltipFlow = (props) => (
  //   <Tooltip {...props}>
  //     <span style={{ fontSize: "16px" }}>Built on Flow</span>
  //   </Tooltip>
  // );
  // const renderTooltipGraffle = (props) => (
  //   <Tooltip {...props}>
  //     <span style={{ fontSize: "16px" }}>Graffle</span>
  //   </Tooltip>
  // );

  // const renderTooltipBlocto = (props) => (
  //   <Tooltip {...props}>
  //     <span style={{ fontSize: "16px" }}>Blocto</span>
  //   </Tooltip>
  // );
  // const renderTooltipLilico = (props) => (
  //   <Tooltip {...props}>
  //     <span style={{ fontSize: "16px" }}>Lilico</span>
  //   </Tooltip>
  // );
  // const renderTooltipTypesense = (props) => (
  //   <Tooltip {...props}>
  //     <span style={{ fontSize: "16px" }}>Typesense</span>
  //   </Tooltip>
  // );
  const footerClass = `footer-wrapper ${className}`
  return (
    <div className={footerClass}>
      <FooterLinks />
      <FooterLower/>
      {/* <Container fluid={+true} id="footer-upper">
        <Row className="align-items-center justify-content-center text-center d-block d-md-none pt-3 px-0 mx-0">
          <Col xs="5">
            <Row>
              <Col className="text-start">
                <Link to={"/p/privacy"} style={{ textDecoration: "none" }}>
                  <small className="copyright">Privacy policy</small>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="text-start">
                <Link to={"/p/terms"} style={{ textDecoration: "none" }}>
                  <small className="copyright">Terms and conditions</small>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="text-start">
                {" "}
                <small className="copyright"> @copyright find.xyz 2021</small>
              </Col>
            </Row>
          </Col>
          <Col xs="1"></Col>
        </Row>
      </Container> */}
      {/* <Container
        fluid={+true}
        className="mb-3 d-flex align-items-center justify-content-center footer-lower w-100 mx-0 px-0"
      >
        <Row className="px-lg-0 px-0 mx-0 w-100 pb-3 align-items-center justify-content-center">
          <Row className="py-3 mx-0 px-1">
            <Col lg="auto" className="d-none d-md-block">
              <Image
                onClick={() => scroll.scrollTo("navbar")}
                src="/assets/img/find_logo.png"
                className="footerlogo"
              />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center px-1 mx-0">
            <Col className="">
              <Row className="align-items-center justify-content-center ">
                <Col align="start" className="d-none d-md-block">
                  <Link to={"/p/privacy"} style={{ textDecoration: "none" }}>
                    <small className="copyright">Privacy policy</small>
                  </Link>
                </Col>
                <Col align="start" className="d-none d-md-block ">
                  <Link to={"/p/terms"} style={{ textDecoration: "none" }}>
                    <small className="copyright">Terms and conditions</small>
                  </Link>
                </Col>
                <Col align="start" className="d-none d-md-block">
                  <small className="copyright"> @copyright find.xyz 2021</small>
                </Col>
                <Col lg="auto" className="d-md-none px-2">
                  <Image
                    onClick={() => scroll.scrollTo("navbar")}
                    src="/assets/img/find_logo.png"
                    className="footerlogo"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs="3" lg="4"></Col>
            <Col>
              <Row className="d-flex align-items-center justify-content-center">
                <Col className="d-none d-md-block">
                  <b>Proudly powered by:</b>
                </Col>
                <OverlayTrigger placement="top" overlay={renderTooltipFlow}>
                  <Col align="center">
                    <a
                      href="https://www.onflow.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        alt="footer-flow-logo"
                        fluid={+true}
                        src="/assets/img/footer/flow-foot.svg"
                        className="footerlogo"
                      />
                    </a>
                  </Col>
                </OverlayTrigger>

                <OverlayTrigger placement="top" overlay={renderTooltipBlocto}>
                  <Col align="center">
                    <a
                      href="https://portto.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        alt="footer-blocto-logo"
                        fluid={+true}
                        src="/assets/img/footer/blocto-foot.svg"
                        className="footerlogo"
                      />
                    </a>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={renderTooltipLilico}>
                  <Col align="center">
                    <a
                      alt="footer-lilico-logo"
                      href="https://lilico.app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        id="footer-lilico-img"
                        fluid={+true}
                        src="/assets/img/footer/lilico-foot.svg"
                        className="footerlogo"
                      />
                    </a>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltipTypesense}
                >
                  <Col align="center">
                    <a
                      alt="footer-typesense-logo"
                      href="https://typesense.app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        id="footer-typesense-img"
                        fluid={+true}
                        src={
                          UseThemeStatus() === "light"
                            ? "/assets/img/footer/typesense-foot-light.png"
                            : "/assets/img/footer/typesense-foot-dark.png"
                        }
                        className="footerlogo"
                      />
                    </a>
                  </Col>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={renderTooltipGraffle}>
                  <Col align="center">
                    <a
                      alt="footer-graffle-logo"
                      href="https://www.graffle.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        fluid={+true}
                        src="/assets/img/footer/graffle-foot.svg"
                        className="footerlogo"
                      />
                    </a>
                  </Col>
                </OverlayTrigger>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container> */}
    </div>
  );
}
export default Footer;
