import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import ReactTimeago from "react-timeago";
import { Script } from "../../../../functions/script";
import { UseThemeStatus } from "../../../../functions/themeMode";
import {
  handleBuyerFulfillNFTAuction,
  handleFullfillAuction,
} from "../../../../functions/flow-interactions/transactions";
import Loading from "../../../loading/loading";
import MarketplaceItemBoughtModal from "../../../marketplace/marketplaceitemboughtmodal/marketplaceitemboughtmodal";
import MpDetailMakeOffer from "../../../search/mpdetailpage/mpdetailmakeoffer";
import MpDetailRemoveListing from "../../../search/mpdetailpage/mpdetailremovelisting";
import FlowSticker from "../../ftstickers/FlowSticker";
import FUSDSticker from "../../ftstickers/FUSDSticker";
import MakeBidNftMpModal from "./makebidnftmpmodal";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import parseNameLink from "../../../../functions/parseNameLink";
import { useSwipeable } from "react-swipeable";
import { toLocalNumber } from "../../../../functions/format";
import {
  DAPPER,
  getListingType,
  getWalletType,
  NONE,
} from "../../../search/mpdetailpage/utils";
import { useWalletType } from "../../../../functions/useWalletType";
import { PriceTag, WalletHints } from "../../../search/mpdetailpage/components";

// Swipeable Config
const config = {
  delta: 150, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: true,
  swipeDuration: 250,
};

function Title(props) {
  const { listingStatus } = props;
  const title = getTitle(listingStatus);
  return (
    <Row className="py-2">
      <Col className="fw-bold">{title}</Col>
      <Timer {...props} />
    </Row>
  );
}
function Timer(props) {
  const { isName, listingStatus, listingDetails } = props;

  const { auctionStarted } = listingStatus;
  const { auction, auctionEnds } = listingDetails;
  const timestamp = (isName ? auctionEnds : auction.auctionEndsAt) * 1000;

  if (auctionStarted) {
    return (
      <Col className="d-flex justify-content-end">
        <span className="secColor me-1">Ends:</span>
        <span className="fw-bold">
          <ReactTimeago date={new Date(timestamp)} timeStyle="twitter" />
        </span>
      </Col>
    );
  }

  return (
    <Col className="d-flex justify-content-end">
      <span className="propertiesWrap">
        Timer begins on first bid
        <i className="fa-solid fa-circle-info ms-1" />
      </span>
    </Col>
  );
}

function LatestBid(props) {
  const { latestBid, updatedProfile } = props;

  if (!updatedProfile) {
    return null;
  }

  let src = updatedProfile?.avatar;

  if (!updatedProfile) {
    src =
      latestBid?.blockEventData?.buyerAvatar ||
      "/assets/img/store/fnamestore.png";
  }
  // TODO: move to CSS
  const style = {
    height: "30px",
    width: "30px",
    borderRadius: "30px",
  };

  const profileName = parseNameLink(updatedProfile);

  return (
    <Row className="align-items-center pb-3">
      <Col xs="auto">
        <Image crossOrigin="anonymous" style={style} src={src} />
      </Col>
      <Col>{profileName}</Col>
    </Row>
  );
}

function RenderButtons(props) {
  const { isName } = props;
  const { handleShow } = props;
  const { hit, hits } = props;
  const { toastStatus, themeStatus } = props;
  const { listingDetails, listingStatus } = props;
  const { userHasOffer, findUser } = props;
  const { buyClicked, handleBuy } = props;

  const personalItem = listingStatus.ownItem;

  const showMakeOffer =
    listingDetails &&
    listingStatus.listingDetails.linkedForMarket &&
    !listingStatus.forSale;

  const showFulfillAction =
    listingStatus.auctionEnded &&
    listingStatus.userHighestBidder &&
    listingStatus.auctionEndedAboveReserve;

  const showRemoveListing = !listingStatus.auctionEnded && personalItem;
  const showMakeBid = !listingStatus.auctionEnded && !personalItem;
  const showModal = buyClicked && toastStatus.status === "success";

  return (
    <Row className="pt-3">
      <Col align="center" xs="12" lg="6" className="pb-xs-2 mb-2 mb-lg-0">
        {showMakeOffer && (
          <MpDetailMakeOffer
            hit={hit}
            themeStatus={themeStatus}
            findUser={findUser}
            profileDetails={listingStatus.sellerProfile}
            listingDetails={listingDetails}
            userHasOffer={userHasOffer}
            isName={isName}
          />
        )}

        {showRemoveListing && (
          <MpDetailRemoveListing
            hit={hit}
            label={"Cancel Auction"}
            isName={isName}
            forSale={listingStatus.forSale}
            forAuction={listingStatus.forAuction}
            listingDetails={listingDetails}
            themeStatus={themeStatus}
            listingStatus={listingStatus}
          />
        )}
      </Col>

      {!personalItem && (
        <Col align="center" xs="12" lg="6" className="pb-xs-2">
          <Row>
            {showFulfillAction && (
              <Button
                className="w-100"
                variant="find-nochange-dark"
                onClick={handleBuy}
              >
                Fulfill
              </Button>
            )}

            {showMakeBid && (
              <Button
                className="w-100"
                variant="find-nochange-dark"
                onClick={handleShow}
              >
                Make Bid
              </Button>
            )}
          </Row>
        </Col>
      )}

      <Modal centered data-theme={themeStatus} show={showModal}>
        <MarketplaceItemBoughtModal nftDetails={hit} isName={isName} />
      </Modal>
    </Row>
  );
}

function getTitle(listingStatus) {
  const { auctionStarted, auctionEndedAboveReserve } = listingStatus;

  if (!auctionStarted) {
    return "Starting bid:";
  }

  const auctionCompleted = auctionStarted && auctionEndedAboveReserve;
  return auctionCompleted ? "Winning bid:" : "Current bid:";
}

async function getProfile(user) {
  return Script(scripts.getProfile, { user });
}

function RenderTitle(props) {
  const { isName, nftName } = props;

  const prefix = "Make a bid for";
  if (!isName) {
    return (
      <h4 className="fw-bold mb-0">
        {prefix} {nftName}
      </h4>
    );
  }

  return (
    <h4 className="fw-bold mb-0">
      {prefix}
      <span className="themeColor ms-1">{nftName}</span>
      .find
    </h4>
  );
}

export default function MakeBidNftMp(props) {
  // Deconstruct props
  const { hit, hits } = props;
  const { findUser, profileDetails } = props;
  const { isName, ownItem, nftDetails } = props;
  const { listingDetails, listingStatus, latestBid } = props;
  const { user, userHasOffer, userHighestBidder } = props;
  const { toastStatus, themeStatus } = props;

  const [show, setShow] = useState(false);
  const [buyerClickedFulfil, setBuyerClickedFulfil] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  const [profileAddress, setProfileAddress] = useState(null);

  const listingType = getListingType(listingDetails, isName, "auction");
  const walletType = getWalletType(useWalletType());

  const showButtons = walletType === listingType;
  const showHints = walletType === NONE || !showButtons;

  useEffect(() => {
    if (latestBid?.blockEventData?.buyer) {
      setProfileAddress(latestBid.blockEventData.buyer);
    }
    if (listingDetails?.latestBidBy) {
      setProfileAddress(listingDetails.latestBidBy);
    }
  }, [latestBid, listingDetails]);

  useEffect(() => {
    if (profileAddress) {
      console.log({ profileAddress });
      try {
        getProfile(profileAddress).then((profile) => {
          setUpdatedProfile(profile);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [profileAddress]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBuyerMpFulfil = () => {
    setBuyerClickedFulfil(true);
    if (isName) {
      handleFullfillAuction(nftDetails.nft_name, nftDetails.seller);
    } else {
      handleBuyerFulfillNFTAuction(nftDetails.uuid);
    }
  };

  const handlers = useSwipeable({
    ...config,
    onSwipedDown: handleClose,
  });

  // We need to hide block for Auctions on Dapper wallet, since it's not supported
  const wrongWallet = listingType === DAPPER || walletType === DAPPER;
  const notAuction = !listingStatus.forAuction;
  const hideBlock = wrongWallet || notAuction;

  if (hideBlock) {
    return null;
  }

  if (!listingDetails) {
    return <Loading />;
  }

  const cardId = "mp-price-box-card-sale";
  const cardClassName = "mp-price-box-card px-4 pb-4 pt-4 mb-2";

  return (
    <Row className="py-2 justify-content-center mb-4  ">
      <Col xs="12">
        <Card id={cardId} className={cardClassName}>
          <Title {...props} />
          <PriceTag {...props} type="auction" />
          <LatestBid
            updatedProfile={updatedProfile}
            latestBid={latestBid}
            listingDetails={listingDetails}
          />
          <RenderButtons
            {...props}
            show={showButtons}
            handleShow={handleShow}
            buyClicked={buyerClickedFulfil}
            handleBuy={handleBuyerMpFulfil}
          />
          <WalletHints
            show={showHints}
            listingDetails={listingDetails}
            listingType={listingType}
            action={"bid on this item"}
          />
        </Card>

        <Modal
          centered
          show={show}
          onHide={handleClose}
          data-theme={UseThemeStatus()}
        >
          <div {...handlers}>
            <Modal.Header
              style={{ border: "none" }}
              closeButton
              closeLabel="close window"
            >
              <Modal.Title className="text-center w-100">
                <RenderTitle isName={isName} nftName={hit.nft_name} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <MakeBidNftMpModal
                userHighestBidder={userHighestBidder}
                listingDetails={listingDetails}
                nftDetails={nftDetails}
                profileDetails={profileDetails}
                isName={isName}
                hit={hit}
                user={user}
                listingStatus={listingStatus}
                latestBid={latestBid}
              />
            </Modal.Body>
          </div>
        </Modal>
      </Col>
    </Row>
  );
}
