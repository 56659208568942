import React, { useState } from "react";
import "./LiveFeedWrapper.scss";

const ThoughtBoxUploadIPFS = () => {
  const [uploadedMedia, setUploadedMedia] = useState(null);
  const [uploadedMediaType, setUploadedMediaType] = useState(null);
  const [uploadedMediaDisplay, setUploadedMediaDisplay] = useState(null);
  console.log(uploadedMedia);

  const uploadMedia = (e) => {
    setUploadedMedia(e.target.files[0]);
    setUploadedMediaType(e.target.files[0].type);
    setUploadedMediaDisplay(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <div>
      IPFS
      {uploadedMediaDisplay && (
        <>
          {uploadedMediaType && uploadedMediaType.includes("image") ? (
            <img
              style={{ width: "100%" }}
              src={uploadedMediaDisplay}
              alt="The uploaded file"
            />
          ) : uploadedMediaType && uploadedMediaType.includes("video") ? (
            <video style={{ width: "100%" }} autoplay controls muted>
              <source src={uploadedMediaDisplay} type={uploadedMediaType} />
            </video>
          ) : null}
        </>
      )}
      <form action="">
        <input onChange={uploadMedia} type="file" id="myFile" name="filename" />
        <input type="submit" />
      </form>
    </div>
  );
};

export default ThoughtBoxUploadIPFS;
