import React from "react";

import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Accordion,
  useAccordionButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { IncreaseRemoveOffer } from "../bidsoffersreceived/increaseremoveoffer/increaseremoveoffer";
import "./bidsofferssent.css";
import { FulfillEndedNameOffer } from "./fulfillendednameoffer";

export function BidsOffersSent({ bids, profile }) {
  // //console.log(bids);

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <>
        <div className="d-none d-lg-block">
          <Button
            variant="find-outline-commerce"
            size="sm"
            onClick={decoratedOnClick}
          >
            {children}
          </Button>
        </div>
        <div className="d-lg-none">
          <Button
            onClick={decoratedOnClick}
            style={{
              backgroundImage: "var(--accordion-chevron)",
              width: "26px",
              height: "26px",
              backgroundColor: "var(--background)",
              borderColor: "var(--background)",
              boxShadow: "none",
            }}
          ></Button>
        </div>
      </>
    );
  }

  return (
    <Container
      fluid={+true}
      id="names-for-auction"
      className="px-0 fade-in-right mb-3"
    >
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <h4>Names you are bidding/offering on</h4>
            </Col>
          </Row>
          <Accordion>
            <table className="profile-table" id="profile-names-bidding-table">
              <tbody>
                {bids && bids.length !== 0 ? (
                  bids.map((bid, i) => (
                    <React.Fragment key={i}>
                      <tr
                        className="align-items-center profile-names-table-names-row"
                        id="names-bidding-row"
                      >
                        <td className="profile-names-table-names-col">
                          <Link to={"/mp/FIND/" + bid.name}>
                            <span className="fw-bold">
                              <span className="themeColor">{bid.name}</span>
                              .find
                            </span>
                          </Link>
                        </td>

                        <td className="profile-names-table-price-col">
                          Your {bid.lease.auctionEnds ? "bid" : "offer"}: &nbsp;
                          <b>{parseFloat(bid.amount).toFixed(2)} FUSD</b>
                        </td>
                        <td className="profile-names-table-sticker-col">
                          {bid.lease.auctionEnds ? (
                            <span className="profile-sticker">
                              {bid.lease.auctionEnds < bid.lease.currentTime
                                ? "Ended: "
                                : "Ends: "}{" "}
                              <ReactTimeago
                                date={new Date(bid.lease.auctionEnds * 1000)}
                              />
                            </span>
                          ) : (
                            <span className="profile-sticker">
                              Waiting for response
                            </span>
                          )}
                        </td>

                        <td className="profile-names-table-button-col">
                          <CustomToggle eventKey={i} bid={bid}>
                            Review
                          </CustomToggle>
                        </td>
                        {/* <td lg="auto" align="right">{!user.loggedIn ? <Button variant="find-outline-dark" size='sm' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Make Offer</Button> : <Button variant="find-outline-dark" size='sm'>Sell/Extend</Button>}</Col> */}
                      </tr>

                      <tr className="profile-mobile-row">
                        <td className="mobile-store-sticker-col">
                          {bid.lease.auctionEnds ? (
                            <span className="store-sticker">
                              {bid.lease.auctionEnds < bid.lease.currentTime
                                ? "Ended: "
                                : "Ends: "}{" "}
                              <ReactTimeago
                                date={new Date(bid.lease.auctionEnds * 1000)}
                              />
                            </span>
                          ) : (
                            <span className="store-sticker">
                              Waiting for response
                            </span>
                          )}
                        </td>
                        <td align="end">
                          <CustomToggle eventKey={i}>Review</CustomToggle>
                        </td>
                      </tr>

                      <tr>
                        <td className="profile-dropdown" colSpan={4}>
                          <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                              <div
                                className="collapse show"
                                id="collapseExample"
                              >
                                {bid.type === "auction" &&
                                bid.lease.auctionEnds <
                                  bid.lease.currentTime ? (
                                  <FulfillEndedNameOffer bid={bid} />
                                ) : (
                                  <IncreaseRemoveOffer bid={bid} />
                                )}
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </td>
                      </tr>

                      <tr>
                        <td className="profile-mobile-dropdown" colSpan={2}>
                          <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                              <div
                                className="collapse show"
                                id="collapseExample"
                              >
                                {bid.type === "auction" &&
                                bid.lease.auctionEnds <
                                  bid.lease.currentTime ? (
                                  <FulfillEndedNameOffer bid={bid} />
                                ) : (
                                  <IncreaseRemoveOffer bid={bid} />
                                )}
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </td>
                      </tr>

                      {/* <tr>
                        <td className="d-none d-md-table-cell" colSpan={4}>
                          <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                              <div
                                className="collapse show"
                                id="collapseExample"
                              >
                                {bid.type === "auction" &&
                                bid.lease.auctionEnds <
                                  bid.lease.currentTime ? (
                                  <FulfillEndedNameOffer bid={bid} />
                                ) : (
                                  <IncreaseRemoveOffer bid={bid} />
                                )}
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </td>
                      </tr>

                      <tr>
                        <td className="d-table-cell d-md-none" colSpan={3}>
                          <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                              <div
                                className="collapse show"
                                id="collapseExample"
                              >
                                {bid.type === "auction" &&
                                bid.lease.auctionEnds <
                                  bid.lease.currentTime ? (
                                  <FulfillEndedNameOffer bid={bid} />
                                ) : (
                                  <IncreaseRemoveOffer bid={bid} />
                                )}
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </td>
                      </tr> */}
                    </React.Fragment>
                  ))
                ) : (
                  <tr className="align-items-center mt-3">
                    <td>
                      <small>
                        {" "}
                        You aren't currently bidding or offering on any names
                      </small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
}
