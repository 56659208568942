import { atom, useAtom } from "react-atomic-state";

const themeStatus = atom(localStorage.getItem("find-theme") ? localStorage.getItem("find-theme") :  "light");

export const lightMode = () => {localStorage.setItem("find-theme", "light");themeStatus.set("light");document.body.classList.remove('dark')}
export const darkMode = () => {localStorage.setItem("find-theme", "dark");themeStatus.set("dark"); document.body.classList.add('dark')}

if (window.matchMedia && !localStorage.getItem("find-theme") && (window.matchMedia('(prefers-color-scheme: dark)').matches)){
    darkMode()
}

export const UseThemeStatus = () => useAtom(themeStatus);

