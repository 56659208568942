import axios from "axios";
import React, { useEffect, useState } from "react";
import LatestBidsCard from "../../../../search/latestbids/latestbidscard";

const ColDetailLatestBids = ({ listingStatus }) => {
  const [latestBids, setLatestBids] = useState(null);
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  useEffect(() => {
    if (
      listingStatus.allCurrentListings.forAuction.auctionInfo.auctionStarted
    ) {
      const getEvents = async () => {
        let data;
        let res = await axios.get(
          "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?eventType=A." +
            contractAddress +
            ".FindMarketAuctionEscrow.EnglishAuction&id=" +
            listingStatus.itemId +
            "&pageSize=3"
        );
        data = res.data;
        setLatestBids(
          data.filter(
            (event) => event.blockEventData.status === "active_ongoing"
          )
        );
      };
      getEvents();
    }
  }, []);

  // console.log(latestBids)

  return (
    <>
      {latestBids && latestBids.length > 0 && (
        <span className="fw-bold">Latest bids:</span>
      )}
      {latestBids &&
        latestBids.length > 0 &&
        latestBids.map((activity, i) => {
          return (
            <LatestBidsCard
              key={i}
              activity={activity}
              i={i}
              isName={false}
            />
          );
        })}
    </>
  );
};

export default ColDetailLatestBids;
