import React from "react";
import CustomImageFull from "../CustomImage/CustomImageFull";

const DropPageMintBoxImage = ({ packDetails }) => {
  const gateway = "https://find.mypinata.cloud/ipfs"
  const headerBackgroundUrl = packDetails
    ? `${gateway}/${packDetails.collectionDisplay.bannerImage.file.cid}`
    : null;
  return (
    <div className="drop-page-mint-box-image-wrapper">
      <CustomImageFull imgUrl={headerBackgroundUrl} />
    </div>
  );
};

export default DropPageMintBoxImage;
