import { useConnector, useRange } from "react-instantsearch-hooks-web";
import connectRange from "instantsearch.js/es/connectors/range/connectRange";
import "rheostat/initialize";
import Rheostat from "rheostat";
import "rheostat/css/rheostat.css";
import { useEffect, useState } from "react";
import "./CustomRangeSlider.css";
import { Col, Row } from "react-bootstrap";

function useRangeSlider(props) {
  return useConnector(connectRange, props);
}

export function RangeSlider(props) {
  const {attribute = "amount"} = props;
  const { start, range, canRefine, refine } = useRange({
    attribute,
  });
  const [stateMin, setStateMin] = useState(start[0]);
  const [stateMax, setStateMax] = useState(start[1]);

  // console.log(range)
  // console.log(start)
  //console.log(currentRefinement);

  useEffect(() => {
    if (canRefine) {
      if (start[0] === -Infinity) {
        setStateMin(range.min);
      }
      if (start[1] === Infinity) setStateMax(range.max);
    }
  }, [start]);

  // console.log(stateMax);

  //   const handleSliderRange = (e) => {
  // console.log(e.target.value)
  // refine([parseFloat(e.currentTarget.value)]);
  //   }

  // if (min === max) {
  //   return null;
  // }

  const onChange = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
    if (start[0] !== min || start[1] !== max) {
      refine([min, max]);
    }
  };

  const changeInputMin = (e) => {
    if ((e.target.value <= stateMax - 1) && (e.target.value >= 0)) {
      setStateMin(e.target.value);
      refine([e.target.value, stateMax]);
    }
  };

  const changeInputMax = (e) => {
    if ((e.target.value >= stateMin + 1) && (e.target.value <= range.max)) {
      setStateMax(e.target.value);
      refine([stateMin, e.target.value]);
    }
  };

  const onValuesUpdated = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
  };

  return (
    <div className="accordion-body--wide">
      {" "}
      <Rheostat
        min={range.min}
        max={range.max}
        values={[start[0], start[1]]}
        onChange={onChange}
        onValuesUpdated={onValuesUpdated}
      />
      <Row className="mt-2">
        <Col>
          <input
            className="slider-input"
            value={stateMin}
            min={range.min}
            max={stateMax - 1}
            type="number"
            onChange={changeInputMin}
          />
        </Col>
        <Col align="end">
          <input
            className="slider-input"
            value={stateMax}
            min={stateMin + 1}
            max={range.max}
            type="number"
            onChange={changeInputMax}
          />
        </Col>
      </Row>
      {/* <input
            type="range"
            min={range.min}
            max={range.max}
            // value={Number.isFinite(start[0]) ? start[0] : '0'}
            onChange={handleSliderRange}
          /> */}
    </div>
  );
}
