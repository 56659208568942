import { useEffect, useState } from "react";
import { useStateChanged } from "./DisabledState";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import { Script } from "./script";

const network = process.env.REACT_APP_NETWORK;

const useGetFactoryCollections = (address) => {
  const [collectionItems, setCollectionItems] = useState();
  const [collectionCounter, setCollectionCounter] = useState(1);
  const [getFinished, setGetFinished] = useState(false)

  useEffect(() => {
    async function getUserCollections(addr) {


      const nftCatalogItems = await Script(scripts.getNFTCatalogIDs, {
        user: addr,
        collections: [],
      });

      setCollectionItems((collectionItems) => {
        setCollectionCounter((collectionCounter) => collectionCounter + 1);
        return {...collectionItems,nftCatalogItems};
      });

    }
    try {
      getUserCollections(address);
    } catch (error) {
      console.error(error);
    }


    // eslint-disable-next-line
  }, [useStateChanged()]);

// console.log(collectionItems)
// console.log(getFinished)
 

useEffect(()=>{
  if ((network === "testnet" && collectionCounter === 4) || (network === "mainnet" && collectionCounter === 7)){
    setGetFinished(true)
  }
}, [collectionCounter])


  return {
    collectionItems,
    getFinished,
  };
};

export default useGetFactoryCollections;
