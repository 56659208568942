import React from "react";
import { handleUserFindNameOptOut } from "../../../firebaseConfig";
import "./createprofile.scss";

const CreateProfileContinue = ({ currentStage, setCurrentStage, user }) => {

 const handleContinueWithoutNameClicked = ()=>{
  handleUserFindNameOptOut(user.addr)
  setCurrentStage(4)
  }

  return (
    <div id="create-profile-continue-wrapper">
      <div
        onClick={handleContinueWithoutNameClicked}
        className="create-profile-continue-card"
      >
        <div>
          <div className="create-card-upper-text">
            Continue without a .find name
          </div>
          <div className="create-card-lower-text">
            You can continue to use .find for free
          </div>
        </div>
        <div>&gt;</div>
      </div>
    </div>
  );
};

export default CreateProfileContinue;
