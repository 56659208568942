import React from "react";

const imagekitURL = "https://images.find.xyz/";
const defaultImage = "https://i.imgur.com/8W8NoO1.png";
const pinataRoot =
  process.env.REACT_APP_PINATA_ROOT || "https://gateway.pinata.cloud/";

const transforms = {
  icon: "tr:w-100,h-100,fo-auto/",
  thumbnail: "tr:w-300,h-300,fo-auto/",
  detail: "tr:w-600,fo-auto/",
  fullSize: "/",
  default: "tr:w-1500/",
};

const isExcluded = (link, exclude) => {
  return (
    link.includes("bitku.art") || link.includes("www.flowns.org") || exclude
  );
};

const isFindName = (link) => {
  return link.includes("/assets/img/marketplace/find_name_with_find");
};

const getNameUrl = (finaName) => {
  return `${imagekitURL}tr:ot-${finaName},ots-55,otc-58B792,ox-N166,oy-N24,ott-b/${defaultImage}`;
};

const parseLink = (link) => {
  if (link.includes("ipfs://")) {
    return link.replace("ipfs://", `${pinataRoot}ipfs/`);
  }
  return link.replace("gateway.pinata", "find.mypinata");
};

export default function parseImgUrl(link, type, findName, exclude) {
  if (!link) return defaultImage;
  if (isFindName(link)) return getNameUrl(findName);

  const parsedLink = parseLink(link);
  if (isExcluded(link, exclude)) return parsedLink;

  const transform = transforms[type] || transforms.default;
  return `${imagekitURL}${transform}${parsedLink}`;
}