import { createContext, useContext, useEffect, useRef, useState } from "react";
import { query } from "@onflow/fcl";

export const resolveFINDAddresses = async (addressList) => {
  const args = (arg, t) => [arg(addressList, t.Array(t.Address))];
  const cadence = `
      import FIND from 0x097bafa4e0b48eef
      import Profile from 0x097bafa4e0b48eef

      access(all) fun resolve(_ address: Address): String? {
        let account=getAccount(address)
        let leaseCap = account.capabilities.borrow(<&FIND.LeaseCollection{FIND.LeaseCollectionPublic}>(FIND.LeasePublicPath))
        
        if !leaseCap {
          return nil
        }
        let profile= Profile.find(address).asProfile()
        let leases = leaseCap.borrow()!.getLeaseInformation() 
        var time : UFix64?= nil
        var name :String?= nil
        for lease in leases {
          //filter out all leases that are FREE or LOCKED since they are not actice
          if lease.status != "TAKEN" {
            continue
          }
          //if we have not set a 
          if profile.findName == "" {
            if time == nil || lease.validUntil < time! {
              time=lease.validUntil
              name=lease.name
            }
          }
          if profile.findName == lease.name {
            return lease.name
          }
        }
        return name
      }

      access(all) fun main(addresses:[Address]):[String?] { 
        let res:[String?] = []
        for address in addresses {
          let name = resolve(address)
          res.append(name)
        }  
        return res
      }
  `;
  const names = await query({ args, cadence });
  return addressList.reduce((acc, item, i) => {
    acc[item] = names[i];
    return acc;
  }, {});
};

const FindResolverContext = createContext(null);
export const FindResolverContainer = (props) => {
  const { children } = props;

  const stack = useRef([]);
  const requested = useRef({});
  const processing = useRef(false);
  const timeout = useRef(null);
  const sliceSize = 3;

  const [registry, setRegistry] = useState({});

  const lookup = (address) => {
    if (!registry[address] && !requested.current[address]) {
      stack.current.push(address);
      requested.current[address] = true;
    }
    run();
  };

  const run = () => {
    clearTimeout(timeout.current);
    if (stack.current.length < sliceSize) {
      timeout.current = setTimeout(() => {
        batchProcess().then();
      }, 1000);
    } else {
      setTimeout(batchProcess, 2000);
    }
  };

  const batchProcess = async () => {
    if (processing.current === true) {
      return;
    }

    if (stack.current.length > 0) {
      const slice = stack.current.slice(0, sliceSize);
      const names = await resolveFINDAddresses(slice);

      setRegistry({
        ...registry,
        ...names,
      });

      stack.current = stack.current.slice(sliceSize);
      batchProcess().then();
    } else {
      processing.current = false;
    }
  };

  const value = { lookup, registry };

  return (
    <FindResolverContext.Provider value={value}>
      {children}
    </FindResolverContext.Provider>
  );
};

export const useFindResolver = (address) => {
  const { lookup, registry } = useContext(FindResolverContext);

  const validAddress = !!address && address.startsWith("0x")

  useEffect(() => {
    if (address && validAddress) {
      lookup(address);
    }
  }, [address]);

  if (!address) {
    return "???";
  }

  if(!validAddress){
    return address
  }

  return registry[address] || address;
};
