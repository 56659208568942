import React from "react";
import "./ColDetailAvailableOn.scss";

const ColDetailAvailableOnFlowty = ({ listingStatus }) => {
  const onDetails = listingStatus.allCurrentListings.flowty;
  return (
    <div className="available-on-wrapper-flowty p-3 my-4">
      <div className="available-on-row">
        <div className="available-on-icon-col">
          <img
            id="icon-img"
            src="/assets/img/profile/collection/nft-detail/on-flowty.jpg"
          />
          <img id="verify-img" src="/assets/img/find-verified-tick.svg" />
        </div>
        <div className="available-on-info-col">
          <div className="available-on-info-row-1">This item is available for loan on the Flowty website</div>
          <div className="available-on-flowty-info-row">
            <div className="flowty-info-title">Loan amount:&nbsp;</div>
            <div className="flowty-info-value">
              {parseInt(onDetails.amount).toFixed(2)}&nbsp;
              {onDetails.paymentVaultType.split(".")[2]}
            </div>
          </div>
          <div className="available-on-flowty-info-row">
            <div className="flowty-info-title">Repayment:&nbsp;</div>
            <div className="flowty-info-value">
              {parseInt(onDetails.repaymentAmount).toFixed(2)}&nbsp;
              {onDetails.paymentVaultType.split(".")[2]}
            </div>
          </div>
          <div className="available-on-flowty-info-row">
            <div className="flowty-info-title">Loan rate:&nbsp;</div>
            <div className="flowty-info-value">
              {onDetails.interestRate * 100}%
            </div>
          </div>
          <div className="available-on-flowty-info-row">
            <div className="flowty-info-title">APR:&nbsp;</div>
            <div className="flowty-info-value">number</div>
          </div>
          <div className="available-on-flowty-info-row">
            <div className="flowty-info-title">Term:&nbsp;</div>
            <div className="flowty-info-value">
              {onDetails.term / 86400}&nbsp;days
            </div>
          </div>
          <div className="available-on-flowty-info-row">
            <div className="flowty-info-title">Funded:&nbsp;</div>
            <div className="flowty-info-value">
              {!onDetails.funded ? "False" : "True"}
            </div>
          </div>
          <div className="available-on-info-row-2"></div>
        </div>
        <div className="available-on-price-col">
          <div className="available-on-price-row-1">
            <img src="/assets/img/profile/collection/nft-detail/chevron-right.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColDetailAvailableOnFlowty;
