import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import searchClient from "../../functions/searchClient";
import Breadcrumbs from "../Breadcrumbs";
import CustomHits from "./CustomHits";

import MarketplaceDetailPageHit from "./MarketplaceDetailPageHit";
import "../../App.css";

function MarketplaceDetailPage({ user, findUser }) {
  const [indexName, setIndexName] = useState("market");

  const search = useLocation().search;
  const name = new URLSearchParams(search).get("market[menu][collection_name]");
  const { colName, nft } = useParams();

  useEffect(()=>{
    setIndexName('market')
  }, [nft])


  return (
    <Container
      fluid={+true}
      className="pb-5"
      style={{
        backgroundColor: "var(--background-navigation)",
        minHeight: "calc(100vh - 218px)",
      }}
      id="marketplacedetailpage"
    >
      <Helmet>
        <title>
          {colName} {nft} / .find
        </title>
        <meta
          name="description"
          content="View all the information about a specific NFT like the owner, .find history, properties, and more"
        />
      </Helmet>
      <Row className="pt-0 justify-content-center">
        <InstantSearch indexName={indexName} searchClient={searchClient}>
          {colName.toLowerCase() === "find" ? (
            <Configure hitsPerPage={1} facetFilters={["nft_name:" + nft]} />
          ) : (
            <Configure hitsPerPage={1} facetFilters={["uuid:" + nft]} />
          )}
          <Container
            fluid={+true}
            className="pb-5"
            style={{
              backgroundColor: "var(--background-navigation)",
              minHeight: "calc(100vh - 218px)",
            }}
          >
            <Row className="pt-2 justify-content-center">
              <Col className="px-0 py-2" xs="12">
                 <Breadcrumbs page="mpdetail"/>
                <MarketplaceDetailPageHit
                  user={user}
                  findUser={findUser}
                  setIndexName={setIndexName}
                  indexName={indexName}
                />
                 <CustomHits />
              </Col>
            </Row>
          </Container>
        </InstantSearch>
        <Row>
          <Col>{name}</Col>
        </Row>
      </Row>
    </Container>
  );
}

export default MarketplaceDetailPage;
