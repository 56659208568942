import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

export default function DropPageInfoSection(props) {
  const {
    sectionTitle = "",
    sectionDescription = [],
    sectionArtwork,
    noGlow,
  } = props;

  const imgClass = `drop-page-info-section-img ${noGlow ? "" : "glow"}`

  return (
    <div className="drop-page-info-section-wrapper">
      <div className="drop-page-info-section-row">
        <div className="drop-page-info-section-text">
          <span className="drop-page-info-section-title-span">
            {sectionTitle}
          </span>
          {sectionDescription.map((sectionSpan, i) => {
            return (
              <p key={i} className="drop-page-info-section-desc-span">
                {sectionSpan}
              </p>
            );
          })}
        </div>
        <Image src={sectionArtwork} className={imgClass} crossOrigin='anonymous'/>
      </div>
    </div>
  );
}
