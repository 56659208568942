import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseNameLink from "../../../../../functions/parseNameLink";
import "./detailspropertycard.css";

const NameDetailsPropertyCard = ({ listingStatus }) => {
  let leaseStatus = null;
  let findName = null;
  let listingDetails = null;
  let nameItemOwner = null;
  let nameValidUntil = null;
  let nameLockedUntil = null;
  let nameRarity = null;
  let nameIndexHit = null;
  let listingTx = null;
  let itemType = null;
  let itemOwner;
  let itemCollectionDescription = "A name for your wallet and .find profile.";
  let itemOwnerAvi = null;
  let itemCurrentListings = {
    forAuction: false,
    forSale: false,
    offered: false,
  };
  let itemIsListed = null;


  // console.log(listingStatus);

  if (
    listingStatus.sellerProfile &&
    Object.keys(listingStatus.sellerProfile).length !== 0 &&
    Object.getPrototypeOf(listingStatus.sellerProfile) === Object.prototype
  ) {
    itemOwner = listingStatus.sellerProfile;
    itemOwnerAvi = listingStatus.sellerProfile.avatar;
  }

  if (listingStatus?.listingDetails) {
    listingDetails = listingStatus.listingDetails;
    if (listingStatus?.listingDetails?.leaseStatus) {
      leaseStatus = listingStatus.listingDetails.leaseStatus;
      findName = listingStatus.listingDetails.leaseStatus.name;

      new Date(listingStatus.listingDetails.leaseStatus.validUntil);

      nameValidUntil = listingStatus.listingDetails.leaseStatus.validUntil;
      nameLockedUntil = listingStatus.listingDetails.leaseStatus.lockedUntil;
    }
    if (listingStatus?.listingDetails.userReport?.profile) {
      nameItemOwner = listingStatus?.listingDetails.userReport?.profile;
    }
    if (
      listingStatus.forAuction === true ||
      listingStatus.forSale === true ||
      listingStatus.offered === true
    ) {
      itemIsListed = true;
      if (listingStatus.forAuction === true) {
        itemCurrentListings.forAuction = true;
      }
      if (listingStatus.forSale === true) {
        itemCurrentListings.forSale = true;
      }
      if (listingStatus.offered === true) {
        itemCurrentListings.offered = true;
      }
    }
  }

  if (listingStatus.indexHit) {
    nameIndexHit = listingStatus.indexHit;
    if (listingStatus.indexHit.nft_rarity) {
      nameRarity =  listingStatus.indexHit.nft_rarity[0].toUpperCase() + listingStatus.indexHit.nft_rarity.slice(1).toLowerCase() ;
    }
    if (listingStatus.indexHit.transaction_hash) {
      listingTx = listingStatus.indexHit.transaction_hash;
    }
    if (listingStatus.indexHit.nft_type) {
      itemType = listingStatus.indexHit.nft_type;
    }
  }

  // console.log(nameItemOwner);
  // console.log(listingDetails);
  // console.log(nameIndexHit);

  if (listingDetails && leaseStatus) {
    return (
      <Card id="nft-detail-details-card" className="nft-details-card">
        <Card.Body className="px-0 px-0 px-sm-3 py-0 my-0">
          <Col className="py-0 px-0" xs="12">
            <Row className="pb-4">
              {nameRarity && (
                <Col className="px-0">
                  <span className="fw-bold px-0 small">
                    Rarity:&nbsp;&nbsp;
                  </span>
                  <span className="propertiesWrap">{nameRarity}</span>
                </Col>
              )}
              {findName && (
                <Col className="px-0">
                  <span className="fw-bold px-0 small">
                    Name length:&nbsp;&nbsp;
                  </span>
                  <span className="propertiesWrap">{findName.length}</span>
                </Col>
              )}
            </Row>

            <Row className="pb-4">
              {nameRarity && (
                <Col className="px-0">
                  <span className="fw-bold px-0 small">UUID:&nbsp;&nbsp;</span>
                  <span className="propertiesWrap">
                    {listingStatus.indexHit ? listingStatus.indexHit.uuid : ""}
                  </span>
                </Col>
              )}
              {findName && (
                <Col className="px-0">
                  <span className="fw-bold px-0 small">ID:&nbsp;&nbsp;</span>
                  <span className="propertiesWrap">
                    {listingStatus.indexHit ? listingStatus.indexHit.id : ""}
                  </span>
                </Col>
              )}
            </Row>

            <Row className="pb-4">
              {nameRarity && (
                <Col className="px-0">
                  <span className="fw-bold px-0 small">
                    Valid until:&nbsp;&nbsp;
                  </span>
                  <span className="propertiesWrap">
                    {" "}
                    {new Date(nameValidUntil * 1000).toLocaleDateString()}
                  </span>
                </Col>
              )}
              {findName && (
                <Col className="px-0">
                  <span className="fw-bold px-0 small">
                    Locked until:&nbsp;&nbsp;
                  </span>
                  <span className="propertiesWrap">
                    {" "}
                    {new Date(nameLockedUntil * 1000).toLocaleDateString()}
                  </span>
                </Col>
              )}
            </Row>

            <Row className="pb-4">
                <span className="fw-bold small px-0">
                  {" "}
                  Current market listings:
                </span>
                {itemIsListed ? (
                  <Row className="propertiesWrap">
                    {itemCurrentListings.forAuction && (
                      <Link
                        className="px-0"
                        to={
                          "/mp/" +
                          listingDetails.collectionName +
                          "/" +
                          listingDetails.id
                        }
                      >
                        <span>Auction</span>
                      </Link>
                    )}
                    {itemCurrentListings.forSale && (
                      <Link
                        className="px-0"
                        to={
                          "/mp/" +
                          listingDetails.collectionName +
                          "/" +
                          listingDetails.id
                        }
                      >
                        <span>Sale</span>
                      </Link>
                    )}
                    {itemCurrentListings.offered && (
                      <Link
                        className="px-0"
                        to={
                          "/mp/" +
                          listingDetails.collectionName +
                          "/" +
                          listingDetails.id
                        }
                      >
                        <span>Offered</span>
                      </Link>
                    )}
                  </Row>
                ) : (
                  <Row>
                    <span>None</span>
                  </Row>
                )}
              </Row>

            {/* 
            {itemOwner && (
              <Row className="pb-4 align-items-center ">
                <Col xs="auto" className="px-0">
                  <span className="fw-bold px-0 small">
                    {" "}
                    Leased by:&nbsp;&nbsp;
                  </span>
                </Col>
                <Col>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      <span>{parseNameLink(itemOwner)}</span>
                    </Col>
                    {itemOwnerAvi && (
                      <Col xs="auto" className="px-0">
                        <div className="profileMenuPic mx-2">
                          <Image
                            src={itemOwnerAvi}
                            style={{ height: "40px" }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            )} */}

            {itemCollectionDescription && (
              <div className="pb-4">
                <Row>
                  <span className="fw-bold px-0 pb-0 small"> Collection:</span>
                </Row>
                <Row>
                  <Col className=" px-0">
                    <span className="propertiesWrap">.find name lease</span>
                    <br />
                    <span className="propertiesWrap">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          "https://flowscan.org/contract/" +
                          itemType.replaceAll(".Lease", "")
                        }
                      >
                        {itemType.replaceAll(".Lease", "")}
                      </a>
                    </span>
                    <br />
                    {itemCollectionDescription && (
                      <><br/>
                      <span className="propertiesWrap">
                        {itemCollectionDescription}
                      </span></>
                    )}
                  </Col>
                </Row>
              </div>
            )}

            {/* {nameValidUntil && (
              <Row className="pb-5">
                <Col className="px-0" xs="auto">
                  <span className="fw-bold px-0 small">
                    Lease valid until:&nbsp;&nbsp;
                  </span>
                </Col>
                <Col >
                <span className="propertiesWrap"> {new Date(nameValidUntil * 1000).toLocaleDateString()}</span>
                </Col>
              </Row>
            )} */}

            {/* {nameLockedUntil && (
              <Row className="pb-5">
                <Col className="px-0" xs="auto">
                  <span className="fw-bold px-0 small">
                    Locked until:&nbsp;&nbsp;
                  </span>
                </Col>
                <Col >
                <span className="propertiesWrap">   {new Date(nameLockedUntil * 1000).toLocaleDateString()}</span>
                </Col>
              </Row>
            )} */}

            {listingTx && (
              <Row className="pb-4">
                <Col className="px-0" xs="12">
                  <span className="fw-bold px-0 pb-2 small">
                    Listing transaction:{" "}
                  </span>
                </Col>

                <Col className="px-0" xs="auto">
                  <span className="propertiesWrap">
                    <a
                      target="_blank"
                      className="propertiesWrap"
                      rel="noreferrer"
                      href={process.env.REACT_APP_BLOCK_VIEWER_URL + listingTx}
                    >
                      {listingTx}
                    </a>
                  </span>
                </Col>
              </Row>
            )}

            <Row className="px-0">
              <Col className="px-0" xs="12">
                <span className="fw-bold px-0 small">
                  Allowed market actions:
                </span>
              </Col>
              <Col className="px-0 mx-0 pb-5 ">
                <span className="propertiesWrap">
                  FindMarketDirectOfferEscrow
                </span>
                <br />
                <span className="propertiesWrap"> FindMarketSale</span>
                <br />
                <span className="propertiesWrap"> FindMarketAuctionEscrow</span>
                <br />
              </Col>
            </Row>
          </Col>
        </Card.Body>
      </Card>
    );
  } else {
    return <p>No properties</p>;
  }
};

// return (
//   <>
//              <Row className="py-2 ">
//              <Col xs="12" md="3">
//                <h5>Name:</h5>
//              </Col>
//              <Col className="propertiesWrap">
//                {listingDetails.name ? (
//                   listingDetails.name
//                 ) : (
//                   <span>
//                     {listingDetails.collectionName}
//                     {listingDetails.id}
//                   </span>
//                 )}
//               </Col>
//             </Row>
//             <Row className="py-2 ">
//              <Col xs="12" md="3">
//                <h5>Owned by:</h5>
//              </Col>
//              <Col className="propertiesWrap">
//               </Col>
//             </Row>

//             <Row className="py-2 ">
//              <Col xs="12" md="3">
//                <h5>Valid until:</h5>
//              </Col>
//              <Col className="propertiesWrap">

//               </Col>
//             </Row>
//   </>
// )

{
  /* <Row className="py-3 pb-4">
<h1>
  {leaseStatus.name}
  <span style={{ color: "var(--text-primary)" }}>.find</span>
</h1>
<Col className="propertiesWrap"></Col>
</Row>

{nameItemOwner && (
<Row className="align-items-center py-3 ">
  <Col xs="12" md="3">
    <h3 className="themeColor">Owned by:</h3>
  </Col>
  <Col className="propertiesWrap">
    {parseNameLink(nameItemOwner)}
  </Col>
</Row>
)}

{nameItemOwner && (
   <Row className="py-3 align-items-center">
   <Col xs="12" md="3">
     <h3  className="themeColor">Collection:</h3>
   </Col>
   <Col className="propertiesWrap">
     <span>
       .find Names
     </span>
     <br />
     <span>
       <a
         target="_blank"
         rel="noreferrer"
         href={
           "https://flowscan.org/contract/" +
           nameItemType.replaceAll(".Lease", "")
         }
       >
         {nameItemType.replaceAll(".Lease", "")}
       </a>
     </span>
     <br />
      {nameItemCollectionDescription && <span>{nameItemCollectionDescription}</span>}
   </Col>
 </Row>
)}

<Row className="py-3 align-items-center">
{findName && (
  <Col>
    <Row className="align-items-center">
      <Col xs="12" md="6">
        <h3 className="themeColor">Name length: </h3>
      </Col>
      <Col className="propertiesWrap">{findName.length}</Col>
    </Row>
  </Col>
)}
{nameRarity && (
  <Col>
    <Row className="align-items-center">
      <Col xs="12" md="6">
        <h3 className="themeColor">Rarity: </h3>
      </Col>
      <Col className="propertiesWrap">{nameRarity}</Col>
    </Row>
  </Col>
)}
</Row>

<Row className="py-3 align-items-center">
{nameValidUntil && (
  <Col>
    <Row className="align-items-center">
      <Col xs="12" md="6">
        <h3 className="themeColor">Lease valid until: </h3>
      </Col>
      <Col className="propertiesWrap">{new Date(nameValidUntil*1000).toLocaleDateString()}</Col>
    </Row>
  </Col>
)}
{nameLockedUntil && (
  <Col>
    <Row className="align-items-center">
      <Col xs="12" md="6">
        <h3 className="themeColor">Locked until: </h3>
      </Col>
      <Col className="propertiesWrap">{new Date(nameLockedUntil*1000).toLocaleDateString()}</Col>
    </Row>
  </Col>
)}
</Row>

<Row className="py-3 align-items-center">
{nameIndexHit.uuid && (
  <Col>
    <Row className="align-items-center">
      <Col xs="12" md="6">
        <h3 className="themeColor">UUID:</h3>
      </Col>
      <Col className="propertiesWrap">{nameIndexHit.uuid}</Col>
    </Row>
  </Col>
)}
{nameIndexHit.id && (
  <Col>
    <Row className="align-items-center">
      <Col xs="12" md="6">
        <h3 className="themeColor">Listing ID:</h3>
      </Col>
      <Col className="propertiesWrap">{nameIndexHit.id}</Col>
    </Row>
  </Col>
)}
</Row>

<Row className="py-3 align-items-center">
<Col xs="12" md="3">
  <h3 className="themeColor">Allowed market actions:</h3>
</Col>
<Col className="propertiesWrap">
  FindMarketDirectOfferEscrow
  <br />
  FindMarketSale
  <br />
  FindMarketAuctionEscrow
  <br />
</Col>
</Row> */
}

// {listingTx && (
// <Row className="align-items-center py-3 ">
//   <Col xs="12" md="3">
//     <h3 className="themeColor">Listing transaction: </h3>
//   </Col>
//   <Col className="propertiesWrap">
//     <a
//       target="_blank"
//       rel="noreferrer"
//       href={process.env.REACT_APP_BLOCK_VIEWER_URL + listingTx}
//     >
//       {listingTx}
//     </a>
//   </Col>
// </Row>
// )}
{
  /*
{itemEdition && (
  <Row className="align-items-center y-2 ">
    <Col xs="12" md="3">
      <h3  className="themeColor">Edition:</h3>
    </Col>
    <Col className="propertiesWrap">
      {listingDetails.name ? (
        listingDetails.name
      ) : (
        <span>
          {listingDetails.collectionName}
          {listingDetails.id}
        </span>
      )}
    </Col>
  </Row>
)}

{itemThumbnail && (
  <Row className="py-3 align-items-center">
    <Col xs="12" md="3">
      <h3  className="themeColor">Thumbnail:</h3>
    </Col>
    <Col className="propertiesWrap">
      <a href={parseImgUrl(itemThumbnail)}>{itemThumbnail}</a>
    </Col>
  </Row>
)}

  <Row className="py-3 align-items-center">
{listingDetails.id && (
    <Col>
    <Row className="align-items-center">
    <Col xs="12" md="6">
      <h3  className="themeColor">ID:</h3>
    </Col>
    <Col className="propertiesWrap">{listingDetails.id}</Col></Row></Col>
)}
{listingDetails.uuid && (
  <Col>
    <Row className="align-items-center">
    <Col xs="12" md="6">
      <h3  className="themeColor">UUID:</h3>
    </Col>
    <Col className="propertiesWrap">{listingDetails.uuid}</Col></Row></Col>
)}
  </Row>

<Row className="py-3 align-items-center">
  <Col xs="12" md="3">
    <h3  className="themeColor">Collection:</h3>
  </Col>
  <Col className="propertiesWrap">
    <span>
      <span className="fw-bold">Title: </span>
      {listingDetails.collectionName}
    </span>
    <br />
    <span>
      <span className="fw-bold">Contract: </span>
      <a
        target="_blank"
        rel="noreferrer"
        href={
          "https://flowscan.org/contract/" +
          itemType.replaceAll(".NFT", "")
        }
      >
        {itemType.replaceAll(".NFT", "")}
      </a>
    </span>
    <br />
    <span>
      <a
        target="_blank"
        rel="noreferrer"
        href={
          "https://nft-catalog.vercel.app/catalog/" +
          listingDetails.collectionName
        }
      >
        Flow NFT Catalog
      </a>
    </span> <br/>
   {itemCollectionDescription && <span>{itemCollectionDescription}</span>}
  </Col>
</Row>


{listingDetails.rarity && (
  <Row className="py-3 align-items-center">
    <Col xs="12" md="3">
      <h3  className="themeColor">Rarity:</h3>
    </Col>
    <Col className="propertiesWrap">{listingDetails.rarity}</Col>
  </Row>
)}

{itemTags && (
  <Row className="py-3 align-items-center">
    <Col xs="12" md="3">
      <h3  className="themeColor">Tags:</h3>
    </Col>
    <Col className="propertiesWrap">
      {Object.keys(listingDetails.tags).map((properties, i) => {
        // console.log(listingDetails);
        return (
          <div key={i}>
            <span className="fw-bold">
              {properties.replaceAll("_", " ")}:{" "}
            </span>
            {listingDetails.tags[properties]}
          </div>
        );
      })}
    </Col>
  </Row>
)}

{itemScalars && (
  <Row className="py-3 align-items-center">
    <Col xs="12" md="3">
      <h3  className="themeColor">Scalars:</h3>
    </Col>
    <Col className="propertiesWrap">
      {Object.keys(itemScalars).map((properties, i) => {
        console.log(listingDetails);
        return (
          <div key={i}>
            <span className="fw-bold">
              {properties.replaceAll("_", " ")}:{" "}
            </span>

            {itemScalars[properties] * 1}
          </div>
        );
      })}
    </Col>
  </Row>
)}

{itemAllowedListings && (
  <Row className="py-3 align-items-center">
    <Col xs="12" md="3">
      <h3  className="themeColor">Allowed market actions:</h3>
    </Col>
    <Col className="propertiesWrap">
      <Row>
        {Object.keys(itemAllowedListings).map((properties, i) => {
          return <div key={i}>{properties}</div>;
        })}
      </Row>
    </Col>
  </Row>
)}

  <Row className="py-3 align-items-center">
    <Col xs="12" md="3">
      <h3  className="themeColor">Current Market Listings:</h3>
    </Col>
    <Col className="propertiesWrap">
    {itemIsListed ? (

      <Row>
        {itemCurrentListings.forAuction && <Link to={'/mp/' + listingDetails.collectionName + '/' + listingDetails.id }><span>Auction</span></Link>}
        {itemCurrentListings.forSale && <Link to={'/mp/' + listingDetails.collectionName + '/' + listingDetails.id }><span>Sale</span></Link>}
        {itemCurrentListings.offered && <Link to={'/mp/' + listingDetails.collectionName + '/' + listingDetails.id }><span>Offered</span></Link>}

      </Row>) :
      <Row><span>None</span></Row>}
    </Col>
  </Row>


{itemRoyalties && (
  <Row className="py-3 align-items-center">
    <Col xs="12" md="3">
      <h3  className="themeColor">Royalties:</h3>
    </Col>
    <Col className="propertiesWrap">
      <Row>
        {Object.keys(itemRoyalties).map((properties, i) => {
          return (
            <Col key={i} xs="auto" className="propertiesRoyalty m-1">
              <div>
                <span className="fw-bold">Royalty Name:</span>{" "}
                {itemRoyalties[properties].royaltyName}
              </div>
              <div>
                <span className="fw-bold">Paid to:</span>{" "}
                {itemRoyalties[properties].findName ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/" + itemRoyalties[properties].findName}
                  >
                    <span className="fw-bold">
                      <span className="themeColor">
                        {itemRoyalties[properties].findName}
                      </span>
                      .find
                    </span>
                  </Link>
                ) : (
                  itemRoyalties[properties].address
                )}
              </div>
              <div>
                <span className="fw-bold">Cut percentage:</span>{" "}
                {parseFloat(itemRoyalties[properties].cut).toFixed(
                  2
                ) * 100}
                %
              </div>
            </Col>
          );
        })}
      </Row>
    </Col>
  </Row>
)} */
}

export default NameDetailsPropertyCard;
