import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import TimeAgo from "react-timeago";
import { handleBuy, handleWithdrawSale } from "../../../functions/flow-interactions/transactions";
import "./namesforsale.css";
import "../profile.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../functions/useWalletType";
import { toLocalNumber } from "../../../functions/format";
import DapperSticker from "../../forms/ftstickers/DapperSticker";
import FusdFtSticker from "../../forms/ftstickers/FUSDSticker";

export function NamesForSale(props) {
  const { profile } = props;
  const [ownProfile, setOwnProfile] = useState(false);
  const walletType = useWalletType();
  const user = useUserWalletStatus();

  useEffect(() => {
    if (user && profile && user.addr === profile.address) {
      setOwnProfile(true);
    }
  }, [user, profile]);

  const leases = props.leases?.FindLeaseMarketSale?.items || [];


  const price = (item) => {
    const Sticker = item.ftAlias === "DUC" ? DapperSticker : FusdFtSticker;
    const formattedAmount = toLocalNumber(item);

    return (
      <span>
        <b>${formattedAmount}</b>
        <Sticker />
      </span>
    );
  };

  return (
    <Container
      fluid={+true}
      id="names-for-sale"
      className="px-0 fade-in-right mb-3"
    >
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <h4>Names for sale</h4>
            </Col>
          </Row>
          <table className="profile-table" id="profile-names-for-sale-table">
            <tbody>
              {leases.length > 0 ? (
                ownProfile ? (
                  leases.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr className="align-items-center mt-3 profile-names-table-names-row">
                          <td className="profile-names-table-names-col">
                            <Link to={"/mp/FIND/" + item.leaseName}>
                              <span className="fw-bold">
                                <span className="themeColor">
                                  {item.leaseName}
                                </span>
                                .find
                              </span>
                            </Link>
                          </td>

                          <td
                            className="profile-names-table-price-col"
                            style={{ color: "var(--text-secondary)" }}
                          >
                            <div>List price: {price(item)}</div>
                          </td>

                          <td className="profile-names-table-sticker-col">
                            {item.latestBid && (
                              <div className="sticker-wrapper">
                                <span className="store-sticker">
                                  Offer received
                                </span>
                              </div>
                            )}
                          </td>

                          <td className="profile-names-table-button-col">
                            <Button
                              variant="find-outline-commerce-red"
                              onClick={() => handleWithdrawSale(item.leaseName, walletType)}
                              size="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>

                        <tr className="profile-mobile-row">
                          <td className=" ">
                            {item.latestBid && (
                              <span className="store-sticker">
                                Offer received
                              </span>
                            )}
                          </td>
                          <td align="end">
                            <Button
                              variant="find-outline-commerce-red"
                              onClick={() => handleWithdrawSale(item.leaseName, walletType)}
                              size="sm"
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  leases.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr className="align-items-center mt-3 profile-names-table-names-row">
                          <td className="profile-names-table-names-col">
                            <Link to={"/mp/FIND/" + item.leaseName}>
                              <span className="fw-bold">
                                <span className="themeColor">
                                  {item.leaseName}
                                </span>
                                .find
                              </span>
                            </Link>
                          </td>

                          <td className="profile-names-table-price-col">
                            <div>List price: {price(item)}</div>
                          </td>
                          <td className="profile-names-table-sticker-col"></td>
                          <td className="profile-names-table-button-col">
                            <Button
                              variant="find-outline-commerce"
                              onClick={() =>
                                handleBuy(
                                  item.leaseName,
                                  item.amount,
                                  item.seller,
                                  walletType
                                )
                              }
                              size="sm"
                            >
                              Buy name
                            </Button>
                          </td>
                        </tr>
                        <tr className="profile-mobile-row">
                          <td className="mobile-store-sticker-col">
                            <div className="valid-for ms-auto">
                              <img
                                src="/assets/img/profile/namesowned/tick-circle.png"
                                alt="green tick"
                                width="16"
                                height="16"
                              />
                              <span style={{ color: "black" }}>
                                Valid for{" "}
                                <TimeAgo
                                  date={new Date(item.validUntil * 1000)}
                                  timestyle="twitter"
                                />
                              </span>
                            </div>
                          </td>

                          {walletType !== "Dapper Wallet" && (
                            <td className="mobile-store-buttons-col">
                              <Button
                                variant="find-outline-commerce"
                                onClick={() =>
                                  handleBuy(
                                    item.leaseName,
                                    item.amount,
                                    item.seller,
                                    walletType
                                  )
                                }
                                size="sm"
                              >
                                Buy
                              </Button>
                            </td>
                          )}
                        </tr>
                      </React.Fragment>
                    );
                  })
                )
              ) : (
                <tr className="align-items-center mt-3">
                  <td>
                    <small>No names listed for sale</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </Container>
  );
}
