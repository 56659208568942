import { FindAddress, TransferTitle, Price } from "../../components";

export const Empty = (props) => {
  console.log("Debug Empty Transfer", props);
  return null;
};

export const Mint = (props) => {
  const { transferType } = props;
  const { recipient, minters } = transferType;
  const mintersContent = (
    <p>
      Minter: &nbsp;
      {minters.map((item) => (
        <FindAddress address={item} forceAddress={false} />
      ))}
    </p>
  );
  return (
    <section>
      <header>
        <TransferTitle>
          🔨 Item was minted for <FindAddress address={recipient} />
        </TransferTitle>
        {mintersContent}
      </header>
    </section>
  );
};

export const Burn = () => {
  return (
    <section>
      <header>
        <TransferTitle>🔥 Item was burned</TransferTitle>
      </header>
    </section>
  );
};

export const Transfer = (props) => {
  const { transferType } = props;
  const { recipient, sender } = transferType;
  return (
    <section>
      <header>
        <TransferTitle>📫 Item was Transferred</TransferTitle>
        <FindAddress address={sender} prefix={"From:"} />
        <FindAddress address={recipient} prefix={"To:"} />
      </header>
    </section>
  );
};

export const Staked = (props) => {
  const { transferType } = props;
  const { platform, staker } = transferType;
  return (
    <section>
      <header>
        <TransferTitle>
          🥩 Item was Staked on <b>{platform}</b>
        </TransferTitle>
        <FindAddress address={staker} prefix={"Stacked by:"} />
      </header>
    </section>
  );
};

export const Swapped = (props) => {
  const { transferType } = props;
  const { platform } = transferType;
  const { from, to } = transferType;

  return (
    <section>
      <header>
        <TransferTitle>
          💱 Item was Swapped on <b>{platform}</b>
        </TransferTitle>
        {from && <FindAddress address={from} prefix={"From:"} />}
        {to && <FindAddress address={to} prefix={"To:"} />}
      </header>
    </section>
  );
};

export const Airdropped = (props) => {
  const { transferType } = props;
  const { platform, catcher } = transferType;
  return (
    <section>
      <header>
        <TransferTitle>
          🚀 Item was Airdropped by <b>{platform}</b>
        </TransferTitle>
        <FindAddress address={catcher} prefix={"Catcher:"} />
      </header>
    </section>
  );
};

export const MarketplaceItemPurchased = (props) => {
  const { transferType } = props;
  const { platform, buyer, price, commission } = transferType;
  return (
    <section>
      <header>
        <TransferTitle>📑 Item sold on Marketplace</TransferTitle>
        <FindAddress address={buyer} prefix={"Buyer:"} />
        {price && (
          <p>
            Price: <b>{price.amount}</b>
          </p>
        )}
        {commission && (
          <p>
            Commission: <b>{commission.amount}</b>
          </p>
        )}
        <p>
          Platform: <b>{platform}</b>
        </p>
      </header>
    </section>
  );
};

export const MarketplaceSaleCanceled = (props) => {
  const { transferType } = props;
  const { platform, price } = transferType;

  return (
    <section>
      <header>
        <TransferTitle>📥 Marketplace Sale Canceled</TransferTitle>
        <Price price={price} />
        <p>
          Platform: <b>{platform}</b>
        </p>
      </header>
    </section>
  );
};

export const Unknown = () => {
  return (
    <section>
      <header>
        <TransferTitle>Unknown transfer type</TransferTitle>
      </header>
    </section>
  );
};
