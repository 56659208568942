import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import MpDetailsOffersCard from "../../../../search/mpdetailpage/mpdetailofferscard";
import ColDetailMakeOffer from "../ColDetailMakeOffer/ColDetailMakeOffer";
import {
  getWalletType,
  NONE,
  getListingType,
} from "../../../../search/mpdetailpage/utils";
import { useWalletType } from "../../../../../functions/useWalletType";
import { WalletHints } from "../../../../search/mpdetailpage/components";

function NoOffers() {
  return (
    <Card className="latestOffersCard py-3">
      <Row className="align-items-center px-2">
        <Col id="history-desc-col" xs="5" sm="5" md="6" lg="5">
          <Row>
            <span className="secColor small">No recent offers</span>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
function Offers(props) {
  const { offers } = props;
  if (offers && offers.length > 0) {
    return offers.map((offer, i) => {
      return <MpDetailsOffersCard activity={offer} key={i} />;
    });
  }
  return <NoOffers />;
}
function RenderButtons(props) {
  const { listingStatus, show } = props;
  const { allCurrentListings } = listingStatus;

  const notForSale = !allCurrentListings.forSale.forSale;
  const notForAuction = !allCurrentListings.forAuction.forAuction;
  const canBeSold = allCurrentListings.linkedForMarket;

  const showButton = notForSale && notForAuction && canBeSold;

  if (!show || !showButton) {
    return null;
  }

  return (
    <Row className="mt-2 mb-3 justify-content-end">
      <Col sm={6}>
        <ColDetailMakeOffer listingStatus={listingStatus} />
      </Col>
    </Row>
  );

  return null;
}

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const apiUrl = process.env.REACT_APP_GRAFFLE_SEARCH_URL;

export default function ColDetailOffers(props) {
  const { listingStatus } = props;
  const [offersEventsData, setOffersEventsData] = useState();

  const walletType = getWalletType(useWalletType());
  const listingType = getListingType(listingStatus.listingDetails);
  const showHints = walletType === NONE;

  useEffect(() => {
    const getEvents = async () => {
      let data;
      let res = await axios.get(
        apiUrl +
          "search?eventType=A." +
          contractAddress +
          ".FindMarketDirectOfferEscrow.DirectOffer&id=" +
          listingStatus.itemId
      );
      data = res.data;
      setOffersEventsData(
        data.filter((event) => event.blockEventData.status === "active_offered")
      );
    };

    getEvents();
  }, [listingStatus]);

  return (
    <Accordion className="my-3">
      <Accordion.Item id="offers-accordion-header" eventKey="0">
        <Accordion.Header className=" px-3">
          <i
            style={{ fontSize: "30px" }}
            className="fa-solid fa-envelope-open-text mx-2 secColor"
          />
          <span className="fw-bold">Offers</span>{" "}
        </Accordion.Header>
        <Accordion.Body className="px-3 pb-3">
          <Offers offers={offersEventsData} />
          <RenderButtons listingStatus={listingStatus} show={!showHints} />
          <WalletHints
            show={showHints}
            listingType={listingType}
            action={"make on offer for this item"}
          />

          {/* {offersEventsData && offersEventsData.length > 0 ? (
          offersEventsData.map((offer, i) => {
            return <MpDetailsOffersCard activity={offer} key={i} />;
          })
        ) : (
          <Row
            style={{
              height: "100px",
            }}
            className=" align-items-center my-0"
          >
            <Col>
              <Card
                className=" latestOffersCard px-1 py-0"
                style={{
                  width: "100%",
                }}
              >
                <Row className="align-items-center px-2">
                  <Col id="history-desc-col" xs="5" sm="5" md="6" lg="5">
                    <Row>
                      <span className="secColor small">No recent offers</span>
                    </Row>
                    <Row></Row>
                  </Col>
                  <Col align="right" xs="3" md="3" lg="3" xl="3"></Col>
                  <Col xs="2" md="1" lg="2" xl="2" align="right"></Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )} */}
          {/* {hit && !listingStatus.forSale && !listingStatus.forAuction && (
          <Row className="mt-2 mb-3">
            <Col className="d-none d-sm-block"></Col>
            <Col>
              <MpDetailMakeOffer
                hit={hit}
                themeStatus={themeStatus}
                findUser={findUser}
                profileDetails={listingStatus.sellerProfile}
                listingDetails={listingDetails}
                userHasOffer={userHasOffer}
                isName={isName}
              />
            </Col>
          </Row>
        )} */}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
