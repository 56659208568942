import React from 'react'
import { Container, Row } from 'react-bootstrap'

const ContactHeader = () => {
  return (
      <Container>
          <Row className='pt-4'>
    <h3>Get in contact</h3></Row></Container>
  )
}

export default ContactHeader