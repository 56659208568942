import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.css";

const FooterLower = () => {
  return (
    <Container className="footer-container mt-4 mb-5 mb-md-5">
      <Row>
        <Col className="footer-text-lower d-none d-md-block">
          © 2022 .find - All rights reserved.
        </Col>
        <Col className="footer-text-lower d-block d-md-none">
          © 2022 .find <br />
        </Col>
        <Col className="d-none d-sm-block"></Col>
        <Col>
          <Row>
            <Col align="right">
              <Link className="footer-link-lower mx-2" to={"/p/terms"   }>
                Terms
              </Link>
              <Link className="footer-link-lower mx-2" to={"/p/privacy"}>
                Privacy
              </Link>
              {/* <Link className="footer-link-lower mx-2" to={"/"}>
                Cookies
              </Link> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterLower;
