import Loading from "../../loading/loading";
import LatestBidsCard from "./latestbidscard";

export default function LatestBids(props){
  const { eventsData = [] } = props
  const { isName, forAuction, listingDetails } = props;

  // TODO: Fix events here => src/functions/useGetLatestBids.js
  if(eventsData === ""){
    return null
  }

  if (forAuction && listingDetails && eventsData) {
    return (
      <>
        {eventsData.length > 0 && <span className="fw-bold">Latest bids:</span>}
        {eventsData.length > 0 &&
          eventsData.map((activity, i) => {
            return (
              <LatestBidsCard
                key={i}
                activity={activity}
                i={i}
                listingDetails={listingDetails}
                isName={isName}
              />
            );
          })}
      </>
    );
  }

  return <Loading/>
};
