import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import parseImgUrl from "../../../functions/parseImgUrl";
import parseNameLink from "../../../functions/parseNameLink";

const MpDetailOwnedBy = ({ profileDetails }) => {
  // console.log(profileDetails);

  return (
    <Row className="align-items-center p-3">
      <Col xs="2" sm="auto" className="profileMenuPic">
        {profileDetails && (
          <Image
            crossOrigin="anonymous"
            src={parseImgUrl(profileDetails.avatar, "icon")}
            fluid={+true}
          />
        )}
      </Col>
      <Col align="left">
        <Row>
          <small>Owned By</small>
        </Row>
        <Row className="find-text">{parseNameLink(profileDetails)}</Row>
      </Col>
    </Row>
  );
};

export default MpDetailOwnedBy;
