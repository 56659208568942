import { useCallback } from "react";
import { Accordion } from "react-bootstrap";
import { useRefinementList } from "react-instantsearch-hooks-web";

import GroupedFilter from "./GroupedFilter";
import SingleGroupFilter from "./SingleGroupFilter";
import { groupItems } from "../../../../../utils";

export default function Filter(props) {
  const { title, groups, attribute, hideSingle = false } = props;

  const refinementList = useRefinementList({
    attribute,
    limit: 1000,
    // showMore: true,
    // showMoreLimit: 100,
  });

  const { items, refine, toggleShowMore, isShowingMore, canToggleShowMore } =
    refinementList;

  const onClick = useCallback((e) => {
    refine(e.target.value);
  }, []);

  if (hideSingle && items.length === 1) {
    return null;
  }

  const content = groups ? (
    <GroupedFilter
      title={title}
      groups={groupItems(groups)(items)}
      onClick={onClick}
      attribute={attribute}
    />
  ) : (
    <SingleGroupFilter items={items} onClick={onClick} />
  );

  const buttonLabel = isShowingMore ? "Show Less" : "Show More";
  return (
    <Accordion.Item className="mt-1" eventKey={title}>
      <Accordion.Header>
        <span className="smallTextSpan">{title}</span>
      </Accordion.Header>
      <Accordion.Body className="px-0">
        <div className={"filter__options-list"} key={title}>
          {content}
          {canToggleShowMore && (
            <button onClick={toggleShowMore}>{buttonLabel}</button>
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}
