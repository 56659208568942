import React from "react";
import { Row } from "react-bootstrap";

export default function WalletHints(props) {
  const { show, listingType, action = "be able to buy this item" } = props;

  if (!show) {
    return null;
  }

  const anyWallet = listingType === "any" || !listingType;
  const walletType = anyWallet ? (
    "in"
  ) : (
    <span>
      into a <b>{listingType}</b> Wallet
    </span>
  );

  return (
    <Row className="pt-2">
      <p className={"fine-print"}>
        You need to be logged {walletType} to {action}
      </p>
    </Row>
  );
}
