import React, { useState } from "react";
import { GreyContainer, Column, Row, Button } from "../components";
import { FlowSticker, PriceLabel, PriceValue } from "./components";
import Progress from "../Progress";
import Increment from "../Increment";

function LeftPart(props) {
  const { label, price } = props;
  return (
    <Column className={"dp__price"}>
      <PriceLabel>{label}</PriceLabel>
      <Row className={"dp__gap"}>
        <PriceValue>{price}</PriceValue>
        <FlowSticker />
      </Row>
    </Column>
  );
}

function RightPart(props) {
  const { onMint, onChange } = props;
  return (
    <Row className={"dp__gap-2 dp__price-block--controls"}>
      <Increment initial={1} max={5} onChange={onChange} />
      <Button className="dp__small-hero" label={"Mint"} onClick={onMint} />
    </Row>
  );
}

const mintPlug = (amount) => {
  console.warn("⚠ You haven't specified 'mint' function");
  console.log(`You want to mint ${amount} item${amount > 1 ? "s" : ""}`);
};

export default function PriceBlock(props) {
  const [amount, setAmount] = useState(1);
  const { price, progress } = props;
  const { started } = props;
  const { mint = mintPlug } = props;
  const onMint = () => {
    mint(amount);
  };

  const label = amount > 1 ? `Total price for ${amount} items` : "Price";
  const priceTotal = price * amount;

  if (started) {
    return (
      <Column>
        <GreyContainer className={"dp__mb-2 dp__space-between"}>
          <LeftPart label={label} price={priceTotal} />
          <RightPart onMint={onMint} onChange={setAmount} />
        </GreyContainer>
        <Progress label={"Total minted"} progress={progress} />
      </Column>
    );
  }
  return (
    <Column>
      <GreyContainer className={"dp__mb-2"}>
        <LeftPart label={"Price"} price={price} />
      </GreyContainer>
    </Column>
  );
}
