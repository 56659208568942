import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  Image,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import EventsSwitch from "../../../functions/EventsSwitch";
import Bl0xLoading from "../../loading/bl0xloading";
import "./profilelatesttransactions.css";

function LogoGraffle() {
  const src = "/assets/img/home/graffle.webp"
  const alt = "Graffle Logo"
  return <Image fluid={true} src={src} alt={alt} className={"me-2"}/>
};

function GiftActivityLink(props){
  const { activity, itemName } = props
  const { itemType, viewItem } = activity

  if(itemType === "name" ){
    return (
      <span className="fw-bold align-items-end">
        <span className="themeColor">{itemName}</span>.find
      </span>
    )
  }

  return <span className="fw-bold">{itemName}</span>
}
function SoldActivityLink(props){
  const { eventType, activity } = props;
  const { toIsName, toName } = activity

  if(eventType !== "Sold:"){
    return null
  }

  const className = "d-none d-md-block small secondaryText ps-1"
  return(
    <span className={className}> to
      {toIsName ? (
        <Link to={"/" + toName}>
          <span className="fw-bold">
            <span className="themeColor">{toName}</span>
            .find
          </span>
        </Link>
      ) : (
        <span className="fw-bold">{toName}</span>
      )}
    </span>
  )
}
function BoughtActivityLink(props) {
  const {eventType, activity} = props;
  const {fromIsName, fromName} = activity

  if (eventType !== "Bought:") {
    return null
  }

  const className = "d-none d-md-block small secondaryText ps-1"
  return (
    <span className={className}>
      from
      {fromIsName ? (
        <Link to={"/" + fromName}>
          <span className="fw-bold">
            <span className="themeColor">{fromName}</span>
            .find
          </span>
        </Link>
      ) : (
        <span className="fw-bold">{fromName}</span>
      )}
    </span>
  )
}

function ActivityLink(props) {
  const { activity, itemName, eventType } = props;
    return (
    <td className="no-border center-cell nameCell">
      <span className="d-md-flex align-items-center">
        <GiftActivityLink evetType={eventType} activity={activity} itemName={itemName}/>
        <SoldActivityLink eventType={eventType} activity={activity}/>
        <BoughtActivityLink eventType={eventType} activity={activity}/>
      </span>
    </td>
  );
}

function compareEvenDate(a, b) {
  if (a.eventDate < b.eventDate) {
    return 1;
  }
  if (a.eventDate > b.eventDate) {
    return -1;
  }
  return 0;
}

function makeAPISearch(userAddress, pageSize){
  const graffleSearch = process.env.REACT_APP_GRAFFLE_SEARCH_URL;
  return function (type) {
    const url = `${graffleSearch}search?${type}=${userAddress}&pageSize=${pageSize}`
    return axios.get(url).then(result => result.data)
  }
}

async function getEvents(userAddress, pageSize){
  const search = makeAPISearch(userAddress, pageSize)

  const results = await Promise.all([
    search("seller"),
    search("buyer"),
    search("account"),
    search("owner"),
    search("from"),
    search("toAddress"),
    search("newOwner"),
    search("previousOwner"),
  ])

  return results.flat().sort(compareEvenDate)
};

export function ProfileLatestTransactions(props) {
  const { profileData } = props

  const [eventsData, setEventsData] = useState("");
  const [pageSize, setPageSize] = useState(3);

  const userAddress = profileData.address;
  let soldActivity = {};

  //unfortunately with graffle we cannot do wildcard search ie everything related to address
  //some more keys to add here could be newOwner, previousOwner for transfering names
  //also from and toAddress for gifting
  //no problems so far concerning loadtime but i am wary of too many requests

  //todo - style row widths and push new events from signalR (dont know how this will scale with filtering, currently the only filter for notifications is toAddress)

  useEffect(() => {
    getEvents(userAddress,pageSize).then(data => setEventsData(data))
  }, [pageSize, profileData]);

  // //console.log(eventsData);
  // //console.log(JSON.stringify(eventsData[0], 0, null));
  return (
    <Container
      fluid={+true}
      id="profile-latest-transactions"
      className="px-0 mt-3"
    >
      <Card>
        <Card.Body>
          <Row className="my-3 titleRow">
            <Col>
              <h4>Latest Activity</h4>
            </Col>
          </Row>
          {eventsData.length === 0 ? (
            <Row className="py-5">
              <Col className="flex-center">
                <Bl0xLoading />
              </Col>
            </Row>
          ) : (
            <Table id="profile-latest-transactions-table">
              <tbody className="tableContent">
                {eventsData.map((activity, i) => {
                  soldActivity = EventsSwitch(activity);

                  let userEventType = soldActivity.eventType;
                  let userItemName = soldActivity.itemName;

                  //switch userEventType depending on user as buyer/seller
                  switch (soldActivity.eventType) {
                    case "Gifted:":
                      if (soldActivity.toAddress === profileData.address) {
                        userEventType = "Received Gift:";
                        userItemName = soldActivity.fromName;
                      }
                      break;
                    case "Offered:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Made Offer:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Was Offered:";
                      }
                      break;
                    case "Sold:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Bought:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Sold:";
                      }
                      break;
                    case "Bid:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Made Bid:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Received Bid:";
                      }
                      break;

                    case "Rejected:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Offer Rejected:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Rejected Offer:";
                      }
                      break;

                    case "Offer Accepted:":
                      if (
                        activity.blockEventData.buyer === profileData.address
                      ) {
                        userEventType = "Offer Accepted:";
                      } else if (
                        activity.blockEventData.seller === profileData.address
                      ) {
                        userEventType = "Accepted Offer:";
                      }
                      break;
                  }


                  return (
                    <React.Fragment key={i}>
                      <tr
                        key={i + "row1"}
                        id={i + "row1"}
                        className=" no-border tableRow tableRow1"
                      >
                        <td className="no-border center-cell main-text iconCell">
                          {soldActivity.eventIcon}
                        </td>
                        <td className="no-border center-cell main-text eventTypeCell">
                          {userEventType}
                        </td>

                        <ActivityLink activity={soldActivity} itemName={userItemName} eventType={userEventType}/>

                        <td
                          align="end"
                          className=" d-none d-md-table-cell no-border center-cell secondaryText amountCell"
                        >
                          {soldActivity.txAmount}&nbsp;{soldActivity.txFtType}
                        </td>
                        <td
                          align="end"
                          className="no-border center-cell secondaryText d-none d-xl-block timeCell"
                        >
                          <div className="d-flex flex-column">
                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleTimeString()}{" "}
                            </small>

                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleDateString()}{" "}
                            </small>
                          </div>
                        </td>
                      </tr>

                      {activity.blockEventData.message && (
                        <tr className=" tableRow2 d-none d-md-table-row ">
                          <td className=" no-border"></td>
                          <td className=" no-border"></td>
                          <td className=" no-border messageCell">
                            {activity.blockEventData.message &&
                              activity.blockEventData.message}
                          </td>
                          <td className=" no-border"></td>
                          <td className=" no-border"></td>
                        </tr>
                      )}

                      {(userEventType === "Sold:" ||
                        userEventType === "Bought:") && (
                        <tr
                          id="profile-latest-transactions-mobile-row-2  "
                          className="d-table-row d-md-none "
                        >
                          <td className="no-border">&nbsp;</td>
                          <td className="no-border">&nbsp;</td>

                          <td
                            className="no-border center-cell mobileTimeCell secondaryText"
                            style={{ textAlign: "end" }}
                          >
                            {userEventType === "Sold:" && (
                              <span className="d-block d-md-none small">
                                {" "}
                                to{" "}
                                {soldActivity.toIsName ? (
                                  <Link to={"/" + soldActivity.toName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.toName}
                                  </span>
                                )}
                              </span>
                            )}
                            {userEventType === "Bought:" && (
                              <span className="d-block d-md-none small">
                                {" "}
                                from{" "}
                                {soldActivity.fromIsName ? (
                                  <Link to={"/" + soldActivity.fromName}>
                                    <span className="fw-bold">
                                      <span className="themeColor">
                                        {soldActivity.toName}
                                      </span>
                                      .find
                                    </span>
                                  </Link>
                                ) : (
                                  <span className="fw-bold">
                                    {soldActivity.fromName}
                                  </span>
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                      )}

                      <tr
                        id="profile-latest-transactions-mobile-row-1  "
                        className="d-table-row d-md-none "
                      >
                        <td className="no-border">&nbsp;</td>
                        <td
                          className="no-border center-cell mobileTimeCell secondaryText"
                          colSpan={1}
                        >
                          {" "}
                          <div className="d-flex flex-column">
                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleTimeString()}{" "}
                            </small>

                            <small>
                              {new Date(
                                activity.eventDate
                              ).toLocaleDateString()}{" "}
                            </small>
                          </div>
                        </td>
                        <td className="mobileAmountCell no-border center-cell secondaryText">
                          {" "}
                          {soldActivity.txAmount}&nbsp;{soldActivity.txFtType}
                        </td>
                      </tr>

                      {activity.blockEventData.message && (
                        <tr
                          id="profile-latest-transactions-mobile-row-2"
                          className="d-table-row d-md-none"
                        >
                          <td className="no-border">&nbsp;</td>
                          <td className="messageCell no-border" colSpan={3}>
                            {" "}
                            {activity.blockEventData.message}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          )}

          {pageSize !== 100 && eventsData.length > 2 && (
            <Row>
              <Col>
                <Button variant="find-text" onClick={() => setPageSize(100)}>
                  View all activity
                </Button>
              </Col>
            </Row>
          )}
          <Row className="graffleLogoRow">
            <Col align="center">
              {" "}
              <a
                className="graffleLink"
                href="https://graffle.io"
                rel="noreferrer"
                target="_blank"
              >
                <LogoGraffle/> Powered by Graffle
              </a>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
