import TimeAgo from "react-timeago";
import "./index.scss";

const LIVE = "Live";
const CLOSED = "Closed";
export default function ProjectStatus(props) {
  const { className } = props;
  const { startsAt, finished } = props;

  if (!startsAt) {
    return null;
  }

  const baseClassName = `dp__project-status ${className} `
  const closedClass = baseClassName + "dp__project-status--closed"
  const liveClass = baseClassName + "dp__project-status--live"

  if (finished) {
    return (
      <div className={closedClass}>
        {CLOSED}
      </div>
    );
  }

  const currentTimestamp = new Date().getTime();
  if (startsAt < currentTimestamp) {
    return (
      <div className={liveClass}>
        {LIVE}
      </div>
    );
  }

  return (
    <div className={baseClassName}>
      <span>Starts in:</span>
      <TimeAgo date={startsAt} />
    </div>
  );
}
