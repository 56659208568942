import { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { UseThemeStatus } from "../../../functions/themeMode";
import { MakeOfferName } from "../../forms/buying/makeoffername/makeoffername";
import { ListName } from "../../forms/selling/listname/listname";
import "./namesowned.css";
import "../profile.css";
import {
  handleExtend,
  SetMainName,
} from "../../../functions/flow-interactions/transactions";
import TransferName from "../transfername/transfername";
import TimeAgo from "react-timeago";
import {
  useUserWalletStatus,
  useWalletType,
} from "../../../functions/useWalletType";
import ListNameDapperWallet from "../../forms/selling/listname/ListNameDapperWallet";
import { getNamePriceInFlow } from "../../../functions/flow-interactions/scripts";

function Manage(props) {
  const { ownProfile, eventKey } = props;
  const onClick = useAccordionButton(eventKey);

  if (!ownProfile) {
    return null;
  }

  return (
    <Col xs="auto" md="auto" className="ms-auto">
      <div className="d-none d-lg-block">
        <Button variant="find-text" size="sm" onClick={onClick}>
          Manage
        </Button>
      </div>
      <div className="d-lg-none">
        <Button
          onClick={onClick}
          style={{
            backgroundImage: "var(--accordion-chevron)",
            width: "26px",
            height: "26px",
            backgroundColor: "var(--background)",
            borderColor: "var(--background)",
            boxShadow: "none",
          }}
        ></Button>
      </div>
    </Col>
  );
}

function NameInteraction(props) {
  const { ownProfile, showModal } = props;
  const label = ownProfile ? "Sell name" : "Make offer";
  if (ownProfile) {
    return (
      <Button variant="find-outline-commerce" onClick={showModal}>
        {label}
      </Button>
    );
  }
}

const ModalTitle = function (props) {
  const { walletType, name } = props;
  const subtitle =
    walletType === "Dapper Wallet"
      ? ".find for sale"
      : ".find for sale";

  return (
    <h3 className="fw-bold" style={{ marginBottom: "0px" }}>
      List <span style={{ color: "var(--theme-main" }}>{name}</span>
      {subtitle}
    </h3>
  );
};

function ExtendLeaseRow(data) {
  const walletType = useWalletType();

  const {lease} = data;

  let cost = 5.0;
  if (lease.name.length === 3) {
    cost = 500.0;
  } else if (lease.name.length === 4) {
    cost = 100.0;
  }

  const [price, setFlowPrice] = useState(0);

  useEffect(() => {
    getNamePriceInFlow(lease.name).then(setFlowPrice);
  }, [cost]);


  return (
    <>
      <div className="seperator100 my-3"></div>
      <Row>
        <Col>Extend Lease</Col>
      </Row>
      <Row className="align-items-center">
        <Col xs="auto">
          <div className="valid-for ms-auto">
            <img
              src="/assets/img/profile/namesowned/tick-circle.png"
              alt="green tick"
              width="16"
              height="16"
            />
            <span style={{ color: "black" }}>
              Will be valid until{" "}
              {new Date(
                Number(lease.validUntil * 1000 + 31536000000)
              ).toLocaleString()}
            </span>
          </div>
        </Col>
        <Col xs="auto" className="ms-auto fw-bold">
          {price === 0 ? "Loading..." : `${(price*1.05).toFixed(2)} FLOW`}
        </Col>
        <Col xs="auto" className="mt-3 mt-md-auto ms-auto">
          <Button
            variant="find-outline-commerce"
            onClick={() => handleExtend(lease.name, {cost, costFLOW: price}, walletType)}
          >
            Extend
          </Button>
        </Col>
      </Row>
    </>
  );
}

export function NamesOwned(props) {
  const { leases, defaultLease, profile } = props;
  const { forSale = [] } = props;

  const [show, setShow] = useState(null);
  const [ownProfile, setOwnProfile] = useState(false);
  const handleClose = (id) => setShow(id);
  const handleShow = (id) => setShow(id);
  const themeStatus = UseThemeStatus();
  const currentDate = (new Date().getTime() / 1000).toFixed(0);
  const walletType = useWalletType();
  const user = useUserWalletStatus();

  useEffect(() => {
    if (user.addr === profile.address) {
      setOwnProfile(true);
    }
  }, [user, profile]);

  const leasesForSale = useMemo(() => {
    const leases = forSale?.FindLeaseMarketSale?.items || [];
    return leases.reduce((acc, item) => {
      acc[item.leaseName] = item;
      return acc;
    }, {});
  }, [forSale]);

  return (
    <Container fluid={true} id="names-owned" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row className="mt-3">
            <Col>
              <h4>Names owned</h4>
            </Col>
          </Row>
          {leases.length !== 0 ? (
            <div id="names-owned-accordion">
              <Accordion>
                {leases.map((lease, i) => {
                  let expiresIn = lease.validUntil * 1 - currentDate * 1;
                  let loomingExpiry = false;
                  let expired = false;
                  if (expiresIn <= 0) {
                    expired = true;
                  }
                  if (expiresIn <= 2592000 && expiresIn > 0) {
                    loomingExpiry = true;
                  }

                  return (
                    <Card key={i}>
                      <Card.Header>
                        <Row className="align-items-center">
                          <Col xs="12" md="auto">
                            <span className="fw-bold">
                              <span className="themeColor">{lease.name}</span>
                              .find
                            </span>
                          </Col>

                          {/* //sticker col for expiry status */}
                          <Col>
                            {lease.name === defaultLease ? (
                              <span className="profile-main-name-sticker">
                                Main name
                              </span>
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {expired && (
                              <span className="profile-expired-sticker">
                                Expired{" "}
                                <TimeAgo
                                  date={new Date(lease.validUntil * 1000)}
                                  timestyle="twitter"
                                  suffix="none"
                                />
                              </span>
                            )}
                            {loomingExpiry && (
                              <span className="profile-expires-in-sticker">
                                Expires{" "}
                                <TimeAgo
                                  date={new Date(lease.validUntil * 1000)}
                                  timestyle="twitter"
                                  suffix="none"
                                />
                              </span>
                            )}
                          </Col>

                          <Manage
                            ownProfile={ownProfile}
                            eventKey={i + "manage"}
                          />

                          {/* NameInteraction here opens toggle for Make offer (if logged in and viewing another profile -> study and apply to make bid )  */}
                          <Col xs="auto" className="">
                            <NameInteraction
                              showModal={() => handleShow(i)}
                              ownProfile={ownProfile}
                            />
                            <Modal
                              id="list-name-modal"
                              show={show === i}
                              onHide={handleClose}
                              centered
                              data-theme={themeStatus}
                            >
                              <Modal.Header
                                className="noBorder"
                                closeButton
                                closeLabel="close window"
                              >
                                <Modal.Title className="text-center w-100 noBorder">
                                  <ModalTitle
                                    walletType={walletType}
                                    name={lease.name}
                                  />
                                </Modal.Title>
                              </Modal.Header>
                              <ModalBody>
                                {walletType === "Dapper Wallet" ? (
                                  <ListNameDapperWallet
                                    lease={lease}
                                    listingDetails={leasesForSale[lease.name]}
                                    closeModal={() => handleShow(false)}
                                  />
                                ) : (
                                  <ListName lease={lease} />
                                )}
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body>
                          <div className="collapse show" id="collapseExample">
                            {ownProfile ? (
                              <ListName lease={lease} profile={profile} />
                            ) : (
                              <MakeOfferName lease={lease} />
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                      <Accordion.Collapse eventKey={i + "manage"}>
                        <Card.Body>
                          <div className="collapse show" id="collapseExample">
                            {ownProfile && (
                              <div className="commerceinfo p-3">
                                <Row>
                                  <Col>
                                    <div className="fw-bold">
                                      Manage{" "}
                                      <span className="themeColor">
                                        {lease.name}
                                      </span>
                                      .find
                                    </div>
                                    <small>
                                      Here you can extend your lease, make this
                                      your default name or transfer your name to
                                      another .find profile
                                    </small>
                                  </Col>
                                </Row>

                                <ExtendLeaseRow lease={lease} />

                                <div className="seperator100 my-3"></div>
                                <Row className="align-items-center">
                                  <Col>
                                    Make{" "}
                                    <b>
                                      <span className="themeColor">
                                        {lease.name}
                                      </span>
                                      .find
                                    </b>{" "}
                                    your main name
                                  </Col>
                                  <Col xs="auto" className="ms-auto">
                                    <Button
                                      variant="find-outline-commerce"
                                      onClick={() => SetMainName(lease.name)}
                                    >
                                      Default
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="seperator100 my-3"></div>
                                <TransferName lease={lease} />
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>
            </div>
          ) : (
            <div>
              <small>No names, sorry.</small>
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
