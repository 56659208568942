import { sortBy } from "../../../../../utils";
import RefineItem from "../RefineItem";

export default function GroupedFilter(props) {
  const { title, groups, onClick } = props;

  const groupNames = Object.keys(groups);
  const sortedGroups = groupNames.sort(
    sortBy((item) => {
      // we can reverse sorting by subtracting from some big number
      return parseInt(item.slice(0, 1));
    })
  );

  return sortedGroups.map((groupName) => {
    const items = groups[groupName];
    if (items.length === 0) {
      return null;
    }
    const sorted = items.sort(sortBy("label"));
    const index = parseInt(groupName[0]);
    const subtitle =
      isNaN(index) || index === undefined ? groupName : groupName.slice(2);

    return (
      <div className={"filter__options-list"}>
        <h4>{subtitle}</h4>
        {sorted.map((item) => {
          return <RefineItem item={item} onClick={onClick} />;
        })}
      </div>
    );
  });
}
