import React from "react";
import { Image } from "react-bootstrap";
import "./ftstickers.css";

export default function FusdFtSticker(props){
  const { noBg } = props;
  return (
    <div className="sticker-box">
      <span
        className={
          noBg === "true"
            ? "sticker-span-no-bg"
            : noBg === "tertiary"
            ? "sticker-span-tertiary"
            : "sticker-span"
        }
      >
        <div>
          <Image
            className="sticker-image"
            src="/assets/img/stickerlogos/fusd-sticker-logo.jpeg"
          />
        </div>
        <span className="ft-span secColor">FUSD</span>
      </span>
    </div>
  );
};

