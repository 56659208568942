import React, { useState, useEffect } from "react";
import { scripts } from "@findonflow/find-flow-contracts-1.0";
import { Script } from "../../functions/script";
import * as fcl from "@onflow/fcl";
import "./navhead.css";
import { Container, Navbar } from "react-bootstrap";
import { useStateChanged } from "../../functions/DisabledState";
import { darkMode, lightMode, UseThemeStatus } from "../../functions/themeMode";
import { useLocation, useNavigate } from "react-router";

import NavheadLogo from "./navheadlogo";
import NavheadMobileTop from "./navheadmobiletop";
import NavheadLinks from "./navheadlinks";
import NavheadDropdown from "./navheaddropdown";
import NavheadMainTop from "./navheadmaintop";
import NavheadBanner from "./NavheadBanner";
import useIsScrolled from "../../functions/useIsScrolled";

function NavHead({ user, findUser, lostAndFoundItems }) {
  const [profile, setProfile] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [profileStatus, setProfileStatus] = useState({ profile: "start" });
  let currentTheme = UseThemeStatus();
  // let themeButtonState = false;
  const location = useLocation();
  const navigate = useNavigate();
  const scrolled = useIsScrolled()
  // console.log(location);

  const switchTheme = () => {
    if (currentTheme === "light") {
      darkMode();
    } else {
      lightMode();
    }
  };

  // console.log(lostAndFoundItems)

  useEffect(() => {
    if (user.loggedIn) {
      async function getProfile(addr) {
        const profile = await Script(scripts.getProfile, { user: addr });
        setProfile(profile);
      }
      try {
        getProfile(user.addr);
      } catch (error) {
        //console.log(error);
      }
    }
    // eslint-disable-next-line
  }, [user, useStateChanged()]);

  function disableScroll() {
    document.body.classList.add("stop-scrolling");
  }
  function enableScroll() {
    document.body.classList.remove("stop-scrolling");
  }

  useEffect(() => {
    expanded ? disableScroll() : enableScroll();
  }, [expanded]);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  useEffect(() => {
    if (profile) {
      setProfileStatus({ profile: "found" });
    }
    if (!profile) {
      setProfileStatus({ profile: "not_found" });
    }
  }, [profile]);

  //useEffect to redirect to updateprofilepage if profile needs fixing
  useEffect(() => {
    //&& findUser.needsUpdate
    if (
      findUser &&
      findUser.needsUpdate &&
      location.pathname !== "/me/updateprofile"
    )
      navigate("/me/updateprofile");
    if (
      findUser &&
      findUser !== "first_init" &&
      !findUser.profile &&
      location.pathname !== "/me"
    ) {
      // console.log(findUser);
      navigate("/me");
    }
  }, [findUser, useStateChanged, location]);

  return (
    <Container id="navbar" fluid={+true} className="g-0">
      <Navbar
        collapseOnSelect={true}
        expanded={expanded}
        bg={currentTheme}
        variant={currentTheme}
        expand="lg"
        className={scrolled ? "px-3 py-2 navbar-custom navbar-custom-scrolled" : "px-3 py-2 navbar-custom"}
        fixed="top"
      >
        <Container fluid>
          <NavheadLogo expanded={expanded} setExpanded={setExpanded} />
          {!user.loggedIn && <NavheadLinks />}
          <NavheadMobileTop
            profile={profile}
            user={user}
            expanded={expanded}
            setExpanded={setExpanded}
          />
          <NavheadMainTop
            user={user}
            expanded={expanded}
            setExpanded={setExpanded}
            switchTheme={switchTheme}
            profileStatus={profileStatus}
            profile={profile}
            lostAndFoundItems={lostAndFoundItems}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="navbar-collapse justify-content-end"
          >
            <NavheadDropdown
              setExpanded={setExpanded}
              user={user}
              profileStatus={profileStatus}
              switchTheme={switchTheme}
              profile={profile}
              lostAndFoundItems={lostAndFoundItems}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
     {/* {!user.loggedIn && <NavheadBanner />} */}
    </Container>
  );
}
export default NavHead;
