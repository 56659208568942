import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./LiveFeedWrapper.scss";
import { UseThemeStatus } from "../../functions/themeMode";
import ThoughtBoxNFTSelectOthers from "./ThoughtBoxNFTSelectOthers";
import ThoughtBoxNFTSelectOwn from "./ThoughtBoxNFTSelectOwn";
import ThoughtBoxUploadHosted from "./ThoughtBoxUploadHosted";
import ThoughtBoxUploadIPFS from "./ThoughtBoxUploadIPFS";

const ThoughtBoxMediaModal = ({
  showMediaInput,
  setShowMediaInput,
  user,
  thoughtInput,
  setThoughtInput,
}) => {
  const [currentStage, setCurrentStage] = useState(1);
  const [uploadOwnNFT, setUploadOwnNFT] = useState(false);
  const [uploadOthersNFT, setUploadOthersNFT] = useState(false);
  const [uploadMediaHosted, setUploadMediaHosted] = useState(false);
  const [uploadMediaIPFS, setUploadMediaIPFS] = useState(false);

  return (
    <Modal
      show={showMediaInput}
      onHide={() => setShowMediaInput(false)}
      centered
      data-theme={UseThemeStatus()}
    >
      <Modal.Header
        style={{ border: "none" }}
        closeButton
        closeLabel="close window"
      >
        <Modal.Title className="text-center w-100">Media</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStage === 1 && (
          <>
            <div
              onClick={() => {
                setUploadOwnNFT(true);
                setCurrentStage(2);
              }}
            >
              Choose NFT from your collection
            </div>
            <div
              onClick={() => {
                setUploadOthersNFT(true);
                setCurrentStage(2);
              }}
            >
              Choose NFT from someone else's collection
            </div>
            <div
              onClick={() => {
                setUploadMediaHosted(true);
                setCurrentStage(2);
              }}
            >
              Upload already hosted media
            </div>
            <div
              onClick={() => {
                setUploadMediaIPFS(true);
                setCurrentStage(2);
              }}
            >
              Upload media to IPFS
            </div>
          </>
        )}

        {currentStage === 2 && (
          <>
            {uploadOwnNFT && <ThoughtBoxNFTSelectOwn user={user} />}
            {uploadOthersNFT && <ThoughtBoxNFTSelectOthers />}
            {uploadMediaHosted && (
              <ThoughtBoxUploadHosted
                thoughtInput={thoughtInput}
                setThoughtInput={setThoughtInput}
              />
            )}
            {uploadMediaIPFS && <ThoughtBoxUploadIPFS />}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ThoughtBoxMediaModal;
