import {
  Card,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import RelatedAccounts from "./RelatedAccounts";

export function ProfileDetails({ profileData, allProfileData }) {
  function runCopy(copyData) {
    copy(copyData);
    toast(<span align="center">{copyData} copied to clipboard</span>, {
      duration: 2000,
      style: {},
    });
    ////console.log(copyData)
  }

  let walletAddress = profileData.address;

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      <span style={{ fontSize: "16px" }}>{walletAddress}</span>
    </Tooltip>
  );

  return (
    <Container fluid id="profile-details" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row className="my-3">
            <Col>
              <h4>Profile Details</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="mb-3">
                <Col xs="5" lg="4">
                  <span>Wallet Address:</span>
                </Col>
                <Col xs="6" lg="8" className="d-flex  align-items-center">
                  <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <span className="truncate secColor">{walletAddress}</span>
                  </OverlayTrigger>
                  <img
                    style={{
                      width: "15px",
                      marginLeft: "10px",
                    }}
                    src={"/assets/img/NetworkLogos/flow-network.svg"}
                  />
                  <div
                    className="p-0"
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      color: "var(--text-secondary)",
                      marginLeft: "10px",
                    }}
                    onClick={() => runCopy(walletAddress)}
                  >
                    <i className="copyicon fa fa-copy"></i>
                  </div>
                </Col>
              </Row>
              {profileData.wallets.map((wallet, i) => {
                if (wallet.name !== "DUC" && wallet.name !== "FUT") {
                  return (
                    <Row className="mb-3" key={i}>
                      <Col xs="5" lg="4">
                        <span>{wallet.name}:</span>
                      </Col>
                      <Col xs="6" lg="8">
                        <span style={{ color: "var(--text-secondary)" }}>
                          {parseFloat(wallet.balance).toFixed(2)}
                        </span>
                      </Col>
                    </Row>
                  );
                }
              })}

              <RelatedAccounts
                allProfileData={allProfileData}
                runCopy={runCopy}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
