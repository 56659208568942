import React from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { useWalletType } from "../../../functions/useWalletType";
import "./mpsidebar.css";



const MpSideBarWallet = ({ showAll, setShowAll, mobile }) => {
  const wallet = useWalletType() === "Dapper Wallet" ? "Dapper" : "non-Dapper"

  return (
    <Row>
      <Col>
        <div id={mobile ? 'mp-side-bar-float-mobile' : 'mp-side-bar-float'}>
          <Image
          className="mx-2"
            style={{    height: '25px',
              width: '25px' }}
            src={wallet === "Dapper" ? "/assets/img/walletProviders/dapper-logo-device-transparent.png" : "/assets/img/walletProviders/flow-logo-device-transparent.png"}
          />
          <span className="include-float-span" style={{}}>
            {" "}
            Only show {wallet}?{" "}
          </span>
          <Form className="float-switch">
            <Form.Check
            className="form-switch-toggle"
              onChange={() => setShowAll(!showAll)}
              checked={!showAll}
              type="switch"
              id="custom-switch"
            />
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default MpSideBarWallet;
