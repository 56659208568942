import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./ColDetailItemBoughtModal.css";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import * as fcl from "@onflow/fcl";
import { useUserProfile } from "../../../../../functions/useUserProfile";

const ColDetailItemBoughtModal = ({ listingStatus }) => {
  const [userLink, setUserLink] = useState("/");
  const [shareLink, setShareLink] = useState("/");
  const { colName } = useParams();
  const userProfile = useUserProfile();

  let shareName;
  if (userProfile.profile.findName) {
    shareName = userProfile.profile.findName;
  } else {
    shareName = userProfile.profile.address;
  }

  useEffect(() => {
    if (listingStatus.itemId) {
      setUserLink("/me/collection/main/" + colName + "/" + listingStatus.itemId);
      setShareLink(
        "https://find.xyz/" +
          shareName +
          "/collection/main/" +
          colName +
          "/" +
          listingStatus.itemId
      );
    }
  }, [listingStatus.itemId && listingStatus]);

  let title = `I Just purchased ${listingStatus.itemName}. Come check it out on my profile now!`;

  return (
    <Container id="mp-bought-success-modal" className="p-3">
      <Modal.Header className="successHeader justify-content-center px-5">
        <h3>Woo hoo! Your purchase is complete!</h3>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p className="modalText px-5">
              Your purchase of{" "}
              <span className="fw-bold">{listingStatus.itemName}</span> is
              complete - check it out on your profile now!
            </p>
          </Col>
        </Row>
        <Link to={userLink}>
          <Image
          crossOrigin="anonymous"
            className="modal-img"
            fluid={+true}
            src={parseImgUrl(listingStatus.itemThumbnail, 'thumbnail')}
          />
        </Link>

        <Row className="align-items-center justify-content-center">
          <Col xs="12" md="7">
            <Link to={userLink}>
              <Button variant="find-nochange-dark">View NFT</Button>
            </Link>
          </Col>
        </Row>
        <div className="seperator100 my-4"></div>
        <Row className="justify-content-center">
          <p className="modalText">Share your new purchase</p>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto" className="my-auto modalFooterSocials">
            <TwitterShareButton
              className="m-1"
              title={title}
              url={
                shareLink +
                "  " +
                parseImgUrl(listingStatus.itemThumbnail, "", "", true)
              }
              hashtags={["foundonflow", "onFlow"]}
            >
              <i className="fab fa-twitter m-1 shareIcons" />
            </TwitterShareButton>
            <FacebookShareButton className="m-1" quote={title} url={shareLink}>
              <i className="fab fa-facebook m-1 shareIcons" />
            </FacebookShareButton>
            <LinkedinShareButton className="m-1" url={shareLink}>
              <i className="fab fa-linkedin m-1 shareIcons" />
            </LinkedinShareButton>
          </Col>
        </Row>
      </Modal.Body>
    </Container>
  );
};

export default ColDetailItemBoughtModal;
