import React from "react";
import { Col, Row } from "react-bootstrap";
import { useWalletType } from "../../../../../functions/useWalletType";
import ColDetailOwnItemSaleOptions from "./ColDetailOwnItemSaleOptions";
import ColDetailOwnItemSendItem from "./ColDetailOwnItemSendItem";

const haveItems = value => {
  return value && Object.keys(value).length > 0
}

const listingLogic = (walletType, listingStatus) => {
  const { allowedListingActions, dapperAllowedListingActions } = listingStatus
  if(walletType === "Dapper Wallet"){
    return haveItems(dapperAllowedListingActions)
  }
  return haveItems(allowedListingActions)
}

const ColDetailOwnItemCatalogOptions = ({
  listingStatus,
  showSalesModal,
  setShowSalesModal,
}) => {
  const walletType = useWalletType();

  const canBeListed = listingLogic(walletType, listingStatus)
  const isListed = listingStatus?.allCurrentListings?.forSale?.forSale

  return (
    <div id="col-detail-own-item-options">
      <Row className="mb-3">
        {canBeListed &&
            <>
              {!isListed && <Col>
                <ColDetailOwnItemSaleOptions
                  showSalesModal={showSalesModal}
                  setShowSalesModal={setShowSalesModal}
                  listingStatus={listingStatus}
                />
              </Col>}
              <Col>
                <ColDetailOwnItemSendItem listingStatus={listingStatus} />
              </Col>
            </>
          }

        {/* {listingStatus.dapperAllowedListingActions &&
          Object.keys(listingStatus.dapperAllowedListingActions).length > 0 && (
            <>
              <Col>
                <ColDetailOwnItemSaleOptions
                  showSalesModal={showSalesModal}
                  setShowSalesModal={setShowSalesModal}
                  listingStatus={listingStatus}
                />
              </Col>
              <Col>
                <ColDetailOwnItemSendItem listingStatus={listingStatus} />
              </Col>
            </>
          )} */}
      </Row>
      {/* <Row>
        <Col align='center'>
          <ColDetailBurnItem listingStatus={listingStatus} />
        </Col>
      </Row> */}
    </div>
  );
};

export default ColDetailOwnItemCatalogOptions;
