import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import parseImgUrl from "../../../../functions/parseImgUrl";
import {
  handleAcceptNFTOffer,
  handleRejectNFTOffer,
} from "../../../../functions/flow-interactions/transactions";
import ReviewOwnAuctionNftStore from "../../../forms/selling/listnft/reviewownauctionnftstore";
import "../../userstore.css";

const StoreBidsOnNfts = ({ bids, filterTerm }) => {
  const [filteredBiddedSaleItems, setFilteredBiddedSaleItems] = useState();
  const [filteredBiddedAuctionItems, setFilteredBiddedAuctionItems] =
    useState();

  // //console.log(bids);

  useEffect(() => {
    if (bids.FindMarketAuctionEscrow) {
      setFilteredBiddedAuctionItems(
        bids.FindMarketAuctionEscrow.items
          .filter((bid) => bid.bidder)
          .filter(
            (bid) =>
              bid.nft.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
              bid.nft.collectionName
                .toLowerCase()
                .includes(filterTerm.toLowerCase())
          )
      );
    } else {
      setFilteredBiddedAuctionItems();
    }
  }, [bids, filterTerm]);

  useEffect(() => {
    if (bids.FindMarketDirectOfferEscrow) {
      setFilteredBiddedSaleItems(
        bids.FindMarketDirectOfferEscrow.items.filter(
          (bid) =>
            bid.nft.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
            bid.nft.collectionName
              .toLowerCase()
              .includes(filterTerm.toLowerCase())
        )
      );
    } else {
      setFilteredBiddedSaleItems();
    }
  }, [bids, filterTerm]);

  // let filteredBiddedSaleItems = bids.FindMarketDirectOfferEscrow.items;

  // let filteredBiddedAuctionItems = bids.FindMarketAuctionEscrow.items;

  // const findLogo = <Image fluid={+true} src="/assets/img/store/fnamestore.png" />;

  // //console.log(filteredBiddedSaleItems);
  // //console.log(filteredBiddedAuctionItems);

  function CustomToggle({ children, eventKey, lease }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <>
        <div className="d-none d-lg-block">
          <Button
            variant="find-outline-commerce"
            size="sm"
            onClick={decoratedOnClick}
          >
            {children}
          </Button>
        </div>
        <div className="d-lg-none">
          <Button
            onClick={decoratedOnClick}
            style={{
              backgroundImage: "var(--accordion-chevron)",
              width: "26px",
              height: "26px",
              backgroundColor: "var(--background)",
              borderColor: "var(--background)",
              boxShadow: "none",
            }}
          ></Button>
        </div>
      </>
    );
  }

  return (
    <Container fluid={+true} id="" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row>
            <Col align="left">
              <h4>Bids/offers on your NFTs</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <p>
              <b>Offers:</b>
            </p>
          </Row>
          <table className="store-table" id="store-offered-nfts-table">
            <tbody>
              {filteredBiddedSaleItems && filteredBiddedSaleItems.length > 0 ? (
                filteredBiddedSaleItems.map((bid, i) => {
                  let imgUrl = "";
                  let nftName;
                  bid.nft.name
                    ? (nftName = bid.nft.name)
                    : (nftName = bid.nft.collectionName + " #" + bid.nft.id);
                  if (bid.nft.thumbnail) {
                    if (bid.nft.thumbnail.includes("ipfs://")) {
                      imgUrl = bid.nft.thumbnail.replace(
                        "ipfs://",
                        "https://find.mypinata.cloud/ipfs/"
                      );
                    } else {
                      imgUrl = bid.nft.thumbnail;
                    }
                  }
                  const NftThumb = (
                    <Image
                    crossOrigin="anonymous"
                      className="store-thumb-image"
                      fluid={+true}
                      src={parseImgUrl(imgUrl, 'icon')}
                      />
                  );

                  // console.log(bid);
                  return (
                    <React.Fragment key={i + "store-offered-names-row"}>
                      <tr
                        id={i + "store-offered-names-row"}
                        className="store-row"
                      >
                        <td className="store-thumb-col">{NftThumb}</td>
                        <td className="store-name-col">
                          <Link
                            to={
                              "/mp/" +
                              bid.nft.collectionName +
                              "/" +
                              bid.listingId
                            }
                          >
                            <span className="fw-bold">
                              <span>{nftName}</span>
                            </span>
                          </Link>
                        </td>
                        <td className="store-sticker-col">
                          <span className="store-sticker">
                            From:{" "}
                            {bid.bidderName
                              ? bid.bidderName + ".find"
                              : bid.bidder}
                          </span>
                        </td>
                        <td className="store-price-col">
                          <div className="d-none d-lg-block">
                            {" "}
                            Offer:{" "}
                            <b>
                              {" "}
                              {parseFloat(bid.amount).toFixed(2)} {bid.ftAlias}
                            </b>
                          </div>
                          <div className="d-block d-lg-none">
                            <b>
                              {" "}
                              {parseFloat(bid.amount).toFixed(2)} {bid.ftAlias}
                            </b>
                          </div>
                        </td>
                        <td className="store-time-left-col"></td>

                        <td className="store-buttons-col">
                          <Button
                            onClick={() => handleRejectNFTOffer(bid.listingId)}
                            variant="find-outline-commerce-red"
                          >
                            Reject
                          </Button>

                          <Button
                            onClick={() => handleAcceptNFTOffer(bid.listingId, bid.amount)}
                            variant="find-outline-commerce"
                          >
                            Accept
                          </Button>
                        </td>
                      </tr>
                      <tr className="mobile-row">
                        <td className="mobile-store-sticker-col">
                          {bid.latestBid && (
                            <span className="store-sticker">Offer </span>
                          )}
                        </td>
                        <td className="mobile-store-time-left-col"></td>
                        <td align="end">
                          <Button
                            onClick={() => handleRejectNFTOffer(bid.listingId)}
                            variant="find-outline-commerce-red"
                          >
                            Reject
                          </Button>

                          <Button
                            onClick={() => handleAcceptNFTOffer(bid.listingId, bid.amount)}
                            variant="find-outline-commerce"
                          >
                            Accept
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>

                    // <Row key={i} className="my-4 align-items-center">
                    //          <Col className="col-1" xs="2" md="1">
                    //     {NftThumb}
                    //   </Col>
                    //   <Col xs="10" md="auto">
                    //     <b>{bid.nft.name}</b>
                    //   </Col>
                    //   <Col>
                    //     Offer:{" "}
                    //     <b>
                    //       {parseFloat(bid.amount).toFixed(2)} {bid.ftAlias}
                    //     </b>
                    //   </Col>
                    //   <Col xs='12' md='auto'>
                    //     From:&nbsp;<b>{bid.bidder}</b>
                    //   </Col>
                    //   <Col xs="auto" className="ms-auto">
                    //     <Button
                    //       onClick={() => handleRejectNFTOffer(bid.nft.id)}
                    //       variant="find-outline-commerce-red"
                    //     >
                    //       Reject
                    //     </Button>
                    //   </Col>
                    //   <Col xs="auto">
                    //     <Button
                    //       onClick={() => handleAcceptNFTOffer(bid.nft.id)}
                    //       variant="find-outline-commerce"
                    //     >
                    //       Accept
                    //     </Button>
                    //   </Col>
                    // </Row>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <small>No current offers on your NFTs</small>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="seperator100 my-4"></div>

          <Row>
            <p>
              <b>For auction:</b>
            </p>
          </Row>
          <Accordion>
            <table className="store-table" id="store-bidded-nfts-table">
              <tbody>
                {filteredBiddedAuctionItems &&
                filteredBiddedAuctionItems.length > 0 ? (
                  filteredBiddedAuctionItems.map((bid, i) => {
                    // console.log(bid)
                    let nftName;
                    bid.nft.name
                      ? (nftName = bid.nft.name)
                      : (nftName = bid.nft.collectionName + " #" + bid.nft.id);

                    let imgUrl = "";
                    if (bid.nft.thumbnail) {
                      if (bid.nft.thumbnail.includes("ipfs://")) {
                        imgUrl = bid.nft.thumbnail.replace(
                          "ipfs://",
                          "https://find.mypinata.cloud/ipfs/"
                        );
                      } else {
                        imgUrl = bid.nft.thumbnail;
                      }
                    }
                    let ended;
                    if (bid.auction.auctionEndsAt < bid.auction.timestamp) {
                      ended = true;
                    } else {
                      ended = false;
                    }

                    const NftThumb = (
                      <Image
                      crossOrigin="anonymous"
                        className="store-thumb-image"
                        fluid={+true}
                        src={parseImgUrl(imgUrl, 'icon')}
                      />
                    );

                    return (
                      <React.Fragment key={i + "store-bidded-nfts-row"}>
                        <tr
                          id={i + "store-bidded-nfts-row"}
                          className="store-row"
                        >
                          <td className="store-thumb-col">{NftThumb}</td>
                          <td className="store-name-col">
                            <Link
                              to={
                                "/mp/" +
                                bid.nft.collectionName +
                                "/" +
                                bid.listingId
                              }
                            >
                              <span className="fw-bold">
                                <span>{nftName}</span>
                              </span>
                            </Link>
                          </td>
                          <td className="store-sticker-col">
                            <span className="store-sticker">
                              Bidder:{" "}
                              {bid.bidderName
                                ? bid.bidderName + ".find"
                                : bid.bidder}
                            </span>
                          </td>
                          <td className="store-price-col">
                            <div className="d-none d-lg-block">
                              {/* //       {bid.latestBid && ( */}
                              {/* //         <>
            //           Bid:&nbsp;
            //           <b>{parseFloat(bid.latestBid).toFixed(2)} FUSD</b>
            //         </>
            //       )} */}
                              Bid:{" "}
                              <b>
                                {parseFloat(bid.amount).toFixed(2)}{" "}
                                {bid.ftAlias}
                              </b>
                            </div>
                            <div className="d-block d-lg-none">
                              <b>
                                {parseFloat(bid.amount).toFixed(2)}{" "}
                                {bid.ftAlias}
                              </b>{" "}
                            </div>
                          </td>
                          <td className="store-time-left-col">
                            <span className="store-sticker">
                              {!ended ? "Ends: " : "Ended: "}
                              <ReactTimeago
                                date={
                                  new Date(bid.auction.auctionEndsAt * 1000)
                                }
                                timestyle="twitter"
                              />
                            </span>
                          </td>

                          <td className="store-buttons-col">
                            <CustomToggle eventKey={i}>
                              {" "}
                              View Auction
                            </CustomToggle>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-none d-md-table-cell" colSpan={6}>
                            <Accordion.Collapse eventKey={i}>
                              <Card.Body>
                                <div
                                  className="collapse show"
                                  id="collapseExample"
                                >
                                  <ReviewOwnAuctionNftStore
                                    ended={ended}
                                    filteredBid={bid}
                                  />{" "}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </td>
                        </tr>

                        <tr className="mobile-row">
                          <td className="mobile-store-sticker-col">
                            {bid.bidder && (
                              <span className="store-sticker">Bids </span>
                            )}
                          </td>
                          <td className="mobile-store-time-left-col">
                            <span className="store-sticker">
                              Ends:{" "}
                              <ReactTimeago
                                date={
                                  new Date(bid.auction.auctionEndsAt * 1000)
                                }
                                timestyle="twitter"
                              />
                            </span>
                          </td>
                          <td align="end">
                            <CustomToggle eventKey={i}>
                              {" "}
                              View Auction
                            </CustomToggle>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-table-cell d-md-none" colSpan={3}>
                            <Accordion.Collapse eventKey={i}>
                              <Card.Body>
                                <div
                                  className="collapse show"
                                  id="collapseExample"
                                >
                                  <ReviewOwnAuctionNftStore
                                    ended={ended}
                                    filteredBid={bid}
                                  />{" "}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </td>
                        </tr>
                      </React.Fragment>

                      // <React.Fragment key={i}>
                      //   <Row className="my-4 align-items-center">
                      //     <Col className="col-1" xs="2" md="1">
                      //       <Image className="store-thumb" fluid={+true} src={imgUrl} />
                      //     </Col>
                      //     <Col xs="10" md="auto">
                      //       <b>{bid.nft.name}</b>
                      //     </Col>
                      //     <Col>
                      //       {" "}
                      //       {bid.bidder && (
                      //         <>
                      //           Start:&nbsp;
                      //           <b>
                      //             {parseFloat(bid.auction.startPrice).toFixed(2)}{" "}
                      //             {bid.ftAlias}
                      //           </b>
                      //         </>
                      //       )}
                      //     </Col>
                      //     <Col>
                      //       {bid.bidder && (
                      //         <>
                      //           Bid:&nbsp;
                      //           <b>
                      //             {parseFloat(bid.amount).toFixed(2)} {bid.ftAlias}
                      //           </b>
                      //         </>
                      //       )}
                      //     </Col>
                      //     <Col>
                      //       {bid.auctionEnds > bid.currentTime
                      //         ? "Ends: "
                      //         : "Ended: "}
                      //       <b>
                      //         <ReactTimeago
                      //           date={new Date(bid.auction.auctionEndsAt * 1000)}
                      //           timestyle="twitter"
                      //         />{" "}
                      //       </b>
                      //     </Col>
                      //     <Col align="right">
                      //       <CustomToggle eventKey={i}> View Auction</CustomToggle>
                      //     </Col>
                      //   </Row>
                      //   <Accordion.Collapse eventKey={i}>
                      //     <Card.Body>
                      //       <div className="collapse show" id="collapseExample">
                      //         <ReviewOwnAuctionNftStore filteredBid={bid} />
                      //       </div>
                      //     </Card.Body>
                      //   </Accordion.Collapse>
                      // </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td>
                      <small>No current bids on your NFTs</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StoreBidsOnNfts;
