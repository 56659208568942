import { Col, Container, Row } from "react-bootstrap";
import { WhoIntegrates } from "../components/home/whointegrates/whointegrates";
import Breadcrumbs from "../components/Breadcrumbs";
import AlphaTestingHeading from "../components/alphatesting/alphatestingheading/alphatestingheading";
import AlphaTestingInfo from "../components/alphatesting/alphatestingheading/alphatestinginfo/alphatestinginfo";
import AlphaTestingSideNavMobile from "../components/alphatesting/alphatestingheading/alphatestingsidenav/alphatestingsidenavmobile";
import AlphaTestingSideNav from "../components/alphatesting/alphatestingheading/alphatestingsidenav/alphatestingsidenav";
import { Helmet } from "react-helmet";

const AlphaTesting = ({ findUser, metaTitle, metaDescription }) => {
  if (findUser) {
    return (
      <>
        <Container
          fluid={+true}
          id="helphub-wrapper"
          style={{
            backgroundColor: "var(--background-profile)",
            minHeight: "calc(100vh - 218px",
          }}
        >
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <Container className="content-container">
            <Row className="pt-2 justify-content-center">
              <Col className="px-0" xs="12" lg="10">
                <AlphaTestingHeading />
                <AlphaTestingSideNavMobile />

                <AlphaTestingInfo />
                <WhoIntegrates />
              </Col>
              <Col id="helphub-side-nav-col" xs="12" lg="2" className="pt-2 ">
                <AlphaTestingSideNav />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Breadcrumbs />
        <Container
          fluid={+true}
          id="helphub-wrapper"
          style={{
            backgroundColor: "var(--background-profile)",
            minHeight: "calc(100vh - 218px",
          }}
        >
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
          </Helmet>
          <Container className="content-container">
            <Row className="justify-content-center">
              <Col className="px-0" xs="12" lg="10">
                <AlphaTestingHeading />
                <AlphaTestingSideNavMobile />
                <AlphaTestingInfo />
                <WhoIntegrates />
              </Col>
              <Col id="helphub-side-nav-col" xs="12" lg="2" className="pt-2 ">
                <AlphaTestingSideNav />
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
};

export default AlphaTesting;
