import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";

import projectMapper from "./mapper/project";
import { useFetchTransfers } from "./service";
import { FindResolverContainer } from "./service/find-resolver";
import { NFTResolverContainer } from "./service/nft-resolver";

import "./styles.css";

export default function NFTDetails(props) {
  const { collection, id } = props;

  const { load, loading, transfers, owner } = useFetchTransfers({
    collection,
    id,
  });

  let rendered = [];
  if (transfers) {
    const viaMapper = projectMapper({ collection, id });
    rendered = transfers.map(viaMapper);
  }

  // Load data on init
  useEffect(() => {
    load().then();
  }, []);

  if (!transfers) {
    return (
      <Row className="align-items-center justify-content-center ">
        <Col>
          <Card
            className="align-items-start justify-content-center "
            style={{
              backgroundColor: "var(--background-profile)",
              width: "100%",
            }}
          >
            <Card.Body>
              <Col>No recent history</Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <div className="history-container">
      <FindResolverContainer>
        <NFTResolverContainer>
          {transfers && <div>{rendered}</div>}
        </NFTResolverContainer>
      </FindResolverContainer>
    </div>
  );
}
