import React from "react";
import { Col, Row } from "react-bootstrap";
import { usePagination, useRange } from "react-instantsearch-hooks-web";
import "./CollectionPage.css";

const CollectionStatsBox = (props) => {
  const { nbHits } = usePagination(props);
  const {  range } = useRange({
    attribute: "amount",
  });

  return (
    <Row
      id="collection-stats-row-wrap"
      className="d-flex justify-content-center align-items-center"
    >
      <Col xs="10" md="9" xl="7">
        <Row id="collection-stats-row" className="d-lg-none">
          <Col xs="6">
            <Row className="stats-title">Listed Items:</Row>
            <Row className="stats-num">{nbHits}</Row>
          </Col>

          <Col xs="6">
            <Row className="stats-title">Floor Price:</Row>
            <Row className="stats-num">{range.min}</Row>
          </Col>
          {/* <Col xs="6">
            <Row className="stats-title">Total Volume:</Row>
            <Row className="stats-num">1,000,000 FUSD</Row>
          </Col> */}
        </Row>

        <Row id="collection-stats-row" className="d-none d-lg-flex">
          <Col>
            <Row className="stats-title">Listed Items:</Row>
            <Row className="stats-num">{nbHits}</Row>
          </Col>
   
          <Col>
            <Row className="stats-title">Floor Price:</Row>
            <Row className="stats-num">{range.min}</Row>
          </Col>
          {/* <Col>
            <Row className="stats-title">Total Volume:</Row>
            <Row className="stats-num">1,000,000 FUSD</Row>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

export default CollectionStatsBox;
