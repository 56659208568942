import { Container, Row, Col, Image } from "react-bootstrap";
import { UseThemeStatus } from "../../../functions/themeMode";

export function MakeWalletFindable() {
    const themeStatus = UseThemeStatus()
    return (
        <Container id="make-wallet-findable">
            <Row>
                <Col className="my-auto px-5">
                    <div className="my-3"><h2>Make your wallet findable for everyone</h2></div>
                    <p>Remembering an 18 digit hex code is hard, even Einstein would struggle. With .find you can lease any name you want and attach it to your wallet, meaning it's easier for you and friends to find each other across the flow blockchain. </p>
                </Col>
                <Col sm="12" lg="6" xs={{ order: 'first' }} md={{ order: 'last'}} align="center">
                    <Image src={themeStatus === 'dark' ? "/assets/img/home/wallet-example-dark.webp" :"/assets/img/home/wallet-example-light.webp"} alt="example of a real life wallet" width="500" fluid={+true} />
                </Col>
            </Row>
        </Container>
    )
}