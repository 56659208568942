import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SearchHome from "../components/home/SearchHome";
import { InfoCards } from "../components/home/infocards/infocards";
import { GiftNamesSendFlow } from "../components/home/giftnamessendflow/giftnamessendflow";
import { MintAndMarket } from "../components/home/mintandmarket/mintandmarket";
import { MakeWalletFindable } from "../components/home/makewalletfindable/makewalletfindable";
import { WhoIntegrates } from "../components/home/whointegrates/whointegrates";
import { JoinDiscord } from "../components/home/joindiscord/joindiscord";
import { BuiltOn } from "../components/home/builton/builton";
import BestCollections from "../components/home/bestcollections/bestcollections";

import "../components/home/home.css";

function Home(props) {
  const { metaTitle, metaDescription } = props;

  return (
    <Container id="home" fluid={+true} className="home g-0">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <SearchHome />

      <InfoCards />
      <BestCollections />
      <GiftNamesSendFlow />
      <MintAndMarket />
      <MakeWalletFindable />
      <WhoIntegrates />
      {/* <Faq /> */}
      <JoinDiscord />
      <BuiltOn />
    </Container>
  );
}
export default Home;
