import { Autocomplete } from "../search/autocomplete/Autocomplete";
import { InstantSearch } from "react-instantsearch-hooks-web";
import searchBarClient from "../../functions/searchClient"

export default function SearchOffCanvas({ name, ...props }) {
  return (
    <>
      <InstantSearch
        indexName="market"
        searchClient={searchBarClient}
      >
        <Autocomplete
          searchClient={searchBarClient}
          placeholder=""
          openOnFocus
          className="nav"
          detachedMediaQuery="(max-width: 991px)"
        />
      </InstantSearch>
    </>
  );
}
