import { groupItems, process } from "./utils";
import RenderTree from "./RenderTree";
import Context from "./context";

import "./new-traits.scss"

export default function TraitsDisplay(props) {
  const { traitsList, itemCatalogName } = props;

  const grouped = groupItems(traitsList);
  const treeData = process(grouped, { level: 0 });

  return (
    <Context.Provider value={itemCatalogName}>
      <div className={"trait-root"}>
        <RenderTree treeData={treeData} />
      </div>
    </Context.Provider>
  );

  /*  return traitsList.map((properties, i) => {
    // console.log(properties);
    // console.log(itemTraits);

    const layout = {
      xs: "8",
      sm: "6",
      lg: "6",
      xl: "6",
      xxl: "4",
    };

    const to =
      "/mp?collection=" +
        itemCatalogName +
        "&traits=" +
        properties.displayType &&
      properties.displayType.toLowerCase() + properties.displayType &&
      "." +
        encodeURI(properties.name.toLowerCase()) +
        "%3D" +
        encodeURI(properties.value);

    return (
      <Col key={i} {...layout} className="propertiesTraits mb-3">
        <Link style={{ textDecoration: "none" }} to={to}>
          <Card>
            <Card.Body className="traitsWrap">
              <div>
                {properties.name && (
                  <Row>
                    <span className="fw-bold">
                      {UppercaseFirstLetter(properties.name)}
                    </span>
                  </Row>
                )}
                {properties.value &&
                  (properties.displayType === "Number" ? (
                    <Row>
                      <span className="themeColor">{properties.value * 1}</span>
                    </Row>
                  ) : properties.displayType === "date" ? (
                    <Row>
                      <span className="themeColor">
                        {new Date(properties.value * 1000).toLocaleDateString()}
                      </span>
                    </Row>
                  ) : (
                    <Row>
                      <span className="themeColor">
                        {UppercaseFirstLetter(properties.value)}
                      </span>
                    </Row>
                  ))}
                {properties?.rarity?.description ? (
                  <Row>
                    <span className="secColor">
                      {UppercaseFirstLetter(properties.rarity.description)}
                    </span>
                  </Row>
                ) : properties?.rarity?.score ? (
                  <Row>
                    <span className="secColor">
                      {properties?.rarity?.score * 1}
                      {properties?.rarity?.max &&
                        " / " + properties?.rarity?.max * 1}
                    </span>
                  </Row>
                ) : (
                  <Row>
                    <span className="secColor"></span>
                  </Row>
                )}
              </div>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );
  });*/
}
