import React from "react";
import LiveFeedEventItem from "./LiveFeedEventItem";
import LiveFeedEventItemThought from "./LiveFeedEventItemThought";
import LiveFeedEventItemThoughtTabThought from "./LiveFeedEventItemThoughtTabThought";
import "./LiveFeedWrapper.scss";

const LiveFeedDataBox = ({ currentTab, eventsData, thoughtsData, user }) => {
  // console.log(eventsData)
  return (
    <div id="live-feed-data-box-wrapper">
      {/* //non thoughts tabs */}
      {currentTab !== "Thoughts" && (
        <>
          {eventsData && Object.keys(eventsData).length > 0 ? (
            eventsData.map((event, i) => {
              // console.log(event)
              if (!event.flowEventId.includes("FindThoughts.Published")) {
                return <LiveFeedEventItem key={event.id} event={event} i={i} />;
              } else {
                return (
                  <LiveFeedEventItemThought
                    key={event.id}
                    event={event}
                    i={i}
                    user={user}
                  />
                );
              }
            })
          ) : eventsData && Object.keys(eventsData).length === 0 ? (
            <div id="live-feed-data-box-loading-wrapper">No recent data</div>
          ) : (
            <div id="live-feed-data-box-loading-wrapper">
              {currentTab === "Trending"
                ? "Coming soon...™"
                : "Fetching data from chain..."}{" "}
            </div>
          )}
        </>
      )}
      {/* thoughts tab */}
      {currentTab === "Thoughts" && (
        <>
          {thoughtsData && thoughtsData.length > 0 ? (
            thoughtsData.map((thought, i) => {
              return (
                <LiveFeedEventItemThoughtTabThought
                  key={thought.id}
                  thought={thought}
                />
              );
            })
          ) : (
            <div id="live-feed-data-box-loading-wrapper">
              Fetching data from chain...
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LiveFeedDataBox;
