import { sortBy } from "../../../../../utils";
import RefineItem from "../RefineItem";

export default function SingleGroupFilter(props) {
  const { items, onClick } = props;

  return items
    .sort(sortBy("label"))
    .map((item) => <RefineItem item={item} onClick={onClick} />);
}
