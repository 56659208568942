import React from "react";
import "./createprofile.scss";

const CreateProfileStageBar = ({ currentStage, setCurrentStage }) => {
  // console.log(currentStage)
  return (
    <div id="create-profile-stage-bar-wrapper">

      <div id="stage-bar-row">

        <div className="stage-icon-wrapper">
          <img
            className="stage-icon-completed"
            src="assets/img/profile/createprofile/stage-icon-current.svg"
          />
        </div>

        <div className="stage-icon-wrapper">
          <img
            
            className={currentStage > 2 ? "stage-icon-completed" : null}
            src={"assets/img/profile/createprofile/stage-icon-current.svg"}
          />
        </div>

        <div className="stage-icon-wrapper">
          <img
            className={currentStage === 4 ? "stage-icon-completed" : ""}
            src={
              currentStage >= 3
                ? "assets/img/profile/createprofile/stage-icon-current.svg"
                : "assets/img/profile/createprofile/stage-icon.svg"
            }
          />
        </div>

        <div className="stage-icon-wrapper">
          <img
            src={
              currentStage === 4
                ? "assets/img/profile/createprofile/stage-icon-current.svg"
                : "assets/img/profile/createprofile/stage-icon.svg"
            }
          />
        </div>

      </div>
      
    </div>
  );
};

export default CreateProfileStageBar;
