import React from "react";

import FlowSticker from "../../../forms/ftstickers/FlowSticker";
import FUSDSticker from "../../../forms/ftstickers/FUSDSticker";
import DapperSticker from "../../../forms/ftstickers/DapperSticker";
import USDCSticker from "../../../forms/ftstickers/USDCSticker";

export const NONE = "none";
export const DAPPER = "Dapper";
export const NON_DAPPER = "Non-Dapper";

export function getAlias(listingDetails, isName, type = "sale") {
  if(type === "auction"){
    return listingDetails.auctionFtAlias
  }

  return isName
    ? listingDetails?.leaseStatus?.saleFtAlias
    : listingDetails?.findMarket?.FindMarketSale?.ftAlias;
}

export function findSticker(ftAlias){
  let sticker = null;
  let prefix = "";

  if (ftAlias) {
    switch (true) {
      case ftAlias.includes("FUT"):
        prefix = ""
        sticker = <DapperSticker coin="FLOW"/>
        break;
      case ftAlias.includes("Flow"):
        prefix = "";
        sticker = <FlowSticker />;
        break;
      case ftAlias.includes("FUSD"):
        prefix = "$";
        sticker = <FUSDSticker />;
        break;
      case ftAlias.includes("DUC"):
        prefix = "$";
        sticker = <DapperSticker />;
        break;
      case ftAlias.includes("USDC"):
        prefix = "$";
        sticker = <USDCSticker/>
        break;
      default:
        prefix = "";
        sticker = null;
    }
  }

  return { prefix, sticker }
}

export function getSticker(listingDetails, isName, type = "sale") {
  const ftAlias = getAlias(listingDetails, isName, type);

  return findSticker(ftAlias)
}
export function getItemAmount(listingDetails, isName, type) {
  if (type === "auction") {
    const { amount, latestBidBy, latestBid, auctionStartPrice } = listingDetails;
    if (!isName) {
      return amount;
    }

    return latestBidBy ? latestBid : auctionStartPrice;
  }

  return isName
    ? listingDetails?.leaseStatus?.salePrice
    : listingDetails?.findMarket?.FindMarketSale?.amount;
}
export function getListingType(listingDetails, isName, type) {
  const ftAlias = getAlias(listingDetails, isName, type);

  let listingType = null;
  if (ftAlias) {
    switch (true) {
      case ftAlias.includes("DUC"):
      case ftAlias.includes("FUT"):
        listingType = DAPPER;
        break;
      case ftAlias.includes("Flow"):
      case ftAlias.includes("FUSD"):
      case ftAlias.includes("USDC"):
        listingType = NON_DAPPER;
        break;
      default:
        break;
    }
  }
  return listingType;
}
export function getWalletType(walletType) {
  if (walletType === "Dapper Wallet") {
    return DAPPER;
  }

  if (walletType === NONE) {
    return NONE;
  }

  return NON_DAPPER;
}
