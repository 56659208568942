
const truncateAddress = (address, length) => {
  // console.log(address);
  let itemLength = length ? length : 3
  let trunctAddressStart;
  let trunctAddressEnd;
  let trunctAddressMiddle = "...";
  let trunctAddressTotal;

  if (address && address.length) {
    trunctAddressStart = address.substring(0, itemLength);
    trunctAddressEnd = address.substring(address.length - itemLength);
    trunctAddressTotal =
      trunctAddressStart + trunctAddressMiddle + trunctAddressEnd;
    return trunctAddressTotal;
  }
};

export default truncateAddress;
