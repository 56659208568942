import TypesenseAdapter from "typesense-instantsearch-adapter";

const typesenseApiKey = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_API_KEY;
const typesenseHostUrl = process.env.REACT_APP_TYPESENSE_INSTANTSEARCH_HOST_URL;

export function makeSearchClient(query_by) {
  const typesenseInstantsearchAdapter = new TypesenseAdapter({
    server: {
      apiKey: typesenseApiKey, // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: typesenseHostUrl,
          port: "443",
          protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: { query_by }
  });
  const searchClient = typesenseInstantsearchAdapter.searchClient;

  // Set transporter
  searchClient.transporter = {
    headers: {
      "x-algolia-application-id": typesenseHostUrl,
      "x-algolia-api-key": typesenseApiKey,
    },
    queryParameters: {},
  };

  return searchClient
}

export default makeSearchClient("nft_name, collection_name, seller_name, seller")