import Slider from "../Generic/Slider";
import { Accordion } from "react-bootstrap";

export default function EditionFilter() {
  const attribute = "number.edition_wearable_number";

  return (
    <Accordion.Item className="filter__slider-container" eventKey={"edition"}>
      <Accordion.Header>
        <span className="smallTextSpan">Edition</span>
      </Accordion.Header>
      <Accordion.Body className="px-0">
        <Slider attribute={attribute} />
      </Accordion.Body>
    </Accordion.Item>
  );
}
