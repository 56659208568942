import { useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

import "./searchedfor.css";

const SearchedFor = ({ searchedName }) => {
  let navigate = useNavigate();
  const form = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name } = form.current;
    let searchName = name.value.toLowerCase();
    searchName = searchName.replace(/[^a-z0-9-]/g, "");
    if (!searchName) {
      return;
    }
    if (searchName.length < 3 || searchName.length > 16) {
      return;
    }
    navigate("/" + searchName);
  };

  return (
    <Container className="searched-for-wrapper form-inputs px-3 pb-4">
      <Row className="justify-content-center" xs={1} md={2}>
        <Col className="mb-2">
          <Form ref={form} onSubmit={handleSubmit}>
            <Form.Control
              type="string"
              placeholder="Search for a name or 0xAddress"
              name="name"
            />
          </Form>
        </Col>
        <Col className="mt-3 mt-md-0" md="auto" align="center">
          <Button onClick={handleSubmit} text="Confirm" variant="find-skinny">
            Search
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchedFor;
