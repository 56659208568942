import React, { useEffect, useState } from "react";
import ReviewMadeBidNftStore from "../../../forms/buying/reviewmadebidnftstore/reviewmadebidnftstore";
import ReactTimeago from "react-timeago";

import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { handleRetractNFTOffer } from "../../../../functions/flow-interactions/transactions";
import "../../userstore.css";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../../functions/parseImgUrl";

const StoreBuyingNfts = ({ bids, filterTerm }) => {
  // console.log(bids);

  const [filteredNftOffers, setFilteredNftOffers] = useState();
  const [filteredNftBids, setFilteredNftBids] = useState();
  const [ghostNftOffers, setGhostNftOffers] = useState();

  // FindMarketDirectOfferEscrow

  useEffect(() => {
    if (bids.FindMarketDirectOfferEscrow) {
      setFilteredNftOffers(
        bids.FindMarketDirectOfferEscrow.items.filter(
          (item) =>
            item.item.nft.name
              .toLowerCase()
              .includes(filterTerm.toLowerCase()) ||
            item.item.nft.collectionName
              .toLowerCase()
              .includes(filterTerm.toLowerCase())
        )
      );
      if (
        bids.FindMarketDirectOfferEscrow.ghosts &&
        bids.FindMarketDirectOfferEscrow.ghosts.length > 0
      ) {
        setGhostNftOffers(bids.FindMarketDirectOfferEscrow.ghosts);
      }
    }
  }, [bids, filterTerm]);

  useEffect(() => {
    if (bids.FindMarketAuctionEscrow) {
      setFilteredNftBids(
        bids.FindMarketAuctionEscrow.items.filter(
          (item) =>
            item.item.nft.name
              .toLowerCase()
              .includes(filterTerm.toLowerCase()) ||
            item.item.nft.collectionName
              .toLowerCase()
              .includes(filterTerm.toLowerCase())
        )
      );
    }
  }, [bids, filterTerm]);

  // console.log(filteredNftOffers)
  // const filteredNftOffers = bids.filter((bid) => bid.item.saleType === "directoffer")
  // const filteredNftBids = bids.filter((bid) => bid.item.saleType === "ondemand_auction")

  function CustomToggle({ children, eventKey, lease }) {
    // //console.log(bids);
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <>
        <div className="d-none d-lg-block">
          <Button
            variant="find-outline-commerce"
            size="sm"
            onClick={decoratedOnClick}
          >
            {children}
          </Button>
        </div>
        <div className="d-lg-none">
          <Button
            onClick={decoratedOnClick}
            style={{
              backgroundImage: "var(--accordion-chevron)",
              width: "26px",
              height: "26px",
              backgroundColor: "var(--background)",
              borderColor: "var(--background)",
              boxShadow: "none",
            }}
          ></Button>
        </div>
      </>
    );
  }

  //saleType = directoffer

  // //console.log(filteredNftOffers)
  return (
    <Container fluid={+true} id="store-names-listings" className="px-0 mb-3">
      <Card>
        <Card.Body>
          <Row>
            <Col align="left">
              <h4>Your active bids/offers on NFTs</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <p>
              <b>Offers:</b>
            </p>
          </Row>
          <table className="store-table" id="store-offering-nfts-table">
            <tbody>
              {filteredNftOffers && filteredNftOffers.length > 0 ? (
                filteredNftOffers.map((bid, i) => {
                  // //console.log(bid);

                  let imgUrl = "";
                  let nftName;
                  bid.item.nft.name
                    ? (nftName = bid.item.nft.name)
                    : (nftName =
                        bid.item.nft.collectionName + " #" + bid.item.nft.id);

                  if (bid.item.nft.thumbnail) {
                    if (bid.item.nft.thumbnail.includes("ipfs://")) {
                      imgUrl = bid.item.nft.thumbnail.replace(
                        "ipfs://",
                        "https://find.mypinata.cloud/ipfs/"
                      );
                    } else {
                      imgUrl = bid.item.nft.thumbnail;
                    }
                  }

                  const NftThumb = (
                    <Image
                      className="store-thumb-image"
                      fluid={+true}
                      src={imgUrl}
                    />
                  );

                  return (
                    <React.Fragment key={i + "store-offering-names-row"}>
                      <tr
                        id={i + "store-offering-names-row"}
                        className="store-row"
                      >
                        <td className="store-thumb-col">{NftThumb}</td>
                        <td className="store-name-col">
                          <Link
                            to={
                              "/mp/" +
                              bid.item.collectionName +
                              "/" +
                              bid.listingId
                            }
                          >
                            <span className="fw-bold">
                              <span>{nftName}</span>
                            </span>
                          </Link>
                        </td> 
                        <td className="store-sticker-col"></td>
                        <td className="store-price-col">
                          <div className="d-none d-lg-block">
                            Your offer:{" "}
                            <b>
                              {parseFloat(bid.item.amount).toFixed(2)}{" "}
                              {bid.item.ftAlias}
                            </b>
                          </div>
                          <div className="d-block d-lg-none">
                            <b>
                              {parseFloat(bid.item.amount).toFixed(2)}{" "}
                              {bid.item.ftAlias}
                            </b>{" "}
                          </div>
                        </td>
                        <td className="store-time-left-col">
                          <span className="store-sticker">
                            Waiting for response{" "}
                          </span>
                        </td>

                        <td className="store-buttons-col">
                          <Button
                            onClick={() =>
                              handleRetractNFTOffer(bid.item.listingId)
                            }
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                      <tr className="mobile-row">
                        <td className="mobile-store-sticker-col">
                          {" "}
                          <span className="store-sticker">Waiting </span>
                        </td>
                        <td className="mobile-store-time-left-col"></td>
                        <td align="end">
                          <Button
                            onClick={() =>
                              handleRetractNFTOffer(bid.item.listingId)
                            }
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>

                    // <Row key={i} className="my-4 align-items-center">
                    //   <Col className="col-1" xs="2" md="1">
                    //     <Image className="store-thumb" fluid={+true} src={imgUrl} />
                    //   </Col>
                    //   <Col xs="10" md="auto">
                    //     <b>{bid.item.nft.name}</b>
                    //   </Col>
                    //   <Col xs="12" md="auto">
                    //     Your offer:{" "}
                    //     <b>
                    //       {parseFloat(bid.item.amount).toFixed(2)}{" "}
                    //       {bid.item.ftAlias}
                    //     </b>
                    //   </Col>
                    //   <Col></Col>

                    //   <Col xs="12" md="auto">
                    //     Waiting for response
                    //   </Col>
                    //   <Col xs="12" md="auto" align="right">
                    // <Button
                    //   onClick={() => handleRetractNFTOffer(bid.item.listingId)}
                    //   variant="find-outline-commerce-red"
                    // >
                    //   Remove
                    // </Button>
                    //   </Col>
                    // </Row>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <small>
                      You are not currently making offers on any NFTs
                    </small>
                  </td>
                </tr>
              )}

              {ghostNftOffers &&
                ghostNftOffers.length > 0 &&
                ghostNftOffers.map((bid, i) => {
                  let imgUrl = "/assets/img/store/fnamestore.png";
                  let ghostID = bid.id;
                  const NftThumb = (
                    <Image
                    crossOrigin="anonymous"

                      className="store-thumb-image"
                      fluid={+true}
                      src={parseImgUrl(imgUrl, 'icon')}
                    />
                  );
                  return (
                    <React.Fragment key={i + "store-offering-names-row"}>
                      <tr
                        id={i + "store-offering-names-row"}
                        className="store-row"
                      >
                        <td className="store-thumb-col">{NftThumb}</td>
                        <td className="store-name-col">
                          <span className="fw-bold">
                            <span>{ghostID}</span>
                          </span>
                        </td>
                        <td className="store-sticker-col"></td>
                        <td className="store-price-col"></td>
                        <td className="store-time-left-col">
                          <span className="store-sticker">Ghost listing</span>
                        </td>
                        <td className="store-buttons-col">
                          <Button
                            onClick={() => handleRetractNFTOffer(ghostID)}
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                      <tr className="mobile-row">
                        <td className="mobile-store-sticker-col">
                          <span className="store-sticker">Ghost</span>
                        </td>
                        <td className="mobile-store-time-left-col"></td>
                        <td align="end">
                          <Button
                            onClick={() => handleRetractNFTOffer(ghostID)}
                            variant="find-outline-commerce-red"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>

                    // <Row key={i} className="my-4 align-items-center">
                    //   <Col className="col-1" xs="2" md="1">
                    //     <Image className="store-thumb" fluid={+true} src={imgUrl} />
                    //   </Col>
                    //   <Col xs="10" md="auto">
                    //     <b>{bid.item.nft.name}</b>
                    //   </Col>
                    //   <Col xs="12" md="auto">
                    //     Your offer:{" "}
                    //     <b>
                    //       {parseFloat(bid.item.amount).toFixed(2)}{" "}
                    //       {bid.item.ftAlias}
                    //     </b>
                    //   </Col>
                    //   <Col></Col>

                    //   <Col xs="12" md="auto">
                    //     Waiting for response
                    //   </Col>
                    //   <Col xs="12" md="auto" align="right">
                    // <Button
                    //   onClick={() => handleRetractNFTOffer(bid.item.listingId)}
                    //   variant="find-outline-commerce-red"
                    // >
                    //   Remove
                    // </Button>
                    //   </Col>
                    // </Row>
                  );
                })}
            </tbody>
          </table>

          <div className="seperator100 my-4"></div>

          <Row>
            <p>
              <b>For auction:</b>
            </p>
          </Row>
          <Accordion>
            <table className="store-table" id="store-bidding-nfts-table">
              <tbody>
                {filteredNftBids && filteredNftBids.length > 0 ? (
                  filteredNftBids.map((bid, i) => {
                    let imgUrl = "";
                    let nftName;
                    bid.item.nft.name
                      ? (nftName = bid.item.nft.name)
                      : (nftName =
                          bid.item.nft.collectionName + " #" + bid.item.nft.id);
                    if (bid.item.nft.thumbnail) {
                      if (bid.item.nft.thumbnail.includes("ipfs://")) {
                        imgUrl = bid.item.nft.thumbnail.replace(
                          "ipfs://",
                          "https://find.mypinata.cloud/ipfs/"
                        );
                      } else {
                        imgUrl = bid.item.nft.thumbnail;
                      }
                    }
                    let ended;
                    if (
                      bid.item.auction.auctionEndsAt <
                      bid.item.auction.timestamp
                    ) {
                      ended = true;
                    } else {
                      ended = false;
                    }

                    const NftThumb = (
                      <Image
                      crossOrigin="anonymous"

                        className="store-thumb-image"
                        fluid={+true}
                        src={parseImgUrl(imgUrl, 'icon')}
                      />
                    );

                    return (
                      <React.Fragment key={i + "store-bidding-nfts-row"}>
                        <tr
                          id={i + "store-bidding-nfts-row"}
                          className="store-row"
                        >
                          <td className="store-thumb-col">{NftThumb}</td>
                          <td className="store-name-col">
                            <Link
                              to={
                                "/mp/" +
                                bid.item.collectionName +
                                "/" +
                                bid.listingId
                              }
                            >
                              <span className="fw-bold">
                                <span>{nftName}</span>
                              </span>
                            </Link>
                          </td>
                          <td className="store-sticker-col"></td>
                          <td className="store-price-col">
                            <div className="d-none d-lg-block">
                              Your bid:{" "}
                              <b>
                                {" "}
                                {parseFloat(bid.item.amount).toFixed(2)}{" "}
                                {bid.item.ftAlias}
                              </b>{" "}
                            </div>
                            <div className="d-block d-lg-none">
                              <b>
                                {" "}
                                {parseFloat(bid.item.amount).toFixed(2)}{" "}
                                {bid.item.ftAlias}
                              </b>{" "}
                            </div>
                          </td>
                          <td className="store-time-left-col">
                            <span className="store-sticker">
                              {!ended ? "Ends: " : "Ended: "}
                              <ReactTimeago
                                date={
                                  new Date(
                                    bid.item.auction.auctionEndsAt * 1000
                                  )
                                }
                                timestyle="twitter"
                              />
                            </span>
                          </td>

                          <td className="store-buttons-col">
                            <CustomToggle eventKey={i}>
                              {" "}
                              View Auction
                            </CustomToggle>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-none d-md-table-cell" colSpan={6}>
                            <Accordion.Collapse eventKey={i}>
                              <Card.Body>
                                <div
                                  className="collapse show"
                                  id="collapseExample"
                                >
                                  <ReviewMadeBidNftStore filteredBid={bid} />
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </td>
                        </tr>

                        <tr className="mobile-row">
                          <td className="mobile-store-sticker-col">
                            {bid.latestBid && (
                              <span className="store-sticker">Bids </span>
                            )}
                          </td>
                          <td className="mobile-store-time-left-col">
                            <span className="store-sticker">
                              Ends:{" "}
                              <ReactTimeago
                                date={
                                  new Date(
                                    bid.item.auction.auctionEndsAt * 1000
                                  )
                                }
                                timestyle="twitter"
                              />
                            </span>
                          </td>
                          <td align="end">
                            <CustomToggle eventKey={i}>
                              {" "}
                              View Auction
                            </CustomToggle>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-table-cell d-md-none" colSpan={3}>
                            <Accordion.Collapse eventKey={i}>
                              <Card.Body>
                                <div
                                  className="collapse show"
                                  id="collapseExample"
                                >
                                  <ReviewMadeBidNftStore filteredBid={bid} />
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </td>
                        </tr>
                      </React.Fragment>

                      // <React.Fragment key={i}>
                      //   <Row key={i} className="my-4 align-items-center">
                      //   <Col className="col-1" xs="2" md="1">
                      //   <Image className="store-thumb" fluid={+true} src={imgUrl} />
                      // </Col>
                      // <Col xs="10" md="auto">
                      //   <b>{bid.item.nft.name}</b>
                      // </Col>
                      //     <Col xs='12' md='auto'>
                      //       Your bid:{" "}
                      //       <b>
                      // {parseFloat(bid.item.amount).toFixed(2)}{" "}
                      // {bid.item.ftAlias}
                      //       </b>
                      //     </Col>
                      //     <Col xs='12' md='auto'>
                      //       {" "}
                      //       Auction{" "}
                      //       {bid.item.auction.auctionEndsAt >
                      //       bid.item.auction.timestamp
                      //         ? "ends:"
                      //         : "ended:"}{" "}
                      //       &nbsp;
                      //       <b>
                      //         <ReactTimeago
                      //           date={
                      //             new Date(
                      //               bid.item.auction.auctionEndsAt * 1000
                      //             )
                      //           }
                      //           timestyle="twitter"
                      //         />
                      //       </b>{" "}
                      //       {}
                      //     </Col>

                      //     {/* button needs new tx cancelBidMarketDirectOfferEscrowed */}
                      //     <Col xs='12' md='auto' align="right">
                      //       <CustomToggle eventKey={i}>View Auction</CustomToggle>
                      //     </Col>
                      //   </Row>
                      //   <Accordion.Collapse eventKey={i}>
                      //     <Card.Body>
                      //       <div className="collapse show" id="collapseExample">
                      // <ReviewMadeBidNftStore filteredBid={bid} />
                      //       </div>
                      //     </Card.Body>
                      //   </Accordion.Collapse>
                      // </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td>
                      <small>You are not currently bidding on any NFTs</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StoreBuyingNfts;
