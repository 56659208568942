import * as registry from "./registry";

const plugins = Object.keys(registry).map((name) => {
  return registry[name]
});

export default function pluginProcessor(...args) {
  // Iterate over all plugins and identify the result
  for (let i = 0; i < plugins.length; i++) {
    const classify = plugins[i];
    const result = classify(...args);
    if (result) {
      return result;
    }
  }

  // Always return `null` in case none of the plugins covers this specific use case
  return null;
}
