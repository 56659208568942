import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import ReactTimeago from "react-timeago";
import { UseThemeStatus } from "../../../../../functions/themeMode";
import { toLocalNumber } from "../../../../../functions/format";
import FlowSticker from "../../../../forms/ftstickers/FlowSticker";
import FUSDSticker from "../../../../forms/ftstickers/FUSDSticker";
import ColDetailMakeOffer from "../ColDetailMakeOffer/ColDetailMakeOffer";
import ColDetailRemoveListingAuction from "../ColDetailRemoveListing/ColDetailRemoveListingAuction";
import ColDetailLatestBid from "./ColDetailLatestBid";
import ColDetailMakeBidModal from "./ColDetailMakeBidModal";

const ColDetailForAuction = (props) => {
  const { listingStatus } = props
  const [auctionListing, setAuctionListing] = useState();
  const [auctionInfo, setAuctionInfo] = useState();
  const [latestBid, setLatestBid] = useState();
  const [show, setShow] = useState();
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const themeStatus = UseThemeStatus();

  useEffect(() => {
    if (listingStatus.allCurrentListings.forAuction.auctionListing) {
      setAuctionListing(
        listingStatus.allCurrentListings.forAuction.auctionListing
      );
      setAuctionInfo(listingStatus.allCurrentListings.forAuction.auctionInfo);
    }
  }, [listingStatus.allCurrentListings.forAuction.auctionListing]);

  useEffect(() => {
    if (
      listingStatus.allCurrentListings.forAuction.auctionInfo.auctionStarted
    ) {
      const getEvents = async () => {
        // //console.log("getSales fired")
        let data;
        let res = await axios.get(
          "https://prod-main-net-dashboard-api.azurewebsites.net/api/company/04bd44ea-0ff1-44be-a5a0-e502802c56d8/search?eventType=A." +
            contractAddress +
            ".FindMarketAuctionEscrow.EnglishAuction&id=" +
            listingStatus.itemId +
            // listingDetails.findMarket.FindMarketAuctionEscrow.listingId +
            "&pageSize=1"
        );
        data = res.data;
        setLatestBid(
          data.filter(
            (event) => event.blockEventData.status === "active_ongoing"
          )
        );
      };

      getEvents();
    }
  }, []);

      //mobile swipedown close

      const config = {
        delta: 150, // min distance(px) before a swipe starts. *See Notes*
        preventScrollOnSwipe: true,
        swipeDuration: 250,
      };
      const handlers = useSwipeable({
        onSwipedDown: () => {
          // console.log('this')
          setShow(false);
        },
        ...config,
      });

  if(!auctionInfo){
    return null
  }
  if (auctionListing){

  }
    let bidLabel = "Starting bid: "
    if(auctionInfo.auctionStarted === true){
      bidLabel = auctionInfo.auctionEndedAboveReserve ? "Winning bid: " : "Current bid: "
    }

    return (
      <Row className="py-2 justify-content-center mb-4  ">
        <Col xs="12">
          <Card
            id="mp-price-box-card-sale"
            className="mp-price-box-card px-4 pb-4 pt-4 mb-2"
          >
            <Row className="py-2">
              <Col>
                <span className="fw-bold">
                  {bidLabel}
                </span>
              </Col>
              <Col>
                {auctionInfo.auctionStarted ? (
                  <div
                    style={{ float: "right" }}
                    className="d-flex align-items-end"
                  >
                    <span className=" secColor">Ends:&nbsp;</span>
                    <span className="fw-bold" style={{ float: "right" }}>
                      <ReactTimeago
                        date={
                          new Date(auctionListing.auction.auctionEndsAt * 1000)
                        }
                        timeStyle="twitter"
                      />
                    </span>
                  </div>
                ) : (
                  <span className="propertiesWrap" style={{ float: "right" }}>
                    Timer begins on first bid
                    <i className="fa-solid fa-circle-info" />
                  </span>
                )}
              </Col>
            </Row>
            <Row className="pb-2">
              <Col>
                <div className="mp-price-row">
                  <h3 className="mb-0">{toLocalNumber(auctionListing?.amount)}&nbsp;</h3>
                  {auctionListing.ftAlias.includes("Flow") ? (
                    <FlowSticker noBg={"true"} />
                  ) : (
                    <FUSDSticker noBg={"true"} />
                  )}
                </div>
              </Col>
            </Row>

            {latestBid && <ColDetailLatestBid latestBid={latestBid[0]} />}

            <Row className="pt-3">
              <Col
                align="center"
                xs="12"
                lg="6"
                className="pb-xs-2 mb-2 mb-lg-0"
              >
                {auctionListing.auction &&
                  listingStatus.allCurrentListings.linkedForMarket &&
                  (!listingStatus.user.loggedIn ||
                    !listingStatus.owner.ownItem) && (
                    <>
                      {!listingStatus.forSale && (
                        <ColDetailMakeOffer
                          listingStatus={listingStatus}
                          saleDetails={auctionListing}
                        />
                      )}
                    </>
                  )}
              </Col>
              <Col align="center" xs="12" lg="6" className="pb-0">
                {listingStatus.owner.ownItem && (
                  <ColDetailRemoveListingAuction
                    listingStatus={listingStatus}
                    saleDetails={auctionListing}
                  />
                )}
                {!listingStatus.owner.ownItem && (
                  <>
                    <Row className="">
                      <Col xs="12">
                        {auctionInfo.auctionEnded &&
                        auctionInfo.userHighestBidder &&
                        auctionInfo.auctionEndedAboveReserve ? (
                          <>
                            <Button
                              className="w-100"
                              variant="find-nochange-dark"
                              // onClick={handleBuyerMpFulfil}
                            >
                              <small>Fulfill</small>
                            </Button>
                            {/* {buyerClickedFulfil &&
                            toastStatus.status === "success" && (
                              <Modal
                                data-theme={themeStatus}
                                show={
                                  buyerClickedFulfil &&
                                  toastStatus.status === "success"
                                }
                                centered
                              >
                                <MarketplaceItemBoughtModal
                                  nftDetails={hit}
                                  isName={isName}
                                />
                              </Modal>
                            )} */}
                          </>
                        ) : (
                          !auctionInfo.auctionEnded &&
                          !listingStatus.owner.ownItem && (
                            <Button
                              className="w-100"
                              variant="find-nochange-dark"
                              onClick={() => setShow(!show)}
                            >
                              <small> Make Bid</small>
                            </Button>
                          )
                        )}
                      </Col>
                    </Row>
                    <Modal
                      show={show}
                      onHide={() => setShow(!show)}
                      centered
                      data-theme={themeStatus}
                    >
                              <div {...handlers}>

                      <Modal.Header
                        style={{ border: "none" }}
                        closeButton
                        closeLabel="close window"
                      >
                        <Modal.Title className="text-center w-100">
                          <h4
                            className="fw-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            Make a bid for {listingStatus.itemName}
                          </h4>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ColDetailMakeBidModal
                          listingStatus={listingStatus}
                          auctionListing={auctionListing}
                          auctionInfo={auctionInfo}
                        />
                      </Modal.Body>
                      </div>
                    </Modal>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
};

export default ColDetailForAuction;
