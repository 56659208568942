import React from "react";
import { Container, Row } from "react-bootstrap";
import RegisterNameBox from "../registernamebox/registernamebox";
import SearchedMoreItems from "../searchedmoreitems/searchedmoreitems";
import SearchTabs from "../searchtabs/searchtabs";
import './searchresultsnameavailable.css'


const SearchResultsNameAvailable = ({profileData, searchedName}) => {
  return (
    <div className="px-3 pb-3 pt-4" id="search-results-name-available">
    <Container className="content-container" >
      <Row className="pb-3">
       
          <h5>Search results for <span className="themeColor">{searchedName}</span> </h5>
             
          {/* <div style={{ color: "var(--text-secondary)" }}>1337,000 search results found</div> */}
       
      </Row>

      <Row>
          <SearchTabs />
      </Row>
      <Row>
        <RegisterNameBox profileData={profileData} searchedName={searchedName} />
      </Row>
      <Row>
      <div className="seperator100 my-4"></div>
      </Row>
      <Row>
        <SearchedMoreItems />
      </Row>
    </Container>
    </div>
  );
};

export default SearchResultsNameAvailable;
