import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import MpDetailBuyNow from "./mpdetailbuynow";
import MpDetailMakeOffer from "./mpdetailmakeoffer";
import MpDetailRemoveListing from "./mpdetailremovelisting";
import { useWalletType } from "../../../functions/useWalletType";

import { PriceTag, WalletHints } from "./components";
import { getListingType, getItemAmount, getWalletType } from "./utils";
import { NONE } from "./utils";

import "../marketplace.css";

function RenderButtons(props) {
  const {
    buyNowClicked,
    findUser,
    isName,
    hit,
    listingDetails,
    listingStatus,
    query,
    setBuyNowClicked,
    themeStatus,
    toastStatus,
    userHasOffer,
  } = props;

  const { show = false } = props;

  const itemAmount = getItemAmount(listingDetails, isName);

  if (!show) {
    return null;
  }

  const personalItem = listingStatus.ownItem;

  const showMakeOffer =
    listingStatus.listingDetails.linkedForMarket && !personalItem;
  const showRemoveListing = listingDetails && personalItem;
  const showBuyButton = listingStatus.forSale && !personalItem;

  return (
    <Row className="pt-4">
      <Col align="center" xs="12" lg="6" className="pb-xs-2 mb-3 mb-lg-0">
        {showMakeOffer && (
          <MpDetailMakeOffer
            hit={hit}
            themeStatus={themeStatus}
            findUser={findUser}
            profileDetails={listingStatus.sellerProfile}
            listingDetails={listingDetails}
            userHasOffer={userHasOffer}
            isName={isName}
          />
        )}
        {showRemoveListing && (
          <MpDetailRemoveListing
            hit={hit}
            label={"Withdraw Sale"}
            isName={isName}
            forSale={listingStatus.forSale}
            forAuction={listingStatus.forSale}
            listingDetails={listingDetails}
            themeStatus={themeStatus}
            listingStatus={listingStatus}
          />
        )}
      </Col>
      <Col align="center" xs="12" lg="6" className="pb-xs-2">
        {showBuyButton && (
          <MpDetailBuyNow
            hit={hit}
            isName={isName}
            listingDetails={listingDetails}
            toastStatus={toastStatus}
            themeStatus={themeStatus}
            buyNowClicked={buyNowClicked}
            setBuyNowClicked={setBuyNowClicked}
            query={query}
            findUser={findUser}
            listingStatus={listingStatus}
            itemAmount={itemAmount}
          />
        )}
      </Col>
    </Row>
  );
}

export default function MpDetailSalePrice(props) {
  const { listingDetails, listingStatus, isName } = props;

  const listingType = getListingType(listingDetails, isName);

  const walletType = getWalletType(useWalletType());
  const showButtons = walletType === listingType;
  const showHints = walletType === NONE || !showButtons;

  // Styles and Id
  const cardId = "mp-price-box-card-sale";
  const cardClass = "mp-price-box-card px-4 p-4 mb-2";

  if(!(listingDetails && listingStatus.forSale)){
    return null
  }

  return (
    <Row className="py-2 justify-content-center">
      <Col xs="12">
        <Card id={cardId} className={cardClass}>
          <Row className="fw-bold m-0">Sale price:</Row>
          <PriceTag listingDetails={listingDetails} isName={isName} />
          <RenderButtons {...props} show={showButtons} />
          <WalletHints
            show={showHints}
            listingDetails={listingDetails}
            listingType={listingType}
          />
        </Card>
      </Col>
    </Row>
  );
}
