import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UppercaseFirstLetter from "../../../../../functions/UppercaseFirstLetter";
import "./detailspropertycard.css";
import TraitsDisplay from "./TraitsDisplay";

const CatalogTraitsCardMarket = ({ listingStatus }) => {
  let itemCollectionName;
  let itemTraits;
  let itemRarity;
  let itemEdition;
  let itemEditionMax;
  let itemTraitsSorted;
  let indexNftAlias

  // console.log(listingStatus)

  if (Object.keys(listingStatus.listingDetails).length > 0) {
    if (
      listingStatus.listingDetails.nftDetail &&
      listingStatus.listingDetails.nftDetail.traits
    ) {
      itemTraits = listingStatus.listingDetails.nftDetail.traits;
      itemTraitsSorted = Object.values(itemTraits).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      // console.log(itemTraitsSorted);
    }

    if (listingStatus?.listingDetails?.nftDetail?.collection) {
      itemCollectionName =
        listingStatus.listingDetails.nftDetail.collection.name;
    }
  }

  if (listingStatus.itemEdition) {
    itemEdition = listingStatus.itemEdition;
    if (listingStatus.itemEditionMax) {
      itemEditionMax = listingStatus.itemEditionMax;
    }
  }

  if (Object.keys(listingStatus.indexHit).length > 0) {
    if (listingStatus.indexHit.nft_rarity) {
      itemRarity = listingStatus.indexHit.nft_rarity;
    }

    if (listingStatus.indexHit.nft_alias){
      indexNftAlias = listingStatus.indexHit.nft_alias
    }
  }

  return (
    <Card id="nft-detail-details-card" className="nft-details-card">
      <Card.Body className="px-0">
        {itemEdition && (
          <div className="flex-row pb-4 align-items-center">
            <i
              style={{ fontSize: "30px" }}
              className="fa-solid fa-newspaper mx-1 secColor"
            ></i>
            &nbsp; &nbsp;
            <span className="fw-bold small"> Edition:&nbsp;&nbsp;</span>
            <span className="secColor small fw-bold">
              {itemEdition}
              {itemEditionMax && <>&nbsp;/&nbsp;{itemEditionMax}</>}
            </span>
          </div>
        )}

        {itemRarity && (
          <div className="flex-row pb-4 align-items-center">
            <i
              style={{ fontSize: "30px" }}
              className="fa-solid fa-gem mx-1 secColor"
            ></i>
            &nbsp; &nbsp;{" "}
            <span className="fw-bold small"> Rarity:&nbsp;&nbsp;</span>
            <span className="secColor small">{UppercaseFirstLetter(itemRarity)}</span>
          </div>
        )}
        {itemTraits && Object.keys(itemTraits).length > 0 && (
          <>
            <div className="flex-row pb-4 align-items-center">
              <i
                style={{ fontSize: "30px" }}
                className="fas fa-star mx-1 secColor"
              />
              &nbsp;&nbsp;<span className="fw-bold small">Traits:&nbsp;</span>
            </div>
            {itemCollectionName &&
              <Row className="pb-3 propRow">
                <TraitsDisplay traitsList={itemTraitsSorted} itemCatalogName={itemCollectionName}/>
              </Row>
            }
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default CatalogTraitsCardMarket;
