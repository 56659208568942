import { useEffect, useState } from "react";
import toast, { useToasterStore } from "react-hot-toast";

const useToastTxStatus = () => {
  const [latestToast, setLatestToast] = useState();
  const [txStatus, setTxStatus] = useState("no tx");
  const [message, setMessage] = useState();
  const toastState = useToasterStore();
  // console.log(toastState);

  useEffect(() => {
    if (toastState.toasts.length > 0) {
      setLatestToast(toastState.toasts[0].id);
    } else {
      setLatestToast("notoast");
    }
  }, []);

  useEffect(() => {
    if (latestToast) {
      // console.log("Effect sees a toast");
      if (toastState.toasts.length > 0) {
        // console.log("The toast is valid");
        // console.log(latestToast);
        if (
          toastState.toasts[0].id !== latestToast ||
          latestToast === "notoast"
        ) {
          // console.log("The toast is new");
            setTxStatus(toastState.toasts[0].type);
            setMessage(toastState.toasts[0].message)
            // console.log("The toast was " +  toastState.toasts[0].type);
          
        }
      }
    }
  }, [toastState]);



  return {
    status: txStatus,
    message: message

  }
};

export default useToastTxStatus;
