import React from "react";
import { Col, Row } from "react-bootstrap";
import "./createprofile.scss";

const CreateProfileStages = ({ currentStage }) => {
  return (
    <div id="create-profile-stages-wrapper">
      
      <Row className="py-2">
        <Col xs="2">
          <img src="assets/img/profile/createprofile/step-icon-done.svg" />
        </Col>
        <Col className="stage-text-col">
          <Row>
            <Col>
              <span className={"stage-title-span-completed"}>
                Sign in with your wallet
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="stage-desc-span-completed">
                Successfully signed into wallet
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="py-2">
        <Col xs="2">
          <img
            src={
              currentStage === 2
                ? "assets/img/profile/createprofile/step-icon-current.svg"
                : "assets/img/profile/createprofile/step-icon-done.svg"
            }
          />
        </Col>
        <Col className="stage-text-col">
          <Row>
            <Col>
              <span
                className={
                  currentStage === 2
                    ? "stage-title-span-active"
                    : currentStage > 2
                    ? "stage-title-span-completed"
                    : "stage-title-span"
                }
              >
                Create a free profile
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span
                className={
                  currentStage < 3
                    ? "stage-desc-span"
                    : "stage-desc-span-completed"
                }
              >
                Enter a free profile name
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="py-2">
        <Col xs="2">
          <img
            src={
              currentStage === 2
                ? "assets/img/profile/createprofile/step-icon-base.svg"
                : currentStage === 3
                ? "assets/img/profile/createprofile/step-icon-current.svg"
                : "assets/img/profile/createprofile/step-icon-done.svg"
            }
          />
        </Col>
        <Col className="stage-text-col">
          <Row>
            <Col>
              <span
                className={
                  currentStage === 3
                    ? "stage-title-span-active"
                    : currentStage > 3
                    ? "stage-title-span-completed"
                    : "stage-title-span"
                }
              >
                Register a .find name
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span
                className={
                  currentStage < 4
                    ? "stage-desc-span"
                    : "stage-desc-span-completed"
                }
              >
                You can register a .find name
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="py-2">
        <Col xs="2">
          <img
            src={
              currentStage === 2 || currentStage === 3
                ? "assets/img/profile/createprofile/step-icon-base.svg"
                : currentStage === 4
                ? "assets/img/profile/createprofile/step-icon-current.svg"
                : "assets/img/profile/createprofile/step-icon-done.svg"
            }
          />
        </Col>
        <Col className="stage-text-col">
          <Row>
            <Col>
              <span
                className={
                  currentStage === 4
                    ? "stage-title-span-active"
                    : "stage-title-span"
                }
              >
                Explore .find
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="stage-desc-span">Go and explore .find</span>
            </Col>
          </Row>
        </Col>
      </Row>

    </div>
  );
};

export default CreateProfileStages;
