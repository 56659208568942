const signalR = require("@microsoft/signalr");
const axios = require("axios");

export default function GraffleSDK() {

  let negotiateResult;
  const projectID = process.env.REACT_APP_GRAFFLE_PROJECT_ID
  const negotiate = async () => {

    const authHeader = {
        "graffle-api-key": process.env.REACT_APP_GRAFFLE_API_KEY
    }
    const url = process.env.REACT_APP_GRAFFLE_API_URL

    negotiateResult = await axios.post(url, {}, { headers: authHeader });
  };

  this.stream = async (streamCallback) => {
    await negotiate();
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(negotiateResult.data.url, {
        accessTokenFactory: () => negotiateResult.data.accessToken,
      })
      .withAutomaticReconnect()
      ///.configureLogging("Debug")
      .build();

    if (connection) {
      //connection.serverTimeoutInMilliseconds(120000)
      //connection.keepAliveIntervalInMilliseconds(10000)
      connection.start()
      .then((result) => {
          ////console.log("1st Parse: "+projectID)
        connection.on(projectID, (message) => {
          var parsedMessage = JSON.parse(message);
          ////console.log("Parsing Message for: "+projectID)
          streamCallback(parsedMessage);
          
        });
      });
    }
    return connection;
  };
}

// *** Example implementation ***
// let conn = useRef();
//     useEffect(() => {
//         const streamSDK = new GraffleSDK();
//         const feed = async (message) => {
//             if (get(message, "flowEventId") === "A.097bafa4e0b48eef.FIND.FungibleTokenSent" && get(message, "blockEventData.tag") === "may4thcharity") {
//                 addDonation(message.blockEventData.fromName ? message.blockEventData.fromName : message.blockEventData.from, message.blockEventData.message, message.blockEventData.type === "A.3c5959b568896393.FUSD.Vault" ? parseFloat(message.blockEventData.amount).toFixed(2) + " FUSD" : parseFloat(message.blockEventData.amount).toFixed(2) + " FLOW", message.eventDate)
//             }
//             if (get(message, "flowEventId") === "A.097bafa4e0b48eef.FIND.AuctionBid") {
//                 setNewBid(message)

//             }

//             //setLatestMessage(message);
//             //console.log(message)
//         };
//         ////console.log("Creating the stream")
//         async function startConn() {
//             conn.current = await streamSDK.stream(feed);
//         }
//         startConn()
//     }, []);
//     useEffect(() => () => {
//         ////console.log("Stopping the connection")
//         conn.current.stop()
//     }, []);