import React from "react";

export default function DropPageMintBoxSplash(){
  return (
    <div className="drop-page-mint-box-splash-wrapper">
      <div>
        <span className="drop-page-mint-box-splash-title-span">
          Art Collaboration
        </span>
      </div>

      <p className="drop-page-mint-box-splash-desc-span">
        By owning a Party Favorz NFT, you are granted access to the VIP sections
        of our virtual parties which include, but are not limited to major
        giveaways, 1 on 1s with artists/project leaders, and some IRL utility
        that involves partying, down the line. By owning Party Favorz, you are
        supporting the idea of community coming together for a few goals that
        include having fun, being positive, learning, and most importantly
        SUPPORTING ARTISTS.
      </p>
    </div>
  );
};
