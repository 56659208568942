import { Col, Row, Card, Image } from "react-bootstrap";

import DapperSticker from "../../ftstickers/DapperSticker";
import { ListForSale } from "./listforsale/listforsale";
import { UseThemeStatus } from "../../../../functions/themeMode";
import { useWalletType } from "../../../../functions/useWalletType";
import { useUserProfile } from "../../../../functions/useUserProfile";
import React from "react";
import { Link } from "react-router-dom";
import { toLocalNumber } from "../../../../functions/format";

function AvailableOnFind(props) {
  const { details } = props;

  const amount = toLocalNumber(details);
  const to = `/mp/FIND/${details.leaseName}`

  const className = "align-items-between justify-content-center";
  const cardStyle = { backgroundColor: "var(--background-profile)" };
  const imgStyle = {
    height: "40px",
    width: "65px",
    borderRadius: "12px",
  };
  const linkArrow =
    UseThemeStatus() === "light"
      ? "/assets/img/profile/linkToLight.svg"
      : "/assets/img/profile/linkToDark.svg";
  const linkArrowStyle = {
    borderRadius: "0px",
    height: "25px",
    width: "25px",
    transform: "translateY(-5px)"
  }

  return (
    <Row style={{ height: "100px" }} className="mx-0 px-0">
      <Card className={className} style={cardStyle}>
        <Row className="align-items-center justify-content-center p-2">
          <Col xs="7" md="8">
            <Row className="align-items-center justify-content-center py-2">
              <Image
                className="d-none d-md-block"
                style={imgStyle}
                src="/assets/img/store/fnamestore.png"
              />

              <Col className="px-2">
                <h4 className="my-0">.find Market</h4>
                <Col style={{ color: "var(--text-secondary" }}>Sale</Col>
              </Col>
            </Row>
          </Col>

          <Col className="mb-0 fw-bold d-flex align-items-center justify-content-center">
            <h3 className="my-0">${amount}</h3>
          </Col>

          <Col className="d-flex w-auto align-items-center justify-content-center">
            <Link to={to}>
              <Image
                style={linkArrowStyle}
                src={linkArrow}
              />
            </Link>
          </Col>
        </Row>
      </Card>
    </Row>
  );
}

export default function ListNameDapperWallet(props) {
  const { lease, listingDetails, closeModal } = props;
  const walletType = useWalletType();
  const userProfile = useUserProfile();

  return (
    <div id="list-name" data-theme={UseThemeStatus()} className="form-inputs">
      {listingDetails ? (
        <>
          <Col className="pb-2 fw-bold mx-0">Listed for Sale:</Col>
          <AvailableOnFind details={listingDetails} />
        </>
      ) : (
        <>
          <Row className="pt-3 align-items-center">
            <Col className="fw-bold">List for Sale:</Col>
            <Col>
              <DapperSticker />
            </Col>
          </Row>
          <Row className="my-4">
            <ListForSale
              lease={lease.name}
              listingDetails={listingDetails}
              walletType={walletType}
              user={userProfile}
              validUntil={lease.validUntil}
              close={closeModal}
            />
          </Row>
        </>
      )}
    </div>
  );
}
