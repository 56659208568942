import { Form } from 'react-bootstrap';
import { useMenu } from 'react-instantsearch-hooks-web';
import { useLocation } from 'react-router';
import { itemName } from '../../pages/ImrpovedMarketplace/Doodles/components/utils';

export default function CustomCurrencyRefinement(props) {
    const {
        items,
        refine,
    } = useMenu({
        attribute: "amount_alias",
        sortBy: ['name:asc']
    });

  return <>
            <Form.Select onChange={(event) => {
                event.preventDefault();
                refine(event.currentTarget.value);
            }}>
                <option label='Currency'/>
                {items.map((item, i) => {
                    return (
                        <option
                        key={i}
                        value={item.value}
                        label={itemName(item.value)}
                        onChange={e => refine(e.target.value)}
                      />                    )
                })}
            </Form.Select>
  </>;
}