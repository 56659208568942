import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./navhead.css";
import { BoxArrowUpRight } from "react-bootstrap-icons";

const NavheadLinks = () => {
  return (
    <Nav className="me-auto pt-lg-0 d-none d-lg-flex" id="mainLinks">
      <Nav.Link as={Link} to={"/lf"} className="ms-lg-3">
        Live Feed
      </Nav.Link>
      <Nav.Link as={Link} to={"/mp"} className="ms-lg-3">
        Marketplace
      </Nav.Link>
         {/* Explore gone til redesign/build - Linear 789 */}

      {/* <Nav.Link as={Link} to={"/p/explore"} className="ms-lg-3">
        Explore
      </Nav.Link> */}

      <Nav.Link
        //<Nav.Link> is default
        //active false as external link does not deactivate
        active={false}
        href={"https://forms.gle/tHRvdELPrEBAVdkP9"}
        className="ms-lg-3"
        target="_blank"
        rel="noreferrer"
      >
        Launchpad <BoxArrowUpRight className="mx-1 mb-1" />
      </Nav.Link>
      <Nav.Link as={Link} to={"/afterfuture/pack/1"} className="ms-lg-3">
        🚀 Current Drops
      </Nav.Link>
    </Nav>
  );
};

export default NavheadLinks;
