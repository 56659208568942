import { Button, Form, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useFormStatus } from "../../../../../functions/DisabledState";
import { handleListNameForAuction } from "../../../../../functions/flow-interactions/transactions";
import toast from "react-hot-toast";

export function ListForAuction({ lease, walletType, user }) {
  const [startPrice, setPrice] = useState(null);
  const [reservePrice, setReserve] = useState(null);
  const [duration, setDuration] = useState(null);

  // async function handleStartAuction(e) {
  //     if (duration === "Select duration" || !duration || !startPrice || startPrice < 1 || !reservePrice || reservePrice < 1) {
  //         if (!startPrice) {
  //             document.getElementById("startPrice").classList.add("is-invalid")
  //             document.getElementById("startPrice").classList.remove("is-valid")
  //         }
  //         if (!reservePrice) {
  //             document.getElementById("reservePrice").classList.add("is-invalid")
  //             document.getElementById("reservePrice").classList.remove("is-valid")
  //         }
  //         if (duration === "Select duration" || !duration) {
  //             document.getElementById("selectDuration").classList.add("is-invalid")
  //             document.getElementById("selectDuration").classList.remove("is-valid")
  //         }
  //         e.preventDefault();
  //         e.stopPropagation();
  //         return
  //     }
  //     if (startPrice * 1 > reservePrice * 1) {
  //         document.getElementById("reservePrice").classList.add("is-invalid")
  //         document.getElementById("reservePrice").classList.remove("is-valid")
  //         e.preventDefault();
  //         e.stopPropagation();
  //         return
  //     }
  //     try {
  //         await Tx(
  //             [
  //                 fcl.transaction(transactions.listForAuction),
  //                 fcl.args([
  //                     fcl.arg(lease, t.String),
  //                     fcl.arg(parseFloat(startPrice).toFixed(2), t.UFix64),
  //                     fcl.arg(parseFloat(reservePrice).toFixed(2), t.UFix64),
  //                     fcl.arg(parseFloat(duration).toFixed(2), t.UFix64),
  //                     fcl.arg("300.0", t.UFix64),
  //                 ]),
  //                 fcl.proposer(fcl.currentUser().authorization),
  //                 fcl.payer(fcl.currentUser().authorization),
  //                 fcl.authorizations([fcl.currentUser().authorization]),
  //                 fcl.limit(9999),
  //             ],
  //             {
  //                 onStart() {
  //                     //console.log("start");
  //                 },
  //                 onSubmission() {
  //                     //console.log("submitted");
  //                 },
  //                 async onSuccess(status) {
  //                     //console.log("success");
  //                     const event = document.createEvent("Event");
  //                     event.initEvent("bid", true, true);
  //                     document.dispatchEvent(event);
  //                 },
  //                 async onError(error) {
  //                     if (error) {
  //                         const { message } = error;
  //                         //console.log(message);
  //                     }
  //                 },
  //             }
  //         );
  //     } catch (e) {
  //         //console.log(e);
  //     }
  // }

  async function handleStartAuction(e) {
      // const durationInDays = (duration/86400)
    if (
      duration === "Select duration" ||
      !duration ||
      !startPrice ||
      startPrice < 1 ||
      !reservePrice ||
      reservePrice < 1
    ) {
      if (!startPrice || startPrice < 1) {
        document.getElementById("startPrice").classList.add("is-invalid");
        document.getElementById("startPrice").classList.remove("is-valid");
        toast.error("Start price needs to be 1 or higher");
        return;
      }
      if (!reservePrice || reservePrice < 1) {
        document.getElementById("reservePrice").classList.add("is-invalid");
        document.getElementById("reservePrice").classList.remove("is-valid");
        toast.error("Reserve price needs to be 1 or higher");
        return;
      }
      if (duration === "Select duration" || !duration) {
        document.getElementById("selectDuration").classList.add("is-invalid");
        document.getElementById("selectDuration").classList.remove("is-valid");
        toast.error("Please select a duration");
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (startPrice * 1 > reservePrice * 1) {
      document.getElementById("reservePrice").classList.add("is-invalid");
      document.getElementById("reservePrice").classList.remove("is-valid");
      toast.error(`Reserve price needs to be ${startPrice} or higher`);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    handleListNameForAuction(lease, startPrice, reservePrice, duration, walletType)

  }

  return (
    <Form onSubmit={handleStartAuction} noValidate>
      <fieldset disabled={useFormStatus()}>
        <Row>
          <Col>
            <small className="fw-bold">
              The countdown timer will not start until someone makes their first
              bid at the starting price or higher.
            </small>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="12" md="6">
            <Form.Label>Start Price:</Form.Label>
            <Form.Control
              id="startPrice"
              placeholder="Enter the amount in FLOW"
              type="number"
              min="0"
              required
              onChange={(e) => {
                setPrice(e.target.value);
                if (e.target.value * 1 < 1) {
                  e.target.classList.add("is-invalid");
                  e.target.classList.remove("is-valid");
                } else {
                  e.target.classList.add("is-valid");
                  e.target.classList.remove("is-invalid");
                }
              }}
            ></Form.Control>
          </Col>
          <Col className="" xs="12" md="6">
            <Form.Label>Reserve Price:</Form.Label>
            <Form.Control
              id="reservePrice"
              placeholder="Enter the amount in FLOW"
              type="number"
              required
              min="0"
              onChange={(e) => {
                setReserve(e.target.value);
                if (startPrice * 1 > e.target.value * 1) {
                  e.target.classList.add("is-invalid");
                  e.target.classList.remove("is-valid");
                } else {
                  e.target.classList.add("is-valid");
                  e.target.classList.remove("is-invalid");
                }
              }}
            ></Form.Control>
          </Col>
          <Col xs="12" md="6" className="mt-3">
            <Form.Select
              id="selectDuration"
              aria-label="Duration Select"
              onChange={(e) => {
                if (!e.target.value || e.target.value === "Select duration") {
                  e.target.classList.add("is-invalid");
                  e.target.classList.remove("is-valid");
                } else {
                  e.target.classList.add("is-valid");
                  e.target.classList.remove("is-invalid");
                }
                setDuration(e.target.value);
              }}
            >
              <option>Select duration</option>
              <option value="86400">1 Day</option>
              <option value="259200">3 Days</option>
              <option value="345600">4 Days</option>
              <option value="604800">7 Days</option>
            </Form.Select>
          </Col>
          <Col xs='12' md="6" align="center" className="mt-3 mt-md-auto ms-auto">
            <Button className="w-100"
              variant="find-outline-commerce-dark"
              onClick={handleStartAuction}
            >
              List name
            </Button>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}
