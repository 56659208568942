import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const EmptyWalletPlaceholder = () => {
  return (
    <Row className='justify-content-center'>
    <Col align='center' className='px-5 pt-4'>
      <Image
        className=''
        src='/assets/img/profile/collection/no-nfts.webp'
        fluid={+true}
      ></Image>
      <div className='my-3'>
        <h4>
          Uh oh, there are currently no NFTs in this collection.
        </h4>
      </div>
      <div className="mb-5">
        Some NFTs aren’t supported yet. Until then, why not check
        out the{" "}
        <Link className="themeColor" to={"/mp/"}>
          marketplace.
        </Link>
      </div>
    </Col>
  </Row>


  )
}

export default EmptyWalletPlaceholder