import axios from "axios";

import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import DetailsItemHistoryCard from "./detailsitemhistorycard";
import NFTDetails from "../../../../NFTHistoryMk2";

const DetailsItemHistory = ({ listingStatus }) => {
  const collection = listingStatus?.listingDetails?.nftDetail?.type
    .split(".")
    .slice(0, 3)
    .join(".");
  const id = listingStatus?.listingDetails?.nftDetail?.id;

  return <NFTDetails collection={collection} id={id} />;
};

export default DetailsItemHistory;
