export const parseCollectionData = (collectionData) => {
  // console.log(collectionData);
  const storagePath = `/${collectionData.storagePath.domain}/${collectionData.storagePath.identifier}`;
  const publicPath = `/${collectionData.publicPath.domain}/${collectionData.publicPath.identifier}`;

  const contracts = {};

  const typeID = collectionData.publicLinkedType.type.type.typeID ? collectionData.publicLinkedType.type.type.typeID : collectionData.publicLinkedType.type.typeID;
  const [, address, name, interf] = typeID.split(".");
  // console.log(interf)
  contracts[name] = address;
  const type = `${name}.${interf}`;

  const restrictions = collectionData.publicLinkedType.type.restrictions ? collectionData.publicLinkedType.type.restrictions : collectionData.publicLinkedType.restrictions;
  const interfacesArr = [];

  for (let i = 0; i < restrictions.length; i++) {
    const r = restrictions[i].typeID;
    const [, address, name, interf] = r.split(".");
    contracts[name] = address;
    interfacesArr.push(`${name}.${interf}`);
  }

  let imports = ``;
  for (const [name, address] of Object.entries(contracts)) {
    imports = imports.concat(`import ${name} from 0x${address}\n`);
  }

  const interfaces = interfacesArr.join(", ");
  return {
    storagePath: storagePath,
    publicPath: publicPath,
    interfaces: interfaces,
    imports: imports,
    collectionType: type,
    contracts: contracts,
  };
};


export const doBulkSetupAccount = (metadataArr) => {
  //input array
  //generates unique inputs
  // console.log(metadataArr)

  //console.log(metadataArr);

  let imports = "";

  let code = ``;
  let allContracts = {};

  for (let i = 0; i < metadataArr.length; i++) {
    const metadata = metadataArr[i];
    //console.log(metadata);

    const contractName = Object.keys(metadata)[0].split('.')[2];
    const collectionData = Object.values(metadata)[0];
    //console.log(collectionData);

    const { storagePath, publicPath, interfaces, collectionType, contracts } =
      parseCollectionData(collectionData);
      //console.log(collectionType);

    //console.log(contracts);

    const body = `
      if account.borrow<&NonFungibleToken.Collection>(from: ${storagePath}) == nil {
          account.save(<- ${contractName}.createEmptyCollection(), to: ${storagePath})
          account.link<&${collectionType}{${interfaces}}>(${publicPath}, target: ${storagePath})
      }
      if account.borrow<&NonFungibleToken.Collection>(from: ${storagePath}) != nil && !account.getCapability<&${collectionType}{${interfaces}}>(${publicPath}).check() {
          account.unlink(${publicPath})
          account.link<&${collectionType}{${interfaces}}>(${publicPath}, target: ${storagePath})
      }
      `;

    code = code.concat(body);

    for (const [name, address] of Object.entries(contracts)) {
      //ignore NFT + metadata views
      if (
        name === "NonFungibleToken" ||
        name === "MetadataViews" ||
        name === "FindViews"
      ) {
        continue;
      }

      allContracts[name] = address;
      imports += `import ${name} from 0x${address}\n`;
    }
    // console.log(allContracts);
  }
  return { ["//IMPORT"]: imports, ["//LINK"]: code };
};
