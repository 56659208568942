import { Row, Column } from "../components";
import ProjectStatus from "../ProjectStatus";
import { IconStack, Verified } from "../Icon";

import "../components/index.scss";
import "./index.scss";

export default function ProjectTitle(props) {
  const { verified = false } = props;
  const { name = "Untitled", title = "Unknown Project" } = props;
  const { links = {} } = props;
  const { startsAt } = props;

  return (
    <Column className={"dp__project-title"}>
      <Row className={"dp__space-between dp__mb-1"}>
        <Row className={"dp__gap"}>
          <Verified show={verified} />
          <span className={"dp__project-name"}>{name}</span>
        </Row>
        <IconStack links={links} />
      </Row>
      <h1 className={"dp__title"}>{title}</h1>
      <ProjectStatus startsAt={startsAt} />
    </Column>
  );
}
