import { capitalizeTrait } from "./utils";

export default function RenderTrait(props) {
  const { trait } = props;
  const { shortName, value, displayType } = trait;
  let formatted = value.toString();
  const isLongValue = formatted.startsWith("0x") || formatted.length > 10

  if(displayType === "Date"){
    let timestamp = parseInt(value)
    if(timestamp / Math.pow(10,9) < 5){
      timestamp = timestamp * 1000
    }
    formatted = new Date(timestamp).toLocaleDateString()
  }

  if(displayType === "Numeric"){
    formatted = value * 1
  }

  return (
    <section className={"trait"}>
      <span>{capitalizeTrait(shortName)}</span>
      <p className={`value ${isLongValue && "long-value"}`} title={value}>
        {formatted}
      </p>
    </section>
  );
}
