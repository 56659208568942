import React from "react";
import { Link, useParams } from "react-router-dom";
import './ColDetailBackToCol.css'

const ColDetailBackToCol = ({ listingStatus, colName, profile, walletProvider }) => {
  //useparams me does not register as profile hence this
  profile ? (profile = profile) : (profile = "me");

  return (
    <Link to={`/${profile}/collection/${walletProvider}/${colName}`}>
      <div  className="flex-row secColor small">
        <i className="fa fa-arrow-left " aria-hidden="true" />
        &nbsp;&nbsp;
        <span id='back-to-col-span'>Back to collection</span>{" "}
      </div>
    </Link>
  );
};

export default ColDetailBackToCol;
