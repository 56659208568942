import React from "react";
import { NamesForAuction } from "../namesforauction/namesforauction";
import { NamesForSale } from "../namesforsale/namesforsale";
import NftsForSale from "../nftsforsale/nftsforsale";

// TODO: fix leases for Auction
const ProfileStore = (props) => {
  const { type } = props;
  const { itemsForSale, leasesForSale, leases, profile } = props.profile;

  return (
    <>
      <NamesForSale leases={leasesForSale} type={type} profile={profile} />
      <NamesForAuction leases={leases} type={type} />
      <NftsForSale itemsForSale={itemsForSale} type={type} />
    </>
  );
};

export default ProfileStore;
