import { Col, Container, Image, Row } from "react-bootstrap";
import './whointegrates.css'

export function WhoIntegrates() {
    return(
        <Container id="who-integrates" className="my-lg-5 my-3 py-lg-5 ">
            <Row>
                <Col align="center">
                <h3 className="my-3">Who integrates with .find?</h3>
                <Row className="d-flex justify-content-center my-5">
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/versus.jpg" alt="Versus flow auction site logo" width="64px"/></div><div className="pt-2 integrate-label">Versus</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/flovatar.jpeg" alt="Flovatars logo" width="64px" /></div><div className="pt-2 integrate-label">Flovatar</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/bl0x.jpg" alt="Schwap logo" width="64px" /></div><div className="pt-2 integrate-label">Bl0x</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/neo.jpeg" alt="Neo Collectibles logo" width="64px" /></div><div className="pt-2 integrate-label">Neo</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/schwap.png" alt="Schwap logo" width="64px" /></div><div className="pt-2 integrate-label">Schwap</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/flow-scan.jpg" alt="Schwap logo" width="64px" /></div><div className="pt-2 integrate-label">Flowscan</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/float.png" alt="Schwap logo" width="64px" /></div><div className="pt-2 integrate-label">FLOAT</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/day-nft.jpeg" alt="Schwap logo" width="64px" /></div><div className="pt-2 integrate-label">DayNFT</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/bitku.jpg" alt="Schwap logo" width="64px" /></div><div className="pt-2 integrate-label">Bitku</div></Col>
                    <Col xs='auto' className="px-4"><div className='integrate-image-wrap'><Image fluid={+true} src="/assets/img/home/viv3.jpg" alt="Schwap logo" width="64px" /></div><div className="pt-2 integrate-label">Viv3</div></Col>


                </Row>
                </Col>
            </Row>
        </Container>
    )
}