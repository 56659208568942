import {
  getEvent,
} from "../../../../utils";
import {
  FindAddress,
  TransferTitle,
  WildGuess,
} from "../../../components";

export const BatchMinted = (props) => {
  const { transfer } = props;
  const { signers } = transfer;
  const minter = signers[0];
  return (
    <section>
      <header>
        <TransferTitle>🍀 Minted in a Batch</TransferTitle>
        <FindAddress prefix={"Minted by:"} address={minter} />
      </header>
    </section>
  );
};
export const GaiaClaimAirdrop = (props) => {
  const { args } = getEvent(props, "Deposit");
  const { to } = args;
  return (
    <section>
      <header>
        <TransferTitle>🚀 Gaia Airdropped</TransferTitle>
        <FindAddress prefix="Catched by:" address={to} />
        <WildGuess />
      </header>
    </section>
  );
};

export const BatchMove = (props) => {
  const { args } = getEvent(props, "Deposit");
  const { to } = args;
  return (
    <section>
      <header>
        <TransferTitle>📦 Packed and Stashed</TransferTitle>
        <FindAddress address={to} prefix={"Stored at:"} />
        <WildGuess />
      </header>
    </section>
  );
};
