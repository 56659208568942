import React from "react";
import ColDetailOwnItemCatalogOptions from "./ColDetailOwnItemCatalogOptions";
import ColDetailOwnItemMakePfp from "./ColDetailOwnItemMakePfp";
import ColDetailPackOpen from "./ColDetailPackOpen";

const ColDetailOwnItem = ({
  listingStatus,
  colName,
  setOpenPackClicked,
  openPackClicked,
  showSalesModal,
  setShowSalesModal,
}) => {
  const isPackCollection =
    colName.toLowerCase().includes("bl0xpack") ||
    colName.includes("FindPack")

  return (
    <>
      {listingStatus.aCatalogItem && (
        <ColDetailOwnItemCatalogOptions
          showSalesModal={showSalesModal}
          setShowSalesModal={setShowSalesModal}
          listingStatus={listingStatus}
        />
      )}
      {isPackCollection && (
        <ColDetailPackOpen
          listingStatus={listingStatus}
          colName={colName}
          setOpenPackClicked={setOpenPackClicked}
          openPackClicked={openPackClicked}
        />
      )}
      <ColDetailOwnItemMakePfp listingStatus={listingStatus} />
    </>
  );
};

export default ColDetailOwnItem;
