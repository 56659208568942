import React from "react";
import { Row } from "react-bootstrap";
import CustomHitsInfinite from "../search/CustomHitsInfinite";
import CustomCharityAuctionHits from "./CustomCharityAuctionHits";

const CharityAuctionItems = () => {
  return (
    <Row>
      <CustomCharityAuctionHits />
    </Row>
  );
};

export default CharityAuctionItems;
