import { SPORT } from "../TemplateFilter/groups";

export const UNIQUE = "0-Unique";
export const EPIC = "1-Epic";
export const RARE = "2-Rare";
export const COMMON = "3-Colored";

// prettier-ignore
const groups = {
	// Unique Traits
	"universe": UNIQUE,
		"holographic": UNIQUE,
		"gold": UNIQUE,

		// Epic Traits
		"rainbowpuke splatter": EPIC,
		"skully": EPIC,
		"lennon": EPIC,
		"hoop": EPIC,
		"stud": EPIC,

		// Rare Traits
		"multi-colored": RARE,
		"rainbow": RARE,
		"bubblegum": RARE,

	// The rest items are Common
}

export default {
  organizer: (item) => {
    const { label } = item;
    const groupValue = groups[item.label];

    switch (true) {
			// items inside groups
			case groupValue !== undefined:
				return groupValue;

      case label.toLowerCase().includes("athlet"):
        return SPORT;
      default:
        return COMMON;
    }
  },
};
