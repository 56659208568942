import { useState } from "react";
import { useFormStatus } from "../../../../../functions/DisabledState";
import { Button, Form, Row, Col } from "react-bootstrap";
import { handleListForSale } from "../../../../../functions/flow-interactions/transactions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export function ListForSale(props) {
  const { lease, walletType, validUntil, user, close } = props;
  const [amount, setAmount] = useState(null);
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate("/me/names");
    close();
  };

  function updateField(e) {
    const varVal = e.target.value;
    //now validate
    if (varVal < 1) {
      e.target.classList.add("is-invalid");
      e.target.classList.remove("is-valid");
    } else {
      e.target.classList.add("is-valid");
      e.target.classList.remove("is-invalid");
    }
    setAmount(varVal);
  }

  const submitListForSale = (e) => {
    e.preventDefault();

    if (amount < 1) {
      toast.error("Please enter an amount of 1 or higher");
    } else {
      const params = {
        lease,
        amount,
        validUntil,
        userAddress: user.profile.address,
      };
      handleListForSale(params, goToProfile, walletType);
    }
  };

  return (
    <Form onSubmit={submitListForSale} noValidate>
      <fieldset disabled={useFormStatus()}>
        <Row>
          <Col xs="12" md="6">
            <Form.Group>
              <Form.Label align="left">Sale Price:</Form.Label>
              <Form.Control
                id="bidPrice"
                className={"custom-input-list-name"}
                placeholder="Enter amount"
                type="number"
                onChange={updateField}
                required
                min="0"
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col
            xs="12"
            md="6"
            align="center"
            className="mt-3 mt-md-auto ms-auto"
          >
            <Button
              type="submit"
              className="w-100"
              variant="find-outline-commerce-dark"
            >
              List name
            </Button>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}
