import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import truncateItemName from "../../../functions/truncateItemName";

const RelatedAccounts = ({ allProfileData, runCopy }) => {
  const [relatedAccounts, setRelatedAccounts] = useState(null);

  useEffect(() => {
    if (allProfileData) {
      if (allProfileData.accounts && allProfileData.accounts.length > 0) {
        setRelatedAccounts(allProfileData.accounts);
      }
    }
  }, [allProfileData]);

  // console.log(allProfileData)

  if (relatedAccounts){
   return (
    <Row>
      <Col xs="5" lg="4">
        Related Accounts:
      </Col>
      <Col xs="6" lg="8">
        {relatedAccounts &&
          relatedAccounts.map((account, i) => {
            const renderTooltip = (props) => (
              <Tooltip {...props}>
                <span style={{ fontSize: "16px" }}>{account.name} - {account.address}</span>
              </Tooltip>
            );
            return (
              <Row className="mb-3">
                <Col className="d-flex align-items-center secColor">
                  {/* <span className="fw-bold">{account.name}:&nbsp;</span> */}

                  <OverlayTrigger placement="top" overlay={renderTooltip}>
                    <span className="truncate">{account.address}</span>
                  </OverlayTrigger>
                  <img
                    style={{ width: "15px", marginLeft: "10px" }}
                    src={
                      account.network === "Ethereum"
                        ? "/assets/img/NetworkLogos/eth-network.svg"
                        : "/assets/img/NetworkLogos/flow-network.svg"
                    }
                  />

                  <div
                    className="p-0"
                    style={{
                      fontSize: "16px",
                      cursor: "pointer",
                      color: "var(--text-secondary)",
                      // marginBottom: "1rem",
                      marginLeft: "10px",
                    }}
                    onClick={() => runCopy(account.address)}
                  >
                    <i className="copyicon fa fa-copy"></i>
                  </div>
                </Col>
              </Row>
            );
          })}
      </Col>
    </Row>
  )}
};

export default RelatedAccounts;
