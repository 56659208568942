import React, { useEffect, useState } from "react";
import {
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import ListedBoth from "../../../../forms/selling/listnft/ListedBoth";
import ListNftForSale from "../../../../forms/selling/listnft/listnftforsale";
import ListNftForAuction from "../../../../forms/selling/listnft/listnftforauction";
import "./ColDetailListItem.scss";
import { useWalletType } from "../../../../../functions/useWalletType";

const ColDetailListItem = (props) => {
  const { listingStatus, showSalesModal,setShowSalesModal } = props
  const [salesType, setSalesType] = useState("sale");
  const [forSale, setIsForSale] = useState(false);
  const [forAuction, setIsForAuction] = useState(false);
  const [forSaleAndAuction, setforSaleAndAuction] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  //const ftType = listingStatus.aDapperItem ? listingStatus.dapperAllowedListingActions.FindMarketSale.ftAlias : listingStatus?.FindMarketSale?.ftAlias
  const wallet = useWalletType();

  const isDapperWallet = wallet === "Dapper Wallet"
  const ftType = isDapperWallet
    ? listingStatus.dapperAllowedListingActions.FindMarketSale.ftAlias
    : listingStatus.allowedListingActions.FindMarketSale.ftAlias

  const canBeAuctioned = isDapperWallet
    ? listingStatus.dapperAllowedListingActions.FindMarketeAuctionSoft
    : listingStatus.allowedListingActions.FindMarketAuctionEscrow


  useEffect(() => {
    if (listingStatus.allCurrentListings.forSale.forSale) {
      setIsForSale(true);
      setSalesType("auction");
    }
    if (listingStatus.allCurrentListings.forAuction.forAuction) {
      setIsForAuction(true);
      setSalesType("sale");
    }
    if (
      listingStatus.allCurrentListings.forSale.forSale &&
      listingStatus.allCurrentListings.forAuction.forAuction
    ) {
      setforSaleAndAuction(true);
      setSalesType("none");
    }
  }, [listingStatus.allCurrentListings]);

  //royalty mouseover
  const renderTooltipRoyalties = (props) => (
    <Tooltip {...props}>
      <div id="royalty-mouseover-wrapper">
        <span>This item has the following {salesType} royalties:</span>
        {salesType === "auction"
          ?
          !ftType.includes("DUC") &&
          Object.keys(listingStatus.itemRoyalties.Auction).map(
            (royalty, i) => {
              return (
                <span key={i}>
                  {listingStatus.itemRoyalties.Auction[royalty].royaltyName}:{" "}
                  {listingStatus.itemRoyalties.Auction[royalty].cut * 100}%{" "}
                </span>
              );
            }
          )
          : Object.keys(listingStatus.itemRoyalties.Sale).map((royalty, i) => {
            return (
              <span key={i}>
                {listingStatus.itemRoyalties.Sale[royalty].royaltyName}:{" "}
                {listingStatus.itemRoyalties.Sale[royalty].cut * 100}%{" "}
              </span>
            );
          })}
      </div>
    </Tooltip>
  );

  return (
    <div id="col-detail-list-item">
      <div id="list-item-royalties">
        <OverlayTrigger placement="bottom" overlay={renderTooltipRoyalties}>
          <img src="/assets/img/profile/list-item/royalty-mouseover.svg" />
        </OverlayTrigger>
      </div>


      <div id="list-item-title">Sell or list {listingStatus.itemName}</div>
      <div id="list-item-img" className="flex-center">
        <img src={parseImgUrl(listingStatus.itemThumbnail)} />
      </div>
      {canBeAuctioned &&
        <div id="list-item-desc">
          List this NFT as a direct sale or though an auction.
        </div>}

      <div id='list-radio-wrapper'>
        {!forSale && (
          <div className="list-radio">
            <Form.Check
              type="radio"
              id="sale"
              label="List for sale"
              name="salestype"
              onChange={() => setSalesType("sale")}
              defaultChecked={salesType === "sale"}
            />
          </div>
        )}
        {canBeAuctioned &&
          !forAuction && (
            <div className="list-radio">
              {!forSale ? (
                <Form.Check
                  type="radio"
                  id="auction"
                  label="List for auction"
                  name="salestype"
                  onChange={() => setSalesType("auction")}
                />
              ) : (
                <Form.Check
                  type="radio"
                  id="auction"
                  label="List for auction"
                  name="salestype"
                  onChange={() => setSalesType("auction")}
                  defaultChecked
                />)}
            </div>
          )}</div>
      <div className="my-2">
        {/* <Col>{toggleSaleOption(salesType)}</Col> */}
        {salesType === "auction" ? (
          canBeAuctioned &&
          <ListNftForAuction
            listingStatus={listingStatus}
            showSalesModal={showSalesModal}
            setShowSalesModal={setShowSalesModal}
          />
        ) : salesType === "sale" ? (
          <ListNftForSale
            listingStatus={listingStatus}
            showSalesModal={showSalesModal}
            setShowSalesModal={setShowSalesModal}
          />
        ) : (
          <ListedBoth listingStatus={listingStatus} />
        )}
      </div>
    </div>
  );
};

export default ColDetailListItem;
