import { createContext, useContext, useEffect, useRef, useState } from "react";
import { gql, ApolloClient, InMemoryCache } from "@apollo/client";

export const apolloClient = new ApolloClient({
  uri: "https://query.flowgraph.co/?token=5a477c43abe4ded25f1e8cc778a34911134e0590",
  cache: new InMemoryCache(),
});

const queryNFTOwner = gql`
  query ($id: ID!, $collection: ID!) {
    nft(contractId: $collection, nftId: $id) {
      owner {
        address
      }
    }
  }
`;
const NFTResolverContext = createContext(null);

export const NFTResolverContainer = (props) => {
  const { children } = props;

  const stack = useRef([]);
  const requested = useRef({});
  const processing = useRef(false);
  const timeout = useRef(null);
  const sliceSize = 3;

  const [registry, setRegistry] = useState({});

  const lookup = async (collection, id) => {
    const key = `${collection}:${id}`;
    if (!registry[key] && !requested.current[key]) {
      const response = await apolloClient.query({
        query: queryNFTOwner,
        variables: {
          id,
          collection,
        },
      });
      requested.current[key] = true;

      let nftData = null;
      if (response.data !== null) {
        console.log(collection, id, response.data);
      }

      setRegistry({
        ...registry,
        [key]: nftData,
      });
    }
  };

  const value = { lookup, registry };
  return (
    <NFTResolverContext.Provider value={value}>
      {children}
    </NFTResolverContext.Provider>
  );
};

export const useNFTResolver = (collection, id) => {
  const { lookup, registry } = useContext(NFTResolverContext);

  useEffect(() => {
    lookup(collection, id);
  }, []);

  const key = `${collection}:id`;
  return registry[key] || "UNKNOWN NFT";
};
