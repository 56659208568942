import React, { useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import parseImgUrl from "../../functions/parseImgUrl";
import CustomImageThumb from "../CustomImage/CustomImageThumb";

const DropPageBannerBio = ({ packDetails }) => {
  const [bannerOpen, setBannerOpen] = useState();
  const [squareOpen, setSquareOpen] = useState();
  //here we need background / square from response script, here is dummy for now
  const headerName = "Party Favors X Afterfuture";
  const headerDescription = packDetails
    ? packDetails.collectionDisplay.description
    : null;
  const headerBackgroundUrl = packDetails
    ? `https://find.mypinata.cloud/ipfs/${packDetails.collectionDisplay.bannerImage.file.cid.replaceAll(
        " ",
        ""
      )}?&pinataGatewayToken=bB9fSKu7aVWjHnxPTv8iYGVlQOkWVl1aNM7sC9xtLQxu41FK7VHJybMhp5EOFmIH`
    : null;
  const squareImage = packDetails
    ? parseImgUrl(
        `https://find.mypinata.cloud/ipfs/${packDetails.collectionDisplay.bannerImage.file.cid.replaceAll(
          " ",
          ""
        )}`
      )
    : null;

  const headerLinks = packDetails
    ? packDetails.collectionDisplay.socials
    : null;


  return (
    <div className="drop-page-banner-bio-wrapper">
      <Col>
        <Card className="outer-wrapper">
          <Card.Body>
            <Row className="justify-content-sm-center justify-content-lg-start">
              <Col
                className="header-square-col ms-lg-2 d-flex justify-content-center"
                xs="12"
                lg="auto"
              >
                <img src={squareImage}/>
              </Col>
              <Col>
                <div className="header-name-padding d-none d-lg-block"></div>
                <Row>
                  <Col
                    xs="12"
                    lg="8"
                    xl="9"
                    xxl="10"
                    className="d-flex justify-content-center align-items-center justify-content-lg-start mt-3 mt-lg-0"
                  >
                    <h3>{headerName}</h3>
                  </Col>
                  <Col className="links-col" className="d-none d-lg-flex justify-content-end gap-2">
                    {headerLinks &&
                      Object.keys(headerLinks).map((link, i) => {
                        return (
                          <div key={i}>
                            <a
                              className="link-item"
                              href={headerLinks[link].url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className={"fa-brands fa-" + link} />
                            </a>
                          </div>
                        );
                      })}
                  </Col>
                </Row>

                <Row>
                  <Col className="justify-content-center text-center text-lg-start justify-content-lg-start px-3">
                    {headerDescription && (
                      <span className="collection-description">
                          {headerDescription}
                        </span>
                    )}

                    {/* {headerDescription &&
                        headerDescription.map((sectionSpan, i) => {
                          return (
                            <React.Fragment key={i}>
                              <span
                                className="collection-description"
                                className="drop-page-info-section-desc-span"
                              >
                                {sectionSpan}
                              </span>
                              <br />
                            </React.Fragment>
                          );
                        })} */}
                  </Col>
                </Row>
                <Row className="d-flex d-lg-none">
                  <Col
                    className="justify-content-center text-center text-lg-start justify-content-lg-start px-3"
                  >
                    {headerLinks &&
                      Object.keys(headerLinks).map((link, i) => {
                        return (
                          <div key={i}>
                            <a
                              className="link-item"
                              href={headerLinks[link].url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className={"fa-brands fa-" + link} />
                            </a>
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default DropPageBannerBio;
