import React from "react";
import "./CharityAuction.scss";

const CharityAuctionInfo = () => {
  const charityInfoLeft = [
    { name: "name", info: "this is the info", image: "img link" },
    { name: "name", info: "this is the info", image: "img link" },
    { name: "name", info: "this is the info", image: "img link" },
    { name: "name", info: "this is the info", image: "img link" },
  ];
  const charityInfoRight = [
    { name: "name", info: "this is the info", image: "img link" },
    { name: "name", info: "this is the info", image: "img link" },
    { name: "name", info: "this is the info", image: "img link" },
    { name: "name", info: "this is the info", image: "img link" },
  ];

  return (
    <div id="charity-auction-info-wrapper">
      <div id="charity-info-title-row">Title row</div>
      <div id="charity-auction-desc-row">
        <div id="charity-auction-desc-left-col">
          {charityInfoLeft.map((charity, i) => {
            return (
              <div id="charity-info-item">
                <div>{charity.image}</div>
                <div>{charity.info}</div>
              </div>
            );
          })}
        </div>
        <div id="charity-auction-desc-right-col">
          {charityInfoRight.map((charity, i) => {
            return (
              <div id="charity-info-item">
                <div>{charity.image}</div>
                <div>{charity.info}</div>
              </div>
            );
          })}
        </div>

        {/* {charityInfo.map((charity, i) => {
          return (
            <div id="charity-info-item">
              <div>{charity.image}</div>
              <div>{charity.info}</div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default CharityAuctionInfo;
