import { MARKETPLACE_ITEM_PURCHASED } from "../../../const";
import { FLOW_DEPOSITED_EVENT } from "../../../const";
import { splitPayments } from "../../../../utils";

const FC_PURCHASE_EVENT =
  "A.921ea449dffec68a.FlovatarMarketplace.FlovatarComponentPurchased";

const extractPurchase = (transfer) => {
  const { events } = transfer;
  const purchaseEvent = events.byId[FC_PURCHASE_EVENT][0];
  const flowEvents = events.byId[FLOW_DEPOSITED_EVENT];

  const { from, to, price } = purchaseEvent.args;
  const payments = splitPayments(flowEvents, price, 0.1);

  return {
    to,
    from,
    payments,
  };
};

export const extractor = (transfer, type) => {
  switch (type) {
    case MARKETPLACE_ITEM_PURCHASED: {
      return extractPurchase(transfer);
    }
  }
};
