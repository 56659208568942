import { atom, useAtom } from "react-atomic-state";

const queryStatus = atom(localStorage.getItem("query") ? localStorage.getItem("query") :  null);

export const refineQuery = (query) => {
    localStorage.setItem("query", query)
    queryStatus.set(query)
    // console.log(query)

}

export const UseRefineQuery = () => useAtom(queryStatus);

