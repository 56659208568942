import * as tx from "../../const";

const platform = "Swap";
const eventTypes = {
  proposalExecuted: "A.9066631feda9e518.Swap.ProposalExecuted",
};

export default function SwapPlugin(context, transfer, idRelated) {
  const { hash, events } = transfer;

  if (hash === "____________________MjM1ODMwOTYx") {
    const proposalEvents = events.byId[eventTypes.proposalExecuted];

    if (proposalEvents) {
      const event = proposalEvents[0];
      const { proposal } = event.args;
      const to = proposal.value.fields[3].value.value;
      const from = proposal.value.fields[5].value.value;
      return {
        type: tx.SWAPPED,
        platform,
        to,
        from,
      };
    }
    return {
      type: tx.SWAPPED,
      platform,
    };
  }

  return null;
}
