import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parseImgUrl from "../../../../../functions/parseImgUrl";
import parseNameLink from "../../../../../functions/parseNameLink";
import UppercaseFirstLetter from "../../../../../functions/UppercaseFirstLetter";
import "./detailspropertycard.css";

const ShardDetailsPropertyCard = ({ listingStatus }) => {
  // console.log(listingStatus);
  let listingDetails;
  let itemRoyalties = null;
  let itemTags = null;
  let itemTagsSorted = null;
  let itemScalars = null;
  let itemMedia = null;
  let itemThumbnail = null;
  let itemAllowedListings = null;
  let itemCurrentListings = null;
  let itemCollectionDescription = null;
  let itemEdition = null;
  let itemCollectionContract = null;
  let itemCollectionContractLink = null;
  let itemOwner = null;
  let itemType = null;
  let itemIsListed = null;


  //owner
  if (
    listingStatus.owner &&
    listingStatus.owner.ownerProfile &&
    Object.keys(listingStatus.owner.ownerProfile).length !== 0
  ) {
    itemOwner = listingStatus.owner.ownerProfile;
  }



  //market

  //royalties
  if (
    listingStatus.itemRoyalties.Sale &&
    Object.keys(listingStatus.itemRoyalties.Sale).length !== 0 &&
    Object.getPrototypeOf(listingStatus.itemRoyalties.Sale) === Object.prototype
  ) {
    itemRoyalties = listingStatus.itemRoyalties.Sale;
  }

  //allowedlistings
  if (
    listingStatus.allowedListingActions &&
    Object.keys(listingStatus.allowedListingActions).length !== 0 &&
    Object.getPrototypeOf(listingStatus.allowedListingActions) ===
      Object.prototype
  ) {
    itemAllowedListings = listingStatus.allowedListingActions;
  }

  // console.log(itemCollectionContract);

  //details
  if (listingStatus.listingDetails) {
    listingDetails = listingStatus.listingDetails;
    //media
    if (
      listingStatus.listingDetails.media &&
      Object.keys(listingStatus.listingDetails.media).length !== 0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.media) !==
        Object.prototype
    ) {
      itemMedia = listingStatus.listingDetails.media;
    }
    //contract
    if (
      listingStatus.listingDetails.contract &&
      Object.keys(listingStatus.listingDetails.contract).length !== 0 &&
      Object.getPrototypeOf(listingStatus.listingDetails.contract) ===
        Object.prototype
    ) {
      itemCollectionContract = listingStatus.listingDetails.contract;
      if (
        listingStatus.listingDetails.contract.name &&
        listingStatus.listingDetails.contract.address &&
        listingStatus.listingDetails.contract.public_path
      ) {
        itemCollectionContractLink =
          "A." +
          listingStatus.listingDetails.contract.address.replace("0x", "") +
          "." +
          listingStatus.listingDetails.contract.public_path.split(".")[0];
      }
    }


    if (listingStatus.listingDetails.description) {
      itemCollectionDescription = listingStatus.listingDetails.description;
    }

    if (
      listingStatus.listingDetails.metadata &&
      Object.keys(listingStatus.listingDetails.metadata).length !== 0
    ) {
      itemTags = listingStatus.listingDetails.metadata;

      if (Object.keys(itemTags)) {


         itemTagsSorted = Object.keys(itemTags).sort().reduce(
          (obj, key) => { 
            obj[key] = itemTags[key]; 
            return obj;
          }, 
          {}
        );


      }

    }

  }


  if (listingDetails) {
    return (
      <Card style={{backgroundColor: 'var(--background)'}} id="nft-detail-details-card" className="nft-details-card">
        <Card.Body>
          <Row>
   

            <Col xs="12">

              {itemTags && (
                <>
                  <div className="flex-row pb-4 align-items-center px-0">
                    <i
                      style={{ fontSize: "30px" }}
                      className="fas fa-star mx-1 secColor"
                    />
                    &nbsp;&nbsp;
                    <span className="fw-bold small">Traits:&nbsp;</span>
                  </div>

                  <Row className="pb-3 propRow">
                    {Object.keys(itemTagsSorted).map((properties, i) => {
                      // console.log(listingDetails);
                      return (
                        <Col
                            key={i}
                            xs="6"
                            sm="6"
                            lg="6"
                            xl="6"
                            xxl="4"
                            className="propertiesTraits mb-3"
                          >
                          <Card>
                            <Card.Body className="traitsWrap">
                              <div>
                                <Row>
                                  <span className="fw-bold">{UppercaseFirstLetter(properties)}</span>
                                </Row>
                                <Row>
                                  <span className="themeColor">
                                    {itemTags[properties]}
                                  </span>
                                </Row>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  } else {
    return <p>No properties</p>;
  }
};

export default ShardDetailsPropertyCard;
